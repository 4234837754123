/*
 *
 * SecondaryMarket constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/SecondaryMarket/DEFAULT_ACTION',
}

export default ActionTypes;
