/*
 *
 * RegisterInvestments
 *
 */

import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectRegisterInvestments from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import ProfileResponse from '../../models/response/profile-response';
import { get, OAUTH_TOKEN } from '../../services/local-storage-service';
import { replace } from 'connected-react-router';
import {
  fetchInvestmentsAction,
  updateFormStateInvestmentAction,
  updateStepAction,
  updateInvestmentAction,
  getUserPreferencesAction,
} from './actions';
import FormChips from './components/chips';
import PreferenceRequest from 'models/request/preference-request';
import makeSelectRegister from 'containers/Register/selectors';
import { FormattedMessage, useIntl } from 'react-intl';
import A from 'components/A';
import ArrowButton from 'components/ArrowButton';
import { setLoadingAction } from 'containers/Register/actions';
import { SettingsBackupRestoreSharp } from '@material-ui/icons';

const stateSelector = createStructuredSelector({
  registerInvestments: makeSelectRegisterInvestments(),
  register: makeSelectRegister(),
});

const useStyles = makeStyles(theme => ({
  checkBox: {
    '&.MuiFormControlLabel-label': {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
}));

interface Props {
  nextStep: (ev) => void;
  skipStep: (ev) => void;
  backStep: (ev) => void;
}

const RegisterInvestments = forwardRef((props: Props, ref) => {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'registerInvestments', reducer: reducer });
  useInjectSaga({ key: 'registerInvestments', saga: saga });
  const intl = useIntl();
  const styles = useStyles(props);
  const { registerInvestments, register } = useSelector(stateSelector);
  const {
    formState,
    locationChips,
    sectorChips,
    stageChips,
    updateSuccess,
  } = registerInvestments;
  const { profile } = register;
  const dispatch = useDispatch();
  const { nextStep, skipStep } = props;
  const [checkedB, setCheckedB] = React.useState(false);

  useEffect(() => {
    if (!formState.userId) {
      const token = get(OAUTH_TOKEN);
      if (!token) {
        dispatch(replace('/login'));
      }
    }
  }, [formState]);

  useEffect(() => {
    if (profile) {
      dispatch(fetchInvestmentsAction(profile.userId));
      dispatch(getUserPreferencesAction(profile.userId));
    }
  }, [profile]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(updateStepAction(formState));
      nextStep(null);
    }
  }, [updateSuccess]);

  const updateFormStateChips = field => stateValue => {
    const formField = formState[field];
    if (formField) {
      dispatch(
        updateFormStateInvestmentAction([
          {
            field: field,
            state: { ...formField, ids: stateValue },
          },
        ]),
      );
    }
  };

  const onClickSkip = () => {
    dispatch(updateStepAction(formState));
    skipStep(null);
  };

  const onClickNext = () => {
    dispatch(setLoadingAction(true));
    const data = [
      ...formState['locationPreferences'].ids,
      ...formState['sectorPreferences'].ids,
      ...formState['stagePreferences'].ids,
    ];
    const request: PreferenceRequest = {
      preferenceTagIds: data,
      isEnroll: checkedB,
    };
    dispatch(updateInvestmentAction(request));
  };

  const handleChange = value => {
    setCheckedB(value.target.checked);
  };

  const scope = 'RegisterInvestments';

  return (
    <div>
      {formState.userId && (
        <div>
          <div>
            <Typography
              variant={'h4'}
              style={{ fontSize: '14px', fontWeight: 600 }}
            >
              <FormattedMessage
                id={`RegisterInvestments.locationPreferences`}
              />
            </Typography>
            <FormChips
              formState={formState.locationPreferences}
              chips={locationChips}
              onChange={updateFormStateChips('locationPreferences')}
            />
          </div>
          <div>
            <Typography
              variant={'h4'}
              style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
            >
              <FormattedMessage id={`RegisterInvestments.sectorPreferences`} />
            </Typography>
            <FormChips
              formState={formState.sectorPreferences}
              chips={sectorChips}
              onChange={updateFormStateChips('sectorPreferences')}
            />
          </div>
          <div>
            <Typography
              variant={'h4'}
              style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
            >
              <FormattedMessage id={`RegisterInvestments.stagePreferences`} />
            </Typography>
            <FormChips
              formState={formState.stagePreferences}
              chips={stageChips}
              onChange={updateFormStateChips('stagePreferences')}
            />
          </div>

          <Typography
            variant={'h4'}
            style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
          >
            <FormattedMessage id={`${scope}.enrollSystematicInvestment`} />
          </Typography>

          <div>
            <FormattedMessage id={`${scope}.investSytematicallyFixedAmount`} />
          </div>
          <Typography
            variant={'h4'}
            style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
          >
            <FormattedMessage id={`${scope}.howDoesItWork`} />
          </Typography>
          <div style={{ paddingBottom: '20px' }}>
            <FormattedMessage id={`${scope}.autoReserveYouPreferredAmount`} />{' '}
            <FormattedMessage id={`${scope}.alwaysHave5BusinessDaysToOptOut`} />{' '}
            <FormattedMessage
              id={`${scope}.eneterHowMuchYouWouldLikeToInvest`}
            />
          </div>

          <FormControlLabel
            className={styles.checkBox}
            control={
              <Checkbox
                checked={checkedB}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span style={{ fontWeight: 600, color: 'white' }}>
                <FormattedMessage id={`${scope}.iWantToEnrollInSystematic`} />
              </span>
            }
          />

          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} md={true}>
              <A
                onClick={e => {
                  props.backStep(e);
                }}
              >
                <FormattedMessage id={`back`} />
              </A>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <ArrowButton
                text={`${intl.messages[`next`]}`}
                onClick={onClickNext}
                type="button"
                size="small"
              ></ArrowButton>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
});

export default memo(RegisterInvestments);
