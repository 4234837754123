/*
 *
 * TemplateCreate reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  loading: false,
};

const templateCreateReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.GET_KEY_SUCCESS:
        draft.dynamicKey = action.payload.dynamicValues;
        draft.signatureKey = action.payload.signatureValues;
        break;
      case ActionTypes.SUBMIT_ACTION:
        draft.error = {};
        const { createESignDynamicDataRequests } = action.payload.request;
        if (action.payload.request.name.trim() === '') {
          draft.error.name = 'fileIsRequired';
        }
        if (action.payload.request.description.trim() === '') {
          draft.error.description = 'fileIsRequired';
        }
        if (action.payload.request.fileNamePrefix.trim() === '') {
          draft.error.prefix = 'fileIsRequired';
        }
        if (action.payload.request.fileNamePrefix.trim() === '') {
          draft.error.prefix = 'fileIsRequired';
        }
        if (!action.payload.file || action.payload.file === '') {
          draft.error.file = 'fileIsRequired';
        }
        if (createESignDynamicDataRequests) {
          for (const data of createESignDynamicDataRequests) {
            if (!data.key || !data.key.trim()) {
              draft.error.signKey = 'keyIsRequired';
              break;
            }
            const filter = createESignDynamicDataRequests.filter(
              d => d.key === data.key,
            );
            if (filter.length > 1) {
              draft.error.signKey = 'keyIsDuplicated';
              draft.error.signKeyValue = data.key;
              break;
            }
          }
        }
        break;
      case ActionTypes.CLEAR_ACTION:
        draft.error = action.payload;
        break;
      case ActionTypes.SET_LOADING:
        draft.loading = action.payload;
        break;
    }
  },
  initialState,
);
export default templateCreateReducer;
