import React from 'react';
import trend from '../Icons/trend.svg';
import { makeStyles, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as MuraIcon } from '../../../../images/icons/Mura.svg';
import { ReactComponent as MuriIcon } from '../../../../images/icons/Muri.svg';

const useStyles = makeStyles({
  cardsStyle: {
    background: '#1A1A1A',
    textAlign: 'center',
    border: ' 0.5px solid #333333',
    borderRadius: '3px',
    padding: '2rem 1rem 1rem 1rem',
    justifyContent: 'center',
    color: '#fff',
    maxWidth: '100%',
  },

  cardsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardsPosition: {
    /// for tablets
    '@media(min-width:601px) and (max-width:1024px)': {
      transform: 'translateY(-3%)',
    },
    /// for desktop
    '@media (min-width:1024px) and (max-width:1200px) ': {
      transform: 'translateY(-3%)',
    },
    '@media  (min-width:1200px) ': {
      transform: 'translateY(20%)',
    },
  },
});

function CardItems() {
  const classes = useStyles();
  return (
    <Grid container spacing={4} justify="flex-end">
      <Grid item lg={3} md={12} sm={12} xs={12}>
        <div
          style={{ backgroundColor: '#5334B2' }}
          className={classes.cardsStyle}
        >
          <img src={trend} alt=" trend " style={{ marginBottom: '30px' }} />
          <h1
            style={{
              marginBottom: '15px',
              fontWeight: 700,
              fontStyle: 'normal',
              fontSize: '20px',
            }}
          >
            <FormattedMessage id="CardItems.HeaderOne" />
          </h1>
          <p style={{ fontWeight: 400, fontStyle: 'normal', fontSize: '16px' }}>
            <FormattedMessage id="CardItems.TitleOne" />
          </p>
        </div>
      </Grid>
      <Grid item lg={3} md={12} sm={12} xs={12}>
        <div className={classes.cardsStyle}>
          <MuraIcon style={{ marginBottom: '30px' }} />
          <h1
            style={{
              marginBottom: '15px',
              fontWeight: 700,
              fontStyle: 'normal',
              fontSize: '20px',
            }}
          >
            <FormattedMessage id="CardItems.HeaderTwo" />
          </h1>
          <p style={{ fontWeight: 400, fontStyle: 'normal', fontSize: '16px' }}>
            <FormattedMessage id="CardItems.TitleTwo" />
          </p>
        </div>
      </Grid>
      <Grid item lg={3} md={12} sm={12} xs={12}>
        <div className={classes.cardsStyle}>
          <MuriIcon style={{ marginBottom: '30px' }} />
          <h1
            style={{
              marginBottom: '15px',
              fontWeight: 700,
              fontStyle: 'normal',
              fontSize: '20px',
            }}
          >
            <FormattedMessage id="CardItems.HeaderThree" />
          </h1>
          <p style={{ fontWeight: 400, fontStyle: 'normal', fontSize: '16px' }}>
            <FormattedMessage id="CardItems.TitleThree" />
          </p>
        </div>
      </Grid>
    </Grid>
  );
}

export default CardItems;
