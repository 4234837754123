import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Card, Typography, Theme } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import SearchInput from 'components/SearchInput';
import Table, { HeaderProps } from 'components/Table';
import { useIntl, FormattedMessage } from 'react-intl';
import Img from 'components/Img';
import NumberFormat from 'react-number-format';
import NoAvatar from 'images/not-found.png';
import { changeStatusAction } from '../actions';
import FilterInvestor from './filter';
import InvestorHeader from './header';
import Status from 'components/Status';
import UserData from 'models/user';
import Alert from 'components/AlertDialog/alertDialog';
import InvestorCreateDialog from 'containers/InvestorCreateDialog';
import InvestorSendEmailDialog from 'containers/InvestorSendEmail';
import { ActivateColorButton } from '../../../components/ActiveButton/ActivateColorButton';
import { DeactivateColorButton } from '../../../components/DeactiveButton/DeactivateColorButton';

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    marginBottom: 12,
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 8,
  },
  image: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    marginRight: '10px',
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: 'var(--color-purple)',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 3,
    display: 'inline-block',
  },
  title: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
    minWidth: 75,
  },
  tableCell30: {
    width: '30%',
  },
  tableCell10: {
    width: '10%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell25: {
    width: '25%',
  },
}));

interface Props {
  items: UserData[];
  total: number;
  fetchPage?: (searchKey: string, page: number) => void;
  onFilterChanged?: (value, type) => void;
  refreshPage: boolean;
  scope: string;
}

function InvestorList(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    items,
    total,
    fetchPage,
    onFilterChanged,
    refreshPage,
    scope,
  } = props;
  const [searchKey, setSearchKey] = React.useState('');
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sendEmailOpen, setSendEmailOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<UserData | undefined>(
    undefined,
  );
  const [selectedType, setSelectedType] = React.useState<string>('ACTIVE');
  const intl = useIntl();

  useEffect(() => {
    setOpen(false);
  }, [refreshPage]);

  const onChange = value => {
    setSearchKey(value);
    if (fetchPage) {
      fetchPage(value, 1);
    }
  };

  const onFilterChange = (value, type) => {
    if (onFilterChanged) {
      onFilterChanged(value, type);
    }
  };

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(searchKey, page);
    }
  };
  const headers: HeaderProps[] = [
    {
      name: `${intl.messages['investors']}`.toUpperCase(),
      headerClass: classes.tableCell25,
    },
    {
      name: `${intl.messages['tagsAndInterests']}`.toUpperCase(),
      headerClass: classes.tableCell30,
    },
    {
      name: `${intl.messages['tickets']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['status']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    { name: `${intl.messages['actions']}`.toUpperCase() },
  ];

  const handleInvestorClick = item => {
    dispatch(push(`/investors/${item.userId}`));
  };
  const handleStatusClick = (item, type) => {
    if (type === 'ACTIVE') {
      dispatch(changeStatusAction({ userId: item.userId, status: type }));
    } else {
      dispatch(changeStatusAction({ userId: item.userId, status: type }));
    }
  };
  const handleCreate = () => {
    setOpen(true);
  };
  const handleSendEmail = () => {
    setSendEmailOpen(true);
  };
  const showConfirmStatus = (item, type) => {
    setSelectedItem(item);
    setSelectedType(type);
    setConfirmOpen(true);
  };
  const handleInvite = () => {
    setConfirmOpen(false);
    handleStatusClick(selectedItem, selectedType);
  };
  const localScope = `${scope}.investorList`;
  const renderItem = (item: UserData, index: number) => {
    return [
      <div key={index}>
        <Grid container>
          <Grid item>
            <Img src={item.picturePath || NoAvatar} className={classes.image} />
          </Grid>
          <Grid item xs>
            <Typography
              className={classes.title}
              onClick={() => handleInvestorClick(item)}
            >
              {item.fullName}
            </Typography>
            <Typography onClick={() => handleInvestorClick(item)}>
              {item.email}
            </Typography>
          </Grid>
        </Grid>
      </div>,
      <div key={index}>
        {item.userPreferenceTags.map(val => {
          return (
            <span className={classes.interestTag} key={val}>
              {val}
            </span>
          );
        })}
      </div>,
      <div key={index}>
        <Typography>{item.totalTickets}</Typography>
        <Typography>
          <NumberFormat
            value={
              item.averageTotalTicketsAmount
                ? item.averageTotalTicketsAmount
                : 0
            }
            decimalScale={2}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
      </div>,
      <div key={index}>
        <Status name={item.status} />
      </div>,
      <div key={index}>
        <ActivateColorButton
          style={{ margin: 8 }}
          variant="outlined"
          color="primary"
          disabled={item.status === 'ACTIVE'}
          onClick={() => showConfirmStatus(item, 'ACTIVE')}
        >
          <FormattedMessage id="activate" />
        </ActivateColorButton>
        <DeactivateColorButton
          color="default"
          variant="outlined"
          disabled={item.status === 'INACTIVE'}
          onClick={() => showConfirmStatus(item, 'INACTIVE')}
        >
          <FormattedMessage id="deactivate" />
        </DeactivateColorButton>
      </div>,
    ];
  };
  return (
    <div>
      <div className={classes.searchBox}>
        <SearchInput onChange={onChange} />
      </div>

      <div className={classes.searchBox}>
        <Card variant="outlined">
          <FilterInvestor onChange={onFilterChange} />
        </Card>
      </div>

      <div className={classes.searchBox}>
        <InvestorHeader
          scope={scope}
          noMatched={total}
          handleCreate={handleCreate}
          handleSendEmail={handleSendEmail}
        />
        <Table
          headers={headers}
          items={items}
          renderItem={renderItem}
          fetchDataForPage={handleFetchPage}
          totalElements={total}
        />
      </div>
      <Alert
        open={confirmOpen}
        title={`${
          selectedType === 'ACTIVE'
            ? `${intl.messages[`${localScope}.activeInvestor`]}`
            : `${intl.messages[`${localScope}.inActiveInvestor`]}`
        }`}
        description={
          selectedType === 'ACTIVE' ? (
            <FormattedMessage
              id={`${localScope}.confirmActive`}
              values={{
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          ) : (
            <FormattedMessage
              id={`${localScope}.confirmInActive`}
              values={{
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          )
        }
        onCancel={() => {
          setConfirmOpen(false);
        }}
        onConfirm={handleInvite}
      />
      <InvestorSendEmailDialog
        open={sendEmailOpen}
        setOpen={setSendEmailOpen}
      />
      <InvestorCreateDialog
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

export default memo(InvestorList);
