/*
 *
 * RegisterIdentity reducer
 *
 */

import ActionTypes from './constants';
import { ContainerActions, ContainerState } from './types';
import produce, { Draft } from 'immer';
import { initialDatePickerState } from '../../components/FormControl/datepicker';
import { initialSelectState } from '../../components/FormControl/select';
import { initialInputState } from '../../components/FormControl/input';
import { initialAvatarUploadState } from './components/avatar-upload';

type File = {
  id: string;
  fileId: string;
};

type Docu = {
  id: string;
  type: 'KYC_DOCUMENT_ID' | 'KYC_RESIDENCY_CERTIFICATE';
  files: File[];
};

export const initialState: ContainerState = {
  fetchDataError: false,
  updateSuccess: false,
  loading: false,
  formState: {
    userId: undefined,
    firstName: initialInputState,
    lastName: initialInputState,
    phone: initialInputState,

    avatar: initialAvatarUploadState,

    nationality: initialSelectState,
    individualDocType: initialSelectState,
    individualDocNumber: initialInputState,
    birthday: initialDatePickerState,
    individualDocFiles: initialInputState,

    address1: initialInputState,
    address2: initialInputState,
    zipCode: initialInputState,
    city: initialInputState,
    country: initialSelectState,
    residenceDocFiles: initialInputState,
  },
};

const registerIdentityReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_ME_ERROR_ACTION:
        draft.fetchDataError = true;
        break;
      case ActionTypes.UPDATE_PROFILE_SUCCESS_ACTION:
        draft.updateSuccess = true;
        draft.loading = false;
        break;
      case ActionTypes.UPDATE_PROFILE_FAILED_ACTION:
        draft.updateSuccess = true;
        draft.loading = false;
        break;
      case ActionTypes.RESET_STATE_ACTION:
        draft.updateSuccess = false;
        draft.formState.userId = null;
        break;
      case ActionTypes.UPDATE_PROFILE_ACTION:
        draft.loading = true;
        break;
      case ActionTypes.INIT_PROFILE_ACTION:
        const profile = action.payload;
        draft.documentGroups = profile.documentGroups;
        const getFilesIds = (
          docGroupName: 'KYC_DOCUMENT_ID' | 'KYC_RESIDENCY_CERTIFICATE',
        ) => {
          const documents =
            profile.documentGroups[0].documentGroup.documents || [];
          const files = documents.find(
            doc => doc.document.type === docGroupName,
          );
          return files.document.files.map(file => file.fileId);
        };
        const formFields = [
          { field: 'userId', state: profile.userId },
          {
            field: 'firstName',
            state: { ...initialInputState, value: profile.firstName },
          },
          {
            field: 'lastName',
            state: { ...initialInputState, value: profile.lastName },
          },
          {
            field: 'phone',
            state: { ...initialInputState, value: profile.phone },
          },
          {
            field: 'avatar',
            state: {
              ...initialAvatarUploadState,
              remoteUrl: profile.picturePath,
              localFile: {},
            },
          },
          {
            field: 'nationality',
            state: { ...initialInputState, value: profile.nationality },
          },
          {
            field: 'individualDocType',
            state: { ...initialInputState, value: profile.individualDocType },
          },
          {
            field: 'individualDocNumber',
            state: { ...initialInputState, value: profile.individualDocNumber },
          },
          {
            field: 'birthday',
            state: { ...initialInputState, value: profile.birthday },
          },

          {
            field: 'address1',
            state: { ...initialInputState, value: profile.address1 },
          },
          {
            field: 'address2',
            state: { ...initialInputState, value: profile.address2 },
          },
          {
            field: 'zipCode',
            state: { ...initialInputState, value: profile.zipCode },
          },
          {
            field: 'city',
            state: { ...initialInputState, value: profile.city },
          },
          {
            field: 'country',
            state: { ...initialInputState, value: profile.country },
          },
          {
            field: 'individualDocFiles',
            state: {
              ...initialInputState,
              value: profile.files ? getFilesIds('KYC_DOCUMENT_ID') : undefined,
            },
          },
          {
            field: 'residenceDocFiles',
            state: {
              ...initialInputState,
              value: profile.files
                ? getFilesIds('KYC_RESIDENCY_CERTIFICATE')
                : undefined,
            },
          },
        ];
        for (const state of formFields) {
          draft.formState[state.field] = state.state;
        }
        break;
      case ActionTypes.UPDATE_FORM_STATE_IDENTITY_ACTION:
        const states = action.payload;
        if (states && states.length > 0) {
          for (const state of states) {
            draft.formState[state.field] = state.state;
          }
        }
        break;
      default:
        break;
    }
  },
  initialState,
);

export default registerIdentityReducer;
