import React, { memo } from 'react';
import moment from 'moment';
import { get } from 'lodash/fp';
import {
  Typography,
  Grid,
  Link,
  Divider,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { TEMPLATE_CATEGORY } from 'utils/constants';
import { SignatureDocument } from '../types';
import { requestSign } from '../actions';
import { requestDownload } from 'containers/App/actions';
import { useIntl, FormattedMessage } from 'react-intl';

interface Props {
  documents: SignatureDocument[];
  onRemove: (doc) => void;
  onRefresh: (id) => void;
}

const getStatus = (status, classes) => {
  switch (status) {
    case 'SIGNED':
      return (
        <Typography
          className={classes.rowTypography}
          style={{ color: '#6DB595', fontWeight: 500 }}
        >
          <FormattedMessage id="signed" />
        </Typography>
      );
    case 'IN_PROCESS':
      return (
        <Typography
          className={classes.rowTypography}
          style={{ color: '#588FDB', fontWeight: 500 }}
        >
          <FormattedMessage id="inProcess" />
        </Typography>
      );
    case 'DECLINED':
      return (
        <Typography
          className={classes.rowTypography}
          style={{ color: 'red', fontWeight: 500 }}
        >
          <FormattedMessage id="declined" />
        </Typography>
      );
    case 'EXPIRED':
        return (
          <Typography
            className={classes.rowTypography}
            style={{ color: 'red', fontWeight: 500 }}
          >
            <FormattedMessage id="expired" />
          </Typography>
        );
    default:
      return '';
  }
};

const templateType = TEMPLATE_CATEGORY.reduce((mapAccumulator, obj) => {
  mapAccumulator.set(obj.value, obj.label);
  return mapAccumulator;
}, new Map());

const useStyle = makeStyles({
  header: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '10px',
    color: '#888888',
  },
  docName: {
    fontWeight: 500,
    fontSize: '0.875rem',
    color: '#588FDB',
  },
  docNameString: {
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  files: {
    display: 'grid',
  },
  file: {
    fontWeight: 300,
    fontSize: '0.8rem',
  },
  divider: {
    margin: '10px 0px 0px',
    background: '#F6F6F6',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.8rem',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0px !important',
  },
  requestSign: {
    paddingRight: 10,
  },
  refresh: {
    fontSize: '0.875rem',
    paddingRight: 0,
  },
  remove: {
    fontSize: '0.875rem',
    paddingRight: 0,
    color: '#888888',
  },
  rowTypography: {
    padding: '6px 0',
  },
});

function SignatureTable(props: Props) {
  const intl = useIntl();
  const { documents, onRemove, onRefresh } = props;
  const classes = useStyle();
  const dispatch = useDispatch();

  const onRequestSign = sign => {
    const { esignDocument } = sign;
    dispatch(
      requestSign({
        dealId: get('dealId')(esignDocument),
        eSignTemplateId: get('esignTemplate.esignTemplateId')(esignDocument),
        vehicleIds: [get('vehicleId')(esignDocument)],
        esignDocumentId: get('esignDocumentId')(esignDocument),
      }),
    );
  };

  const onViewFile = sign => {
    if (sign.files && sign.files.length) {
      dispatch(
        requestDownload({
          dealId: sign.esignDocument.dealId,
          fileIds: [sign.files[0].fileId],
        }),
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={4} sm={3} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['name']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['type']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['status']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['lastUpdate']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={3}>
        <Typography className={classes.header}>{''}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider className={classes.divider} />
      </Grid>
      {documents.map((sign, i) => (
        <Grid
          item
          xs={12}
          sm={12}
          container
          key={i}
          style={{ margin: '10px 0' }}
          spacing={2}
        >
          <Grid item xs={4} sm={3}>
            {get('esignDocument.status')(sign) === 'SIGNED' ? (
              <Link
                className={classes.docName}
                onClick={() => onViewFile(sign)}
              >
                {sign.title}
              </Link>
            ) : (
              <Typography
                className={(classes.docNameString, classes.rowTypography)}
              >
                {sign.title}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} sm={2}>
            <Typography className={classes.rowTypography}>
              {templateType.get(
                get('esignDocument.esignTemplate.category')(sign),
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2}>
            {getStatus(get('esignDocument.status')(sign), classes)}
          </Grid>
          <Grid item xs={4} sm={2}>
            <Typography className={classes.rowTypography}>
              {moment(get('esignDocument.modifiedDate')(sign)).format(
                'DD-MM-YYYY',
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} className={classes.flexEnd}>
            <Button
              color="primary"
              className={classes.requestSign}
              onClick={() => onRequestSign(sign)}
              disabled={get('esignDocument.status')(sign) === 'SIGNED' || get('esignDocument.status')(sign) === 'EXPIRED'}
            >
              <FormattedMessage id="requestSigning" />
            </Button>
            {/* Temporarily hide because of unspecified functionality */}
            {/* {get('esignDocument.status')(sign) === 'IN_PROCESS' ? (
              <Link
                className={classes.refresh}
                onClick={() => onRefresh(sign.documentId)}
              >
                Refresh
              </Link>
            ) : (
              <Link className={classes.remove} onClick={() => onRemove(sign)}>
                Remove
              </Link>
            )} */}
          </Grid>
        </Grid>
      ))}
      {!documents.length && (
        <Typography
          style={{ textAlign: 'center', padding: '20px 0', width: '100%' }}
        >
          {`${intl.messages['noDocumentsToDisplay']}`}
        </Typography>
      )}
    </Grid>
  );
}

export default memo(SignatureTable);
