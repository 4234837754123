import React, { memo, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Grid,
  Button,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  matches: number;
  onCreate: () => void;
}

function Header(props: Props) {
  const intl = useIntl();
  const { matches, onCreate } = props;
  const localScope = 'SettingsDocument.templateHeader';
  return (
    <Grid container style={{ padding: '12px 0' }}>
      <Grid
        item
        xs={12}
        sm={true}
        style={{ fontSize: '0.8rem', alignItems: 'center', display: 'flex' }}
      >
        <div>
          <FormattedMessage
            id={`${localScope}.matchedTemplate`}
            values={{ num: matches }}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={'auto'}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          size={'small'}
          variant="contained"
          color="primary"
          onClick={onCreate}
        >
          {`${intl.messages['createNewTemplate']}`.toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
}

export default memo(Header);
