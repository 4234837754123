import React, { memo } from 'react';
import { Grid, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  matches: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: '#888888',
      fontSize: '0.85rem',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

function Header(props: Props) {
  const intl = useIntl();
  const { matches } = props;
  const classes = useStyles();
  return (
    <Grid container style={{ paddingBottom: '11px' }}>
      <Grid
        item
        style={{ fontSize: '0.8rem', alignItems: 'center', display: 'flex' }}
      >

        <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {matches}{' '}
            <FormattedMessage
              id={`notifications`}
              values={{ num: matches }}
            />
           </span>
        </Typography>


      </Grid>
    </Grid>
  );
}

export default memo(Header);
