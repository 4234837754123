/*
 *
 * UploadDocuments constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/UploadDocuments/DEFAULT_ACTION',
  UPLOAD_FILE_ACTION = 'app/UploadDocuments/UPLOAD_FILE_ACTION',
  UPLOAD_FILE_SUCCESS_ACTION = 'app/UploadDocuments/UPLOAD_FILE_SUCCESS_ACTION',
  UPLOAD_FILE_FAILED_ACTION = 'app/UploadDocuments/UPLOAD_FILE_FAILED_ACTION',
  REMOVE_FILE_ACTION = 'app/UploadDocuments/REMOVE_FILE_ACTION',
  SET_DATA_ACTION = 'app/UploadDocuments/SET_DATA_ACTION',
  CLEAR_DATA = 'app/UploadDocuments/CLEAR_DATA',
}

export default ActionTypes;
