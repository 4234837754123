import React, { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import CustomForm from 'components/FormControl';
import {
  Dialog,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useIntl } from 'react-intl';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

import { emailToInvestors } from '../actions';
import PopupTitle from 'components/PopupTitle';
import UpdateDealTranslationFullDescriptionByIdMutation from 'gql/deal/UpdateDealTranslationFullDescriptionById.gql';
import CreateDealTranslationFullDescriptionMutation from 'gql/deal/CreateDealTranslationFullDescription.gql';
import { LANGUAGES } from 'utils/constants';
import Deal from 'models/deal';
import HtmlEditor from 'components/HtmlEditor';

interface Props {
  deal: Deal;
  onCancel: () => void;
  onUpdate: () => void;
  open: boolean;
  status: string | undefined;
}

const useStyle = makeStyles({
  fullWidth: {
    width: '100%',
  },
  summaryTitle: {
    margin: '10px 0',
  },
});

const FullDescriptionEditor = (props: Props) => {
  const intl = useIntl();
  const classes = useStyle();
  const dispatch = useDispatch();
  const { onUpdate, onCancel, open, status, deal } = props;
  const [formState, setFormState] = useState({
    description: {
      en: createEditorStateWithText(''),
      de: createEditorStateWithText(''),
      ja: createEditorStateWithText(''),
    },
    summaryTitle: {},
    sendMail: true,
  });
  const [value, setValue] = React.useState('en');

  const [updateDealTranslationFullDescriptionById] = useMutation(
    UpdateDealTranslationFullDescriptionByIdMutation,
  );
  const [createDealTranslationFullDescription] = useMutation(
    CreateDealTranslationFullDescriptionMutation,
  );

  const updateFormState = (field, subfield = '') => stateValue => {
    if (stateValue === undefined || stateValue === null) {
      return;
    }

    if (formState[field]) {
      setFormState(prevState => {
        const formField = prevState[field];
        let newFieldState;

        if (subfield) {
          newFieldState = { ...formField, [subfield]: stateValue };
        } else {
          newFieldState = stateValue;
        }

        return { ...prevState, [field]: newFieldState };
      });
    }
  };

  const loadState = () => {
    LANGUAGES.forEach(language => {
      const dealTranslationForThisLanguage = deal.deal_translations.filter(
        item => {
          return item.languageCode === language;
        },
      );

      updateFormState(
        'summaryTitle',
        language,
      )(dealTranslationForThisLanguage[0]?.summaryTitle || '');

      const parsedDescription =
        dealTranslationForThisLanguage[0]?.description &&
        JSON.parse(dealTranslationForThisLanguage[0]?.description)
          ? EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(dealTranslationForThisLanguage[0]?.description),
              ),
            )
          : EditorState.createWithText('');

      updateFormState('description', language)(parsedDescription);
    });
  };

  useEffect(() => {
    loadState();
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    if ((status === 'OPEN' || status === 'PREVIEW') && formState.sendMail) {
      dispatch(emailToInvestors({ dealId: deal.dealId }));
    }

    LANGUAGES.forEach(language => {
      const dealTranslationForThisLanguage = deal.deal_translations.filter(
        item => {
          return item.languageCode === language;
        },
      );

      // Update already existing deal translation
      if (dealTranslationForThisLanguage[0]) {
        const result = updateDealTranslationFullDescriptionById({
          variables: {
            dealTranslationId: dealTranslationForThisLanguage[0].id,
            summaryTitle: formState.summaryTitle[language],
            description: JSON.stringify(
              convertToRaw(formState.description[language].getCurrentContent()),
            ),
          },
        });

        result.then(() => {
          onUpdate();
        });
      } else {
        // Add new deal translation
        const result = createDealTranslationFullDescription({
          variables: {
            dealId: deal.dealId,
            description: JSON.stringify(
              convertToRaw(formState.description[language].getCurrentContent()),
            ),
            summaryTitle: formState.summaryTitle[language],
            languageCode: language,
          },
        });

        result.then(() => {
          onUpdate();
        });
      }
    });
  };

  const handleCancel = () => {
    loadState();
    onCancel();
  };

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <PopupTitle
                title={`${intl.messages['updateDealFUllDescription']}`}
                onClose={handleCancel}
              />
            </Grid>
            <TabContext value={value}>
              <TabList
                onChange={handleTabChange}
                aria-label={`${intl.messages['languageTabs']}`}
                variant="fullWidth"
                classes={{
                  root: classes.fullWidth,
                }}
              >
                <Tab label={`${intl.messages['en']}`} value="en" />
                <Tab label={`${intl.messages['de']}`} value="de" />
                <Tab label={`${intl.messages['ja']}`} value="ja" />
              </TabList>
              {LANGUAGES.map(language => (
                <TabPanel
                  key={`tab_${language}`}
                  value={language}
                  classes={{
                    root: classes.fullWidth,
                  }}
                >
                  <Grid item xs={12} sm={12} className={classes.summaryTitle}>
                    <CustomForm
                      type="input"
                      onChange={value =>
                        updateFormState('summaryTitle', language)(value)
                      }
                      title={`${intl.messages['title']}`}
                      defaultValue={formState.summaryTitle[language]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <HtmlEditor
                      editorState={formState.description[language]}
                      onChange={value =>
                        updateFormState('description', language)(value)
                      }
                    />
                  </Grid>
                </TabPanel>
              ))}
            </TabContext>
            {(status === 'OPEN' || status === 'PREVIEW') && (
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={formState.sendMail}
                      onChange={e =>
                        updateFormState('sendMail')(e.currentTarget.checked)
                      }
                    />
                  }
                  label={
                    <Typography>
                      {status === 'OPEN'
                        ? `${intl.messages['sendInfoEmailToAllCommittedInvestors']}`
                        : `${intl.messages['sendInfoEmailToAllInvitedInvestors']}`}
                    </Typography>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} />
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                size={'small'}
                style={{ marginRight: 24 }}
                color="primary"
                variant="outlined"
                onClick={handleCancel}
              >
                {`${intl.messages['cancel']}`.toUpperCase()}
              </Button>
              <Button
                size={'small'}
                style={{ marginTop: '0px' }}
                variant="contained"
                color="primary"
                onClick={handleEdit}
              >
                {`${intl.messages['update']}`.toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default memo(FullDescriptionEditor);
