/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';

const selectGlobal = (state: ApplicationRootState) => {
  return state.global;
};

const selectRoute = (state: ApplicationRootState) => {
  return state.router;
};

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, globalState => globalState.currentUser);

const makeSelectDownloading = () =>
  createSelector(selectGlobal, globalState => globalState.downloading);

const makeSelectCountries = () =>
  createSelector(selectGlobal, globalState => globalState.countries);

const makeSelectCountriesMap = () =>
  createSelector(selectGlobal, globalState => globalState.countriesMap);

const makeSelectUserVehicles = () =>
  createSelector(selectGlobal, globalState => globalState.vehicles);

const makeSelectPreferences = () =>
  createSelector(selectGlobal, globalState => globalState.preferences);

const makeIsLoggedIn = () =>
  createSelector(selectGlobal, globalState => globalState.isLogggedIn);

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.error);

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => routeState.location);

const makeSelectPreviewDocuments = () =>
  createSelector(selectGlobal, globalState => globalState.previewDocuments);

const makeSelectShowDocViewer = () =>
  createSelector(selectGlobal, globalState => globalState.showDocViewer);

const makeSelectPreviewDocumentsLoading = () =>
  createSelector(selectGlobal, globalState => globalState.previewDocumentsLoading);

export {
  selectGlobal,
  makeSelectCurrentUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectLocation,
  makeIsLoggedIn,
  makeSelectUserVehicles,
  makeSelectPreferences,
  makeSelectCountries,
  makeSelectCountriesMap,
  makeSelectDownloading,
  makeSelectPreviewDocuments,
  makeSelectShowDocViewer,
  makeSelectPreviewDocumentsLoading
};
