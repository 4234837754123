import React, { memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '@media (min-width: 1200px)': {
        paddingBottom: '25px',
      },
    },
    current: {
      '& .process-step__index': {
        borderColor: 'var(--color-dark-purple)',
      },
      '& .process-step__index-inner': {
        background: 'var(--color-dark-purple)',
      },
    },
    done: {
      '& .process-step__index': {
        borderColor: 'var(--color-dark-purple)',
        backgroundColor: 'var(--color-dark-purple)',
      },
      '& .process-step__index-inner': {
        background: 'var(--color-dark-purple)',
      },
      '& $line': {
        backgroundColor: 'var(--color-dark-purple)',
      },
    },
    index: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      border: '1px solid var(--color-grey)',
      position: 'relative',
      margin: '0 auto',
      '@media (min-width: 1200px)': {
        margin: 0,
      },
    },
    index__inner: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '24px',
      height: '24px',
      lineHeight: 'normal',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 700,
      borderRadius: '50%',
      background: 'var(--color-grey)',
      color: '#1a1a1a',
    },
    line: {
      content: '""',
      position: 'absolute',
      left: 'calc(50% + 20px)',
      top: '27px',
      height: '2px',
      width: 'calc(100% - 56px)',
      backgroundColor: 'var(--color-grey)',
      border: 0,
      margin: 0,
      padding: 0,
      '@media (min-width: 1200px)': {
        left: '27px',
        top: '48px',
        height: 'calc(100% - 56px + 25px)',
        width: '2px',
      },
    },
  }),
);

interface Props {
  step: number;
  title: string;
  content: string;
  currentStep: number;
}

function Step(props: Props) {
  const classes = useStyles();
  const { step, title, content, currentStep } = props;
  return (
    <Grid
      className={`${classes.root} ${
        currentStep === step
          ? classes.current
          : currentStep > step
          ? classes.done
          : ''
      }`}
      container={true}
      spacing={4}
      alignItems={'stretch'}
      wrap={'nowrap'}
    >
      <Grid item xs={12} lg={'auto'}>
        <div className={`${classes.index} process-step__index`}>
          <div
            className={`${classes['index__inner']} process-step__index-inner`}
          >
            {step}
          </div>
        </div>
        {4 !== step && <hr className={classes.line} />}
      </Grid>
      <Hidden mdDown>
        <Grid item xs={'auto'} lg={true}>
          <h4 style={{ marginBottom: 0, color: 'white', paddingTop: '7px' }}>
            {title}
          </h4>
          {currentStep === step && <div>{content}</div>}
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default memo(Step);
