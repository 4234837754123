import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the register state domain
 */

const selectRegisterDomain = (state: ApplicationRootState) => {
  return state.register || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Register
 */

const makeSelectRegister = () =>
  createSelector(selectRegisterDomain, substate => {
    return substate;
  });

export default makeSelectRegister;
export { selectRegisterDomain };
