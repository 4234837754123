/*
 *
 * Register
 *
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectRegister from './selectors';
import reducer from './reducer';
import saga from './saga';
import RegisterSignUp from '../RegisterSignUp';
import RegisterIdentity from '../RegisterIdentity';
import RegisterVehicles from '../RegisterVehicles';
import RegisterInvestments from '../RegisterInvestments';
import { fetchProfileAction, setLoadingAction, setStepAction } from './actions';
import { get, OAUTH_TOKEN } from '../../services/local-storage-service';
import { useIntl } from 'react-intl';

import whiteIcon from '../../images/logos/Jadesquare-logo-white.svg';

import RegisterSuccess from './components/success';
import RegisterProcess from './components/process';

import { makeStyles, Container, Grid, Typography } from '@material-ui/core';
import Img from 'components/Img';
import { FormattedMessage } from 'react-intl';
import { replace } from 'connected-react-router';
import A from 'components/A';
import RegisterLoader from './components/loader';
import VerificationRequired from './components/verification';
const stateSelector = createStructuredSelector({
  register: makeSelectRegister(),
});

const useStyles = makeStyles(theme => ({
  signup: {
    border: '1px solid var(--color-medium-dark-grey)',
    color: 'var(--color-grey)',
    fontSize: '0.85rem',
  },
  sidebar: {
    background: '#1a1a1a',
    borderRight: 0,
    padding: '20px',
    '@media (min-width: 992px)': {
      borderRight: '1px solid var(--color-medium-dark-grey)',
      padding: '30px',
    },
  },
  content: {
    padding: '20px',
    position: 'relative',
    '@media (min-width: 992px)': {
      padding: '40px 20px',
    },
  },
}));

function Register() {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'register', reducer: reducer });
  useInjectSaga({ key: 'register', saga: saga });

  const { register } = useSelector(stateSelector);
  const { step, profile } = register;
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  let childRefStep2;
  let childRefStep3;
  let childRefStep4;

  useEffect(() => {
    if (get(OAUTH_TOKEN)) {
      if (step === 1) {
        dispatch(fetchProfileAction());
      }
    }
  }, []);

  const onClickBackSuccess = () => {
    dispatch(setStepAction(step - 1));
  };

  const onClickSkipSuccess = () => {
    dispatch(setLoadingAction(true));
    dispatch(setStepAction(step === 5 ? step : step + 1));
  };

  const onClickNextSuccess = () => {
    dispatch(setStepAction(step === 5 ? step : step + 1));
  };

  useEffect(() => {
    if (step === 5) {
      localStorage.clear();
    }
    dispatch(setLoadingAction(false));
  }, [step]);

  const scope = 'Register';

  const registerSteps = {
    1: {
      title: intl.messages[`Register.components.appbar.signUp`],
      content: intl.messages[`RegisterSignUp.accountWillGiveAccessToInvest`],
      render: <RegisterSignUp nextStep={onClickNextSuccess} />,
    },
    2: {
      title: intl.messages[`Register.components.appbar.identity`],
      content:
        intl.messages[
          `ProfileEditDialog.profileEdit.identityOfThePrimaryInvestor`
        ],
      render: (
        <RegisterIdentity
          ref={ref => (childRefStep2 = ref)}
          nextStep={onClickNextSuccess}
          backStep={onClickBackSuccess}
        />
      ),
    },
    3: {
      title: intl.messages[`Register.components.appbar.vehicles`],
      content: intl.messages[`RegisterVehicles.asInvestorMessage`],
      render: (
        <RegisterVehicles
          ref={ref => (childRefStep3 = ref)}
          skipStep={onClickSkipSuccess}
          nextStep={onClickNextSuccess}
          backStep={onClickBackSuccess}
        />
      ),
    },
    4: {
      title: intl.messages[`Register.components.appbar.investments`],
      content:
        intl.messages[`RegisterInvestments.byChoosingInvestmentInterests`],
      render: (
        <RegisterInvestments
          ref={ref => (childRefStep4 = ref)}
          skipStep={onClickSkipSuccess}
          nextStep={onClickNextSuccess}
          backStep={onClickBackSuccess}
        />
      ),
    },
  };

  const clickOnSignIn = () => {
    dispatch(replace('/login'));
  };

  return (
    <Container className="main">
      <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
        <Img src={whiteIcon} style={{ height: 30 }} />
      </div>
      {step === 5 ? (
        <div>
          {profile && !profile.isVerified ? (
            <VerificationRequired scope={scope} />
          ) : (
            <RegisterSuccess scope={scope} />
          )}
        </div>
      ) : (
        <div className={classes.signup}>
          {/************************************************ BODY *******************************************/}
          {register.isLoading && <RegisterLoader />}
          <Grid container spacing={0}>
            <Grid item xs={12} lg={4} classes={{ root: classes.sidebar }}>
              <h2 className="h4" style={{ fontSize: '1.45rem' }}>
                <FormattedMessage id={`RegisterSignUp.createYourAccount`} />
              </h2>
              <div style={{ paddingBottom: '2rem' }}>
                <FormattedMessage id={`RegisterSignUp.alreadyHaveAccount`} />
                <A onClick={clickOnSignIn}>
                  {' '}
                  <FormattedMessage id={`RegisterSignUp.signIn`} />
                </A>
              </div>
              <RegisterProcess steps={registerSteps} step={step} />
            </Grid>
            <Grid item xs={12} lg={8} classes={{ root: classes.content }}>
              {registerSteps[step].render}
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default memo(Register);
