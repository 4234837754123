/*
 *
 * ProfileAbout actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const fetchDocumentAction = payload =>
  action(ActionTypes.FETCH_USER_DOCUMENT_ACTION, payload);

export const fetchDocumentSuccessAction = payload =>
  action(ActionTypes.FETCH_USER_DOCUMENT_SUCCESS_ACTION, payload);

export const updateProfileSuccessAction = payload =>
  action(ActionTypes.UPDATE_PROFILE_SUCCESS_ACTION, payload);

export const hideLoading = () => action(ActionTypes.HIDE_LOADING);
