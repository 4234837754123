import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import PreferenceResponse from 'models/response/preference-response';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function getPreferenceGroup(): Promise<any> {
  return instance
    .get('/preference-tags/group')
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function updatePreferences(
  data: PreferenceResponse,
): Promise<any> {
  return instance
    .post('/users/preference-tags', data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getUserPreference(): Promise<any> {
  return instance
    .get('/users/preference-tags')
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}
