import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useIntl } from 'react-intl';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { PAGE_SIZE } from 'utils/constants';
import { Grid, Card } from '@material-ui/core';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '12px',
      color: '#888888',
      padding: '10px 0',
    },
    actions: {
      padding: '5px 8px',
      color: 'var(--color-grey)',
    },
    currentPageNo: {
      color: 'var(--color-purple)',
    },
    hr: {
      border: 0,
      padding: 0,
      margin: 0,
      width: '1px',
      height: '20px',
      backgroundColor: 'var(--color-grey)',
    },
  }),
);

export interface Props {
  page: number;
  total: number;
  onChangePage?: (page) => void;
}

export default function Pagination(props: Props): JSX.Element {
  const intl = useIntl();
  const { page, total, onChangePage } = props;
  const classes = useStyles();
  const onBeforeClick = () => {
    if (onChangePage && page > 1) {
      onChangePage(page - 1);
    }
  };
  const onNextClick = () => {
    if (onChangePage && (page) * PAGE_SIZE < total) {
      onChangePage(page + 1);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={true}>
          {`${intl.messages['showing']} ${(page -1) * PAGE_SIZE + 1} - ${Math.min(
            (page) * PAGE_SIZE,
            total,
          )} ${total > PAGE_SIZE ? `${intl.messages['of']} ${total}` : ''}`}
        </Grid>
        {total > PAGE_SIZE && (
          <Grid item xs={'auto'}>
            <Card className={classes.actions}>
              <Grid container alignItems="center" wrap="nowrap" spacing={3}>
                <Grid item>
                  <span className={classes.currentPageNo}>{`${page}`}</span>
                  {total > PAGE_SIZE
                    ? ` / ${Math.ceil(total / PAGE_SIZE)}`
                    : ''}
                </Grid>
                <Grid item xs={'auto'}>
                  <hr className={classes.hr} />
                </Grid>
                <Grid item>
                  <Grid container>
                    <IconButton
                      onClick={() => onChangePage && onChangePage(1)}
                      disabled={page === 1}
                      style={{ padding: 0 }}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => onBeforeClick()}
                      disabled={page === 1}
                      style={{ padding: 0 }}
                    >
                      <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => onNextClick()}
                      disabled={(page) * PAGE_SIZE >= total}
                      style={{ padding: 0 }}
                    >
                      <NavigateNextIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        onChangePage &&
                        onChangePage(Math.ceil(total / PAGE_SIZE))
                      }
                      disabled={(page) * PAGE_SIZE >= total}
                      style={{ padding: 0 }}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
