import { reduce } from "lodash";
import { ContainerState, ContainerActions } from "./types";
import ActionTypes from "./constants";
import produce, { Draft } from "immer";
import { isLoggedIn } from "utils/helpers";
import LocalService from "services/local-storage-service";

// The initial state of the App
export const initialState: ContainerState = {
	loading: false,
	error: undefined,
	isLogggedIn: isLoggedIn(),
	currentUser: undefined,
	countries: [],
	countriesMap: {},
	previewDocumentsLoading: false,
	previewDocuments: [],
	showDocViewer: false,
};

const appReducer = produce((draft: Draft<ContainerState>, action: ContainerActions) => {
	switch (action.type) {
		case ActionTypes.SHOW_TOAST:
			console.log(action, draft);
			draft.error = action.payload;
			break;
		case ActionTypes.LOGIN_SUCCESS:
			draft.isLogggedIn = true;
			break;
		case ActionTypes.LOGOUT:
			draft.isLogggedIn = false;
			draft.currentUser = undefined;
			LocalService.clear();
			break;
		case ActionTypes.SET_USER_DATA:
			draft.currentUser = action.payload;
			break;
		case ActionTypes.SET_USER_VEHICLE:
			draft.vehicles = action.payload.filter((item) => item.status === "ACTIVE");
			break;
		case ActionTypes.SET_PREFERENCE:
			draft.preferences = action.payload;
			break;
		case ActionTypes.REQUEST_DOWNLOAD_SINGLE_FILE:
			if (!draft.downloading) {
				draft.downloading = 0;
			}

			draft.downloading += 1;
			break;
		case ActionTypes.REQUEST_DOWNLOAD:
			if (!draft.downloading) {
				draft.downloading = 0;
			}

			draft.downloading += 1;
			break;
		case ActionTypes.DOWNLOAD_FINISHED:
			if (draft.downloading) {
				draft.downloading -= 1;
			}
			break;
		case ActionTypes.GET_COUNTRIES_SUCCEED:
			draft.countries = action.payload;
			draft.countriesMap = reduce(
				action.payload,
				(map, obj) => {
					map[obj.alpha2Code] = {
						enShortName: obj.enShortName,
						nationality: obj.nationality,
					};
					return map;
				},
				{}
			);
			break;
		case ActionTypes.PREVIEW_DOWNLOAD_STARTED:
			draft.previewDocumentsLoading = true;
			break;
		case ActionTypes.PREVIEW_DOWNLOAD_FINISHED:
			draft.previewDocuments = action.payload.map((item) => {
				item.uri = item.url;
				return item;
			});
			draft.previewDocumentsLoading = false;
			break;
		case ActionTypes.HANDLE_TOGGLE_DOC_VIEWER:
			draft.showDocViewer = action.payload;
			break;
	}
}, initialState);

export default appReducer;
