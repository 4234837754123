/*
 *
 * ForgotPassword constants
 *
 */

enum ActionTypes {
  CALL_RESET_PASSWORD = 'app/ForgotPassword/CALL_RESET_PASSWORD',
  CALL_RESET_PASSWORD_SUCCEED = 'app/ForgotPassword/CALL_RESET_PASSWORD_SUCCEED',
  RESET_STATE = 'app/ForgotPassword/RESET_STATE',
}

export default ActionTypes;
