/*
 *
 * RegisterSignUp reducer
 *
 */

import ActionTypes from './constants';
import { ContainerActions, ContainerState } from './types';
import produce, { Draft } from 'immer';
import { initialInputState } from '../../components/FormControl/input';

export const initialState: ContainerState = {
  success: false,
  formState: {
    firstName: initialInputState,
    lastName: initialInputState,
    email: initialInputState,
    password: initialInputState,
    languageCode: { value: 'en' },
  },
};

const registerSignUpReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SIGN_UP_SUCCESS:
        draft.success = true;
        break;
      case ActionTypes.UPDATE_FORM_STATE_SIGN_UP_ACTION:
        const states = action.payload;
        if (states && states.length > 0) {
          for (const state of states) {
            draft.formState[state.field] = state.state;
          }
        }
        break;
      default:
        break;
    }
  },
  initialState,
);

export default registerSignUpReducer;
