import { UpdateDealRequest } from 'models/request/deal-request';
import { DealType } from './constants';
import moment from 'moment';
import { initialFormState } from 'components/FormControl/state';
import { LANGUAGES } from 'utils/constants';
import DealTranslation from 'models/dealTranslation';

export default function validateCreate(
  formState,
  updateFormState,
  dealId,
  userId,
): UpdateDealRequest | undefined {
  const dealType = formState.dealType.value;
  let validate = true;
  let name = {};
  name['en'] = {
    ...formState.name['en'],
    ...initialFormState,
  };
  name['de'] = {
    ...formState.name['de'],
    ...initialFormState,
  };
  name['ja'] = {
    ...formState.name['ja'],
    ...initialFormState,
  };
  let closingDate = {
    ...formState.closingDate,
    ...initialFormState,
  };
  let minTicket = {
    ...formState.minTicket,
    ...initialFormState,
  };
  let maxTicket = {
    ...formState.maxTicket,
    ...initialFormState,
  };
  let premoney = {
    ...formState.premoney,
    ...initialFormState,
  };
  let valuationFloor = {
    ...formState.valuationFloor,
    ...initialFormState,
  };
  let valuationCap = {
    ...formState.valuationCap,
    ...initialFormState,
  };
  let totalSize = {
    ...formState.totalSize,
    ...initialFormState,
  };
  let loanInterest = {
    ...formState.loanInterest,
    ...initialFormState,
  };
  let nda = {
    ...formState.nda,
    ...initialFormState,
  };
  let openingDate = {
    ...formState.openingDate,
    ...initialFormState,
  };
  let tokeny_link = {
    ...formState.tokeny_link,
    ...initialFormState,
  };
  /* 2023-01-27: Client asked to remove required state
  if (!tokeny_link.value || tokeny_link.value === '') {
    tokeny_link = {
      ...tokeny_link,
      error: true,
      helperMessage: 'textIsRequired',
    };
    validate = false;
  }*/
  const {
    netProfit,
    forecastNetProfit,
    summary,
    discount,
    time,
    gauge,
    confidential,
    interests,
    additionalList,
  } = formState;

  if (!(name['en'].value && name['en'].value.trim())) {
    name['en'] = {
      ...name['en'],
      error: true,
      helperMessage: 'nameRequired',
    };
    name = { ...name, en: name['en'] };
    validate = false;
  }
  if (!closingDate.value) {
    closingDate = {
      ...closingDate,
      error: true,
      helperMessage: 'closingDateRequired',
    };
    validate = false;
  }
  if (!minTicket.value || minTicket.value === '0') {
    minTicket = {
      ...minTicket,
      error: true,
      helperMessage: 'minTicketRequired',
    };
    validate = false;
  }
  if (!maxTicket.value || maxTicket.value === '0') {
    maxTicket = {
      ...maxTicket,
      error: true,
      helperMessage: 'maxTicketRequired',
    };
    validate = false;
  }
  if (!totalSize.value || totalSize.value === '0') {
    totalSize = {
      ...totalSize,
      error: true,
      helperMessage: 'totalSizeRequired',
    };
    validate = false;
  }
  if (
    minTicket.value &&
    maxTicket.value &&
    parseInt(maxTicket.value) < parseInt(minTicket.value)
  ) {
    minTicket = {
      ...minTicket,
      error: true,
      helperMessage: 'minMaxTicketNotValid',
    };
    validate = false;
  }
  if (
    minTicket.value &&
    totalSize.value &&
    parseInt(totalSize.value) < parseInt(minTicket.value)
  ) {
    minTicket = {
      ...minTicket,
      error: true,
      helperMessage: 'minTotalTicketNotValid',
    };
    validate = false;
  }
  if (
    maxTicket.value &&
    totalSize.value &&
    parseInt(totalSize.value) < parseInt(maxTicket.value)
  ) {
    maxTicket = {
      ...maxTicket,
      error: true,
      helperMessage: 'maxTicketNotValid',
    };
    validate = false;
  }
  if (confidential.value && !nda.value) {
    nda = {
      ...nda,
      error: true,
      helperMessage: 'ndaRequired',
    };
    validate = false;
  }
  if (
    openingDate.value &&
    closingDate.value &&
    moment(openingDate.value, 'DD-MM-YYYY').isAfter(
      moment(closingDate.value, 'DD-MM-YYYY'),
    )
  ) {
    openingDate = {
      ...openingDate,
      error: true,
      helperMessage: 'openingDateNotValid',
    };
    validate = false;
  }

  // Populate request
  const request: UpdateDealRequest = {
    userId: userId,
    dealData: {
      id: dealId,
      isConfidentialAgreement: confidential.value,
      isGaugeDisplay: gauge.value,
      dealType: dealType,
      totalSizeRound: totalSize.value,
      minTicket: minTicket.value,
      maxTicket: maxTicket.value,
      tokeny_link: tokeny_link.value,
      refClosingDate: moment(closingDate.value, 'DD-MM-YYYY').format(
        'YYYY-MM-DD',
      ),
    },
    dealPreferenceTagIds: [],
    translationsToInsert: [],
  };
  if (confidential.value) {
    request.dealData.esignTemplateId = nda.value;
  }

  const openingTime = time.value
    ? `${openingDate.value} ${time.value.format('HH:mm')}+08:00`
    : `${openingDate.value} 00:00+08:00`;
  const requestOpeningDate = moment(openingTime, 'DD-MM-YYYY HH:mm Z').format();
  if (openingDate.value && openingTime && requestOpeningDate) {
    request.dealData.openingDate = requestOpeningDate;
  }

  switch (dealType) {
    case DealType.EQUITY:
      if (!premoney.value || premoney.value === '0') {
        premoney = {
          ...premoney,
          error: true,
          helperMessage: 'premoneyRequired',
        };
        validate = false;
      }
      request.dealData.premoneyValuation = premoney.value;
      request.dealData.netProfit = netProfit.value;
      request.dealData.forecastNetProfit = forecastNetProfit.value;

      break;
    case DealType.SAFE:
      if (!valuationFloor.value || valuationFloor.value === '0') {
        valuationFloor = {
          ...valuationFloor,
          error: true,
          helperMessage: 'valuationFloorRequired',
        };
        validate = false;
      }
      if (!valuationCap.value || valuationCap.value === '0') {
        valuationCap = {
          ...valuationCap,
          error: true,
          helperMessage: 'valuationCapRequired',
        };
        validate = false;
      }
      request.dealData.valuationCap = valuationCap.value;
      request.dealData.valuationFloor = valuationFloor.value;
      request.dealData.discount = discount.value || 0;
      break;
    case DealType.LOAN:
      if (!loanInterest.value || loanInterest.value === '0') {
        loanInterest = {
          ...loanInterest,
          error: true,
          helperMessage: 'loanInterestRequired',
        };
        validate = false;
      }
      request.dealData.loanInterest = loanInterest.value;
      break;
  }

  LANGUAGES.forEach(language => {
    const dealTranslation: DealTranslation = {
      name: name[language].value ? name[language].value.trim() : '',
      summary: summary[language].value ? summary[language].value.trim() : '',
      summaryTitle: formState.summaryTitle[language],
      description: formState.description[language],
      languageCode: language,
    };

    const additional: any = [];

    additionalList[language]?.value?.forEach(addItem => {
      additional.push({ summary: addItem.value, title: addItem.key });
    });

    dealTranslation.deal_additional_details = {
      data: additional,
    };
    request.translationsToInsert.push(dealTranslation);
  });

  if (interests && interests.value) {
    request.dealPreferenceTagIds = interests.value;
  }

  LANGUAGES.forEach(language => {
    updateFormState('name', language)(name[language]);
    updateFormState('summary', language)(summary[language]);
  });
  updateFormState('closingDate')(closingDate);
  updateFormState('minTicket')(minTicket);
  updateFormState('maxTicket')(maxTicket);
  updateFormState('totalSize')(totalSize);
  updateFormState('premoney')(premoney);
  updateFormState('valuationCap')(valuationCap);
  updateFormState('valuationFloor')(valuationFloor);
  updateFormState('loanInterest')(loanInterest);
  updateFormState('nda')(nda);
  updateFormState('openingDate')(openingDate);
  updateFormState('tokeny_link')(tokeny_link);

  return validate ? request : undefined;
}
