import { takeLatest, call, put } from 'redux-saga/effects';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { updateProfile } from 'services/api/profile-service';
import {
  updateProfileFailedAction,
  updateProfileSuccessAction,
} from './actions';
import { updateProfileSuccessAction as profileSuccessAction } from 'containers/ProfileAbout/actions';
import { uploadUserProfilePicture } from 'services/api/upload-service';
function* updateProfileRequest(action) {
  const request = { ...action.payload };
  if (request.localFile) {
    const upload: FileResponse = request.localFile
      ? yield call(uploadUserProfilePicture, request.localFile)
      : null;
    if (upload) {
      request['picturePath'] = upload.url;
    }
  }
  try {
    const response = yield call(updateProfile, request);
    yield put(updateProfileSuccessAction(response));
    yield put(profileSuccessAction(response));
    yield put(
      showToast({
        message: 'profileSuccessfullyUpdated',
        type: 'success',
      }),
    );
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
    yield put(updateProfileFailedAction(err));
  }
}

export default function* profileEditDialogSaga() {
  yield takeLatest(ActionTypes.SUBMIT_ACTION, updateProfileRequest);
}
