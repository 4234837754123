import React, { memo } from 'react';
import { FormControl, FormLabel } from '@material-ui/core';
import TagsSelector from 'components/TagsSelector';
import { useIntl } from 'react-intl';
interface Props {
  onSelect: (e) => void;
  option: any;
  defaultValue?: Array<string>;
}

function Interests(props: Props) {
  const intl = useIntl();
  const { onSelect, option, defaultValue = [] } = props;

  return (
    <FormControl fullWidth>
      <FormLabel
        style={{ color: 'rgba(255, 255, 255, 0.7)', marginBottom: '5px' }}
      >
        {`${intl.messages['interests']}`}
      </FormLabel>
      <TagsSelector
        onSelect={onSelect}
        option={option}
        defaultValue={defaultValue}
        style={{ width: '100%' }}
      />
    </FormControl>
  );
}

export default memo(Interests);
