import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  statusDefault: {
    fontWeight: 'bold',
    color: '#888888',
    whiteSpace: 'nowrap',
    // backgroundColor: '#DEDEDE',
  },
  statusDone: {
    fontWeight: 'bold',
    color: 'var(--color-cyan)',
    whiteSpace: 'nowrap',
    // backgroundColor: '#C8ECDC',
  },
  statusOnGoing: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
    whiteSpace: 'nowrap',
    // backgroundColor: '#CFE1FF',
  },
  statusRemove: {
    borderRadius: 2,
    fontWeight: 'bold',
    color: '#D74F4F',
    whiteSpace: 'nowrap',
    // backgroundColor: '#FFDCDC',
  },
}));
interface Props {
  name?: string;
}

function Status(props: Props) {
  const intl = useIntl();
  const { name } = props;
  const classes = useStyles();
  const status = val => {
    if (val) {
      switch (val.toLowerCase()) {
        case 'removed':
          return (
            <Typography className={classes.statusRemove}>
              {
                intl.messages[
                  val
                    .split('-')
                    .join('')
                    .toLowerCase()
                ]
              }
            </Typography>
          );
        case 'preview':
        case 'open':
        case 'registered':
        case 'active':
          return (
            <Typography className={classes.statusOnGoing}>
              {
                intl.messages[
                  val
                    .split('-')
                    .join('')
                    .toLowerCase()
                ]
              }
            </Typography>
          );
        case 'close':
        case 'funded':
          return (
            <Typography className={classes.statusDone}>
              {
                intl.messages[
                  val
                    .split('-')
                    .join('')
                    .toLowerCase()
                ]
              }
            </Typography>
          );
      }
    }
    return (
      <Typography className={classes.statusDefault}>
        {val
          ? intl.messages[
              val
                .split('-')
                .join('')
                .toLowerCase()
            ]
          : ''}
      </Typography>
    );
  };

  return status(name);
}

export default memo(Status);
