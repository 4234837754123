/**
 * Shows a success toast message
 */

import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  message: string;
}
function SuccessToast(props: Props): JSX.Element {
  const { message, isOpen } = props;
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    setShowing(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setShowing(false);
  };

  return (
    <Snackbar
      open={showing}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      <Alert severity="success" onClose={handleClose}>
        {message || ''}
      </Alert>
    </Snackbar>
  );
}

export default SuccessToast;
