/*
 *
 * CompanyDeals
 *
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DealTable from './components/dealTable';
import { Grid, makeStyles } from '@material-ui/core';
import { PAGE_SIZE } from 'utils/constants';
import DealCreator from 'containers/DealCreator';
import { useQuery } from '@apollo/react-hooks';
import ListCompanyDealsQuery from '../hasura/gql/ListCompanyDeals.gql';
import Deal from '../../../models/deal';
import Company from '../../../models/company';
import ErrorToast from '../../../components/ErrorToast';
import SuccessToast from 'components/SuccessToast';
import { makeSelectCurrentUser } from 'containers/App/selectors';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const useStyles = makeStyles(() => ({
  root: {},
  logo: {
    margin: 6,
    width: 35,
    height: 35,
    borderRadius: '50%',
  },
  detail: {
    marginTop: 6,
    padding: 6,
  },
}));
interface Props {
  companyId: string;
  onDealCreated: () => void;
}

function CompanyDeals(props: Props): JSX.Element {
  const [currentPage, setCurrentPage] = React.useState(1);
  const classes = useStyles();
  const [showDealCreateDialog, setShowDealCreateDialog] = React.useState(false);
  const [dealCreationSuccess, setDealCreationSuccess] = React.useState<
    string | undefined
  >(undefined);
  const [dealCreationError, setDealCreationError] = React.useState<
    string | undefined
  >(undefined);
  const { currentUser } = useSelector(stateSelector);

  const LANGUAGE_CODE = currentUser?.languageCode
    ? currentUser.languageCode
    : 'en';

  const { loading, error, data, refetch } = useQuery(ListCompanyDealsQuery, {
    variables: {
      id: props.companyId,
      offset: (currentPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      languageCode: LANGUAGE_CODE,
    },
  });

  const toDeal = items => {
    const result = new Array<Deal>();
    items.forEach(item => {
      result.push({
        dealId: item.dealId,
        name: item.translations[0].name,
        dealType: item.dealType,
        status: item.status,
        totalSizeRound: item.totalSizeRound,
        refClosingDate: item.refClosingDate,
        company: {
          companyId: item.company.companyId,
          name: item.company.translations[0].name,
        } as Company,
        fundTransferredTicketsTotalAmount:
          item.ticketsAggregate.aggregate.sum.amount,
        fundTransferredTicketsCount: item.ticketsAggregate.aggregate.count,
      } as Deal);
    });
    return result;
  };

  useEffect(() => {
    refetch();
  }, []);

  const fetchPage = page => {
    setCurrentPage(page);
    refetch();
  };

  const addNewDeal = () => {
    setShowDealCreateDialog(true);
  };

  const onDealCreationSuccess = message => {
    setDealCreationSuccess(message);
    refetch();
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <ErrorToast
        isOpen={Boolean(error || dealCreationError)}
        message={error ? error.message : dealCreationError || ''}
      />
      <SuccessToast
        isOpen={Boolean(dealCreationSuccess)}
        message={dealCreationSuccess || ''}
      />
      <Grid item xs={12}>
        {!loading && !error && (
          <DealTable
            loading={loading}
            error={error != undefined}
            items={toDeal(data.deals)}
            total={data.dealsAggregate.count.value}
            fetchPage={fetchPage}
            companyId={props.companyId}
            addNewDeal={addNewDeal}
          />
        )}
      </Grid>
      <DealCreator
        companyId={props.companyId}
        propOpen={showDealCreateDialog}
        setOpen={setShowDealCreateDialog}
        onSuccess={message => {
          onDealCreationSuccess(message);
          refetch();
          props.onDealCreated();
        }}
        onError={setDealCreationError}
      />
    </Grid>
  );
}

export default CompanyDeals;
