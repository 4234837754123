/**
 * Displays a horizontal divider with custom text on it.
 */

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '2rem',
  },
  border: {
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  content: {
    flexShrink: 0,
    padding: '0 0.6rem',
    color: theme.palette.text.disabled,
    fontSize: '0.8rem',
    textAlign: 'center',
  },
}));

interface Props {
  label: string;
}

function DividerWithLabel(props: Props): JSX.Element {
  const { label } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <div className={classes.content}>{label}</div>
      <div className={classes.border} />
    </div>
  );
}

export default DividerWithLabel;
