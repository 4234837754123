import { takeLatest, call, put, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import { RestResult } from 'models/response/rest-result';
import * as actions from './actions';
import { showToast } from 'containers/App/actions';
import {
  createTemplate,
  getDynamicValue,
  updateTemplate,
} from 'services/api/sign-template';
import { SigningTemplate } from 'models/signing-template';
import { submitSuccessAction } from 'containers/SettingsDocument/actions';
import makeSelectTemplateCreateDialog from './selectors';

export function* createTemplateRequest(action) {
  const { error } = yield select(makeSelectTemplateCreateDialog());
  if (!error || error === {} || Object.keys(error).length === 0) {
    const { request, file } = action.payload;
    try {
      yield put(actions.setLoading(true));
      const result: RestResult<SigningTemplate> = !request.esignTemplateId
        ? yield call(createTemplate, request, file)
        : yield call(updateTemplate, request, file);
      yield put(
        showToast({
          message: request.esignTemplateId
            ? 'templateSuccessfullyUpdated'
            : 'templateSuccessfullyCreated',
          type: 'success',
        }),
      );
      yield put(actions.setLoading(false));
      if (result) {
        yield put(submitSuccessAction(result));
      }
    } catch (err) {
      yield put(actions.setLoading(false));
      yield put(actions.submitFailedAction(err));
      yield put(
        showToast({
          message: err?.response?.data?.messages[0]
            ? err.response.data.messages[0]
            : 'unexpectedError',
          type: 'error',
        }),
      );
    }
  }
}

export function* getDynamicValueRequest() {
  try {
    const result: RestResult<any> = yield call(getDynamicValue);
    if (result) {
      yield put(actions.getKeySuccess(result));
    }
  } catch (err) {
    yield put(actions.submitFailedAction(err));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* createTemplateSaga() {
  yield takeLatest(ActionTypes.SUBMIT_ACTION, createTemplateRequest);
  yield takeLatest(ActionTypes.GET_KEY, getDynamicValueRequest);
}
