/*
 *
 * CompanyCreateDialog actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const clearFormAction = () => action(ActionTypes.CLEAR_FORM_ACTION);
export const createInvestor = request =>
  action(ActionTypes.CREATE_INVESTOR, request);
export const validateInvestor = request =>
  action(ActionTypes.VALIDATE_INVESTOR, request);
export const setLoading = loading => action(ActionTypes.SET_LOADING, loading);
export const setError = err => action(ActionTypes.SET_ERROR, err);
