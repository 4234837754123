/*
 *
 * Register
 *
 */

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Toolbar, Grid, Typography, AppBar } from '@material-ui/core';
import Img from 'components/Img';
import { FormattedMessage } from 'react-intl';
import whiteIcon from '../../../images/logos/Jadesquare-logo-white.svg';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import A from 'components/A';
import makeSelectRegister from '../selectors';
const stateSelector = createStructuredSelector({
  register: makeSelectRegister(),
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 120px)',
      color: 'var(--color-grey)',
    },
    wrapper: {
      border: '1px solid var(--color-medium-dark-grey)',
      padding: '20px',
    },
    title: {
      fontSize: '1.5rem',
      textAlign: 'center',
      color: 'white',
    },
    subTitle: {
      marginTop: '5rem',
      fontSize: '1rem',
      textAlign: 'center',
    },
  }),
);
interface Props {
  scope: string;
}

function RegisterSuccess(props: Props) {
  const { scope } = props;
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const { register } = useSelector(stateSelector);
  const { profile } = register;

  const localScope = `${scope}.components.success`;

  return (
    <div>
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={12} lg={8} xl={7}>
          <div className={classes.wrapper}>
            <Typography className={classes.title}>
              <FormattedMessage id={`${localScope}.dear`} />
              <b>{profile ? `${profile.firstName} ${profile.lastName}` : ''}</b>
              <FormattedMessage id={`${localScope}.accountSuccess`} />
            </Typography>

            <Typography className={classes.subTitle}>
              <FormattedMessage id={`${localScope}.fromJadeSquarePolicy`} />{' '}
              <br />
              <FormattedMessage id={`${localScope}.contactUs`} />
              <A href="mailto:info@jadesquare.com">info@jadesquare.com</A>
              <FormattedMessage id={`${localScope}.ifYouHaveQuestion`} />
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(RegisterSuccess);
