/*
 *
 * ProfileVehicles constants
 *
 */

enum ActionTypes {
  FETCH_USER_VEHICLE = 'app/ProfileVehicles/FETCH_USER_VEHICLE',
  FETCH_USER_VEHICLE_SUCCESS = 'app/ProfileVehicles/FETCH_USER_VEHICLE_SUCCESS',
  FETCH_USER_VEHICLE_FAILED = 'app/ProfileVehicles/FETCH_USER_VEHICLE_FAILED',
  UPDATE_USER_VEHICLE = 'app/ProfileVehicles/UPDATE_USER_VEHICLE',
  UPDATE_USER_VEHICLE_SUCCESS = 'app/ProfileVehicles/UPDATE_USER_VEHICLE_SUCCESS',
  UPDATE_USER_VEHICLE_FAILED = 'app/ProfileVehicles/UPDATE_USER_VEHICLE_FAILED',
  CREATE_USER_VEHICLE = 'app/ProfileVehicles/CREATE_USER_VEHICLE',
  CREATE_USER_VEHICLE_SUCCESS = 'app/ProfileVehicles/CREATE_USER_VEHICLE_SUCCESS',
  CREATE_USER_VEHICLE_FAILED = 'app/ProfileVehicles/CREATE_USER_VEHICLE_FAILED',
}

export default ActionTypes;
