/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: '#888888',
      fontSize: '0.85rem',
      margin: theme.spacing(2, 0),
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  noOfDeal: number;
}

function DealHeader(props: Props) {
  const { noOfDeal } = props;
  const classes = useStyles();
  const localScope = 'DealList.header';
  return (
    <div className={classes.title}>
      <FormattedMessage id={`thereAreCurrently`} />
      <span className={classes.amount}>
        {noOfDeal}{' '}
        <FormattedMessage
          id={`${localScope}.deal`}
          values={{ num: noOfDeal }}
        />
      </span>
    </div>
  );
}

export default memo(DealHeader);
