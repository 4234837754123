/*
 *
 * DealFlow
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { Location } from 'history';

import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import { Grid, Card, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorToast from 'components/ErrorToast';
import SearchInput from 'components/SearchInput';
import AlertDialog from 'components/AlertDialog/alertDialog';
import DealTable from './components/dealTable';
import { cancelInvite } from './actions';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { PAGE_SIZE } from 'utils/constants';
import Filter from './components/filter';
import ListDealsInvestorQuery from 'gql/deal/ListDealsInvestor.gql';
import { FormattedMessage, useIntl } from 'react-intl';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

const useStyles = makeStyles(() => ({
  topContainer: {
    marginTop: 16,
  },
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
    border: '1px solid #E5E5E5',
  },
  buttonContainer: {
    padding: 16,
  },
  card: {
    padding: '1rem',
    border: '1px solid var(--color-super-dark-grey)',
    borderRadius: 3,
    color: 'var(--color-grey)',
  },
}));

interface Props {
  location: Location;
}

function DealFlow(props: Props) {
  const intl = useIntl();
  useInjectSaga({ key: 'dealFlow', saga: saga });
  const { location } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(stateSelector);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [investStatusFilter, setInvestStatusFilter] = useState<string>('All');
  const [open, setOpen] = useState(false);
  const [dealCancel, setDealCancel] = useState<any>({});
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);

  const LANGUAGE_CODE = user?.languageCode ? user.languageCode : 'en';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamPage = Number(searchParams.get('page'));
    const searchParamStatus = searchParams.get('status');

    setInvestStatusFilter(
      searchParamStatus === null ? 'All' : searchParamStatus,
    );

    if (searchParamPage) {
      refreshPageStateBasedOnURL(searchParamPage);
    }
  }, []);

  useEffect(() => {
    refreshURLBasedOnPageState();
  }, [page]);

  const refreshPageStateBasedOnURL = searchParamPage => {
    if (page !== searchParamPage) {
      setPage(searchParamPage);
    }
  };

  const refreshURLBasedOnPageState = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamPage = Number(searchParams.get('page'));

    if (page !== searchParamPage) {
      searchParams.set('page', String(page));
      dispatch(replace(`${location.pathname}?${searchParams.toString()}`));
    }
  };

  const { loading, error, data, refetch } = useQuery(ListDealsInvestorQuery, {
    variables: {
      searchKey: `%${searchKey}%`,
      investStatusFilter: `${
        investStatusFilter === 'All' ? '' : investStatusFilter
      }%`,
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      userId: user ? user.userId : '',
      language: LANGUAGE_CODE,
    },
  });

  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);

  const cancelInvitation = deal => {
    setDealCancel(deal);
    setOpen(true);
  };

  const onCancelInvitation = () => {
    dispatch(
      cancelInvite({
        dealId: dealCancel.dealId,
        search: {
          searchKey,
          page,
          pageSize: PAGE_SIZE,
          orders: { 'data.deal.refClosingDate': 'asc' },
          userId: user ? user.userId : '',
        },
      }),
    );
    setTimeout(refetch, 2000);
    setOpen(false);
    setPage(1);
  };

  const onChangeSearchKey = value => {
    if (isFirstPageLoad) {
      setIsFirstPageLoad(false);
    } else {
      setPage(1);
    }
    setSearchKey(value);
  };

  const onChangeStatus = value => {
    setPage(1);
    setInvestStatusFilter(value);
  };

  const updateSuccess = () => {
    setPage(1);
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Container className="main">
      <div>
        <ErrorToast
          isOpen={Boolean(error)}
          message={error ? error.message : ''}
        />
        <div className={classes.topContainer}>
          <Card variant="outlined">
            <SearchInput onChange={onChangeSearchKey} />
            <Filter
              onChangeStatus={onChangeStatus}
              status={investStatusFilter}
            />
          </Card>
        </div>
        <div>
          {!error && !loading && (
            <DealTable
              items={data.deal_investor.map(item => {
                return { ...item.deal };
              })}
              total={data.deal_investor_aggregate.aggregate.count}
              fetchPage={setPage}
              updateSuccess={updateSuccess}
              cancelInvite={cancelInvitation}
              page={page}
            />
          )}
        </div>
        <AlertDialog
          title={`${intl.messages[`cancelInvitation`]}`}
          open={open}
          description={
            <Typography>
              <FormattedMessage id="areYouSureToRemoveThisDealFromYourInterest" />
            </Typography>
          }
          onCancel={() => setOpen(false)}
          onConfirm={onCancelInvitation}
        />
      </div>
    </Container>
  );
}

export default memo(DealFlow);

{
  /* <Grid container justify="center" spacing={4}>
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? error.message : ''}
      />
      <Grid item xs={12} md={10} className={classes.topContainer}>
        <Card variant="outlined" className={classes.card}>
          <SearchInput onChange={onChangeSearchKey} />
        </Card>
      </Grid>
      <Grid item xs={12} md={10}>
        <Card variant="outlined" className={classes.card}>
          <Filter onChangeStatus={onChangeStatus} status={investStatusFilter} />
        </Card>
      </Grid>
      <Grid item xs={12} md={10}>
        {!error && !loading && (
          <DealTable
            items={data.deal_investor.map(item => {
              return { ...item.deal };
            })}
            total={data.deal_investor_aggregate.aggregate.count}
            fetchPage={setPage}
            updateSuccess={updateSuccess}
            cancelInvite={cancelInvitation}
            page={page}
          />
        )}
      </Grid>
      <AlertDialog
        title={`${intl.messages[`cancelInvitation`]}`}
        open={open}
        description={
          <Typography>
            <FormattedMessage id="areYouSureToRemoveThisDealFromYourInterest" />
          </Typography>
        }
        onCancel={() => setOpen(false)}
        onConfirm={onCancelInvitation}
      />
    </Grid>
    */
}
