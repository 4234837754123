/*
 *
 * DocumentSettings
 *
 */

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectDocumentSettings from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Container,
} from '@material-ui/core';
import SearchInput from 'components/SearchInput';
import Filter from './components/templateFilter';
import TemplateList from './components/templateTable';
import Loading from 'components/Loading';
import { fetchTemplateAction } from './actions';
import TemplateCreateDialog from 'containers/TemplateCreateDialog';
import { PAGE_SIZE } from 'utils/constants';
import { SigningTemplate } from 'models/signing-template';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
  }),
);

const stateSelector = createStructuredSelector({
  documentSettings: makeSelectDocumentSettings(),
});

interface Props {}

function DocumentSettings(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'documentSettings', reducer: reducer });
  useInjectSaga({ key: 'documentSettings', saga: saga });
  const [open, setOpen] = React.useState<any>(false);
  const [filter, setFilter] = React.useState<any>(['ACTIVE', 'INACTIVE']);
  const [key, setKey] = React.useState<any>('');
  const [selectedItem, setSelectedItem] = React.useState<SigningTemplate>();
  const { documentSettings } = useSelector(stateSelector);
  const { templates, total, refreshPage, loading } = documentSettings;
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleSearch = value => {
    setKey(value);
    dispatch(
      fetchTemplateAction({
        filter,
        searchKey: value,
        page: 0,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  const handleFilterChanged = value => {
    setFilter(value);
    dispatch(
      fetchTemplateAction({
        filter: value,
        searchKey: key,
        page: 0,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  const handleFetchPage = page => {
    dispatch(
      fetchTemplateAction({
        filter,
        searchKey: key,
        page: page - 1,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  useEffect(() => {
    if (!refreshPage) {
      setOpen(false);
      dispatch(
        fetchTemplateAction({
          filter,
          searchKey: key,
          page: 0,
        }),
      );
    }
  }, [refreshPage]);
  return (
    <Container className="main">
      <div>
        <h2>
          {' '}
          <FormattedMessage id="documentSettings" />{' '}
        </h2>
      </div>
      <div className={classes.container} style={{ marginBottom: 12 }}>
        <SearchInput onChange={handleSearch} />
      </div>

      <div className={classes.container}>
        <Filter onChange={handleFilterChanged} />
      </div>
      <div className={classes.container}>
        <TemplateList
          items={templates}
          total={total}
          onOpen={item => {
            setSelectedItem(item);
            setOpen(true);
          }}
          fetchPage={handleFetchPage}
        />
      </div>
      <TemplateCreateDialog
        open={open}
        item={selectedItem}
        onCancel={() => {
          setOpen(false);
          setSelectedItem(undefined);
        }}
      />
      {loading && <Loading fullScreen />}
    </Container>
  );
}

export default DocumentSettings;
