import { Chip, FormControl, PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo, useEffect } from 'react';
import CustomFormLabel from '../../../components/FormControl/components/form-label';
import CustomHelperText from '../../../components/FormControl/components/helper-text';
import {
  FormState,
  initialFormState,
} from '../../../components/FormControl/state';

export interface ChipValue {
  id: string;
  label: string;
}

export interface ChipsState extends FormState {
  ids: string[];
}

export const initialChipsState: ChipsState = {
  ids: [],
  ...initialFormState,
};

interface Props {
  formState: ChipsState;
  chips: ChipValue[];
  onChange: (ev) => void;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth: boolean;
}

FormChips.defaultProps = {
  fullWidth: true,
};

const useStyles = makeStyles(theme => ({
  chipList: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function FormChips(props: Props) {
  const styles = useStyles(props);
  const {
    formState,
    chips,
    onChange,
    title,
    subtitle,
    margin,
    fullWidth,
  } = props;

  const [selectedChips, setSelectedChips] = React.useState<string[]>(
    formState.ids,
  );

  useEffect(() => {
    setSelectedChips([...formState.ids]);
  }, [formState]);

  function isSelectedChip(id: string): boolean {
    if (!id) {
      return false;
    }
    return selectedChips.findIndex(chip => chip === id) >= 0;
  }

  const handleClickChip = chipId => event => {
    if (!chipId || chipId < 0) {
      return;
    }
    const selecting = selectedChips;
    const chipIndex = selecting.findIndex(chip => chip === chipId);
    if (chipIndex >= 0) {
      selecting.splice(chipIndex, 1);
    } else {
      selecting.push(chipId);
    }
    setSelectedChips(selecting);
    onChange(selecting);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <CustomFormLabel title={title} subtitle={subtitle} />

      <div className={styles.chipList}>
        {chips.map((chip, index) => (
          <Chip
            variant={isSelectedChip(chip.id) ? 'default' : 'outlined'}
            key={index}
            label={chip.label}
            size={'medium'}
            onClick={handleClickChip(chip.id)}
          />
        ))}
      </div>

      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(FormChips);
