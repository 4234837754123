import React, { memo } from 'react';
import {
  Card,
  Grid,
  Button,
  Link,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import Table from 'components/Table';
import { Ticket } from 'models/ticket';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    tableCell10: {
      width: '10%',
    },
    tableCell15: {
      width: '15%',
    },
    tableCell20: {
      width: '20%',
    },
    title: {
      color: '#888888',
      fontSize: '0.85rem',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  total: number;
  tickets?: Ticket[];
  fetchPage: (value: number) => void;
  onItemClick: (item: Ticket) => void;
  scope: string;
}

const numberWithCommas = num => {
  if (!num) {
    return '';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getStatus = (status, messages) => {
  switch (status) {
    case 'SUBMITTED':
      return (
        <b
          style={{  fontWeight: 600 }}
        >{`${messages['submitted']}`}</b>
      );
    case 'CANCELLED':
      return (
        <b
          style={{ color: '#888888', fontWeight: 600 }}
        >{`${messages['cancelled']}`}</b>
      );
    case 'REJECTED':
      return (
        <b
          style={{ color: '#D74F4F', fontWeight: 600 }}
        >{`${messages['rejected']}`}</b>
      );
    case 'FUNDS_NOT_TRANSFERRED':
      return (
        <b style={{ color: '#D74F4F', fontWeight: 600 }}>
          {`${messages['fundsNotTransferred']}`}
        </b>
      );
    case 'APPROVED':
      return (
        <b
          style={{ color: 'var(--color-purple)', fontWeight: 600 }}
        >{`${messages['approved']}`}</b>
      );
    case 'FUNDS_REQUESTED':
      return (
        <b
          style={{ color: 'var(--color-purple)', fontWeight: 600 }}
        >{`${messages['fundsRequested']}`}</b>
      );
    case 'FUNDS_TRANSFERRED':
      return (
        <b
          style={{ color: 'var(--color-cyan)', fontWeight: 600 }}
        >{`${messages['fundsTransfedde']}`}</b>
      );
    default:
      return '';
  }
};

function TicketsTable(props: Props) {
  const intl = useIntl();
  const { total, tickets, fetchPage, onItemClick, scope } = props;

  const [page, setPage] = React.useState(1);
  const classes = useStyles();

  const headers: any[] = [
    {
      name: `${intl.messages['dealName']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['vehicle']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['amount']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['status']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['creation']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['modification']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['issuer']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    { name: '', headerClass: classes.tableCell10 },
  ];

  const handleFetchPage = currentPage => {
    setPage(currentPage);
    fetchPage(currentPage);
  };

  const actionButton = (index, item) => {
    return (
      <Button
        key={index}
        size={'small'}
        variant="outlined"
        color="primary"
        onClick={() => onItemClick(item)}
      >
        <FormattedMessage id="edit" />
      </Button>
    );
  };

  const localScope = `${scope}.TicketsTable`;

  const renderItem = (item, index) => {
    return [
      <Link
        style={{ color: 'var(--color-purple)', fontWeight: 600 }}
        href={`/deals/${item.deal.dealId}`}
      >
        {item.deal.name}
      </Link>,
      <Typography key={index}>
        {item.vehicle.vehicleType === 'CORPORATE'
          ? item.vehicle.corporateName
          : `${intl.messages['individualVehicle']}`}
      </Typography>,
      <Typography style={{ fontWeight: 600 }}>
        {`$${numberWithCommas(item.amount)}`}
      </Typography>,
      <Typography>{getStatus(item.status, intl.messages)}</Typography>,
      <Typography>
        {`${moment(item.createdDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      <Typography>
        {`${moment(item.modifiedDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      <Typography>
        {item.issuer.userType === 'ADMIN'
          ? `${intl.messages['admin']} - ${item.issuer.fullName}`
          : `${intl.messages['investor']}`}
      </Typography>,
      actionButton(index, item),
    ];
  };

  const renderItemInvestor = (item, index) => {
    return [
      <Typography key={index}>
        {item.vehicle.vehicleType === 'CORPORATE'
          ? item.vehicle.corporateName
          : `${intl.messages['individualVehicle']}`}
      </Typography>,
      <Typography>{item.investorApproach}</Typography>,
      <Typography style={{ fontWeight: 600 }}>
        {`$${numberWithCommas(item.amount)}`}
      </Typography>,
      <Typography>{getStatus(item.status, intl.messages)}</Typography>,
      <Typography>
        {`${moment(item.createdDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      <Typography>
        {`${moment(item.modifiedDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      actionButton(index, item),
    ];
  };

  return (
    <div>
      <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {total}{' '}
            <FormattedMessage
              id={`Tickets.TicketsTable.matchedTickets`}
              values={{ num: total }}
            />
          </span>
        </Typography>
      <Table
        headers={headers}
        items={tickets}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
        currentPage={page}
      />
    </div>
  );
}

export default memo(TicketsTable);
