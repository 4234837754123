import React, { useEffect, useState } from 'react';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from '@draft-js-plugins/buttons';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createVideoPlugin from '@draft-js-plugins/video';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import { makeStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import VideoIcon from '@material-ui/icons/Videocam';
import './styles.css';
import ErrorToast from '../ErrorToast';
import { uploadUrls, uploadFileToS3 } from 'services/api/file-service';
import { BACKEND_API_URL } from './../../env';

interface Props {
  editorState: any;
  onChange: any;
}

const useStyle = makeStyles({
  editorContainer: {
    marginTop: 10,
    marginBottom: 10,
    boxShadow: '0px 1px 3px 0px rgb(220 220 220)',
  },
  editor: {
    padding: 15,
    minHeight: '30vh',
    maxHeight: '50vh',
    overflow: 'scroll',
  },
});

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

const linkPlugin = createLinkPlugin();
const resizeablePlugin = createResizeablePlugin({
  horizontal: 'absolute',
  initialWidth: 'auto',
  initialHeight: 'auto',
});
const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);

const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin();

const plugins = [
  staticToolbarPlugin,
  linkPlugin,
  imagePlugin,
  resizeablePlugin,
  focusPlugin,
  videoPlugin,
  blockDndPlugin,
];

const HtmlEditor = (props: Props): JSX.Element => {
  const classes = useStyle();
  const { editorState, onChange } = props;
  const [addVideoUrl, setAddVideoUrl] = useState('');
  const [isAddVideoUrlOpen, setIsAddVideoUrlOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsgCode, setErrorMsgCode] = useState('');

  const imageInputAccept = 'image/gif,image/jpeg,image/jpg,image/png,image/svg';

  let editor;
  let hiddenImageInput;

  const setErrorMessage = msgCode => {
    setErrorMsgCode(msgCode);
    setIsError(true);
  };

  const focus = () => {
    editor.focus();
  };

  const onChangeAddVideoUrl = event => {
    setAddVideoUrl(event.target.value);
  };

  const onKeyDownAddVideoUrl = event => {
    if (isAddVideoUrlOpen) {
      if (event.key === 'Enter') {
        event.preventDefault();
        // @ts-ignore
        const newEditorState = videoPlugin.addVideo(editorState, {
          src: addVideoUrl,
        });
        onChange(newEditorState);
        setIsAddVideoUrlOpen(false);
        setAddVideoUrl('');
      } else if (event.key === 'Escape') {
        event.preventDefault();
        setIsAddVideoUrlOpen(false);
        setAddVideoUrl('');
      }
    }
  };

  const uploadImages = images => {
    const imgList = [...images];
    if (imgList.some(img => imageInputAccept.indexOf(img.type) === -1)) {
      setErrorMessage('onlyImageFiles');
      return;
    }

    console.log(`Adding images: ${imgList.map(i => i.name).join(', ')}`);
    // @ts-ignore
    uploadUrls({ files: imgList.map(file => ({ name: file.name })) })
      .then(urlResp => {
        console.log(`Upload urls: ${urlResp.map(r => r.url).join(', ')}`);
        uploadFileToS3(
          urlResp.map(resp => `${resp.url}&folder=pictures`),
          imgList,
        )
          .then(() => {
            let newEditorState = editorState;

            urlResp.forEach(resp => {
              console.log(`Adding image: ${resp.name} ${resp.fileId}`);
              newEditorState = imagePlugin.addImage(
                newEditorState,
                `${BACKEND_API_URL}files/pictures/${resp.fileId}/${resp.name}`,
                {},
              );
            });

            onChange(newEditorState);
          })
          .catch(uploadResp => {
            console.error(`Upload failed: ${JSON.stringify(uploadResp)}`);
            setErrorMessage('unexpectedError');
          });
      })
      .catch(urlResp => {
        console.error(`Upload failed: ${JSON.stringify(urlResp)}`);
        setErrorMessage('unexpectedError');
      });
  };

  const handleInputImage = ev => {
    uploadImages(ev.target.files);
  };

  const handlePastedFiles = files => {
    uploadImages(files);
  };

  const handleDroppedFiles = (selection, files) => {
    uploadImages(files);
  };

  return (
    <div className={classes.editorContainer}>
      <ErrorToast
        isOpen={isError}
        message={errorMsgCode}
        handleClose={() => {
          setIsError(false);
          setErrorMsgCode('');
        }}
      />
      <Toolbar>
        {externalProps => (
          <div>
            {!isAddVideoUrlOpen && (
              <>
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <Separator />
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <CodeButton {...externalProps} />
                <Separator />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
                <Separator />
                <linkPlugin.LinkButton {...externalProps} />
                <div className={externalProps.theme.buttonWrapper}>
                  <input
                    ref={ref => (hiddenImageInput = ref)}
                    type="file"
                    name="file"
                    multiple={true}
                    onChange={handleInputImage}
                    style={{ display: 'none' }}
                    accept={imageInputAccept}
                  />
                  <button
                    className={externalProps.theme.button}
                    onClick={() => hiddenImageInput.click()}
                  >
                    <ImageIcon />
                  </button>
                </div>
                <div className={externalProps.theme.buttonWrapper}>
                  <button
                    className={externalProps.theme.button}
                    onClick={() => {
                      setIsAddVideoUrlOpen(true);
                    }}
                  >
                    <VideoIcon />
                  </button>
                </div>
              </>
            )}
            {isAddVideoUrlOpen && (
              <input
                className="i1qh9dya i119ugvj"
                placeholder="Enter a URL and press enter"
                type="text"
                onChange={onChangeAddVideoUrl}
                onKeyDown={onKeyDownAddVideoUrl}
                value={addVideoUrl}
                onBlur={() => {
                  setIsAddVideoUrlOpen(false);
                }}
              />
            )}
          </div>
        )}
      </Toolbar>
      <div
        className={classes.editor}
        onClick={focus}
        onDragOver={e => {
          e.preventDefault();
        }}
        onDrop={e => {
          e.preventDefault();
        }}
      >
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          ref={element => {
            editor = element;
          }}
          handlePastedFiles={handlePastedFiles}
          handleDroppedFiles={handleDroppedFiles}
          blockStyleFn={contentBlock => {
            const type = contentBlock.getType();
            if (type === 'atomic') {
              return 'draftjs-block-align-center';
            }
            return null;
          }}
        />
      </div>
    </div>
  );
};

export default HtmlEditor;
