/*
 *
 * TicketCreate
 *
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  DialogActions,
  Button,
  FormControlLabel,
  Input,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import Select from 'components/Select';
import NumberFormat from 'react-number-format';
import { submitAction, clear, fetchUserVehicleAction } from './actions';
import { Ticket } from 'models/ticket';
import makeSelectTicketEdit from './selectors';
import CloseIcon from '@material-ui/icons/Close';
import { setIsLoading } from 'containers/Home/actions';
const useStyles = makeStyles(() => ({
  title: {
    paddingTop: 4,
    fontSize: '1.25rem',
  },
  errorInput: {
    width: '100%',
    borderColor: '#D74F4F',
  },
  input: {
    width: '100%',
  },
  reminder: {
    width: '100%',
    marginTop: 12,
  },
  reminderTitle: {
    fontWeight: 'bold',
  },
  reminderSub: {
    fontWeight: 'bold',
  },

  label: { marginTop: 12 },
  requiredText: {
    color: '#D74F4F',
  },
  border: {},
  titleColor: {},
}));

const stateSelector = createStructuredSelector({
  ticketEdit: makeSelectTicketEdit(),
});

interface Props {
  ticket?: Ticket;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function TicketEditDialog(props: Props): JSX.Element {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'ticketEdit', reducer: reducer });
  useInjectSaga({ key: 'ticketEdit', saga: saga });
  const { open, onClose, onSuccess, ticket } = props;
  const { deal, investor, vehicle } = ticket || {};
  const [vehicleId, setVehicleId] = React.useState<string | undefined>(
    vehicle ? vehicle.vehicleId : undefined,
  );
  const [vehicleItems, setVehicleItems] = React.useState<any[]>([]);
  const [statusList, setStatusList] = React.useState<any[]>([]);
  const [sendMail, setSendMail] = React.useState(true);
  const { ticketEdit } = useSelector(stateSelector);
  const { success, error, userVehicles, loading } = ticketEdit;
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    if (vehicle) {
      setVehicleId(vehicle.vehicleId);
    }
  }, [vehicle]);

  useEffect(() => {
    if (investor) {
      dispatch(fetchUserVehicleAction(investor.userId));
    }
  }, [investor]);

  useEffect(() => {
    if (ticket) {
      switch (ticket.status) {
        case 'SUBMITTED':
          setStatusList([
            {
              key: 'SUBMITTED',
              value: `${intl.messages['submit']}`.toUpperCase(),
            },
            {
              key: 'APPROVED',
              value: `${intl.messages['approve']}`.toUpperCase(),
            },
            {
              key: 'REJECTED',
              value: `${intl.messages['reject']}`.toUpperCase(),
            },
          ]);
          break;
        case 'APPROVED':
          setStatusList([
            {
              key: 'APPROVED',
              value: `${intl.messages['approve']}`.toUpperCase(),
            },
            {
              key: 'FUNDS_REQUESTED',
              value: `${intl.messages['requestFunds']}`.toUpperCase(),
            },
            {
              key: 'REJECTED',
              value: `${intl.messages['reject']}`.toUpperCase(),
            },
          ]);
          break;
        case 'FUNDS_REQUESTED':
          setStatusList([
            {
              key: 'FUNDS_REQUESTED',
              value: `${intl.messages['fundsRequest']}`.toUpperCase(),
            },
            {
              key: 'FUNDS_TRANSFERRED',
              value: `${intl.messages['retrieveFunds']}`.toUpperCase(),
            },
            {
              key: 'FUNDS_NOT_TRANSFERRED',
              value: `${intl.messages['notRetrieveFunds']}`.toUpperCase(),
            },
          ]);
          break;
        default:
          setStatusList([]);
      }
    }
  }, [ticket]);

  useEffect(() => {
    const items = userVehicles
      ? userVehicles.map(val => {
        if (val.vehicleType === 'INDIVIDUAL') {
          if (!vehicleId) {
            setVehicleId(val.vehicleId);
          }
          return {
            value: `${intl.messages['individual']}`,
            key: val.vehicleId,
          };
        } else {
          return { value: `${val.corporateName}`, key: val.vehicleId };
        }
      })
      : [];
    setVehicleItems(items);
  }, [userVehicles]);

  const investorType = [
    { value: `${intl.messages['financial']}`, key: 'FINANCIAL' },
    { value: `${intl.messages['strategic']}`, key: 'STRATEGIC' },
  ];

  const tokenIntent = [
    { value: `${intl.messages['tokenIntent.token']}`, key: 'token' },
    { value: `${intl.messages['tokenIntent.standard']}`, key: 'standard' },
  ];

  const [request, setRequest] = useState<any>({
    dealId: deal ? deal.dealId : 1,
    vehicleId: vehicleId,
    investorApproach: 'FINANCIAL',
    minValue: deal ? deal.minTicket : 0,
    maxValue: deal ? deal.maxTicket : 0,
    tokenIntent: 'standard',
  });

  useEffect(() => {
    setRequest(pre => {
      return {
        ...pre,
        vehicleId: vehicleId,
        dealId: deal ? deal.dealId : undefined,
        minValue: deal ? deal.minTicket : 0,
        maxValue: deal ? deal.maxTicket : 0,
      };
    });
  }, [deal]);

  useEffect(() => {
    if (ticket) {
      setRequest(pre => {
        return {
          ...pre,
          vehicleId: vehicleId,
          ticketId: ticket.ticketId,
          amount: ticket.amount,
          dealId: deal ? deal.dealId : undefined,
          minValue: deal ? deal.minTicket : 0,
          maxValue: deal ? deal.maxTicket : 0,
          tokenIntent: ticket.tokenIntent,
        };
      });
    }
  }, [ticket]);

  const handleSelectVehicle = val => {
    setVehicleId(val.target.value);
    setRequest(pre => {
      return { ...pre, vehicleId: val.target.value };
    });
  };

  const handleSelectInvestorApproach = val => {
    setRequest(pre => {
      return { ...pre, investorApproach: val.target.value };
    });
  };
  const handleSelectTokenIntent = val => {
    setRequest(pre => {
      return { ...pre, tokenIntent: val.target.value };
    });
  };

  const handleSelectStatus = val => {
    setRequest(pre => {
      return { ...pre, status: val.target.value };
    });
  };

  const submit = () => {
    dispatch(setIsLoading(true));
    dispatch(
      submitAction({
        ...request,
        sendMail: sendMail && ticket && ticket.status !== request.status,
        oldStatus: ticket ? ticket.status : '',
      }),
    );
  };

  const onChanged = val => {
    const value = val.target.value
      .replace('$', '')
      .split(',')
      .join('');
    setRequest(pre => {
      return { ...pre, amount: value };
    });
  };

  useEffect(() => {
    if (success) {
      onSuccess();
    }
  }, [success]);

  useEffect(() => {
    if (!open) {
      dispatch(clear());
      // setRequest({
      //   dealId: deal ? deal.dealId : 1,
      //   vehicleId: vehicleId,
      //   investorApproach: 'FINANCIAL',
      //   minValue: deal ? deal.minTicket : 0,
      //   maxValue: deal ? deal.maxTicket : 0,
      // });
    }
  }, [open]);
  const scope = 'TicketEdit';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{ square: true }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          alignItems="center"
          spacing={2}
          className={classes.border}
        >
          <Grid item xs>
            <Typography className={`${classes.title}`}>
              <FormattedMessage id={`${scope}.title`} />
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className={classes.titleColor} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage id="investor" />
        </Typography>
        <Typography>{investor ? investor.fullName : ''}</Typography>
        <Typography className={classes.label}>
          <FormattedMessage id="vehicle" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <Select
          value={vehicleId}
          // defaultValue={defaultVehicle}
          items={vehicleItems}
          disabled
          onChange={handleSelectVehicle}
          className={classes.input}
        />
        <Typography className={classes.label}>
          <FormattedMessage id="investorType" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <Select
          defaultValue={ticket ? ticket.investorApproach : 'FINANCIAL'}
          items={investorType}
          onChange={handleSelectInvestorApproach}
          className={classes.input}
        />
        <Typography className={classes.label}>
          <FormattedMessage id="tokenIntentQuestion" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <Select
          defaultValue={ticket ? 'standard' : 'standard'}
          items={tokenIntent}
          onChange={handleSelectTokenIntent}
          className={classes.input}
          disabled={true}
        />
        <Typography className={classes.label}>
          <FormattedMessage id="status" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        {statusList.length !== 0 ? (
          <Select
            defaultValue={ticket ? ticket.status : undefined}
            items={statusList}
            onChange={handleSelectStatus}
          />
        ) : (
          <Input
            value={
              ticket
                ? ticket.status
                  .split('_')
                  .join(' ')
                  .toLowerCase()
                : ''
            }
            className={classes.input}
            disabled
            disableUnderline
          />
        )}
        <Typography className={classes.label}>
          <FormattedMessage id="ticketAmount" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <NumberFormat
          defaultValue={ticket ? ticket.amount : 0}
          thousandSeparator={true}
          prefix={'$'}
          className={`${error.ticketAmount ? classes.errorInput : classes.input
            }`}
          onChange={onChanged}
          customInput={Input}
          disableUnderline
        />
        {error.ticketAmount && (
          <Typography className={classes.requiredText}>
            {intl.formatMessage(error.ticketAmount[0])}
          </Typography>
        )}
        <Grid container className={classes.reminder}>
          <Grid item xs={12}>
            <Typography className={classes.reminderTitle}>
              <FormattedMessage id="ticketAmountReminder" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="minValue" />
            </Typography>
            <NumberFormat
              value={deal ? deal.minTicket : 0}
              thousandSeparator={true}
              prefix={'$'}
              displayType={'text'}
              onChange={onChanged}
              className={classes.reminderSub}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="maxValue" />
            </Typography>
            <NumberFormat
              value={deal ? deal.maxTicket : 0}
              thousandSeparator={true}
              prefix={'$'}
              displayType={'text'}
              onChange={onChanged}
              className={classes.reminderSub}
            />
          </Grid>
        </Grid>
        {ticket && ticket.status !== request.status && request.status && (
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={sendMail}
                  onChange={e => setSendMail(e.currentTarget.checked)}
                />
              }
              label={
                <Typography>
                  <FormattedMessage id="sendInfoEmailToInvestor" />
                </Typography>
              }
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          {`${intl.messages['cancel']}`.toUpperCase()}
        </Button>
        <Button
          style={{
            backgroundColor: 'var(--color-dark-purple)',
            color: 'var(--color-light-grey)',
          }}
          variant="contained"
          onClick={submit}
          // onClick={() => {
          //   console.log(request);
          // }}
          disabled={loading}
        >
          {`${intl.messages['commit']}`.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TicketEditDialog;
