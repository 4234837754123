/*
 *
 * ProfileVehicleCreateDialog
 *
 */

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectProfileVehicleCreateDialog from './selectors';
import reducer from './reducer';
import saga from './saga';
import CorporateVehicle from './components/corporateVehicle';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import Img from 'components/Img';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Vehicle from 'models/vehicle';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  updateAction,
  submitUpdateAction,
  createAction,
  initVehicle,
  updateDocumentAction,
  clearInfoAction,
} from './actions';
import UserData from 'models/user';
import Loader from 'components/Loading';
const stateSelector = createStructuredSelector({
  profileVehicleCreateDialog: makeSelectProfileVehicleCreateDialog(),
});

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#000',
  },
  input: {
    width: '100%',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    border: '1px solid #bdbfc3',
  },
  container: {
    padding: 12,
  },
  title: {
    paddingTop: 4,
    fontSize: '1.2rem',
  },
  titleColor: {
    color: '#666666',
  },
  border: {
    borderBottom: '1px solid #e7e7e7',
  },
  image: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  deleteBtn: {
    margin: 8,
  },
  requireText: {
    color: '#D74F4F',
  },
  errorInput: {
    border: '1px solid #D74F4F',
  },
  errorText: {
    color: '#D74F4F',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  item?: Vehicle;
  investor: UserData;
}

function ProfileVehicleCreateDialog(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'profileVehicleCreateDialog', reducer: reducer });
  useInjectSaga({ key: 'profileVehicleCreateDialog', saga: saga });
  const classes = useStyles();
  const { open, onClose, item, investor } = props;
  const { profileVehicleCreateDialog } = useSelector(stateSelector);
  const { vehicle, loading } = profileVehicleCreateDialog;
  const dispatch = useDispatch();
  const onVehicleChanged = (type, value) => {
    dispatch(updateAction({ type: type, value: value }));
  };

  useEffect(() => {
    dispatch(clearInfoAction());
  }, [open]);

  useEffect(() => {
    if (investor) {
      if (item) {
        dispatch(initVehicle(item));
      } else {
        dispatch(
          initVehicle({
            vehicleType: 'CORPORATE',
            userId: investor.userId,
          }),
        );
      }
    }
  }, [item]);
  const onSubmit = () => {
    const request = { ...vehicle };
    if (!request.userId) {
      request.userId = investor.userId;
    }
    if (request && request.vehicleId) {
      if (request.document) {
        if (request.document.documentId) {
          dispatch(updateDocumentAction(request.document));
        } else {
          if (!request.documentGroups || request.documentGroups.length === 0) {
            request.documentGroups = [{ title: 'Default' }];
          }
          request.documentGroups[0].documents = [request.document];
        }
      }

      dispatch(
        submitUpdateAction({ request: request, vehicleId: vehicle.vehicleId }),
      );
    } else {
      dispatch(createAction(request));
    }
  };
  const scope = 'ProfileVehicleCreateDialog';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      PaperProps={{ square: true }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            {item && item.vehicleId ? (
              <FormattedMessage id={`${scope}.updateVehicle`} />
            ) : (
              <FormattedMessage id={`${scope}.createVehicle`} />
            )}
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <CorporateVehicle onChange={onVehicleChanged} vehicle={item} />
        <Grid
          item
          xs={12}
          container
          justify="flex-end"
          className={classes.container}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading && (
              <div style={{ marginRight: 12 }}>
                <Loader />{' '}
              </div>
            )}
            {item && item.vehicleId ? (
              <FormattedMessage id={'update'} />
            ) : (
              <FormattedMessage id={'create'} />
            )}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ProfileVehicleCreateDialog;
