import React, { memo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Select as MuiSelect,
  MenuItem,
  InputBase,
} from '@material-ui/core';

interface Item {
  key?: string;
  value?: string;
}
interface Props {
  items: Item[];
  [propName: string]: any;
}

const BootstrapInput = withStyles(() => ({
  root: {
    marginTop: 0,
    border: '1px solid var(--color-dark-grey)',
    borderRadius: '3px',
    minHeight: 25,
  },
  input: {
    position: 'relative',
    padding: '3px 6px',
  },
}))(InputBase);

function Select(props: Props) {
  const { items, disabled = false, ...rest } = props;

  return (
    <MuiSelect disabled={disabled} input={<BootstrapInput />} {...rest}>
      {items.map(element => {
        return (
          <MenuItem value={element.key} key={element.key}>
            {element.value}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
}

export default memo(Select);
