/*
 *
 * InviteInvestor
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import SearchInput from 'components/SearchInput';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { PAGE_SIZE } from 'utils/constants';
import InviteInvestor from 'models/invite-investor';
import InviteList from './components/inviteList';
import EmailForm from './components/emailForm';
import ErrorToast from 'components/ErrorToast';
import ListInvestorsQuery from 'gql/user/ListInvestors.gql';
import MultiMailMutation from 'gql/email/MultiMail.gql';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 12,
    textTransform: 'uppercase',
  },
  warning: {
    marginTop: 4,
    color: '#D74F4F',
  },
}));

interface Props {
  open: boolean;
  setOpen: (boolean) => void;
}

function InvestorSendEmailDialog(props: Props) {
  const { open, setOpen } = props;
  const [selectedUser, setSelecteduser] = React.useState<InviteInvestor[]>([]);
  const [searchKey, setSearchKey] = React.useState();
  const [page, setPage] = useState(1);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);
  const [sendError, setSendError] = useState(undefined);
  const classes = useStyles();

  const [multiMail] = useMutation(MultiMailMutation);

  const { loading, error, data, refetch } = useQuery(ListInvestorsQuery, {
    variables: {
      searchKey: `%${searchKey}%`,
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
  });

  useEffect(() => {
    setSendError(undefined);
    refetch();
  }, [open]);

  const onChanged = key => {
    if (isFirstPageLoad) {
      setIsFirstPageLoad(false);
    } else {
      setPage(1);
    }

    setSearchKey(key);
  };

  const handleSelectUser = (item: InviteInvestor) => {
    const isSelected =
      selectedUser.filter(sel => item.userId === sel.userId).length > 0;

    if (!isSelected) {
      setSelecteduser(pre => {
        return [...pre, item];
      });
    } else {
      setSelecteduser(pre => {
        return pre.filter(obj => obj.userId !== item.userId);
      });
    }
  };

  const sendEmail = formData => {
    const result = multiMail({
      variables: {
        body: formData.bodyText,
        investorIds: selectedUser.map(user => user.userId),
        subject: formData.subject,
        title: formData.bodyTitle,
        buttonUrl: formData.buttonURL,
        buttonTitle: formData.buttonTitle,
      },
    });

    result.then(
      () => {
        setIsEmailFormOpen(false);
        setOpen(false);
      },
      error => {
        setSendError(error.message);
      },
    );
  };

  const scope = 'InvestorSendEmail';
  return (
    <div>
      <ErrorToast
        isOpen={Boolean(error || sendError)}
        message={error ? error.message : sendError || ''}
      />
      <Dialog open={open} maxWidth="lg" fullWidth={true}>
        <DialogTitle id="alert-dialog-title">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <FormattedMessage id={'sendEmail'} />
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item style={{ padding: 12 }}>
              <SearchInput onChange={onChanged} />
            </Grid>
            {!loading && !error && (
              <Grid item style={{ padding: 12 }}>
                <InviteList
                  items={data.user}
                  total={data.user_aggregate.aggregate.count}
                  selected={selectedUser}
                  onSelect={handleSelectUser}
                  fetchPage={setPage}
                  page={page}
                  scope={scope}
                />
              </Grid>
            )}
            <Grid item className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={() => setOpen(false)}
              >
                <FormattedMessage id={'cancel'} />
              </Button>
              <Button style={{backgroundColor:"var(--color-dark-purple)", color:"var(--color-light-grey)"}}
             
                variant="contained"
                className={classes.button}
                onClick={() => setIsEmailFormOpen(true)}
                disabled={!selectedUser || selectedUser?.length === 0}
              >
                <FormattedMessage id={'next'} />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <EmailForm
        open={isEmailFormOpen}
        onUpdate={sendEmail}
        onCancel={() => setIsEmailFormOpen(false)}
        scope={scope}
      />
    </div>
  );
}

export default memo(InvestorSendEmailDialog);
