import { createService } from './axios';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function requestSigning(request: {
  eSignTemplateId: string;
  dealId: string;
  vehicleIds: string[];
  esignDocumentId?: string | undefined;
}) {
  return instance.post(`/e-sign/documents`, request).then(resp => {
    return resp.data.data;
  });
}

export async function refreshSignGroup(id) {
  return instance.get(`/documents/groups/${id}`).then(resp => {
    return resp.data.data;
  });
}

export async function refreshSignDocument(id) {
  return instance.get(`/documents/${id}`).then(resp => {
    return resp.data.data;
  });
}

export async function getPreviewUrl(request: {
  esignTemplateId: string;
  dealId: string;
  vehicleId: string;
}) {
  return instance.post('/e-sign/documents/preview', request).then(resp => {
    return resp.data.data;
  });
}
