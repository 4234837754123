import { call, put, takeLatest, select } from 'redux-saga/effects';
import { createTicket } from 'services/api/ticket-service';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import {
  submitSuccessAction,
  submitFailedAction,
  validateTicket,
} from './actions';
import makeSelectTicketCreate from './selectors';
import { setIsLoading } from 'containers/Home/actions';

export function* ticketCreateRequest(action) {
  yield put(validateTicket(action.payload));
  const data = yield select(makeSelectTicketCreate());
  if (data.error.isValid) {
    const request = action.payload;
    try {
      const result = yield call(createTicket, request);
      yield put(submitSuccessAction(result));
      yield put(
        showToast({
          message: 'ticketSuccessfullyCreated',
          type: 'success',
        }),
      );
    } catch (err) {
      yield put(submitFailedAction());
      yield put(
        showToast({
          message: err?.response?.data?.messages[0] ? err.response.data.messages[0] : 'unexpectedError',
          type: 'error',
        }),
      );
    } finally {
      yield put(setIsLoading(false));
      console.log('happened');
    }
  }
}

export default function* homeSage() {
  yield takeLatest(ActionTypes.SUBMIT_ACTION, ticketCreateRequest);
}
