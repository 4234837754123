// import { take, call, put, select } from 'redux-saga/effects';

// Individual exports for testing
import { call, put, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import ProfileResponse from '../../models/response/profile-response';
import { me } from '../../services/api/profile-service';
import * as actions from './actions';
import { showToast } from '../App/actions';

function* changeRegisterStep(action) {
  // console.log('SAGA: ' + JSON.stringify(action.payload));
}

function* fetchProfile(action) {
  try {
    const profile: ProfileResponse = yield call(me);

    yield put(actions.fetchProfileSuccessAction(profile));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* registerSaga() {
  yield takeLatest(ActionTypes.SET_STEP, changeRegisterStep);
  yield takeLatest(ActionTypes.FETCH_PROFILE_ACTION, fetchProfile);
}
