import React, { memo, useEffect } from 'react';
import {
  Button,
  Link,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import Table from 'components/Table';
import { Ticket } from 'models/ticket';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    tableCell10: {
      width: '10%',
    },
    tableCell15: {
      width: '15%',
    },
    tableCell20: {
      width: '20%',
    },
    title: {
      color: '#888888',
      fontSize: '0.85rem',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  total: number;
  tickets?: Ticket[];
  fetchPage: (value: number) => void;
  onItemClick: (item: Ticket) => void;
  isAdmin: boolean;
  page: number;
}

const numberWithCommas = num => {
  if (!num) {
    return '';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getStatus = (status, messages) => {
  switch (status) {
    case 'SUBMITTED':
      return <b style={{ fontWeight: 600 }}>{`${messages['submitted']}`}</b>;
    case 'CANCELLED':
      return (
        <b
          style={{ color: '#888888', fontWeight: 600 }}
        >{`${messages['cancelled']}`}</b>
      );
    case 'REJECTED':
      return (
        <b
          style={{ color: '#D74F4F', fontWeight: 600 }}
        >{`${messages['rejected']}`}</b>
      );
    case 'FUNDS_NOT_TRANSFERRED':
      return (
        <b style={{ color: '#D74F4F', fontWeight: 600 }}>
          {`${messages['fundsNotTransferred']}`}
        </b>
      );
    case 'APPROVED':
      return (
        <b
          style={{ color: 'var(--color-purple)', fontWeight: 600 }}
        >{`${messages['approved']}`}</b>
      );
    case 'FUNDS_REQUESTED':
      return (
        <b
          style={{ color: 'var(--color-purple)', fontWeight: 600 }}
        >{`${messages['fundsRequested']}`}</b>
      );
    case 'FUNDS_TRANSFERRED':
      return (
        <b
          style={{ color: 'var(--color-cyan)', fontWeight: 600 }}
        >{`${messages['fundsTransfedde']}`}</b>
      );
    default:
      return '';
  }
};

function TicketsTable(props: Props) {
  const { total, tickets, fetchPage, onItemClick, isAdmin, page } = props;
  const intl = useIntl();

  useEffect(() => {
    console.log(page);
  }, [page]);
  const classes = useStyles();

  const headers: any[] = [
    {
      name: `${intl.messages['investor']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['investmentType']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['vehicle']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['amount']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['status']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['creation']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['modification']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['issuer']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    { name: '', headerClass: classes.tableCell10 },
  ];

  const headersInvestor: any[] = [
    {
      name: `${intl.messages['vehicle']}`.toUpperCase(),
      headerClass: classes.tableCell20,
    },
    {
      name: `${intl.messages['investmentType']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['investorType']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['amount']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['status']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['creation']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['modification']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    { name: '', headerClass: classes.tableCell10 },
  ];

  const handleFetchPage = currentPage => {
    fetchPage(currentPage);
  };
  const localScope = 'Tickets.TicketsTable';
  const actionButton = (index, item) => {
    if (isAdmin) {
      return (
        <Button
          key={index}
          size={'small'}
          variant="outlined"
          color="primary"
          onClick={() => onItemClick(item)}
        >
          <FormattedMessage id="edit" />
        </Button>
      );
    } else {
      return (
        <Button
          key={index}
          size={'small'}
          variant="outlined"
          color="primary"
          disabled={item.status !== 'SUBMITTED'}
          onClick={() => onItemClick(item)}
        >
          <FormattedMessage id="cancel" />
        </Button>
      );
    }
  };

  const renderItem = (item, index) => {
    return [
      <Link
        style={{ color: 'var(--color-purple)', fontWeight: 600 }}
        href={`/investors/${item.investor.userId}`}
      >
        {item.investor.fullName}
      </Link>,
      <Typography
        key={index}
        style={{
          color:
            item.tokenIntent === 'token'
              ? 'var(--color-purple)'
              : 'var(--color-grey)',
          fontWeight: 600,
        }}
      >
        {item.tokenIntent === 'token'
          ? `${intl.messages['tokenIntent.token']}`
          : `${intl.messages['tokenIntent.standard']}`}
      </Typography>,
      <Typography key={index}>
        {item.vehicle.vehicleType === 'CORPORATE'
          ? item.vehicle.corporateName
          : `${intl.messages['individualVehicle']}`}
      </Typography>,
      <Typography style={{ fontWeight: 600 }}>
        {`$${numberWithCommas(item.amount)}`}
      </Typography>,
      <Typography>{getStatus(item.status, intl.messages)}</Typography>,
      <Typography>
        {`${moment(item.createdDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      <Typography>
        {`${moment(item.modifiedDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      <Typography>
        {item.issuer.userType === 'ADMIN'
          ? `${intl.messages['admin']} - ${item.issuer.fullName}`
          : `${intl.messages['investor']}`}
      </Typography>,
      actionButton(index, item),
    ];
  };

  const renderItemInvestor = (item, index) => {
    return [
      <Typography key={index}>
        {item.vehicle && item.vehicle.vehicleType === 'CORPORATE'
          ? item.vehicle.corporateName
          : `${intl.messages['individualVehicle']}`}
      </Typography>,
      <Typography
      key={index}
      style={{
        color:
          item.tokenIntent === 'token'
            ? 'var(--color-purple)'
            : 'var(--color-grey)',
        fontWeight: 600,
      }}
    >
      {item.tokenIntent === 'token'
        ? `${intl.messages['tokenIntent.token']}`
        : `${intl.messages['tokenIntent.standard']}`}
    </Typography>,
      <Typography>
        {intl.messages[item.investorApproach.toLowerCase()]}
      </Typography>,
      <Typography style={{ fontWeight: 600 }}>
        {`$${numberWithCommas(item.amount)}`}
      </Typography>,
      <Typography>{getStatus(item.status, intl.messages)}</Typography>,
      <Typography>
        {`${moment(item.createdDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      <Typography>
        {`${moment(item.modifiedDate).format('DD-MM-YYYY HH:mm')} (SGT)`}
      </Typography>,
      actionButton(index, item),
    ];
  };

  return (
    <div>
      <div>
        <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {total}{' '}
            <FormattedMessage
              id={`${localScope}.matchedTickets`}
              values={{ num: total }}
            />
          </span>
        </Typography>
      </div>
      <Table
        headers={isAdmin ? headers : headersInvestor}
        items={tickets}
        renderItem={isAdmin ? renderItem : renderItemInvestor}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
        currentPage={page}
      />
    </div>
  );
}

export default memo(TicketsTable);
