/*
 *
 * Authentication
 *
 */

import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Dashboard from 'containers/Dashboard';
import Companies from 'containers/Company/Companies';
import Deals from 'containers/Deals';
import Investors from 'containers/Investors';
import { Switch, Route } from 'react-router-dom';
import DealDetail from 'containers/DealDetail';
import SecondaryMarket from 'containers/SecondaryMarket';
import DealFlow from 'containers/DealFlow';
import Portfolio from 'containers/Portfolio';
import InvestorDetail from 'containers/InvestorDetail';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { createStructuredSelector } from 'reselect';
import DocumentSettings from 'containers/SettingsDocument';
import GuidanceSetting from 'containers/GuidanceSetting';
import GetStarted from 'containers/GetStartedNew';

import InvestorDashboard from 'containers/InvestorDashboard';
import CompanyDetail from 'containers/Company/CompanyDetail';
import { ApolloConsumer } from '@apollo/client';
import Investments from 'containers/InvestmentsPage/Components/Main';
import FAQPage from 'containers/FAQ';
import NotFound from 'containers/ErrorPages/components/NotFound';
import secondaryMarket from 'containers/secMarket/components/Market';
import OurCompanyPage from 'containers/OurCompany';

import Notifications from 'containers/Notifications';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});
const useStyles = makeStyles(() => ({
  toolbar: {
    flexDirection: 'row-reverse',
  },
  card: {
    minWidth: 275,
    maxWidth: 300,
  },
  container: {
    height: '90vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function RouteLayout() {
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useSelector(stateSelector);
  const isAdmin = currentUser && currentUser.userType === 'ADMIN';
  return (
    <ApolloConsumer>
      {client => (
        <Switch>
          <Route
            path="/deals/:id/document"
            render={props => (
              <DealDetail
                client={client}
                match={props.match}
                location={props.location}
              />
            )}
          />
          <Route
            path="/deals/:id"
            render={props => (
              <DealDetail
                client={client}
                match={props.match}
                location={props.location}
              />
            )}
          />
          <Route
            path="/deals"
            exact={true}
            component={isAdmin ? Deals : DealFlow}
          />
          <Route path="/portfolio" exact={true} component={Portfolio} />
          {isAdmin && (
            <Route
              path="/companies/:id"
              render={props => (
                <CompanyDetail match={props.match} client={client} />
              )}
            />
          )}
          {isAdmin && (
            <Route
              path="/companies"
              render={() => <Companies client={client} />}
            />
          )}
          {isAdmin && (
            <Route
              path="/investors/:id"
              exact={true}
              component={InvestorDetail}
            />
          )}
          <Route path="/secondary-market" component={secondaryMarket} />
          {isAdmin && (
            <Route path="/investors" exact={true} component={Investors} />
          )}
          <Route path="/markets" exact={true} component={SecondaryMarket} />
          {isAdmin && (
            <Route
              path="/settings/document"
              exact={true}
              component={DocumentSettings}
            />
          )}
          {isAdmin && (
            <Route
              path="/settings/guidance"
              exact={true}
              component={GuidanceSetting}
            />
          )}
          <Route path="/me" exact={true} component={InvestorDetail} />
          <Route
            path="/dashboard"
            exact={true}
            component={isAdmin ? Dashboard : InvestorDashboard}
          />
          <Route path="/get-started" exact={true} component={GetStarted} />
          <Route path="/faq" exact={true} component={FAQPage} />
          <Route
            path="/"
            exact={true}
            component={isAdmin ? Dashboard : InvestorDashboard}
          />
          <Route path="/investments" exact={true} component={Investments} />
          <Route path="/our-company" exact={true} component={OurCompanyPage} />
          {isAdmin && (
            <Route
              path="/notifications"
              exact={true}
              component={Notifications}
            />
          )}
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      )}
    </ApolloConsumer>
  );
}

export default memo(RouteLayout);
