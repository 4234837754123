// tslint:disable: object-literal-key-quotes

import React, { memo, useState, useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles, Theme } from '@material-ui/core/styles';
import { flattenDeep } from 'lodash';

import {
  Grid,
  Tabs,
  Tab,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  Card,
} from '@material-ui/core';
import AlertDialog from 'components/AlertDialog/alertDialog';
import Loading from 'components/Loading';
import Label from './components/Label';
import DocumentGroups from './components/DocumentGroups';
import DocumentGroupCreator from './components/DocumentGroupCreator';
import DocumentEditor from 'components/DocumentEditor/DocumentEditor';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import {
  getDocuments,
  createGroup,
  deleteGroup,
  createDocument,
  deleteDocument,
  editDocument,
  getNumberOfDocuments,
  moveDocumentSuccess,
} from './actions';
import makeSelectCompanyDocuments from './selectors';
import DocumentCreator from 'components/DocumentCreator/DocumentCreator';
import { useIntl } from 'react-intl';
import DocumentMoveDialog from 'components/DocumentMoveDialog/DocumentMoveDialog';

const stateSelector = createStructuredSelector({
  companyDocuments: makeSelectCompanyDocuments(),
});

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        width: '100%',
      }}
    >
      {children}
    </div>
  );
};

const CustomTabs = withStyles(() => ({
  root: {},
  flexContainer: {
    justifyContent: 'flex-start',
  },
}))(Tabs);

CustomTabs.defaultProps = {
  indicatorColor: 'primary',
  orientation: 'vertical',
};

const CustomTab = withStyles(() => ({
  root: {
    textTransform: 'unset',
    maxWidth: 'unset',
    letterSpacing: 'unset',
  },
  wrapper: {
    alignItems: 'start',
    paddingLeft: 5,
  },
  textColorInherit: {
    opacity: 'unset',
  },
  selected: {
    backgroundColor: 'var(--color-dark-grey)',
  },
}))(Tab);

function tabProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const getType = index => {
  switch (index) {
    case 0:
      return { type: 'DOCUMENT', isPublic: true };
    case 1:
      return { type: 'DOCUMENT', isPublic: false };
    case 2:
      return { type: 'REPORT', isPublic: true };
    case 3:
      return { type: 'REPORT', isPublic: false };
    default:
      return { type: '', isPublic: false };
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: '10px',
    width: 'unset',
    [theme.breakpoints.down('xs')]: {
      margin: 8,
    },
  },
  tabPanel: {
    padding: 8,
  },
}));

interface Props {
  companyId: string;
  status?: string;
}

function AdminDocuments(props: Props) {
  const intl = useIntl();
  useInjectReducer({ key: 'companyDocuments', reducer: reducer });
  useInjectSaga({ key: 'companyDocuments', saga: saga });

  const { companyId, status } = props;

  const [width, setWidth] = useState(window.innerWidth);

  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [documentCreatorOpen, setDocumentCreatorOpen] = useState(false);
  const [groupDelete, setGroupDelete] = useState<any>({});
  const [groupId, setGroupId] = useState('');
  const [deleteDocumentAlert, setDeleteDocumentAlert] = useState(false);
  const [documentDelete, setDocumentDelete] = useState<any>({});
  const [documentEdit, setDocumentEdit] = useState<any>({});
  const [documentEditorOpen, setDocumentEditorOpen] = useState(false);
  const [sendRemoveMail, setSendRemoveMail] = useState(true);
  const [documentToMove, setDocumentToMove] = useState<any>(null);
  const [moveDocumentDialogOpen, setMoveDocumentDialogOpen] = useState(false);

  const classes = useStyles();

  const { companyDocuments } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const {
    documentGroups,
    numberOfDocuments,
    loading,
    loadingDocument,
  } = companyDocuments;

  const isSendMailVisible = () => {
    return (
      (tabValue === 1 || tabValue === 3) &&
      status !== 'DRAFT' &&
      status !== 'REMOVED' &&
      status !== 'ARCHIVED'
    );
  };

  const handleAddGroupClick = () => {
    setOpen(true);
  };

  const onCreateGroup = groupName => {
    dispatch(
      createGroup({
        ...getType(tabValue),
        companyId: companyId,
        title: groupName,
      }),
    );
  };

  const onClickRemoveGroup = group => {
    setGroupDelete(group);
    setAlertOpen(true);
  };

  const handleClickRemoveDocument = document => {
    setDocumentDelete(document);
    setDeleteDocumentAlert(true);
  };

  const onRemoveGroup = () => {
    setAlertOpen(false);
    dispatch(
      deleteGroup({
        ...getType(tabValue),
        companyId: companyId,
        groupId: groupDelete.documentGroupId,
        sendMail: sendRemoveMail && isSendMailVisible(),
      }),
    );
  };

  const onRemoveDocument = () => {
    setDeleteDocumentAlert(false);
    dispatch(
      deleteDocument({
        ...getType(tabValue),
        companyId: companyId,
        documentId: documentDelete.documentId,
        sendMail: sendRemoveMail && isSendMailVisible() && tabValue > 0,
      }),
    );
  };

  const handleAddDocumentClick = groupId => {
    setDocumentCreatorOpen(true);
    setGroupId(groupId);
  };

  const onCreateDocument = data => {
    setDocumentCreatorOpen(false);
    dispatch(
      createDocument({
        ...getType(tabValue),
        documentGroupId: groupId,
        companyId: companyId,
        title: data.name,
        files: data.files,
        sendMail: data.sendMail,
        newFileTitles: data.newFileTitles
      }),
    );
  };

  const handleClickEditDocument = doc => {
    setDocumentEdit(doc);
    setDocumentEditorOpen(true);
  };

  const onEditDocument = data => {
    setDocumentEditorOpen(false);
    dispatch(
      editDocument({
        ...getType(tabValue),
        ...data,
        companyId,
        title: data.name,
        documentGroupId: groupId,
        documentId: documentEdit.documentId,
      }),
    );
  };

  const handleMoveDocumentCancel = () => {
    setMoveDocumentDialogOpen(false);
  }

  const handleMoveDocumentSave = () => {
    dispatch(moveDocumentSuccess());
    dispatch(getDocuments({ ...getType(tabValue), companyId }));
    dispatch(getNumberOfDocuments(companyId));
    setMoveDocumentDialogOpen(false);
  }

  useLayoutEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    dispatch(getNumberOfDocuments(companyId));
  }, []);

  useEffect(() => {
    dispatch(
      getDocuments({
        ...getType(tabValue),
        companyId,
      }),
    );
  }, [tabValue]);
  return (
    <Grid container className={classes.root} spacing={5}>
      <Grid item xs={12} sm={3} lg={2}>
        <Card style={{ padding: '12px' }}>
          <CustomTabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
            <CustomTab
              label={
                <Label
                  title={`${intl.messages[`publicDocuments`]}`}
                  subTitle={`${numberOfDocuments.totalInDocumentPublicGroup ||
                    0} ${
                    numberOfDocuments.totalInDocumentPublicGroup === 1
                      ? `${intl.messages[`document`]}`
                      : `${intl.messages[`documents`]}`
                  }`}
                />
              }
              {...tabProps(0)}
            />
            <CustomTab
              label={
                <Label
                  title={`${intl.messages[`privateDocuments`]}`}
                  subTitle={`${numberOfDocuments.totalInDocumentPrivateGroup ||
                    0} ${
                    numberOfDocuments.totalInDocumentPrivateGroup === 1
                      ? `${intl.messages[`document`]}`
                      : `${intl.messages[`documents`]}`
                  }`}
                />
              }
              {...tabProps(1)}
            />
            <CustomTab
              label={
                <Label
                  title={`${intl.messages[`publicReports`]}`}
                  subTitle={`${numberOfDocuments.totalInReportPublicGroup || 0} ${
                    numberOfDocuments.totalInReportPublicGroup === 1
                      ? `${intl.messages[`report`]}`
                      : `${intl.messages[`reports`]}`
                  }`}
                />
              }
              {...tabProps(2)}
            />
            <CustomTab
              label={
                <Label
                  title={`${intl.messages[`privateReports`]}`}
                  subTitle={`${numberOfDocuments.totalInReportPrivateGroup ||
                    0} ${
                    numberOfDocuments.totalInReportPrivateGroup === 1
                      ? `${intl.messages[`report`]}`
                      : `${intl.messages[`reports`]}`
                  }`}
                />
              }
              {...tabProps(3)}
            />
          </CustomTabs>
        </Card>
      </Grid>
      <Grid item xs={12} sm={width > 1350 ? 10 : 9}>
        <Card style={{ padding: '12px' }}>
          <TabPanel value={tabValue} index={0}>
            <DocumentGroups
              documentGroups={documentGroups}
              title={`${intl.messages[`documentsSharedWithInvestor`]}`}
              type="document"
              companyId={companyId}
              isPublic={true}
              addNewGroup={handleAddGroupClick}
              onRemoveGroup={onClickRemoveGroup}
              addDocument={handleAddDocumentClick}
              onRemoveDocument={handleClickRemoveDocument}
              onEditDocument={handleClickEditDocument}
              setDocumentToMove={setDocumentToMove}
              setMoveDocumentDialogOpen={setMoveDocumentDialogOpen}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <DocumentGroups
              documentGroups={documentGroups}
              title={`${intl.messages[`documentsOnlySharedWithAdmins`]}`}
              type="document"
              companyId={companyId}
              isPublic={false}
              addNewGroup={handleAddGroupClick}
              onRemoveGroup={onClickRemoveGroup}
              addDocument={handleAddDocumentClick}
              onRemoveDocument={handleClickRemoveDocument}
              onEditDocument={handleClickEditDocument}
              setDocumentToMove={setDocumentToMove}
              setMoveDocumentDialogOpen={setMoveDocumentDialogOpen}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <DocumentGroups
              documentGroups={documentGroups}
              title={`${intl.messages[`reportsSharedWithInvestor`]}`}
              type="report"
              companyId={companyId}
              isPublic={true}
              addNewGroup={handleAddGroupClick}
              onRemoveGroup={onClickRemoveGroup}
              addDocument={handleAddDocumentClick}
              onRemoveDocument={handleClickRemoveDocument}
              onEditDocument={handleClickEditDocument}
              setDocumentToMove={setDocumentToMove}
              setMoveDocumentDialogOpen={setMoveDocumentDialogOpen}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <DocumentGroups
              documentGroups={documentGroups}
              title={`${intl.messages[`reportsOnlySharedWithAdmins`]}`}
              type="report"
              companyId={companyId}
              isPublic={false}
              addNewGroup={handleAddGroupClick}
              onRemoveGroup={onClickRemoveGroup}
              addDocument={handleAddDocumentClick}
              onRemoveDocument={handleClickRemoveDocument}
              onEditDocument={handleClickEditDocument}
              setDocumentToMove={setDocumentToMove}
              setMoveDocumentDialogOpen={setMoveDocumentDialogOpen}
            />
          </TabPanel>
          {loadingDocument && <Loading />}
          </Card>
      </Grid>
      <DocumentGroupCreator
        open={open}
        onCreate={onCreateGroup}
        onCancel={() => setOpen(false)}
        groupNames={documentGroups.map(group => group.title)}
      />
      <DocumentCreator
        sendMailVisible={isSendMailVisible()}
        open={documentCreatorOpen}
        onCancel={() => setDocumentCreatorOpen(false)}
        onCreate={onCreateDocument}
        type={getType(tabValue).type.toLowerCase()}
        docNames={flattenDeep(
          documentGroups.map(group =>
            group.documents ? group.documents.map(doc => doc.title) : [],
          ),
        )}
      />
      <DocumentEditor
        sendMailVisible={isSendMailVisible()}
        open={documentEditorOpen}
        onCancel={() => setDocumentEditorOpen(false)}
        onEdit={onEditDocument}
        type={getType(tabValue).type.toLowerCase()}
        docNames={flattenDeep(
          documentGroups.map(group =>
            group.documents ? group.documents.map(doc => doc.title) : [],
          ),
        )}
        oldName={documentEdit.title}
        docFiles={documentEdit.files || []}
      />
      <AlertDialog
        title={`${intl.messages['removeGroup']}`}
        open={alertOpen}
        description={
          <Typography>
            {`${intl.messages['removeGroup']}`}{' '}
            <span style={{ fontWeight: 500 }}>{groupDelete.title}</span>.{' '}
            {`${intl.messages['doYouWantToContinue']}`}
          </Typography>
        }
        onCancel={() => setAlertOpen(false)}
        onConfirm={onRemoveGroup}
      >
        {isSendMailVisible() && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={sendRemoveMail}
                onChange={e => setSendRemoveMail(e.currentTarget.checked)}
              />
            }
            label={
              <Typography color="textSecondary">
                {`${intl.messages['sendInformationEmailToInvestors']}`}
              </Typography>
            }
          />
        )}
      </AlertDialog>
      <AlertDialog
        title={`${intl.messages['removeDocument']}`}
        open={deleteDocumentAlert && tabValue > 0}
        description={
          <Typography>
            {`${intl.messages['removeDocument']}`}{' '}
            <span style={{ fontWeight: 500 }}>{documentDelete.title}</span>.{' '}
            {`${intl.messages['doYouWantToContinue']}`}
          </Typography>
        }
        onCancel={() => setDeleteDocumentAlert(false)}
        onConfirm={onRemoveDocument}
      >
        {isSendMailVisible() && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={sendRemoveMail}
                onChange={e => setSendRemoveMail(e.currentTarget.checked)}
              />
            }
            label={
              <Typography color="textSecondary">
                {`${intl.messages['sendInformationEmailToInvestors']}`}
              </Typography>
            }
          />
        )}
      </AlertDialog>
      <AlertDialog
        title={`${intl.messages['removeSignature']}`}
        open={deleteDocumentAlert && tabValue === 0}
        description={
          <Typography>
            {`${intl.messages['removeSignature']}`}{' '}
            <span style={{ fontWeight: 500 }}>{documentDelete.title}</span>.{' '}
            {`${intl.messages['doYouWantToContinue']}`}
          </Typography>
        }
        onCancel={() => setDeleteDocumentAlert(false)}
        onConfirm={onRemoveDocument}
      />
      { documentToMove &&
          <DocumentMoveDialog isCompany={true} companyId={companyId} open={moveDocumentDialogOpen} onSave={handleMoveDocumentSave} onCancel={handleMoveDocumentCancel} documentToMove={documentToMove}/>
        }
      {loading && <Loading fullScreen />}
    </Grid>
  );
}

export default memo(AdminDocuments);
