import React, { memo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  requestDownloadSingleFile,
  requestPreviewSingleFile
} from 'containers/App/actions';
import { useDispatch } from 'react-redux';
import { ReactComponent as DownloadSvg } from 'images/icons/with-class/download.svg';

const useStyles = makeStyles(() => ({
  root: {
    color: 'var(--color-purple)',
    fontSize: '12px',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--color-dark-purple)',
    },
  },
  inactive: {
    color: 'var(--color-purple)',
    fontSize: '12px',
    fontWeight: 500,
  },
}));

interface AttachmentProps {
  dealId?: string;
  fileId: string;
  name: string;
  style?: any;
  downloadable?: boolean;
  isDealAttachment?: boolean;
  admin?: boolean;
  title?: string;
}
function Attachment(props: AttachmentProps) {
  const { fileId, name, dealId, style, isDealAttachment = false, downloadable = true, admin = false, title = null } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const downloadFile = fileId => {
    dispatch(requestDownloadSingleFile(fileId));
  };
  const previewFile = fileId => {
    dispatch(requestPreviewSingleFile(fileId));
  };
  return (
    <Grid item xs={12} style={{ ...style }}>
      <Grid
        container
        style={{ marginTop: 6 }}
        alignItems="center"
      >
        <Grid className={downloadable ? classes.root : classes.inactive} item xs={true} onClick={downloadable ? () => previewFile(fileId) : null}>
          {title || name}
        </Grid>
        <Grid className={downloadable ? classes.root : classes.inactive} item xs={'auto'}>
          {downloadable ? <DownloadSvg onClick={downloadable ? () => downloadFile(fileId) : null} /> : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(Attachment);
