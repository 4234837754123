/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS model syntax
 *   You CANNOT use import/export in this file.
 */

const enTranslationMessages = require('./translations/en.json');
const enCommonTranslationMessages = require('./translations/en-common.json');
const enModelTranslationMessages = require('./translations/en-model.json');
const enErrorTranslationMessages = require('./translations/en-error.json');
const deTranslationMessages = require('./translations/de.json');
const deCommonTranslationMessages = require('./translations/de-common.json');
const deModelTranslationMessages = require('./translations/de-model.json');
const deErrorTranslationMessages = require('./translations/de-error.json');
const jaTranslationMessages = require('./translations/ja.json');
const jaCommonTranslationMessages = require('./translations/ja-common.json');
const jaModelTranslationMessages = require('./translations/ja-model.json');
const jaErrorTranslationMessages = require('./translations/ja-error.json');

// Merge the main, common and model dictionaries
const mergedEn = {
  ...enTranslationMessages,
  ...enCommonTranslationMessages,
  ...enModelTranslationMessages,
  ...enErrorTranslationMessages,
};
const mergedDe = {
  ...deTranslationMessages,
  ...deCommonTranslationMessages,
  ...deModelTranslationMessages,
  ...deErrorTranslationMessages,
};
const mergedJa = {
  ...jaTranslationMessages,
  ...jaCommonTranslationMessages,
  ...jaModelTranslationMessages,
  ...jaErrorTranslationMessages,
};

declare let Intl: {
  PluralRules?: any;
  RelativeTimeFormat?: any;
};
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/de');
}

export const DEFAULT_LOCALE = 'en';

// prettier-ignore
export const appLocales = [
   'en',
   'de',
   'ja',
 ];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, mergedEn)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return { ...formattedMessages, [key]: formattedMessage };
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', mergedEn),
  de: formatTranslationMessages('de', mergedDe),
  ja: formatTranslationMessages('ja', mergedJa),
};
