import React, { memo, useState, useEffect } from 'react';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Input,
  IconButton,
  Link,
  OutlinedInput,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage, useIntl } from 'react-intl';

const fileMaxLength = 209715200; // 200 MB

const style = {
  documentRow: {
    fontWeight: 500,
    marginBottom: 10,
    borderRadius: 4,
    padding: '3px 8px',
    background: '#1d1c1c'
  },
  fileName: {
    color: 'white',
    fontSize: '12px',
    lineHeight: '12px'
  }
};

interface Props {
  sendMailVisible: boolean;
  open: boolean;
  onEdit: (data: {
    name: string;
    files: any[];
    oldFiles: any[];
    sendMail: boolean;
    newFileTitles?: string[];
  }) => void;
  onCancel: () => void;
  type: string;
  docNames: string[];
  docFiles: any[];
  oldName: string;
}

function DocumentCreator(props: Props) {
  const intl = useIntl();
  const {
    open,
    onCancel,
    onEdit,
    type,
    docNames,
    docFiles,
    oldName,
    sendMailVisible,
  } = props;

  const [nameErr, setNameErr] = useState('');
  const [filesErr, setFilesErr] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [name, setName] = useState(oldName);
  const [oldFiles, setOldFiles] = useState(docFiles);
  const [sendMail, setSendMail] = useState(true);
  const [newFileTitles, setNewFileTitles] = useState<string[]>([]);

  let hiddenFileInput;

  useEffect(() => {
    setFiles([]);
    setNewFileTitles([]);
    setOldFiles(docFiles);
  }, [docFiles]);

  useEffect(() => {
    setName(oldName);
  }, [oldName]);

  const cancelUpdate = () => {
    onCancel();
    setName(oldName);
    setFiles([]);
    setOldFiles(docFiles);
    setNewFileTitles([]);
  };

  const handleInputFile = ev => {
    for (const file of ev.target.files) {
      if (file.size > fileMaxLength) {
        setFilesErr(`${intl.messages['error.maximumFileSizeIs200MB']}`);
        return;
      }
      setFilesErr('');
      const filesObj = [...files];
      filesObj.push(file);
      setFiles(filesObj);
    }
  };

  const handleRemoveFile = index => {
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
  };

  const handleRemoveOldFile = index => {
    const newFiles = oldFiles.filter((file, i) => i !== index);
    setOldFiles(newFiles);
  };

  const handleClickEdit = () => {
    setNameErr('');
    setFilesErr('');
    if (!name) {
      setNameErr(`${intl.messages['error.documentNameIsRequired']}`);
      return;
    }
    if (name !== oldName && docNames.includes(name)) {
      setNameErr(`${intl.messages['error.documentNameAlreadyExists']}`);
      return;
    }
    if (!files.length && !oldFiles.length) {
      setFilesErr(`${intl.messages['error.filesAreRequired']}`);
      return;
    }
    onEdit({
      name,
      files,
      oldFiles,
      sendMail: sendMailVisible && sendMail,
      newFileTitles,
    });
  };

  const handleFileTitleChange = (event, index): void => {
    const newTitles = [...newFileTitles];
    newTitles[index] = event.target.value;
    setNewFileTitles(newTitles);
  };

  const handleOldFileTitleChange = (event, index): void => {
    const newFiles = [...oldFiles];
    newFiles[index] = {...newFiles[index], title: event.target.value};
    setOldFiles(newFiles);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography style={{ paddingTop: 4, fontSize: '1.25rem' }}>
              {`${intl.messages['update']}`} {type}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={cancelUpdate}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 6 }}>
          {`${intl.messages['name']}`}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <Input
          fullWidth
          defaultValue={oldName}
          inputProps={{ maxLength: 40 }}
          style={{
            border: nameErr ? '1px solid red' : '1px solid #bdbfc3',
            padding: '0 6px',
          }}
          disableUnderline
          onChange={e => setName(e.target.value)}
        />
        {nameErr && (
          <Typography
            style={{ color: 'red', paddingTop: 6, fontSize: '0.8rem' }}
          >
            {nameErr}
          </Typography>
        )}
        <input
          ref={ref => (hiddenFileInput = ref)}
          type="file"
          name="file"
          onChange={handleInputFile}
          style={{ display: 'none' }}
        />
        <Typography style={{ marginBottom: 6, marginTop: 10 }}>
          {`${intl.messages['files']}`}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        {oldFiles.map((file, index) => (
          <div key={index} style={style.documentRow}>
            <Grid container spacing={5} alignItems='center'>
              <Grid item xs>
                <div style={style.fileName}><FormattedMessage id={`fileName`} /></div>
                <Link
                  style={{ color: '#588FDB', fontSize: '12px' }}
                  underline={'always'}
                >
                  {file.name}
                </Link>
              </Grid>
              <Grid item>
                <OutlinedInput
                    placeholder={`${intl.messages[`fileTitle`]}`}
                    labelWidth={0}
                    type={'text'}
                    value={file.title || ''}
                    onChange={() => handleOldFileTitleChange(event, index)}
                  />
              </Grid>
              <Grid item>
                <span
                  style={{ color: 'red', fontSize: '12px' }}
                  onClick={() => handleRemoveOldFile(index)}
                >
                  {`${intl.messages['delete']}`}
                </span>
              </Grid>
            </Grid>
          
            
          </div>
        ))}
        {files.map((file, index) => (
          <div key={index} style={style.documentRow}>
            <Grid container spacing={5} alignItems='center'>
              <Grid item xs>
                <div style={style.fileName}><FormattedMessage id={`fileName`} /></div>
                <Link
                  style={{ color: '#588FDB', fontSize: '12px' }}
                  underline={'always'}
                >
                  {file.name}
                </Link>
              </Grid>
              <Grid item>
                <OutlinedInput
                    placeholder={`${intl.messages[`fileTitle`]}`}
                    labelWidth={0}
                    type={'text'}
                    onChange={() => handleFileTitleChange(event, index)}
                  />
              </Grid>
              <Grid item>
                <span
                  style={{ color: 'red', fontSize: '12px' }}
                  onClick={() => handleRemoveFile(index)}
                >
                  {`${intl.messages['delete']}`}
                </span>
              </Grid>
            </Grid>
          </div>
        ))}
        {filesErr && (
          <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
            {filesErr}
          </Typography>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={() => hiddenFileInput.click()}
        >
          {`${intl.messages['upload']}`.toUpperCase()}
        </Button>
      </DialogContent>
      <DialogActions style={{ marginTop: 40 }}>
        <Button color="primary" variant="outlined" onClick={cancelUpdate}>
          {`${intl.messages['cancel']}`.toUpperCase()}
        </Button>
        <Button color="primary" variant="contained" onClick={handleClickEdit}>
          {`${intl.messages['update']}`.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(DocumentCreator);
