/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { createSelector } from 'reselect';
import { makeSelectLocale } from './selectors';

import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser } from 'containers/App/selectors';

export interface Props {
  messages: { [locale: string]: { [id: string]: string } };
  children?: React.ReactNode;
}

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});
const stateSelectorLocale = createSelector(
  makeSelectLocale(),
  locale => ({
    locale,
  }),
);

export default function LanguageProvider(props: Props): JSX.Element {
  const { locale } = useSelector(stateSelectorLocale);
  const { currentUser } = useSelector(stateSelector);
  const currentLocale = currentUser ? currentUser.languageCode : locale || 'en';

  return (
    <IntlProvider
      locale={currentLocale}
      key={currentLocale}
      messages={props.messages[currentLocale]}
    >
      {React.Children.only(props.children)}
    </IntlProvider>
  );
}
