// tslint:disable: jsx-wrap-multiline
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  makeStyles,
  Theme,
  Link,
  createStyles,
  Button
} from '@material-ui/core';
import moment from 'moment';
import Table from 'components/Table';
import Header from './Header';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      color:'var(--color-purple)',
      fontWeight: 600,
      minWidth: 60,
    },
    tableCell5: {
      width: '5%',
    },
    tableCell10: {
      width: '10%',
    },
    tableCell15: {
      width: '15%',
    },
    tableCell20: {
      width: '20%',
    },
    tableCell25: {
      width: '25%',
    },
    tableCell30: {
      width: '30%',
    },
    tableCell35: {
      width: '35%',
    },
    investButton: {
      backgroundColor: 'var(--color-purple)',
      border: '1px solid var(--color-purple)',
      color: 'var(--color-light-grey)',
      '&:hover': {
        color: 'var(--color-purple)',
        backgroundColor: 'transparent',
      },
    },
  }),
);

interface Props {
  notifications?: any[];
  total: number;
  fetchPage: (page: number) => void;
  markAsRead: (item: any) => void;
  type: string;
  page: number;
}

function NotificationsList(props: Props) {
  const intl = useIntl();
  const {
    notifications,
    fetchPage,
    total,
    page,
    markAsRead
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const headers: any[] = [
    { name: `${intl.messages['date']}`.toUpperCase(), headerClass: classes.tableCell15 },
    {
      name: `${intl.messages['investor']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['deal']}`.toUpperCase(),
      headerClass: classes.tableCell20,
    },
    {
      name: `${intl.messages['type']}`.toUpperCase(),
      headerClass: classes.tableCell25,
    },
    {
      name: `${intl.messages['actions']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
  ];

  const handleFetchPage = currentPage => {
    fetchPage(currentPage);
  };

  const onClickUser = item => {
    dispatch(push(`/investors/${item.user.id}`));
  };

  const onClickDeal = item => {
    dispatch(push(`/deals/${item.deal.id}`));
  };

  const renderItem = (item, index) => {
    return [
      `${moment(item.created_date).format('DD-MM-YYYY, HH:mm')} (SGT)`,
      <Link className={classes.name} onClick={() => onClickUser(item)}>
        {item.user.fullName}
      </Link>,
      <>
        {item.deal && item.deal.deal_translations[0] && (
          <Link className={classes.name} onClick={() => onClickDeal(item)}>
            {item.deal?.deal_translations[0].name}
          </Link>
        )}
      </>,
      intl.messages[item.type],
      <>
      {!item.read_at && (
        <Button
          size={'small'}
          variant="contained"
          className={classes.investButton}
          onClick={() => {
            markAsRead(item);
          }}
        >
          {`${intl.messages['markAsRead']}`.toUpperCase()}
        </Button>
      )}
      </>
    ];
  };


  return (
    <>
      <div>
        <Header
          matches={total}
        />
        <Table
          headers={headers}
          items={notifications}
          renderItem={renderItem}
          fetchDataForPage={handleFetchPage}
          totalElements={total}
          currentPage={page}
        />
      </div>
    </>
  );
}

export default memo(NotificationsList);
