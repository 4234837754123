import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { createStructuredSelector } from 'reselect';
import { Location } from 'history';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import Tickets from 'containers/Tickets';
import Description from 'containers/DealDescription';
import Invites from 'containers/Invites';
import TicketCreate from 'containers/TicketCreate';
import InviteInvestorDialog from 'containers/InviteInvestor';
import Commits from 'containers/Commits';
import AdminDocuments from 'containers/AdminDocuments';
import InvestorDocuments from 'containers/InvestorDocuments';
import Dialog from 'components/AlertDialog/alertDialog';
import Loading from 'components/Loading';
import TopBar from './components/TopBar';
import NotFoundPage from './components/NotFound';
import SignNDA from './components/SignNDA';
import ErrorToast from 'components/ErrorToast';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { changeTabAction } from 'containers/Home/actions';
import makeSelectDealDetail from './selectors';
import { getPublicDocuments, getUserTicket } from './actions';
import reducer from './reducer';
import saga from './saga';
import ticketReducer from 'containers/Tickets/reducer';
import { useIntl } from 'react-intl';
import capitalizeFirstLetter from 'services/capitalize-first-letter';
import GetDealByIdAdminQuery from 'gql/deal/GetDealByIdAdmin.gql';
import GetDealByIdInvestorQuery from 'gql/deal/GetDealByIdInvestor.gql';
import UpdateDealStatus from 'gql/deal/UpdateDealStatus.gql';
import { ApolloClient } from '@apollo/client';
import { notifyToInvestors } from '../../services/api/deal-service';
import { match } from 'react-router-dom';

const stateSelector = createStructuredSelector({
  dealDetail: makeSelectDealDetail(),
  user: makeSelectCurrentUser()
});

const getTabValue = (tab, admin) => {
  if (admin) {
    switch (tab) {
      case 'description':
        return 0;
      case 'invites':
        return 1;
      case 'commits':
        return 2;
      case 'tickets':
        return 3;
      case 'documents':
        return 4;
      default:
        return 0;
    }
  }
  switch (tab) {
    case 'description':
      return 0;
    case 'tickets':
      return 1;
    case 'documents':
      return 2;
    default:
      return 0;
  }
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  match: match<any>;
  location: Location;
}

const isSendMail = (status, newStatus) => {
  return (
    (status === 'PREVIEW' &&
      (newStatus === 'OPEN' || newStatus === 'ARCHIVED')) ||
    (status === 'OPEN' &&
      (newStatus === 'FUNDED' ||
        newStatus === 'CLOSED' ||
        newStatus === 'ARCHIVED')) ||
    (status === 'FUNDED' && newStatus === 'CLOSED')
  );
};

function DealDetail(props: Props) {
  const intl = useIntl();
  useInjectReducer({ key: 'dealDetail', reducer: reducer });
  useInjectSaga({ key: 'dealDetail', saga: saga });
  useInjectReducer({ key: 'tickets', reducer: ticketReducer });
  

  const { match, location } = props;
  const { user, dealDetail } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const [createTicketOpen, setCreateTicketOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [inviteDialog, setInviteDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [reset, setReset] = useState(false);
  const [willSendMail, setWillSendMail] = useState(true);
  const [statusChangeError, setStatusChangeError] = useState('');
  const [investAmount, setInvestAmount] = useState(100);

  const dealId = match.params.id;
  let signStatus;

  const LANGUAGE_CODE = user?.languageCode ? user.languageCode : 'en';

  const isAdmin = () => {
    return user && user.userType === 'ADMIN';
  };

  const variables: { id: string; userId?: string; language: string } = {
    id: dealId,
    language: LANGUAGE_CODE,
  };

  if (!isAdmin()) {
    variables.userId = user ? user.userId : '';
  }

  const { error, loading, data, refetch } = useQuery(
    isAdmin() ? GetDealByIdAdminQuery : GetDealByIdInvestorQuery,
    {
      variables,
    },
  );

  const deal = data?.deal_by_pk;
  const esignDocument = data?.e_sign_document && data.e_sign_document[0];
  const statistics = data && {
    approved: data.approved,
    commited: data.commited,
    invited: data.invited,
    totalRaised: data.totalRaised,
    totalTickets: data.totalTickets,
    investorTickets: data.investorTickets,
  };
  const attachments = data?.attachments?.map(doc => {
    return {
      documentId: doc.documentId,
      files: doc.files.map(item => {
        return {
          fileId: item.file.fileId,
          name: item.file.name,
          url: item.file.url,
          title: item.file.title,
        };
      }),
    };
  });

  const getSignStatus = () => {
    if (!deal.isConfidentialAgreement) {
      return '';
    }
    if (!esignDocument) {
      return 'NOT_REQUESTED';
    }
    if (esignDocument.status) {
      return esignDocument.status;
    }
    return '';
  };

  const dealStatus = {
    DRAFT: `${intl.messages['draft']}`,
    PREVIEW: `${intl.messages['preview']}`,
    OPEN: `${intl.messages['open']}`,
    FUNDED: capitalizeFirstLetter(`${intl.messages['funded']}`),
    CLOSED: `${intl.messages['closed']}`,
    ARCHIVED: `${intl.messages['archived']}`,
    REMOVED: `${intl.messages['removed']}`,
  };

  if (deal) {
    signStatus = getSignStatus();
    if (!newStatus) {
      setNewStatus(deal.status);
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get('tab');
    setTabValue(getTabValue(tab, isAdmin()));
    if (!isAdmin()) {
      dispatch(getUserTicket({ dealId: dealId }));
    }
  }, []);

  useEffect(() => {
    if (tabValue === 0) {
      refetch();
      dispatch(getPublicDocuments(dealId));
    }
  }, [tabValue]);

  useEffect(() => {
    if (deal && deal.dealId && !isAdmin()) {
      dispatch(changeTabAction(deal.status === 'CLOSED' ? 2 : 3));
    }
  }, [deal]);

  const getInterest = (): string[] => {
    const interest =
      deal &&
      deal.dealPreferenceTags.map(item => {
        return item.preferenceTag.preferenceTagId;
      });
    return interest;
  };

  const AdminLayout = () => {
    switch (tabValue) {
      case 1:
        return (
          <Invites
            dealStatus={deal.status}
            dealId={deal.dealId}
            dealName={deal.deal_translations[0].name}
            isConfidentialAgreement={deal.isConfidentialAgreement}
          />
        );
      case 2:
        return <Commits dealId={deal.dealId} />;
      case 3:
        return <Tickets dealId={deal.dealId} isAdmin={true} />;
      case 4:
        return <AdminDocuments dealId={deal.dealId} status={deal.status} />;
      default:
        return (
          <Description
            client={props.client}
            admin
            deal={deal}
            statistics={statistics}
            attachments={attachments}
            onUpdate={refetch}
            onBannerCommitClicked={bannerCommitHandler}
            publicDocuments={dealDetail.publicDocuments}
          />
        );
    }
  };

  const InvestorLayout = () => {
    if (
      signStatus === 'NOT_REQUESTED' ||
      signStatus === 'IN_PROCESS' ||
      signStatus === 'DECLINED'
    ) {
      return (
        user?.userId && (
          <SignNDA
            dealId={deal.dealId}
            esignTemplateId={deal.esignTemplateId}
            userId={user?.userId}
          />
        )
      );
    }
    switch (tabValue) {
      case 1:
        return (
          <Tickets dealId={deal.dealId} isAdmin={false} userId={user?.userId} />
        );
      case 2:
        return (
          <InvestorDocuments
            dealId={deal && deal.dealId ? deal.dealId : dealId}
          />
        );
      default:
        return (
          <Description
            client={props.client}
            deal={deal}
            tickets={data.investorRealizesTickets}
            statistics={statistics}
            attachments={attachments}
            publicDocuments={dealDetail.publicDocuments}
            onUpdate={refetch}
            onBannerCommitClicked={bannerCommitHandler}
          />
        );
    }
  };

  const bannerCommitHandler = (amount: number) => {
    setInvestAmount(amount);
    setCreateTicketOpen(true);
  };

  const investDeal = () => {
    setCreateTicketOpen(!createTicketOpen);
  };

  const handleClose = () => {
    setCreateTicketOpen(false);
    if (!isAdmin()) {
      dispatch(getUserTicket({ dealId: dealId }));
    }
  };

  const onChangeStatus = status => {
    setReset(false);
    setDialogDescription(`${intl.messages[`DealDetail.to${status}`]}`);
    setNewStatus(status);
    setDialogOpen(true);
  };

  const onCancel = () => {
    setReset(true);
    setDialogOpen(false);
  };

  const onConfirm = async () => {
    try {
      await props.client.mutate({
        mutation: UpdateDealStatus,
        variables: {
          id: dealId,
          status: newStatus,
        },
      });
      if (willSendMail && isSendMail(deal.status, newStatus)) {
        await notifyToInvestors({
          dealId: dealId,
          dealUpdate: 'STATUS',
          oldDealStatus: deal.status,
        });
      }

      setDialogOpen(false);
      setReset(true);
      refetch();
    } catch (err) {
      setStatusChangeError('');
      setStatusChangeError(err.message);
      console.log(err.message);
    }
  };

  return (
    <>
      {(error || Boolean(statusChangeError)) && (
        <ErrorToast
          isOpen={Boolean(error) || Boolean(statusChangeError)}
          message={
            error || statusChangeError
              ? error?.message || statusChangeError
              : ''
          }
        />
      )}
      {error && <NotFoundPage />}
      {!error && !loading && (
        <>
          <TopBar
            tabValue={tabValue}
            admin={isAdmin()}
            deal={deal}
            statistics={statistics}
            onChangeTab={setTabValue}
            investDeal={() => {
              investDeal();
            }}
            addInvestors={() => setInviteDialog(true)}
            onChangeStatus={onChangeStatus}
            reset={reset}
            signStatus={signStatus}
          />
          {isAdmin() ? AdminLayout() : InvestorLayout()}
          <TicketCreate
            open={createTicketOpen}
            onClose={handleClose}
            deal={deal}
            passedAmount={investAmount}
          />
          <InviteInvestorDialog
            deal={deal}
            interest={getInterest()}
            open={inviteDialog}
            onClose={() => {
              setInviteDialog(false);
              refetch();
            }}
          />
          <Dialog
            title={`${intl.messages['updateDealStatus']}`}
            open={dialogOpen}
            description={
              <>
                {`${intl.messages['updateDealTo']}`}{' '}
                <b style={{ fontWeight: 600 }}>{dealStatus[newStatus]}</b>{' '}
                {dialogDescription}
              </>
            }
            onCancel={onCancel}
            onConfirm={onConfirm}
          >
            {isSendMail(deal.status, newStatus) && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={willSendMail}
                    onChange={e => setWillSendMail(e.currentTarget.checked)}
                  />
                }
                label={
                  <Typography color="textSecondary">
                    {`${intl.messages['sendInfoEmailToInvestor']}`}
                  </Typography>
                }
              />
            )}
          </Dialog>
        </>
      )}
      {loading && <Loading fullScreen />}
    </>
  );
}

export default memo(DealDetail);
