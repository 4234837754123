import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import AntSwitch from 'containers/RegisterVehicles/components/switch';
import { FormattedMessage } from 'react-intl';

interface Props {
  isIndividual: boolean;
  onChange: (active: boolean) => void;
}

function IndividualVehicle(props: Props) {
  const { isIndividual, onChange } = props;
  return (
    <Grid container spacing={3} alignItems="center" wrap="nowrap">
      <Grid item xs={'auto'}>
        <AntSwitch
          checked={isIndividual}
          onChange={ev => onChange(ev.target.checked)}
          style={{ color: '#BBBBBB' }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <div
          style={{
            fontWeight: 500,
            color: 'var(--color-light-grey)',
            fontSize: '0.875rem',
          }}
        >
          <FormattedMessage id="individualVehicle" />
        </div>
      </Grid>
    </Grid>
  );
}

export default memo(IndividualVehicle);
