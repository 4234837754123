import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link, Card } from '@material-ui/core';
import Company from 'models/company';
import NotFound from 'images/not-found.png';
import CompanyCreateDialog from 'containers/Company/CompanyCreateDialog';
import { ApolloClient } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {},
  logo: {
    margin: 6,
    width: 35,
    height: 35,
    borderRadius: '50%',
  },
  detail: {
    marginTop: 6,
    padding: 6,
  },
  detailText: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: 12,
  },
  linkText: {
    fontSize: '0.8rem',
    padding: 12,
  },
}));

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  company: Company;
  languageCode: string;
  onUpdate?: () => void;
}

function Detail(props: Props) {
  const classes = useStyles();
  const { company, onUpdate } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClick = () => {
    if (company) {
      if (company.website.startsWith('http')) {
        window.open(company.website, '_blank');
      } else {
        window.open(`http://${company.website}`, '_blank');
      }
    }
  };
  const handleCreateDialogClose = () => {
    setOpen(false);
    if (onUpdate) {
      onUpdate();
    }
  };
  const hanleSuccess = () => {
    setOpen(false);
    if (onUpdate) {
      onUpdate();
    }
  };
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12}>
        <Card variant="outlined" style={{ padding: 6 }}>
          <Grid container>
            <Grid item xs>
              <Typography className={classes.detailText}>
                <FormattedMessage id="details" />
              </Typography>
            </Grid>
            <Grid item>
              <Link
                className={classes.linkText}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <FormattedMessage id="edit" />
              </Link>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item>
              <img
                src={(company && company.pathPicture) || NotFound}
                className={classes.logo}
              />
            </Grid>
            <Grid item>
              <div>
                {company ? company.name.get(props.languageCode) : ''}
              </div>
              {company && company.website && company.website !== "undefined" && (
                <Link onClick={handleClick}>
                  {company.website}
                </Link>
              )}
            </Grid>
            <Grid item className={classes.detail} xs={12}>
              <FormattedMessage id="description" />
              <Typography>
                {company.description
                  ? company.description.get(props.languageCode)
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={6} />
      <CompanyCreateDialog
        client={props.client}
        open={open}
        onCancel={handleCreateDialogClose}
        onSuccess={hanleSuccess}
        item={company}
      />
    </Grid>
  );
}

export default memo(Detail);
