/*
 *
 * InviteInvestor constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InviteInvestor/DEFAULT_ACTION',
  FETCH_INVESTORS_ACTION = 'app/InviteInvestor/FETCH_INVESTORS_ACTION',
  FETCH_INVESTORS_SUCCESS_ACTION = 'app/InviteInvestor/FETCH_INVESTORS_SUCCESS_ACTION',
  FETCH_INVESTORS_FAILED_ACTION = 'app/InviteInvestor/FETCH_INVESTORS_FAILED_ACTION',
  SUBMIT_DEAL_INVESTOR_ACTION = 'app/InviteInvestor/SUBMIT_DEAL_INVESTOR_ACTION',
  SUBMIT_DEAL_INVESTOR_SUCCESS_ACTION = 'app/InviteInvestor/SUBMIT_DEAL_INVESTOR_SUCCESS_ACTION',
  SUBMIT_DEAL_INVESTOR_FAILED_ACTION = 'app/InviteInvestor/SUBMIT_DEAL_INVESTOR_FAILED_ACTION',
}

export default ActionTypes;
