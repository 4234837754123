import React, { memo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Grid } from '@material-ui/core';

import ErrorToast from 'components/ErrorToast';
import SuccessToast from 'components/SuccessToast';
import SearchInput from 'components/SearchInput';
import Alert from 'components/AlertDialog/alertDialog';
import Investors from './components/Investors';
import Filter from './components/Filter';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectDealEditor from 'containers/DealEditor/selectors';
import { getInvites, resendInvites } from './actions';
import { makeSelectPreferences } from 'containers/App/selectors';
import makeSelectInvites from './selectors';
import reducer from './reducer';
import saga from './saga';
import { PAGE_SIZE } from 'utils/constants';
import SendCustomEmail from './components/SendCustomEmail';
import MultiInvestorMailMutation from 'gql/email/MultiInvestorMail.gql';

const stateSelector = createStructuredSelector({
  invites: makeSelectInvites(),
  dealEditor: makeSelectDealEditor(),
  preferences: makeSelectPreferences(),
});

interface Props {
  dealId: string;
  isConfidentialAgreement: boolean;
  dealStatus: string;
  dealName: string;
}

function Invites(props: Props) {
  const intl = useIntl();
  useInjectReducer({ key: 'invites', reducer: reducer });
  useInjectSaga({ key: 'invites', saga: saga });

  const { invites, preferences } = useSelector(stateSelector);
  const { investors, total, resend } = invites;
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [tags, setTags] = useState<any[]>([]);
  const [status, setStatus] = useState('All');
  const [investStatus, setInvestStatus] = useState('All');
  const [page, setPage] = useState(1);
  const [selectedInvestors, setSelectedInvestors] = useState<any[]>([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [sendEmailVisible, setSendEmailVisible] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState('');
  const [sendEmailError, setSendEmailError] = useState('');
  const { dealId, isConfidentialAgreement, dealStatus } = props;

  const [multiInvestorMail] = useMutation(MultiInvestorMailMutation);

  const requestGetInvites = () => {
    dispatch(
      getInvites({
        dealId,
        pageSize: PAGE_SIZE,
        searchKey,
        interestsAny: tags.length ? tags : undefined,
        status: status !== 'All' ? status : undefined,
        investStatus: investStatus !== 'All' ? investStatus : undefined,
        page: page - 1,
      }),
    );
  };

  useEffect(() => {
    if (resend) {
      requestGetInvites();
    }
  }, [resend]);

  useEffect(() => {
    if (sendEmailVisible) {
      setSendEmailSuccess('');
      setSendEmailError('');
    }
  }, [sendEmailVisible]);

  useEffect(() => {
    requestGetInvites();
  }, [searchKey, tags, status, investStatus, page]);

  const onSelectTags = tagIds => {
    setTags(tagIds);
    setPage(1);
  };

  const onSelectStatus = value => {
    setStatus(value);
    setPage(1);
  };

  const onSelectInvestStatus = value => {
    setInvestStatus(value);
    setPage(1);
  };

  const handleSelectInvestor = item => {
    if (item.selected) {
      setSelectedInvestors(pre => {
        return [...pre, item];
      });
    } else {
      setSelectedInvestors(pre => {
        return pre.filter(obj => obj.userId !== item.userId);
      });
    }
  };

  const onSendMail = () => {
    setAlertVisible(true);
  };

  const onSendCustomMail = () => {
    setSendEmailVisible(true);
  };

  const onCancel = () => {
    setAlertVisible(false);
  };

  const onConfirmSendEmail = (title, content) => {
    multiInvestorMail({
      variables: {
        dealId,
        title,
        content,
        investorIds: selectedInvestors.map(investors => investors.userId),
      },
    }).then(
      () => {
        setSendEmailSuccess(`${intl.messages['sendEmailSuccessfully']}`);
        setSendEmailVisible(false);
      },
      () => {
        setSendEmailError('unexpectedError');
      },
    );
  };

  const onConfirm = () => {
    setAlertVisible(false);
    dispatch(
      resendInvites({
        dealId,
        userIds: selectedInvestors.map(investors => investors.userId),
      }),
    );
    setSelectedInvestors([]);
    if (investors) {
      investors.forEach(investor => (investor.selected = false));
    }
  };

  return (
    <Container className="main">
      <ErrorToast
        isOpen={Boolean(sendEmailError)}
        message={sendEmailError || ''}
      />
      <SuccessToast
        isOpen={Boolean(sendEmailSuccess)}
        message={sendEmailSuccess || ''}
      />
      <div style={{ marginBottom: '15px' }}>
        <SearchInput
          onChange={v => {
            setSearchKey(v);
            setPage(1);
          }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Filter
          status={status}
          investStatus={investStatus}
          tags={tags}
          tagsOption={preferences}
          onSelectTags={onSelectTags}
          onSelectStatus={onSelectStatus}
          onSelectInvestStatus={onSelectInvestStatus}
        />
      </div>
      <div>
        <Investors
          dealStatus={dealStatus}
          onSendMail={onSendMail}
          onSendCustomMail={onSendCustomMail}
          investors={investors}
          isConfidentialAgreement={isConfidentialAgreement}
          matches={total}
          seleted={selectedInvestors.length}
          total={total}
          fetchPage={setPage}
          onSelect={handleSelectInvestor}
          selectedInvestors={selectedInvestors}
        />
      </div>
      <Alert
        title={`${intl.messages['resendInvitation']}`}
        open={alertVisible}
        onCancel={onCancel}
        onConfirm={onConfirm}
        description={`${selectedInvestors.length} ${
          intl.messages['selected']
        } ${
          selectedInvestors.length === 1
            ? `${intl.messages['investor']}`
            : `${intl.messages['investors']}`
        } ${intl.messages['confirmMailMessage']}`}
      />
      <SendCustomEmail
        open={sendEmailVisible}
        onConfirm={onConfirmSendEmail}
        onCancel={() => {
          setSendEmailVisible(false);
        }}
      />
    </Container>
  );
}

export default memo(Invites);
