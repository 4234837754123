/*
 *
 * Register
 *
 */

import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, Grid, Typography, AppBar, Button } from '@material-ui/core';
import Img from 'components/Img';
import { FormattedMessage } from 'react-intl';
import whiteIcon from '../../../images/logos/Jadesquare-logo-white.svg';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import A from 'components/A';
import { logout } from 'containers/App/actions';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser } from 'containers/App/selectors';
const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    container: {
      flex: 1,
      height: 'calc(100vh - 100px)',
      textAlign: 'center',
    },
    logo: {
      margin: theme.spacing(4),
    },
    title: {
      fontSize: '1.25rem',
      textAlign: 'center',
      padding: 12,
    },
    subTitle: {
      fontSize: '1rem',
      textAlign: 'center',
      padding: 12,
    },
    buttonContainer: {
      textAlign: 'center',
      marginTop: 10,
    },
    button: {
      minWidth: 300,
    },
    iconButton: {
      padding: theme.spacing(5),
    },
  }),
);
interface Props {
  scope: string;
}

function AccountDeactivated(props: Props) {
  const { scope } = props;
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentUser } = useSelector(stateSelector);
  const handleClick = () => {
    dispatch(logout());
  };
  return (
    <div>
      <AppBar position="static" color="secondary">
        <Toolbar style={{ height: '100%' }}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <Img src={whiteIcon} style={{ height: 25 }} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={8}>
          <Grid item>
            <Typography className={classes.title}>
              <FormattedMessage id={`${scope}.dear`} />
              <b>{currentUser ? currentUser.fullName : ''}</b>
              <FormattedMessage id={`${scope}.accountDeactivated`} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={`classes.subTitle`}>
              <FormattedMessage id={`${scope}.accountDeactivatedBody`} />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={`classes.subTitle`}>
              <FormattedMessage id={`${scope}.contactUs`} />
              <A href="mailto:info@jadesquare.com">info@jadesquare.com</A>
              <FormattedMessage id={`${scope}.ifYouHaveQuestion`} />
            </Typography>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleClick}
            >
              <FormattedMessage id={`${scope}.backToLogin`} />
            </Button>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={() => dispatch(push('/me'))}
            >
              <FormattedMessage id={`${scope}.editYourProfile`} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(AccountDeactivated);
