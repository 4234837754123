import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import { SigningTemplate } from 'models/signing-template';
import { BACKEND_API_URL } from './../../env';
const instance = createService(BACKEND_API_URL);

export async function searchTemplate(request: any): Promise<SigningTemplate> {
  return instance
    .post('/e-sign/templates/search', request)
    .then((resp: AxiosResponse<SigningTemplate>) => {
      return resp.data;
    });
}

export async function createTemplate(
  request: any,
  file: File,
): Promise<SigningTemplate> {
  const formData = new FormData();
  if (request.name) {
    formData.append('name', request.name);
  }
  if (request.category) {
    formData.append('category', request.category);
  }
  if (request.description) {
    formData.append('description', request.description);
  }
  if (request.fileNamePrefix) {
    formData.append('fileNamePrefix', request.fileNamePrefix);
  }
  if (request.status) {
    formData.append('status', request.status);
  }
  if (request.createESignDynamicDataRequests) {
    formData.append(
      'createESignDynamicDataRequests',
      JSON.stringify(
        request.createESignDynamicDataRequests.map(data => ({
          ...data,
          key: data.key ? data.key.trim() : data.key,
        })),
      ),
    );
  }
  formData.append('file', file);
  return instance
    .post('/e-sign/templates', formData)
    .then((resp: AxiosResponse<SigningTemplate>) => {
      return resp.data;
    });
}

export async function updateTemplate(
  request: any,
  file: File,
): Promise<SigningTemplate> {
  const formData = new FormData();
  formData.append('eSignTemplateId', request.esignTemplateId);
  if (request.name) {
    formData.append('name', request.name);
  }
  if (request.category) {
    formData.append('category', request.category);
  }
  if (request.description) {
    formData.append('description', request.description);
  }
  if (request.fileNamePrefix) {
    formData.append('fileNamePrefix', request.fileNamePrefix);
  }
  if (request.status) {
    formData.append('status', request.status);
  }
  if (request.createESignDynamicDataRequests) {
    formData.append(
      'createESignDynamicDataRequests',
      JSON.stringify(
        request.createESignDynamicDataRequests.map(data => ({
          ...data,
          eSignTemplateId: data.esignTemplateId,
          eSignDynamicDataId: data.esignDynamicDataId,
          key: data.key ? data.key.trim() : data.key,
        })),
      ),
    );
  }
  formData.append('file', file);
  return instance
    .put(`/e-sign/templates/${request.esignTemplateId}`, formData)
    .then((resp: AxiosResponse<SigningTemplate>) => {
      return resp.data;
    });
}

export async function updateTemplateStatus(
  request: any,
): Promise<SigningTemplate> {
  return instance
    .put(`/e-sign/templates/${request.esignTemplateId}/status`, request)
    .then((resp: AxiosResponse<SigningTemplate>) => {
      return resp.data;
    });
}

export async function getDynamicValue(): Promise<SigningTemplate> {
  return instance
    .get(`/e-sign/templates/e-sign-dynamic-value`)
    .then((resp: AxiosResponse<RestResult<SigningTemplate>>) => {
      return resp.data.data;
    });
}

export async function previewTemplate(id) {
  return instance.get(`/e-sign/templates/${id}/preview`).then(resp => {
    return resp.data.data;
  });
}
