/*
 *
 * ProfileEditDialog
 *
 */

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectProfileEditDialog from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Input,
  Grid,
  Typography,
  makeStyles,
  Divider,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import UserData from 'models/user';
import ProfileEdit from './components/profileEdit';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { submitAction } from './actions';
import Loader from 'components/Loading';
import { showToast } from 'containers/App/actions';
const stateSelector = createStructuredSelector({
  profileEditDialog: makeSelectProfileEditDialog(),
});

const useStyles = makeStyles(theme => ({
  container: {},
  input: {
    width: '100%',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 0,
    paddingRight: 0,
    // border: '1px solid #bdbfc3',
  },
  requireText: {
    color: '#D74F4F',
  },
  errorInput: {
    border: '1px solid #D74F4F',
  },
  errorText: {
    color: '#D74F4F',
  },
  hiddenColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

interface Props {
  investor: UserData;
  open: boolean;
  onClose: () => void;
}

function ProfileEditDialog(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'profileEditDialog', reducer: reducer });
  useInjectSaga({ key: 'profileEditDialog', saga: saga });

  const { profileEditDialog } = useSelector(stateSelector);
  const { loading } = profileEditDialog;
  const { investor, onClose, open } = props;
  const [profile, setProfile] = React.useState<UserData>(investor);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [documents, setDocuments] = React.useState({
    KYC_RESIDENCY_CERTIFICATE: [],
    KYC_DOCUMENT_ID: [],
  });
  const onSubmit = () => {
    if (!profile.languageCode) profile.languageCode = 'en';
    
    /* 2023-01-27: Client asked to remove required KYC docs
    if (
      documents.KYC_DOCUMENT_ID.length === 0 ||
      documents.KYC_RESIDENCY_CERTIFICATE.length === 0
    ) {
      dispatch(showToast({ type: 'error', message: 'KYC_MISSING' }));
      return;
    }*/

    dispatch(submitAction({ ...profile, documents }));
  };

  const handleChanged = (value, type) => {
    setProfile(pre => {
      return { ...pre, [type]: value };
    });
  };

  useEffect(() => {
    setProfile(investor);
  }, [investor]);

  const handleDocumentChanged = (fileIds, type) => {
    // if (profile && profile.documentGroups) {
    //   const newDocGroup = profile.documentGroups.map(group => {
    //     if (group.documents) {
    //       const newDocuments = group.documents.map(doc => {
    //         if (doc.type === type) {
    //           return { ...doc, fileIds };
    //         }
    //       });
    //       console.log(newDocuments);
    //       return { ...group, documents: newDocuments };
    //     }
    //     return group;
    //   });
    //   setProfile({ ...profile, documentGroups: newDocGroup });
    //   console.log(profile);
    // }

    if (profile.documentGroups) {
      profile.documentGroups.forEach(group => {
        if (group.isDefault && group.documents) {
          group.documents.forEach(doc => {
            if (doc.type === type) {
              doc = { ...doc, fileIds: fileIds };
            }
          });
        }
      });
    }
    setDocuments(prev => {
      return { ...prev, [type]: fileIds };
    });
  };

  const onFileRemove = (fileId, type) => {
    setDocuments(prev => {
      return { ...prev, [type]: prev[type].filter(id => id !== fileId) };
    });
  };
  const scope = 'ProfileEditDialog';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      PaperProps={{ square: true }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <FormattedMessage id={`${scope}.updateProfile`} />
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ProfileEdit
          investor={profile}
          onChange={handleChanged}
          onDocumentChange={handleDocumentChanged}
          scope={scope}
          onFileRemove={onFileRemove}
        />
        <Grid
          item
          xs={12}
          container
          justify="flex-end"
          className={classes.container}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            {loading && (
              <div style={{ marginRight: 12 }}>
                <Loader />{' '}
              </div>
            )}
            <FormattedMessage id="update" />
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ProfileEditDialog;
