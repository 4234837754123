import { RestResult } from 'models/response/rest-result';
import ProfileRequest from '../../models/request/profile-request';
import ProfileResponse from '../../models/response/profile-response';
import axios, { AxiosResponse } from 'axios';
import { get, OAUTH_TOKEN } from '../local-storage-service';
import { createService } from './axios';
import SearchUserRequest from 'models/request/search-invitation-request';
import UserData from 'models/user';
import { DashboardStatistic } from 'models/request/investor-statistic';
import Deal from 'models/deal';
import { PAGE_SIZE } from 'utils/constants';
import { Ticket } from 'models/ticket';
import { BACKEND_API_URL } from './../../env';
const instance = createService(BACKEND_API_URL);

export async function signUp(
  request: ProfileRequest,
): Promise<ProfileResponse> {
  return instance
    .post('/users/register', request)
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data.data;
    });
}

export async function resendVerificationemail(request: { email: string }) {
  return instance
    .post('/users/resend-verification', request)
    .then((resp: any) => {
      return resp.data;
    });
}

export async function me(): Promise<ProfileResponse> {
  return instance
    .get('/users/me')
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data.data;
    });
}

export async function fetchUser(userId: string): Promise<ProfileResponse> {
  return instance
    .get(`/users/${userId}`)
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data.data;
    });
}

export async function updateProfile(
  request: ProfileRequest,
): Promise<ProfileResponse> {
  return instance
    .put('/users/update', request)
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data.data;
    });
}

export async function changeStatus(
  request: ProfileRequest,
): Promise<ProfileResponse> {
  return instance
    .put('/users/status', request)
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data.data;
    });
}

export async function searchInvitation(
  request: SearchUserRequest,
): Promise<RestResult<ProfileResponse>> {
  return instance
    .post('/users/invitation', request)
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data;
    });
}

export async function searchUser(request: any): Promise<RestResult<UserData>> {
  return instance
    .post('/users/search', request)
    .then((resp: AxiosResponse<RestResult<UserData>>) => {
      return resp.data;
    });
}

export async function updaStepProfile(request: any): Promise<ProfileResponse> {
  return instance
    .put('/users/update/register-step', request)
    .then((resp: AxiosResponse<RestResult<ProfileResponse>>) => {
      return resp.data.data;
    });
}

export async function getInvestorStatistic(): Promise<DashboardStatistic> {
  return instance
    .get('/investors/dashboards')
    .then((resp: AxiosResponse<RestResult<DashboardStatistic>>) => {
      return resp.data.data;
    });
}

export async function sendPassword(
  dealId: string,
): Promise<DashboardStatistic> {
  return instance
    .get('/investors/deal-password', { params: { dealId: dealId } })
    .then((resp: AxiosResponse<RestResult<DashboardStatistic>>) => {
      return resp.data.data;
    });
}

export async function sendCompanyPassword(companyId: string): Promise<any> {
  return instance
    .get('/companies/company-password', { params: { companyId: companyId } })
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function createInvestor(request: any) {
  return instance.post('/users/admin-register', request).then(resp => {
    return resp.data.data;
  });
}

export async function fetchInvestorDeal(
  request: any,
): Promise<RestResult<Deal[]>> {
  const url = `/investors/${request.investorId}/deals`;
  return instance
    .get(url, {
      params: {
        page: request.page ? request.page : 0,
        pageSize: request.pageSize ? request.pageSize : PAGE_SIZE,
      },
    })
    .then((resp: AxiosResponse<RestResult<Deal[]>>) => {
      return resp.data;
    });
}

export async function fetchInvestorTicket(
  request: any,
): Promise<RestResult<Ticket[]>> {
  const url = `/investors/${request.investorId}/tickets`;
  return instance
    .get(url, {
      params: {
        page: request.page ? request.page : 0,
        pageSize: request.pageSize ? request.pageSize : PAGE_SIZE,
      },
    })
    .then((resp: AxiosResponse<RestResult<Ticket[]>>) => {
      return resp.data;
    });
}

export async function fetchInvestorStatistic(
  userId: string,
): Promise<RestResult<any>> {
  const url = `/investors/${userId}/statistic`;
  return instance.get(url).then((resp: AxiosResponse<RestResult<any>>) => {
    return resp.data.data;
  });
}
