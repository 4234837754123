/*
 *
 * Investors
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectInvestors from './selectors';
import reducer from './reducer';
import saga from './saga';
import InvestorList from './components/investorList';
import { fetchInvestorAction } from './actions';
import { PAGE_SIZE } from 'utils/constants';
import { Container, Grid } from '@material-ui/core';
import { setIsLoading } from 'containers/Home/actions';
const stateSelector = createStructuredSelector({
  investorsState: makeSelectInvestors(),
});

interface Props {}

function Investors(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'investors', reducer: reducer });
  useInjectSaga({ key: 'investors', saga: saga });

  const { investorsState } = useSelector(stateSelector);
  const { investors, total, refreshPage } = investorsState;
  const [searchKey, setSearchKey] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filter, setFilter] = React.useState<any>({});
  const dispatch = useDispatch();

  const fetchPage = (searchKey, page) => {
    setSearchKey(searchKey);
    setCurrentPage(page);
    dispatch(
      fetchInvestorAction({
        ...filter,
        searchKey: searchKey,
        page: page - 1,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  const filterChange = (value, type) => {
    const newFilter = { ...filter, [type]: value };
    setFilter(newFilter);
    dispatch(
      fetchInvestorAction({
        ...newFilter,
        searchKey: searchKey,
        page: currentPage - 1,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  useEffect(() => {
    if (refreshPage) {
      dispatch(
        fetchInvestorAction({
          ...filter,
          searchKey: searchKey,
          page: currentPage - 1,
          pageSize: PAGE_SIZE,
        }),
      );
    }
  }, [refreshPage]);

  useEffect(() => {
    dispatch(setIsLoading(true));
  }, []);

  const scope = 'Investors';
  return (
    <Container className="main">
      <InvestorList
        refreshPage={refreshPage}
        total={total}
        items={investors}
        fetchPage={fetchPage}
        onFilterChanged={filterChange}
        scope={scope}
      />
    </Container>
  );
}

export default Investors;
