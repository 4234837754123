/*
 *
 * Invites constants
 *
 */

enum ActionTypes {
  GET_INVITES = 'app/Invites/GET_INVITES',
  GET_INVITES_SUCCEED = 'app/Invites/GET_INVITES_SUCCEED',
  GET_INVITES_FAILED = 'app/Invites/GET_INVITES_FAILED',
  RESEND_INVITES = 'app/Invites/RESEND_INVITES',
  RESEND_INVITES_SUCCEED = 'app/Invites/RESEND_INVITES_SUCCEED',
  REJECT_INVITE = 'app/Invites/REJECT_INVITE',
  REJECT_INVITE_SUCCEED = 'app/Invites/REJECT_INVITE_SUCCEED',
}

export default ActionTypes;
