/*
 *
 * RegisterSignUp
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectRegisterSignUp from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Grid } from '@material-ui/core';
import { signUpAction, updateFormStateSignUpAction } from './actions';
import FormInput from '../../components/FormControl/input';
import FormControl from 'components/FormControl';
import { changeLocale } from '../LanguageProvider/actions';

import { makeSelectLocale } from '../LanguageProvider/selectors';
import { useIntl, FormattedMessage } from 'react-intl';
import A from 'components/A';
import ArrowButton from 'components/ArrowButton';
import RegisterLoader from '../Register/components/loader';
import { setLoadingAction } from 'containers/Register/actions';

const stateSelector = createStructuredSelector({
  registerSignUp: makeSelectRegisterSignUp(),
  currentLanguage: makeSelectLocale(),
});

interface Props {
  nextStep: (ev) => void;
}

function RegisterSignUp(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'registerSignUp', reducer: reducer });
  useInjectSaga({ key: 'registerSignUp', saga: saga });
  const { registerSignUp, currentLanguage } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const { success, formState } = registerSignUp;
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (success) {
      props.nextStep(null);
    }
  }, [success]);

  const clickNext = e => {
    e.preventDefault();
    dispatch(setLoadingAction(true));
    dispatch(signUpAction(formState));
  };

  const updateFormState = field => stateValue => {
    const formField = formState[field];
    if (formField) {
      if (field === 'languageCode') {
        updateLocale(stateValue);
      }
      dispatch(
        updateFormStateSignUpAction([
          {
            field: field,
            state: { ...formField, value: stateValue },
          },
        ]),
      );
    }
  };

  const updateLocale = (languageCode: string) => {
    dispatch(changeLocale(languageCode));
  };

  const scope = 'RegisterSignUp';

  return (
    <div>
      {loading && <RegisterLoader />}
      <Grid container={true} spacing={4}>
        <Grid item xs={12} lg={6}>
          <FormInput
            onChange={updateFormState('firstName')}
            formState={formState.firstName}
            title={`${intl.messages[`model.firstName`]} *`}
            placeholder={`${intl.messages[`model.firstName`]}`}
            hideTitle={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormInput
            onChange={updateFormState('lastName')}
            formState={formState.lastName}
            title={`${intl.messages[`model.lastName`]} *`}
            placeholder={`${intl.messages[`model.lastName`]}`}
            hideTitle={true}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={4} style={{ paddingBottom: '15px' }}>
        <Grid item xs={12} lg={6}>
          <FormInput
            onChange={updateFormState('email')}
            formState={formState.email}
            title={`${intl.messages[`model.email`]} *`}
            placeholder={`${intl.messages[`model.email`]}`}
            hideTitle={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormInput
            onChange={updateFormState('password')}
            formState={formState.password}
            title={`${intl.messages[`model.password`]} *`}
            placeholder={`${intl.messages[`model.password`]}`}
            type={'password'}
            hideTitle={true}
          />
        </Grid>
      </Grid>

      {/* <FormControl
        onChange={updateFormState('languageCode')}
        type={'radio'}
        options={[
          { value: 'en', text: 'English' },
          { value: 'de', text: 'German' },
          {
            value: 'ja',
            text: 'Japanese',
          },
        ]}
        defaultValue={currentLanguage || 'en'}
      /> */}

      <Grid
        container={true}
        spacing={4}
        alignItems="center"
        style={{ paddingTop: '25px' }}
      >
        <Grid item xs={12} lg={true}>
          <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            <FormattedMessage id={`${scope}.byClickingAgreeTo`} />
            &nbsp;
            <A
              href="https://storage.googleapis.com/public-datastore-1/documents/JADE-ToS_and_PP_2021.pdf"
              target="_blank"
            >
              {intl.messages[`${scope}.TOS`]} {intl.messages['and']}{' '}
              {intl.messages[`${scope}.privacyPolicy`]}
            </A>
          </div>
        </Grid>
        <Grid item xs={12} lg={'auto'} style={{ textAlign: 'right' }}>
          <ArrowButton
            text={`${intl.messages[`next`]}`}
            onClick={clickNext}
            type="button"
            buttonType="button"
            size="small"
          ></ArrowButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(RegisterSignUp);
