import { FormState, initialFormState } from './state';
import { FormControl, MenuItem, PropTypes, Select } from '@material-ui/core';
import React, { memo } from 'react';
import CustomFormLabel from './components/form-label';
import CustomHelperText from './components/helper-text';

export interface SelectState extends FormState {
  value: string | undefined | null;
}

export const initialSelectState: SelectState = {
  value: 'default',
  ...initialFormState,
};

export interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  formState: SelectState;
  onChange: (ev) => void;
  defaultLabel: string;
  options: SelectOption[];
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth?: boolean;
  hideTitle?: boolean;
  disableDefault?: boolean;
  [propName: string]: any;
}

FormSelect.defaultProps = {
  fullWidth: true,
  options: [],
};

function FormSelect(props: Props) {
  const {
    formState,
    onChange,
    defaultLabel,
    options,
    title,
    subtitle,
    margin,
    fullWidth,
    disableDefault,
    hideTitle,
    ...rest
  } = props;

  const handleOptionChange = ev => {
    onChange(ev.target.value === 'default' ? '' : ev.target.value);
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      variant={'outlined'}
      {...rest}
    >
      <CustomFormLabel title={title} subtitle={subtitle} hidden={hideTitle} />

      <Select
        margin={'none'}
        value={formState.value || 'default'}
        onChange={handleOptionChange}
        error={formState.error}
      >
        <MenuItem value={'default'} disabled={disableDefault}>
          {defaultLabel}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <CustomHelperText formState={formState} />
    </FormControl> 
  );
}

export default memo(FormSelect);
