/*
 *
 * RegisterIdentity
 *
 */

import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectRegisterIdentity from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Grid, Typography } from '@material-ui/core';
import {
  resetStateAction,
  updateFormStateIdentityAction,
  updateProfileAction,
  initProfileAction,
} from './actions';
import {
  fetchProfileAction,
  setLoadingAction,
} from 'containers/Register/actions';
import FormDatePicker from '../../components/FormControl/datepicker';
import FormSelect, { SelectOption } from '../../components/FormControl/select';
import FormInput from '../../components/FormControl/input';
import FormAvatarUpload from './components/avatar-upload';
import { replace } from 'connected-react-router';
import UploadDocuments from 'containers/UploadDocuments';
import { get, OAUTH_TOKEN } from 'services/local-storage-service';
import Document from 'models/document';
import makeSelectRegister from 'containers/Register/selectors';
import { makeSelectCountries } from 'containers/App/selectors';
import CustomHelperText from '../../components/FormControl/components/helper-text';
import { FormattedMessage, useIntl } from 'react-intl';
import GetDocumentFileByDocumentIdQuery from 'gql/document/GetDocumentFileByDocumentId.gql';
import ArrowButton from 'components/ArrowButton';
import moment from 'moment';
import { isNull } from 'lodash';

const stateSelector = createStructuredSelector({
  registerIdentity: makeSelectRegisterIdentity(),
  register: makeSelectRegister(),
  countries: makeSelectCountries(),
});

const idDocumentTypeOptions: SelectOption[] = [
  { value: 'ID_CARD', label: 'ID Card' },
  { value: 'PASSPORT', label: 'Passport' },
];

interface Props {
  nextStep: (ev) => void;
  backStep: (ev) => void;
}

const RegisterIdentity = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  useInjectReducer({ key: 'registerIdentity', reducer: reducer });
  useInjectSaga({ key: 'registerIdentity', saga: saga });
  const [identityDocument, setIdentityDocument] = React.useState<Document>();
  const [residentDocument, setResidentDocument] = React.useState<Document>();
  const { registerIdentity, register, countries } = useSelector(stateSelector);
  const { updateSuccess, formState } = registerIdentity;
  const { profile } = register;
  const dispatch = useDispatch();
  const { nextStep, backStep } = props;

  const { data, refetch } = useQuery(GetDocumentFileByDocumentIdQuery, {
    variables: {
      documentIds:
        profile?.documentGroups.length > 0 &&
        profile?.documentGroups[0]?.documentGroup?.documents?.map(
          item => item.document.id,
        ),
    },
  });

  useEffect(() => {
    if (data && profile && profile['documentGroups']) {
      const documents = profile['documentGroups'][0].documentGroup.documents;

      documents
        .map(document => document.document)
        .forEach(document => {
          const filesOfThisDocument = data.document_file
            .filter(documentFile => {
              return (
                !isNull(documentFile.file) &&
                documentFile.documentId === document.id
              );
            })
            .map(documentFile => documentFile.file);
          if (document.type === 'KYC_DOCUMENT_ID') {
            setIdentityDocument(prevState => {
              const newFiles = prevState?.files || [];

              return {
                documentId: document.id,
                type: document.type,
                files: [...newFiles, ...filesOfThisDocument],
              };
            });
            onDocumentUpdate(
              'KYC_DOCUMENT_ID',
              filesOfThisDocument.map(file => {
                return file.id;
              }),
            );
          } else if (document.type === 'KYC_RESIDENCY_CERTIFICATE') {
            setResidentDocument(prevState => {
              const newFiles = prevState?.files || [];

              return {
                documentId: document.id,
                type: document.type,
                files: [...newFiles, ...filesOfThisDocument],
              };
            });

            onDocumentUpdate(
              'KYC_RESIDENCY_CERTIFICATE',
              filesOfThisDocument.map(file => {
                return file.id;
              }),
            );
          }
        });
    }
  }, [data]);

  useEffect(() => {
    const token = get(OAUTH_TOKEN);
    if (!token) {
      dispatch(replace('/login'));
    }
    if (updateSuccess) {
      nextStep(null);
      dispatch(resetStateAction());
      dispatch(fetchProfileAction());
      refetch();
    }
    dispatch(setLoadingAction(false));
  }, [updateSuccess, formState]);

  useEffect(() => {
    dispatch(fetchProfileAction());
  }, []);

  useEffect(() => {
    if (profile) {
      dispatch(initProfileAction({ ...profile, files: data }));
    }
  }, [profile]);

  const onClickNextIdentity = () => {
    dispatch(setLoadingAction(true));
    dispatch(updateProfileAction(formState));
  };

  const onDocumentUpdate = (type, items) => {
    if (type === 'KYC_DOCUMENT_ID') {
      updateFormState('individualDocFiles')(items);
    } else if (type === 'KYC_RESIDENCY_CERTIFICATE') {
      updateFormState('residenceDocFiles')(items);
    }
  };

  const updateFormState = field => stateValue => {
    const formField = formState[field];
    if (formField) {
      dispatch(
        updateFormStateIdentityAction([
          {
            field: field,
            state: { ...formField, value: stateValue },
          },
        ]),
      );
    }
  };

  const updateFormStateAvatar = field => localFile => {
    dispatch(
      updateFormStateIdentityAction([
        {
          field: field,
          state: {
            ...formState['avatar'],
            localFile: localFile,
            remoteUrl: '',
          },
        },
      ]),
    );
  };

  return (
    <div>
      {formState.userId && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <FormInput
                onChange={updateFormState('phone')}
                formState={formState.phone}
                title={`${intl.messages['model.phone']} *`}
                placeholder={`${intl.messages['model.phone']}`}
                type={'tel'}
                inputProps={{
                  maxLength: 15,
                }}
                hideTitle={true}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormAvatarUpload
                formState={formState.avatar}
                title={`${intl.messages['model.picturePath']}`}
                onChange={updateFormStateAvatar('avatar')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              { countries.length && <FormSelect
                formState={formState.nationality}
                defaultLabel={`${intl.messages['selectNationality']}`}
                onChange={updateFormState('nationality')}
                title={`${intl.messages['nationality']}`}
                hideTitle={true}
                options={countries.slice().sort((oneCountry, nextCountry) => oneCountry.nationality.localeCompare(nextCountry?.nationality)).map(item => {
                  return { label: item.nationality, value: item.alpha2Code };
                })}
              /> }
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormDatePicker
                onChange={updateFormState('birthday')}
                formState={formState.birthday}
                title={`${intl.messages['model.birthDate']}`}
                hideTitle={true}
                subtitle={'(dd-mm-yyyy)'}
                maxDate={moment()
                  .subtract(21, 'years')
                  .format('YYYY-MM-DD')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormSelect
                formState={formState.individualDocType}
                defaultLabel={`${intl.messages['selectDocumentType']}`}
                onChange={updateFormState('individualDocType')}
                title={`${intl.messages['model.individualDocType']}`}
                options={idDocumentTypeOptions}
                hideTitle={true}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormInput
                onChange={updateFormState('individualDocNumber')}
                formState={formState.individualDocNumber}
                title={`${intl.messages['idDocumentNumber']}`}
                placeholder={`${intl.messages['idNumber']}`}
                hideTitle={true}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <UploadDocuments
                title={`${intl.messages['files']}`}
                fileTypes={['docx', 'pdf', 'jpeg', 'xls', 'png']}
                onChange={fileIds => {
                  onDocumentUpdate('KYC_DOCUMENT_ID', fileIds);
                }}
                document={identityDocument}
                accept={
                  'image/jpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,	application/msword'
                }
              />
              <CustomHelperText formState={formState.individualDocFiles} />
            </Grid>
          </Grid>

          <Typography
            variant={'h4'}
            style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
          >
            <FormattedMessage id="model.documentGroups.identityDocument" />
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <FormInput
                onChange={updateFormState('address1')}
                formState={formState.address1}
                title={`${intl.messages['model.address1']}`}
                placeholder={`${intl.messages['model.address1']}`}
                hideTitle={true}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormInput
                onChange={updateFormState('address2')}
                formState={formState.address2}
                title={`${intl.messages['model.address2']}`}
                placeholder={`${intl.messages['model.address2']}`}
                hideTitle={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={2}>
              <FormInput
                onChange={updateFormState('zipCode')}
                formState={formState.zipCode}
                title={`${intl.messages['model.zipCode']}`}
                placeholder={`${intl.messages['model.zipCode']}`}
                hideTitle={true}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormInput
                onChange={updateFormState('city')}
                formState={formState.city}
                title={`${intl.messages['model.city']}`}
                placeholder={`${intl.messages['model.city']}`}
                hideTitle={true}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <FormSelect
                formState={formState.country}
                defaultLabel={`${intl.messages['selectCountry']}`}
                onChange={updateFormState('country')}
                title={`${intl.messages['model.country']}`}
                hideTitle={true}
                options={countries.map(item => {
                  return {
                    label: item.enShortName,
                    value: item.alpha2Code,
                  };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <UploadDocuments
                title={`${intl.messages['model.proofOfResidency']}`}
                fileTypes={['docx', 'pdf', 'jpeg', 'xls', 'png']}
                onChange={fileIds => {
                  onDocumentUpdate('KYC_RESIDENCY_CERTIFICATE', fileIds);
                }}
                document={residentDocument}
                accept={
                  'image/jpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,	application/msword'
                }
              />
              <CustomHelperText formState={formState.residenceDocFiles} />
            </Grid>
          </Grid>

          <div style={{ textAlign: 'right' }}>
            <ArrowButton
              text={`${intl.messages[`next`]}`}
              onClick={onClickNextIdentity}
              type="button"
              size="small"
            ></ArrowButton>
          </div>
        </div>
      )}
    </div>
  );
});

export default memo(RegisterIdentity);
