import React, { memo } from 'react';
import styled from 'styles/styled-components';
import { Typography, Divider, IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const HeaderImage = styled(InsertDriveFileOutlined)`
  width: 26px !important;
  height: 28px !important;
  margin-right: 10px;
`;

interface Props {
  title: string;
  style?: any;
  onClose: () => void;
}

function PopupTitle(props: Props) {
  const { title, style, onClose } = props;
  return (
    <Grid item container>
      <Grid item xs>
        <HeaderWrapper style={style}>
          <HeaderImage />
          <Typography variant={'h5'} style={{ lineHeight: 1.7 }}>
            {title}
          </Typography>
        </HeaderWrapper>
      </Grid>
      <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ paddingTop: 8 }}
        >
          <CloseIcon style={{ color: '#666666' }} />
        </IconButton>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default memo(PopupTitle);
