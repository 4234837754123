import { takeLatest, call, put, select } from 'redux-saga/effects';
import { resetPassword } from 'services/api/auth-service';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { updatePaswordSucceed } from './actions';

function* updatePasword(action) {
  try {
    yield call(resetPassword, action.payload);
    yield put(updatePaswordSucceed());
  } catch (err) {
    console.log(`err.response`, err.response.status);
    let errorMessage;
    if (err.response.status === 401) {
      errorMessage = 'tokenExpired';
    } else if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

// Individual exports for testing
export default function* resetPasswordSaga() {
  yield takeLatest(ActionTypes.UPDATE_PASSWORD, updatePasword);
}
