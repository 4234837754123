/*
 *
 * RegisterInvestments actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const fetchInvestmentsAction = userId =>
  action(ActionTypes.FETCH_INVESTMENTS_ACTION, userId);

export const updateFormStateInvestmentAction = states =>
  action(ActionTypes.UPDATE_FORM_STATE_INVESTMENT_ACTION, states);

export const updateStateInvestmentAction = states =>
  action(ActionTypes.UPDATE_STATE_INVESTMENT_ACTION, states);

export const updateStepAction = form =>
  action(ActionTypes.UPDATE_STEP_ACTION, form);

export const updateInvestmentAction = states =>
  action(ActionTypes.UPDATE_INVESTMENT_ACTION, states);

export const updateInvestmentSuccessAction = () =>
  action(ActionTypes.UPDATE_INVESTMENT_SUCCESS_ACTION);

export const getUserPreferencesAction = states =>
  action(ActionTypes.GET_USER_PREFERENCES, states);

export const getUserPreferencesSuccessAction = payload =>
  action(ActionTypes.GET_USER_PREFERENCES_SUCCESS, payload);
