/*
 *
 * Investors actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const fetchInvestorAction = payload =>
  action(ActionTypes.FETCH_INVESTOR_ACTION, payload);
export const fetchInvestorSuccessAction = payload =>
  action(ActionTypes.FETCH_INVESTOR_SUCCESS_ACTION, payload);
export const fetchInvestorFailedAction = payload =>
  action(ActionTypes.FETCH_INVESTOR_FAILED_ACTION, payload);
export const changeStatusAction = payload =>
  action(ActionTypes.CHANGE_STATUS_ACTION, payload);
export const changeStatusSuccessAction = payload =>
  action(ActionTypes.CHANGE_STATUS_SUCCESS_ACTION, payload);
export const changeStatusFailedAction = payload =>
  action(ActionTypes.CHANGE_STATUS_FAILED_ACTION, payload);
export const setRefreshPage = payload =>
  action(ActionTypes.SET_REFRESH_PAGE, payload);
