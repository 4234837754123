import React, { memo, useState } from 'react';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Input,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

interface Props {
  open: boolean;
  onCreate: (groupName: string) => void;
  onCancel: () => void;
  groupNames: string[];
}

function DocumentGroupCreator(props: Props) {
  const intl = useIntl();
  const { open, onCancel, onCreate, groupNames } = props;

  const [err, setErr] = useState('');
  const [groupName, setGroupName] = useState('');

  const handeCreate = () => {
    if (!groupName.trim()) {
      setErr(`${intl.messages['error.groupNameIsRequired']}`);
    } else if (groupNames.includes(groupName.trim())) {
      setErr(`${intl.messages['error.groupNameAlreadyExist']}`);
    } else {
      onCreate(groupName.trim());
      setErr('');
      onCancel();
      setGroupName('');
    }
  };

  const handleCancel = () => {
    onCancel();
    setErr('');
    setGroupName('');
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography style={{ paddingTop: 4, fontSize: '1.25rem' }}>
              {`${intl.messages['createGroup']}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 6 }}>
          {`${intl.messages['groupName']}`}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <Input
          fullWidth
          inputProps={{ maxLength: 40 }}
          style={{
            border: err ? '1px solid red' : '1px solid #bdbfc3',
            padding: '0 6px',
          }}
          disableUnderline
          onChange={e => setGroupName(e.target.value)}
        />
        {err && (
          <Typography
            style={{ color: 'red', paddingTop: 6, fontSize: '0.8rem' }}
          >
            {err}
          </Typography>
        )}
      </DialogContent>
      <DialogActions style={{ marginTop: 40 }}>
        <Button color="primary" variant="outlined" onClick={handleCancel}>
          {`${intl.messages['cancel']}`.toUpperCase()}
        </Button>
        <Button color="primary" variant="contained" onClick={handeCreate}>
          {`${intl.messages['create']}`.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(DocumentGroupCreator);
