import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
export const GreenOutlineButton = withStyles(() => ({
  root: {
    color: '#6DB595',
    border: '1px solid #6DB595',
    '&:hover': {
      color: '#5AA483',
      border: '1px solid #5AA483',
    },
    '&:disabled': {
      color: '#C8ECDC',
      border: '1px solid #C8ECDC',
    },
  },
}))(Button);
