/*
 *
 * ForgotPassword
 *
 */

import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link as RouterLink } from 'react-router-dom';

import {
  Typography,
  Container,
  Card,
  FormControl,
  CardContent,
  FormHelperText,
  Grid,
  OutlinedInput,
} from '@material-ui/core';
import Succeed from './components/Succeed';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { callResetPassword } from './actions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import makeSelectForgotPassword from './selectors';
import reducer from './reducer';
import saga from './saga';
import { useIntl, FormattedMessage } from 'react-intl';
import Img from 'components/Img';
import whiteIcon from '../../images/logos/Jadesquare-logo-white.svg';
import A from 'components/A';
import ArrowButton from 'components/ArrowButton';
import { push } from 'connected-react-router';

const validateMail = email => {
  // tslint:disable-next-line: max-line-length
  // eslint-disable-next-line no-control-regex
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email));
};

const stateSelector = createStructuredSelector({
  forgotPassword: makeSelectForgotPassword(),
});

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    minHeight: '100vh',
  },
  content: {
    backgroundColor: 'transparent',
    padding: '15px',
    color: 'var(--color-grey)',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 60px',
    },
  },
}));

function ForgotPassword() {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'forgotPassword', reducer: reducer });
  useInjectSaga({ key: 'forgotPassword', saga: saga });

  const classes = useStyles();
  const intl = useIntl();

  const [email, setEmail] = useState('');
  const [invalid, setInvalid] = useState(false);

  const { forgotPassword } = useSelector(stateSelector);

  const { succeed } = forgotPassword;

  const dispatch = useDispatch();

  const onClick = () => {
    if (validateMail(email)) {
      dispatch(callResetPassword({ email }));
      setInvalid(false);
    } else {
      setInvalid(true);
    }
  };
  const scope = 'ForgotPassword';
  return (
    <Container className="main">
      <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
        <RouterLink to="/">
          <Img src={whiteIcon} style={{ height: 30 }} />
        </RouterLink>
      </div>
      <Grid container justify={'center'}>
        <Grid item xs={12} md={8} xl={6}>
          <Card variant="outlined" className={classes.content}>
            <CardContent>
              {succeed ? (
                <Succeed email={email} />
              ) : (
                <>
                  <Typography
                    variant={'h3'}
                    style={{
                      marginBottom: 0,
                      color: 'white',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id={`${scope}.resetYourPassword`} />
                  </Typography>
                  <Typography
                    style={{
                      margin: '20px 0',
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id={`${scope}.infor`} />
                  </Typography>

                  <FormControl style={{ width: '100%' }}>
                    <OutlinedInput
                      placeholder={`${intl.messages[`model.email`]}`}
                      labelWidth={0}
                      fullWidth
                      type={'email'}
                      onChange={e => setEmail(e.target.value)}
                    />
                    {invalid && (
                      <FormHelperText
                        style={{
                          maxWidth: 330,
                          color: 'red',
                          fontSize: '0.75rem',
                        }}
                      >
                        <FormattedMessage id={`${scope}.invalid`} />
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Grid
                    container
                    spacing={4}
                    alignItems={'center'}
                    style={{ paddingTop: '100px' }}
                  >
                    <Grid item xs={12} lg={true}>
                      <A
                        style={{ color: 'var(--color-grey)' }}
                        onClick={() => {
                          dispatch(push('/login'));
                        }}
                      >
                        <FormattedMessage id={`Authentication.backToLogin`} />
                      </A>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={'auto'}
                      style={{ textAlign: 'right' }}
                    >
                      <ArrowButton
                        text={`${intl.messages[`sendInstructions`]}`}
                        type={'button'}
                        onClick={onClick}
                      ></ArrowButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default memo(ForgotPassword);
