import React from 'react';
import { makeStyles } from '@material-ui/core';

import { ReactComponent as ArrowSvg } from '../../images/icons/with-class/rounded-arrow.svg';

const useStyles = makeStyles({
  arrowButtonImage: {
    marginLeft: '15px',
    '& svg': {
      height: '44px',
      width: 'auto',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#999999',
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    fontSize: '1rem',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    lineHeight: '44px',
    '&:hover': {
      color: 'var(--color-light-grey)',
    },
  },
  small: {
    '&$link': {
      lineHeight: '28px',
    },
    '& $arrowButtonImage': {
      '& svg': {
        height: '28px',
      },
    },
  },
  big: {
    '&$link': {
      lineHeight: '56px',
    },
    '& $arrowButtonImage': {
      '& svg': {
        height: '56px',
      },
    },
  },
});

interface Props {
  text: string;
  onClick?: (event?: any) => void;
  type?: 'button' | 'link';
  buttonType?: 'button' | 'submit';
  href?: string;
  disabled?: boolean;
  size?: 'small' | 'normal' | 'big';
}

const ArrowButton = (props: Props) => {
  const styles = useStyles();
  const {
    text,
    onClick,
    type,
    buttonType,
    href,
    disabled,
    size = 'normal',
  } = props;

  const buttonContent = (
    <>
      {text} <ArrowSvg className={styles.arrowButtonImage} />
    </>
  );

  return (
    <>
      {(!type || type === 'link') && (
        <a
          className={`${styles.link} ${size !== 'normal' ? styles[size] : ''}`}
          onClick={onClick || undefined}
          href={href || undefined}
        >
          {buttonContent}
        </a>
      )}
      {type && type === 'button' && (
        <button
          type={buttonType || 'button'}
          className={`${styles.link} ${size !== 'normal' ? styles[size] : ''}`}
          onClick={onClick || undefined}
          disabled={disabled ?? false}
        >
          {buttonContent}
        </button>
      )}
    </>
  );
};

export default ArrowButton;
