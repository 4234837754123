/*
 *
 * ForgotPassword actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const callResetPassword = (request: { email: string }) =>
  action(ActionTypes.CALL_RESET_PASSWORD, request);

export const callResetPasswordSucceed = () =>
  action(ActionTypes.CALL_RESET_PASSWORD_SUCCEED);

export const resetState = () => action(ActionTypes.RESET_STATE);
