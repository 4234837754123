/*
 *
 * ResetPassword actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const updatePasword = (request: {
  confirmationPassword: string;
  encryptedString: string;
  newPassword: string;
}) => action(ActionTypes.UPDATE_PASSWORD, request);

export const updatePaswordSucceed = () =>
  action(ActionTypes.UPDATE_PASSWORD_SUCCEED);

export const resetState = () => action(ActionTypes.RESET_STATE);
