import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { ReactComponent as GlobeSvg } from 'images/icons/globe.svg';
import { ReactComponent as StockExchangeAppSvg } from 'images/icons/stock-exchange-app.svg';
import { ReactComponent as CoinSvg } from 'images/icons/coin.svg';
import { ReactComponent as ContractSvg } from 'images/icons/contract.svg';
import { ReactComponent as QuoteSvg } from 'images/icons/quote.svg';
import { backgroundColor } from 'styles/colors';
import InvestmentImage from '../../images/our-tech-image.jpg';
import SingaporeImage from '../../images/singapore-image.jpg';
import CeoImage from '../../images/ceo-profile.jpg';
import { useIntl } from 'react-intl';

const useStyle = makeStyles((theme: Theme) => ({
  textContainer: {
    maxWidth: '60%',
    paddingTop: '5rem',
  },
  container: {
    width: '100%',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  },
  paragraph: {
    textAlign: 'justify',
  },
  images: {
    width: '100%',
  },
  panelTextLeft: {
    //textAlign: 'right',
  },
  paragraphContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%',
    },
    maxWidth: '100%',
  },
  smallParagraphContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '70%',
    },
    maxWidth: '100%',
  },
  smallParagraphTitleContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
    maxWidth: '100%',
  },
  icon: {
    marginBottom: '1rem',
  },
  smallCard: {
    borderBottom: '1px solid white',
  },
  smallCardTitle: {
    marginBottom: 0,
  },
  ceoWrapper: {
    textAlign: 'center',
    marginBottom: '5rem',
  },
  ceoImage: {
    position: 'relative',
    zIndex: 998,
  },
  ceoSpeach: {
    // width: '100%',
    // height: '100%',
    backgroundColor: 'var(--color-super-dark-grey)',
    // zIndex: 1,
    // position: 'absolute',
    // transform: 'translate(-10%,35%)',
    // padding: '6rem 3rem 2rem 10rem',
    paddingTop: '8rem',
    paddingBottom: '4rem',
    borderRadius: '3px',
    border: '0.5px solid var(--color-medium-dark-grey)',
    paddingRight: '4rem',
    transform: 'translateX(-20%)',
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(0)',
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
  },
  quoteImage: {
    display: 'inline-block',
    marginRight: '0.5rem',
  },
  quoteWrapper: {
    zIndex: 999,
    display: 'inline-block',
    position: 'relative',
    transform: 'translate(50%,75%)',
    //marginTop: '2rem',
  },
  smallTitle: {
    fontSize: '20px',
  },
}));
const OurCompanyPage = () => {
  const intl = useIntl();
  const classes = useStyle();
  return (
    <Grid container direction="column">
      <Container maxWidth="lg">
        <Grid item xs={12} sm={12} md={11} lg={11}>
          <div style={{ paddingTop: '3rem' }}>
            <h2>{intl.messages['OurCompany.titleOne']}</h2>
          </div>
        </Grid>
        <div className={classes.container} style={{ paddingBottom: 0 }}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <img className={classes.images} src={InvestmentImage} />
            </Grid>
            <Grid item sm={12} md={6}>
              <div className={classes.paragraphContainer}>
                <p style={{ fontSize: '16px' }}>
                  {intl.messages['OurCompany.ourTechnology']}
                </p>
                <p style={{ fontSize: '16px' }}>
                  {intl.messages['OurCompany.descOne']}
                </p>
              </div>
            </Grid>
            <div style={{ marginTop: '5rem' }}>
              <Grid container>
                <Grid item md={3}>
                  <h2>{intl.messages['OurCompany.whoWeAre']}</h2>
                </Grid>
                <Grid item md={9}>
                  <p style={{ fontSize: '16px' }}>
                    {intl.messages['OurCompany.whoWeAreDesc']}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        <div className={classes.container} style={{ paddingBottom: 0 }}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <img className={classes.images} src={SingaporeImage} />
            </Grid>
            <Grid item sm={12} md={6}>
              <div className={classes.paragraphContainer}>
                <h2>{intl.messages['OurCompany.diligenceTitle']}</h2>
                <p style={{ fontSize: '16px' }}>
                  {intl.messages['OurCompany.diligenceDesc']}
                </p>
                <h5 style={{ marginBottom: 0 }}>
                  {intl.messages['OurCompany.singaporeTitle']}
                </h5>
                <p style={{ fontSize: '16px' }}>
                  {intl.messages['OurCompany.singaporeDesc']}
                </p>
              </div>
            </Grid>
            <div style={{ marginTop: '5rem' }}>
              <Grid container>
                <Grid item md={3}>
                  <h2>{intl.messages['OurCompany.liquidityOptions']}</h2>
                </Grid>
                <Grid item md={9}>
                  <p style={{ fontSize: '16px' }}>
                    {intl.messages['OurCompany.liquidityOptionsDesc']}
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </Container>
      <Container style={{ marginTop: '5rem', paddingBottom: '5rem' }}>
        <div className={classes.ceoWrapper}>
          <h2>{intl.messages['OurCompany.ceoTitle']}</h2>
        </div>
        <Grid container justify="flex-end">
          <Grid container item md={10}>
            <Grid item sm={12} md={4}>
              <div className={classes.ceoImage}>
                <img src={CeoImage} width="100%" />
              </div>
            </Grid>
            <Grid item md={8}>
              <div className={classes.quoteWrapper}>
                <QuoteSvg className={classes.quoteImage} />
                <QuoteSvg className={classes.quoteImage} />
              </div>
              <div className={classes.ceoSpeach}>
                <Grid container justify="flex-end">
                  <Grid item sm={12} md={8}>
                    <p style={{ fontSize: '16px' }}>
                      {intl.messages['OurCompany.ceoText']}
                    </p>
                    <p style={{ fontSize: '16px' }}>
                      {intl.messages['OurCompany.ceoText1']}
                    </p>
                    <h4 className={classes.smallTitle}>
                      {intl.messages['OurCompany.ceoName']}
                    </h4>
                    <p
                      style={{
                        color: 'var(--color-grey)',
                        fontSize: '16px',
                      }}
                    >
                      {intl.messages['OurCompany.ceoSign']}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default OurCompanyPage;
