/*
 *
 * ProfileDeals constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ProfileDeals/DEFAULT_ACTION',
  FETCH_DEAL_ACTION = 'app/ProfileDeals/FETCH_DEAL_ACTION',
  FETCH_DEAL_SUCCESS_ACTION = 'app/ProfileDeals/FETCH_DEAL_SUCCESS_ACTION',
}

export default ActionTypes;
