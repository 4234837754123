/*
 *
 * SignRequest constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/SignRequest/DEFAULT_ACTION',
  SET_OPEN = 'app/SignRequest/SET_OPEN',
  SEARCH_VEHICLES = 'app/SignRequest/SEARCH_VEHICLES',
  SEARCH_VEHICLES_SUCCEED = 'app/SignRequest/SEARCH_VEHICLES_SUCCEED',
  SEARCH_TEMPLATES = 'app/SignRequest/SEARCH_TEMPLATES',
  SEARCH_TEMPLATES_SUCCEED = 'app/SignRequest/SEARCH_TEMPLATES_SUCCEED',
  REQUEST_SIGN = 'app/SignRequest/REQUEST_SIGN',
  REQUEST_SIGN_SUCCEED = 'app/SignRequest/REQUEST_SIGN_SUCCEED',
  GET_PREVIEW = 'app/SignRequest/GET_PREVIEW',
  GET_PREVIEW_SUCCEED = 'app/SignRequest/GET_PREVIEW_SUCCEED',
}

export default ActionTypes;
