/*
 *
 * Deals
 *
 */

import React from 'react';
import { Location } from 'history';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import DealList from 'containers/DealList';

interface Props {
  location: Location;
}

function Deals(props: Props): JSX.Element {
  useInjectReducer({ key: 'deals', reducer: reducer });
  useInjectSaga({ key: 'deals', saga: saga });

  return (
    <div>
      <DealList location={props.location} />
    </div>
  );
}

export default Deals;
