import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
export const ActivateColorButton = withStyles(() => ({
  root: {
    color: 'var(--color-dark-purple)',
    border: '1px solid var(--color-dark-purple)',
    '&:hover': {
      color: 'var(--color-light-grey)',
      border: '1px solid var(--color-dark-purple)',
      backgroundColor:'var(--color-dark-purple)'
    },
    '&:disabled': {
      color: 'var(--color-light-grey)',
      backgroundColor:'var(--color-dark-purple)',
      border: '1px solid var(--color-dark-purple)',
    },
  },
}))(Button);
