/*
 *
 * Authentication actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const signIn = loginForm => action(ActionTypes.SIGN_IN, loginForm);

export const signInSuccess = () => action(ActionTypes.SIGN_IN_SUCCESS);

export const signInError = error => action(ActionTypes.SIGN_IN_ERROR, error);
