/*
 *
 * Home reducer
 *
 */

import { ContainerState, ContainerActions } from './types';
import ActionTypes from './constants';
import { produce, Draft } from 'immer';

// The initial state of the App
export const initialState: ContainerState = {
  selectedTab: 1,
  isLoading: false,
};

// Take this container's state (as a slice of root state), this container's actions and return new state
const homeReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.CHANGE_TAB_ACTION:
        draft.selectedTab = action.payload;
        break;
      case ActionTypes.SET_ISLOADING:
        draft.isLoading = action.payload;
        break;
      default:
        break;
    }
  },
  initialState,
);

export default homeReducer;
