import { takeLatest, call, put } from 'redux-saga/effects';
import { editTicket } from 'services/api/ticket-service';
import { cancelTicketSuccesAction, cancelTicketFailedAction } from './actions';
import ActionTypes from './constants';

function* cancelTickets(action) {
  const request = {
    status: 'CANCELLED',
    ticketId: action.payload,
  };

  try {
    const response = yield call(editTicket, request);
    yield put(cancelTicketSuccesAction(response));
  } catch (err) {
    yield put(cancelTicketFailedAction(err));
  }
}

export default function* ticketsSaga() {
  yield takeLatest(ActionTypes.CANCEL_TICKET, cancelTickets);
}
