import produce, { Draft } from "immer";
import ActionTypes from "./constants";
import { ContainerState, ContainerActions } from "./types";

export const initialState: ContainerState = {
	deal: {
		company: {},
	},
	error: false,
	loading: false,
	noOfTickets: 0,
	publicDocuments: [],
};

const dealDetailReducer = produce((draft: Draft<ContainerState>, action: ContainerActions) => {
	switch (action.type) {
		case ActionTypes.SET_DEAL_DESCRIPTION:
			draft.deal = {
				...draft.deal,
				...action.payload,
			};
			break;
		case ActionTypes.UPDATE_DEAL:
			draft.deal = {
				company: draft.deal.company,
				...action.payload,
			};
			break;
		case ActionTypes.UPDATE_DEAL_STATUS_SUCCEED:
			draft.deal.status = action.payload;
			break;
		case ActionTypes.GET_DOCUMENTS_SUCCEED:
      		draft.publicDocuments = action.payload;
			break;
		case ActionTypes.GET_USER_TICKET_SUCCESS:
			draft.noOfTickets = action.payload["total"];
			break;
		case ActionTypes.REQUEST_SIGN:
			draft.loading = true;
			break;
		case ActionTypes.REQUEST_SIGN_SUCCEED:
			draft.loading = false;
			draft.deal = {
				...draft.deal,
				esignDocument: action.payload[0],
			};
			break;
		case ActionTypes.REQUEST_SIGN_FAILED:
			draft.loading = false;
			break;
	}
}, initialState);

export default dealDetailReducer;
