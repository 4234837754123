/*
 *
 * ProfileVehicles actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const fetchUserVehicleAction = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE, payload);
export const fetchUserSuccessVehicleAction = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_SUCCESS, payload);
export const fetchUserVehicleFailedAction = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_SUCCESS, payload);
export const updateUserVehicleAction = payload =>
  action(ActionTypes.UPDATE_USER_VEHICLE, payload);
export const updateUserVehicleSuccessAction = payload =>
  action(ActionTypes.UPDATE_USER_VEHICLE_SUCCESS, payload);
export const updateUserVehicleFailedAction = payload =>
  action(ActionTypes.UPDATE_USER_VEHICLE_FAILED, payload);
export const createUserVehicleAction = payload =>
  action(ActionTypes.CREATE_USER_VEHICLE, payload);
export const createUserVehicleSuccessAction = payload =>
  action(ActionTypes.CREATE_USER_VEHICLE_SUCCESS, payload);
export const createUserVehicleFailedAction = payload =>
  action(ActionTypes.CREATE_USER_VEHICLE_FAILED, payload);
