const PREFIX = `local::`;

export function set<T = object>(key: string, value: T): void {
  if (!localStorage) {
    return;
  }

  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(PREFIX + key, serializedValue);
  } catch (error) {
    throw new Error('store serialization failed');
  }
}

export function get<T = object>(key: string): T | undefined {
  if (!localStorage) {
    return null;
  }

  try {
    const serializedValue = localStorage.getItem(PREFIX + key);
    if (serializedValue == null) {
      return null;
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

export function removeItem(key: string) {
  if (!localStorage) {
    return null;
  }
  try {
    localStorage.removeItem(PREFIX + key);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

export function clear() {
  if (!localStorage) {
    return null;
  }
  try {
    removeItem(REFRESH_TOKEN);
    removeItem(OAUTH_TOKEN);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

export const OAUTH_TOKEN = 'ih_access_token';
export const REFRESH_TOKEN = 'ih_refresh_token';
export const ANONYMOUS_ID = 'anonymousId';

export default {
  get,
  set,
  clear,
  removeItem,
  OAUTH_TOKEN,
  REFRESH_TOKEN,
  ANONYMOUS_ID,
};
