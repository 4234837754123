import React, { memo, useState } from 'react';
import CustomForm from 'components/FormControl';
import HtmlEditor from 'components/HtmlEditor';
import { Dialog, Grid, Button, makeStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { FormattedMessage, useIntl } from 'react-intl';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { stateToHTML } from 'draft-js-export-html';

import PopupTitle from 'components/PopupTitle';
import { LANGUAGES } from 'utils/constants';

interface Props {
  onCancel: () => void;
  onUpdate: (any) => void;
  open: boolean;
  scope: string;
}

const useStyle = makeStyles({
  fullWidth: {
    width: '100%',
  },
  formElement: {
    margin: '10px 0',
  },
});

const EmailForm = (props: Props) => {
  const classes = useStyle();
  const intl = useIntl();
  const { onUpdate, onCancel, open, scope } = props;
  const [value, setValue] = React.useState('en');
  const localScope = `${scope}.emailForm`;
  const initialFormState = {
    subject: {},
    bodyTitle: {},
    bodyText: {
      en: createEditorStateWithText(''),
      de: createEditorStateWithText(''),
      ja: createEditorStateWithText(''),
    },
    buttonTitle: {},
    buttonURL: {},
  };
  const [formState, setFormState] = useState(initialFormState);

  const updateFormState = (field, subfield = '') => stateValue => {
    if (stateValue === undefined || stateValue === null) {
      return;
    }

    if (formState[field]) {
      setFormState(prevState => {
        const formField = prevState[field];
        let newFieldState;

        if (subfield) {
          newFieldState = { ...formField, [subfield]: stateValue };
        } else {
          newFieldState = stateValue;
        }

        return { ...prevState, [field]: newFieldState };
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSend = () => {
    const htmlTransformedBodyText = {};
    Object.keys(formState.bodyText).forEach(language => {
      htmlTransformedBodyText[language] = stateToHTML(
        formState.bodyText[language].getCurrentContent(),
      );
    });
    onUpdate({
      subject: formState.subject,
      bodyTitle: formState.bodyTitle,
      bodyText: htmlTransformedBodyText,
      buttonTitle: formState.buttonTitle,
      buttonURL: formState.buttonURL,
    });
    setFormState(initialFormState);
  };

  return (
    <>
      <Dialog open={open} maxWidth="lg">
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <PopupTitle
                title={`${intl.messages[`${localScope}.sendEmailToInvestors`]}`}
                onClose={onCancel}
              />
            </Grid>
            <TabContext value={value}>
              <TabList
                onChange={handleTabChange}
                aria-label={`${intl.messages['languageTabs']}`}
                variant="fullWidth"
                classes={{
                  root: classes.fullWidth,
                }}
              >
                <Tab label={`${intl.messages['en']}`} value="en" />
                <Tab label={`${intl.messages['de']}`} value="de" />
                <Tab label={`${intl.messages['ja']}`} value="ja" />
              </TabList>
              {LANGUAGES.map(language => (
                <TabPanel
                  value={language}
                  key={language}
                  classes={{
                    root: classes.fullWidth,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className={classes.formElement}>
                      <CustomForm
                        type="input"
                        onChange={value =>
                          updateFormState('subject', language)(value)
                        }
                        title={`${intl.messages[`${localScope}.subject`]}`}
                        defaultValue={formState.subject[language]}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.formElement}>
                      <CustomForm
                        type="input"
                        onChange={value =>
                          updateFormState('bodyTitle', language)(value)
                        }
                        title={`${intl.messages[`${localScope}.bodyTitle`]}`}
                        defaultValue={formState.bodyTitle[language]}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <HtmlEditor
                      editorState={formState.bodyText[language]}
                      onChange={value =>
                        updateFormState('bodyText', language)(value)
                      }
                    />
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className={classes.formElement}>
                      <CustomForm
                        type="input"
                        onChange={value =>
                          updateFormState('buttonTitle', language)(value)
                        }
                        title={`${intl.messages[`${localScope}.buttonTitle`]}`}
                        defaultValue={formState.buttonTitle[language]}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.formElement}>
                      <CustomForm
                        type="input"
                        onChange={value =>
                          updateFormState('buttonURL', language)(value)
                        }
                        title={`${intl.messages[`${localScope}.buttonURL`]}`}
                        defaultValue={formState.buttonURL[language]}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              ))}
            </TabContext>
            <Grid item xs={12} sm={6} />
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                size={'small'}
                style={{ marginRight: 24 }}
                color="primary"
                variant="outlined"
                onClick={onCancel}
              >
                <FormattedMessage id={`cancel`} />
              </Button>
              <Button
                style={{
                  backgroundColor: 'var(--color-dark-purple)',
                  color: 'var(--color-light-grey)',
                }}
                size={'small'}
                variant="contained"
                onClick={handleSend}
              >
                <FormattedMessage id={`send`} />
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default memo(EmailForm);
