/*
 *
 * RegisterSignUp constants
 *
 */

enum ActionTypes {
  SIGN_UP_SUCCESS = 'app/RegisterSignUp/SIGN_UP_SUCCESS',
  SIGN_UP_ACTION = 'app/RegisterSignUp/SIGN_UP_ACTION',
  UPDATE_FORM_STATE_SIGN_UP_ACTION = 'app/RegisterSignUp/UPDATE_FORM_STATE_SIGN_UP_ACTION',
}

export default ActionTypes;
