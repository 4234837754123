/*
 *
 * Home constants
 *
 */

enum ActionTypes {
  CHANGE_TAB_ACTION = 'app/Home/CHANGE_TAB_ACTION',
  FETCH_USER_ACTION = 'app/Home/FETCH_USER_ACTION',
  FETCH_USER_VEHICLE_ACTION = 'app/Home/FETCH_USER_VEHICLE_ACTION',
  FETCH_USER_VEHICLE_SUCCESS_ACTION = 'app/Home/FETCH_USER_VEHICLE_SUCCESS_ACTION',
  FETCH_USER_SUCCESS_ACTION = 'app/Home/FETCH_USER_SUCCESS_ACTION',
  FETCH_USER_FAILED_ACTION = 'app/Home/FETCH_USER_FAILED_ACTION',
  FETCH_PREFERENCE_ACTION = 'app/Home/FETCH_PREFERENCE_ACTION',
  FETCH_PREFERENCE_SUCCESS_ACTION = 'app/Home/FETCH_PREFERENCE_SUCCESS_ACTION',
  SET_ISLOADING = 'app/Home/SET_ISLOADING',
}

export default ActionTypes;
