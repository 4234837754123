/*
 *
 * DealDescription actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const sendComment = (request: {
  template: string;
  contentParams: {
    REPLY_TO_EMAIL: string;
    RECEIVER: string;
    DEAL_NAME?: string;
    TOPIC: string;
    DEAL_ID?: string;
    INVESTOR_ID?: string;
    INVESTOR_NAME?: string;
    MESSAGE: string;
    EMAIL_HEADER: string;
  };
}) => action(ActionTypes.SEND_COMMENT, request);
export const setTopic = value => action(ActionTypes.SET_TOPIC, value);
export const setMessage = value => action(ActionTypes.SET_MESSAGE, value);
export const uploadBannerLogoSucceed = value =>
  action(ActionTypes.UPLOAD_BANNER_LOGO_SUCCEED, value);
export const uploadBannerLogo = value =>
  action(ActionTypes.UPLOAD_BANNER_LOGO, value);
export const emailToInvestors = value =>
  action(ActionTypes.EMAIL_TO_INVESTORS, value);
