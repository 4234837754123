/*
 *
 * Authentication constants
 *
 */

enum ActionTypes {
  SIGN_IN = 'app/Authentication/SIGN_IN',
  SIGN_OUT = 'app/Authentication/SIGN_OUT',
  SIGN_IN_SUCCESS = 'app/Authentication/SIGN_IN_SUCCESS',
  SIGN_IN_ERROR = 'app/Authentication/SIGN_IN_ERROR',
}

export default ActionTypes;
