import { call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import * as actions from './actions';
import ProfileResponse from '../../models/response/profile-response';
import {
  me,
  updateProfile,
  updaStepProfile,
} from '../../services/api/profile-service';
import ProfileRequest from '../../models/request/profile-request';
import { showToast } from '../App/actions';
import { InputState } from '../../components/FormControl/input';
import {
  FormState,
  initialFormState,
} from '../../components/FormControl/state';
import { SelectState } from '../../components/FormControl/select';
import { DatePickerState } from '../../components/FormControl/datepicker';
import { AvatarUploadState } from './components/avatar-upload';

import { updateDocumentFiles } from 'services/api/file-service';
import {
  fetchProfileSuccessAction,
  setLoadingAction,
} from 'containers/Register/actions';
import makeSelectRegister from 'containers/Register/selectors';
import makeSelectRegisterIdentity from './selectors';
import { uploadUserProfilePicture } from 'services/api/upload-service';

function* updateProfileAction(action) {
  put(setLoadingAction(true));
  const { profile } = yield select(makeSelectRegister());
  const { documentGroups } = yield select(makeSelectRegisterIdentity());
  yield select(makeSelectRegister());
  const states: FormState[] = action.payload;
  let isValid = true;
  const userId: string = states['userId'];
  const firstName: InputState = { ...states['firstName'], ...initialFormState };
  const lastName: InputState = { ...states['lastName'], ...initialFormState };
  const phone: InputState = { ...states['phone'], ...initialFormState };
  const avatar: AvatarUploadState = {
    ...states['avatar'],
    ...initialFormState,
  };
  const nationality: SelectState = {
    ...states['nationality'],
    ...initialFormState,
  };
  const birthday: DatePickerState = {
    ...states['birthday'],
    ...initialFormState,
  };
  const individualDocType: SelectState = {
    ...states['individualDocType'],
    ...initialFormState,
  };
  const individualDocNumber: InputState = {
    ...states['individualDocNumber'],
    ...initialFormState,
  };
  const individualDocFiles: any = {
    value: [],
    ...states['individualDocFiles'],
    ...initialFormState,
  };
  const address1: InputState = { ...states['address1'], ...initialFormState };
  const address2: InputState = { ...states['address2'], ...initialFormState };
  const zipCode: InputState = { ...states['zipCode'], ...initialFormState };
  const city: InputState = { ...states['city'], ...initialFormState };
  const country: SelectState = { ...states['country'], ...initialFormState };
  const residenceDocFiles: any = {
    value: [],
    ...states['residenceDocFiles'],
    ...initialFormState,
  };

  let upload: any = null;

  if (avatar.localFile) {
    if (avatar.localFile.name) {
      upload = yield call(uploadUserProfilePicture, avatar.localFile);
    } else {
      upload = { url: avatar.remoteUrl || '' };
    }
  }

  if (!individualDocFiles?.value || individualDocFiles?.value.length === 0) {
    individualDocFiles.error = true;
    individualDocFiles.helperMessage = 'individualDocFilesRequired';
    isValid = false;
  }

  if (!residenceDocFiles.value || residenceDocFiles.value.length === 0) {
    residenceDocFiles.error = true;
    residenceDocFiles.helperMessage = 'individualDocFilesRequired';
    isValid = false;
  }

  const uploadDocumentGroup = documentGroups[0]?.documentGroup?.documents;

  const request: ProfileRequest = {
    userId: userId,
    firstName: firstName.value ? firstName.value.trim() : '',
    lastName: lastName.value ? lastName.value.trim() : '',
    phone: phone.value ? phone.value.trim() : '',
    nationality: nationality.value,
    birthday: birthday.value,
    individualDocType: individualDocType.value,
    individualDocNumber: individualDocNumber.value,
    address1: address1.value,
    address2: address2.value,
    zipCode: zipCode.value,
    city: city.value,
    country: country.value,
    registerStep:
      !profile.registerStep || profile.registerStep === 'IDENTITY'
        ? 'VEHICLE'
        : profile.registerStep,
    documentGroups: [
      {
        documents: uploadDocumentGroup.map(item => {
          let files;

          if (item.document.type === 'KYC_DOCUMENT_ID') {
            files = individualDocFiles.value;
          } else if (item.document.type === 'KYC_RESIDENCY_CERTIFICATE') {
            files = residenceDocFiles.value;
          } else {
            isValid = false;
          }

          return { ...item.document, files };
        }),
      },
    ],
    documents: {
      'KYC_DOCUMENT_ID': individualDocFiles.value,
      'KYC_RESIDENCY_CERTIFICATE': residenceDocFiles.value
    },
    picturePath: upload ? upload.url : '',
  };
  if (!request.firstName) {
    firstName.error = true;
    firstName.helperMessage = 'firstNameRequired';
    isValid = false;
  }
  if (!request.phone) {
    phone.error = true;
    phone.helperMessage = 'phoneRequired';
    isValid = false;
  }
  if (!request.lastName) {
    lastName.error = true;
    lastName.helperMessage = 'lastNameRequired';
    isValid = false;
  }
  if (!request.nationality) {
    nationality.error = true;
    nationality.helperMessage = 'nationalityRequired';
    isValid = false;
  }
  if (!request.birthday) {
    birthday.error = true;
    birthday.helperMessage = 'birthdayRequired';
    isValid = false;
  }
  if (!request.individualDocNumber) {
    individualDocNumber.error = true;
    individualDocNumber.helperMessage = 'individualDocNumberRequired';
    isValid = false;
  }
  if (!request.individualDocType) {
    individualDocType.error = true;
    individualDocType.helperMessage = 'individualDocTypeRequired';
    isValid = false;
  }
  if (!request.address1) {
    address1.error = true;
    address1.helperMessage = 'address1Required';
    isValid = false;
  }
  if (!request.zipCode) {
    zipCode.error = true;
    zipCode.helperMessage = 'zipCodeRequired';
    isValid = false;
  }
  if (!request.city) {
    city.error = true;
    city.helperMessage = 'cityRequired';
    isValid = false;
  }
  if (!request.country) {
    country.error = true;
    country.helperMessage = 'countryRequired';
    isValid = false;
  }
  if (!residenceDocFiles) {
    country.error = true;
    country.helperMessage = 'countryRequired';
    isValid = false;
  }

  if (isValid) {
    try {
      const updateProfileResponse: ProfileResponse = yield call(
        updateProfile,
        request,
      );
      const stepRequest = {
        email: profile.email,
        userStep:
          !profile.registerStep || profile.registerStep === 'IDENTITY'
            ? 'VEHICLE'
            : profile.registerStep,
      };
      const updateStepResponse: ProfileResponse = yield call(
        updaStepProfile,
        stepRequest,
      );
      yield put(actions.updateProfileSuccessAction());
    } catch (err) {
      yield put(actions.updateProfileFailedAction());
      yield put(
        showToast({
          message: err?.response?.data?.messages[0]
            ? err.response.data.messages[0]
            : 'unexpectedError',
          type: 'error',
        }),
      );
    } finally {
      put(setLoadingAction(false));
    }
  }

  yield put(
    actions.updateFormStateIdentityAction([
      { field: 'firstName', state: firstName },
      { field: 'lastName', state: lastName },
      { field: 'avatar', state: avatar },
      { field: 'phone', state: phone },
      { field: 'individualDocFiles', state: individualDocFiles },
      { field: 'residenceDocFiles', state: residenceDocFiles },
      { field: 'nationality', state: nationality },
      { field: 'birthday', state: birthday },
      { field: 'individualDocNumber', state: individualDocNumber },
      { field: 'individualDocType', state: individualDocType },
      { field: 'address1', state: address1 },
      { field: 'zipCode', state: zipCode },
      { field: 'city', state: city },
      { field: 'country', state: country },
    ]),
  );

}

function* updateStepAction(action) {
  put(setLoadingAction(true));
  let { profile } = yield select(makeSelectRegister());
  if (!profile) {
    const profileResponse = yield call(me);
    yield put(fetchProfileSuccessAction(profileResponse));
    profile = profileResponse;
  }
  if (!profile) {
    yield put(actions.fetchMeErrorAction());
    return;
  }

  if (!profile.registerStep || profile.registerStep === 'IDENTITY') {
    const request = {
      email: profile.email,
      userStep:
        !profile.registerStep || profile.registerStep === 'IDENTITY'
          ? 'VEHICLE'
          : profile.registerStep,
    };
    const updateProfileResponse: ProfileResponse = yield call(
      updaStepProfile,
      request,
    );
  }
  put(setLoadingAction(false));
}

function* uploadDocumentRequest(action) {
  const { documentId, fileIds } = action.payload;
  const updateProfileResponse: ProfileResponse = yield call(
    updateDocumentFiles,
    documentId,
    fileIds,
  );
}

export default function* registerIdentitySaga() {
  yield takeLatest(ActionTypes.UPDATE_PROFILE_ACTION, updateProfileAction);
  yield takeLatest(ActionTypes.UPDATE_STEP_ACTION, updateStepAction);
  yield takeLatest(ActionTypes.UPLOAD_DOCUMENT_ACTION, uploadDocumentRequest);
}
