import { takeLatest, call, put } from 'redux-saga/effects';
import ActionTypes from './constants';
import { searchInvitation } from 'services/api/profile-service';
import { submitDealInvestor } from 'services/api/deal-service';
import { RestResult } from 'models/response/rest-result';
import Company from 'models/company';
import * as actions from './actions';
import { showToast } from 'containers/App/actions';

export function* fetchInvestorRequest(action) {
  const request = action.payload;
  try {
    const result: RestResult<Company> = yield call(searchInvitation, request);
    if (result) {
      yield put(actions.fetchInvestorsSuccessAction(result));
    }
  } catch (err) {
    yield put(actions.fetchInvestorsFailedAction());
  }
}

export function* submitDealInvestorRequest(action) {
  const request = action.payload;
  try {
    const result: RestResult<Company> = yield call(submitDealInvestor, request);
    if (result) {
      yield put(actions.submitDealSuccessInvestor(result));
      yield put(
        showToast({
          message: 'investorHaveBeenSuccessfullyInvited',
          type: 'success',
        }),
      );
    }
  } catch (err) {
    yield put(actions.submitDealFailedInvestor());
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* inviteInvestorSaga() {
  yield takeLatest(ActionTypes.FETCH_INVESTORS_ACTION, fetchInvestorRequest);
  yield takeLatest(
    ActionTypes.SUBMIT_DEAL_INVESTOR_ACTION,
    submitDealInvestorRequest,
  );
}
