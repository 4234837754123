/*
 *
 * DealFlow actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const cancelInvite = (data: { dealId: string; search?: any }) =>
  action(ActionTypes.CANCEL_INVITE, data);
