import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the TicketEdit state domain
 */

const selectTicketEditDomain = (state: ApplicationRootState) => {
  return state.ticketEdit || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by TicketEdit
 */

const makeSelectTicketEdit = () =>
  createSelector(selectTicketEditDomain, substate => {
    return substate;
  });

export default makeSelectTicketEdit;
export { selectTicketEditDomain };
