/*
 *
 * TemplateCreate constants
 *
 */

enum ActionTypes {
  CLEAR_ACTION = 'app/TemplateCreate/CLEAR_ACTION',
  SUBMIT_ACTION = 'app/TemplateCreate/SUBMIT_ACTION',
  SUBMIT_FAILED_ACTION = 'app/TemplateCreate/SUBMIT_FAILED_ACTION',
  GET_KEY_SUCCESS = 'app/ProfileVehicles/GET_KEY_SUCCESS',
  GET_KEY = 'app/ProfileVehicles/GET_KEY',
  SET_LOADING = 'app/TemplateCreate/SET_LOADING',
}

export default ActionTypes;
