import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Switch } from '@material-ui/core';

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 30,
      height: 16,
      padding: 0,
      display: 'inline-flex',
      color: 'var(--color-grey)'
    },
    switchBase: {
      padding: 2,
      color: 'white',
      '&$checked': {
        transform: 'translateX(13px)',
        color: 'white',
        '& + $track': {
          opacity: 1,
          backgroundColor: 'var(--color-dark-purple)',
          borderColor: 'var(--color-dark-purple)',
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid #BBBBBB`,
      borderRadius: 999,
      opacity: 1,
      backgroundColor: 'transparent',
    },
    checked: {},
  }),
)(Switch);

export default AntSwitch;
