/*
 *
 * DealList
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table, { HeaderProps } from 'components/Table';
import Status from 'components/Status';
import InviteInvestor from 'models/invite-investor';
import Header from './templateHeader';
import { SigningTemplate } from 'models/signing-template';
import { useIntl, FormattedMessage } from 'react-intl';
import { DeactivateColorButton } from 'components/DeactiveButton/DeactivateColorButton';
import { GreenOutlineButton } from 'components/GreenOutlineButton/GreenOutlineButton';
import { updateTemplateStatusAction, getPreview } from '../actions';
import { TEMPLATE_CATEGORY } from 'utils/constants';
// import SigningTemplate from 'models/signing-template';
const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
    border: '1px solid #E5E5E5',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 8,
  },
  card: {
    border: '1px solid #E5E5E5',
    borderRadius: 0,
  },
  container: {
    padding: 12,
  },
  image: {
    width: 25,
    height: 25,
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  descriptionText: {
    textAlign: 'left',
    marginTop: 8,
  },
  title: {
    fontWeight: 'bold',
    color: '#588FDB',
    minWidth: 60,
  },
  tagnInterest: {
    width: '40%',
  },
  firstRow: {
    width: '5%',
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: '#CFE1FF',
    color: '#588FDB',
    fontWeight: 'bold',
    borderRadius: 2,
    display: 'inline-block',
  },
  statusActive: {
    color: '#6DB595',
  },
  statusRegistered: {
    color: '#000',
  },
  statusInActive: {
    color: '#D74F4F',
  },
  tableCell10: {
    width: '10%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell30: {
    width: '30%',
  },
  tableCell35: {
    width: '35%',
  },
  btn: {
    margin: 4,
  },
}));

const templateType = TEMPLATE_CATEGORY.reduce((mapAccumulator, obj) => {
  // either one of the following syntax works
  // mapAccumulator[obj.key] = obj.val;
  mapAccumulator.set(obj.value, obj.label);

  return mapAccumulator;
}, new Map());

interface Props {
  items: SigningTemplate[];
  total: number;
  fetchPage?: (page: number) => void;
  onOpen: (item) => void;
}

function TemplateList(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items, total, fetchPage, onOpen } = props;
  const [page, setPage] = React.useState(1);
  const handleFetchPage = (currentPage: number) => {
    setPage(currentPage);
    if (fetchPage) {
      fetchPage(currentPage);
    }
  };

  const handleDeactive = (item: SigningTemplate) => {
    dispatch(
      updateTemplateStatusAction({
        esignTemplateId: item.esignTemplateId,
        status: 'INACTIVE',
      }),
    );
  };
  const handleActive = (item: SigningTemplate) => {
    dispatch(
      updateTemplateStatusAction({
        esignTemplateId: item.esignTemplateId,
        status: 'ACTIVE',
      }),
    );
  };

  const previewSample = item => {
    dispatch(getPreview(item.esignTemplateId));
  };
  const headers: HeaderProps[] = [
    {
      name: 'Template name',
      headerClass: classes.tableCell30,
    },
    {
      name: 'Template category',
      align: 'left',
      headerClass: classes.tableCell15,
    },
    {
      name: 'Using',
      align: 'left',
      headerClass: classes.tableCell10,
    },
    {
      name: 'Status',
      headerClass: classes.tableCell15,
    },
    { name: '', headerClass: classes.tableCell30 },
  ];
  const localScope = 'SettingsDocument.templateTable';
  const renderItem = (item: SigningTemplate, index: number) => {
    return [
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index} className={classes.title}>
        <Typography
          onClick={ev => {
            onOpen(item);
          }}
        >
          {item.name}
        </Typography>
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline

      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index} className={classes.statusActive}>
        {templateType.get(item.category)}
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline

      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        {item.countDeal}
        <FormattedMessage
          id={`${localScope}.deal`}
          values={{ num: item.countDeal }}
        />
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        <Status name={item.status} />
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        <Button
          color="primary"
          variant="outlined"
          className={classes.btn}
          onClick={() => previewSample(item)}
        >
          <FormattedMessage id={`previewSample`} />
        </Button>
        {item.status === 'ACTIVE' ? (
          <DeactivateColorButton
            className={classes.btn}
            onClick={ev => handleDeactive(item)}
          >
            <FormattedMessage id={`deactive`} />
          </DeactivateColorButton>
        ) : (
          <GreenOutlineButton
            className={classes.btn}
            onClick={ev => handleActive(item)}
          >
            <FormattedMessage id={`active`} />
          </GreenOutlineButton>
        )}
      </div>,
    ];
  };

  return (
    <>
      <Header matches={total} onCreate={() => onOpen(undefined)} />
      <Table
        headers={headers}
        items={items}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
        currentPage={page}
      />
    </>
  );
}

export default memo(TemplateList);
