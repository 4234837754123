import { call, put, takeEvery } from 'redux-saga/effects';
import uploadFile from 'utils/uploadFile';
import ActionTypes from './constants';
import { uploadFileSuccessAction, uploadFileFailedAction } from './actions';

function* uploadFiles(action) {
  const { document, file } = action.payload;
  try {
    const response = yield call(uploadFile, file);
    yield put(
      uploadFileSuccessAction({
        documentId: document.documentId,
        type: document.type,
        file: response[0],
      }),
    );
  } catch (err) {
    yield put(
      uploadFileFailedAction({ document: document, localId: file.localId }),
    );
  }
}

export default function* ticketsSaga() {
  yield takeEvery(ActionTypes.UPLOAD_FILE_ACTION, uploadFiles);
}
