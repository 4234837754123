import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the resetPassword state domain
 */

const selectResetPasswordDomain = (state: ApplicationRootState) => {
  return state.resetPassword || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ResetPassword
 */

const makeSelectResetPassword = () =>
  createSelector(selectResetPasswordDomain, substate => {
    return substate;
  });

export default makeSelectResetPassword;
export { selectResetPasswordDomain };
