import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the tickets state domain
 */

const selectTicketsDomain = (state: ApplicationRootState) => {
  return state.tickets || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Tickets
 */

const makeSelectTickets = () =>
  createSelector(selectTicketsDomain, substate => {
    return substate;
  });

export default makeSelectTickets;
export { selectTicketsDomain };
