import React, { memo, useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import Deal from 'models/deal';
import ErrorToast from 'components/ErrorToast';
import Header from './Header';
import EditButton from './EditButton';
import { DetailWrapper } from '../styles';
import FullDescriptionEditor from './FullDescriptionEditor';
import { FormattedMessage } from 'react-intl';
import GetDealFullDescriptionsByIdQuery from 'gql/deal/GetDealFullDescriptionsById.gql';
import { convertVideoSrcToEmbed } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  descriptionContent: {
    "& img": {
      maxWidth: "100%"
    },
    "& a": {
      color: "white"
    }
  }
}));

interface Props {
  deal: Deal;
  admin?: boolean;
  onUpdate: () => void;
}

function FullDescription(props: Props) {
  const { deal, admin, onUpdate } = props;
  const [isEditing, setEditing] = useState(false);

  const classes = useStyles();

  const { error, data, refetch } = useQuery(GetDealFullDescriptionsByIdQuery, {
    variables: {
      dealId: deal.dealId,
    },
  });

  const dealWithAllTranslations = { ...deal };

  if (data?.deal_by_pk?.deal_translations) {
    dealWithAllTranslations.deal_translations =
      data.deal_by_pk.deal_translations;
  }

  const isEdit = () =>
    deal.status === 'DRAFT' ||
    deal.status === 'PREVIEW' ||
    deal.status === 'OPEN';

  const handleEdit = () => {
    setEditing(false);
    onUpdate();
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  const options = {
    blockStyleFn: block => {
      if (block.getType() === 'atomic') {
        return {
          style: {
            textAlign: 'center',
          },
        };
      }
      return undefined;
    },
    entityStyleFn: entity => {
      const entityType = entity.get('type').toLowerCase();
      const data = entity.getData();
      if (entityType === 'link') {
        return {
          element: 'a',
          attributes: {
            target: '_blank',
            href: data.url,
          },
        };
      } else if (entityType === 'draft-js-video-plugin-video') {
        const data = entity.getData();
        return {
          element: 'iframe',
          attributes: {
            src: convertVideoSrcToEmbed(data.src),
            frameborder: '0',
            allowfullscreen: true,
            width: 560,
            height: 315,
            style: 'max-width: 100%',
          },
        };
      }
      return undefined;
    },
  };

  const parsedHtml =
    deal.deal_translations[0].description &&
    JSON.parse(deal.deal_translations[0].description)
      ? stateToHTML(
          convertFromRaw(JSON.parse(deal.deal_translations[0].description)),
          options,
        )
      : '';

  return (
    <div id="description">
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? error.message : ''}
      />
      <Grid container style={{ marginBottom: 15 }}>
        <Grid xs item>
          <Header>{deal.deal_translations[0].summaryTitle || ''}</Header>
        </Grid>
        {admin && isEdit() && (
          <Grid
            item
            xs={"auto"}
            style={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <EditButton
              onClick={() => setEditing(true)}
              style={{ marginLeft: '15px' }}
            >
              <FormattedMessage id="edit" />
            </EditButton>
          </Grid>
        )}
      </Grid>
      <div className={classes.descriptionContent}>
        <div
          dangerouslySetInnerHTML={{
            __html: parsedHtml,
          }}
        />
      </div>
      {data && (
        <FullDescriptionEditor
          open={isEditing}
          deal={dealWithAllTranslations}
          onCancel={() => setEditing(false)}
          onUpdate={handleEdit}
          status={deal.status}
        />
      )}
    </div>
  );
}

export default memo(FullDescription);
