import React, { memo, useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import {
  Button,
  Typography,
  makeStyles,
  Grid,
  Card,
  Container,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { showToast } from 'containers/App/actions';
import { useIntl } from 'react-intl';
import { requestSigning } from '../../../services/api/sign-document';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client/core';

interface Props {
  dealId: string;
  esignTemplateId: string;
  userId: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    margin: '30px 7%',
    border: '1px solid #EDEDED',
    boxShadow: '1px 2px 4px rgba(57, 60, 73, 0.1)',
    padding: '20px 10px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function SignNDA(props: Props) {
  const intl = useIntl();
  const { dealId, esignTemplateId, userId } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [signState, setSignState] = useState('NOT_REQUESTED');

  const { loading, error, data, refetch } = useQuery(
    gql`
      query eSignQuery($dealId: uuid!, $userId: uuid!) {
        e_sign_document(
          where: {
            dealId: { _eq: $dealId }
            vehicle: { userId: { _eq: $userId } }
          }
        ) {
          id
          dealId
          vehicleId
          status
        }
        vehicle(where: { userId: { _eq: $userId } }) {
          id
          vehicleType
          status
        }
      }
    `,
    {
      variables: { dealId: dealId, userId: userId },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setSignState(data.e_sign_document[0]?.status ?? 'NOT_REQUESTED');
      },
    },
  );

  useEffect(() => {
    if (data) {
      setSignState(data.e_sign_document[0]?.status ?? 'NOT_REQUESTED');
    }
  }, [refresh]);

  const getVehicleId = () => {
    if (data.vehicle.length == 0) {
      return;
    }
    const filterVehicle = data.vehicle.filter(
      vehicle =>
        vehicle.vehicleType === 'INDIVIDUAL' && vehicle.status === 'ACTIVE',
    );
    if (filterVehicle.length) {
      return filterVehicle[0].id;
    }
    return get('[0].id')(
      data.vehicle.filter(vehicle => vehicle.status === 'ACTIVE'),
    );
  };

  const signNDA = async () => {
    const vehicleId = getVehicleId();
    if (!vehicleId) {
      dispatch(showToast({ message: 'dontHaveAnyVehicle', type: 'error' }));
      return;
    }
    try {
      setBtnDisabled(true);
      const response = await requestSigning({
        dealId: dealId,
        eSignTemplateId: esignTemplateId,
        vehicleIds: [vehicleId],
      });
      setSignState(response.status);
    } catch (err) {
      let errorMessage;
      if (err.response.errors && err.response.errors.length) {
        errorMessage = err.response.errors[0].message || 'unexpectedError';
      } else {
        errorMessage = err.response.data.messages[0] || 'unexpectedError';
      }
      dispatch(
        showToast({
          message: errorMessage,
          type: 'error',
        }),
      );
    } finally {
      setBtnDisabled(false);
    }
  };

  const refreshSign = async () => {
    await refetch();
    setRefresh(!refresh);
  };

  const onClick = () => {
    if (signState === 'NOT_REQUESTED') {
      signNDA();
    } else {
      refreshSign();
    }
  };

  return (
    <Container className="main">
      <Card>
        {!loading && (
          <Grid container>
            <Grid item xs={true} className={classes.center}>
              <Typography>
                {signState === 'NOT_REQUESTED'
                  ? `${intl.messages['toAccessFullDescription']}`
                  : `${intl.messages['checkYourEmailToSIgnDocument']}`}
              </Typography>
            </Grid>
            <Grid item xs={'auto'} className={classes.flexEnd}>
              <Button
                color="primary"
                variant="contained"
                style={{ textTransform: 'unset' }}
                onClick={onClick}
                disabled={btnDisabled}
              >
                {signState === 'NOT_REQUESTED'
                  ? `${intl.messages['signTheNDA']}`
                  : `${intl.messages['refresh']}`}
              </Button>
            </Grid>
          </Grid>
        )}
      </Card>
    </Container>
  );
}

export default memo(SignNDA);
