/**
 * Shows an error toast message
 */

import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface Props {
  isOpen: boolean;
  message: string;
  handleClose?: () => void;
}
function ErrorToast(props: Props) {
  const intl = useIntl();
  const { message, isOpen } = props;
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    setShowing(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setShowing(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <Snackbar
      open={showing}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      <Alert severity="error" onClose={handleClose}>
        {message ? intl.messages[`error.${message}`] : ''}
      </Alert>
    </Snackbar>
  );
}

export default ErrorToast;
