import React, { memo, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  margin: 1px;
  background-color: var(--color-dark-purple);
  border-radius: 2px;
  box-sizing: content-box;
  padding-left: 10px;
  padding-right: 8px;
  outline: 0;
  overflow: hidden;
  font-size: 12px;
  font-weight: 600;
  color: white;
  min-height: 30px;
  &:focus {
    background-color: var(--color-purple);
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 6px;
  }

  & svg {
    font-size: 16px;
    cursor: pointer;
    padding: 0;
  }
`;

const BootstrapInput = withStyles(() => ({
  root: {
    marginTop: 0,
    minHeight: 30,
    height: 'auto',
  },
}))(TextField);

const CustomAutocomplete = withStyles(() => ({
  root: {
    height: 'auto',
    width: '100%',
  },
  inputRoot: {
    height: 'auto',
    padding: '0px !important',
  },
  option: {
    padding: '0 0 0 10px',
    '&:hover': {
      backgroundColor: '#B5CEFA',
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#588FDB !important',
      color: 'white',
    },
  },
  groupLabel: {
    fontWeight: 'unset',
    lineHeight: 'unset',
  },
  input: {},
  endAdornment: {
    position: 'relative',
    marginLeft: '0.5rem',
  },
}))(Autocomplete);

interface Props {
  options: { title: string; value: string | number; group?: string }[];
  defaultValue?: any[];
  clear?: boolean;
  onClear?: () => void;
  [propsName: string]: any;
  onSelect: (value: any) => void;
}

function MultiSelect(props: Props) {
  const {
    options,
    defaultValue,
    onSelect,
    value,
    clear,
    onClear,
    // tslint:disable-next-line: trailing-comma
    ...rest
  } = props;

  const [selected, setSelected] = React.useState<any>();

  const selectValues =
    selected ||
    (defaultValue ? options.filter(o => defaultValue.includes(o.value)) : []);

  useEffect(() => {
    if (clear) {
      setSelected([]);
      if (onClear) {
        onClear();
      }
      onSelect([]);
    }
  }, [clear]);

  return (
    <CustomAutocomplete
      {...rest}
      multiple
      value={value || selectValues}
      onChange={(event, newValue) => {
        setSelected(newValue);
        onSelect(newValue);
      }}
      options={options}
      getOptionLabel={(option: any) => option.title}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option: any, index) => (
          <Tag label={option.title} {...getTagProps({ index })} key={index} />
        ))
      }
      renderInput={params => <BootstrapInput {...params} variant="outlined" />}
    />
  );
}

export default memo(MultiSelect);
