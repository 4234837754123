import React, { memo } from 'react';

import { FormControl, FormLabel, Typography } from '@material-ui/core';
import CustomSwitch from 'components/CustomSwitch';
import StatusLabel from 'components/StatusLabel';

interface Props {
  label: string;
  statusVisible?: boolean;
  status?: boolean;
  description: string;
  onSwitch: (e: any) => void;
  checked?: boolean;
  disabled?: boolean;
}

function ToogleForm(props: Props) {
  const {
    label,
    status,
    description,
    onSwitch,
    statusVisible,
    checked,
    disabled,
  } = props;

  return (
    <FormControl>
      <FormLabel style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
        {label}
      </FormLabel>
      <div style={{ display: 'flex' }}>
        <CustomSwitch
          onSwitchChange={onSwitch}
          checked={checked}
          disabled={disabled}
        />
        {statusVisible && (
          <StatusLabel
            status={status ? 'on' : 'off'}
            text={status ? 'on' : 'off'}
          />
        )}
        <Typography style={{ margin: '4px 4px' }}>{description}</Typography>
      </div>
    </FormControl>
  );
}

export default memo(ToogleForm);
