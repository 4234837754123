/*
 *
 * ProfileAbout
 *
 */

import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMutation } from '@apollo/react-hooks';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectProfileAbout from './selectors';
import reducer from './reducer';
import saga from './saga';
import Detail from './components/details';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import UserData from 'models/user';
import { fetchDocumentAction, hideLoading } from './actions';
import ProfileEditDialog from 'containers/ProfileEditDialog';
import { fetchUserAction } from 'containers/InvestorDetail/actions';
import { fetchUser } from 'containers/Home/actions';

import LanguageChangeMutation from 'gql/languageChange/LanguageChange.gql';

const stateSelector = createStructuredSelector({
  profileAbout: makeSelectProfileAbout(),
  currentUser: makeSelectCurrentUser(),
});

interface Props {
  investor: UserData;
}

function ProfileAbout(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'profileAbout', reducer: reducer });
  useInjectSaga({ key: 'profileAbout', saga: saga });
  const { profileAbout, currentUser } = useSelector(stateSelector);
  const [open, setOpen] = React.useState<boolean>(false);
  const { investor } = props;
  const dispatch = useDispatch();
  const { needRefresh } = profileAbout;
  const scope = 'ProfileAbout';

  useEffect(() => {
    if (needRefresh) {
      dispatch(fetchUserAction(investor.userId));
      dispatch(hideLoading());
      setOpen(false);
    }
  }, [needRefresh]);
  useEffect(() => {
    if (investor) {
      dispatch(fetchDocumentAction(investor.userId));
    }
  }, []);

  const handleEdit = () => {
    setOpen(true);
  };

  const [languageChange] = useMutation(LanguageChangeMutation);

  const updateLocale = (event: React.ChangeEvent<HTMLInputElement>) => {
    languageChange({
      variables: {
        userId: investor.userId,
        languageCode: (event.target as HTMLInputElement).value,
      },
    }).then(() => {
      dispatch(fetchUser());
    });
  };
  let isAdmin = false;
  if (currentUser && currentUser.userType === 'ADMIN') {
    isAdmin = true;
  }

  return (
    <div>
      {investor && (
        <Detail
          investor={investor}
          onEdit={handleEdit}
          updateLocale={updateLocale}
          scope={scope}
          showLangSelect={!isAdmin}
        />
      )}

      {investor && (
        <ProfileEditDialog
          investor={investor}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default memo(ProfileAbout);
