/*
 *
 * ProfileVehicleCreateDialog actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const clearInfoAction = () => action(ActionTypes.CLEAR_INFO_ACTION);
export const initVehicle = payload =>
  action(ActionTypes.INIT_VEHICLE_ACTION, payload);
export const updateAction = payload =>
  action(ActionTypes.UPDATE_ACTION, payload);
export const createAction = payload =>
  action(ActionTypes.CREATE_ACTION, payload);
export const createSuccessAction = payload =>
  action(ActionTypes.CREATE_FAILED_ACTION, payload);
export const submitUpdateAction = payload =>
  action(ActionTypes.SUBMIT_UPDATE_ACTION, payload);
export const submitUpdateSuccessAction = payload =>
  action(ActionTypes.SUBMIT_UPDATE_FAILED_ACTION, payload);
export const updateDocumentAction = payload =>
  action(ActionTypes.UPDATE_DOCUMENT_ACTION, payload);
