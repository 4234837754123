// import { take, call, put, select } from 'redux-saga/effects';

// Individual exports for testing
import { call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import * as actions from '../RegisterInvestments/actions';
import ProfileResponse from '../../models/response/profile-response';
import { get, set } from '../../services/local-storage-service';
import {
  me,
  updateProfile,
  updaStepProfile,
} from '../../services/api/profile-service';
import ProfileRequest from '../../models/request/profile-request';
import {
  getPreferenceGroup,
  updatePreferences,
  getUserPreference,
} from '../../services/api/preference-service';
import { showToast } from '../App/actions';
import PreferenceResponse from '../../models/response/preference-response';
import { initialChipsState } from './components/chips';
import makeSelectRegister from 'containers/Register/selectors';

function* fetchInvestments(action) {
  try {
    const group: any = yield call(getPreferenceGroup);
    const locationGroup: PreferenceResponse[] = group['LOCATION'];
    const sectorGroup: PreferenceResponse[] = group['SECTOR'];
    const stageGroup: PreferenceResponse[] = group['STAGE'];

    const locationIds = locationGroup
      ? locationGroup.map(location => {
          return { id: location.preferenceTagId, label: location.name };
        })
      : [];
    const sectorIds = sectorGroup
      ? sectorGroup.map(location => {
          return { id: location.preferenceTagId, label: location.name };
        })
      : [];
    const stageIds = stageGroup
      ? stageGroup.map(location => {
          return { id: location.preferenceTagId, label: location.name };
        })
      : [];

    yield put(
      actions.updateStateInvestmentAction([
        { field: 'locationChips', state: locationIds },
        { field: 'sectorChips', state: sectorIds },
        { field: 'stageChips', state: stageIds },
      ]),
    );

    yield put(
      actions.updateFormStateInvestmentAction([
        { field: 'userId', state: action.payload },
        {
          field: 'locationPreferences',
          state: { ...initialChipsState, ids: [] },
        },
        {
          field: 'sectorPreferences',
          state: { ...initialChipsState, ids: [] },
        },
        { field: 'stagePreferences', state: { ...initialChipsState, ids: [] } },
      ]),
    );

    // yield put(actions.fetchMeSuccessAction(profile));
  } catch (err) {
    // yield put(actions.fetchMeErrorAction());
    console.error(err);
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* updateStepAction(action) {
  const { profile } = yield select(makeSelectRegister());
  if (!profile) {
    return;
  }

  if (profile.registerStep === 'INVESTMENT') {
    const request = {
      email: profile.email,
      userStep: 'COMPLETED',
    };
    const updateProfileResponse: ProfileResponse = yield call(
      updaStepProfile,
      request,
    );
  }
}

function* updateInvestments(action) {
  const data = action.payload;
  const { profile } = yield select(makeSelectRegister());
  if (!profile) {
    return;
  }
  try {
    const result: any = yield call(updatePreferences, data);
    const request = {
      email: profile.email,
      userStep: 'COMPLETED',
    };
    // const updateProfileResponse: ProfileResponse = yield call(
    //   updaStepProfile,
    //   request,
    // );
    yield put(actions.updateInvestmentSuccessAction());
  } catch (err) {}
}

function* getUserPreferenceRequest() {
  try {
    const result: any = yield call(getUserPreference);
    yield put(actions.getUserPreferencesSuccessAction(result));
  } catch (err) {
    console.log(err);
  }
}

export default function* registerInvestmentsSaga() {
  yield takeLatest(ActionTypes.FETCH_INVESTMENTS_ACTION, fetchInvestments);
  yield takeLatest(ActionTypes.UPDATE_STEP_ACTION, updateStepAction);
  yield takeLatest(ActionTypes.UPDATE_INVESTMENT_ACTION, updateInvestments);
  yield takeLatest(ActionTypes.GET_USER_PREFERENCES, getUserPreferenceRequest);
}
