// import { take, call, put, select } from 'redux-saga/effects';

import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { RestResult } from 'models/response/rest-result';
import Deal from 'models/deal';
import { fetchDealSuccessAction } from './actions';
import { showToast } from 'containers/App/actions';
import { fetchInvestorDeal } from 'services/api/profile-service';
import ActionTypes from './constants';

export function* fetchDealRequest(action) {
  try {
    const result: RestResult<Deal> = yield call(
      fetchInvestorDeal,
      action.payload,
    );
    yield put(fetchDealSuccessAction(result));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* profileDealSaga() {
  yield takeLatest(ActionTypes.FETCH_DEAL_ACTION, fetchDealRequest);
}
