import React, { memo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import { Typography } from '@material-ui/core';
import { resetState } from '../actions';
import ArrowButton from 'components/ArrowButton';

function Succeed() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const backToSignIn = () => {
    dispatch(resetState());
    dispatch(replace('/login'));
  };
  const localScope = 'ResetPassword.Succeed';
  return (
    <>
      <Typography
        variant="h3"
        style={{
          marginBottom: 0,
          color: 'white',
          fontWeight: 400,
          textAlign: 'center',
        }}
      >
        <FormattedMessage id="passwordChanged" />
      </Typography>
      <Typography
        style={{
          margin: '20px 0',
          textAlign: 'center',
        }}
      >
        <FormattedMessage id={`${localScope}.succeed`} />
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <ArrowButton
          text={`${intl.messages[`backToSignIn`]}`}
          type={'button'}
          onClick={backToSignIn}
        ></ArrowButton>
      </div>
    </>
  );
}

export default memo(Succeed);
