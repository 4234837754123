import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import whiteIcon from '../../../images/logos/Jadesquare-logo-white.svg';
import Img from 'components/Img';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from '@material-ui/core';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  listText: {
    color: '#FFF',
  },
  selectedCell: {
    backgroundColor: '#666666',
    color: '#FFF',
  },
});

interface ILink {
  visibleText: string;
  url: string;
  hideAdmin: boolean;
  hideUser: boolean;
}
interface ExtendedLink {
  visibleText: string;
  url: string;
  hideAdmin: boolean;
  hideUser: boolean;
  children?: ILink[];
}
interface Props {
  open: boolean;
  onClose: () => void;
  onSelected: (url) => void;
  selected?: number;
  items: ExtendedLink[];
  isAdmin: boolean;
}

export default function MuiDrawer(props: Props) {
  const classes = useStyles();
  const { open, onClose, onSelected, items } = props;
  const dispatch = useDispatch();
  const list = () => (
    <div role="presentation" onClick={onClose}>
      <List>
        {items.map((item, index) => {
          const { visibleText, url, hideAdmin, hideUser } = item;
          const isActive = location.pathname.startsWith(url);
          const shouldBeVisible =
            (!hideAdmin && props.isAdmin) ||
            (!hideUser && !props.isAdmin) ||
            (!hideAdmin && !hideUser);

          return shouldBeVisible ? (
            url !== 'none' ? (
              <ListItem
                button
                key={visibleText}
                onClick={() => {
                  onSelected(url);
                }}
                className={`${isActive ? classes.selectedCell : ''}`}
              >
                <ListItemText
                  primary={visibleText}
                  className={classes.listText}
                />
              </ListItem>
            ) : (
              <div key={index}>
                <List key={index}>
                  {item.children &&
                    item.children.map((link, linkindex) => {
                      const isActiveLi = location.pathname.startsWith(link.url);
                      return (
                        <ListItem key={linkindex}
                          className={`${
                            isActiveLi ? classes.selectedCell : ''
                          }`}
                          onClick={() => {
                            onSelected(link.url);
                          }}
                        >
                          <ListItemText
                            primary={link.visibleText}
                            className={classes.listText}
                          />
                        </ListItem>
                      );
                    })}
                </List>
              </div>
            )
          ) : null;
        })}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer anchor={'left'} variant="temporary" open={open} onClose={onClose}>
        <div style={{ backgroundColor: '#000', height: '100%' }}>
          <Img
            src={whiteIcon}
            style={{ height: 25, margin: 18 }}
            onClick={() => {
              dispatch(push('/'));
            }}
          />
          {list()}
        </div>
      </Drawer>
    </div>
  );
}
