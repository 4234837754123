/*
 *
 * Portfolio
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Card, Link, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import Table, { HeaderProps } from 'components/Table';
import NumberFormat from 'react-number-format';
import Deal from 'models/deal';
import moment from 'moment';
import DealHeader from './header';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: 'transparent',
    border: 'none',
  },
  centerText: {
    textAlign: 'center',
  },
  descriptionText: {
    textAlign: 'left',
  },
  title: {
    fontWeight: 700,
    color: 'var(--color-light-grey)',
    '&:hover': {
      color: 'var(--color-purple)',
      textDecoration: 'none',
    },
  },
  tableCell20: {
    width: '20%',
  },
  purpleText: {
    color: 'var(--color-purple)',
    fontWeight: 700,
  },
}));

interface Props {
  items?: Deal[];
  total: number;
  fetchPage?: (page: number) => void;
  page: number;
}

function DealTable(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items, total, fetchPage, page } = props;

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(page);
    }
  };
  const headers: HeaderProps[] = [
    { name: `${intl.messages['dealName']}`, headerClass: classes.tableCell20 },
    {
      name: `${intl.messages['companyName']}`,
      headerClass: classes.tableCell20,
    },
    {
      name: `${intl.messages['ticketValue']}`,
      headerClass: classes.tableCell20,
    },
    { name: `${intl.messages['vehicle']}`, headerClass: classes.tableCell20 },
    { name: `${intl.messages['closeDate']}`, headerClass: classes.tableCell20 },
  ];

  const viewDetail = id => {
    dispatch(push(`deals/${id}`));
  };

  const renderItem = (item, index: number) => {
    const acceptedTicketsAmount = item.acceptedTickets.reduce(
      (acc, curr) => acc + curr.amount,
      0,
    );
    const vehicleTypes: [
      'INDIVIDUAL' | 'CORPORATE',
    ] = item.userInvestedVehicle.map(VEHICLE => {
      return VEHICLE.vehicle ? VEHICLE.vehicle.vehicleType : 'INDIVIDUAL';
    });

    const isVariableVehicle = (vehicleList: ['INDIVIDUAL' | 'CORPORATE']) => {
      return vehicleList.indexOf('CORPORATE') !== -1;
    };

    return [
      <div key={index}>
        <Link
          href={`/deals/${item.dealId}`}
          className={classes.title}
          onClick={e => {
            e.preventDefault();
            viewDetail(item.dealId);
          }}
        >
          {item.deal_translations[0].name}
        </Link>
      </div>,
      <div key={index}>{item.company.company_translations[0]?.name}</div>,
      <div>
        <NumberFormat
          className={classes.purpleText}
          key={index}
          value={acceptedTicketsAmount || 0}
          thousandSeparator={true}
          prefix={'$'}
          displayType={'text'}
        />
        <br></br>
        <Typography>
          <NumberFormat
            key={index}
            value={item.acceptedTickets.length || 0}
            displayType={'text'}
          />
          {` ${(intl.messages[
            'tickets'
          ] as string).toLowerCase()} (${Math.floor(
            (acceptedTicketsAmount / item.totalSizeRound) * 100,
          )}%)`}
        </Typography>
      </div>,
      <p style={{ fontSize: '14px' }} key={index}>
        {isVariableVehicle(vehicleTypes)
          ? `${intl.messages['multipleVehicle']}`
          : `${intl.messages['individualVehicle']}`}
      </p>,
      moment(item.refClosingDate).format('DD-MM-YYYY'),
    ];
  };
  return (
    <>
      <DealHeader noOfDeal={total} />
      <Table
        headers={headers}
        items={items}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
      />
    </>
  );
}

export default memo(DealTable);
