/*
 *
 * SignRequest actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const setOpen = (open: boolean) => action(ActionTypes.SET_OPEN, open);

export const searchVehicles = (request: {
  dealId: string;
  request: {
    page?: number;
    pageSize?: number;
    searchKey?: string;
    eSignDocumentStatuses?: string[];
    eSignTemplateId: string;
    vehicleType?: string;
  };
}) => action(ActionTypes.SEARCH_VEHICLES, request);

export const searchVehiclesSucceed = data =>
  action(ActionTypes.SEARCH_VEHICLES_SUCCEED, data);

export const searchTemplates = () => action(ActionTypes.SEARCH_TEMPLATES);

export const searchTemplatesSucceed = data =>
  action(ActionTypes.SEARCH_TEMPLATES_SUCCEED, data);

export const requestSign = (request: {
  eSignTemplateId: string;
  dealId: string;
  vehicleIds: string[];
  esignDocumentId?: string | undefined;
}) => action(ActionTypes.REQUEST_SIGN, request);

export const requestSignSucceed = () =>
  action(ActionTypes.REQUEST_SIGN_SUCCEED);

export const getPreview = (request: {
  esignTemplateId: string;
  dealId: string;
  vehicleId: string;
}) => action(ActionTypes.GET_PREVIEW, request);

export const getPreviewSucceed = () => action(ActionTypes.GET_PREVIEW_SUCCEED);
