/*
 *
 * Dashboard
 *
 */

import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Divider,
  Card,
} from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';

import Table, { HeaderProps } from 'components/Table';
import ErrorToast from 'components/ErrorToast';
import styled from 'styles/styled-components';
import AdminDashboardQuery from 'gql/dashboard/AdminDashboard.gql';
import GetDealInvestorByFileAccessStatus from 'gql/dashboard/GetDealInvestorByFileAccessStatus.gql';
import ChangeFilePermissionByAdmin from 'gql/dashboard/ChangeFilePermissionByAdmin.gql';
import { useIntl, FormattedMessage } from 'react-intl';
import { ActivateColorButton } from 'components/ActiveButton/ActivateColorButton';
import { DeactivateColorButton } from 'components/DeactiveButton/DeactivateColorButton';
import { setIsLoading } from 'containers/Home/actions';
import { useDispatch } from 'react-redux';

const useStyle = makeStyles({
  border: {},
  header: {
    marginBottom: 10,
    fontSize: '0.875rem',
  },
  bold: {
    fontWeight: 600,
  },
  divider: {
    marginBottom: 20,
    backgroundColor: '#888888',
  },
  value: {
    fontWeight: 600,
    fontSize: '1.5rem',
    margin: 0,
  },
  tableCell30: {
    width: '30%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell20: {
    width: '20%',
  },
});

function Dashboard() {
  const intl = useIntl();
  const classes = useStyle();

  const { loading, error, data, refetch } = useQuery(AdminDashboardQuery);

  const {
    loading: accessRequestsLoading,
    error: accessRequestsError,
    data: accessRequestsData,
    refetch: accessRequestsRefetch,
  } = useQuery(GetDealInvestorByFileAccessStatus,{
    notifyOnNetworkStatusChange: true,
  });

  const [changePermission] = useMutation(ChangeFilePermissionByAdmin);

  let formattedData: any = {};

  if (data) {
    formattedData = {
      activeInvestors: data.activeUser.aggregate.count,
      averageTicket: data.ticketAggregate.aggregate.avg.amount,
      companies: data.totalCompany.aggregate.count,
      deals: data.totalDeal.aggregate.count,
      inActiveInvestors: data.inactiveUser.aggregate.count,
      investors: data.totalUser.aggregate.count,
      registeredInvestors: data.registeredUser.aggregate.count,
      ticketAmounts: data.ticketAggregate.aggregate.sum.amount,
      tickets: data.totalTickets.aggregate.count,
    };
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsLoading(loading || accessRequestsLoading));
  }, [loading, accessRequestsLoading]);
  useEffect(() => {
    refetch();
    accessRequestsRefetch();
  }, []);

  const headers: HeaderProps[] = [
    { name: `${intl.messages['dealName']}`, headerClass: classes.tableCell20 },
    {
      name: `${intl.messages['investorName']}`,
      headerClass: classes.tableCell20,
    },
    {
      name: `${intl.messages['model.email']}`,
    },
    { name: '' },
  ];

  const renderItem = (item, index: number) => {
    return [
      <div key={index}>{item.deal?.deal_translations[0].name}</div>,
      <div key={index}>{item.user?.fullName}</div>,
      <div key={index}>{item.user?.email}</div>,
      <div key={index}>
        <ActivateColorButton
          style={{ margin: 8 }}
          variant="outlined"
          color="primary"
          onClick={() => answerRequest(item.id, 'GRANTED')}
        >
          <FormattedMessage id="approve" />
        </ActivateColorButton>
        <DeactivateColorButton
          color="default"
          variant="outlined"
          onClick={() => answerRequest(item.id, 'REJECTED')}
        >
          <FormattedMessage id="reject" />
        </DeactivateColorButton>
      </div>,
    ];
  };

  const answerRequest = (id: number, status: string) => {
    dispatch(setIsLoading(true));
    changePermission({
      variables: {
        dealInvestorId: id,
        newStatus: status,
      },
    })
      .then(() => {
        dispatch(setIsLoading(false));
        accessRequestsRefetch();
      })
      .catch(e => {
        console.warn(e);
        dispatch(setIsLoading(false));
      });
  };

  return (
    <Container className="main">
      <Grid container spacing={3}>
        <ErrorToast
          isOpen={Boolean(error)}
          message={error ? error.message : ''}
        />
        {!error && !loading && (
          <Grid item xs={12} sm={6}>
            <Card>
              <div>
                <Typography className={`${classes.header} ${classes.bold}`}>
                  {formattedData.investors || 0}{' '}
                  {formattedData.investors === 1
                    ? `${intl.messages['investor']}`
                    : `${intl.messages['investors']}`}
                </Typography>
              </div>
              <div>
                <Divider className={classes.divider} />
              </div>
              <div>
                <Doughnut
                  data={{
                    labels: [
                      `${intl.messages['active']}`,
                      `${intl.messages['inactive']}`,
                      `${intl.messages['registered']}`,
                    ],
                    datasets: [
                      {
                        backgroundColor: ['#0e6dea', '#666666', '#7F2DCB'],
                        data: [
                          formattedData.activeInvestors || 0,
                          formattedData.inActiveInvestors || 0,
                          formattedData.registeredInvestors || 0,
                        ],
                        borderWidth: 0.2,
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: true,
                      position: 'right',
                    },
                  }}
                />
              </div>
            </Card>
          </Grid>
        )}
        {!error && !loading && (
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <Typography className={classes.header}>
                    <FormattedMessage id="totalTickets" />
                  </Typography>
                  <Typography className={classes.value}>
                    {formattedData.tickets || 0}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <Typography className={classes.header}>
                    <FormattedMessage id="activeInvestors" />
                  </Typography>
                  <Typography className={classes.value}>
                    {formattedData.activeInvestors || 0}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <Typography className={classes.header}>
                    <FormattedMessage id="deals" />
                  </Typography>
                  <Typography className={classes.value}>
                    {formattedData.deals || 0}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <Typography className={classes.header}>
                    <FormattedMessage id="companies" />
                  </Typography>
                  <Typography className={classes.value}>
                    {formattedData.companies || 0}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <Typography className={classes.header}>
                    <FormattedMessage id="averageTicket" />
                  </Typography>
                  <Typography className={classes.value}>
                    <NumberFormat
                      value={Math.round(formattedData.averageTicket || 0)}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                      displayType={'text'}
                    />
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <Typography className={classes.header}>
                    <FormattedMessage id="totalAmountRaised" />
                  </Typography>
                  <Typography className={classes.value}>
                    <NumberFormat
                      value={Math.round(formattedData.ticketAmounts || 0)}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                      displayType={'text'}
                    />
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <div style={{ paddingTop: 15 }}>
        <h4>
          <FormattedMessage id="Dashboard.dealAccessRequests" />
        </h4>
        {!accessRequestsError && !accessRequestsLoading && (
          <Table
            headers={headers}
            items={accessRequestsData.deal_investor}
            renderItem={renderItem}
          />
        )}
      </div>
    </Container>
  );
}

export default Dashboard;
