// tslint:disable: jsx-wrap-multiline
import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { Grid, Card, Typography, Theme, Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import SearchInput from 'components/SearchInput';
import Table, { HeaderProps } from 'components/Table';
import { useIntl, FormattedMessage } from 'react-intl';
import Img from 'components/Img';
import NumberFormat from 'react-number-format';
import NoAvatar from 'images/not-found.png';
import A from 'components/A';
import Status from 'components/Status';
import UserData from 'models/user';
import VehicleHeader from './header';
import Vehicle from 'models/vehicle';
import { allCountries } from 'utils/constants';
const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    // marginBottom: 8,
    // border: '1px solid #E5E5E5',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 0,
    border: '1px solid var(--color-dark-grey)',
    backgroundColor: 'var(--color-super-dark-grey)',
    color: 'var(--color-purple)',
    padding: '20px',
    width: '100%',
  },
  card: {
    border: '1px solid #E5E5E5',
    borderRadius: 0,
  },
  container: {
    padding: theme.spacing(6, 3),
  },
  image: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    marginRight: '10px',
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: '#CFE1FF',
    color: '#588FDB',
    fontWeight: 'bold',
    borderRadius: 2,
    display: 'inline-block',
  },
  title: {
    fontWeight: 'bold',
    color: '#588FDB',
    minWidth: 75,
  },
  tableCell30: {
    width: '30%',
  },
  tableCell10: {
    width: '10%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell25: {
    width: '25%',
  },
}));

interface Props {
  items: Vehicle[];
  total: number;
  fetchPage?: (searchKey: string, page: number) => void;
  onFilterChanged?: (value, type) => void;
  onEdit: (item: Vehicle | undefined) => void;
  searchValue: string;
}

function VehicleList(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const {
    items,
    total,
    fetchPage,
    onFilterChanged,
    onEdit,
    searchValue,
  } = props;
  const [searchKey, setSearchKey] = React.useState('');
  const [filteredItems, setFilteredItem] = React.useState<Vehicle[]>(items);

  useEffect(() => {
    setFilteredItem(items);
  }, [items]);

  useEffect(() => {
    setSearchKey(searchValue);
    if (fetchPage) {
      fetchPage(searchValue, 0);
    }
    setFilteredItem(pre => {
      if (items) {
        if (!searchValue) {
          return items;
        }
        return items.filter(item => {
          return (
            (item.corporateName &&
              item.corporateName
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (item.corporateType &&
              item.corporateType
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (item.corporateRegistrationNumber &&
              item.corporateRegistrationNumber
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      } else {
        return pre;
      }
    });
  }, [searchValue]);

  const onFilterChange = (value, type) => {
    if (onFilterChanged) {
      onFilterChanged(value, type);
    }
  };

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(searchKey, page);
    }
  };
  const headers: HeaderProps[] = [
    {
      name: `${intl.messages['companyName']}`,
      headerClass: classes.tableCell25,
    },
    {
      name: `${intl.messages['companyType']}`,
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['regNumber']}`,
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['model.address']}`,
      headerClass: classes.tableCell30,
    },
    { name: `${intl.messages['actions']}` },
  ];

  const getCountry = code => {
    return (
      allCountries.find(item => item.value === code) || { value: '', label: '' }
    );
  };
  const renderItem = (item: Vehicle, index: number) => {
    return [
      <Typography key={index}>{item.corporateName}</Typography>,
      <Typography key={index}>{item.corporateType}</Typography>,
      <Typography key={index}>{item.corporateRegistrationNumber}</Typography>,
      <Typography key={index}>{`${item.address1 || ''} ${item.address2 ||
        ''} ${item.zipCode || ''} ${item.city || ''}, ${
        getCountry(item.country).label
      }`}</Typography>,
      <Button
        key={index}
        color="primary"
        variant="contained"
        onClick={ev => onEdit(item)}
      >
        <FormattedMessage id="edit" />
      </Button>,
    ];
  };
  return (
    <div>
      <div>
        <VehicleHeader
          noMatched={total}
          handleCreate={ev => onEdit(undefined)}
        />
        <Table
          headers={headers}
          items={filteredItems}
          renderItem={renderItem}
          fetchDataForPage={handleFetchPage}
          totalElements={total}
        />
      </div>
      <div>
        <Button className={classes.button} onClick={ev => onEdit(undefined)}>
          + <FormattedMessage id="ProfileVehicleCreateDialog.createVehicle" />
        </Button>
      </div>
    </div>
  );
}

export default memo(VehicleList);
