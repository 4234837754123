import React, { memo, useState } from 'react';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Input,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextArea from 'components/TextArea';
import { FormattedMessage, useIntl } from 'react-intl';
import { LANGUAGES } from 'utils/constants';
import { initialInputState } from 'components/FormControl/input';

const useStyles = makeStyles(() => ({
  border: {
    borderBottom: '1px solid #e7e7e7',
  },
  fullWidth: {
    width: '100%',
  },
}));

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: (title: any, content: any) => void;
}

function SendCustomemail(props: Props) {
  const intl = useIntl();
  const { open, onCancel, onConfirm } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState('en');
  const [formState, setFormState] = useState({
    title: {
      en: initialInputState,
      de: initialInputState,
      ja: initialInputState,
    },
    content: {
      en: initialInputState,
      de: initialInputState,
      ja: initialInputState,
    },
  });

  const updateFormState = (field, subfield = '') => stateValue => {
    const formField = formState[field];
    let newFieldState;

    if (formField) {
      if (subfield) {
        const newSubfieldValue = { ...formField[subfield], value: stateValue };
        newFieldState = { ...formField, [subfield]: newSubfieldValue };
      } else {
        stateValue = Number(stateValue) || stateValue;
        newFieldState = { ...formField, value: stateValue };
      }

      setFormState(prevState => ({ ...prevState, [field]: newFieldState }));
    }
  };

  const handeCreate = () => {
    const isTitleEmpty =
      !formState?.title['en']?.value ||
      formState.title['en'].value.trim().length === 0;
    const isContentEmpty =
      !formState?.content['en']?.value ||
      formState.content['en'].value.trim().length === 0;

    setFormState(prevState => {
      return {
        ...prevState,
        title: {
          ...prevState.title,
          en: {
            ...prevState.title['en'],
            error: isTitleEmpty,
            helperMessage: isTitleEmpty
              ? `${intl.messages['error.titleIsRequired']}`
              : undefined,
          },
        },
        content: {
          ...prevState.content,
          en: {
            ...prevState.content['en'],
            error: isContentEmpty,
            helperMessage: isContentEmpty
              ? `${intl.messages['error.contentIsRequired']}`
              : undefined,
          },
        },
      };
    });

    if (!isTitleEmpty && !isContentEmpty) {
      const requestTitle = {};
      const requestContent = {};
      Object.keys(formState.title).forEach(key => {
        requestTitle[key] = formState.title[key].value;
      });
      Object.keys(formState.content).forEach(key => {
        requestContent[key] = formState.content[key].value;
      });

      onConfirm(requestTitle, requestContent);

      setFormState({
        title: {
          en: initialInputState,
          de: initialInputState,
          ja: initialInputState,
        },
        content: {
          en: initialInputState,
          de: initialInputState,
          ja: initialInputState,
        },
      });
    }
  };

  const handleCancel = () => {
    onCancel();
    setFormState({
      title: {
        en: initialInputState,
        de: initialInputState,
        ja: initialInputState,
      },
      content: {
        en: initialInputState,
        de: initialInputState,
        ja: initialInputState,
      },
    });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          alignItems="center"
          spacing={2}
          className={classes.border}
        >
          <Grid item xs>
            <Typography style={{ paddingTop: 4, fontSize: '1.25rem' }}>
              <FormattedMessage id="sendEmail" />
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TabContext value={value}>
          <TabList
            onChange={handleTabChange}
            aria-label={`${intl.messages['languageTabs']}`}
            variant="fullWidth"
            classes={{
              root: classes.fullWidth,
            }}
          >
            <Tab label={`${intl.messages['en']}`} value="en" />
            <Tab label={`${intl.messages['de']}`} value="de" />
            <Tab label={`${intl.messages['ja']}`} value="ja" />
          </TabList>
          {LANGUAGES.map(language => (
            <TabPanel
              value={language}
              key={language}
              classes={{
                root: classes.fullWidth,
              }}
            >
              <Typography style={{ marginBottom: 6 }}>
                <FormattedMessage id="title" />{' '}
                {language === 'en' && (
                  <span style={{ color: '#f44336' }}>*</span>
                )}
              </Typography>
              <Input
                fullWidth
                inputProps={{ maxLength: 40 }}
                style={{
                  border: formState.title[language].error
                    ? '1px solid red'
                    : '1px solid #bdbfc3',
                  padding: '6px',
                }}
                disableUnderline
                onChange={event =>
                  updateFormState('title', language)(event.target.value)
                }
                defaultValue={formState.title[language].value}
              />
              {formState.title[language].error && (
                <Typography
                  style={{ color: 'red', paddingTop: 6, fontSize: '0.8rem' }}
                >
                  {formState.title[language].helperMessage}
                </Typography>
              )}
              <Typography style={{ marginBottom: 6, marginTop: 12 }}>
                <FormattedMessage id="emailContent" />
                {language === 'en' && (
                  <span style={{ color: '#f44336' }}>*</span>
                )}
              </Typography>
              <TextArea
                rowsMin={6}
                autoComplete={'off'}
                id="description"
                style={{
                  width: '100%',
                  border: formState.content[language].error
                    ? '1px solid red'
                    : '1px solid #bdbfc3',
                  padding: 6,
                }}
                maxLength={5000}
                onChange={event =>
                  updateFormState('content', language)(event.target.value)
                }
                defaultValue={formState.content[language].value}
              />
              {formState.content[language].error && (
                <Typography
                  style={{ color: 'red', paddingTop: 6, fontSize: '0.8rem' }}
                >
                  {formState.content[language].helperMessage}
                </Typography>
              )}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
      <DialogActions style={{ marginTop: 40 }}>
        <Button color="primary" variant="outlined" onClick={handleCancel}>
          {`${intl.messages['cancel']}`.toUpperCase()}
        </Button>
        <Button color="primary" variant="contained" onClick={handeCreate}>
          {`${intl.messages['send']}`.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(SendCustomemail);
