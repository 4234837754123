/*
 *
 * InviteInvestor reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import InviteInvestor from 'models/invite-investor';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  default: null,
  investors: [],
  currentPage: 0,
  pageSize: 25,
  total: 300,
  showCreateDialog: false,
  refreshPage: undefined,
  filter: {},
  inviteSuccess: false,
};

const inviteInvestorReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_INVESTORS_SUCCESS_ACTION:
        draft.investors = action.payload.data;
        draft.total = action.payload.metaData['total'];
        break;
      case ActionTypes.FETCH_INVESTORS_FAILED_ACTION:
        draft.investors = [];
        break;
      case ActionTypes.SUBMIT_DEAL_INVESTOR_SUCCESS_ACTION:
        draft.inviteSuccess = true;
        break;
      default:
        break;
    }
  },
  initialState,
);

export default inviteInvestorReducer;
