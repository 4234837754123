/*
 *
 * SignRequest reducer
 *
 */

import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  open: false,
  vehicles: [],
  total: 0,
  templates: [],
  loading: false,
};

const signRequestReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SET_OPEN:
        draft.open = action.payload;
        break;
      case ActionTypes.SEARCH_VEHICLES_SUCCEED:
        draft.vehicles = action.payload.data;
        draft.total = action.payload.total;
        break;
      case ActionTypes.SEARCH_TEMPLATES_SUCCEED:
        draft.templates = action.payload;
        break;
      case ActionTypes.REQUEST_SIGN:
        draft.loading = true;
        break;
      case ActionTypes.REQUEST_SIGN_SUCCEED:
        draft.loading = false;
        draft.open = false;
        break;
      case ActionTypes.GET_PREVIEW:
        draft.loading = true;
        break;
      case ActionTypes.GET_PREVIEW_SUCCEED:
        draft.loading = false;
        break;
    }
  },
  initialState,
);

export default signRequestReducer;
