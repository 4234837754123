import { Dispatch } from 'redux';
import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { HASURA_API_URL } from 'env';
import { onError } from '@apollo/client/link/error';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Navigation } from '@material-ui/icons';

const httpLink = createHttpLink({
  uri: HASURA_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem('local::ih_access_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: accessToken
        ? `Bearer ${accessToken.replace(/"/g, '')}`
        : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (
        message === 'Malformed Authorization header' ||
        message === 'Could not verify JWT: JWTExpired'
      ) {
        localStorage.removeItem('local::ih_access_token');
        const lastLocation = window.location.pathname.replace('/', '');
        window.location.replace(`/login?rd=${lastLocation}`);
      }
    });
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: from([errorLink, (authLink.concat(httpLink) as unknown) as ApolloLink]),
  cache,
});

export default client;
