import React, { memo, useState, useEffect } from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Tabs,
  Tab,
  Button,
  Select,
  MenuItem,
  Container,
  Box,
  makeStyles
} from '@material-ui/core';
import StatusLabel from 'components/StatusLabel';
import NumberLabel from 'components/NumberLabel';
import styles from '../styles';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalizeFirstLetter from 'services/capitalize-first-letter';
import Deal from 'models/deal';
import { DealStatistic } from 'models/deal-statistic';

const CustomTab = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 600,
    minWidth: 100,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.5rem',
      minWidth: 'auto',
      whiteSpace: 'nowrap',
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
  wrapper: {
    width: 'auto',
    flexDirection: 'unset',
  },
}))(Tab);

const CustomTabs = withStyles((theme: Theme) => ({
  root: {
    marginRight: 12,
    [theme.breakpoints.down('xs')]: {
      marginRight: 4,
      marginBottom: 5,
    },
    '&.CustomTabs--investor': {
      '& $flexContainer': {
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'center',
        },
      },
    },
  },
  flexContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
}))(Tabs);

CustomTabs.defaultProps = {
  indicatorColor: 'primary',
  textColor: 'primary',
};

const CustomButton = withStyles(() => ({
  root: {
    height: 30,
  },
  label: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))(Button);

const getDealStatus = status => {
  switch (status) {
    case 'draft':
    case 'archived':
      return 'off';
    case 'closed':
    case 'funded':
      return 'closed';
    case 'removed':
      return 'removed';
    default:
      return 'on';
  }
};

const getStatusList = (status, messages) => {
  switch (status) {
    case 'DRAFT':
      return [
        { name: `${messages['preview']}`, value: 'PREVIEW' },
        { name: `${messages['open']}`, value: 'OPEN' },
        { name: `${messages['archived']}`, value: 'ARCHIVED' },
        { name: `${messages['removed']}`, value: 'REMOVED' },
      ];
    case 'PREVIEW':
      return [
        { name: `${messages['draft']}`, value: 'DRAFT' },
        { name: `${messages['open']}`, value: 'OPEN' },
        { name: `${messages['archived']}`, value: 'ARCHIVED' },
        { name: `${messages['removed']}`, value: 'REMOVED' },
      ];
    case 'OPEN':
      return [
        { name: `${messages['closed']}`, value: 'CLOSED' },
        {
          name: capitalizeFirstLetter(`${messages['funded']}`),
          value: 'FUNDED',
        },
        { name: `${messages['archived']}`, value: 'ARCHIVED' },
      ];
    case 'FUNDED':
      return [{ name: `${messages['closed']}`, value: 'CLOSED' }];
    case 'ARCHIVED':
      return [{ name: `${messages['removed']}`, value: 'REMOVED' }];
    case 'CLOSED':
      return [{ name: `${messages['open']}`, value: 'OPEN' }];
    default:
      return [];
  }
};

interface Props {
  admin?: boolean;
  deal: Deal;
  onChangeTab: (value: any) => void;
  investDeal: () => void;
  addInvestors: () => void;
  onChangeStatus: (value: any) => void;
  reset?: boolean;
  noOfTicket?: number;
  tabValue: number;
  signStatus: string;
  statistics: DealStatistic;
}
const useStyles = makeStyles({
  addInvestors:{
    "&:hover":{
      backgroundColor:"var(--color-dark-purple)",
      color:"var(--color-light-grey)"
    }
  }
})
function TopBar(props: Props) {
  const classes = useStyles()
  const intl = useIntl();
  const {
    admin,
    deal,
    onChangeTab,
    investDeal,
    addInvestors,
    onChangeStatus,
    reset,
    tabValue,
    signStatus,
    statistics,
  } = props;

  const dealStatus = {
    DRAFT: `${intl.messages['draft']}`,
    PREVIEW: `${intl.messages['preview']}`,
    OPEN: `${intl.messages['open']}`,
    FUNDED: `${intl.messages['funded']}`,
    CLOSED: `${intl.messages['closed']}`,
    ARCHIVED: `${intl.messages['archived']}`,
    REMOVED: `${intl.messages['removed']}`,
  };

  const [selectValue, setSelectValue] = useState(deal.status || 'default');

  const statusList = getStatusList(deal.status, intl.messages);

  useEffect(() => {
    setSelectValue(deal.status || 'default');
  }, [deal.status]);

  useEffect(() => {
    if (reset) {
      setSelectValue(deal.status || 'default');
    }
  }, [reset]);

  const changeTab = (e, v) => {
    onChangeTab(v);
  };

  const changeStatus = e => {
    onChangeStatus(e.target.value);
    setSelectValue(e.target.value);
  };

  const onlyDescription = () =>
    !admin &&
    (signStatus === 'NOT_REQUESTED' ||
      signStatus === 'IN_PROCESS' ||
      signStatus === 'DECLINED');

  const status = deal.status ? deal.status.toLowerCase() : '';
  const investDealDisabled = status !== 'open';
  const addInvestorsDisabled = getDealStatus(status) !== 'on';

  const adminTabs = (
    <Grid item xs>
      <CustomTabs value={tabValue} onChange={changeTab}>
        <CustomTab label={`${intl.messages['description']}`} />
        <CustomTab
          style={
            statistics?.invited?.aggregate?.count ? {} : { display: 'none' }
          }
          label={
            <>
              <FormattedMessage id="invites" />
              <NumberLabel label={statistics?.invited?.aggregate?.count || 0} />
            </>
          }
        />
        <CustomTab
          style={
            statistics?.commited?.aggregate?.count ? {} : { display: 'none' }
          }
          label={
            <>
              <FormattedMessage id="commits" />
              <NumberLabel
                label={statistics?.commited?.aggregate?.count || 0}
              />
            </>
          }
        />
        <CustomTab
          style={
            statistics?.totalTickets?.aggregate?.count
              ? {}
              : { display: 'none' }
          }
          label={
            <>
              <FormattedMessage id="tickets" />
              <NumberLabel
                label={statistics?.totalTickets?.aggregate?.count || 0}
              />
            </>
          }
        />
        <CustomTab label={`${intl.messages['documents']}`} />
      </CustomTabs>
    </Grid>
  );

  const adminButtons = (
    <Grid item style={styles.center}>
      <Grid container spacing={2}>
        <Grid item>
          <CustomButton className={classes.addInvestors}
            size={'medium'}
            variant="contained"
            color="primary"
            onClick={addInvestors}
            disabled={addInvestorsDisabled}
            fullWidth
          >
            <Box
              component="span"
              style={{ fontWeight: 800 }}
              display={{ xs: 'inline', md: 'none' }}
            >
              +
            </Box>
            <Box component="span" display={{ xs: 'none', md: 'inline' }}>
              <FormattedMessage id="addInvestors" />
            </Box>
          </CustomButton>
        </Grid>
        <Grid item>
          <Select
            margin={'none'}
            value={selectValue}
            variant={'outlined'}
            onChange={changeStatus}
            className="small"
            fullWidth
          >
            <MenuItem value={selectValue} disabled>
              {dealStatus[selectValue]}
            </MenuItem>
            {statusList.map(status => (
              <MenuItem value={status.value} key={status.name}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );

  const investorTabs = (
    <Grid item xs={12} sm={true}>
      <Grid container alignItems="center" spacing={3} justify="flex-end">
        <Grid item xs={12} sm={'auto'}>
          <CustomTabs
            className="CustomTabs--investor"
            value={tabValue}
            onChange={changeTab}
          >
            <CustomTab label={`${intl.messages['description']}`} />
            <CustomTab
              style={
                statistics?.investorTickets?.aggregate?.count
                  ? {}
                  : { display: 'none' }
              }
              label={
                <>
                  <FormattedMessage id="tickets" />
                  <NumberLabel
                    label={statistics?.investorTickets?.aggregate?.count || 0}
                  />
                </>
              }
            />
            <CustomTab label={`${intl.messages['documents']}`} />
          </CustomTabs>
        </Grid>
        <Grid item xs={12} sm={'auto'} style={{ textAlign: 'center' }}>
          <Button
            size={'small'}
            color="primary"
            variant="contained"
            onClick={investDeal}
            disabled={investDealDisabled}
            style={{ marginTop: '5px', marginBottom: '5px' }}
          >
            {`${intl.messages['investInThisDeal']}`.toUpperCase()}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const notSignedTab = (
    <Grid item xs>
      <Tabs
        value={tabValue}
        onChange={changeTab}
        indicatorColor={'primary'}
        textColor={'primary'}
        style={{ marginLeft: '7%' }}
      >
        <CustomTab label="Description" />
      </Tabs>
    </Grid>
  );
  return (
    <div style={styles.container}>
      <Container>
        <Grid container alignItems="center">
          {onlyDescription() ? (
            notSignedTab
          ) : (
            <>
              <Grid item xs={12} sm={'auto'}>
                <StatusLabel status={getDealStatus(status)} text={status} />
              </Grid>
              {admin ? adminTabs : investorTabs}
              {admin && statusList.length > 0 && adminButtons}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}

export default memo(TopBar);
