import React, { memo, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  makeStyles,
  Input,
  TextField,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'components/Select';
import { SigniningDynamicField } from 'models/signing-dynamic-field';
import AntSwitch from 'containers/RegisterVehicles/components/switch';
import makeSelectTemplateCreateDialog from '../selectors';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { getKeyAction } from '../actions';
import Autocomplete from '@material-ui/lab/Autocomplete';

const stateSelector = createStructuredSelector({
  state: makeSelectTemplateCreateDialog(),
});

const useStyles = makeStyles(() => ({
  inlineText: {
    display: 'inline-block',
    minWidth: 100,
  },
  cell: {
    padding: 4,
  },
  input: {
    // border: '1px solid #BDBFC3',
    paddingLeft: 0,
    paddingRight: 0,
    margin: 4,
  },
  smallInput: {
    border: '1px solid #BDBFC3',
    paddingLeft: 4,
    paddingRight: 4,
    margin: 4,
    width: '50%',
  },
  fullWidth: {
    width: '100%',
  },
  container: {
    padding: 16,
    marginTop: 12,
    marginBottom: 12,
  },
  removeBtn: {
    color: '#D74F4F',
  },
  switch: {
    margin: 8,
  },
  autoComplete: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
interface Props {
  onChanged: (localId: number, change: any) => void;
  onDelete: (item: SigniningDynamicField) => void;
  item: SigniningDynamicField;
}

function TemplateKey(props: Props) {
  const intl = useIntl();
  const { onChanged, onDelete } = props;
  const classes = useStyles();
  const { state } = useSelector(stateSelector);
  const { signatureKey, dynamicKey } = state;
  const [signatureList, setSignatureList] = React.useState<any[]>([]);
  const [dynamicList, setDynamicList] = React.useState<any[]>([]);
  const [item, setItem] = React.useState<SigniningDynamicField>(props.item);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const handleChange = (value, fieldName) => {
    onChanged(item.localId, { [fieldName]: value });

    // If Type has been changed, set Value to default value as well
    if (fieldName === 'type') {
      switch (value) {
        case 'MANUAL_FORM':
          onChanged(item.localId, { value: undefined });
          break;
        case 'MANUAL_CHECKBOX':
          onChanged(item.localId, { value: undefined });
          break;
        case 'STATIC':
          onChanged(item.localId, { value: '' });
          break;
        case 'SIGNATURE':
          onChanged(item.localId, { value: signatureList[0]?.key });
          break;
        case 'DYNAMIC':
          onChanged(item.localId, { value: dynamicList[0]?.key });
          break;
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!signatureKey || signatureKey.length === 0) {
      dispatch(getKeyAction());
    } else {
      setSignatureList(() => {
        return Object.keys(signatureKey).map(item => {
          return { value: signatureKey[item], key: item };
        });
      });
    }
  }, [signatureKey]);

  useEffect(() => {
    if (dynamicKey) {
      setDynamicList(() => {
        return Object.keys(dynamicKey).map(item => {
          return { value: dynamicKey[item], key: item };
        });
      });
    }
  }, [dynamicKey]);

  // useEffect(() => {
  //   onChanged(item.localId, { value: signatureList[0]?.key });
  // }, [signatureList]);
  //
  // useEffect(() => {
  //   onChanged(item.localId, { value: dynamicList[0]?.key });
  // }, [dynamicList]);

  const getSignatureValue = value => {
    const returnVal = signatureList.find(item => {
      return item.key === value;
    });

    return returnVal?.key || signatureList[0]?.key;
  };

  const getDynamicValue = value => {
    const returnVal = dynamicList.find(item => {
      return item.key === value;
    });

    return returnVal || dynamicList[0]?.key;
  };

  const style = [
    { value: `${intl.messages['normal']}`, key: 'STANDARD' },
    { value: `${intl.messages['bold']}`, key: 'BOLD' },
    { value: `${intl.messages['italic']}`, key: 'ITALIC' },
    { value: `${intl.messages['boldAndItalic']}`, key: 'BOLD_ITALIC' },
  ];

  const type = [
    { value: `${intl.messages['dynamic']}`, key: 'DYNAMIC' },
    { value: `${intl.messages['static']}`, key: 'STATIC' },
    { value: `${intl.messages['signtaure']}`, key: 'SIGNATURE' },
    { value: `${intl.messages['input']}`, key: 'MANUAL_FORM' },
    { value: `${intl.messages['checkBox']}`, key: 'MANUAL_CHECKBOX' },
  ];
  const fontSize = [
    { value: '7', key: '7' },
    { value: '8', key: '8' },
    { value: '9', key: '9' },
    { value: '10', key: '10' },
    { value: '11', key: '11' },
    { value: '12', key: '12' },
    { value: '14', key: '14' },
    { value: '16', key: '16' },
    { value: '18', key: '18' },
    { value: '20', key: '20' },
    { value: '22', key: '22' },
    { value: '24', key: '24' },
    { value: '26', key: '26' },
    { value: '28', key: '28' },
    { value: '36', key: '36' },
    { value: '48', key: '48' },
    { value: '72', key: '72' },
  ];

  return (
    <Card variant="outlined" className={classes.container}>
      <Grid container spacing={2} justify="space-around">
        <Grid container item xs={7} alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Typography className={classes.inlineText}>
              <FormattedMessage id="key" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Input
              defaultValue={item.key}
              onChange={ev => {
                handleChange(ev.target.value, 'key');
              }}
              disableUnderline
              className={`${classes.input}  ${classes.fullWidth}`}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.inlineText}>
              <FormattedMessage id="type" />
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Select
              defaultValue={item.type || ''}
              style={{ margin: 4, width: '100%' }}
              items={type}
              onChange={ev => {
                handleChange(ev.target.value, 'type');
              }}
              className={classes.smallInput}
            />
          </Grid>
          <Grid item xs={5}>
            {item.type === 'MANUAL_FORM' && (
              <>
                <Typography
                  className={classes.inlineText}
                  style={{ textAlign: 'right' }}
                >
                  <FormattedMessage id="isRequired" />
                </Typography>
                <AntSwitch
                  defaultChecked={item.isRequire}
                  className={classes.switch}
                  onChange={ev => {
                    handleChange(ev.target.checked, 'isRequire');
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            {item.type !== 'MANUAL_FORM' && item.type !== 'MANUAL_CHECKBOX' && (
              <Typography className={classes.inlineText}>
                <FormattedMessage id="value" />
              </Typography>
            )}
          </Grid>
          <Grid item xs={10}>
            {item.type === 'STATIC' && (
              <Input
                defaultValue={item.value || ''}
                onChange={ev => {
                  handleChange(ev.target.value, 'value');
                }}
                disableUnderline
                className={`${classes.input}  ${classes.fullWidth}`}
              />
            )}
            {item.type === 'SIGNATURE' && signatureList.length > 0 && (
              <Select
                defaultValue={getSignatureValue(item.value)}
                style={{ margin: 4 }}
                items={signatureList}
                onChange={ev => {
                  handleChange(ev.target.value, 'value');
                }}
                className={`${classes.input}  ${classes.fullWidth}`}
              />
            )}
            {item.type === 'DYNAMIC' && dynamicList.length > 0 && (
              <Autocomplete
                options={dynamicList}
                value={getDynamicValue(item.value)}
                className={classes.autoComplete}
                getOptionLabel={option => option.value}
                onChange={(event, value) => handleChange(value.key, 'value')}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        className: classes.autoComplete,
                      }}
                    />
                  );
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={5} alignItems="center" spacing={2}>
          <Grid item xs={10}>
            <Typography className={classes.inlineText}>
              <FormattedMessage id="forceUppercase" />
            </Typography>
            <AntSwitch
              defaultChecked={item.isUppercase}
              className={classes.switch}
              onChange={ev => {
                handleChange(ev.target.checked, 'isUppercase');
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={`${classes.inlineText} ${classes.removeBtn}`}
              onClick={() => {
                onDelete(item);
              }}
            >
              <FormattedMessage id="delete" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.inlineText}>
              <FormattedMessage id="fontSize" />
            </Typography>
            <Select
              defaultValue={item.fontSize ? item.fontSize : '10'}
              style={{ margin: 4 }}
              items={fontSize}
              onChange={ev => {
                handleChange(ev.target.value, 'fontSize');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.inlineText}>
              <FormattedMessage id="style" />
            </Typography>
            <Select
              defaultValue={item.style ? item.style : 'STANDARD'}
              style={{ margin: 4 }}
              items={style}
              onChange={ev => {
                handleChange(ev.target.value, 'style');
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default memo(TemplateKey);
