/*
 *
 * InvestorCreateDialog constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InvestorCreateDialog/DEFAULT_ACTION',
  CLEAR_FORM_ACTION = 'app/InvestorCreateDialog/CLEAR_FORM_ACTION',
  CREATE_INVESTOR = 'app/InvestorCreateDialog/CREATE_INVESTOR',
  VALIDATE_INVESTOR = 'app/InvestorCreateDialog/VALIDATE_INVESTOR',
  SET_LOADING = 'app/InvestorCreateDialog/SET_LOADING',
  SET_ERROR = 'app/InvestorCreateDialog/SET_ERROR',
}

export default ActionTypes;
