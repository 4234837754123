import React, { memo, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { gql, useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';

import { getNotificationTypes } from 'services/api/notification-service';

import SearchInput from 'components/SearchInput';
import Filter from './components/Filter';
import { PAGE_SIZE } from 'utils/constants';
import ListNotifications from 'gql/notifications/ListNotifications.gql';
import UpdateNotificationByIdMutation from 'gql/notifications/UpdateNotificationById.gql';
import NotificationsList from './components/NotificationsList';
import { setIsLoading } from 'containers/Home/actions';

function Notifications() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [type, setType] = useState('All');
  const [page, setPage] = useState<number>(1);
  const [notificationTypes, setNotificationTypes] = useState([{key: 'All', value: 'All'}]);

  const { loading, error, data, refetch } = useQuery(ListNotifications, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      type: `%${type === 'All' ? '' : type}%`,
      searchKey: `%${searchKey}%`
    },
  });

  const [updateNotificationById] = useMutation(UpdateNotificationByIdMutation, {
		update(cache, { data: { updateNotificationById } }) {
			cache.modify({
				fields: {
					todos(currentNotifications = []) {
						const newNotificationRef = cache.writeFragment({
							data: updateNotificationById,
							fragment: gql`
								fragment NewNotification on notifications {
									id
									read_at
								}
							`,
						});
						return [...currentNotifications, newNotificationRef];
					},
				},
			});
		},
  });

  useEffect(() => {
    getNotificationTypes().then(data => {
      const list = data.map(e => (
          {
            key: e,
            value: intl.messages[e]
          }
        )
      );
      setNotificationTypes([{key: 'All', value: intl.messages['All']}, ...list]);
    });
  }, []);

  useEffect(() => {
    refetch();
  },[])

  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);

  const onSelectType = value => {
    setType(value);
    setPage(1);
  };

  const fetchPage = value => {
    setPage(value);
  };

  const markAsRead = (item) => {
    dispatch(setIsLoading(true));
    updateNotificationById({
      variables: {
        notificationId: item.id
      },
    }).finally(() => {
      dispatch(setIsLoading(false));
    });
  };

  return (
    <Container className="main">
      <div style={{ marginBottom: '15px' }}>
        <SearchInput
          onChange={v => {
            setSearchKey(v);
            setPage(1);
          }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Filter
          type={type}
          types={notificationTypes}
          onSelectType={onSelectType}
        />
      </div>
      <div>
      {!error && !loading && (
        <NotificationsList
          type={type}
          notifications={data.notifications}
          total={data.notifications_aggregate.aggregate.count}
          page={page}
          markAsRead={markAsRead}
          fetchPage={fetchPage}
        />
      )}
      </div>
    </Container>
  );
}

export default memo(Notifications);
