/*
 *
 * Home
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Grid,
  Button,
  IconButton,
  Container,
  Link,
} from '@material-ui/core';
import whiteIcon from '../../../images/logos/Jadesquare-logo-white.svg';
import Img from 'components/Img';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import MuiDrawer from './drawer';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as LogoutSvg } from 'images/icons/with-class/logout.svg';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: '#191919',
    },
    menuButton: {
      lineHeight: '19px',
      color: '#FFF',
      fontSize: '13px',
      fontWeight: 600,
      padding: '5px 10px',
      borderRadius: '5px',
      border: '1px solid transparent',
      '&:hover': {
        borderColor: '#999',
        color: '#FFF',
        textDecoration: 'none',
      },
    },
    selectedButton: {
      backgroundColor: 'var(--color-dark-purple)',
      color: '#FFF',
      '&:hover': {
        borderColor: 'var(--color-dark-purple)',
        color: '#FFF',
      },
    },
    logo: {
      padding: `${theme.spacing(3)} 0`,
      textAlign: 'center',
    },
    iconButton: {
      fontSize: '13px',
      whiteSpace: 'nowrap',
      padding: theme.spacing(4),
      '&:hover .svg-stroke': {
        stroke: 'white',
      },
    },
    navToggler: {
      '& svg': {
        fill: 'var(--color-light-grey)',
      },
    },
    li: {
      color: 'var(--color-grey)',
      display: 'block',
      '&:hover': {
        textDecoration: 'none',
        color: 'var(--color-purple)',
      },
    },
    activeLi: {
      color: 'var(--color-light-grey)',
    },
    showMobile: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    showDesktop: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
  }),
);

interface Props {
  onTabChanged: (value: number) => void;
  currentTab: number;
  name?: string;
  isAdmin: boolean;
  logout: () => void;
}

interface ILink {
  visibleText: string;
  url: string;
  hideAdmin: boolean;
  hideUser: boolean;
  hideDesktop: boolean;
}

interface ExtendedLink {
  visibleText: string;
  url: string;
  hideAdmin: boolean;
  hideUser: boolean;
  hideDesktop: boolean;
  children?: ILink[];
}

function AppBar(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const { currentTab, logout } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const ref = useRef(null);

  const links: ExtendedLink[] = [
    {
      visibleText: `${intl.messages['dashboard']}`.toUpperCase(),
      url: '/dashboard',
      hideAdmin: false,
      hideUser: false,
      hideDesktop: false,
    },
    {
      visibleText: `${intl.messages['companies']}`.toUpperCase(),
      url: '/companies',
      hideAdmin: false,
      hideUser: true,
      hideDesktop: false,
    },
    {
      visibleText: `${
        intl.messages[props.isAdmin ? 'deals' : 'dealFlow']
      }`.toUpperCase(),
      url: '/deals',
      hideAdmin: false,
      hideUser: false,
      hideDesktop: false,
    },
    {
      visibleText: `${intl.messages['portfolio']}`.toUpperCase(),
      url: '/portfolio',
      hideAdmin: true,
      hideUser: false,
      hideDesktop: false,
    },
    {
      visibleText: `${intl.messages['investors']}`.toUpperCase(),
      url: '/investors',
      hideAdmin: false,
      hideUser: true,
      hideDesktop: false,
    },
    {
      visibleText: `${intl.messages['secondaryMarket']}`.toUpperCase(),
      url: '/secondary-market',
      hideAdmin: false,
      hideUser: false,
      hideDesktop: false,
    },
    {
      visibleText: `${intl.messages['info']}`.toUpperCase(),
      url: 'none',
      hideAdmin: false,
      hideUser: false,
      hideDesktop: false,
      children: [
        {
          visibleText: `${intl.messages['getStarted']}`.toUpperCase(),
          url: '/get-started',
          hideAdmin: true,
          hideUser: false,
          hideDesktop: false,
        },
        {
          visibleText: `${intl.messages['aboutUs']}`.toUpperCase(),
          url: '/our-company',
          hideAdmin: true,
          hideUser: false,
          hideDesktop: false,
        },
        {
          visibleText: `${intl.messages['investments']}`.toUpperCase(),
          url: '/investments',
          hideAdmin: true,
          hideUser: false,
          hideDesktop: false,
        },
        {
          visibleText: `${intl.messages['faq']}`.toUpperCase(),
          url: '/faq',
          hideAdmin: true,
          hideUser: false,
          hideDesktop: false,
        },
      ],
    },
    {
      visibleText: `${intl.messages['notifications']}`.toUpperCase(),
      url: '/notifications',
      hideAdmin: false,
      hideUser: true,
      hideDesktop: false,
    },
    {
      visibleText: `${intl.messages['documentSettings']}`.toUpperCase(),
      url: '/settings/document',
      hideAdmin: false,
      hideUser: true,
      hideDesktop: true,
    },
  ];

  const handleClose = () => {
    setIsDropDownOpen(false);
  };

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) handleClose();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleSelectTab = url => {
    redirect(url);
  };

  const onPressLogOut = () => {
    logout();
  };

  const redirect = url => {
    dispatch(push(url));
  };

  const renderMenuButton = (item: ExtendedLink) => {
    const { visibleText, url, hideAdmin, hideUser, hideDesktop } = item;
    const isActive = location.pathname.startsWith(url);
    const shouldBeVisible =
      ((!hideAdmin && props.isAdmin) ||
        (!hideUser && !props.isAdmin) ||
        (!hideAdmin && !hideUser)) &&
      !hideDesktop;
    return shouldBeVisible ? (
      url !== 'none' ? (
        <Grid key={url} item xs={12} lg={'auto'}>
          <Link
            href={url}
            onClick={e => {
              e.preventDefault();
              redirect(url);
            }}
            className={`${classes.menuButton} ${
              isActive ? classes.selectedButton : ''
            }`}
          >
            {visibleText}
          </Link>
        </Grid>
      ) : (
        <Grid key={url} item xs={12} lg={'auto'}>
          <Link
            href="#"
            onClick={e => {
              e.preventDefault();
              setIsDropDownOpen(!isDropDownOpen);
            }}
            onFocus={e => {
              e.preventDefault();
              setIsDropDownOpen(!isDropDownOpen);
            }}
            className={`${classes.menuButton} ${
              isDropDownOpen ? classes.selectedButton : ''
            }`}
          >
            {visibleText} <KeyboardArrowDownIcon />
          </Link>
          <ul
            ref={ref}
            style={
              isDropDownOpen
                ? {
                    position: 'absolute',
                    zIndex: 999,
                    width: '8rem',
                    backgroundColor: 'var(--color-dark-grey)',
                    listStyle: 'none',
                    padding: '0.5rem',
                    borderRadius: '3px',
                    fontSize: '0.875rem',
                    border: '0.5px solid var(--color-medium-dark-grey)',
                  }
                : { display: 'none' }
            }
          >
            {item.children &&
              item.children.map((link, index, arr) => {
                const isActiveLi = location.pathname.startsWith(link.url);
                return (
                  <li key={index}>
                    <Link
                      className={`${classes.li} ${
                        isActiveLi ? classes.activeLi : ''
                      } `}
                      href={link.url}
                      onClick={e => {
                        e.preventDefault();
                        redirect(link.url);
                        setIsDropDownOpen(!isDropDownOpen);
                      }}
                      onBlur={e => {
                        e.preventDefault();
                        if (index === arr.length - 1)
                          setIsDropDownOpen(!isDropDownOpen);
                      }}
                    >
                      {link.visibleText}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </Grid>
      )
    ) : null;
  };

  return (
    <div className={classes.root}>
      <MuiAppBar
        position="static"
        color="inherit"
        classes={{ root: classes.appBar }}
      >
        <Container disableGutters={true}>
          <Toolbar variant={'dense'}>
            <div className={classes.showMobile}>
              <IconButton
                classes={{ root: classes.navToggler }}
                aria-label="open drawer"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Grid container alignItems="center">
              <Grid xs={12} lg="auto" item className={classes.logo}>
                <Img
                  src={whiteIcon}
                  style={{ height: 25 }}
                  onClick={() => {
                    redirect('/');
                  }}
                />
              </Grid>

              <div className={classes.showMobile}>
                <MuiDrawer
                  open={open}
                  items={links}
                  isAdmin={props.isAdmin}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onSelected={handleSelectTab}
                  selected={currentTab}
                />
              </div>

              <Grid item className={classes.showDesktop} xs={true}>
                <Container maxWidth={false}>
                  <Grid container spacing={3} justify="flex-end">
                    {links && links.map(renderMenuButton)}
                  </Grid>
                </Container>
              </Grid>
            </Grid>

            <Button
              color="inherit"
              className={classes.iconButton}
              onClick={onPressLogOut}
            >
              <span style={{ paddingRight: '0.5rem', paddingTop: '0.25rem' }}>
                LOGOUT
              </span>
              <LogoutSvg style={{ height: '1rem' }} />
            </Button>
          </Toolbar>
        </Container>
      </MuiAppBar>
    </div>
  );
}

export default memo(AppBar);
