/*
 *
 * Tickets
 *
 */

import React, { useEffect, useState, memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { createStructuredSelector } from 'reselect';
import { Container, Grid } from '@material-ui/core';

import SearchInput from 'components/SearchInput';
import TicketsTable from './components/TicketsTable';
import Filter from './components/Filter';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { cancelTicketAction } from './actions';
import makeSelectTickets from './selectors';
import reducer from './reducer';
import saga from './saga';
import { PAGE_SIZE } from 'utils/constants';
import { Ticket } from 'models/ticket';
import TicketEditDialog from 'containers/TicketEdit';
import Alert from 'components/AlertDialog/alertDialog';
import ErrorToast from 'components/ErrorToast';
import ListTicketsByDealAdminQuery from 'gql/ticket/ListTicketsByDealAdmin.gql';
import ListTicketsByDealNoStatus from 'gql/ticket/ListTicketsByDealAdminNoStatus.gql';
import ListTicketsByDealInvestorQuery from 'gql/ticket/ListTicketsByDealInvestor.gql';
import ListTicketsByDealInvestorQueryNoStatus from 'gql/ticket/ListTicketsByDealInvestorNoStatus.gql';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  ticketsStore: makeSelectTickets(),
});

interface Props {
  dealId: string;
  isAdmin: boolean;
  userId?: string;
}

function Tickets(props: Props) {
  useInjectReducer({ key: 'tickets', reducer: reducer });
  useInjectSaga({ key: 'tickets', saga: saga });
  const intl = useIntl();
  const { dealId, isAdmin, userId } = props;
  const { ticketsStore } = useSelector(stateSelector);
  const { success } = ticketsStore;
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<any[]>([]);
  const [ticketIssuer, setTicketIssuer] = useState('All');
  const [selectedTicket, setSelectedTicket] = useState<Ticket | undefined>();
  const [open, setOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);

  const variables: {
    dealId: string;
    statusIn?: any[];
    offset: number;
    limit: number;
    searchKey?: string;
    userId?: string;
  } =
    status.length && status.length > 0
      ? {
        dealId,
        statusIn:
          status.length && status.length > 0
            ? status.map(stt => stt.value)
            : [],
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
      }
      : {
        dealId,
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
      };

  if (isAdmin) {
    variables.searchKey = `%${searchKey}%`;
  } else {
    variables.userId = userId || '';
  }

  const { loading, error, data, refetch } = useQuery(
    isAdmin
      ? status.length && status.length > 0
        ? ListTicketsByDealAdminQuery
        : ListTicketsByDealNoStatus
      : status.length && status.length > 0
        ? ListTicketsByDealInvestorQuery
        : ListTicketsByDealInvestorQueryNoStatus,
    {
      notifyOnNetworkStatusChange: true,
      variables,
    },
  );

  useEffect(() => {
    if (success) {
      refetch();
      setAlertVisible(false);
    }
  }, [success]);

  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);

  const onCancel = () => {
    setAlertVisible(false);
  };

  const onConfirm = () => {
    if (selectedTicket) {
      dispatch(cancelTicketAction(selectedTicket.ticketId));
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    refetch();
    setOpen(false);
  };

  const cancelTicket = () => {
    setAlertVisible(true);
  };

  const onItemClick = item => {
    setSelectedTicket(item);
    if (isAdmin) {
      setOpen(true);
    } else {
      cancelTicket();
    }
  };

  const onSelectTicketIssuer = issuer => {
    setPage(1);
    setTicketIssuer(issuer);
  };

  const onSelectStatus = stt => {
    setPage(1);
    setStatus(stt);
  };

  const onChangeSearchKey = value => {
    if (isFirstPageLoad) {
      setIsFirstPageLoad(false);
    } else {
      //setPage(1);
    }
    setSearchKey(value);
  };

  const fetchPage = value => {
    setPage(value);
  };

  // useEffect(() => {
  //   refetch();
  // }, []);

  return (
    <Container className="main">
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? error.message : ''}
      />

      <div>
        {isAdmin && (
          <div style={{ marginBottom: '15px' }}>
            <SearchInput onChange={onChangeSearchKey} />
          </div>
        )}
        <div style={{ marginBottom: '15px' }}>
          <Filter
            onSelectStatus={onSelectStatus}
            onSelectTicketIssuer={onSelectTicketIssuer}
            status={status}
            ticketIssuer={ticketIssuer}
          />
        </div>
        <div>
          {!loading && !error && (
            <TicketsTable
              tickets={data.ticket}
              total={data.ticket_aggregate.aggregate.count}
              fetchPage={fetchPage}
              onItemClick={onItemClick}
              isAdmin={isAdmin}
              page={page}
            />
          )}
        </div>
      </div>
      <TicketEditDialog
        open={open}
        onClose={onClose}
        onSuccess={onSuccess}
        ticket={selectedTicket}
      />
      <Alert
        title={`${intl.messages['Tickets.cancelTicket']}`}
        open={alertVisible}
        onCancel={onCancel}
        onConfirm={onConfirm}
        description={intl.messages['Tickets.cancelMsg']}
      />
    </Container>
  );
}

export default memo(Tickets);
