/*
 *
 * DealList
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { replace } from 'connected-react-router';
import { Location } from 'history';
import { Container } from '@material-ui/core';
import ErrorToast from 'components/ErrorToast';
import SearchInput from 'components/SearchInput';
import DealTable from './components/dealTable';
import FilterDeal from './components/filter';
import { PAGE_SIZE } from 'utils/constants';
import ListDealsAdminPreferenceTagFilterQuery from 'gql/deal/ListDealsAdminPreferenceTagFilter.gql';
import ListDealsAdminQuery from 'gql/deal/ListDealsAdmin.gql';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

interface Props {
  location: Location;
}

function DealList(props: Props) {
  const { location } = props;
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [preferenceTagsFilter, setPreferenceTagsFilter] = useState([]);
  const [page, setPage] = useState<number>(1);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
  const { currentUser } = useSelector(stateSelector);

  const LANGUAGE_CODE = currentUser?.languageCode
    ? currentUser.languageCode
    : 'en';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamPage = Number(searchParams.get('page'));

    if (searchParamPage) {
      refreshPageStateBasedOnURL(searchParamPage);
    }
  }, []);

  useEffect(() => {
    refreshURLBasedOnPageState();
  }, [page]);

  const refreshPageStateBasedOnURL = searchParamPage => {
    if (page !== searchParamPage) {
      setPage(searchParamPage);
    }
  };

  const refreshURLBasedOnPageState = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamPage = Number(searchParams.get('page'));

    if (page !== searchParamPage) {
      searchParams.set('page', String(page));
      dispatch(replace(`${location.pathname}?${searchParams.toString()}`));
    }
  };

  const { loading, error, data, refetch } = useQuery(
    preferenceTagsFilter.length > 0
      ? ListDealsAdminPreferenceTagFilterQuery
      : ListDealsAdminQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables:
        preferenceTagsFilter.length > 0
          ? {
              searchKey: `%${searchKey}%`,
              statusFilter: `%${statusFilter === 'All' ? '' : statusFilter}%`,
              preferenceTagsFilter:
                preferenceTagsFilter.length > 0 ? preferenceTagsFilter : [],
              offset: (page - 1) * PAGE_SIZE,
              limit: PAGE_SIZE,
              language: LANGUAGE_CODE || 'en',
            }
          : {
              searchKey: `%${searchKey}%`,
              statusFilter: `%${statusFilter === 'All' ? '' : statusFilter}%`,
              offset: (page - 1) * PAGE_SIZE,
              limit: PAGE_SIZE,
            },
    },
  );

  const onChangeSearchKey = value => {
    if (isFirstPageLoad) {
      setIsFirstPageLoad(false);
    } else {
      setPage(1);
    }
    setSearchKey(value);
  };

  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);
  const onChangeStatus = value => {
    setPage(1);
    setStatusFilter(value);
  };

  const onChangePreferenceTags = value => {
    setPage(1);
    setPreferenceTagsFilter(value);
  };

  const fetchPage = value => {
    setPage(value);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Container className="main">
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? error.message : ''}
      />
      <div>
        <div style={{ marginBottom: 12 }}>
          <SearchInput onChange={onChangeSearchKey} />
        </div>
        <FilterDeal
          onChangeStatus={onChangeStatus}
          statusFilter={statusFilter}
          onChangePreferenceTags={onChangePreferenceTags}
        />
      </div>
      <div>
        {!error && !loading && (
          <DealTable
            items={data.deal_translation}
            total={data.deal_aggregate.aggregate.count}
            fetchPage={fetchPage}
            page={page}
          />
        )}
      </div>
    </Container>
  );
}

export default memo(DealList);
