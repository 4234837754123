import React, { memo, useState, useEffect } from 'react';
import MultiSelect from 'components/MultiSelect';
import { map, flattenDeep } from 'lodash';

interface Props {
  onSelect: (value: any[]) => void;
  option: any;
  defaultValue: any[];
  clear?: boolean;
  onClear?: () => void;
  [propName: string]: any;
}

const getOptions = options => {
  if (Array.isArray(options)) {
    return options.map(item => ({
      title: item.name,
      value: item.id,
      group: item.category,
    }));
  } else {
    const a = flattenDeep(
      map(options, (tags, key) =>
        tags.map(value => ({
          title: value.name,
          value: value.preferenceTagId,
          group: key,
        })),
      ),
    );
    return a;
  }
};

const TagsSelector = (props: Props) => {
  const {
    onSelect,
    option,
    defaultValue,
    clear,
    onClear,
    value,
    // tslint:disable-next-line: trailing-comma
    ...rest
  } = props;

  const [options, setOptions] = useState(getOptions(option));

  useEffect(() => {
    setOptions(getOptions(option));
    if(defaultValue) {
      handleOnSelect(options.filter(o => defaultValue.includes(o.value)));
    }
   
  }, [option]);

  const handleOnSelect = values => {
    onSelect(values.map(v => v.value));
  };

  return (
    <MultiSelect
      {...rest}
      value={value ? options.filter(o => value.includes(o.value)) : undefined}
      options={options}
      defaultValue={defaultValue}
      onSelect={handleOnSelect}
      clear={clear}
      onClear={onClear}
      groupBy={option => `${option.group} PREFERENCES`}
    />
  );
};

export default memo(TagsSelector);
