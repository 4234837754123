/*
 *
 * AdminDocuments actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const getNumberOfDocuments = dealId =>
  action(ActionTypes.GET_NUMBERS_OF_DOCUMENTS, dealId);

export const getNumberOfDocumentsSucceed = data =>
  action(ActionTypes.GET_NUMBERS_OF_DOCUMENTS_SUCCEED, data);

export const getDocuments = (request: {
  dealId: string;
  type: string;
  isPublic?: boolean;
}) => action(ActionTypes.GET_DOCUMENTS, request);

export const getDocumentsSucceed = (data: any) =>
  action(ActionTypes.GET_DOCUMENTS_SUCCEED, data);

export const createGroup = (data: {
  dealId: string;
  title: string;
  type: string;
  isPublic?: boolean;
}) => action(ActionTypes.CREATE_GROUP, data);

export const deleteGroup = (data: {
  dealId: string;
  type: string;
  groupId: string;
  isPublic?: boolean;
  sendMail: boolean;
}) => action(ActionTypes.DELETE_GROUP, data);

export const createDocument = (data: {
  documentGroupId: string;
  title: string;
  dealId: string;
  type: string;
  isPublic?: boolean;
  files: any[];
  sendMail: boolean;
  newFileTitles: string[];
}) => action(ActionTypes.CREATE_DOCUMENT, data);

export const createDocumentSucceed = () =>
  action(ActionTypes.CREATE_DOCUMENT_SUCCEED);

export const deleteDocument = (data: {
  dealId: string;
  type: string;
  documentId: string;
  isPublic?: boolean;
  sendMail: boolean;
}) => action(ActionTypes.DELETE_DOCUMENT, data);

export const editDocument = (data: {
  documentGroupId: string;
  title: string;
  dealId: string;
  type: string;
  isPublic?: boolean;
  files: any[];
  oldFiles: any[];
  documentId: string;
  sendMail: boolean;
}) => action(ActionTypes.EDIT_DOCUMENT, data);

export const editDocumentSucceed = () =>
  action(ActionTypes.EDIT_DOCUMENT_SUCCEED);

export const requestSign = (request: {
  eSignTemplateId: string;
  dealId: string;
  vehicleIds: string[];
  esignDocumentId?: string | undefined;
}) => action(ActionTypes.REQUEST_SIGN, request);

export const requestSignSucceed = () =>
  action(ActionTypes.REQUEST_SIGN_SUCCEED);

export const refreshSignDocument = id =>
  action(ActionTypes.REFRESH_SIGN_DOCUMENT, id);

export const refreshSignGroup = id =>
  action(ActionTypes.REFRESH_SIGN_GROUP, id);

export const refreshSignDocumentSucceed = data =>
  action(ActionTypes.REFRESH_SIGN_DOCUMENT_SUCCEED, data);

export const refreshSignGroupSucceed = id =>
  action(ActionTypes.REFRESH_SIGN_GROUP_SUCCEED, id);

export const setLoading = loading => action(ActionTypes.SET_LOADING, loading);

export const setLoadingDocument = loading =>
  action(ActionTypes.SET_LOADING_DOCUMENT, loading);

export const sendPassword = payload =>
  action(ActionTypes.SEND_PASSWORD_ACTION, payload);

export const moveDocumentSuccess = () =>
  action(ActionTypes.MOVE_DOCUMENT_SUCCESS);