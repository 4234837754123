/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Select from 'components/Select';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'var(--color-super-dark-grey)',
      paddingTop: 12,
    },
    subTitle: {
      textTransform: 'uppercase',
      color: '#000',
      fontSize: '0.8rem',
    },
    button: {
      textTransform: 'none',
    },
    title: {
      color: '#000',
      margin: theme.spacing(2, 0),
    },
    filterContainer: {
      //margin: theme.spacing(0, 1),
      display: 'inline',
      width: 100,
      color: 'var(--color-grey)',
      fontSize: '14px',
    },
    filter: {
      border: '0.5px solid #BDBFC3',
      ':&selected': {
        border: '0.5px solid #BDBFC3',
      },
    },
    interest: {
      minWidth: 150,
    },
    select: {
      //minWidth: 75,
      border: 'none',
      fontSize: '14px',
    },
    clearBtn: {
      color: '#D74F4F',
      '&:hover': {
        color: '#B74F4F',
      },
    },
    titleText: {
      padding: 4,
      fontWeight: 'bold',
    },
    container: {
      minWidth: 150,
    },
  }),
);

interface Props {
  onChangeStatus: (value) => void;
  status: string | undefined;
}

function FilterDeal(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const { onChangeStatus, status } = props;
  // const [selectStatus, setSelectedStatus] = React.useState('All');
  const handleChange = value => {
    // setSelectedStatus(value.target.value);
    if (value.target.value === 'All') {
      onChangeStatus('All');
    } else {
      onChangeStatus(value.target.value);
    }
  };

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item container>
        <Grid item className={classes.container} xs>
          <Typography className={classes.filterContainer}>
            <FormattedMessage id="status" />
            {':'}
          </Typography>
          <Select
            className={classes.select}
            onChange={handleChange}
            value={status}
            IconComponent={ExpandMoreIcon}
            items={[
              { value: `${intl.messages['all']}`, key: 'All' },
              { value: `${intl.messages['invested']}`, key: 'INVESTED' },
              { value: `${intl.messages['notInvested']}`, key: 'NOT_INVESTED' },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(FilterDeal);
