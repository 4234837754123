/*
 *
 * Authentication reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  emailError: false,
  passwordError: false,
  messageError: '',
  loading: false,
};

const authenticationReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SIGN_IN:
        draft.loading = true;
        break;
      case ActionTypes.SIGN_IN_SUCCESS:
        draft.loading = false;
        draft.messageError = '';
        draft.emailError = false;
        draft.passwordError = false;
        break;
      case ActionTypes.SIGN_IN_ERROR:
        draft.loading = false;
        draft.messageError = action.payload.messageError;
        draft.emailError = action.payload.emailError;
        draft.passwordError = action.payload.passwordError;
        break;
    }
  },
  initialState,
);

export default authenticationReducer;
