import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import { produce, Draft } from 'immer';

export const initialState: ContainerState = {
  investors: undefined,
  total: 10,
  currentPage: 0,
  refreshPage: undefined,
};

const investorsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_INVESTOR_SUCCESS_ACTION:
        draft.refreshPage = false;
        draft.investors = action.payload.data;
        draft.total = action.payload.metaData['total'];
        break;
      case ActionTypes.FETCH_INVESTOR_FAILED_ACTION:
        draft.investors = [];
        break;
      case ActionTypes.CHANGE_STATUS_SUCCESS_ACTION:
        draft.refreshPage = true;
        break;
      case ActionTypes.SET_REFRESH_PAGE:
        draft.refreshPage = action.payload;
        break;
    }
  },
  initialState,
);

export default investorsReducer;
