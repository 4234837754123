import { takeLatest, call, put } from 'redux-saga/effects';
import { getAllDocumentsGroupByDealId } from 'services/api/document-service';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { getDealSuccessDocument } from './actions';
import { getInvestorDocuments } from 'services/api/documentGroups-service';
import { updateDocumentFiles } from 'services/api/file-service';
import flatMap from 'lodash/flatMap';

function* getDocument(action) {
  try {
    const group = yield call(getInvestorDocuments, action.payload);
    yield put(getDealSuccessDocument(group));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* getFiles(dealId) {
  const documentGroups: any[] = yield call(
    getAllDocumentsGroupByDealId,
    dealId,
  );
  const result = flatMap(
    flatMap(documentGroups, item => {
      return item.documents && item.isDefault && item.title === 'Deal documents'
        ? item.documents
        : [];
    }),
    item => (item.files && item.isDefault ? item.files : []),
  );

  return result;
}

function* uploadDocumentsRequest(action) {
  const { documentId, fileIds } = action.payload;
  try {
    yield call(updateDocumentFiles, documentId, fileIds);
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* dealEditorSaga() {
  yield takeLatest(ActionTypes.GET_DEAL_DOCUMENT, getDocument);
  yield takeLatest(ActionTypes.UPLOAD_DOCUMENT, uploadDocumentsRequest);
}
