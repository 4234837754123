/*
 *
 * Invites actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const getInvites = (request: {
  dealId?: string;
  interestsAny?: any[];
  orders?: any[];
  page?: number;
  pageSize?: number;
  searchKey?: string;
  status?: string;
  investStatus?: string;
}) => action(ActionTypes.GET_INVITES, request);

export const getInvitesSucceed = (data: any) =>
  action(ActionTypes.GET_INVITES_SUCCEED, data);

export const getInvitedFailed = (data: any) =>
  action(ActionTypes.GET_INVITES_FAILED, data);

export const resendInvites = (request: { dealId: string; userIds: string[] }) =>
  action(ActionTypes.RESEND_INVITES, request);

export const resendInvitesSucceed = () =>
  action(ActionTypes.RESEND_INVITES_SUCCEED);

export const rejectInvite = id => action(ActionTypes.REJECT_INVITE, id);

export const rejectInviteSucceed = () =>
  action(ActionTypes.REJECT_INVITE_SUCCEED);
