import React, { memo } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography } from '@material-ui/core';
import Header from './Header';
import EditButton from './EditButton';
import Attachments from './Attachments';
import styles, { DetailWrapper, TagWrapper } from '../styles';
import { useIntl } from 'react-intl';
import Deal from 'models/deal';
import PublicDocuments from './PublicDocuments';

export const numberWithCommas = (num: number | undefined): string => {
  if (!num) {
    return '';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getDealType = (dealType, messages) => {
  switch (dealType) {
    case 'EQUITY':
      return `${messages['equity']}`;
    case 'SAFE':
      return `${messages['SAFEBASAir']}`;
    case 'LOAN':
      return `${messages['loanPortfolios']}`;
    default:
      return '';
  }
};

const style = styles.detail;

const CustomGridIteam = withStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))(Grid);

CustomGridIteam.defaultProps = {
  item: true,
  xs: 12,
  sm: 12,
};

const Title = (props: { children?: any }) => {
  const { children } = props;
  return (
    <Grid xs={12} sm={12} item>
      <div style={style.title}>{children}</div>
    </Grid>
  );
};

const Tag = (props: { children?: any }) => {
  const { children } = props;
  return <TagWrapper>{children}</TagWrapper>;
};

interface FieldProps {
  name: string;
  value: any;
  link?: boolean;
}

const Field = (props: FieldProps) => {
  const { name, value, link } = props;
  if (!value) {
    return <></>;
  }
  return (
    <Grid xs={12} sm={12} item>
      <Grid item xs={12} sm={12}>
        <Grid container>
          <Grid item xs={6} sm={4}>
            <Typography style={style.fieldName}>{name}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={8}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              textAlign: 'right',
            }}
          >
            <Typography style={style.fieldValue}>{value}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface Props {
  deal: Deal;
  admin?: boolean;
  editDeal: () => void;
  attachments?: any[];
  publicDocuments?: any[];
}


function Detail(props: Props) {
  const intl = useIntl();
  const { deal, admin, editDeal, attachments, publicDocuments } = props;

  const isEdit = () => deal.status === 'DRAFT' || deal.status === 'PREVIEW' || true;

  const Fields = (
    <>
      <Field
        name={`${intl.messages['name']}`}
        value={deal.deal_translations[0].name}
      />
      <Field
        name={`${intl.messages['openingDate']}`}
        value={
          deal.openingDate
            ? `${moment(deal.openingDate).format('DD-MM-YYYY, HH:mm')} (SGT)`
            : ''
        }
      />
      <Field
        name={`${intl.messages['closingDate']}`}
        value={
          deal.refClosingDate
            ? moment(deal.refClosingDate).format('DD-MM-YYYY')
            : ''
        }
      />
      <Field
        name={`${intl.messages['dealType']}`}
        value={getDealType(deal.dealType, intl.messages)}
      />
      <Field
        name={`${intl.messages['roundSizeBig']}`}
        value={`$${numberWithCommas(deal.totalSizeRound)}`}
      />
    </>
  );

  const EnquityFields = (
    <>
      {Fields}
      <Field
        name={`${intl.messages['forecastNetProfit']}`}
        value={deal.netProfit ? `$${numberWithCommas(deal.netProfit)}` : ''}
      />
      <Field
        name={`${intl.messages['minTicket']}`}
        value={`$${numberWithCommas(deal.minTicket)}`}
      />
      <Field
        name={`${intl.messages['maxTicket']}`}
        value={`$${numberWithCommas(deal.maxTicket)}`}
      />
      <Field
        name={`${intl.messages['premoneyValuation']}`}
        value={`$${numberWithCommas(deal.premoneyValuation)}`}
      />
    </>
  );

  const SafeFields = (
    <>
      {Fields}
      <Field
        name={`${intl.messages['minTicket']}`}
        value={`$${numberWithCommas(deal.minTicket)}`}
      />
      <Field
        name={`${intl.messages['maxTicket']}`}
        value={`$${numberWithCommas(deal.maxTicket)}`}
      />
      <Field
        name={`${intl.messages['valuationCap']}`}
        value={`$${numberWithCommas(deal.valuationCap)}`}
      />
      <Field
        name={`${intl.messages['valuationFloor']}`}
        value={`$${numberWithCommas(deal.valuationFloor)}`}
      />
      <Field
        name={`${intl.messages['discount']}`}
        value={deal.discount ? `${numberWithCommas(deal.discount)}%` : ''}
      />
    </>
  );

  const LoanFields = (
    <>
      {Fields}
      <Field
        name={`${intl.messages['minLoan']}`}
        value={`$${numberWithCommas(deal.minTicket)}`}
      />
      <Field
        name={`${intl.messages['maxLoan']}`}
        value={`$${numberWithCommas(deal.maxTicket)}`}
      />
      <Field
        name={`${intl.messages['loanInterest']}`}
        value={`${
          deal.loanInterest ? deal.loanInterest.replace('%', '') : ''
        }%`}
      />
    </>
  );

  const compayLink = deal?.company?.website;

  return (
    <Card style={{ marginBottom: 15, padding: '1.25rem' }}>
      <Grid container alignItems="center">
        <Grid xs={true} item>
          <Title>{`${intl.messages['details']}`}</Title>
        </Grid>
        {admin && isEdit() && (
          <Grid
            item
            xs={'auto'}
            style={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <EditButton onClick={editDeal}>
              {`${intl.messages['edit']}`}
            </EditButton>
          </Grid>
        )}
        {deal.dealPreferenceTags && (
          <>
            <Title>{`${intl.messages['tags']}`}</Title>
            <CustomGridIteam style={{ paddingRight: 3, paddingBottom: 3 }}>
              {map(deal.dealPreferenceTags, dealPreferenceTag => (
                <Tag key={dealPreferenceTag.preferenceTag.name}>
                  {dealPreferenceTag.preferenceTag.name}
                </Tag>
              ))}
            </CustomGridIteam>
          </>
        )}
        {deal.deal_translations[0].summary && (
          <>
            <Title>{`${intl.messages['summary']}`}</Title>
            <CustomGridIteam
              style={{
                whiteSpace: 'pre-line',
                textAlign: 'justify',
              }}
            >
              <Typography>{deal.deal_translations[0].summary}</Typography>
            </CustomGridIteam>
          </>
        )}
        {publicDocuments && publicDocuments.length && (
          <PublicDocuments admin={admin} dealId={deal.dealId} publicDocuments={publicDocuments} detail />
        )}
        <Title>{`${intl.messages['company']}`}</Title>
        <Field
          name={`${intl.messages['companyName']}`}
          value={deal?.company?.company_translations[0].name}
        />
        <CustomGridIteam
          style={{
            textAlign: 'right',
          }}
        >
          <Typography style={{ textAlign: 'center' }}>
            {deal?.company?.company_translations[0].description}
          </Typography>
        </CustomGridIteam>
        {/* <Field
          name={`${intl.messages['website']}`}
          link
          value={
            <a
              href={
                compayLink
                  ? compayLink.match(/^http?:\/\//i) ||
                    compayLink.match(/^https?:\/\//i)
                    ? compayLink
                    : `http://${compayLink}`
                  : ''
              }
              style={style.link}
              target="blank"
            >
              {compayLink}
            </a>
          }
        /> */}
        <Title>{`${intl.messages['information']}`}</Title>
        {deal.dealType === 'EQUITY' && EnquityFields}
        {deal.dealType === 'SAFE' && SafeFields}
        {deal.dealType === 'LOAN' && LoanFields}
        {deal.deal_translations[0].deal_additional_details &&
          deal.deal_translations[0].deal_additional_details.map(
            (additional, i) => {
              return (
                <Field name={additional.key} value={additional.value} key={i} />
              );
            },
          )}
      </Grid>
    </Card>
  );
}

export default memo(Detail);
