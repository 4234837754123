import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import CustomSnackbar from './components/CustomSnackBar';
import { makeStyles } from '@material-ui/styles';
import ArrowButton from 'components/ArrowButton';
import SendEmailUniversal from 'components/SendEmailModalUniversal';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { getFaq } from 'services/api/faq-service';
import { useIntl, FormattedMessage } from 'react-intl';
type faqArticle = {
  title: string;
  description: string;
  id: string;
  category?: 'INVESTMENT' | 'OPPORTUNITY';
};
const useStyles = makeStyles({
  categorySelector: {
    color: 'var(--color-grey)',
    listStyleType: 'none',
    padding: '0px',
    display: 'inline',
  },
  li: {
    display: 'inline',
    width: 'auto',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '0.2rem',
    borderBottom: '2px solid #191919',
    marginBottom: '0.5rem',
    '&:hover': {
      color: 'var(--color-dark-purple)',
      borderBottom: '2px solid var(--color-dark-purple)',
    },
  },
  activeLi: {
    color: 'var(--color-dark-purple)',
    borderBottom: '2px solid var(--color-dark-purple)',
  },
});
const FAQPage = () => {
  const stateSelector = createStructuredSelector({
    profile: makeSelectCurrentUser(),
  });
  const { profile } = useSelector(stateSelector);
  const styles = useStyles();
  const [activeSelector, setActiveSelector] = React.useState('ALL');
  const [isEmailActive, setIsEmailActive] = React.useState(false);
  const [data, setData] = useState([]);
  const setSelector = (selected: 'ALL' | 'INVESTMENT' | 'OPPORTUNITY') => {
    setActiveSelector(selected);
  };
  const intl = useIntl();
  const fakeFaqData: faqArticle[] = [
    {
      title: 'What are the privacy policies for investors using JDSQ?',
      description:
        '<p>At Jadesquare, we are committed to safeguarding your privacy. We use administrative technical and physical security measures to protect your Personal Information. These measures include computer safeguards and secured files and facilities.</p><p>We take reasonable steps to securely destroy or permanently de-identify Personal Information when we no longer need it. We will keep your Online information only as long as we must to deliver our products and services, unless we are required by law or regulation or for litigation and regulatory investigations to keep it.</p>',
      id: '2',
      category: 'INVESTMENT',
    },
    {
      title: 'How do we source for high quality investment projects?',
      description:
        '<p>As a Family Office backed Private Placement Platform, we receive countless of investment opportunities daily however good quality investment projects are hard to come by. Both the asset owners and projects that we have taken an interest in will be vetted thoroughly by our team of analysts before any stage of the onboarding process commences.</p>',
      id: '3',
      category: 'OPPORTUNITY',
    },
    {
      title: 'What is our due diligence process on the projects?',
      description:
        '<p>Essentially, we work on the principle that we only list investment opportunities on Jadesquare that our Family Office (not the Jadesquare platform) would personally invest in as well. This way there will always be a shared interest to ensure that all opportunities are of the highest standard without prejudice.</p>',
      id: '4',
      category: 'OPPORTUNITY',
    },
    {
      title: 'How is liquidity provided?',
      description:
        '<p>Through tokenising each asset or investment product being offered on the platform.</p>',
      id: '5',
      category: 'INVESTMENT',
    },
    {
      title: 'What is tokenization?',
      description:
        '<p>Tokenization is a process of creating a digital representation of a real asset or investment product through units called Tokens. Tokens are digital units that represent a part or full asset or investment. These tokens represent a set of rules encoded in a smart contract and each token belongs to a blockchain address. It`s essentially a digital asset that is stored securely on the blockchain.</p>',
      id: '6',
      category: 'INVESTMENT',
    },
    {
      title: 'How do we evaluate each investment project?',
      description:
        '<p>Based on the financial documentation that each investment opportunity needs to submit quarterly, our team of analysts will be hard at work to ensure that we get the most accurate evaluation of the deals available.</p>',
      id: '7',
      category: 'OPPORTUNITY',
    },
    {
      title: 'What is the lock in period?',
      description:
        '<p>Our platform lock in period is set to minimum 1 year to ensure we get a proper valuation of the investment opportunity before investors can liquidate the asset.</p>',
      id: '8',
      category: 'INVESTMENT',
    },
    {
      title: 'What is the exit strategy being offered?',
      description:
        '<p>Due to the benefits of tokenization and ease of liquidity, we have created a secondary market on the platform for investors.</p>',
      id: '9',
      category: 'INVESTMENT',
    },
    {
      title: 'What is JDSQ?',
      description:
        '<p>It is a Private Placement Platform that creates an Investment Ecosystem which builds various curated businesses with the mission to enhance society.</p>',
      id: '10',
      category: 'INVESTMENT',
    },
    {
      title: 'What are the fees/charges involved?',
      description:
        '<p>All the fees are transparent to the investor before every investment.</p>',
      id: '11',
      category: 'INVESTMENT',
    },
    {
      title: 'What are the advantages of JDSQ as a platform?',
      description:
        '<p>A token economy allows for more access to accurate secured information, full investment transparency and inexpensive transaction fees.</p>',
      id: '12',
      category: 'OPPORTUNITY',
    },
    {
      title: 'What is the KYC/AML process?',
      description:
        '<p>Asset owners and investors using the platform will be vetted thoroughly with our proprietary KYC/AML system during the account creation process to ensure that we operate a clean and transparent digital platform.</p>',
      id: '13',
      category: 'INVESTMENT',
    },
    {
      title: 'Who takes care of asset management and other legal requirements?',
      description:
        '<p>This will be entirely up to the decision of the investor on their choice of a representative on their tokens.</p>',
      id: '14',
      category: 'INVESTMENT',
    },
    {
      title: 'How do I commit to an investment deal that I am interested in?',
      description: `
        <div>
            <p>Please see the video below</p>
            <video controls style="width:100%">
                <source src="https://storage.googleapis.com/public-datastore-1/videos/jadesquare-video.mp4" type="video/mp4">
            </video>
        </div>`,
      id: '15',
      category: 'INVESTMENT',
    },
    {
      title: 'Do you offer any guaranteed return?',
      description:
        '<p>The platform does not offer any guaranteed returns. Jadesquare only offers the most up to date accurate information on the investment deals needed for our investors to make a well-informed decision on their part. Investors are encouraged to make their personal assessment before making any investments.</p>',
      id: '16',
      category: 'INVESTMENT',
    },
    {
      title: 'How will I know about new investment listings?',
      description:
        '<p>As users of the platform, you can list down your various interests in different types of asset classes and we will match those interest to the investment opportunities that are available at any point in time. Following which, we will invite you personally via email to view those potential deals.</p>',
      id: '17',
      category: 'OPPORTUNITY',
    },
    {
      title:
        'How can I get specific questions answered on a particular asset or investment listed on our platform?',
      description:
        '<p>Full exclusive information will be provided on every deal for all registered users however if you still need more information, please send all your enquiries to <a href="mailto:info@jadesquare.com.">info@jadesquare.com.</a></p>',
      id: '18',
      category: 'OPPORTUNITY',
    },
  ];

  useEffect(() => {
    // getFaq(activeSelector as 'ALL' | 'INVESTMENT' | 'OPPORTUNITY').then(
    //   response => {
    //     setData(response);

    //     response.forEach(data => {
    //       data.faqTranslation.forEach((el: faqArticle) => {
    //         console.log(el);
    //       });
    //     });
    //   },
    // );
    if (activeSelector === 'ALL') {
      setData(fakeFaqData);
    } else {
      setData(
        fakeFaqData.filter(article => article.category === activeSelector),
      );
    }
  }, [activeSelector]);
  return (
    <>
      <Container className="main">
        <Container maxWidth="lg">
          <h2 style={{ fontWeight: 'bolder', fontSize: '30px' }}>
            <FormattedMessage id="faqQuestions" />
          </h2>
        </Container>
        <Container maxWidth="lg" style={{ marginTop: '3rem' }}>
          <Container
            style={{ padding: '0px', marginBottom: '1rem' }}
            maxWidth="lg"
          >
            <ul className={styles.categorySelector}>
              <li
                onClick={() => {
                  setSelector('ALL');
                }}
                className={`${styles.li} ${
                  activeSelector === 'ALL' ? styles.activeLi : ''
                }`}
              >
                <FormattedMessage id="faqAllQuestions" />
              </li>
              <li
                onClick={() => {
                  setSelector('INVESTMENT');
                }}
                className={`${styles.li} ${
                  activeSelector === 'INVESTMENT' ? styles.activeLi : ''
                }`}
              >
                <FormattedMessage id="faqInvestors" />
              </li>
              <li
                onClick={() => {
                  setSelector('OPPORTUNITY');
                }}
                className={`${styles.li} ${
                  activeSelector === 'OPPORTUNITY' ? styles.activeLi : ''
                }`}
              >
                <FormattedMessage id="faqOpportunities" />
              </li>
            </ul>
          </Container>
          <Grid style={{ marginTop: '0.2rem' }} container spacing={1}>
            <Grid xs={12} sm={12} md={8} lg={8}>
              {/* {data &&
                data.map((articles: { faqTranslation: faqArticle[] }) => {
                  return articles.faqTranslation!.map((article: faqArticle) => {
                    return <CustomSnackbar article={article} />;
                  });
                })} */}
              {data &&
                data.map(article => {
                  return <CustomSnackbar article={article} />;
                })}
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4}>
              <div style={{ paddingLeft: '20px' }}>
                <h4 style={{ fontSize: '20px', margin: '5px' }}>
                  <FormattedMessage id="faqMessageTitle" />
                </h4>
                <p style={{ color: '#999999', fontSize: '14px' }}>
                  <FormattedMessage id="faqMessageDescription" />
                </p>
                <ArrowButton
                  onClick={() => {
                    setIsEmailActive(true);
                  }}
                  text={`${intl.messages['arrowButton']}`}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        {isEmailActive ? (
          <Container
            style={{
              position: 'fixed',
              top: '40%',
              zIndex: 999,
              backgroundColor: 'none',
            }}
            maxWidth="xl"
          >
            <Grid style={{ justifyContent: 'center' }}>
              <Container maxWidth="sm">
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#0D0D0D',
                    padding: '3rem',
                    paddingTop: '1rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    borderRadius: '10px',
                    border: '0.5px solid #333333',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <SendEmailUniversal
                    description={`${intl.messages['emailDescription']}`}
                    placeholder={`${intl.messages['emailPlaceholder']}`}
                    senderEmail={profile!.email}
                    investorId={profile!.userId}
                    investorName={profile!.fullName}
                    onCancelHandler={() => {
                      setIsEmailActive(false);
                    }}
                    onSubmitSuccessHandler={() => {
                      setIsEmailActive(false);
                    }}
                    title={`${intl.messages['emailTitle']}`}
                  />
                </div>
              </Container>
            </Grid>
          </Container>
        ) : null}
      </Container>
    </>
  );
};

export default FAQPage;
