import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import { BACKEND_API_URL } from './../../env';
const instance = createService(BACKEND_API_URL);

export async function createTicket(request: any): Promise<any> {
  return instance
    .post('/tickets', request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function editTicket(request: any): Promise<any> {
  return instance
    .put(`/tickets/${request.ticketId}`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function notifyToInvestors(data: {
  ticketId: string;
  oldTicketStatus?: string;
}): Promise<any> {
  return instance
    .post('/tickets/email-for-update-ticket', data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}
