enum ActionTypes {
  UPDATE_DEAL = 'app/DealDetail/UPDATE_DEAL',
  SET_DEAL_DESCRIPTION = 'app/DealDetail/SET_DEAL_DESCRIPTION',
  UPDATE_DEAL_STATUS = 'app/DealDetail/UPDATE_DEAL_STATUS',
  UPDATE_DEAL_STATUS_SUCCEED = 'app/DealDetail/UPDATE_DEAL_STATUS_SUCCEED',
  GET_USER_TICKET = 'app/DealDetail/GET_USER_TICKET',
  GET_USER_TICKET_SUCCESS = 'app/DealDetail/GET_USER_TICKET_SUCCESS',
  GET_USER_TICKET_FAILED = 'app/DealDetail/GET_USER_TICKET_FAILED',
  REQUEST_SIGN = 'app/DealDetail/REQUEST_SIGN',
  REQUEST_SIGN_SUCCEED = 'app/DealDetail/REQUEST_SIGN_SUCCEED',
  REQUEST_SIGN_FAILED = 'app/DealDetail/REQUEST_SIGN_FAILED',
  GET_DOCUMENTS_SUCCEED = 'app/DealDetail/GET_DOCUMENTS_SUCCEED',
  GET_DEAL_DOCUMENTS = 'app/DealDetail/GET_DEAL_DOCUMENTS',
}

export default ActionTypes;
