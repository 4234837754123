// tslint:disable: object-literal-key-quotes

import React, { memo, useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import {
  Grid,
  makeStyles,
  AccordionDetails,
  Accordion,
} from '@material-ui/core';
import Header from './Header';
import GroupHeader from './GroupHeader';
import GroupTable from './GroupTable';
import { useIntl } from 'react-intl';

const useStyle = makeStyles({
  root: {
    border: '1px solid var(--color-dark-grey)',
    boxShadow: 'none',
    borderRadius: '3px',
    marginBottom: 16, 
    '&::before': {
      backgroundColor: 'unset',
    },
    minHeight: 64,
  },
});

interface Props {
  title: string;
  dealId: string;
  documentGroups: {
    isDefault: boolean;
    documentGroupId: string;
    title: string;
    type: string;
    documents: {
      title: string;
      files: { name: string; title?:string; url: string; fileId: string }[];
      isDefault: boolean;
    }[];
    subType?: string;
  }[];
  isPublic: boolean;
  onRemoveGroup: (group: any) => void;
  type: 'document' | 'report';
  addNewGroup: () => void;
  addDocument: (groupId: string) => void;
  onRemoveDocument: (document: any) => void;
  onEditDocument: (document: any) => void;
  setDocumentToMove: (doc: any) => void;
  setMoveDocumentDialogOpen: (open: boolean) => void;
}

function DocumentGroups(props: Props) {
  const intl = useIntl();
  const {
    documentGroups,
    type,
    title,
    addNewGroup,
    onRemoveGroup,
    addDocument,
    onRemoveDocument,
    onEditDocument,
    setDocumentToMove,
    setMoveDocumentDialogOpen,
    dealId,
    isPublic,
  } = props;

  const classes = useStyle();

  const [expandedPanels, setExpandedPanels] = useState([]);
  const [documentGroupsState, setDocumentGroups] = useState(documentGroups);

  useEffect(() => {
    setDocumentGroups(documentGroups);
  }, [documentGroups]);

  const handleSearch = (search: string) => {
    if(search) {
      const filteredGroups = documentGroups.map(group => {
        return {
          ...group,
          documents: group.documents.filter(doc => {
            if(doc.title.toLowerCase().includes(search.toLowerCase()))
              return true;

            return doc.files.some(file => {
              if(file.title) {
                return file.title.toLowerCase().includes(search.toLowerCase());
              }
              return file.name.toLowerCase().includes(search.toLowerCase());
            });
          }).map(doc => {
            return {
              ...doc,
              files: doc.title.toLowerCase().includes(search.toLowerCase()) ? doc.files : doc.files.filter(file => {
                if(file.title) {
                  return file.title.toLowerCase().includes(search.toLowerCase());
                }
                return file.name.toLowerCase().includes(search.toLowerCase());
              }),
            };
          }),
        };
      });
      setDocumentGroups(filteredGroups);
      setExpandedPanels(filteredGroups.filter(group => group.documents.length).map(group => group.documentGroupId));
    }
    else {
      setDocumentGroups(documentGroups);
      setExpandedPanels(documentGroups.filter(group => group.documents.length).map(group => group.documentGroupId));
    }
  };

  const onExpansionPanelChange = (event: object, expandedState: boolean, groupId: string) => {
    setExpandedPanels(expandedState ? [...expandedPanels, groupId] : expandedPanels.filter(id => id !== groupId));
  };

  return (
    <Grid container spacing={5}>
      <Header
        title={title}
        buttonTitle={`${intl.messages['exportAll']} ${
          type === 'document'
            ? `${intl.messages['documents']}`
            : `${intl.messages['reports']}`
        }`}
        addNewGroup={addNewGroup}
        onSearchChange={(search: string) => handleSearch(search)}
        dealId={dealId}
        isPublic={isPublic}
        type={type === 'document' ? 'DOCUMENT' : 'REPORT'}
      />
      <Grid item xs={12} sm={12}>
        {documentGroupsState.map((group, index) => (
          <Accordion onChange={(event: object, expanded: boolean) => onExpansionPanelChange(event, expanded, group.documentGroupId)} className={classes.root} key={index} expanded={expandedPanels.includes(group.documentGroupId)}>
            <GroupHeader
              title={group.title}
              addButtonTitle={`${intl.messages['add']} ${type}`}
              removeVisible={!group.isDefault}
              documents={
                group.documents.filter(doc => doc.files.length > 0).length
              }
              onRemove={() => onRemoveGroup(group)}
              addDocument={() => addDocument(group.documentGroupId)}
            />
            <AccordionDetails style={{ padding: '8px 18px' }}>
              <GroupTable
                documents={group.documents || []}
                onRemoveDocument={onRemoveDocument}
                onEditDocument={onEditDocument}
                setDocumentToMove={setDocumentToMove}
                setMoveDocumentDialogOpen={setMoveDocumentDialogOpen}
                dealId={dealId}
                subType={group.subType}
                documentCategoryType={type}
                documentCategoryIsPublic={isPublic}
                documentGroupId={group.documentGroupId}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}

export default memo(DocumentGroups);
