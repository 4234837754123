/*
 *
 * Home
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectHome from './selectors';
import reducer from './reducer';
import saga from './saga';
import AppBar from './components/appbar';
import UserBar from './components/userBar';
import RouteLayout from '../Route';
import InvestorDetail from 'containers/InvestorDetail';
import { Switch, Route } from 'react-router-dom';
import {
  changeTabAction,
  fetchUser,
  fetchUserVehicle,
  fetchPreferences,
} from './actions';
import LocalStorage from 'services/local-storage-service';
import {
  makeIsLoggedIn,
  makeSelectCurrentUser,
  makeSelectUserVehicles,
} from 'containers/App/selectors';
import { logout } from 'containers/App/actions';
import { replace } from 'connected-react-router';
import NoInternet from 'containers/ErrorPages/components/NoInternet';
import FooterNavigation from 'components/Footer';
import Loading from 'components/Loading';

const stateSelector = createStructuredSelector({
  state: makeSelectHome(),
  isLoggedIn: makeIsLoggedIn(),
  currentUser: makeSelectCurrentUser(),
  vehicles: makeSelectUserVehicles(),
});

function Home({ match }) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'home', reducer: reducer });
  useInjectSaga({ key: 'home', saga: saga });
  const { state, isLoggedIn, currentUser, vehicles } = useSelector(
    stateSelector,
  );
  const { selectedTab, isLoading } = state;
  const dispatch = useDispatch();
  const handleTabChanged = value => {
    dispatch(changeTabAction(value));
  };

  useEffect(() => {
    if (!LocalStorage.get(LocalStorage.OAUTH_TOKEN)) {
      let redirect = '';
      if (location.pathname && location.pathname !== '/') {
        redirect = location.pathname.replace('/', '');
      }
      dispatch(
        replace(
          `/login${
            redirect
              ? `?rd=${redirect}${
                  location.search ? `&${location.search.replace('?', '')}` : ''
                }`
              : ''
          }`,
        ),
      );
    } else {
      dispatch(fetchUser());
    }
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.userType === 'INVESTOR' &&
      currentUser.status === 'REGISTERED'
    ) {
      dispatch(replace('/register'));
    } else if (
      currentUser &&
      currentUser.status === 'INACTIVE' &&
      !location.pathname.startsWith('/me')
    ) {
      dispatch(replace('/deactivated'));
    }
    if (currentUser && !vehicles) {
      dispatch(fetchUserVehicle(currentUser.userId));
      dispatch(fetchPreferences());
    }
  }, [currentUser]);

  useEffect(() => {
    const location = window.location;
    if (location.pathname.startsWith('/deals')) {
      dispatch(changeTabAction(2));
    } else if (location.pathname.startsWith('/dashboard')) {
      dispatch(changeTabAction(0));
    } else if (location.pathname.startsWith('/companies')) {
      dispatch(changeTabAction(1));
    } else if (location.pathname.startsWith('/investors')) {
      dispatch(changeTabAction(3));
    } else if (location.pathname.startsWith('/secondary-market')) {
      dispatch(changeTabAction(4));
    }
  }, [window.location]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(navigator.onLine);
    });
    window.addEventListener('offline', () => {
      setIsOnline(navigator.onLine);
    });
  }, []);

  return (
    <>
      {isLoading && <Loading fullScreen />}
      {!isOnline ? (
        <div>
          <NoInternet />
        </div>
      ) : (
        <div>
          {currentUser && currentUser.status !== 'INACTIVE' && (
            <AppBar
              onTabChanged={handleTabChanged}
              currentTab={selectedTab}
              name={currentUser && currentUser.fullName}
              logout={handleLogout}
              isAdmin={currentUser.userType === 'ADMIN'}
            />
          )}
          {currentUser && (
            <UserBar
              name={currentUser && currentUser.fullName}
              status={currentUser.status}
              image={currentUser.picturePath}
              isAdmin={currentUser.userType === 'ADMIN'}
            />
          )}
          {currentUser && currentUser.status !== 'INACTIVE' && <RouteLayout />}
          {/* {currentUser && currentUser.status !== 'INACTIVE' && (
            <FooterNavigation />
          )} */}
          {currentUser && currentUser.status === 'INACTIVE' && (
            <Switch>
              <Route path="/me" component={InvestorDetail} />
            </Switch>
          )}
        </div>
      )}
    </>
  );
}

export default memo(Home);
