/*
 *
 * RegisterIdentity constants
 *
 */

/*
 *
 * RegisterIdentity constants
 *
 */
enum ActionTypes {
  FETCH_ME_ERROR_ACTION = 'app/RegisterIdentity/FETCH_ME_ERROR_ACTION',
  UPDATE_PROFILE_ACTION = 'app/RegisterIdentity/UPDATE_PROFILE_ACTION',
  INIT_PROFILE_ACTION = 'app/RegisterIdentity/INIT_PROFILE_ACTION',
  UPDATE_PROFILE_SUCCESS_ACTION = 'app/RegisterIdentity/UPDATE_PROFILE_SUCCESS_ACTION',
  UPDATE_PROFILE_FAILED_ACTION = 'app/RegisterIdentity/UPDATE_PROFILE_FAILED_ACTION',
  RESET_STATE_ACTION = 'app/RegisterIdentity/RESET_STATE_ACTION',
  UPDATE_FORM_STATE_IDENTITY_ACTION = 'app/RegisterIdentity/UPDATE_FORM_STATE_IDENTITY_ACTION',
  UPDATE_STEP_ACTION = 'app/RegisterIdentity/UPDATE_STEP_ACTION',
  UPLOAD_DOCUMENT_ACTION = 'app/RegisterIdentity/UPLOAD_DOCUMENT_ACTION',
}

export default ActionTypes;
