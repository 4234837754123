import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { resendVerificationemail } from 'services/api/profile-service';
import makeSelectRegister from '../selectors';
const stateSelector = createStructuredSelector({
  register: makeSelectRegister(),
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 120px)',
      color: 'var(--color-grey)',
    },
    wrapper: {
      border: '1px solid var(--color-medium-dark-grey)',
      paddingRight: '10rem',
      paddingLeft: '10rem',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    title: {
      fontSize: '1.5rem',
      textAlign: 'center',
      color: 'white',
    },
    subTitle: {
      fontSize: '1rem',
      textAlign: 'center',
    },
  }),
);
interface Props {
  scope: string;
}

function VerificationRequired(props: Props) {
  const { scope } = props;
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const { register } = useSelector(stateSelector);
  const { profile } = register;
  const [responseMessage, setResponseMeassage] = useState({
    error: false,
    message: undefined,
  });

  const localScope = `${scope}.components.verification`;
  const resendOnClick = async email => {
    setResponseMeassage({
      error: false,
      message: undefined,
    });
    try {
      const response = await resendVerificationemail({ email });
      if (response.success) {
        setResponseMeassage({
          error: false,
          message: 'E-mail sent successfully!',
        });
      } else {
        setResponseMeassage({
          error: true,
          message: 'Error occured during sending the e-mail',
        });
      }
    } catch (e) {
      setResponseMeassage({
        error: true,
        message: 'Error occured during sending the e-mail',
      });
    }
  };
  return (
    <div>
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={12} lg={8} xl={7}>
          <div className={classes.wrapper}>
            <Typography className={classes.title}>
              <FormattedMessage id={`${localScope}.title`} />
            </Typography>

            <Typography className={classes.subTitle}>
              <FormattedMessage id={`${localScope}.accountSuccess`} />
              <b>{profile ? profile.email : ''}</b>
              <br />
              <FormattedMessage id={`${localScope}.pleaseCheck`} />
            </Typography>

            <Typography className={classes.subTitle}>
              <FormattedMessage id={`${localScope}.contactUs`} />
              <a
                onClick={() => {
                  console.log(profile.email);
                  resendOnClick(profile.email);
                }}
              >
                <FormattedMessage id={`${localScope}.resendEmail`} />
              </a>
              <br />
              {responseMessage && responseMessage.message && (
                <span
                  style={
                    responseMessage.error
                      ? { color: 'red' }
                      : { color: '#00ad1a' }
                  }
                >
                  <b style={{ fontWeight: 'bold' }}>
                    {responseMessage.message}
                  </b>
                </span>
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(VerificationRequired);
