import { Button, Card, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface Props {
  text: string;
  onButtonClick: () => void;
  buttonText: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardRoot: {
    width: '100%',
    marginBottom: '1rem',
  },
  text: {
    fontSize: '0.875rem',
  },
  button: {
    fontSize: '0.875rem',
    color: 'var(--color-light-grey)',
    backgroundColor: 'var(--color-dark-purple)',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--color-purple)',
    },
  },
}));
const SmallModal = (props: Props) => {
  const { text, onButtonClick, buttonText } = props;
  const classes = useStyles();
  return (
    <Card className={classes.cardRoot}>
      <p className={classes.text}>{text}</p>
      <Button className={classes.button} onClick={onButtonClick}>
        {buttonText}
      </Button>
    </Card>
  );
};

export default SmallModal;
