import { RestResult } from 'models/response/rest-result';
import { AuthResponse } from 'models/response/auth-response';
import { createService } from './axios';
import { AxiosResponse } from 'axios';
import { EmailRequest } from 'models/request/email-request';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function sendQuestionAndComment(request: {
  template: string;
  contentParams: {
    REPLY_TO_EMAIL?: string;
    RECEIVER?: string;
    DEAL_NAME?: string;
    TOPIC: string;
    DEAL_ID?: string;
    INVESTOR_ID?: string;
    INVESTOR_NAME?: string;
    MESSAGE: string;
    EMAIL_HEADER: string;
  };
}): Promise<AuthResponse> {
  return instance
    .post('/email/single-email', request)
    .then((resp: AxiosResponse<RestResult<AuthResponse>>) => {
      return resp.data.data;
    });
}

export async function sendMultipleEmail(
  request: EmailRequest,
): Promise<AuthResponse> {
  return instance
    .post('/email/multi-email', request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}
