import React, { memo, useState } from 'react';

import {
  Typography,
  Grid,
  Button,
  FormControl,
  FormLabel,
  OutlinedInput,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  onChange: (value: any) => void;
  languageCode: string;
}

function Additional(props: Props) {
  const intl = useIntl();
  const { onChange, languageCode } = props;

  const [additionals, setAdditionals] = useState<any[]>([]);

  const onChangeAdditional = (key, index) => e => {
    const newValue = [...additionals];
    newValue[index][key] = e.target.value;
    setAdditionals(newValue);
    onChange(newValue);
  };

  const addNewForm = () => {
    const newValue = [...additionals, { key: '', value: '' }];
    setAdditionals(newValue);
    onChange(newValue);
  };

  const onRemoveForm = index => () => {
    const newValue = [...additionals];
    newValue.splice(index, 1);
    setAdditionals(newValue);
    onChange(newValue);
  };

  return (
    <>
      <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
        <Typography>
          <FormattedMessage id="additionalList" />
          {languageCode !== 'en' && (
            <>
              {' '}
              <FormattedMessage id={`${languageCode}Abbr`} />
            </>
          )}
        </Typography>
      </Grid>
      {additionals.map((additional, index) => {
        return (
          <>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <FormLabel style={{ marginBottom: '5px' }}>
                  {`${intl.messages['title']}`} ({`${intl.messages['max40Char']}`})
                </FormLabel>
                <OutlinedInput
                  inputProps={{ maxLength: 40 }}
                  value={additional.key}
                  onChange={onChangeAdditional('key', index)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <FormLabel style={{ marginBottom: '5px' }}>
                  {`${intl.messages['summary']}`} ({`${intl.messages['max40Char']}`})
                </FormLabel>
                <OutlinedInput
                  inputProps={{ maxLength: 40 }}
                  value={additional.value}
                  onChange={onChangeAdditional('value', index)}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                display: 'flex',
                paddingTop: 25,
              }}
            >
              <Button
                size={'small'}
                color="primary"
                variant="outlined"
                onClick={onRemoveForm(index)}
              >
                {`${intl.messages['delete']}`.toUpperCase()}
              </Button>
            </Grid>
          </>
        );
      })}

      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={addNewForm}
        >
          {`${intl.messages['add']}`}
        </Button>
      </Grid>
    </>
  );
}

export default memo(Additional);
