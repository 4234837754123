/*
 *
 * Deals reducer
 *
 */

import { ContainerState, ContainerActions } from './types';
import ActionTypes from './constants';
import { produce, Draft } from 'immer';

// The initial state of the App

export const initialState: ContainerState = {
  default: null,
};

// Take this container's state (as a slice of root state), this container's actions and return new state
const dealsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.DEFAULT_ACTION:
        break;
      default:
        break;
    }
  },
  initialState,
);

export default dealsReducer;
