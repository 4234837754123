import { call, put, takeLatest, select } from 'redux-saga/effects';
import { notifyToInvestors, editTicket } from 'services/api/ticket-service';
import ActionTypes from './constants';
import {
  submitSuccessAction,
  validateTicket,
  fetchUserVehicleSuccessAction,
  fetchUserVehicleFailedAction,
  submitFailedAction,
} from './actions';
import makeSelectTicketEdit from './selectors';
import { getVehicles } from 'services/api/vehicle-service';
import { showToast } from 'containers/App/actions';
import { setIsLoading } from 'containers/Home/actions';

export function* ticketEditRequest(action) {
  yield put(validateTicket(action.payload));
  const data = yield select(makeSelectTicketEdit());
  if (data.error.isValid) {
    const request = action.payload;
    try {
      const result = yield call(editTicket, request);
      if (request.sendMail) {
        yield call(notifyToInvestors, {
          ticketId: request.ticketId,
          oldTicketStatus: request.oldStatus,
        });
      }
      yield put(submitSuccessAction(result));
      yield put(
        showToast({
          message: 'ticketSuccessfullyUpdated',
          type: 'success',
        }),
      );
    } catch (err) {
      yield put(submitFailedAction());
      yield put(
        showToast({
          message: 'failedToUpdateTicket',
          type: 'error',
        }),
      );
      // yield put(submit(err));
    } finally {
      yield put(setIsLoading(false));
    }
  }
}

export function* requestFetchVehicle(action) {
  const userId = action.payload;
  try {
    const result = yield call(getVehicles, userId);
    yield put(fetchUserVehicleSuccessAction(result));
  } catch (err) {
    yield put(fetchUserVehicleFailedAction(err));
  }
}

export default function* ticketEditSaga() {
  yield takeLatest(ActionTypes.SUBMIT_ACTION, ticketEditRequest);
  yield takeLatest(ActionTypes.FETCH_USER_VEHICLE_ACTION, requestFetchVehicle);
}
