import { takeLatest, call, put } from 'redux-saga/effects';
import {
  searchDealInvestors,
  submitDealInvestor,
  rejectInviteInvestor,
} from 'services/api/deal-service';
import { showToast } from 'containers/App/actions';
import {
  getInvitesSucceed,
  getInvitedFailed,
  resendInvitesSucceed,
  rejectInviteSucceed,
} from './actions';
import ActionTypes from './constants';

function* getInvites(action) {
  try {
    const response = yield call(searchDealInvestors, action.payload);
    yield put(getInvitesSucceed(response));
  } catch (err) {
    yield put(getInvitedFailed(err));
  }
}

function* resendInvites(action) {
  try {
    yield call(submitDealInvestor, action.payload);
    yield put(
      showToast({
        message: 'resendInvitationSuccessfully',
        type: 'success',
      }),
    );
    yield put(resendInvitesSucceed());
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* rejectInvite(action) {
  try {
    yield call(rejectInviteInvestor, action.payload);
    yield put(rejectInviteSucceed());
    yield put(
      showToast({
        message: 'rejectInvitationSuccessfully',
        type: 'success',
      }),
    );
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* invitesSaga() {
  yield takeLatest(ActionTypes.GET_INVITES, getInvites);
  yield takeLatest(ActionTypes.RESEND_INVITES, resendInvites);
  yield takeLatest(ActionTypes.REJECT_INVITE, rejectInvite);
}
