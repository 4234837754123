import { RestResult } from 'models/response/rest-result';
import axios, { AxiosResponse } from 'axios';
import { createService } from './axios';
import RequestUploadResponse from 'models/response/request-upload-response';
import FileRequest from 'models/request/file-request';
import DownloadRequest from 'models/request/download-request';
import { showToast } from 'containers/App/actions';
import { BACKEND_API_URL } from './../../env';
import { sanitizeFileName } from 'utils/helpers';

const instance = createService(BACKEND_API_URL);

export async function uploadUrls(data: {
  documentGroupType?: string;
  companyId?: string;
  dealId?: string;
  userId?: string;
  subType?: string;
  files: [{ name: string; localId?: number }];
}) {
  const { files, ...rest } = data;
  const fileNames = files.map(file => sanitizeFileName(file.name));
  const dataUpload = {
    ...rest,
    files: fileNames.map((fileName, index) => ({
      name: fileName,
      localId: files[index].localId,
    })),
  };

  return instance
    .post('/files/documents/upload-urls', dataUpload)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function downloadSingleFile(
  fileId: string,
): Promise<FileResponse[]> {
  return instance
    .get('/files/download-urls', { params: { fileIds: fileId } })
    .then((resp: AxiosResponse<RestResult<FileResponse[]>>) => {
      return resp.data.data;
    });
}

export async function downloadFiles(data: DownloadRequest) {
  try {
    const queryParams = new URLSearchParams();

    if(!data.isAttachment) data.isAttachment = false;
    if(!data.isCompany) data.isCompany = false;

    Object.keys(data)
      .filter(param => data[param])
      .forEach(param => {
        queryParams.append(param, data[param]);
    });

    const accessToken = localStorage.getItem('local::ih_access_token');
    if (accessToken) {
      queryParams.append('accessToken', accessToken.replace(/"/g, ''));
    }

    const a = document.createElement('a');
    
    a.href = `${BACKEND_API_URL}/files/download-files?${queryParams}`.replace('//files','/files');
    a.download = 'download';
    a.target = '_blank';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (err) {
    showToast({
      message: 'error.downloadFailed',
      type: 'error',
    });
  }
}

export async function requestUpload(
  request: FileRequest[],
): Promise<RequestUploadResponse[]> {
  return instance
    .post('files/my-documents/upload-urls', request)
    .then((resp: AxiosResponse<RestResult<RequestUploadResponse[]>>) => {
      return resp.data.data;
    });
}

export async function updateDocumentFiles(
  documentId: string,
  fileIds: string[],
): Promise<any> {
  return instance
    .put(`/documents/${documentId}/files`, fileIds)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function uploadFileToS3(urls: string[], files: any[]) {
  const client = axios.create();
  await Promise.all(
    urls.map((url, index) => {
      return new Promise((resolve, reject) => {
        client
          .put(url, files[index], {
            headers: {
              'Content-Type': files[index].type,
              'Content-Disposition': `attachment; filename=${encodeURI(
                files[index].name || 'unknow',
              )}`,
            },
          })
          .then(resp => resolve(resp))
          .catch(err => reject(err));
      });
    }),
  );
}

export async function createGroupDocument(request: any) {
  return instance
    .post(`/documents/groups`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function createDocument(request: any) {
  return instance
    .post(`/documents`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function updateDocumentGroup(
  documentGroupId: string,
  request: any,
) {
  return instance
    .put(`/documents/groups/${documentGroupId}`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function updateDocumentFileTitles(request: any) {
  return instance
    .patch('/files/change-titles', request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}