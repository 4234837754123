/**
 *
 * CustomPaper
 *
 */
import React, { memo } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import styled from 'styles/styled-components';

const TextArea = styled(TextareaAutosize)`
  font-size: 14px;
  border: 1px solid var(--color-medium-dark-grey);
  padding: 8px;
  background-color: transparent;
  color: var(--color-light-grey);
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: var(--color-dark-purple);
  }
`;

export default memo(TextArea);
