/*
 *
 * Portfolio
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Card, Container } from '@material-ui/core';
import { replace } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import { Location } from 'history';

import ErrorToast from 'components/ErrorToast';
import SearchInput from 'components/SearchInput';
import DealTable from './components/dealTable';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { PAGE_SIZE } from 'utils/constants';
import ListPortfolioInvestorQuery from 'gql/deal/ListPortfolioInvestor.gql';
import { useIntl } from 'react-intl';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

const useStyles = makeStyles(() => ({
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
    border: '1px solid #E5E5E5',
    borderRadius: '3px',
  },
  buttonContainer: {
    padding: 16,
  },
  card: {
    border: '1px solid var(--color-medium-dark-grey)',
    borderRadius: '3px',
  },
}));

interface Props {
  location: Location;
}

function Portfolio(props: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSelector(stateSelector);
  const { location } = props;
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);

  const LANGUAGE_CODE = user?.languageCode ? user.languageCode : 'en';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamPage = Number(searchParams.get('page'));

    if (searchParamPage) {
      refreshPageStateBasedOnURL(searchParamPage);
    }
  }, []);

  useEffect(() => {
    refreshURLBasedOnPageState();
  }, [page]);

  const refreshPageStateBasedOnURL = searchParamPage => {
    if (page !== searchParamPage) {
      setPage(searchParamPage);
    }
  };

  const refreshURLBasedOnPageState = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamPage = Number(searchParams.get('page'));

    if (page !== searchParamPage) {
      searchParams.set('page', String(page));
      dispatch(replace(`${location.pathname}?${searchParams.toString()}`));
    }
  };

  const { loading, error, data, refetch } = useQuery(
    ListPortfolioInvestorQuery,
    {
      variables: {
        searchKey: `%${searchKey}%`,
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        userId: user ? user.userId : '',
        language: LANGUAGE_CODE,
      },
    },
  );

  const onChangeSearchKey = value => {
    if (isFirstPageLoad) {
      setIsFirstPageLoad(false);
    } else {
      setPage(1);
    }
    setSearchKey(value);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);
  return (
    <Container className="main">
      <Grid container justify="center" spacing={4}>
        <ErrorToast
          isOpen={Boolean(error)}
          message={error ? `${intl.messages[`${error.message}`]}` : ''}
        />
        <Grid item xs={12} md={12}>
          <SearchInput onChange={onChangeSearchKey} />
        </Grid>
        <Grid item xs={12} md={12}>
          {!error && !loading && (
            <DealTable
              items={data.deal_investor.map(item => {
                return { ...item.deal };
              })}
              total={data.deal_investor_aggregate.aggregate.count}
              fetchPage={setPage}
              page={page}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default memo(Portfolio);
