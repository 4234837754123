/*
 *
 * RegisterVehicles reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import { initialInputState } from '../../components/FormControl/input';
import { initialSelectState } from '../../components/FormControl/select';
import produce, { Draft } from 'immer';
import { initialDocumentUploadState } from '../RegisterIdentity/components/document-upload';

export const initialState: ContainerState = {
  formState: {
    userId: null,
    individualVehicleId: null,
    corporateVehicleId: null,
    individualVehicle: true,
    corporateVehicle: false,
    corporateName: initialInputState,
    corporateType: initialInputState,
    corporateRegistrationNumber: initialInputState,
    address1: initialInputState,
    address2: initialInputState,
    zipCode: initialInputState,
    city: initialInputState,
    country: initialSelectState,
    sameAsInvestor: true,
    corporateDocFiles: initialDocumentUploadState,
  },
  updateSuccess: false,
  documentGroups: [],
};

const registerVehiclesReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.UPDATE_VEHICLE_SUCCESS_ACTION:
        draft.updateSuccess = true;
        break;
      case ActionTypes.RESET_STATE_ACTION:
        draft.updateSuccess = false;
        draft.formState.userId = null;
        break;
      case ActionTypes.UPDATE_FORM_STATE_VEHICLES_ACTION:
        const states = action.payload;
        if (states && states.length > 0) {
          for (const state of states) {
            draft.formState[state.field] = state.state;
          }
        }
        break;
      case ActionTypes.FETCH_VEHICLES_SUCCESS_ACTION:
        draft.documentGroups =
          action.payload && action.payload.documentGroups
            ? action.payload.documentGroups
            : undefined;
        break;
      case ActionTypes.CREATE_DOCUMENT_GROUP_SUCCESS_ACTION:
        draft.documentGroups =
          draft.documentGroups && draft.documentGroups.length > 0
            ? [...draft.documentGroups, ...action.payload]
            : [{ ...action.payload }];
        break;
      case ActionTypes.CREATE_DOCUMENT_GROUP_ACTION:
        draft.documentGroups = [
          { documents: [{ type: 'KYC_CORPORATE', files: [] }] },
        ];
        break;
      default:
        break;
    }
  },
  initialState,
);

export default registerVehiclesReducer;
