/*
 *
 * InviteList
 *
 */

import React, { memo, useEffect } from 'react';
import { Card, Checkbox, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import Table, { HeaderProps } from 'components/Table';
import Status from 'components/Status';
import InviteHeader from './header';
import InviteInvestor from 'models/invite-investor';

const useStyles = makeStyles(() => ({
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 8,
  },
  container: {
    padding: 12,
  },
  image: {
    width: 25,
    height: 25,
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  descriptionText: {
    textAlign: 'left',
    marginTop: 8,
  },
  title: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
    minWidth: 60,
  },
  tagnInterest: {
    width: '40%',
  },
  firstRow: {
    width: '5%',
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: 'var(--color-dark-purple)',
    color: 'var(--color-light-grey)',
    fontWeight: 600,
    borderRadius: 2,
    display: 'inline-block',
  },
  statusActive: {
    color: 'var(--color-cyan)',
  },
  statusRegistered: {
    color: '#000',
  },
  statusInActive: {
    color: '#D74F4F',
  },
  tableCell10: {
    width: '10%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell30: {
    width: '30%',
  },
  tableCell35: {
    width: '35%',
  },
}));

interface Props {
  items: InviteInvestor[];
  total: number;
  fetchPage: (page: number) => void;
  selected: InviteInvestor[];
  onSelect: any;
  page: number;
  scope: string;
}

function InviteList(props: Props) {
  const classes = useStyles();
  const intl = useIntl();
  const { items, total, fetchPage, onSelect, selected, page, scope } = props;
  const [onShowSelected, setOnShowSelected] = React.useState(false);
  const [tableItems, setTableItem] = React.useState(
    onShowSelected ? selected : items,
  );
  const localScope = `${scope}.inviteList`;

  useEffect(() => {
    fetchPage(1);
  }, [total]);

  useEffect(() => {
    setTableItem(onShowSelected ? selected : items);
  }, [items, selected]);

  const handleFetchPage = (currentPage: number) => {
    if (!onShowSelected) {
      fetchPage(currentPage);
      if (fetchPage) {
        fetchPage(currentPage);
      }
    }
  };

  const onItemChanged = item => {
    onSelect(item);
  };

  const headers: HeaderProps[] = [
    {
      name: '',
      headerClass: classes.firstRow,
    },
    {
      name: `${intl.messages['investorName']}`,
      align: 'left',
      headerClass: classes.tableCell30,
    },
    {
      name: `${intl.messages['invitationStatus']}`,
      align: 'left',
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['tagsAndInterests']}`,
      headerClass: classes.tagnInterest,
    },
    { name: `${intl.messages['status']}`, headerClass: classes.tableCell15 },
  ];

  const renderItem = (item: InviteInvestor, index: number) => {
    const isSelected =
      selected.filter(sel => item.userId === sel.userId).length > 0;

    return [
      <div key={index} style={{ textAlign: 'left' }}>
        <Checkbox
          key={index}
          defaultChecked={isSelected ? true : false}
          checked={isSelected ? true : false}
          color="primary"
          onChange={() => onItemChanged(item)}
        />
      </div>,
      <Link
        key={index}
        className={classes.title}
        href={`/investors/${item.userId}`}
        target="_blank"
      >
        {item.name}
      </Link>,
      <div key={index} className={classes.statusActive}>
        {item.countInvitation && item.countInvitation > 0
          ? `${intl.messages['invited']}`
          : ''}
      </div>,
      <div key={index}>
        {item.userPreferenceTags.map(val => {
          return (
            <span className={classes.interestTag} key={val.preferenceTag.id}>
              {val.preferenceTag.name}
            </span>
          );
        })}
      </div>,
      <div key={index}>
        <Status name={item.status} />
      </div>,
    ];
  };

  const onShowOnlySelectedChange = value => {
    setOnShowSelected(value);
    setTableItem(value ? selected : items);
  };
  return (
    <>
      <InviteHeader
        selected={selected.length}
        total={total}
        onShowOnlySelectedChange={onShowOnlySelectedChange}
        scope={localScope}
      />
      <Table
        headers={headers}
        items={tableItems}
        renderItem={renderItem}
        fetchDataForPage={onShowSelected ? undefined : handleFetchPage}
        totalElements={onShowSelected ? selected.length : total}
        currentPage={onShowSelected ? 0 : page}
      />
    </>
  );
}

export default memo(InviteList);
