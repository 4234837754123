import { takeLatest, call, put, select } from 'redux-saga/effects';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import {
  fetchUserSuccessAction,
  changeStatusSuccessAction,
  changeStatusFailedAction,
  fetchStatisticSuccessAction,
} from './actions';
import {
  fetchUser,
  changeStatus,
  fetchInvestorStatistic,
} from 'services/api/profile-service';
import { RestResult } from 'models/response/rest-result';

function* getUserData(action) {
  try {
    const response = yield call(fetchUser, action.payload);
    yield put(fetchUserSuccessAction(response));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export function* changeStatusRequest(action) {
  const request = action.payload;
  try {
    const result: RestResult<any> = yield call(changeStatus, request);
    if (result) {
      yield put(changeStatusSuccessAction(result));
    }
  } catch (err) {
    yield put(changeStatusFailedAction(err));
  }
}

export function* fetchStatisticRequest(action) {
  const request = action.payload;
  try {
    const result: RestResult<any> = yield call(fetchInvestorStatistic, request);
    if (result) {
      yield put(fetchStatisticSuccessAction(result));
    }
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
    // yield put(changeStatusFailedAction(err));
  }
}

export default function* dealDetailSaga() {
  yield takeLatest(ActionTypes.FETCH_USER_ACTION, getUserData);
  yield takeLatest(ActionTypes.CHANGE_STATUS_ACTION, changeStatusRequest);
  yield takeLatest(ActionTypes.FETCH_INVESTOR_STATISTIC, fetchStatisticRequest);
}
