export default {
  paper: {
    paddingBottom: 30,
  },
  header: { padding: '10.5px 0px' },
  detailTitle: {
    verticalAlign: 'middle',
    fontSize: '1.1rem',
    margin: '0px 0px 5px',
  },
};
