import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { getCountries as getCountriesRequest } from 'services/api/county-service';
import { getCountriesSucceed, showToast, downloadFinished, previewDownloadFinished, handleToggleDocViewer, previewDownloadStarted } from './actions';
import ActionTypes from './constants';
import { downloadFiles, downloadSingleFile } from 'services/api/file-service';

function logout() {
  window.location.href = '/login';
}

function* getCountries() {
  try {
    const countries = yield call(getCountriesRequest);
    yield put(getCountriesSucceed(countries));
  } catch (err) {
    yield put(
      showToast({
        message: 'failedToGetCountries',
        type: 'error',
      }),
    );
  }
}

function* requestDownload(action) {
  try {
    yield call(downloadFiles, action.payload);
    yield put(downloadFinished());
  } catch (err) {
    yield put(
      showToast({
        message: 'downloadFailed',
        type: 'error',
      }),
    );
    yield put(downloadFinished());
  }
}

function* requestDownloadSingleFile(action) {
  try {
    const files = yield call(downloadSingleFile, action.payload);

    const a = document.createElement('a');
    if (files && files.length > 0) {
      a.href = files[0].url;
    }
    a.download = 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    yield put(downloadFinished());
  } catch (err) {
    yield put(
      showToast({
        message: 'downloadFailed',
        type: 'error',
      }),
    );
    yield put(downloadFinished());
  }
}

function* requestPreviewSingleFile(action) {
  try {
    yield put(previewDownloadStarted());
    const files = yield call(downloadSingleFile, action.payload);

    yield put(previewDownloadFinished(files));
    yield put(handleToggleDocViewer(true));
  } catch (err) {
    yield put(
      showToast({
        message: 'downloadFailed',
        type: 'error',
      }),
    );
    yield put(previewDownloadFinished([]));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(ActionTypes.LOGOUT, logout);
  yield takeLatest(ActionTypes.GET_COUNTRIES, getCountries);
  yield takeEvery(ActionTypes.REQUEST_DOWNLOAD, requestDownload);
  yield takeEvery(
    ActionTypes.REQUEST_DOWNLOAD_SINGLE_FILE,
    requestDownloadSingleFile,
  );
  yield takeEvery(
    ActionTypes.REQUEST_PREVIEW_SINGLE_FILE,
    requestPreviewSingleFile,
  );
}
