import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import SearchUserRequest from 'models/request/search-invitation-request';
import InviteInvestor from 'models/invite-investor';
import DealRequest, { UpdateDealRequest } from 'models/request/deal-request';
import { BACKEND_API_URL } from './../../env';
const instance = createService(BACKEND_API_URL);

export async function createDeal(data: DealRequest): Promise<any> {
  return instance
    .post('/deals', data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}
export async function submitDealInvestor(
  request: SearchUserRequest,
): Promise<InviteInvestor> {
  return instance
    .post('/deal-investor', request)
    .then((resp: AxiosResponse<RestResult<InviteInvestor>>) => {
      return resp.data.data;
    });
}

export async function searchDealInvestors(
  data: SearchUserRequest,
): Promise<{ data: InviteInvestor; total: number }> {
  return instance
    .post('/deal-investor/search', data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return {
        data: resp.data.data,
        total: parseInt(resp.data.metaData.total),
      };
    });
}

export async function getDealForInvestor(id: string): Promise<any> {
  return instance
    .get(`investors/deals/${id}`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function searchDeal(request: any): Promise<any> {
  return instance
    .post(`/deals/search`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}

export async function updateDeal(
  id: string,
  data: UpdateDealRequest,
): Promise<any> {
  return instance
    .put(`/deals/${id}`, data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function updateDealStatus(
  id: string,
  data: { status: string },
): Promise<any> {
  return instance
    .put(`/deals/${id}/status`, data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function searchDealByInvestor(request: any): Promise<any> {
  return instance
    .post(`/investors/deal-flow/search`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}

export async function searchPortfolio(request: any): Promise<any> {
  return instance
    .post(`/investors/portfolio/search`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}

export async function cancelInvitation(dealId: string): Promise<any> {
  return instance
    .put(`/investors/deals/${dealId}/cancel-invitation`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}

export async function notifyToInvestors(data: {
  dealId: string;
  dealUpdate: string;
  oldDealStatus?: string;
}): Promise<any> {
  return instance
    .post(`/deals/email-for-update-deal`, data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}

export async function rejectInviteInvestor(id: string) {
  return instance
    .delete(`/deal-investor/${id}`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function requestFileAccess(dealId: string): Promise<any> {
  return instance
    .put(`/deals/${dealId}/files/request`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data;
    });
}
