import { takeLatest, call, put } from 'redux-saga/effects';
import { notifyToInvestors } from 'services/api/deal-service';
import { uploadBannerPicture } from 'services/api/upload-service';
import { sendQuestionAndComment } from 'services/api/mail-service';
import { showToast } from 'containers/App/actions';
import { setMessage, setTopic, uploadBannerLogoSucceed } from './actions';
import ActionTypes, { topics } from './constants';
import { setIsLoading } from 'containers/Home/actions';
import { submitEvent } from 'services/api/notification-service';

function* emailToInvestors(action) {
  try {
    yield call(notifyToInvestors, {
      dealId: action.payload.dealId,
      dealUpdate: 'INFO',
    });
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* uploadBannerLogo(action) {
  try {
    const file = yield call(
      uploadBannerPicture,
      action.payload.bannerId,
      action.payload.logo,
    );
    yield put(uploadBannerLogoSucceed(file));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* sendComment(action) {
  yield put(setIsLoading(true));
  try {
    yield call(sendQuestionAndComment, action.payload);
    yield put(
      showToast({
        message: 'yourMessageSentSuccessfully',
        type: 'success',
      }),
    );
    yield call(submitEvent, {userId: action.payload?.contentParams?.INVESTOR_ID, dealId: action.payload?.contentParams?.DEAL_ID, type: 'INVESTOR_NEW_DEAL_COMMENT'});
    yield put(setMessage(''));
    yield put(setTopic(topics[0].value));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function* dealDescriptionSaga() {
  yield takeLatest(ActionTypes.SEND_COMMENT, sendComment);
  yield takeLatest(ActionTypes.EMAIL_TO_INVESTORS, emailToInvestors);
  yield takeLatest(ActionTypes.UPLOAD_BANNER_LOGO, uploadBannerLogo);
}
