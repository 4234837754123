import React, { memo, useState, useEffect } from 'react';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Input,
  IconButton,
  Link,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage, useIntl } from 'react-intl';
const fileMaxLength = 209715200; // 200 MB

interface Props {
  open: boolean;
  onCreate: (data: { name: string; files: any[]; sendMail: boolean, newFileTitles: any[] }) => void;
  onCancel: () => void;
  type: string;
  docNames: string[];
  sendMailVisible: boolean;
}

const style = {
    documentRow: {
      fontWeight: 500,
      marginBottom: 10,
      borderRadius: 4,
      padding: '3px 8px',
      background: '#1d1c1c'
    },
    fileName: {
      color: 'white',
      fontSize: '12px',
      lineHeight: '12px'
    }
  };

function DocumentCreator(props: Props) {
  const intl = useIntl();
  const { open, onCancel, onCreate, type, docNames, sendMailVisible } = props;

  const [nameErr, setNameErr] = useState('');
  const [filesErr, setFilesErr] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [name, setName] = useState('');
  const [sendMail, setSendMail] = useState(true);
  const [newFileTitles, setNewFileTitles] = useState<string[]>([]);

  let hiddenFileInput;

  const cancelCreate = () => {
    onCancel();
    setName('');
    setFiles([]);
  };

  const handleInputFile = ev => {
    for (const file of ev.target.files) {
      if (file.size > fileMaxLength) {
        setFilesErr(`${intl.messages['error.maximumFileSizeIs200MB']}`);
        return;
      }
      setFilesErr('');
      setFiles(prevState => {
        return [...prevState, file];
      });
    }
    ev.target.value = null;
  };

  const handleRemoveFile = index => {
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
  };

  const handleClickCreate = () => {
    setNameErr('');
    setFilesErr('');
    if (!name) {
      setNameErr(`${intl.messages['error.documentNameIsRequired']}`);
      return;
    }
    if (docNames.includes(name)) {
      setNameErr(`${intl.messages['error.documentNameAlreadyExists']}`);
      return;
    }
    if (!files.length) {
      setFilesErr(`${intl.messages['error.filesAreRequired']}`);
      return;
    }
    onCreate({
      name,
      files,
      sendMail: sendMailVisible && sendMail,
      newFileTitles
    });
    setName('');
    setFiles([]);
  };

  const handleFileTitleChange = (event, index): void => {
    const newTitles = [...newFileTitles];
    newTitles[index] = event.target.value;
    setNewFileTitles(newTitles);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography style={{ paddingTop: 4, fontSize: '1.25rem' }}>
              {`${intl.messages['create']}`} {type}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={cancelCreate}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 6 }}>
          {`${intl.messages['name']}`}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <Input
          fullWidth
          inputProps={{ maxLength: 40 }}
          style={{
            border: nameErr ? '1px solid red' : '1px solid #bdbfc3',
            padding: '0 6px',
          }}
          disableUnderline
          onChange={e => setName(e.target.value)}
        />
        {nameErr && (
          <Typography
            style={{ color: 'red', paddingTop: 6, fontSize: '0.8rem' }}
          >
            {nameErr}
          </Typography>
        )}
        <input
          ref={ref => (hiddenFileInput = ref)}
          type="file"
          name="file"
          multiple
          onChange={handleInputFile}
          style={{ display: 'none' }}
        />
        <Typography style={{ marginBottom: 6, marginTop: 10 }}>
          {`${intl.messages['files']}`}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        {files.map((file, index) => (
          <div key={index} style={style.documentRow}>
          <Grid container spacing={5} alignItems='center'>
            <Grid item xs>
              <div style={style.fileName}><FormattedMessage id={`fileName`} /></div>
              <Link
                style={{ color: '#588FDB', fontSize: '12px' }}
                underline={'always'}
              >
                {file.name}
              </Link>
            </Grid>
            <Grid item>
              <OutlinedInput
                  placeholder={`${intl.messages[`fileTitle`]}`}
                  labelWidth={0}
                  type={'text'}
                  onChange={() => handleFileTitleChange(event, index)}
                />
            </Grid>
            <Grid item>
              <span
                style={{ color: 'red', fontSize: '12px' }}
                onClick={() => handleRemoveFile(index)}
              >
                {`${intl.messages['delete']}`}
              </span>
            </Grid>
          </Grid>
        </div>
        ))}
        {filesErr && (
          <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
            {filesErr}
          </Typography>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={() => hiddenFileInput.click()}
        >
          {`${intl.messages['upload']}`.toUpperCase()}
        </Button>
        {sendMailVisible && (
          <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={sendMail}
                  onChange={e => setSendMail(e.currentTarget.checked)}
                />
              }
              label={
                <Typography>{`${intl.messages['sendInformationEmailToInvestors']}`}</Typography>
              }
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ marginTop: 40 }}>
        <Button color="primary" variant="outlined" onClick={cancelCreate}>
          {`${intl.messages['cancel']}`.toUpperCase()}
        </Button>
        <Button color="primary" variant="contained" onClick={handleClickCreate}>
          {`${intl.messages['create']}`.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(DocumentCreator);
