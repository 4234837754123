import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from '../../types';

/**
 * Direct selector to the registerSignUp state domain
 */

const selectRegisterSignUpDomain = (state: ApplicationRootState) => {
  return state.registerSignUp || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by RegisterSignUp
 */

const makeSelectRegisterSignUp = () =>
  createSelector(selectRegisterSignUpDomain, substate => {
    return substate;
  });

export default makeSelectRegisterSignUp;
export { selectRegisterSignUpDomain };
