/*
 *
 * Commits reducer
 *
 */
import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  total: 0,
  commits: [],
};

const commitsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SEARCH_COMMITS_SUCCEED:
        draft.commits = action.payload.data;
        draft.total = action.payload.total;
        break;
    }
  },
  initialState,
);

export default commitsReducer;
