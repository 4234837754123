/*
 *
 * InvestorDetail
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectInvestorDetail from './selectors';
import reducer from './reducer';
import saga from './saga';
import AdminLayout from './components/AdminLayout';
import InvestorLayout from './components/InvestorLayout';

import { makeSelectCurrentUser } from 'containers/App/selectors';
import {
  fetchUserAction,
  changeStatusAction,
  fetchStatisticAction,
} from './actions';
import { Container } from '@material-ui/core';
import TopBar from './components/TopBar';

const stateSelector = createStructuredSelector({
  investorDetail: makeSelectInvestorDetail(),
  currentUser: makeSelectCurrentUser(),
});

interface Props {
  investorId: string;
}

function InvestorDetail({ match }, props: Props) {
  useInjectReducer({ key: 'investorDetail', reducer: reducer });
  useInjectSaga({ key: 'investorDetail', saga: saga });
  const { investorDetail, currentUser } = useSelector(stateSelector);
  const { investor, changeStatusSuccess, statistic } = investorDetail;
  const dispatch = useDispatch();

  const isAdmin = currentUser && currentUser.userType === 'ADMIN';

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchUserAction(match.params.id));
      dispatch(fetchStatisticAction(match.params.id));
    } else {
      if (currentUser) {
        dispatch(fetchUserAction(currentUser.userId));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (changeStatusSuccess) {
      dispatch(fetchUserAction(match.params.id));
    }
  }, [changeStatusSuccess]);

  const statusChange = status => {
    dispatch(changeStatusAction({ userId: match.params.id, status: status }));
  };

  const scope = 'InvestorDetail';

  return (
    <Container className="main">
      {isAdmin && investor && (
        <TopBar
          onStatusChanged={statusChange}
          investor={investor}
          scope={scope}
        />
      )}
      {isAdmin && (
        <AdminLayout statistic={statistic} investor={investor} scope={scope} />
      )}
      {!isAdmin && <InvestorLayout investor={investor} scope={scope} />}
    </Container>
  );
}

export default InvestorDetail;
