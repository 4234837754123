import React, { memo } from 'react';
import styles from '../styles';
import { useIntl } from 'react-intl';

interface Props {}

function NotFound(props: Props) {
  const intl = useIntl();
  return (
    <div style={{ ...styles.notFound, flexDirection: 'column' }}>
      <p style={{ fontSize: 30 }}>{`${intl.messages['error.dealNotFound']}`}</p>
    </div>
  );
}

export default memo(NotFound);
