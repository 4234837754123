import React, { memo, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, Input } from '@material-ui/core';
import Progress from './Progress';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Banner.css';
import Slider from 'react-slick';
import ErrorToast from 'components/ErrorToast';
import BannerEditor from './BannerEditor';
import { DealNumberData, DealNumberDescription } from '../styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Deal from 'models/deal';
import { Banner as BannerType } from 'models/banner';
import BannerHighlight from 'models/bannerHighlight';
import GetBannerByDealIdQuery from 'gql/banner/GetBannerByDealId.gql';
import A from 'components/A';
import { ReactComponent as PlaySvg } from 'images/icons/with-class/play.svg';
import { ReactComponent as PauseSvg } from 'images/icons/with-class/play.svg';
import { ReactComponent as FileSvg } from 'images/icons/with-class/file.svg';
import { ReactComponent as UnlockSvg } from 'images/icons/with-class/unlock.svg';
import filePdf from 'images/file-pdf.png';
import NumberFormat from 'react-number-format';
import DownloadRequest from 'models/request/download-request';
import {
  requestDownload,
  requestDownloadSingleFile,
} from 'containers/App/actions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-scroll';

const useStyle = makeStyles((theme: Theme) => ({
  displayMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  displayDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  hero: {
    position: 'relative',
    color: 'var(--color-light-grey)',
    marginBottom: '15px',
    '&.playing': {
      '& $heroContent': {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
  heroBgMediaWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heroContent: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#0D0D0D80',
    opacity: 1,
    visibility: 'visible',
    transition: '.3s ease all',
    padding: '30px 0',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '70px 0',
      minHeight: '600px',
    },
  },
  picture: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      height: 'auto',
    },
  },
  video: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      height: 'auto',
    },
  },
  playVideo: {
    fontSize: 14,
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  playVideoIcon: {
    paddingRight: '10px',
  },
  pauseVideo: {
    position: 'absolute',
    left: '50%',
    bottom: '50px',
    transform: 'translateX(-50%)',
  },
  sliderWrapper: {},
  commitInputWrapper: {
    fontSize: '1.625rem',
  },
  commitButton: {
    backgroundColor: 'var(--color-dark-purple)',
    boxShadow: '0px 0px 10px rgba(51, 204, 178, 0.3)',
    paddingLeft: '25px',
    paddingRight: '25px',
    color: 'var(--color-light-grey)',
    minHeight: 36,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
  },
  adminEdit: {
    position: 'absolute',
    zIndex: 2,
    right: '10px',
    top: '10px',
  },
  doc: {
    fontSize: '0.875rem',
  },
  docIcon: {
    marginRight: 5,
  },
}));

const CommitInput = withStyles(() => ({
  root: {
    maxWidth: '180px',
  },
  input: {
    border: 0,
    borderBottom: '1px solid var(--color-light-grey)',
    fontSize: '1.625rem',
    color: 'var(--color-light-grey)',
    textAlign: 'center',
    borderRadius: 0,
  },
}))(Input);

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dotsClass: 'slick-dots custom-dots',
  autoplaySpeed: 5000,
  arrows: false,
};

interface Props {
  deal: Deal;
  statistics?: any;
  admin?: boolean;
  banner?: BannerType;
  onUpdate: (message) => void;
  onOpen: () => void;
  onBannerCommitClicked?: any;
  files?: any;
  onFounderClick: () => void;
  onManagementClick: () => void;
}

const Banner = (props: Props) => {
  const intl = useIntl();
  const {
    deal,
    admin,
    banner,
    onUpdate,
    onOpen,
    statistics,
    onBannerCommitClicked,
  } = props;
  const [edit, setEdit] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [updateError, setUpdateError] = useState<string | undefined>(undefined);
  const classes = useStyle();
  const bannerTranslation =
    banner?.banner_translations && banner.banner_translations[0];

  const { error, data, refetch } = useQuery(GetBannerByDealIdQuery, {
    variables: {
      dealId: deal.dealId,
    },
  });

  const videoRef = useRef(null);
  const videoMobileRef = useRef(null);

  let investAmount = 0;

  const onCancel = () => {
    setEdit(false);
  };

  const onSave = message => {
    setUpdateError(undefined);
    onUpdate(message);
  };

  const playClickHandler = () => {
    if (!playing) {
      videoRef.current.play();
    } else videoRef.current.pause();

    setPlaying(!playing);
  };

  const isEdit = () =>
    deal.status === 'DRAFT' ||
    deal.status === 'PREVIEW' ||
    deal.status === 'OPEN';

  const getHighLights = () => {
    if (!bannerTranslation?.banner_highlights) {
      return [];
    }
    const results: BannerHighlight[][] = [];
    const highlights = bannerTranslation?.banner_highlights;

    for (let i = 0; i < highlights.length; i++) {
      if (i % 3 === 2) {
        const page = [highlights[i - 2], highlights[i - 1], highlights[i]];
        results.push(page);
      }
      if (i === highlights.length - 1 && i % 3 < 2) {
        if (i > 1) {
          const page = [highlights[i - 2], highlights[i - 1], highlights[i]];
          results.push(page);
        } else {
          results.push(highlights);
        }
      }
    }
    return results;
  };
  const dispatch = useDispatch();
  const downloadFile = (fileIds, docGroupId, docType) => {
    if(fileIds.length > 1) {
      dispatch(
        requestDownload({
          dealId: deal.dealId,
          documentId: docGroupId,
          isAttachment: docType === "DEAL_DESCRIPTION_ATTACHMENT",
          isCompany: false
        }),
      );
    } else
      dispatch(requestDownloadSingleFile(fileIds[0]));
  };

  const getFiles = () => {
    let files = [];
    if (data && data.deal && data.deal.length > 0) {
      const documentGroups = data.deal[0].document_groups.map(docGroup => {
        return docGroup.documentGroup;
      });
      files = documentGroups.map(item => {
        return item.documents.map((document: any) => {
          return {
            docGroupId: document.document.id,
            docType: document.document.type,
            name: document.document.title,
            ids: document.document.files && document.document.files.length ? document.document.files.map(e => e.file?.id) : [],
          };
        });
      });
    }
    if (files.length === 0) {
      return files;
    } else {
      return files[0];
    }
    // const filesArrays = props.files.map(data => {
    //   return data.files.map(file => {
    //     return file;
    //   });
    // });
    // let files = [];
    // filesArrays.forEach(element => {
    //   files = [...files, ...element];
    // });
    // return files;
  };
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setFiles(getFiles());
  }, [data]);
  const isError = () => {
    return Boolean(error) || Boolean(updateError);
  };

  const getError = () => {
    let result = '';
    if (error) {
      result = error.message;
    } else if (updateError) {
      result = updateError;
    }

    return result;
  };


  const commitInputChangeHandler = event => {
    const val = event.target?.value.replace(',', '');
    investAmount = Number(val);
  };

  const commitButtonClickHandler = () => {
    if (isNaN(investAmount)) return;
    onBannerCommitClicked(Number(investAmount));
  };

  useEffect(() => {
    refetch();
  }, []);

  const hasMedia = banner && banner.url;
  let mediaType: string | null = null;

  if (hasMedia) {
    const splittedBanner = banner?.url?.split(/[#?]/)[0];
    mediaType = splittedBanner
      ?.split('.')
      ?.pop()
      ?.trim().toLowerCase();
  }

  const isVideo =
    mediaType &&
    mediaType !== 'jpeg' &&
    mediaType !== 'jpg' &&
    mediaType !== 'png' &&
    mediaType !== 'svg+xml';

  const media = (mobile = false) => {
    if (hasMedia) {
      if (isVideo) {
        return (
          <>
            <video
              ref={mobile ? videoMobileRef : videoRef}
              muted={false}
              loop={true}
              controls={mobile ? true : false}
              className={classes.video}
            >
              <source src={banner.url} type={`video/${mediaType}`} />
              Your browser does not support HTML5 video.
            </video>
            {playing && (
              <Button
                className={`${classes.playVideo} ${classes.pauseVideo}`}
                onClick={playClickHandler}
              >
                <span className={classes.playVideoIcon}>
                  <PauseSvg />
                </span>
                <span>
                  <FormattedMessage id="pauseVideo" />
                </span>
              </Button>
            )}
          </>
        );
      } else {
        return (
          <img
            className={classes.picture}
            src={banner.url}
            alt={bannerTranslation?.title || deal.deal_translations[0].name}
          />
        );
        /*return (
          <div className={classes.sliderWrapper}>
            {bannerTranslation?.banner_highlights && (
              <Slider {...settings}>
                {getHighLights().map((page, i) => {
                  return (
                    <div key={i}>
                      <Grid container>
                        {page.map((highlight, index) => (
                          <Grid item key={index}>
                            <DealNumberData>{highlight.title}</DealNumberData>
                            <DealNumberDescription>
                              {highlight.summary}
                            </DealNumberDescription>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        );*/
      }
    } else {
      return <div />;
    }
  };
  return (
    <>
      <ErrorToast isOpen={isError()} message={getError()} />
      <section className={`${classes.hero} ${playing ? 'playing' : ''}`}>
        {admin && isEdit() && (
          <Button
            onClick={() => {
              setEdit(true);
              onOpen();
            }}
            className={classes.adminEdit}
          >
            {`${intl.messages['edit']}`}
          </Button>
        )}
        {hasMedia && (
          <div className={classes.heroBgMediaWrapper}>{media()}</div>
        )}
        <div className={classes.heroContent}>
          <Container>
            <div style={{paddingBottom: 15}}>
            {bannerTranslation?.banner_highlights && (
              <>
                  {getHighLights().map((page, i) => {
                    return (
                      <div key={i}>
                        <Grid container justify="center">
                          {page.map((highlight, index) => (
                            <Grid item xs={12} md={6} lg={4} key={index}>
                              <DealNumberData>{highlight.title}</DealNumberData>
                              <DealNumberDescription>
                                {highlight.summary}
                              </DealNumberDescription>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <h1>
              {bannerTranslation?.title || deal.deal_translations[0].name}
            </h1>
            <p>
              {bannerTranslation?.summary || deal.deal_translations[0].summary}
            </p>

            {hasMedia && (
              <div className={classes.displayMobile}>{media(true)}</div>
            )}

            <Progress deal={deal} statistics={statistics} />

            <Grid container spacing={4} alignItems="center" justify="center">
              <Grid item xs={12} md={'auto'} style={{ textAlign: 'center' }}>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  wrap="nowrap"
                  className={classes.commitInputWrapper}
                >
                  <Grid item>$</Grid>
                  <Grid item>
                    <NumberFormat
                      thousandSeparator={true}
                      onChange={commitInputChangeHandler}
                      customInput={CommitInput}
                      disableUnderline
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <Button
                  className={classes.commitButton}
                  onClick={commitButtonClickHandler}
                >
                  <FormattedMessage id="commitNow" />
                </Button>
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <Link
                  style={{ color: 'var(--color-purple)', fontWeight: 'bold' }}
                  to="description"
                  smooth={true}
                  duration={600}
                >
                  <FormattedMessage id="whyToInvest" />
                </Link>
              </Grid>
              {isVideo && (
                <Grid
                  className={classes.displayDesktop}
                  item
                  xs={12}
                  md={'auto'}
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    className={classes.playVideo}
                    onClick={playClickHandler}
                  >
                    <span className={classes.playVideoIcon}>
                      <PlaySvg />
                    </span>
                    <span>
                      <FormattedMessage id="watchVideo" />
                    </span>
                  </Button>
                </Grid>
              )}
            </Grid>

            <Container maxWidth={false}>
              <Grid
                container
                justify="center"
                spacing={5}
                style={{ paddingTop: '50px' }}
              >
                {files.length > 0 && (
                  <Grid item className={classes.doc}>
                    <FileSvg className={classes.docIcon} />
                    <FormattedMessage id="keyDocuments" />:
                  </Grid>
                )}
                {files &&
                  files.map((file, index) => {
                    return index < 3 && file.ids && file.name ? (
                      <Grid key={index} item className={classes.doc}>
                        <A
                          textDecoration="none"
                          target="_blank"
                          color="var(--color-light-grey)"
                          fontWeight="normal"
                          onClick={() => {
                            downloadFile(file.ids, file.docGroupId, file.docType);
                          }}
                        >
                          <img src={filePdf} className={classes.docIcon} />
                          {`${file.name}`}
                        </A>
                      </Grid>
                    ) : null;
                  })}
                {/* <Grid item className={classes.doc}>
                  <A
                    textDecoration="none"
                    href="#"
                    color="var(--color-light-grey)"
                    fontWeight="normal"
                  >
                    <img src={filePdf} className={classes.docIcon} />
                    Sample Document 1
                  </A>
                </Grid>
                <Grid item className={classes.doc}>
                  <A
                    textDecoration="none"
                    href="#"
                    color="var(--color-light-grey)"
                    fontWeight="normal"
                  >
                    <img src={filePdf} className={classes.docIcon} />
                    Sample Document 2
                  </A>
                </Grid> */}
              </Grid>
              <Grid container justify="center" spacing={5}>
                <Grid item className={classes.doc}>
                  <A
                    onClick={props.onManagementClick}
                    color="var(--color-light-grey)"
                    fontWeight="normal"
                    style={{
                      backgroundColor: 'var(--color-cyan)',
                      padding: '7px 15px',
                      borderRadius: '3px',
                      textDecoration: 'none',
                      fontSize: '1.2em'
                    }}
                  >
                    <UnlockSvg className={classes.docIcon} />
                    <FormattedMessage id="requestAccess" />
                  </A>
                </Grid>
                {/* <Grid item className={classes.doc}>
                  <A
                    onClick={props.onManagementClick}
                    color="var(--color-light-grey)"
                    fontWeight="normal"
                    style={{ backgroundColor: 'var(--color-dark-purple)', padding: '5px', borderRadius: '3px' }}
                  >
                    Immersion’s Questionnaire with the Founder
                  </A>
                </Grid>
                <Grid item className={classes.doc}>
                  <A
                    onClick={props.onFounderClick}
                    color="var(--color-light-grey)"
                    fontWeight="normal"
                    style={{ backgroundColor: 'var(--color-dark-purple)', padding: '5px', borderRadius: '3px' }}
                  >
                    Immersion’s Video Q&A with the Founder
                  </A>
                </Grid> */}
              </Grid>
            </Container>
          </Container>
        </div>
      </section>
      {admin && (
        <BannerEditor
          onCancel={onCancel}
          onSave={onSave}
          onError={setUpdateError}
          dealId={deal.dealId}
          open={edit}
          setOpen={setEdit}
          status={deal.status}
          banner={data?.banner && data?.banner[0]}
        />
      )}
    </>
  );
};

export default memo(Banner);
