/*
 *
 * DocumentSettings actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const fetchTemplateAction = payload =>
  action(ActionTypes.GET_TEMPLATE_ACTION, payload);
export const fetchTemplateSuccessAction = payload =>
  action(ActionTypes.GET_TEMPLATE_SUCCESS_ACTION, payload);
export const fetchTemplateFailedAction = payload =>
  action(ActionTypes.GET_TEMPLATE_FAILED_ACTION, payload);

export const updateTemplateStatusAction = payload =>
  action(ActionTypes.UPDATE_TEMPLATE_STATUS_ACTION, payload);
export const updateTemplateStatusSuccessAction = payload =>
  action(ActionTypes.UPDATE_TEMPLATE_STATUS_SUCCESS_ACTION, payload);
export const updateTemplateStatusFailedAction = payload =>
  action(ActionTypes.UPDATE_TEMPLATE_FAILED_STATUS_ACTION, payload);

export const submitSuccessAction = payload =>
  action(ActionTypes.SUBMIT_SUCCESS_ACTION, payload);

export const getPreview = esignTemplateId =>
  action(ActionTypes.GET_PREVIEW, esignTemplateId);

export const getPreviewSucceed = () => action(ActionTypes.GET_PREVIEW_SUCCEED);
