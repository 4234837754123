/*
 *
 * ProfileVehicleCreateDialog constants
 *
 */

enum ActionTypes {
  CLEAR_INFO_ACTION = 'app/ProfileVehicleCreateDialog/CLEAR_INFO_ACTION',
  UPDATE_ACTION = 'app/ProfileVehicleCreateDialog/UPDATE_ACTION',
  INIT_VEHICLE_ACTION = 'app/ProfileVehicleCreateDialog/INIT_VEHICLE_ACTION',
  CREATE_ACTION = 'app/ProfileVehicleCreateDialog/CREATE_ACTION',
  CREATE_FAILED_ACTION = 'app/ProfileVehicleCreateDialog/CREATE_FAILED_ACTION',
  SUBMIT_UPDATE_ACTION = 'app/ProfileVehicleCreateDialog/SUBMIT_UPDATE_ACTION',
  SUBMIT_UPDATE_FAILED_ACTION = 'app/ProfileVehicleCreateDialog/SUBMIT_UPDATE_FAILED_ACTION',
  UPDATE_DOCUMENT_ACTION = 'app/ProfileVehicles/UPDATE_DOCUMENT_ACTION',
}

export default ActionTypes;
