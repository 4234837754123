import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { push, replace } from 'connected-react-router';

import {
  Typography,
  Container,
  Card,
  FormControl,
  CardContent,
  FormHelperText,
  Grid,
  OutlinedInput,
} from '@material-ui/core';
import Succeed from './components/Succeed';

import whiteIcon from '../../images/logos/Jadesquare-logo-white.svg';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { updatePasword } from './actions';
import makeSelectResetPassword from './selectors';
import reducer from './reducer';
import saga from './saga';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Img from 'components/Img';
import ArrowButton from 'components/ArrowButton';
import A from 'components/A';

const stateSelector = createStructuredSelector({
  resetPassword: makeSelectResetPassword(),
});

interface Props {
  location: {
    search: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    minHeight: '100vh',
  },
  content: {
    backgroundColor: 'transparent',
    padding: '15px',
    color: 'var(--color-grey)',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 60px',
    },
  },
}));

function ResetPassword(props: Props) {
  const intl = useIntl();
  useInjectReducer({ key: 'resetPassword', reducer: reducer });
  useInjectSaga({ key: 'resetPassword', saga: saga });

  const { location } = props;

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassErr, setNewPassErr] = useState('');
  const [confirmPassErr, setConfirmPassErr] = useState('');

  const { resetPassword } = useSelector(stateSelector);
  const { succeed } = resetPassword;
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('token')) {
      dispatch(replace('/login'));
    }
  }, []);

  const validate = () => {
    let result = true;
    if (!newPassword) {
      result = false;
      setNewPassErr(`${intl.messages['error.newPasswordRequired']}`);
    } else {
      if (newPassword.length < 8) {
        result = false;
        setNewPassErr(`${intl.messages['error.passwordTooShort']}`);
      } else if (newPassword.length > 20) {
        result = false;
        setNewPassErr(`${intl.messages['error.passwordTooLong']}`);
      } else {
        setNewPassErr('');
      }
    }
    if (!confirmPassword) {
      result = false;
      setConfirmPassErr(`${intl.messages['error.confirmPasswordRequired']}`);
    } else {
      if (confirmPassword !== newPassword) {
        setConfirmPassErr(`${intl.messages['error.confirmPasswordMatch']}`);
        result = false;
      } else {
        setConfirmPassErr('');
      }
    }
    return result;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        updatePasword({
          newPassword,
          confirmationPassword: confirmPassword,
          encryptedString:
            new URLSearchParams(location.search).get('token') || '',
        }),
      );
    }
  };

  return (
    <Container className="main">
      <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
        <RouterLink to="/">
          <Img src={whiteIcon} style={{ height: 30 }} />
        </RouterLink>
      </div>
      <Grid container justify={'center'}>
        <Grid item xs={12} md={8} xl={6}>
          <Card variant="outlined" className={classes.content}>
            <CardContent>
              {succeed ? (
                <Succeed />
              ) : (
                <form onSubmit={handleFormSubmit}>
                  <Typography
                    variant={'h3'}
                    style={{
                      color: 'white',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}
                  >
                    <FormattedMessage id="resetYourPassword" />
                  </Typography>

                  <FormControl style={{ width: '100%', marginBottom: '15px' }}>
                    <OutlinedInput
                      placeholder={`${intl.messages[`newPassword`]}`}
                      labelWidth={0}
                      fullWidth
                      type={'password'}
                      onChange={e => setNewPassword(e.target.value)}
                    />
                    {newPassErr && (
                      <FormHelperText
                        style={{
                          maxWidth: 330,
                          color: 'red',
                          fontSize: '0.75rem',
                        }}
                      >
                        {newPassErr}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl style={{ width: '100%', marginBottom: '15px' }}>
                    <OutlinedInput
                      placeholder={`${intl.messages[`confirmNewPassword`]}`}
                      labelWidth={0}
                      fullWidth
                      type={'password'}
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                    {confirmPassErr && (
                      <FormHelperText
                        style={{
                          maxWidth: 330,
                          color: 'red',
                          fontSize: '0.75rem',
                        }}
                      >
                        {confirmPassErr}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <br />

                  <Grid
                    container
                    spacing={4}
                    alignItems={'center'}
                    style={{ paddingTop: '100px' }}
                  >
                    <Grid item xs={12} lg={true}>
                      <A
                        style={{ color: 'var(--color-grey)' }}
                        onClick={() => {
                          dispatch(push('/login'));
                        }}
                      >
                        <FormattedMessage id={`Authentication.backToLogin`} />
                      </A>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={'auto'}
                      style={{ textAlign: 'right' }}
                    >
                      <ArrowButton
                        text={`${intl.messages[`resetPassword`]}`}
                        type={'button'}
                        buttonType={'submit'}
                      ></ArrowButton>
                    </Grid>
                  </Grid>
                </form>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default memo(ResetPassword);
