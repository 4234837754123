import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the commits state domain
 */

const selectCommitsDomain = (state: ApplicationRootState) => {
  return state.commits || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Commits
 */

const makeSelectCommits = () =>
  createSelector(selectCommitsDomain, substate => {
    return substate;
  });

export default makeSelectCommits;
export { selectCommitsDomain };
