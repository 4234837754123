import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectRegisterVehicles from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import AntSwitch from './components/switch';
import FormSelect, { SelectOption } from '../../components/FormControl/select';
import FormInput from '../../components/FormControl/input';
import {
  fetchVehiclesAction,
  resetStateAction,
  updateFormStateVehiclesAction,
  updateVehicleAction,
  updateStepAction,
  createDocumentGroupAction,
  uploadDocument,
} from './actions';
import { get, OAUTH_TOKEN } from '../../services/local-storage-service';
import { replace } from 'connected-react-router';
import { allCountries } from 'utils/constants';
import UploadDocuments from 'containers/UploadDocuments';
import Document from 'models/document';
import makeSelectRegister from 'containers/Register/selectors';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrowButton from 'components/ArrowButton';
import A from 'components/A';
import { setLoadingAction } from 'containers/Register/actions';

const stateSelector = createStructuredSelector({
  registerVehicles: makeSelectRegisterVehicles(),
  register: makeSelectRegister(),
});

const useStyles = makeStyles(theme => ({}));

const countryOptions: SelectOption[] = allCountries;

interface Props {
  nextStep: (ev) => void;
  skipStep: (ev) => void;
  backStep: (ev) => void;
}

const RegisterVehicles = forwardRef((props: Props, ref) => {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'registerVehicles', reducer: reducer });
  useInjectSaga({ key: 'registerVehicles', saga: saga });
  const intl = useIntl();
  const styles = useStyles(props);
  const { registerVehicles, register } = useSelector(stateSelector);
  const { formState, updateSuccess, documentGroups } = registerVehicles;
  const { profile } = register;
  const dispatch = useDispatch();
  const { nextStep, skipStep } = props;
  const [vehicleDoc, setVehicleDoc] = useState<Document | undefined>();
  const [vehicleFiles, setVehicleFiles] = useState<string[]>([]);
  useEffect(() => {
    if (!formState.userId) {
      const token = get(OAUTH_TOKEN);
      if (!token) {
        dispatch(replace('/login'));
      }
    }
    if (updateSuccess) {
      nextStep(null);
      dispatch(resetStateAction());
    }
  }, [formState, updateSuccess]);

  useEffect(() => {
    if (profile) {
      dispatch(fetchVehiclesAction(profile.userId));
    }
  }, [profile]);

  useEffect(() => {
    if (documentGroups && documentGroups.length > 0) {
      const groupIndex = documentGroups.findIndex(group => {
        return (
          group.documents &&
          group.documents.findIndex(doc => {
            return doc.type === 'KYC_CORPORATE';
          }) >= 0
        );
      });
      if (groupIndex >= 0 && documentGroups[groupIndex].documents) {
        const docIndex = documentGroups[groupIndex].documents.findIndex(
          item => {
            return item.type === 'KYC_CORPORATE';
          },
        );
        setVehicleDoc(documentGroups[groupIndex].documents[docIndex]);
      }
    } else if (!documentGroups) {
      dispatch(createDocumentGroupAction());
    }
  }, [documentGroups]);

  const updateFormState = field => stateValue => {
    const formField = formState[field];
    if (formField) {
      dispatch(
        updateFormStateVehiclesAction([
          {
            field: field,
            state: { ...formField, value: stateValue },
          },
        ]),
      );
    }
  };

  const updateFormStateSwitch = field => ev => {
    if (field === 'individualVehicle' && !ev.target.checked) {
      dispatch(
        updateFormStateVehiclesAction([
          {
            field: field,
            state: false,
          },
        ]),
      );
      dispatch(
        updateFormStateVehiclesAction([
          {
            field: 'corporateVehicle',
            state: true,
          },
        ]),
      );
    } else if (field === 'corporateVehicle' && !ev.target.checked) {
      dispatch(
        updateFormStateVehiclesAction([
          {
            field: field,
            state: ev.target.checked,
          },
        ]),
      );
      dispatch(
        updateFormStateVehiclesAction([
          {
            field: 'individualVehicle',
            state: true,
          },
        ]),
      );
    } else {
      dispatch(
        updateFormStateVehiclesAction([
          {
            field: field,
            state: ev.target.checked,
          },
        ]),
      );
    }
  };
  const onClickBack= (e) =>{
    props.backStep(e);
  };
  const onClickNext = () => {
    dispatch(setLoadingAction(true));
    dispatch(updateVehicleAction(formState));
    if (vehicleFiles && vehicleDoc && vehicleDoc.documentId) {
      dispatch(
        uploadDocument({
          documentId: vehicleDoc.documentId,
          fileIds: vehicleFiles,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(setLoadingAction(false));
  }, [formState]);
  const scope = 'RegisterVehicles';

  return (
    <div>
      {formState.userId && (
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={4} alignItems={'center'} wrap={'nowrap'}>
                <Grid item xs={'auto'}>
                  <AntSwitch
                    checked={!!formState['individualVehicle']}
                    onChange={updateFormStateSwitch('individualVehicle')}
                    id="individualVehicle"
                  />
                </Grid>
                <Grid item xs={true}>
                  <label htmlFor="individualVehicle">
                    <FormattedMessage id={`${scope}.individualVehicle`} />
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={4} alignItems={'center'} wrap={'nowrap'}>
                <Grid item xs={'auto'}>
                  <AntSwitch
                    checked={formState['corporateVehicle']}
                    onChange={updateFormStateSwitch('corporateVehicle')}
                    id="corporateVehicle"
                  />
                </Grid>
                <Grid item xs={true}>
                  <label htmlFor="corporateVehicle">
                    <FormattedMessage id={`${scope}.corporateVehicle`} />
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {formState['corporateVehicle'] && (
            <div>
              <Typography
                variant={'h4'}
                style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
              >
                <FormattedMessage id={`companyIdentity`} />
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormInput
                    onChange={updateFormState('corporateName')}
                    formState={formState.corporateName}
                    title={`${intl.messages[`model.corporateName`]} *`}
                    placeholder={`${intl.messages[`model.corporateName`]} *`}
                    hideTitle={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    onChange={updateFormState('corporateType')}
                    formState={formState.corporateType}
                    title={`${intl.messages[`model.corporateType`]} *`}
                    placeholder={`${intl.messages[`model.corporateType`]} *`}
                    hideTitle={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    onChange={updateFormState('corporateRegistrationNumber')}
                    formState={formState.corporateRegistrationNumber}
                    title={`${
                      intl.messages[`model.corporateRegistrationNumber`]
                    } *`}
                    placeholder={`${
                      intl.messages[`model.corporateRegistrationNumber`]
                    } *`}
                    hideTitle={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    onChange={updateFormState('address1')}
                    formState={formState.address1}
                    title={`${intl.messages[`model.address1`]} *`}
                    placeholder={`${intl.messages[`model.address1`]} *`}
                    hideTitle={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    onChange={updateFormState('address2')}
                    formState={formState.address2}
                    title={`${intl.messages[`model.address2`]} *`}
                    placeholder={`${intl.messages[`model.address2`]}`}
                    hideTitle={true}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <FormInput
                    onChange={updateFormState('zipCode')}
                    formState={formState.zipCode}
                    title={`${intl.messages[`model.zipCode`]} *`}
                    placeholder={`${intl.messages[`model.zipCode`]} *`}
                    hideTitle={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    onChange={updateFormState('city')}
                    formState={formState.city}
                    title={`${intl.messages[`model.city`]} *`}
                    placeholder={`${intl.messages[`model.city`]} *`}
                    hideTitle={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormSelect
                    formState={formState.country}
                    defaultLabel={`${intl.messages[`selectCountry`]}`}
                    onChange={updateFormState('country')}
                    title={`${intl.messages[`model.country`]} *`}
                    options={countryOptions}
                    hideTitle={true}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <UploadDocuments
                    title={`${intl.messages[`incProof`]}`}
                    subtitle={`${intl.messages['cantFindCopyOfYourIncProof']} ${intl.messages['saveProfileUpdateIncProofLater']}`}
                    onChange={fileIds => {
                      setVehicleFiles(fileIds);
                    }}
                    document={vehicleDoc}
                    accept={
                      'image/jpeg, 	application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,	application/msword'
                    }
                    disable={!formState['corporateVehicle']}
                  />
                </Grid>
              </Grid>

              <Typography
                variant={'h4'}
                style={{ fontSize: '14px', fontWeight: 600, marginTop: '25px' }}
              >
                <FormattedMessage id={`legalPepresentative`} />
              </Typography>

              <Grid container spacing={4} alignItems={'center'} wrap={'nowrap'}>
                <Grid item xs={'auto'}>
                  <AntSwitch
                    checked={formState['sameAsInvestor']}
                    onChange={updateFormStateSwitch('sameAsInvestor')}
                    id="sameAsInvestor"
                  />
                </Grid>
                <Grid item xs={true}>
                  <label htmlFor="sameAsInvestor">
                    <FormattedMessage id={`sameAsInvestor`} />
                  </label>
                </Grid>
              </Grid>
            </div>
          )}
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} md={true}>
              <A onClick={onClickBack}>
                <FormattedMessage id={`back`} />
              </A>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <ArrowButton
                text={`${intl.messages[`next`]}`}
                onClick={onClickNext}
                type="button"
                size="small"
              ></ArrowButton>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
});

export default memo(RegisterVehicles);
