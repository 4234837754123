import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the registerVehicles state domain
 */

const selectRegisterVehiclesDomain = (state: ApplicationRootState) => {
  return state.registerVehicles || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by RegisterVehicles
 */

const makeSelectRegisterVehicles = () =>
  createSelector(selectRegisterVehiclesDomain, substate => {
    return substate;
  });

export default makeSelectRegisterVehicles;
export { selectRegisterVehiclesDomain };
