export default {
  interests: {
    padding: 4,
    margin: 4,
    backgroundColor: 'var(--color-purple)',
    color: 'var(--color-light-grey)',
    fontWeight: 600,
    borderRadius: 2,
    display: 'inline-block',
  },
  investStatus: {
    color: 'var(--color-purple)',
    fontWeight: 600,
  },
  tableCell10: {
    width: '10%',
  },
  tableCell15: {
    width: '15%',
  },
  image: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    marginRight: '10px',
  },
};
