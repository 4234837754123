/*
 *
 * SecondaryMarket
 *
 */

import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Typography, makeStyles, Grid, Button, Theme } from '@material-ui/core';

import Img from 'components/Img';
import whiteIcon from '../../images/logos/Jadesquare-logo-white.svg';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectSecondaryMarket from './selectors';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 140,
    paddingBottom: 140,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 140,
      paddingRight: 140,
    },
  },
  logo: {
    width: '100%',
  },
  contentContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 120,
    paddingBottom: 120,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 140,
      paddingRight: 140,
    },
  },
  header: {
    fontWeight: 'bold',
    paddingBottom: 30,
    fontFamily: '"PT Sans", sans-serif',
  },
  paragraph: {
    fontSize: '1rem',
    fontFamily: '"PT Sans", sans-serif',
  },
  button: {
    marginTop: 60,
  },
}));

const stateSelector = createStructuredSelector({
  secondaryMarket: makeSelectSecondaryMarket(),
});

interface Props {}

function SecondaryMarket(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'secondaryMarket', reducer: reducer });
  useInjectSaga({ key: 'secondaryMarket', saga: saga });
  const classes = useStyles();
  const intl = useIntl();

  const { secondaryMarket } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const onClick = () => {
    return false;
    window.open(
      'https://jadesquare-investor-testing.tokeny.com/auth/signin',
      '_blank',
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.logoContainer}>
        <Img src={whiteIcon} className={classes.logo} />
      </Grid>
      <Grid item xs={12} className={classes.contentContainer}>
        <Typography variant="h3" className={classes.header}>
          <FormattedMessage id="SecondaryMarket.header" />
        </Typography>
        <Typography className={classes.paragraph}>
          <FormattedMessage id="SecondaryMarket.paragraph" />
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={onClick}
        >
          {`${intl.messages['SecondaryMarket.comingSoon']}`.toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
}

export default memo(SecondaryMarket);
