import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the companyDocuments state domain
 */

const selectCompanyDocumentsDomain = (state: ApplicationRootState) => {
  return state.companyDocuments || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CompanyDocuments
 */

const makeSelectCompanyDocuments = () =>
  createSelector(selectCompanyDocumentsDomain, substate => {
    return substate;
  });

export default makeSelectCompanyDocuments;
export { selectCompanyDocumentsDomain };
