/*
 *
 * TicketCreate
 *
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectTicketCreate from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  makeStyles,
  DialogActions,
  Button,
  Input,
  IconButton,
} from '@material-ui/core';
import Select from 'components/Select';
import NumberFormat from 'react-number-format';
import { makeSelectUserVehicles } from 'containers/App/selectors';
import Deal from 'models/deal';
import { submitAction, clear } from './actions';
import CloseIcon from '@material-ui/icons/Close';
import Vehicle from 'models/vehicle';
import { TOKENY_LINK } from './../../env';
import { setIsLoading } from 'containers/Home/actions';
const useStyles = makeStyles(() => ({
  title: {
    paddingTop: 4,
    fontSize: '1.25rem',
  },
  selectClass: {
    width: '100%',
    paddingTop: 4,
    paddingBottom: 4,
  },
  input: {
    width: '100%',
  },
  errorInput: {
    width: '100%',
    borderColor: '#D74F4F',
  },
  inputNumber: {
    width: '100%',
  },
  reminder: {
    width: '100%',
    padding: 12,
    marginTop: 12,
  },
  reminderTitle: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  reminderSub: {
    fontWeight: 'bold',
  },

  label: { marginTop: 12 },
  requiredText: {
    color: 'red',
  },
  border: {},
  titleColor: {},
}));

const stateSelector = createStructuredSelector({
  ticketCreate: makeSelectTicketCreate(),
  currentUserVehicle: makeSelectUserVehicles(),
});

interface Props {
  deal?: Deal;
  open: boolean;
  onClose: () => void;
  updateSuccess?: () => void;
  userVehicles?: Vehicle[];
  passedAmount?: number;
}

function TicketCreate(props: Props): JSX.Element {
  const intl = useIntl();
  // Warning: Add your key to RootState in types/index.d.ts file
  const {
    open,
    onClose,
    deal,
    updateSuccess,
    userVehicles,
    passedAmount,
  } = props;
  useInjectReducer({ key: 'ticketCreate', reducer: reducer });
  useInjectSaga({ key: 'ticketCreate', saga: saga });
  const [vehicleId, setVehicleId] = React.useState<string>();
  const { ticketCreate, currentUserVehicle } = useSelector(stateSelector);
  const [vehicles, setVehicles] = React.useState<Vehicle[] | undefined>(
    userVehicles ? userVehicles : currentUserVehicle,
  );
  const { success, error, loading } = ticketCreate;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [items, setItems] = React.useState<any[]>([]);
  useEffect(() => {
    vehicles &&
      vehicles.forEach(val => {
        if (val.vehicleType === 'INDIVIDUAL') {
          setVehicleId(val.vehicleId);
        }
      });
    const temp = vehicles
      ? vehicles.map(val => {
        if (val.vehicleType === 'INDIVIDUAL') {
          return {
            value: `${intl.messages['individual']}`,
            key: val.vehicleId,
          };
        } else {
          return { value: val.corporateName || '', key: val.vehicleId };
        }
      })
      : [];
    if (!vehicleId && temp.length > 0) {
      setVehicleId(temp[0].key);
    }
    setItems(temp);
  }, [vehicles]);

  useEffect(() => {
    setRequest(pre => {
      return {
        ...pre,
        vehicleId: vehicleId,
      };
    });
  }, [vehicleId]);
  useEffect(() => {
    if (userVehicles) {
      setVehicles(userVehicles);
    } else {
      setVehicles(currentUserVehicle);
    }
  }, [currentUserVehicle, userVehicles]);

  // useEffect(() => {
  //   if (!loading && success) {
  //     window.open(TOKENY_LINK, '_blank');
  //   }
  // }, [success, loading]);

  const investorType = [
    { value: `${intl.messages['financial']}`, key: 'FINANCIAL' },
    { value: `${intl.messages['strategic']}`, key: 'STRATEGIC' },
  ];
  const tokenIntent = [
    { value: `${intl.messages['tokenIntent.token']}`, key: 'token' },
    { value: `${intl.messages['tokenIntent.standard']}`, key: 'standard' },
  ];
  const [request, setRequest] = useState<any>({
    dealId: deal ? deal.dealId : 1,
    vehicleId: vehicleId,
    investorApproach: 'FINANCIAL',
    minValue: deal ? deal.minTicket : 0,
    maxValue: deal ? deal.maxTicket : 0,
    amount: passedAmount,
    tokenIntent: 'standard',
  });

  useEffect(() => {
    setRequest(pre => {
      return {
        ...pre,
        vehicleId: vehicleId,
        dealId: deal ? deal.dealId : 1,
        minValue: deal ? deal.minTicket : 0,
        maxValue: deal ? deal.maxTicket : 0,
      };
    });
  }, [deal]);
  const handleSelectVehicle = val => {
    setVehicleId(val.target.value);
    setRequest(pre => {
      return { ...pre, vehicleId: vehicleId };
    });
  };
  const handleSelectInvestorApproach = val => {
    setRequest(pre => {
      return { ...pre, investorApproach: val.target.value };
    });
  };
  const handleSelectTokenIntent = val => {
    console.log(val.target.value);
    setRequest(pre => {
      return { ...pre, tokenIntent: val.target.value };
    });
  };

  const submit = async () => {
    // dispatch(setIsLoading(true));
    dispatch(submitAction(request));
  };

  const onChanged = val => {
    const value = val.target.value
      .replace('$', '')
      .split(',')
      .join('');
    setRequest(pre => {
      return { ...pre, amount: Number(value) };
    });
  };

  useEffect(() => {
    if (success) {
      onClose();
      if (updateSuccess) {
        updateSuccess();
      }
    }
  }, [success]);

  useEffect(() => {
    dispatch(clear());
    setRequest(pre => {
      return {
        ...pre,
        amount: passedAmount ? passedAmount : null,
        tokenIntent: 'standard',
      };
    });
  }, [open]);

  const scope = 'TicketCreate';
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{ square: true }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          alignItems="center"
          spacing={2}
          className={classes.border}
        >
          <Grid item xs>
            <Typography className={`${classes.title}`}>
              <FormattedMessage id={`${scope}.title`} />
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className={classes.titleColor} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.label}>
          <FormattedMessage id="vehicle" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <Select
          value={vehicleId}
          // defaultValue={defaultVehicle}
          items={items}
          className={classes.selectClass}
          onChange={handleSelectVehicle}
        />
        <Typography className={classes.label}>
          <FormattedMessage id="investorType" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <Select
          defaultValue={'FINANCIAL'}
          items={investorType}
          className={classes.selectClass}
          onChange={handleSelectInvestorApproach}
        />
        <Typography className={classes.label}>
          <FormattedMessage id="tokenIntentQuestion" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <Select
          defaultValue={'standard'}
          items={tokenIntent}
          className={classes.selectClass}
          onChange={handleSelectTokenIntent}
          disabled={true}
        />
        <Typography className={classes.label}>
          <FormattedMessage id="ticketAmount" />{' '}
          <span className={classes.requiredText}>*</span>
        </Typography>
        <NumberFormat
          thousandSeparator={true}
          value={request.amount}
          prefix={'$'}
          className={`${error.ticketAmount ? classes.errorInput : classes.inputNumber
            }`}
          onChange={onChanged}
          customInput={Input}
          disableUnderline
        />
        {error.ticketAmount && (
          <Typography className={classes.requiredText}>
            {intl.messages['error.invalidAmount']}
          </Typography>
        )}
        <Grid container className={classes.reminder}>
          <Grid item xs={12}>
            <Typography className={classes.reminderTitle}>
              <FormattedMessage id="ticketAmountReminder" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="minValue" />
            </Typography>
            <NumberFormat
              value={deal ? deal.minTicket : 0}
              thousandSeparator={true}
              prefix={'$'}
              displayType={'text'}
              onChange={onChanged}
              className={classes.reminderSub}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="maxValue" />
            </Typography>
            <NumberFormat
              value={deal ? deal.maxTicket : 0}
              thousandSeparator={true}
              prefix={'$'}
              displayType={'text'}
              onChange={onChanged}
              className={classes.reminderSub}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          {`${intl.messages['cancel']}`.toUpperCase()}
        </Button>
        <Button
          style={{
            backgroundColor: 'var(--color-dark-purple)',
            color: 'var(--color-light-grey)',
          }}
          variant="contained"
          onClick={submit}
          //onClick={()=>{console.log(request)}}
          disabled={loading}
        >
          {`${intl.messages['commit']}`.toUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TicketCreate;
