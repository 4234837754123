/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginBottom: 12 },
    title: {
      color: '#888888',
      fontSize: '0.85rem',
      margin: theme.spacing(2, 0),
    },
    titleAmount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
    emailButton: {
      margin: '0 10px',
    },
  }),
);

interface Props {
  handleCreate: () => void;
  handleSendEmail: () => void;
  noMatched: number;
  scope: string;
}

function InvestorHeader(props: Props) {
  const { handleCreate, noMatched, handleSendEmail, scope } = props;
  const classes = useStyles();
  const localScope = `${scope}.header`;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={true}>
          <Typography className={classes.title}>
            <FormattedMessage id={`thereAreCurrently`} />
            <span className={classes.titleAmount}>
              {noMatched}
              <FormattedMessage
                id={`${localScope}.investor`}
                values={{ num: noMatched }}
              />
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSendEmail}>
            <FormattedMessage id={'sendEmail'} />
          </Button>
        </Grid>
        <Grid item className={classes.emailButton}>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            <FormattedMessage id={`${localScope}.createNewInvestor`} />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(InvestorHeader);
