import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import Vehicle from '../../models/vehicle';
import VehicleRequest from '../../models/request/vehicle-request';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function getVehicles(userId: string): Promise<Vehicle[]> {
  return instance
    .get(`/vehicles/users/${userId}`)
    .then((resp: AxiosResponse<RestResult<Vehicle[]>>) => {
      return resp.data.data;
    });
}

export async function createVehicle(request: VehicleRequest): Promise<Vehicle> {
  return instance
    .post('/vehicles', request)
    .then((resp: AxiosResponse<RestResult<Vehicle>>) => {
      return resp.data.data;
    });
}

export async function updateVehicle(
  vehicleId: string,
  request: VehicleRequest,
): Promise<Vehicle> {
  return instance
    .put(`/vehicles/${vehicleId}`, request)
    .then((resp: AxiosResponse<RestResult<Vehicle>>) => {
      return resp.data.data;
    });
}

export async function getVehiclesToSign(
  dealId: string,
  request: {
    page?: number;
    pageSize?: number;
    searchKey?: string;
    eSignDocumentStatuses?: string[];
    eSignTemplateId: string;
    vehicleType?: string;
  },
): Promise<any> {
  return instance
    .post(`/deals/${dealId}/vehicles/search`, request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return { data: resp.data.data, total: resp.data.metaData.total };
    });
}
