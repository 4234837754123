/*
 *
 * CompanyCreateDialog reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

const validate = email => {
  // tslint:disable-next-line:max-line-length
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email));
};

export const initialState: ContainerState = {
  error: {},
  loading: false,
};

const companyCreateDialogReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.CLEAR_FORM_ACTION:
        draft.error = {};
        break;
      case ActionTypes.VALIDATE_INVESTOR:
        const request = action.payload;
        draft.error = {};
        let loading = true;
        if (!request.firstName) {
          draft.error.firstName = 'First name is required';
          loading = false;
        }
        if (!request.lastName) {
          draft.error.lastName = 'Last name is required';
          loading = false;
        }
        if (!request.email) {
          draft.error.email = 'Email is required';
          loading = false;
        }
        if (request.email && !validate(request.email)) {
          draft.error.email = 'Wrong email format';
          loading = false;
        }
        draft.loading = loading;
        break;
      case ActionTypes.SET_ERROR:
        draft.error = action.payload;
        break;
      case ActionTypes.SET_LOADING:
        draft.loading = action.payload;
        break;
      default:
        break;
    }
  },
  initialState,
);

export default companyCreateDialogReducer;
