/**
 *
 * DownloadPopup
 *
 */
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    padding: 2,
    margin: 4,
  },
}));
interface Props {
  total?: number | undefined;
  isPreview?: boolean;
}

function DownloadPopup(props: Props) {
  const { total, isPreview } = props;
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.container}>
      <CircularProgress
        disableShrink
        size={20}
        thickness={4}
        className={classes.progress}
      />
      <FormattedMessage id={isPreview? 'PreviewPopup.message':'DownloadPopup.message'} values={{ num: total }} />
    </Card>
  );
}

export default memo(DownloadPopup);
