import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const getDealDocument = payload =>
  action(ActionTypes.GET_DEAL_DOCUMENT, payload);

export const getDealSuccessDocument = payload =>
  action(ActionTypes.GET_DEAL_DOCUMENT_SUCCESS, payload);

export const uploadDocument = payload =>
  action(ActionTypes.UPLOAD_DOCUMENT, payload);
