import { Grid, Tab, Tabs, withStyles } from '@material-ui/core';
import ProfileAbout from 'containers/ProfileAbout';
import React from 'react';
import UserData from 'models/user';
import ProfileVehicles from 'containers/ProfileVehicles';
import ActivityView from './activityView';
import ProfileDeal from 'containers/ProfileDeal';
import ProfileTicket from 'containers/ProfileTicket';
import NumberLabel from 'components/NumberLabel';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  investor: UserData;
  scope: string;
  statistic?: any;
}

const CustomTab = withStyles(() => ({
  root: {
    minWidth: 100,
  },
  wrapper: {
    width: 'auto',
    flexDirection: 'unset',
  },
}))(Tab);

const CustomTabs = withStyles(() => ({
  root: {
    marginRight: 12,
  },
  flexContainer: {
    justifyContent: 'flex-end',
  },
}))(Tabs);

const AdminLayout = (props: Props) => {
  const { statistic, investor, scope } = props;
  const intl = useIntl();
  const [tabValue, setTabValue] = React.useState(0);

  const currentView = () => {
    switch (tabValue) {
      case 0:
        return <ActivityView scope={scope} investor={investor} />;
      case 1:
        return <ProfileDeal investor={investor} />;
      case 2:
        return <ProfileTicket investor={investor} />;
      default:
        return <ProfileVehicles investor={investor} />;
    }
  };

  const handleTabChanged = (e, value) => {
    setTabValue(value);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <ProfileAbout investor={investor} />
      </Grid>
      <Grid item xs={12} lg={8}>
        <div>
          <CustomTabs value={tabValue} onChange={handleTabChanged}>
            <CustomTab label={`${intl.messages['activity']}`} />
            <CustomTab
              label={
                <>
                  <FormattedMessage id="deals" />
                  <NumberLabel
                    label={
                      statistic && statistic.totalDeals
                        ? statistic.totalDeals
                        : 0 || 0
                    }
                  />
                </>
              }
              key={'Deal'}
            />
            ,
            <CustomTab
              label={
                <>
                  <FormattedMessage id="tickets" />
                  <NumberLabel
                    label={
                      statistic && statistic.totalTickets
                        ? statistic.totalTickets
                        : 0 || 0
                    }
                  />
                </>
              }
              key={'Tickets'}
            />
            ,
            <CustomTab label={`${intl.messages['vehicles']}`} />
          </CustomTabs>
        </div>
        { investor && currentView()}
      </Grid>
    </Grid>
  );
};

export default AdminLayout;
