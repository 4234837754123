import {
  Button,
  FormControl,
  MenuItem,
  PropTypes,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo, useEffect } from 'react';
import CustomFormLabel from '../../../components/FormControl/components/form-label';
import CustomHelperText from '../../../components/FormControl/components/helper-text';
import {
  FormState,
  initialFormState,
} from '../../../components/FormControl/state';
import { useIntl } from 'react-intl';

export interface AvatarUploadState extends FormState {
  localFile: any;
  remoteUrl: string | null;
}

export const initialAvatarUploadState: AvatarUploadState = {
  localFile: null,
  remoteUrl: null,
  ...initialFormState,
};

interface Props {
  remoteUrl: string;
  onChange: (ev) => void;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth: boolean;
}

AvatarUpload.defaultProps = {
  fullWidth: true,
};

const useStyles = makeStyles(theme => ({}));

function AvatarUpload(props: Props) {
  const intl = useIntl();
  const styles = useStyles(props);
  const { remoteUrl, onChange, title, subtitle, margin, fullWidth } = props;
  let hiddenFileInput;

  const [avatarUrl, setAvatarUrl] = React.useState<string | null>(remoteUrl);

  const handleAvatarChange = ev => {
    setAvatarUrl(URL.createObjectURL(ev.target.files[0]));
    onChange(ev.target.files[0]);
  };

  const handleAvatarRemove = ev => {
    setAvatarUrl(null);
    onChange(null);
  };

  useEffect(() => {
    setAvatarUrl(remoteUrl);
  }, [remoteUrl]);

  const localScope = 'ProfileEditDialog.avatarUpload';

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <CustomFormLabel title={title} subtitle={subtitle} />

      <div>
        {avatarUrl && (
          <img
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
            src={avatarUrl}
            alt={avatarUrl}
          />
        )}
        <input
          ref={ref => (hiddenFileInput = ref)}
          type="file"
          name="file"
          accept="image/*"
          onChange={handleAvatarChange}
          style={{ display: 'none' }}
        />

        <Button
          size={'small'}
          style={{ marginTop: '0px' }}
          variant="contained"
          color="primary"
          onClick={() => hiddenFileInput.click()}
        >
          {avatarUrl
            ? `${intl.messages[`${localScope}.updateProfilePicture`]}`
            : `${intl.messages['upload']}`.toUpperCase()}
        </Button>

        {avatarUrl && (
          <Button
            size={'small'}
            style={{
              marginLeft: '10px',
              backgroundColor: '#D74F4F',
              color: '#FFF',
            }}
            variant="contained"
            onClick={handleAvatarRemove}
          >
            {`${intl.messages['delete']}`.toUpperCase()}
          </Button>
        )}
      </div>
    </FormControl>
  );
}

export default memo(AvatarUpload);
