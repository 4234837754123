/*
 *
 * ProfileVehicles
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectProfileVehicles from './selectors';
import reducer from './reducer';
import saga from './saga';
import UserData from 'models/user';
import IndividualVehicle from './components/individualVehicle';
import VehicleList from './components/vehicleList';
import { Card, Grid } from '@material-ui/core';
import {
  fetchUserVehicleAction,
  updateUserVehicleAction,
  createUserVehicleAction,
} from './actions';
import ProfileVehicleCreateDialog from 'containers/ProfileVehicleCreateDialog';
import Vehicle from 'models/vehicle';
import SearchInput from 'components/SearchInput';
import { FormattedMessage } from 'react-intl';

const stateSelector = createStructuredSelector({
  profileVehicles: makeSelectProfileVehicles(),
});

interface Props {
  investor: UserData;
}

function ProfileVehicles(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'profileVehicles', reducer: reducer });
  useInjectSaga({ key: 'profileVehicles', saga: saga });
  const { investor } = props;
  const { profileVehicles } = useSelector(stateSelector);
  const { vehicles, individualVehicle, needRefresh } = profileVehicles;
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<Vehicle | undefined>(
    undefined,
  );
  const [searchValue, setSearchValue] = React.useState<string>('');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserVehicleAction(investor.userId));
  }, []);

  useEffect(() => {
    if (needRefresh) {
      dispatch(fetchUserVehicleAction(investor.userId));
      setOpen(false);
    }
  }, [needRefresh]);

  const handleIndividualChanged = active => {
    if (active && individualVehicle) {
      dispatch(
        updateUserVehicleAction({
          vehicleId: individualVehicle.vehicleId,
          request: { status: 'ACTIVE', vehicleType: 'INDIVIDUAL' },
        }),
      );
    } else if (!active && individualVehicle) {
      dispatch(
        updateUserVehicleAction({
          vehicleId: individualVehicle.vehicleId,
          request: { status: 'INACTIVE', vehicleType: 'INDIVIDUAL' },
        }),
      );
    } else {
      dispatch(
        createUserVehicleAction({
          userId: investor.userId,
          vehicleType: 'INDIVIDUAL',
        }),
      );
    }
  };

  const onSearchChangeHandler = (value: string) => {
    setSearchValue(value);
  };

  return (
    <div>
      <h2 className="h4">
        <FormattedMessage id="vehicles" />
      </h2>
      <Card style={{ marginBottom: '25px' }}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} lg={'auto'}>
            <IndividualVehicle
              isIndividual={
                individualVehicle !== undefined &&
                individualVehicle.status === 'ACTIVE'
              }
              onChange={handleIndividualChanged}
            />
          </Grid>
          <Grid item xs={12} lg={true}>
            <SearchInput onChange={onSearchChangeHandler} />
          </Grid>
        </Grid>
      </Card>
      <div>
        <VehicleList
          items={vehicles}
          total={vehicles ? vehicles.length : 0}
          searchValue={searchValue}
          onEdit={item => {
            setSelectedItem(item);
            setOpen(true);
          }}
        />
      </div>
      <div>
        <ProfileVehicleCreateDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          item={selectedItem}
          investor={investor}
        />
      </div>
    </div>
  );
}

export default ProfileVehicles;
