/*
 *
 * RegisterVehicles constants
 *
 */

enum ActionTypes {
  UPDATE_FORM_STATE_VEHICLES_ACTION = 'app/RegisterVehicles/UPDATE_FORM_STATE_VEHICLES_ACTION',
  FETCH_PROFILE_ACTION = 'app/RegisterVehicles/FETCH_PROFILE_ACTION',
  FETCH_PROFILE_SUCCESS_ACTION = 'app/RegisterVehicles/FETCH_PROFILE_SUCCESS_ACTION',
  FETCH_VEHICLES_ACTION = 'app/RegisterVehicles/FETCH_VEHICLES_ACTION',
  FETCH_VEHICLES_SUCCESS_ACTION = 'app/RegisterVehicles/FETCH_VEHICLES_SUCCESS_ACTION',
  UPDATE_VEHICLE_ACTION = 'app/RegisterVehicles/UPDATE_VEHICLE_ACTION',
  UPDATE_VEHICLE_SUCCESS_ACTION = 'app/RegisterVehicles/UPDATE_VEHICLE_SUCCESS_ACTION',
  RESET_STATE_ACTION = 'app/RegisterVehicles/RESET_STATE_ACTION',
  UPDATE_STEP_ACTION = 'app/RegisterVehicles/UPDATE_STEP_ACTION',
  CREATE_DOCUMENT_ACTION = 'app/RegisterVehicles/CREATE_DOCUMENT_ACTION',
  UPLOAD_DOCUMENT_ACTION = 'app/RegisterVehicles/UPLOAD_DOCUMENT_ACTION',
  CREATE_DOCUMENT_SUCCESS_ACTION = 'app/RegisterVehicles/CREATE_DOCUMENT_SUCCESS_ACTION',
  CREATE_DOCUMENT_GROUP_ACTION = 'app/RegisterVehicles/CREATE_DOCUMENT_GROUP_ACTION',
  CREATE_DOCUMENT_GROUP_SUCCESS_ACTION = 'app/RegisterVehicles/CREATE_DOCUMENT_GROUP_SUCCESS_ACTION',
}

export default ActionTypes;
