import React, { memo } from 'react';
import { FormControl, PropTypes } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { FormState, initialFormState } from './state';
import CustomFormLabel from './components/form-label';
import CustomHelperText from './components/helper-text';

export interface DatePickerState extends FormState {
  value: string | undefined | null;
}

export const initialDatePickerState: DatePickerState = {
  value: undefined,
  ...initialFormState,
};

interface Props {
  formState: DatePickerState;
  onChange: (ev) => void;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth?: boolean;
  futureDate?: boolean;
  disablePast?: boolean;
  hideTitle?: boolean;
  [propsName: string]: any;
}

FormDatePicker.defaultProps = {
  fullWidth: true,
};

function FormDatePicker(props: Props) {
  const {
    formState,
    onChange,
    title,
    subtitle,
    margin,
    fullWidth,
    futureDate,
    disablePast,
    hideTitle,
    maxDate,
    ...rest
  } = props;

  const handleDateChange = ev => {
    onChange(moment(ev).format('DD-MM-YYYY'));
  };

  const getDate = str => {
    if (str) {
      const check = moment(str, ['DD-MM-YYYY', 'DD/MM/YYYY']);
      return check.isValid() ? new Date(check.valueOf()) : null;
    }
    return null;
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      variant={'outlined'}
      {...rest}
    >
      <CustomFormLabel title={title} subtitle={subtitle} hidden={hideTitle} />

      {!maxDate ? (
        <DatePicker
          disableFuture={!futureDate}
          error={formState.error}
          inputVariant={'outlined'}
          openTo="year"
          placeholder={title}
          format="DD-MM-YYYY"
          views={['year', 'month', 'date']}
          value={getDate(formState.value)}
          onChange={handleDateChange}
          disablePast={disablePast}
        />
      ) : (
        <DatePicker
          disableFuture={!futureDate}
          error={formState.error}
          inputVariant={'outlined'}
          openTo="year"
          placeholder={title}
          format="DD-MM-YYYY"
          views={['year', 'month', 'date']}
          initialFocusedDate={maxDate}
          value={getDate(formState.value)}
          onChange={handleDateChange}
          maxDate={maxDate}
          disablePast={disablePast}
        />
      )}

      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(FormDatePicker);
