/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styles/styled-components';

const A = styled.a<{
  color?: string;
  textDecoration?: string;
  fontWeight?: number | string;
}>`
  color: ${props => props.color || 'var(--color-purple)'};
  text-decoration: ${props => props.textDecoration || 'underline'};
  font-weight: ${props => props.fontWeight || '500'};
  cursor: pointer;
  &:hover {
    color: ${props => props.color || 'var(--color-purple)'}; 
    text-decoration: ${props =>
      props.textDecoration === 'underline' || !props.textDecoration
        ? 'none'
        : 'underline'};
  }
`;

export default A;
