import { Proc, Wnd, AppEnv, ProcEnv } from 'types/env.d';
import { config } from '../internals/buildutil/textenv';

export const environments = (): string[] => [
  'production',
  'staging',
  'development',
  'local',
];

export declare const window: Wnd;

export declare const process: NodeJS.Process;

export const runtimeEnv: AppEnv = {};

if (!process.env) {
  process.env = {};
}

Object.assign(runtimeEnv, {
  NODE_ENV: process.env.NODE_ENV || 'production',
  APP_ENV: process.env.APP_ENV || 'production',
  APP_INGRESS_HOSTS: process.env.APP_INGRESS_HOSTS || '',
  BACKEND_API_URL: process.env.BACKEND_API_URL || '',
  HASURA_API_URL: process.env.HASURA_API_URL || '',
  AUTH_API: process.env.AUTH_API || '',
  REACT_APP_VERSION: process.env.REACT_APP_VERSION || '',
  REACT_APP_NAME: process.env.REACT_APP_NAME || '',
  TOKENY_LINK: process.env.TOKENY_LINK || 'https://staging-jadesquare.tokeny.com/',
});

const VARIABLE_LIST: string[] = [
  'NODE_ENV',
  'APP_ENV',
  'BACKEND_API_URL',
  'HASURA_API_URL',
  'AUTH_API',
  'REACT_APP_NAME',
  'REACT_APP_VERSION',
  'TOKENY_LINK',
];

mergeFrom(runtimeEnv, window.__env__ || {});

if (window.__env__) {
  mergeFrom(window.__env__, runtimeEnv);
}

export const {
  NODE_ENV,
  APP_ENV,
  APP_INGRESS_HOSTS,
  BACKEND_API_URL,
  HASURA_API_URL,
  REACT_APP_NAME,
  REACT_APP_VERSION,
  TOKENY_LINK,
} = runtimeEnv;

function error(message: string, err: Error) {
  // eslint-disable-next-line no-console
  console.error('ERROR', ' ', message || err.message || 'Unknown error');
}

function envFilter(old: string | undefined): string | undefined {
  const v = String(old)
    .trim()
    .replace(/\s*\${([\w_]+)}\s*/i, '');

  if (!v) {
    return '';
  }
  if (v.match(/^(false|no|off|null|nul|nil|undefined|undef)$/giu) !== null) {
    return '';
  }
  if (v.match(/^(true|yes|on)$/giu) !== null) {
    return 'true';
  }
  return v;
}

function mergeFrom(target: AppEnv, src: AppEnv): AppEnv {
  for (const [k, v] of Object.entries(src)) {
    const value = envFilter(v);
    if (value) {
      target[k] = src[k] = value;
    }
  }
  return target;
}
