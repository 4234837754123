/*
 *
 * TemplateCreate actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const clearAction = payload => action(ActionTypes.CLEAR_ACTION, payload);
export const submitAction = payload =>
  action(ActionTypes.SUBMIT_ACTION, payload);
export const submitFailedAction = payload =>
  action(ActionTypes.SUBMIT_FAILED_ACTION, payload);
export const getKeyAction = () => action(ActionTypes.GET_KEY);
export const getKeySuccess = payload =>
  action(ActionTypes.GET_KEY_SUCCESS, payload);
export const setLoading = loading => action(ActionTypes.SET_LOADING, loading);
