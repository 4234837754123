import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { push } from 'connected-react-router';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Card, Link } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab';
import moment from 'moment';

import ErrorToast from 'components/ErrorToast';
import Select from 'components/Select';
import DividerWithLabel from 'components/DividerWithLabel';
import UserData from 'models/user';
import Pagination from 'components/Table/pagination';
import { PAGE_SIZE } from 'utils/constants';
import UserActivityQuery from 'gql/userActivity/UserActivity.gql';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 12,
  },
  title: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    marginBottom: 12,
  },
  textLabel: {
    fontWeight: 300,
    lineHeight: '1.2rem',
    margin: 0,
  },
  body: {
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: '1.2rem',
    margin: 0,
  },
  timelineDot: {
    width: 25,
    height: 25,
  },
  timelineDotDay: {
    fontSize: '0.9rem',
    fontWeight: 500,
    lineHeight: 1,
    margin: 0,
  },
  timelineDotMonth: {
    fontSize: '0.6rem',
    lineHeight: 1,
    margin: 0,
  },
  filterContainer: {
    margin: theme.spacing(0, 1),
    display: 'inline',
    width: 100,
  },
  container: {
    minWidth: 150,
  },
  select: {
    root: {
      padding: '3px 6px',
      lineheight: 'normal',
    },
    minWidth: 75,
  },
  noRecords: {
    margin: theme.spacing(4),
  },
}));

const CustomTimelineItem = withStyles(() => ({
  root: {
    '&.MuiTimelineItem-missingOppositeContent': {
      '&:before': {
        display: 'none',
      },
    },
  },
}))(TimelineItem);

interface Props {
  investor: UserData;
  scope: string;
}

function ActivityView(props: Props) {
  const { investor } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [categoryFilter, setCategoryFilter] = useState('all');

  const intl = useIntl();
  const localScope = `ProfileAbout.activityView`;
  const getTransformedCategoryFilter = () => {
    switch (categoryFilter) {
      case 'deal_related_only':
        return '(D)%';
      case 'profile_related_only':
        return '(P)%';
      case 'vehicle_related_only':
        return '(V)%';
      case 'all':
      default:
        return '(D|P|V)%';
    }
  };

  const { loading, error, data, refetch } = useQuery(UserActivityQuery, {
    variables: {
      userId: investor.userId,
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      category: getTransformedCategoryFilter(),
    },
  });

  let formattedData;

  if (data) {
    formattedData = data.user_activity.map(item => {
      let messageValues: any;

      switch (item.activity_type) {
        case 'D1':
        case 'D2':
          messageValues = {
            dealName: (
              <Link onClick={() => onClickDeal(item.activity_data.id)}>
                {item.activity_data.name}
              </Link>
            ),
          };
          break;

        case 'D3':
          messageValues = {
            dealName: (
              <Link onClick={() => onClickDeal(item.activity_data.id)}>
                {item.activity_data.name}
              </Link>
            ),
            amount: item.activity_data.amount,
          };
          break;

        case 'D4':
          messageValues = {
            newStatus: item.activity_data.status,
            dealName: (
              <Link onClick={() => onClickDeal(item.activity_data.id)}>
                {item.activity_data.name}
              </Link>
            ),
          };
          break;

        case 'D5':
        case 'D6':
          messageValues = { documentName: item.activity_data.name };
          break;

        case 'V1':
        case 'V2':
        case 'V3':
        case 'V4':
          messageValues = { vehicleName: item.activity_data.name };
          break;
      }

      return { ...item, messageValues };
    });
  }

  const onClickDeal = (dealId: string) => {
    dispatch(push(`/deals/${dealId}`));
  };

  const handleChangeFilter = event => {
    setCategoryFilter(event.target.value);
    setPage(1);
  };

  const onChangePage = value => {
    setPage(value);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Card variant="outlined" className={classes.root}>
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? error.message : ''}
      />
      <div>
        <div className={classes.container}>
          <Grid
            alignItems="center"
            justify="flex-end"
            container
            spacing={3}
            className={classes.container}
          >
            <Grid item xs={'auto'}>
              <FormattedMessage id={`filtering`} />
            </Grid>
            <Grid item xs="auto">
              <Select
                className={classes.select}
                onChange={handleChangeFilter}
                defaultValue={intl.messages.email}
                value={categoryFilter}
                items={[
                  {
                    value: `${intl.messages['showAll']}`,
                    key: 'all',
                  },
                  {
                    value: `${intl.messages[`${localScope}.dealRelatedOnly`]}`,
                    key: 'deal_related_only',
                  },
                  {
                    value: `${
                      intl.messages[`${localScope}.profileRelatedOnly`]
                    }`,
                    key: 'profile_related_only',
                  },
                  {
                    value: `${
                      intl.messages[`${localScope}.vehicleRelatedOnly`]
                    }`,
                    key: 'vehicle_related_only',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>

        {!error && !loading && formattedData.length > 0 ? (
          <>
            <Grid container>
              <Timeline>
                {formattedData.map((item, index) => (
                  <CustomTimelineItem key={item.id}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" variant="outlined">
                        <Grid
                          container
                          direction={'column'}
                          alignItems={'center'}
                          justify={'center'}
                          className={classes.timelineDot}
                        >
                          <Typography className={classes.timelineDotDay}>
                            {moment(item.activity_date).format('D')}
                          </Typography>
                          <Typography className={classes.timelineDotMonth}>
                            {moment(item.activity_date)
                              .format('MMM')
                              .toUpperCase()}
                          </Typography>
                        </Grid>
                      </TimelineDot>
                      {index !== formattedData.length - 1 && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography className={classes.body}>
                        <FormattedMessage
                          id={`${localScope}.${item.activity_type}`}
                          values={item.messageValues}
                        />
                      </Typography>
                      <Typography className={classes.textLabel}>
                        {`${moment(item.activity_date).format(
                          'DD.MM.YYYY. HH:mm',
                        )} (SGT)`}
                      </Typography>
                      {formattedData[index + 1] &&
                        moment(formattedData[index + 1].activity_date).year() <
                          moment(item.activity_date).year() && (
                          <DividerWithLabel
                            label={String(
                              moment(
                                formattedData[index + 1].activity_date,
                              ).year(),
                            )}
                          />
                        )}
                    </TimelineContent>
                  </CustomTimelineItem>
                ))}
              </Timeline>
            </Grid>
            <Pagination
              page={page}
              total={data.user_activity_aggregate.aggregate.count}
              onChangePage={onChangePage}
            />
          </>
        ) : (
          <Grid container alignItems="center" justify="center">
            <Typography className={classes.noRecords}>
              <FormattedMessage id={`noRecordsToDisplay`} />
            </Typography>
          </Grid>
        )}
      </div>
    </Card>
  );
}

export default memo(ActivityView);
