// tslint:disable: jsx-wrap-multiline
import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash/fp';

import CloseIcon from '@material-ui/icons/Close';
import {
  Card,
  Grid,
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  Link,
  makeStyles,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import SearchInput from 'components/SearchInput';
import MultiSelect from 'components/MultiSelect';
import Table from 'components/Table';
import Loading from 'components/Loading';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import {
  setOpen,
  searchVehicles,
  searchTemplates,
  requestSign,
  getPreview,
} from './actions';
import makeSelectSignRequest from './selectors';
import reducer from './reducer';
import saga from './saga';
import { PAGE_SIZE, TEMPLATE_CATEGORY } from 'utils/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalizeFirstLetter from 'services/capitalize-first-letter';
const stateSelector = createStructuredSelector({
  signRequest: makeSelectSignRequest(),
});

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 12,
    textTransform: 'uppercase',
  },
  border: {
    borderBottom: '1px solid #e7e7e7',
  },
  title: {
    paddingTop: 4,
    fontSize: '1.375rem',
    fontWeight: 600,
  },
  warning: {
    marginTop: 4,
    color: '#D74F4F',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  tableHeader: {
    padding: '11px 16px',
  },
  checkbox: {
    paddingLeft: 0,
  },
  tableCell10: {
    width: '10%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell20: {
    width: '20%',
  },
  name: {
    fontWeight: 600,
    color: 'var(--color-purple)',
  },
  action: {
    paddingRight: 24,
    marginBottom: 6,
  },
  spacingNone: {
    margin: '-6px 0px',
  },
  vehicleSelect: {
    paddingRight: '0px !important',
  },
}));

const getStatus = (status, messages) => {
  switch (status) {
    case 'SIGNED':
      return (
        <Typography style={{ color: 'var(--color-cyan)', fontWeight: 500 }}>
          {`${messages['signed']}`}
        </Typography>
      );
    case 'IN_PROCESS':
      return (
        <Typography style={{ color: 'var(--color-purple)', fontWeight: 500 }}>
          {`${messages['inProcess']}`}
        </Typography>
      );
    case 'DECLINED':
      return (
        <Typography style={{ color: 'red', fontWeight: 500 }}>
          {`${messages['declined']}`}
        </Typography>
      );
    default:
      return (
        <Typography
          style={{ fontWeight: 500 }}
        >{`${messages['notRequested']}`}</Typography>
      );
  }
};

const getInvestorString = num => {
  if (num === 1) {
    return 'investor';
  }
  return 'investors';
};

interface Props {
  dealId: string;
}

const categories = TEMPLATE_CATEGORY;

const types = {
  CORPORATE: 'Corporate',
  INDIVIDUAL: 'Individual',
};

function SignRequest(props: Props) {
  const intl = useIntl();
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'signRequest', reducer: reducer });
  useInjectSaga({ key: 'signRequest', saga: saga });

  const classes = useStyles();

  const { dealId } = props;

  const selectStatus = [
    {
      title: `${intl.messages['signed']}`,
      value: 'SIGNED',
    },
    {
      title: `${intl.messages['declined']}`,
      value: 'DECLINED',
    },
    {
      title: `${intl.messages['notRequested']}`,
      value: 'NOT_REQUESTED',
    },
  ];

  const { signRequest } = useSelector(stateSelector);
  const [selectedVehicles, setSelectedVehicles] = useState<any[]>([]);
  const [onlySelected, setOnlySelected] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [signStatuses, setSignStatuses] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState<any>('default');
  const [template, setTemplate] = useState<any>('default');
  const [vehicleType, setVehicleType] = useState<any>('default');

  const { open, vehicles, total, templates, loading } = signRequest;

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setOpen(false));
  };

  const onSelectVehicle = e => {
    setVehicleType(e.target.value);
  };

  const onRequestSign = () => {
    dispatch(
      requestSign({
        dealId,
        eSignTemplateId: template,
        vehicleIds: selectedVehicles,
      }),
    );
  };

  const searchVehiclesRequest = () => {
    dispatch(
      searchVehicles({
        dealId,
        request: {
          pageSize: PAGE_SIZE,
          page: page - 1,
          searchKey: searchKey,
          eSignDocumentStatuses: signStatuses,
          eSignTemplateId: template,
          vehicleType: vehicleType === 'default' ? undefined : vehicleType,
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(searchTemplates());
  }, []);

  useEffect(() => {
    setTemplate(get('[0].esignTemplateId')(templates) || 'default');
    setCategory(get('[0].category')(templates) || 'default');
  }, [templates]);

  useEffect(() => {
    if (open && template !== 'default') {
      searchVehiclesRequest();
    }
  }, [dealId, searchKey, page, signStatuses, open, template, vehicleType]);

  useEffect(() => {
    if (!open) {
      setSearchKey('');
      setPage(1);
      setSelectedVehicles([]);
      setOnlySelected(false);
      setSignStatuses([]);
      setVehicleType('default');
    }
  }, [open]);

  const onChangeCategory = e => {
    setCategory(e.target.value);
    setTemplate('default');
  };

  const onChangeTemplate = e => {
    setTemplate(e.target.value);
  };

  const isRequestDisabled = () => {
    return (
      template === 'default' ||
      category === 'default' ||
      !selectedVehicles.length
    );
  };

  const onCheckInvestor = (value, vehicle) => {
    if (value) {
      setSelectedVehicles([...selectedVehicles, vehicle.vehicleId]);
      return;
    }
    setSelectedVehicles(
      selectedVehicles.filter(id => id !== vehicle.vehicleId),
    );
  };

  const onPreview = async id => {
    dispatch(
      getPreview({
        dealId,
        vehicleId: id,
        esignTemplateId: template,
      }),
    );
  };

  const headers = [
    { name: '' },
    {
      name: `${intl.messages['investorName']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['vehicleType']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['vehicle']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['model.email']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['status']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['numberOfTickets']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    {
      name: `${intl.messages['totalApprovedInvestment']}`.toUpperCase(),
      headerClass: classes.tableCell20,
    },
    { name: '', headerClass: classes.tableCell10 },
  ];

  const renderItem = (item, index) => [
    <Checkbox
      color="primary"
      key={index}
      className={classes.checkbox}
      onChange={e => onCheckInvestor(e.currentTarget.checked, item)}
      checked={selectedVehicles.includes(item.vehicleId)}
    />,
    <Link
      className={classes.name}
      key={index}
      href={`/investors/${item.userId}`}
      target="_blank"
    >
      {item.userFullName}
    </Link>,
    <Typography key={index}>{types[item.vehicleType]}</Typography>,
    <Typography key={index}>{item.vehicleName}</Typography>,
    <Typography key={index}>{item.userEmail}</Typography>,
    getStatus(item.esignDocumentStatus, intl.messages),
    <Typography key={index}>{item.ticketCount}</Typography>,
    <Typography key={index}>
      <NumberFormat
        value={item.totalInvestmentTicketAmount}
        decimalScale={2}
        thousandSeparator={true}
        prefix={'$'}
        displayType={'text'}
      />
    </Typography>,
    <Button
      disabled={!template || template === 'default'}
      size={'small'}
      color="primary"
      variant="outlined"
      key={index}
      onClick={() => onPreview(item.vehicleId)}
    >
      <FormattedMessage id="previewFile" />
    </Button>,
  ];

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle>
          <Grid
            container
            alignItems="center"
            className={classes.border}
            spacing={2}
          >
            <Grid item xs>
              <Typography className={classes.title}>
                <FormattedMessage id="requestNewSigning" />
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography>
                <FormattedMessage id="templateCategory" /> *
              </Typography>
              <Select
                value={category}
                variant="outlined"
                fullWidth
                onChange={onChangeCategory}
              >
                <MenuItem value={'default'} disabled>
                  <FormattedMessage id="selectCategory" />
                </MenuItem>
                {categories.map((ctg, index) => (
                  <MenuItem value={ctg.value} key={index}>
                    {ctg.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <Typography>
                <FormattedMessage id="templateDocument" /> *
              </Typography>
              <Select
                value={template}
                variant="outlined"
                fullWidth
                onChange={onChangeTemplate}
              >
                <MenuItem value={'default'} disabled>
                  <FormattedMessage id="selectDocument" />
                </MenuItem>
                {templates &&
                  templates
                    .filter(
                      tpl =>
                        tpl.category === category || category === 'default',
                    )
                    .map((tpl, index) => (
                      <MenuItem value={tpl.esignTemplateId} key={index}>
                        {tpl.name}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography>
                <FormattedMessage id="selectListOfInvestorsToSendSigningRequest" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <SearchInput
                onChange={v => {
                  setSearchKey(v);
                  setPage(1);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              spacing={3}
              className={classes.spacingNone}
            >
              <Grid item className={classes.center}>
                <Typography>
                  <FormattedMessage id="signStatus" />link
                </Typography>
              </Grid>
              <Grid item xs>
                <MultiSelect
                  options={selectStatus}
                  onSelect={v => {
                    setSignStatuses(v.map(status => status.value));
                    setPage(1);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              spacing={3}
              className={classes.spacingNone}
            >
              <Grid item className={classes.center}>
                <Typography>
                  <FormattedMessage id="vehicleType" />
                </Typography>
              </Grid>
              <Grid item xs className={classes.vehicleSelect}>
                <Select
                  value={vehicleType}
                  variant="outlined"
                  fullWidth
                  onChange={onSelectVehicle}
                >
                  <MenuItem value={'default'}>
                    <FormattedMessage id="all" />
                  </MenuItem>
                  <MenuItem value={'INDIVIDUAL'}>
                    <FormattedMessage id="individual" />
                  </MenuItem>
                  <MenuItem value={'CORPORATE'}>
                    <FormattedMessage id="corporate" />
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12}>
              <Card variant="outlined" className={classes.fullWidth}>
                <Grid item xs={12} sm={12} className={classes.tableHeader}>
                  <Typography>
                    {capitalizeFirstLetter(`${intl.messages['selected']}`)}{' '}
                    <b>{selectedVehicles.length}</b>{' '}
                    {getInvestorString(selectedVehicles.length)}{' '}
                    <FormattedMessage id="onTotal" /> <b>{total}</b>{' '}
                    {getInvestorString(total)}
                  </Typography>
                  <Typography className={classes.center}>
                    <Checkbox
                      color="primary"
                      className={classes.checkbox}
                      onChange={e => setOnlySelected(e.currentTarget.checked)}
                    />
                    <FormattedMessage id="showingOnlySelectedInvestors" />
                  </Typography>
                </Grid>
                <Table
                  headers={headers}
                  items={
                    onlySelected
                      ? vehicles.filter(vehicle =>
                          selectedVehicles.includes(vehicle.vehicleId),
                        )
                      : vehicles
                  }
                  renderItem={renderItem}
                  fetchDataForPage={v => setPage(v)}
                  totalElements={total}
                  currentPage={page}
                />
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            onClick={onClose}
            size={'small'}
            color="primary"
            variant="outlined"
          >
            {`${intl.messages['cancel']}`.toUpperCase()}
          </Button>
          <Button style={{backgroundColor:"var(--color-dark-purple)",color:"var(--color-light-grey)"}}
            disabled={isRequestDisabled()}
            onClick={onRequestSign}
            size={'small'}
            variant="contained"
          
          >
            {`${intl.messages['sendRequest']}`.toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <Loading fullScreen />}
    </>
  );
}

export default memo(SignRequest);
