/*
 *
 * ProfileEditDialog reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  default: null,
  loading: false,
};

const profileEditDialogReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SUBMIT_ACTION:
        draft.loading = true;
        break;
      case ActionTypes.UPDATE_SUCCESS_ACTION:
        draft.loading = false;
        break;
      case ActionTypes.UPDATE_FAILED_ACTION:
        draft.loading = false;
        break;
    }
  },
  initialState,
);

export default profileEditDialogReducer;
