import React, { memo, useEffect } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  PropTypes,
} from '@material-ui/core';
import { FormState, initialFormState } from './state';
import CustomFormLabel from './components/form-label';
import CustomHelperText from './components/helper-text';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export interface InputState extends FormState {
  value: string | undefined | null;
}

export const initialInputState: InputState = {
  value: undefined,
  ...initialFormState,
};

interface Props {
  formState: InputState;
  onChange: (ev) => void;
  placeholder?: string;
  type: string;
  hideTitle?: boolean;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth: boolean;
  inputProps?: any;
  disableShowPassword?: boolean;
}

FormInput.defaultProps = {
  type: 'text',
  fullWidth: true,
};

function FormInput(props: Props) {
  const {
    formState,
    onChange,
    type,
    placeholder,
    hideTitle,
    title,
    subtitle,
    margin,
    fullWidth,
    inputProps,
    disableShowPassword,
  } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleInputChange = ev => {
    onChange(ev.target.value);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <CustomFormLabel title={title} subtitle={subtitle} hidden={hideTitle} />

      <OutlinedInput
        placeholder={placeholder}
        value={formState.value}
        onChange={handleInputChange}
        error={formState.error}
        labelWidth={0}
        fullWidth
        type={
          type === 'password'
            ? showPassword
              ? 'text'
              : 'password'
            : type || 'text'
        }
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        endAdornment={
          type === 'password' &&
          !disableShowPassword && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
        inputProps={inputProps}
      />

      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(FormInput);
