import { setIsLoading } from './../Home/actions';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionTypes from './constants';
import { RestResult } from 'models/response/rest-result';
import Company from 'models/company';
import * as actions from './actions';
import { showToast } from 'containers/App/actions';
import { searchUser, changeStatus } from 'services/api/profile-service';

export function* searchDealRequest(action) {
  const request = action.payload;
  yield put(setIsLoading(true));
  try {
    const result: RestResult<Company> = yield call(searchUser, request);
    if (result) {
      yield put(actions.fetchInvestorSuccessAction(result));
    }
  } catch (err) {
    yield put(actions.fetchInvestorFailedAction(err));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

export function* changeStatusRequest(action) {
  const request = action.payload;
  try {
    const result: RestResult<any> = yield call(changeStatus, request);
    if (result) {
      yield put(actions.changeStatusSuccessAction(result));
    }
  } catch (err) {
    yield put(actions.changeStatusFailedAction(err));
  }
}

export default function* investorSaga() {
  yield takeLatest(ActionTypes.FETCH_INVESTOR_ACTION, searchDealRequest);
  yield takeLatest(ActionTypes.CHANGE_STATUS_ACTION, changeStatusRequest);
}
