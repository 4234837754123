/**
 *
 * Img.js
 *
 * Renders an image, enforcing the usage of the alt="" tag
 */

import * as React from 'react';

interface Props {
  src: string | object;
  alt?: string;
  className?: string;
  [prop: string]: any;
}
function Img(props: Props) {
  const { src, className, alt, ...rest } = props;
  return (
    <img
      className={props.className}
      src={props.src as string}
      alt={props.alt}
      {...rest}
    />
  );
}

export default Img;
