import styled from 'styled-components';

export const DealNumberData = styled.p`
  color: #ffffff;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1;
  margin: 16px 0 10px;
  font-family: 'Helvetica', sans-serif;
`;

export const DealNumberDescription = styled.div`
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  line-height: 1.3;
  margin-bottom: 10px;
  min-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  font-family: 'Helvetica', sans-serif;
`;

export const ProgressWrapper = styled.div`
  padding: 20px 0 40px 0;
`;

export const ProgressNumber = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 8px;
  @media (min-width: 900px) {
    font-size: 1.9rem;
  }
`;

export const ProgressDescription = styled.p`
  text-align: center;
  font-size: 0.75rem;
  margin: 0;
  @media (min-width: 900px) {
    font-size: 1rem;
  }
`;

export const DetailWrapper = styled.div`
  border: 1px solid #ededed;
  box-shadow: 1px 2px 4px rgba(57, 60, 73, 0.1);
  height: auto;
  margin-top: 20px;
`;

export const TagWrapper = styled.div`
  padding: 1px 5px;
  margin: 2px;
  background-color: var(--color-dark-purple);
  color: #ffffff;
  font-weight: 500;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  min-height: 18px;
  line-height: normal;
`;

export const BannerDescription = styled.div`
  font-size: 2vw;
  color: rgb(255, 255, 255);
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-align: center;
  text-rendering: optimizelegibility;
  text-shadow: rgba(0, 0, 0, 0.8) 0px 0px 3px;
  -webkit-line-clamp: 2;
  font-family: 'Helvetica', sans-serif;
`;

export default {
  dealInfo: {
    paddingLeft: 28,
    display: 'inline-flex',
    alignItems: 'center',
  },
  adminButton: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 24,
  },
  investorButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 32,
  },
  header: {
    fontWeight: 600,
    fontSize: '0.95rem',
  },
  banner: {
    background: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingTop: '40%',
    },
    dealName: {
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: '0px',
      color: '#FFFFFF',
      fontFamily: 'Helvetica',
    },
    bottom: {
      bottom: 0,
      alignItems: 'center',
      backgroundColor: 'rgba(33, 33, 33, 0.7)',
      width: '100%',
      borderTop: '0.5px solid rgb(136, 136, 136, 0.8)',
      paddingTop: 6,
    },
  },
  detail: {
    link: {},
    title: {
      color: 'var(--color-light-grey)',
      fontWeight: 600,
      fontSize: '1rem',
      marginBottom: '10px',
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    fieldName: {
      color: 'var(--color-grey)',
    },
    fieldValue: {
      fontWeight: 500,
      color: 'var(--color-light-grey)',
      display: 'inline-block !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    download: {
      color: '#588FDB',
      fontSize: '0.8rem',
    },
  },
  mailer: {
    label: {
      marginBottom: 6,
      color: 'rgb(102, 102, 102)',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    form: { padding: '15px 20px 0' },
  },
};
