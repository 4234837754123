// tslint:disable: object-literal-key-quotes

import React, { memo, useState } from 'react';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  makeStyles,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';
import GroupHeader from './GroupHeader';
import GroupTable from './GroupTable';
import SignatureTable from './SignatureTable';
import { SignatureDocument } from '../types';
import { useDispatch } from 'react-redux';
import { requestDownload, requestDownloadSingleFile, requestPreviewSingleFile } from 'containers/App/actions';

const useStyle = makeStyles({
  root: {
    border: '1px solid var(--color-dark-grey)',
    boxShadow: 'none',
    borderRadius: '3px',
    marginBottom: 16,
    '&::before': {
      backgroundColor: 'unset',
    },
    minHeight: 64,
  },
});

interface Props {
  title: string;
  documents?: {
    title: string;
    files: { name: string; url: string; fileId: string }[];
  }[];
  numberOfDocuments: number;
  signatures?: SignatureDocument[];
  dealId?: string;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
}

function Groups(props: Props) {
  const { documents, title, numberOfDocuments, signatures, dealId, expanded, setExpanded } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const handleDownload = (doc, fileIds) => {
    // const request = {
    //   dealId: dealId,
    //   documentId: doc ? doc.documentId : undefined,
    //   fileIds: fileIds ? fileIds : undefined,
    // };
    // dispatch(requestDownloadSingleFile(request));
    dispatch(requestDownloadSingleFile(doc));
  };
  const handlePreview = (id) => {
    dispatch(requestPreviewSingleFile(id));
  };
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12}>
        <Accordion
          className={classes.root}
          onChange={(e, expand) => setExpanded(expand)}
          expanded={expanded}
        >
          <GroupHeader
            title={title}
            documents={numberOfDocuments || 0}
            signature={signatures !== undefined}
            expanded={expanded}
            dealId={dealId}
          />
          <AccordionDetails style={{ padding: '8px 18px 10px' }}>
            {signatures && (
              <SignatureTable signatures={signatures} dealId={dealId} />
            )}
            {documents && (
              <GroupTable
                documents={documents || []}
                onDownload={handleDownload}
                onPreview={handlePreview}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}

export default memo(Groups);
