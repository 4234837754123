/*
 *
 * ProfileTicket reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  default: null,
  tickets: undefined,
};

const profileTicketReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_TICKET_SUCCESS_ACTION:
        draft.tickets = action.payload.data;
        draft.total = action.payload.metaData.total;
        break;
    }
  },
  initialState,
);

export default profileTicketReducer;
