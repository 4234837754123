import React, { memo } from 'react';
import { FormLabel } from '@material-ui/core';

interface Props {
  title?: string;
  subtitle?: string;
  asterisk?: boolean;
  hidden?: boolean;
}

CustomFormLabel.defaultProps = {
  asterisk: false,
};

function CustomFormLabel(props: Props) {
  const { title, subtitle, hidden } = props;
  return (
    <>
      {title && (
        <FormLabel
          style={{
            marginBottom: '5px',
            display: hidden ? 'none' : 'block',
          }}
        >
          {title}
          {subtitle && <span> {subtitle}</span>}
        </FormLabel>
      )}
    </>
  );
}

export default memo(CustomFormLabel);
