/*
 *
 * ResetPassword constants
 *
 */

enum ActionTypes {
  UPDATE_PASSWORD = 'app/ResetPassword/UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCEED = 'app/ResetPassword/UPDATE_PASSWORD_SUCCEED',
  RESET_STATE = 'app/ResetPassword/RESET_STATE',
}

export default ActionTypes;
