import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the registerIdentity state domain
 */

const selectRegisterIdentityDomain = (state: ApplicationRootState) => {
  return state.registerIdentity || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by RegisterIdentity
 */

const makeSelectRegisterIdentity = () =>
  createSelector(selectRegisterIdentityDomain, substate => {
    return substate;
  });

export default makeSelectRegisterIdentity;
export { selectRegisterIdentityDomain };
