// tslint:disable: object-literal-key-quotes

import React, { memo } from 'react';
import {
  Typography,
  Grid,
  ExpansionPanelSummary,
  makeStyles,
  Link,
  AccordionSummary,
} from '@material-ui/core';
import styled from 'styled-components';
import ArrowRight from '@material-ui/icons/ArrowRight';
import NumberLabel from 'components/NumberLabel';
import { useDispatch } from 'react-redux';
import { refreshAllSignatures } from '../actions';

const GroupName = styled(Typography)`
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 1rem;
  font-weight: 500 !important;
  margin-bottom: 0;
`;

interface Props {
  title: string;
  documents: number;
  signature?: boolean;
  expanded?: boolean;
  dealId?: string;
}

const useStyle = makeStyles({
  root: { padding: '0 18px', height: 64 },
  expandIcon: {
    position: 'absolute',
    left: 4,
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    marginLeft: '17px !important',
  },
  expanded: {},
  textButton: {
    fontSize: '0.875rem',
  },
  textButtonDisable: {
    fontSize: '0.875rem',
    color: '#DEDEDE',
  },
});

function GroupHeader(props: Props) {
  const { title, documents, signature, expanded, dealId } = props;
  const classes = useStyle();
  const dispatch = useDispatch();

  const onRefresh = () => {
    dispatch(refreshAllSignatures(dealId));
  };

  return (
    <AccordionSummary
      classes={{
        root: classes.root,
        expandIcon: classes.expandIcon,
        content: classes.content,
        expanded: classes.expanded,
      }}
      expandIcon={<ArrowRight style={{ color: '#588FDB' }} />}
    >
      <Grid
        item
        xs={12}
        sm={8}
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <GroupName>{title}</GroupName>
        {documents > 0 && <NumberLabel label={documents} />}
      </Grid>
      {/* Temporarily hide because of unspecified functionality */}
      {/* <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'grid', justifyContent: 'flex-end' }}
      >
        {signature && (
          <Link
            className={
              expanded ? classes.textButton : classes.textButtonDisable
            }
            onFocus={e => e.stopPropagation()}
            onClick={e => {
              onRefresh();
              e.stopPropagation();
            }}
            underline={expanded ? 'hover' : 'none'}
          >
            Refresh all
          </Link>
        )}
      </Grid> */}
    </AccordionSummary>
  );
}

export default memo(GroupHeader);
