import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
export const DeactivateColorButton = withStyles(() => ({
  root: {
    color: '#888888',
    border: '1px solid #888888',
    '&:hover': {
      color: '#777777',
      border: '1px solid #777777',
    },
    '&:disabled': {
      color: '#DEDEDE',
      border: '1px solid #DEDEDE',
    },
  },
}))(Button);
