/*
 *
 * GuidanceSetting
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles, Link } from '@material-ui/core';

import { base64Decode } from 'utils/helpers';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import GuidanceEditor from './components/GuidanceEditor';
import Loading from 'components/Loading';
import ErrorToast from 'components/ErrorToast';
import SuccessToast from 'components/SuccessToast';
import GetGuidancesQuery from 'gql/guidance/GetGuidances.gql';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const useStyle = makeStyles({
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editText: {
    fontSize: '0.75rem',
    padding: 16,
  },
  content: {
    fontSize: '0.875rem',
    width: '100%',
    height: 'calc(100vh - 64px)',
  },
  fullWidth: {
    width: '100%',
    height: 'calc(100vh - 64px)',
  },
});

function GuidanceSetting() {
  const { currentUser } = useSelector(stateSelector);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(undefined);
  const [isSuccess, setIsSuccess] = useState(undefined);

  const LANGUAGE_CODE = currentUser?.languageCode
    ? currentUser.languageCode
    : 'en';

  const { loading, error, data, refetch } = useQuery(GetGuidancesQuery);

  const guidance = data
    ? data.guidance.filter(item => item.languageCode === LANGUAGE_CODE)[0]
    : '';

  const classes = useStyle();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setIsError(undefined);
    setIsSuccess(undefined);
  }, [open]);

  const onEdit = (isSuccess, message) => {
    setOpen(false);
    refetch();

    if (isSuccess) {
      setIsSuccess(message);
    } else {
      setIsError(message);
    }
  };

  return (
    <Grid container>
      <ErrorToast
        isOpen={Boolean(error || isError)}
        message={error ? error.message : isError || ''}
      />
      <SuccessToast isOpen={Boolean(isSuccess)} message={isSuccess || ''} />
      {!loading && !error && (
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.flexEnd}>
            <Link onClick={() => setOpen(true)} className={classes.editText}>
              <FormattedMessage id="edit" />
            </Link>
          </Grid>
          <div className={classes.fullWidth}>
            <iframe
              className={classes.content}
              id="iframe"
              src={`data:text/html,${encodeURIComponent(
                base64Decode(guidance.description),
              )}`}
            />
          </div>
          <GuidanceEditor
            open={open}
            onCancel={() => setOpen(false)}
            onEdit={onEdit}
            guidances={data.guidance}
          />
          {loading && <Loading fullScreen />}
        </Grid>
      )}
    </Grid>
  );
}

export default memo(GuidanceSetting);
