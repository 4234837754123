import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the investors state domain
 */

const selectInvestorsDomain = (state: ApplicationRootState) => {
  return state.investors || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Investors
 */

const makeSelectInvestors = () =>
  createSelector(selectInvestorsDomain, substate => {
    return substate;
  });

export default makeSelectInvestors;
export { selectInvestorsDomain };
