/*
 *
 * Authentication
 *
 */

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectAuthentication from './selectors';
import {
  makeIsLoggedIn,
  makeSelectCurrentUser,
} from 'containers/App/selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Card,
  CardContent,
  Container,
  Grid,
  OutlinedInput,
  Typography,
  Theme,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import whiteIcon from '../../images/logos/Jadesquare-logo-white.svg';
import { makeStyles } from '@material-ui/core/styles';
import { replace, push } from 'connected-react-router';
import { signIn } from './actions';
import { useIntl, FormattedMessage } from 'react-intl';
import { USER_STATUS, USER_TYPE } from 'utils/constants';
import Img from 'components/Img';
import ArrowButton from 'components/ArrowButton';
import A from 'components/A';
import LanguageDropdown from './components/languageDropdown';
import Loading from '../../components/Loading';
import { Alert } from '@material-ui/lab';
const stateSelector = createStructuredSelector({
  authentication: makeSelectAuthentication(),
  isLoggedIn: makeIsLoggedIn(),
  currentUser: makeSelectCurrentUser(),
});

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    minHeight: '100vh',
  },
  content: {
    backgroundColor: 'transparent',
    padding: '15px',
    color: 'var(--color-grey)',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 60px',
    },
  },
}));

function Authentication() {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'authentication', reducer: reducer });
  useInjectSaga({ key: 'authentication', saga: saga });
  const classes = useStyles();
  const intl = useIntl();
  const { authentication, currentUser } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const { messageError, passwordError, emailError, loading } = authentication;

  useEffect(() => {
    if (currentUser) {
      if (
        currentUser.userType === USER_TYPE.INVESTOR &&
        currentUser.status === USER_STATUS.REGISTERED
      ) {
        dispatch(replace('/register'));
      } else if (currentUser.status === USER_STATUS.INACTIVE) {
        dispatch(replace('/deactivated'));
      } else {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let redirect = params.get('rd');
        const tab = params.get('tab');
        if (redirect) {
          redirect = tab ? `${redirect}?tab=${tab}` : redirect;
          dispatch(replace(decodeURI(redirect)));
        } else {
          dispatch(replace('/deals'));
        }
      }
    }
  }, [currentUser]);

  const handleSignIn = event => {
    event.preventDefault();

    const loginForm = {
      username: event.target.email.value,
      password: event.target.password.value,
    };
    dispatch(signIn(loginForm));
  };

  const handleSignUp = () => {
    dispatch(push('/register'));
  };

  const scope = 'Authentication';
  const isVerificationMessage = location.hash === '#verification';
  return (
    <Container className="main">
      {loading && <Loading fullScreen />}
      <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
        <RouterLink to="/">
          <Img src={whiteIcon} style={{ height: 30 }} />
        </RouterLink>
      </div>
      <Grid container justify={'center'}>
        <Grid item xs={12} md={8} xl={6}>
          <Card variant="outlined" className={classes.content}>
            <CardContent>
              <Grid
                container
                spacing={4}
                wrap={'nowrap'}
                alignItems={'center'}
                style={{ marginBottom: '40px' }}
              >
                <Grid item xs={true}>
                  <Typography
                    variant={'h3'}
                    style={{
                      marginBottom: 0,
                      color: 'white',
                      fontWeight: 400,
                    }}
                  >
                    <FormattedMessage id={`${scope}.welcome`} />
                  </Typography>
                </Grid>
                <Grid item xs={'auto'}>
                  {/* <LanguageDropdown /> */}
                </Grid>
              </Grid>

              <form onSubmit={handleSignIn} style={{ marginBottom: '40px' }}>
                <div style={{ marginBottom: '20px' }}>
                  {isVerificationMessage ? (
                    <p
                      style={{ color: '#00ad1a' }}
                    >{`${intl.messages['verificationConfirm']}`}</p>
                  ) : null}
                  <OutlinedInput
                    placeholder={`${intl.messages[`model.email`]}`}
                    error={emailError}
                    labelWidth={0}
                    fullWidth
                    type={'email'}
                    inputProps={{ id: 'email' }}
                  />
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <OutlinedInput
                    placeholder={`${intl.messages[`model.password`]}`}
                    error={passwordError}
                    labelWidth={0}
                    fullWidth
                    type={'password'}
                    inputProps={{ id: 'password' }}
                  />
                </div>

                <Typography variant={'subtitle2'}>
                  {messageError ? (
                    <Alert severity="error"><FormattedMessage id={`error.${messageError}`} /></Alert>
                  ) : (
                    ''
                  )}
                </Typography>

                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item xs={12} lg={true}>
                    <A
                      style={{ color: 'var(--color-grey)' }}
                      onClick={() => {
                        dispatch(push('/forgot-password'));
                      }}
                    >
                      <FormattedMessage id={`${scope}.forgotPassword`} />
                    </A>
                  </Grid>
                  <Grid item xs={12} lg={'auto'} style={{ textAlign: 'right' }}>
                    <ArrowButton
                      text={`${intl.messages[`${scope}.signIn`]}`}
                      type={'button'}
                      buttonType={'submit'}
                    ></ArrowButton>
                  </Grid>
                </Grid>
              </form>

              <div style={{ textAlign: 'center' }}>
                <FormattedMessage id={`${scope}.newToJadeSquare`} />{' '}
                <A
                  onClick={() => {
                    handleSignUp();
                  }}
                >
                  <FormattedMessage id={`RegisterSignUp.createYourAccount`} />
                </A>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default memo(Authentication);
