// tslint:disable: jsx-wrap-multiline
import React, { memo } from 'react';
import {
  Card,
  Grid,
  Link,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Img from 'components/Img';
import NoAvatar from 'images/not-found.png';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Table from 'components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell15: {
      width: '15%',
    },
    tableCell20: {
      width: '20%',
    },
    tableCell25: {
      width: '25%',
    },
    tableCell30: {
      width: '25%',
    },
    title: {
      color: '#888888',
      fontSize: '0.85rem',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  total: number;
  commits: any[];
  fetchPage: (value: number) => void;
}

function CommitsTable(props: Props) {
  const intl = useIntl();
  const { total, commits, fetchPage } = props;

  const classes = useStyles();

  const headers: any[] = [
    {
      name: `${intl.messages['investor']}`.toUpperCase(),
      headerClass: classes.tableCell25,
    },
    {
      name: `${intl.messages['tagsAndInterests']}`.toUpperCase(),
      headerClass: classes.tableCell30,
    },
    {
      name: `${intl.messages['status']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['tickets']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['averageTicket']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
  ];

  const [page, setPage] = React.useState(1);

  const handleFetchPage = currentPage => {
    setPage(currentPage);
    fetchPage(currentPage);
  };
  const localScope = 'Commits.CommitsTable';
  const renderItem = (item, index) => {
    return [
      <Grid container key={index}>
        <Grid item>
          <Img src={item.picturePath || NoAvatar} style={styles.image} />
        </Grid>
        <Grid item xs>
          <Link
            style={{ color: 'var(--color-purple)', fontWeight: 600 }}
            href={`/investors/${item.userId}`}
          >
            {item.fullName}
          </Link>
          <Typography>{item.email}</Typography>
        </Grid>
      </Grid>,
      <div key={index}>
        {item.preferenceTag.map(val => {
          return (
            <span style={{ ...styles.interests }} key={val}>
              {val}
            </span>
          );
        })}
      </div>,
      <div key={index}>
        <Typography style={styles.investStatus}>
          {item.status.toLowerCase()}
        </Typography>
        {item.activeDate && (
          <Typography>
            {moment(item.activeDate).format('DD-MM-YYYY HH-mm')}
          </Typography>
        )}
      </div>,
      <div key={index}>
        <Typography>
          {item.totalTicketInThisDeal || 0}
          {item.totalTicketInThisDeal === 1
            ? ` ${intl.messages['ticket']}`
            : ` ${intl.messages['tickets']}`}
        </Typography>
        <Typography>
          <NumberFormat
            value={item.raisedAmountInThisDeal || 0}
            decimalScale={2}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
      </div>,
      <Typography key={index}>
        <NumberFormat
          value={item.averageTicketInAllDeal || 0}
          decimalScale={2}
          thousandSeparator={true}
          prefix={'$'}
          displayType={'text'}
        />
      </Typography>,
    ];
  };

  return (
    <div>
      <div>
        <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {total}{' '}
            <FormattedMessage
              id={`${localScope}.matchedInvestors`}
              values={{ num: total }}
            />
          </span>
        </Typography>
      </div>
      <Table
        headers={headers}
        items={commits}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
        currentPage={page}
      />
    </div>
  );
}

export default memo(CommitsTable);
