import React, { memo, useEffect } from 'react';
import { get } from 'lodash/fp';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import UserData from 'models/user';
import Document from 'models/document';
import Attachment from 'components/Attachment';
import { makeSelectCountriesMap, makeSelectCurrentUser, makeSelectPreferences } from 'containers/App/selectors';
import LanguageSelect from 'components/LanguageSelect';
import { FormattedMessage, useIntl } from 'react-intl';
import A from 'components/A';

const stateSelector = createStructuredSelector({
  countriesMap: makeSelectCountriesMap(),
  currentUser: makeSelectCurrentUser(),
  preferences: makeSelectPreferences()
});

const useStyles = makeStyles(() => ({
  root: { marginBottom: '15px' },
  title: {
    marginBottom: 0,
  },
  header: {
    marginTop: 12,
    color: '#FFFFFF',
    fontWeight: 600,
  },
  textLabel: {
    color: 'var(--color-grey)',
    fontWeight: 400,
    margin: 0,
  },
  body: {
    color: '#FFFFFF',
    fontWeight: 600,
    margin: 0,
  },
  rowContainer: {
    paddingTop: 3,
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: 'var(--color-purple)',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 3,
    display: 'inline-block',
    fontSize: 12
  },
}));
interface Props {
  investor: UserData;
  onEdit: () => void;
  updateLocale;
  scope: string;
  showLangSelect: boolean;
}

function Detail(props: Props) {
  const intl = useIntl();
  const { investor, onEdit, updateLocale, scope, showLangSelect } = props;
  const classes = useStyles();
  const [identityDocument, setIdentityDocument] = React.useState<Document>();
  const [residentDocument, setResidentDocument] = React.useState<Document>();
  const [userPreferences, setUserPreferences] = React.useState<Array<string>>();
  const { countriesMap, currentUser, preferences } = useSelector(stateSelector);
  const handleEditProfile = () => {
    onEdit();
  };
  useEffect(() => {
    if (investor && investor['documentGroups']) {
      const defaultGroup = investor['documentGroups'].filter(
        item => item.isDefault,
      );
      if (
        defaultGroup &&
        defaultGroup.length > 0 &&
        defaultGroup[0].documents
      ) {
        defaultGroup[0].documents.forEach(item => {
          if (item.type === 'KYC_DOCUMENT_ID') {
            setIdentityDocument(item);
          } else if (item.type === 'KYC_RESIDENCY_CERTIFICATE') {
            setResidentDocument(item);
          }
        });
      }
    }
  }, [investor]);

  useEffect(() => {
    let userPreferences = [];
    if(preferences && investor.preferenceIdTags) {
      let preferenceList = []
      Object.keys(preferences).forEach(e => {
        preferenceList = [...preferenceList, ...preferences[e]];
      });
      userPreferences = preferenceList.filter(e => investor.preferenceIdTags?.includes(e.preferenceTagId)).map(e => e.name)
    }
    
    setUserPreferences(userPreferences??[]);
  }, [investor, preferences]);

  const localScope = `${scope}.details`;

  const lang = currentUser ? currentUser.languageCode : 'en';
  return (
    <div>
      <Grid container alignItems="center" style={{ paddingBottom: 10 }}>
        <Grid item xs={true}>
          <Typography variant="h4" className={classes.title}>
            <FormattedMessage id={`details`} />
          </Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <A
            style={{ fontSize: '12px' }}
            textDecoration="none"
            onClick={handleEditProfile}
          >
            <FormattedMessage id={`edit`} />
          </A>
        </Grid>
      </Grid>

      <Card variant="outlined" className={classes.root}>
        <Grid container direction={'column'}>
          {investor.userPreferenceTags && (
            <Grid item className={classes.header}>
              <FormattedMessage id={`${localScope}.INTERESTS`} />
              {/* <CustomGridIteam style={{ paddingLeft: 17, paddingTop: 3 }}>
            {map(investor.userPreferenceTags, preferenceTag =>
              preferenceTag.map(tag => <Tag key={tag.name}>{tag.name}</Tag>),
            )}
          </CustomGridIteam> */}
            </Grid>
          )}
          <Grid item className={classes.header} style={{ marginTop: 0 }}>
            <FormattedMessage id={`${localScope}.ACCOUNT`} />
          </Grid>
          <Grid item>
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.email`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {investor.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.phone`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {investor.phone}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.fullName`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {investor.fullName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.header}>
            <FormattedMessage id={`${localScope}.IDENTITY`} />
          </Grid>
          <Grid item>
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.birthDate`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {investor.birthday}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.nationality`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {get('nationality')(countriesMap[investor.nationality])}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.individualDocNumber`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {investor.individualDocType} - {investor.individualDocNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          { identityDocument && identityDocument.files && identityDocument.files.length > 0 &&
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.files`} />
                </Typography>
                {identityDocument.files.map((file: FileResponse) => {
                    return (
                      <Attachment
                        key={`${file.fileId}`}
                        name={file.name}
                        fileId={`${file.fileId}`}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          }
          <Grid item className={classes.header}>
            {`${intl.messages[`model.documentGroups.identityDocument`]}`}
          </Grid>
          <Grid item xs>
            <Grid container className={classes.rowContainer}>
              <Grid item xs style={{ minWidth: 100 }}>
                <Typography className={classes.textLabel}>
                  <FormattedMessage id={`model.address`} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.body}>
                  {`${investor.address1 || ''}  ${investor.address2 || ''}`}
                </Typography>
              </Grid>
            </Grid>
            {residentDocument && residentDocument.files && residentDocument.files.length > 0 &&
            <Grid container className={classes.rowContainer}>
              <Grid item xs>
                <Typography className={classes.textLabel}>
                  <FormattedMessage
                    id={`model.documentGroups.residentDocument`}
                  />
                </Typography>
                {residentDocument.files.map((file: FileResponse) => {
                    return (
                      <Attachment
                        key={`${file.fileId}`}
                        name={file.name}
                        fileId={`${file.fileId}`}
                      />
                    );
                  })}
              </Grid>
            </Grid>
            }
          </Grid>
          <Grid item className={classes.header}>
            {`${intl.messages[`tagsAndInterests`]}`}
          </Grid>
          <Grid item xs>
            {userPreferences?.map(val => {
              return (
                <span className={classes.interestTag} key={val}>
                  {val}
                </span>
              );
            })}
            { !userPreferences || !userPreferences.length &&
              <Typography className={classes.textLabel}>
                {`${intl.messages[`noInterestsAdded`]}`}
              </Typography>
            }
          </Grid>
        </Grid>
      </Card>
      {/* {showLangSelect ? (
        <div>
          <Typography
            variant="h4"
            className={classes.title}
            style={{ paddingBottom: 10 }}
          >
            <FormattedMessage id={`${localScope}.language`} />
          </Typography>
          <Card variant="outlined" className={classes.root}>
            <LanguageSelect
              lang={lang}
              updateLocale={updateLocale}
              row={false}
            />
          </Card>
        </div>
      ) : (
        ''
      )} */}
    </div>
  );
}

export default memo(Detail);
