import React, { memo } from 'react';
import BannerHighlight from 'models/bannerHighlight';
import {
  Grid,
  Button,
  FormControl as MaterialFormControl,
  FormLabel,
  OutlinedInput,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  onChangeHighlight: (
    field: string,
    index: number,
    languageCode: string,
  ) => any;
  onRemoveForm: (index: number, languageCode: string) => any;
  addNewHighlight: (languageCode: string) => any;
  highlights?: BannerHighlight[];
  languageCode: string;
}

function BannerEditorHighlights(props: Props) {
  const intl = useIntl();
  const {
    onChangeHighlight,
    onRemoveForm,
    addNewHighlight,
    highlights,
    languageCode,
  } = props;

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ marginTop: 10, fontSize: '0.875rem' }}
      >
        <FormattedMessage id="bannerHighlights" />
        {languageCode !== 'en' && (
          <>
            {' '}
            <FormattedMessage id={`${languageCode}Abbr`} />
          </>
        )}
      </Grid>
      {highlights?.map((highlight, index) => {
        return (
          <>
            <Grid item xs={12} sm={5} key={index}>
              <MaterialFormControl fullWidth>
                <FormLabel style={{ marginBottom: '5px' }}>
                  <FormattedMessage id="highlightTitle" />
                </FormLabel>
                <OutlinedInput
                  value={highlight.title}
                  onChange={onChangeHighlight('title', index, languageCode)}
                />
              </MaterialFormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <MaterialFormControl fullWidth>
                <FormLabel style={{ marginBottom: '5px' }}>
                  <FormattedMessage id="highlightSummary" />
                </FormLabel>
                <OutlinedInput
                  value={highlight.summary}
                  onChange={onChangeHighlight('summary', index, languageCode)}
                />
              </MaterialFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                display: 'flex',
                paddingTop: 25,
              }}
            >
              <Button
                size={'small'}
                color="primary"
                variant="outlined"
                onClick={onRemoveForm(index, languageCode)}
              >
                {`${intl.messages['delete']}`.toUpperCase()}
              </Button>
            </Grid>
          </>
        );
      })}
      <Grid item xs={12} sm={12}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => addNewHighlight(languageCode)}
        >
          {`${intl.messages['add']}`.toUpperCase()}
        </Button>
      </Grid>
    </>
  );
}

export default memo(BannerEditorHighlights);
