import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the ticketCreate state domain
 */

const selectTicketCreateDomain = (state: ApplicationRootState) => {
  return state.ticketCreate || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by TicketCreate
 */

const makeSelectTicketCreate = () =>
  createSelector(selectTicketCreateDomain, substate => {
    return substate;
  });

export default makeSelectTicketCreate;
export { selectTicketCreateDomain };
