import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { debounce } from 'lodash';
import styles from './styles';

interface Props {
  fullScreen?: boolean;
}

const renderFullScreenLoader = ref => {
  return (
    <div
      style={{ ...styles.fullScreenWrapper, position: 'fixed' }}
      id="img-loading"
      data-testid="img-loading"
      ref={ref}
      role="button"
      tabIndex={0}
    >
      <CircularProgress
        disableShrink
        style={styles.fullScreenProgress}
        size={24}
        thickness={4}
      />
    </div>
  );
};

const renderInlineLoader = () => {
  return (
    <div style={styles.wrapper} id="img-loading" data-testid="img-loading">
      <CircularProgress
        disableShrink
        style={styles.progress}
        size={24}
        thickness={4}
      />
    </div>
  );
};

const CircularIndeterminate = (props: Props) => {
  const { fullScreen } = props;
  const wrapperElement = React.createRef<any>();

  debounce(() => {
    if (wrapperElement && wrapperElement.current && fullScreen) {
      wrapperElement.current.focus();
    }
  }, 0)();

  return fullScreen
    ? renderFullScreenLoader(wrapperElement)
    : renderInlineLoader();
};

CircularIndeterminate.defaultProps = {
  fullScreen: false,
};

export default memo(CircularIndeterminate);
