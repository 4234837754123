import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import FormControl from 'components/FormControl';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  onChangeTitle: (any) => void;
  onChangeSummary: (any) => void;
  title: string;
  summary: string;
  languageCode: string;
}

function BannerEditorFields(props: Props) {
  const intl = useIntl();
  const {
    onChangeTitle,
    onChangeSummary,
    title,
    summary,
    languageCode,
  } = props;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl
          type="input"
          onChange={onChangeTitle}
          title={`${intl.messages['bannerTitle']} ${
            languageCode !== 'en' ? intl.messages[`${languageCode}Abbr`] : ''
          }`}
          defaultValue={title}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          type="input"
          onChange={onChangeSummary}
          title={`${intl.messages['summary']} ${
            languageCode !== 'en' ? intl.messages[`${languageCode}Abbr`] : ''
          }`}
          defaultValue={summary}
          inputProps={{ maxLength: 150 }}
        />
      </Grid>
    </>
  );
}

export default memo(BannerEditorFields);
