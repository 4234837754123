import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the documentSettings state domain
 */

const selectDocumentSettingsDomain = (state: ApplicationRootState) => {
  return state.documentSettings || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by DocumentSettings
 */

const makeSelectDocumentSettings = () =>
  createSelector(selectDocumentSettingsDomain, substate => {
    return substate;
  });

export default makeSelectDocumentSettings;
export { selectDocumentSettingsDomain };
