import { call, put, takeLatest } from 'redux-saga/effects';
import { signIn } from 'services/api/auth-service';
import * as actions from './actions';
import { setUserData, loginSuccess } from 'containers/App/actions';
import AuthRequest from 'models/request/auth-request';
import { AuthResponse } from 'models/response/auth-response';
import ActionTypes from './constants';
import {
  OAUTH_TOKEN,
  REFRESH_TOKEN,
  set,
} from '../../services/local-storage-service';
import { me } from 'services/api/profile-service';
import { getUserPreference } from 'services/api/preference-service';
import { values, flatten } from 'lodash';
export function* requestFetchProfile() {
  try {
    const result = yield call(me);
    const userPreferences = yield call(getUserPreference);
    result.userPreferenceTags = flatten(values(userPreferences));
    yield put(setUserData(result));
  } catch (err) {}
}

export function* requestSignIn(action) {
  const request: AuthRequest = {
    grantType: 'password',
    username: action.payload.username.trim(),
    password: action.payload.password,
  };
  const error = {
    emailError: false,
    passwordError: false,
    messageError: 'unexpectedError',
  };

  try {
    if (!request.username && !request.password) {
      error.emailError = true;
      error.passwordError = true;
      error.messageError = 'requireEmailAndPassword';
    } else if (!request.username) {
      error.emailError = true;
      error.messageError = 'emailRequired';
    } else if (!request.password) {
      error.passwordError = true;
      error.messageError = 'passwordRequired';
    }
    if (error.emailError || error.passwordError) {
      yield put(actions.signInError(error));
    } else {
      const result: AuthResponse = yield call(signIn, request);
      set(OAUTH_TOKEN, result.accessToken);
      set(REFRESH_TOKEN, result.refreshToken);
      yield requestFetchProfile();
      yield put(loginSuccess());
    }
  } catch (err) {
    if (err.response && err.response.status === 400) {
      error.emailError = true;
      error.passwordError = true;
      error.messageError = 'incorrectEmailOrPassword';
    } else if (err.response && err.response.status === 401) {
      error.messageError = 'verificationRequired';
    } else {
      error.messageError = 'unexpectedError';
    }
    yield put(actions.signInError(error));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(ActionTypes.SIGN_IN, requestSignIn);
}
