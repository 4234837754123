import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Container } from '@material-ui/core';
import SearchInput from 'components/SearchInput';
import Filter from './components/Filter';
import CommitsTable from './components/CommitsTable';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectPreferences } from 'containers/App/selectors';
import { searchCommits } from './actions';
import makeSelectCommits from './selectors';
import reducer from './reducer';
import saga from './saga';
import { PAGE_SIZE } from 'utils/constants';

const stateSelector = createStructuredSelector({
  commits: makeSelectCommits(),
  preferences: makeSelectPreferences(),
});

interface Props {
  dealId: string;
}

function Commits(props: Props) {
  useInjectReducer({ key: 'commits', reducer: reducer });
  useInjectSaga({ key: 'commits', saga: saga });

  const { dealId } = props;
  const { commits, preferences } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const { total } = commits;
  const commitList = commits.commits;

  const [searchKey, setSearchKey] = useState('');
  const [page, setPage] = useState(1);
  const [tags, setTags] = useState<any[]>([]);
  const [status, setStatus] = useState('All');

  const onSelectTags = tagIds => {
    setTags(tagIds);
    setPage(1);
  };

  const onSelectStatus = value => {
    setStatus(value);
    setPage(1);
  };

  const requestGetCommits = () => {
    dispatch(
      searchCommits({
        dealId,
        pageSize: PAGE_SIZE,
        searchKey,
        interestsAny: tags.length ? tags : undefined,
        status: status !== 'All' ? status : undefined,
        page: page - 1,
      }),
    );
  };

  useEffect(() => {
    requestGetCommits();
  }, [searchKey, tags, status, page]);

  return (
    <Container className="main"> 
      <div style={{ marginBottom: '15px' }}>
        <SearchInput
          onChange={v => {
            setSearchKey(v);
            setPage(1);
          }}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Filter
          status={status}
          tags={tags}
          tagsOption={preferences}
          onSelectTags={onSelectTags}
          onSelectStatus={onSelectStatus}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CommitsTable total={total} commits={commitList} fetchPage={setPage} />
      </div>
    </Container>
  );
}

export default memo(Commits);
