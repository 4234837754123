import { Card, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import NumberFormat from 'react-number-format';

type Props = {
  summary: {
    individual: {
      amount: number;
      count: number;
      percent: number;
    };
    corporate: {
      amount: number;
      count: number;
      percent: number;
    };
  };
};

const useStyle = makeStyles(() => ({
  card: {
    marginBottom: '1rem',
  },
  title: {
    fontSize: '0.875rem',
  },
  subTitle: {
    margin: 0,
  },
  text: {
    fontSize: '0.875rem',
    color: 'var(--color-grey)',
  },
}));

const TicketCard = (props: Props) => {
  const { summary } = props;
  const classes = useStyle();
  const intl = useIntl();
  return (
    <Card className={classes.card}>
      <h4 className={classes.title}>Tickets Owned</h4>
      <Grid container>
        <Grid item xs={6}>
          <div>
            <h5 className={classes.subTitle}>
              <NumberFormat
                value={summary.individual.amount}
                thousandSeparator={true}
                prefix={'$'}
                displayType={'text'}
              />
            </h5>
            <p className={classes.text}>
              {`${summary.individual.count} ${(intl.messages[
                'tickets'
              ] as string).toLowerCase()} (${summary.individual.percent}%)`}
            </p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ textAlign: 'right' }}>
            <p className={classes.text}>
              <FormattedMessage id="individualVehicle" />
            </p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <h5 className={classes.subTitle}>
              <NumberFormat
                value={summary.corporate.amount}
                thousandSeparator={true}
                prefix={'$'}
                displayType={'text'}
              />
            </h5>
            <p className={classes.text}>{`${summary.corporate.count} ${(intl
              .messages['tickets'] as string).toLowerCase()} (${
              summary.corporate.percent
            }%)`}</p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ textAlign: 'right' }}>
            <p className={classes.text}>
              <FormattedMessage id="corporate" />
            </p>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TicketCard;
