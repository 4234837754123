/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
    },
    subTitle: {
      textTransform: 'uppercase',
      color: '#000',
      fontSize: 10,
    },
    button: {
      textTransform: 'none',
    },
    title: {
      color: '#888888',
      fontSize: '0.85rem',
      
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  noOfDeal: number;
  companyId: string;
  addNewDeal: (companyId: string) => void;
}

function DealHeader(props: Props) {
  const { noOfDeal, companyId, addNewDeal } = props;
  const classes = useStyles();
  const localScope = 'CompanyDeals.header';

  return (
    <Grid container className={classes.root}>
      <Grid item xs>
        <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {noOfDeal}{' '}
            <FormattedMessage
              id={`${localScope}.deal`}
              values={{ num: noOfDeal }}
            />
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          style={{ whiteSpace: 'nowrap' }}
          color="primary"
          onClick={() => addNewDeal(companyId)}
        >
          <FormattedMessage id="addNewDeal" />
        </Button>
      </Grid>
    </Grid>
  );
}

export default memo(DealHeader);
