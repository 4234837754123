/*
 *
 * TicketCreate actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const submitAction = payload =>
  action(ActionTypes.SUBMIT_ACTION, payload);
export const submitSuccessAction = payload =>
  action(ActionTypes.SUBMIT_SUCCESS_ACTION, payload);
export const submitFailedAction = () =>
  action(ActionTypes.SUBMIT_FAILED_ACTION);
export const clear = () => action(ActionTypes.CLEAR_ACTION);
export const validateTicket = payload =>
  action(ActionTypes.VALIDATE_TICKET, payload);
export const fetchUserVehicleAction = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_ACTION, payload);
export const fetchUserVehicleSuccessAction = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_SUCCESS_ACTION, payload);
export const fetchUserVehicleFailedAction = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_FAILED_ACTION, payload);
