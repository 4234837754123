import React, { memo } from 'react';
import { Typography, Grid, Link, Divider } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as DownloadSvg } from 'images/icons/with-class/download.svg';
interface Props {
  documents: {
    title: string;
    files: { name: string; url: string; fileId: string, title?: string }[];
  }[];
  onDownload: (doc, fileIds) => void;
  onPreview: (id) => void;
}

const style = {
  header: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '10px',
    color: '#888888',
  },
  docName: {
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  files: {
    display: 'grid',
  },
  file: {
    fontWeight: 300,
    fontSize: '0.8rem',
  },
  downloadIcon: {
    fontWeight: 300,
    fontSize: '0.8rem',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  divider: {
    margin: '10px 0px 0px',
    background: '#F6F6F6',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.8rem',
  },
};

function GroupTable(props: Props) {
  const intl = useIntl();
  const { documents, onDownload, onPreview } = props;
  const handleExport = doc => {
    onDownload(doc, undefined);
  };
  const handleExportFiles = fileIds => {
    onDownload(undefined, fileIds);
  };
  const handlePreviewFile = (id) => {
    onPreview(id);
  };
  return (
    <Grid container>
      <Grid item xs={4} sm={4} style={{ paddingLeft: 4 }}>
        <Typography style={style.header}>
          {`${intl.messages['name']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={4} style={{ paddingLeft: 4 }}>
        <Typography style={style.header}>
          {`${intl.messages['file']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography>{''}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider style={style.divider} />
      </Grid>
      {documents
        .filter(doc => doc.files.length > 0)
        .map((doc, i) => (
          <Grid
            item
            xs={12}
            sm={12}
            container
            key={i}
            style={{ margin: '10px 0' }}
            spacing={2}
          >
            <Grid item xs={4} sm={4}>
              <Typography style={style.docName}>{doc.title}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} style={style.files}>
              {doc.files.map((file, index) => (
                <div key={index}>
                  <Link
                    style={style.file}
                    onClick={() => handlePreviewFile(file.fileId)}
                  >
                    {file.title || file.name}
                  </Link>
                  <Link style={style.downloadIcon} onClick={() => handleExport(file.fileId)}><DownloadSvg /></Link>
                </div>
              ))}
            </Grid>
            <Grid
              item
              container
              xs={4}
              sm={4}
              style={{
                display: 'grid',
                justifyContent: 'flex-end',
                height: 48,
                paddingRight: 0,
              }}
            >
              <div style={style.button}>
                <Link onClick={ev => handleExport(doc.files[0].fileId)}>
                  <FormattedMessage id="export" />
                </Link>
              </div>
            </Grid>
            {i < documents.length - 1 && (
              <Grid item xs={12} sm={12}>
                <Divider style={style.divider} />
              </Grid>
            )}
          </Grid>
        ))}
      {!documents.filter(doc => doc.files.length > 0).length && (
        <Typography
          style={{ textAlign: 'center', padding: '20px 0', width: '100%' }}
        >
          <FormattedMessage id="noDocumentsToDisplay" />
        </Typography>
      )}
    </Grid>
  );
}

export default memo(GroupTable);
