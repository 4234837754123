import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useIntl } from "react-intl";
import { Button, Container, Grid } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		displayDesktop: {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "block",
			},
		},
		userBar: {
			backgroundColor: "#191919",
			color: "white",
			padding: "8px 0",
			cursor: "pointer",
		},
		icon: {
			width: "22px",
			height: "22px",
			borderRadius: "50%",
			overflow: "hidden",
			backgroundSize: "cover",
			backgroundPosition: "center center",
		},
		name: {
			fontSize: "15px",
			fontWeight: 600,
			paddingRight: "20px",
		},
		status: {
			border: "1px solid transparent",
			borderRadius: "5px",
			background: "transparent",
			display: "inline-block",
			padding: "1px 5px",
			fontSize: "10px",
			fontWeight: 500,
			verticalAlign: "middle",
			"&.active": {
				color: "var(--color-cyan)",
				borderColor: "var(--color-cyan)",
			},
			"&.inactive": {
				color: "red",
				borderColor: "red",
			},
		},
	})
);

interface Props {
	status: string;
	name: string;
	image?: string;
	isAdmin: boolean;
}

function UserBar(props: Props) {
	const intl = useIntl();
	const classes = useStyles();
	const { name, status, image, isAdmin } = props;
	const dispatch = useDispatch();

	const redirect = (url) => {
		dispatch(push(url));
	};

	const userBarClickHandler = () => {
		if (isAdmin) return;
		redirect("/me");
	};

	const defaultUserImage =
		"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARwSURBVHgBfVVLbBtVFD0z4xnPTGzHn9hpEjcdB9KkKikkbZwGENhEAgQsqIRgGVh0hVDLrotKTTaIDRLdwYY2O5CCivgUEIImRSiAFNyk4aMUNUOTkjhu7HEc/2MPdyZ26sSmV5p54/fuO+/ec8+7ZvA/FlnSnWUJr7MMnmYYPEZTCo0AA5UGlb4mSkVM9bcxaqP9TCNAyDijA2dp0Wl4sDSfzmQgWAUIvAWVA6oIY30uZvyBwJFVXdEZXNuNjuyPm3OY/uoKrn/9OSSbHb3HBnD63Hl4vR5zt66bbmoJCPe77kfPPgj0k4mP8NZrL+HjSx9CS9wDsikszf2KHz6dNAHNwBnzUTjaG0noSh3wftClv2/h4jvnkS8W4BYF9HW0Y7BLwfPH+6EtziNxL44qI5VH4WCCO439FjPaqH6BIlBqafn+yyvI5YuwCzxCjxzFyMBxDARPwOHxIrWt4+btRbT4ToJldoDLuhmcQjhn6ecYO0MUlMoY07HXMpksZCpUW7MNVpYFb+GRTKShsyK8bX70dvfsgpqpG0Wm/ImSM0bUrFBCiGHq5dEVOAS/04HOFi8KZeCbyDw+mJxEIpnCT9PXkU5s3FfADs8mr3SAkwfJtMxh1CiEXnGoOgaIgqGDHbASWX6PB6+EQ+iwSVj67We4JAkOm7wLWGvmHIuQxSB9/4Jh7q5uiAwHWbBSNgy8TU64RAkcL6KnvQ0FRzMyqAc2IqSoH2WNotXqvQruPxFE77MvIJ3dgk3kcXXqKtTVNdibHYhrGkpUqTrQasQULFtNZ8/D7pz08OBJbKUz2KYiHmhpx1NPBNF5+CgsDisKkoj9ekMNlQaECjQ+2dd9GBaOxy+RWUh2GYpyBLLvAPRWP8CzNc7Y+039hKXXDV3fC1i1MunQ6XBiKbqOL659h+jGOmL/LKDc2mpGxFQkxtaMFWyVZcqYrlUDaiq9vPgXHM12DPf1Yyj4OFZiK8hINvAul+ljqUtzZy/1jQmWzeMyTWi1C9XvmZlZGNVt9frA0URbcATy4JNIkRzSecqoXK9/cz+HKUt/gNFmV/WLVLALhlQMWlbuxjG/sIi0fAibFjd4SHCPnIYv2IPYpo54vGT6FbN5dB6U4ZCBpqada00Y40dkRjWzoVv6vraRHb2zHFNuq3dRKmwjuZnGyr/LWNVyCL78DJp9ftxa1qGX8rDbBBTyeaytbcAqssg6RfgFUK+G+pDMjJmYxov6qPbtj3PhO9GsmkzlkKMmwwoSPCSx4RdfhbPnOaRzLHLZDFJbOWTzZaxFE+SXg1awUAfUDYmSEhCuUrKrmXffGFZtVmuY41iVszaBp1smyjKODYURjcYINI1kMolcLkPjFixWGTonw7gIkpVRs0WEA1KDRm/Y26cC6ntvhgIcz48LFLHd0YIEUZFKJZAl4O3tosmthWPp4mQRXY9p5ZI+vklJ1/57VGrf2M5diijUoUKMIIxq8Q3F7WlRRFGkIjWpbpftxsLv6vTi8p+XPxs7pTXa/x/Nc5dzwnS2GQAAAABJRU5ErkJggg==";

	return (
		<div style={{ cursor: `${isAdmin ? "initial" : "pointer"}` }} className={classes.userBar} tabIndex={0} onClick={userBarClickHandler}>
			<Container>
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs={12} md={"auto"}>
						<Grid container alignItems="center" spacing={2} wrap="nowrap">
							{image && (
								<Grid item xs={"auto"}>
									<div
										className={classes.icon}
										style={{
											backgroundImage: `url('${image}')`,
										}}
									></div>
								</Grid>
							)}

							<Grid item xs={"auto"}>
								<div className={classes.name}>{name}</div>
							</Grid>
							<Grid item xs={"auto"}>
								<div className={`${classes.status} ${status.toLowerCase()}`}>{`${!isAdmin ? intl.messages[status.toLowerCase()] : intl.messages["admin"]}`}</div>
							</Grid>
						</Grid>
					</Grid>
					{isAdmin && (
						<Grid className={classes.displayDesktop} item xs={12} md={true}>
							<Grid container spacing={2} justify="flex-end" alignItems="center">
								<Grid item>
									<Button onClick={() => dispatch(push("/settings/document"))} variant="contained">{`${intl.messages["documentSettings"]}`}</Button>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Container>
		</div>
	);
}

export default memo(UserBar);
