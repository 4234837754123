/*
 *
 * ProfileDeal
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectProfileDeal from './selectors';
import reducer from './reducer';
import saga from './saga';
import vehicleReducer from 'containers/ProfileVehicles/reducer';
import vehicleSaga from 'containers/ProfileVehicles/saga';
import UserData from 'models/user';
import { fetchDealAction } from './actions';
import DealTable from './components/dealTable';
import { PAGE_SIZE } from 'utils/constants';
import makeSelectProfileVehicles from 'containers/ProfileVehicles/selectors';
import Vehicle from 'models/vehicle';
import { fetchUserVehicleAction } from 'containers/ProfileVehicles/actions';
const stateSelector = createStructuredSelector({
  profileDeal: makeSelectProfileDeal(),
  profileVehicles: makeSelectProfileVehicles(),
});

interface Props {
  investor: UserData;
}

function ProfileDeal(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'profileDeal', reducer: reducer });
  useInjectSaga({ key: 'profileDeal', saga: saga });
  useInjectReducer({ key: 'profileVehicles', reducer: vehicleReducer });
  useInjectSaga({ key: 'profileVehicles', saga: vehicleSaga });
  const { profileDeal, profileVehicles } = useSelector(stateSelector);
  const [vehicleList, setVehicleList] = React.useState<Vehicle[]>([]);
  const { vehicles, individualVehicle } = profileVehicles;
  const { deals, total } = profileDeal;
  const dispatch = useDispatch();
  const { investor } = props;
  useEffect(() => {
    if (investor) {
      dispatch(
        fetchDealAction({
          investorId: investor.userId,
          page: 0,
          pageSize: PAGE_SIZE,
        }),
      );
      dispatch(fetchUserVehicleAction(investor.userId));
    }
  }, [investor]);
  const fetchPage = page => {
    dispatch(
      fetchDealAction({
        investorId: investor.userId,
        page: page,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  useEffect(() => {
    setVehicleList(() => {
      if (individualVehicle && vehicles) {
        return [individualVehicle, ...vehicles];
      } else if (!vehicles && individualVehicle) {
        return [individualVehicle];
      }
      return vehicles;
    });
  }, [vehicles, individualVehicle]);

  return (
    <DealTable
      items={deals}
      total={total}
      fetchPage={fetchPage}
      vehicleList={vehicleList}
      investorId={investor.userId}
    />
  );
}

export default ProfileDeal;
