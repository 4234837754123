import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import GetFilePermissionStatus from '../../../gql/deal/GetFilePermissionStatus.gql';
import { useQuery } from '@apollo/react-hooks';
import { requestDownloadSingleFile } from 'containers/App/actions';
import { useDispatch } from 'react-redux';
import { requestFileAccess } from './../../../services/api/deal-service';
import { useIntl } from 'react-intl';
import { setIsLoading } from 'containers/Home/actions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dealId: string;
  isAdmin: boolean;
}

const RequestAccessModal = (props: Props) => {
  const dispatch = useDispatch();
  const { data, loading: isLoading, error, refetch } = useQuery(
    GetFilePermissionStatus,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      variables: {
        dealId: props.dealId,
      },
    },
  );
  useEffect(() => {
    if (props.isOpen) {
      refetch();
    }
  }, [props.isOpen]);
  useEffect(() => {
    dispatch(setIsLoading(isLoading));
  }, [isLoading]);

  const getFiles = dealData => {
    const documentGroups = dealData.document_groups;

    return documentGroups
      .map(elem => elem.documentGroup)
      .filter(docGroup => {
        return docGroup.documents.length > 0;
      })
      .map(docGroup => docGroup.documents)
      .filter(documents => documents[0].document.files.length > 0)
      .map(doc => {
        return {
          name: doc[0].document.title,
          id: doc[0].document.files[0].file.id,
        };
      });
  };
  const [files, setFiles] = useState([]);
  const [grantedInvestors, setGrantedInvestors] = useState([]);
  useEffect(() => {
    if (data) {
      if (data.deal_investor.length > 0) {
        const files = getFiles(data.deal_investor[0].deal);
        setFiles(files);
      }

      setGrantedInvestors(
        data.deal_investor.filter(
          investor => investor.file_access_status === 'GRANTED',
        ),
      );
    }
  }, [data]);

  const downloadFile = fileId => {
    dispatch(requestDownloadSingleFile(fileId));
  };
  const intl = useIntl();
  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      {isLoading ? (
        <p>Loading...</p>
      ) : !props.isAdmin ? (
        <>
          {data &&
            data.deal_investor[0] &&
            data.deal_investor[0].file_access_status === 'NOT_REQUESTED' && (
              <DialogTitle>
                {`${intl.messages['AccessRequest.WouldYou']}`}
              </DialogTitle>
            )}
          {data &&
            data.deal_investor[0] &&
            data.deal_investor[0].file_access_status === 'REQUESTED' && (
              <DialogTitle>
                {`${intl.messages['AccessRequest.alreadyRequestedTitle']}`}
              </DialogTitle>
            )}
          {data &&
            data.deal_investor[0] &&
            data.deal_investor[0].file_access_status === 'GRANTED' && (
              <DialogTitle>{`${intl.messages['AccessRequest.requestGrantedTitle']}`}</DialogTitle>
            )}
          {data &&
            data.deal_investor[0] &&
            data.deal_investor[0].file_access_status === 'REJECTED' && (
              <DialogTitle>{`${intl.messages['AccessRequest.requestRejectedTitle']}`}</DialogTitle>
            )}
          <DialogContent>
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'GRANTED' &&
              files && (
                <>
                  <p style={{ fontSize: '14px' }}>
                    {`${intl.messages['AccessRequest.requestGrantedDesc']}`}{' '}
                    <a
                      style={{ color: 'var(--color-purple)' }}
                      href="mailto:info@jadesquare.com"
                    >
                      info@jadesquare.com
                    </a>
                  </p>
                  <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                    {files.length > 0 ? (
                      files.map(file => {
                        return (
                          <li style={{ fontSize: '14px' }}>
                            <a
                              style={{ color: 'var(--color-purple)' }}
                              onClick={() => {
                                downloadFile(file.id);
                              }}
                            >
                              {file.name}
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <li style={{ fontSize: '14px' }}>
                        {`${intl.messages['AccessRequest.noFiles']}`}{' '}
                      </li>
                    )}
                  </ul>
                </>
              )}
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'NOT_REQUESTED' && (
                <p>{`${intl.messages['AccessRequest.notRequestedDesc']}`}</p>
              )}
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'REQUESTED' && (
                <p>
                  {`${intl.messages['AccessRequest.requestedDesc']}`}{' '}
                  <a href="mailto:info@jadesquare.com">info@jadesquare.com</a>
                </p>
              )}
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'REJECTED' && (
                <p>
                  {`${intl.messages['AccessRequest.rejectedDesc']}`}{' '}
                  <a href="mailto:info@jadesquare.com">info@jadesquare.com</a>
                </p>
              )}
          </DialogContent>
          <DialogActions>
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'NOT_REQUESTED' && (
                <>
                  <Button
                    className="default"
                    onClick={async () => {
                      try {
                        await requestFileAccess(props.dealId);
                      } catch (e) {
                        console.log(e);
                      } finally {
                        refetch();
                      }
                    }}
                  >
                    {`${intl.messages['AccessRequest.requestAccess']}`.toUpperCase()}
                  </Button>
                  <Button className="secondary" onClick={props.onClose}>
                    {`${intl.messages['AccessRequest.close']}`.toUpperCase()}
                  </Button>
                </>
              )}
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'REQUESTED' && (
                <>
                  <Button
                    className="default"
                    onClick={() => {
                      refetch();
                    }}
                  >
                    {`${intl.messages['AccessRequest.refresh']}`.toUpperCase()}
                  </Button>
                  <Button className="secondary" onClick={props.onClose}>
                    {`${intl.messages['AccessRequest.close']}`.toUpperCase()}
                  </Button>
                </>
              )}
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'REJECTED' && (
                <>
                  <Button className="secondary" onClick={props.onClose}>
                    {`${intl.messages['AccessRequest.close']}`.toUpperCase()}
                  </Button>
                </>
              )}
            {data &&
              data.deal_investor[0] &&
              data.deal_investor[0].file_access_status === 'GRANTED' && (
                <>
                  <Button className="secondary" onClick={props.onClose}>
                    {`${intl.messages['AccessRequest.close']}`.toUpperCase()}
                  </Button>
                </>
              )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>File access granted to users</DialogTitle>
          <DialogContent>
            {data && data.deal_investor && data.deal_investor.length > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <td style={{ padding: '5px' }}>Investor name</td>
                      <td style={{ padding: '5px' }}>Investor email</td>
                      <td style={{ padding: '5px' }}>Investor status</td>
                    </tr>
                  </thead>
                  {grantedInvestors.length > 0 ? (
                    grantedInvestors.map(investor => {
                      return (
                        <tr>
                          <td style={{ padding: '5px' }}>
                            {investor.user.fullName}
                          </td>
                          <td style={{ padding: '5px' }}>
                            {investor.user.email}
                          </td>
                          <td style={{ padding: '5px' }}>
                            {investor.file_access_status}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <p style={{ textAlign: 'center' }}>
                            No granted investors for this deal yet
                          </p>
                        </td>
                      </tr>
                    </>
                  )}
                </table>
              </>
            ) : (
              <>
                <p>There is no investor yet</p>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button className="secondary" onClick={props.onClose}>
              {`${intl.messages['AccessRequest.close']}`.toUpperCase()}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default RequestAccessModal;
