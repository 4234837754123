/*
 *
 * Investors constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Investors/DEFAULT_ACTION',
  FETCH_INVESTOR_ACTION = 'app/Investors/FETCH_INVESTOR_ACTION',
  FETCH_INVESTOR_SUCCESS_ACTION = 'app/Investors/FETCH_INVESTOR_SUCCESS_ACTION',
  FETCH_INVESTOR_FAILED_ACTION = 'app/Investors/FETCH_INVESTOR_FAILED_ACTION',
  CHANGE_STATUS_ACTION = 'app/Investors/CHANGE_STATUS_ACTION',
  CHANGE_STATUS_SUCCESS_ACTION = 'app/Investors/CHANGE_STATUS_SUCCESS_ACTION',
  CHANGE_STATUS_FAILED_ACTION = 'app/Investors/CHANGE_STATUS_FAILED_ACTION',
  SET_REFRESH_PAGE = 'app/Investors/SET_REFRESH_PAGE',
}

export default ActionTypes;
