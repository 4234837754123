import React from 'react';
import { makeStyles } from '@material-ui/core';
import LoadingIndicator from '../../../components/LoadingIndicator';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0D0D0D75',
  },
});

const RegisterLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <LoadingIndicator />
    </div>
  );
};
export default RegisterLoader;
