/*
 *
 * ResetPassword reducer
 *
 */
import { produce, Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  succeed: false,
};

const resetPasswordReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.UPDATE_PASSWORD_SUCCEED:
        draft.succeed = true;
        break;
      case ActionTypes.RESET_STATE:
        draft.succeed = false;
        break;
    }
  },
  initialState,
);

export default resetPasswordReducer;
