import { requestUpload } from 'services/api/file-service';
import RequestUpload from 'models/request/request-upload';
import FileRequest from 'models/request/file-request';
import axios from 'axios';

export default async (file: FileRequest) => {
  try {
    const fileResponse = await requestUpload([file]);
    if (fileResponse.length > 0 && fileResponse[0].url) {
      const options = {
        headers: {
          'Content-Type': file.file.type,
          'Content-Disposition': `attachment; filename=${encodeURI(
            fileResponse[0].name || 'unknown',
          )}`,
        },
      };
      const instance = axios.create();
      await instance.put(fileResponse[0].url, file.file, options);
    }
    return fileResponse;
  } catch (err) {
    console.log(err);
    return null;
  }
};
