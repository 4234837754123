import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styles/styled-components';

const StatusWrapper = styled.div`
  border: 1px solid transparent;
  display: inline-block;
  padding: 1px 5px;
  font-size: 10px;
  background: transparent;
  font-weight: 500;
  border-radius: 5px;
  vertical-align: middle;
`;

const onStyle = {
  color: 'var(--color-cyan)',
  borderColor: 'var(--color-cyan)',
};

const offStyle = {
  color: '#888888',
  borderColor: '#DEDEDE',
};

const closeStyle = {
  color: '#6DB595',
  borderColor: '#C8ECDC',
};

const removedStyle = {
  color: '#D74F4F',
  borderColor: '#FFDCDC',
};

interface Props {
  status: string;
  text: string;
}

function StatusLabel(props: Props) {
  const intl = useIntl();
  const { status, text } = props;

  let style;

  switch (status) {
    case 'on':
      style = onStyle;
      break;
    case 'off':
      style = offStyle;
      break;
    case 'close':
      style = closeStyle;
      break;
    case 'removed':
      style = removedStyle;
      break;
  }

  return (
    <StatusWrapper style={style}>{intl.messages[`${text}`]}</StatusWrapper>
  );
}

export default memo(StatusLabel);
