/**
 *
 * CustomSwitch
 *
 */
import React, { memo } from 'react';
import {
  createStyles,
  Switch,
  SwitchClassKey,
  SwitchProps,
  Theme,
  withStyles,
} from '@material-ui/core';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  onSwitchChange: (ev) => void;
}

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(22px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: 'var(--color-purple)',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#BBBBBB',
        border: '6px solid var(--color-light-grey)',
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 24 / 2,
      border: `1px solid #BBBBBB`,
      backgroundColor: 'transparent',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    disabled: {
      '&$checked': {
        color: '#BBBBBB!important',
        '& + $track': {
          backgroundColor: '#BBBBBB!important',
        },
      },
      '&$focusVisible $thumb': {
        color: '#BBBBBB',
      },
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, onSwitchChange, disabled, ...props }: Props) => {
  const handleSwitchChange = e => {
    onSwitchChange(e.target.checked);
  };

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...props}
      onChange={handleSwitchChange}
      disabled={disabled}
    />
  );
});

export default memo(CustomSwitch);
