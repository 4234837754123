/*
 *
 * Commits constants
 *
 */

enum ActionTypes {
  SEARCH_COMMITS = 'app/Commits/SEARCH_COMMITS',
  SEARCH_COMMITS_SUCCEED = 'app/Commits/SEARCH_COMMITS_SUCCEED',
}

export default ActionTypes;
