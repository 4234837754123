/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#888888',
      fontSize: '0.75rem',
      paddingBottom: '10px',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  handleCreate: (item) => void;
  noMatched: number;
}

function VehicleHeader(props: Props) {
  const { noMatched } = props;
  const classes = useStyles();
  const localScope = `ProfileVehicles.header`;
  return (
    <div className={classes.root}>
      <FormattedMessage id={`thereAreCurrently`} />
      <span className={classes.amount}>
        {noMatched}
        <FormattedMessage
          id={`${localScope}.vehicles`}
          values={{ num: noMatched }}
        />
      </span>
    </div>
  );
}

export default memo(VehicleHeader);
