import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import Select from 'components/Select';
import { FormattedMessage, useIntl } from 'react-intl';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearBtn: {
      color: '#D74F4F',
      '&:hover': {
        color: '#B74F4F',
      },
    },
    titleText: {
      padding: 4,
      fontWeight: 'bold',
      fontSize: '0.875rem',
    },
    filterContainer: {
      display: 'inline-flex',
    },
    interest: {
      minWidth: 150,
    },
    select: {
      width: '100%',
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      marginRight: 5,
      marginBottom: 0,
    },
  }),
);

interface Props {
  onSelectType: (value: string) => void;
  type: string;
  types: any;
}

function Filter(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const {
    onSelectType,
    type,
    types,
  } = props;

  const clearFilter = () => {
    onSelectType('All');
  };

  return (
    <Card>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={true}>
          <Typography className={classes.titleText}>
            <FormattedMessage id="filter" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={'auto'}>
          <Typography style={{ textAlign: 'center' }}>
            <a className={classes.clearBtn} onClick={clearFilter}>
              <FormattedMessage id="clearFilters" />
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3} className={classes.filterContainer}>
          <Typography className={classes.title}>
            <FormattedMessage id="type" />
          </Typography>
          <Select
            value={type}
            className={classes.select}
            onChange={e => onSelectType(e.target.value)}
            defaultValue="All"
            items={types}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default memo(Filter);
