import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {};

const dealCreatorReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.CREATE_DEAL_SUCCESS_GROUP:
        draft.documentGroup = action.payload;
        break;
      case ActionTypes.CREATE_DEAL_GROUP:
        const docGroup = { documents: [{ files: [] }] };
        draft.documentGroup = docGroup;
        break;
    }
  },
  initialState,
);

export default dealCreatorReducer;
