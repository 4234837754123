import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {};

const dealEditorReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.GET_DEAL_DOCUMENT_SUCCESS:
        if (action.payload && action.payload.length > 0) {
          action.payload.forEach(item => {
            if (
              item.type === 'DOCUMENT' &&
              item.isDefault &&
              item.title === 'Deal documents'
            ) {
              draft.documentGroup = item;
              if (
                !draft.documentGroup.documents ||
                draft.documentGroup.documents.length === 0
              ) {
                draft.documentGroup.documents = [{ isDefault: true }];
              } else {
                const defaultIndex = draft.documentGroup.documents.findIndex(
                  item => {
                    return item.isDefault;
                  },
                );
                if (defaultIndex < 0) {
                  draft.documentGroup.documents = [
                    ...draft.documentGroup.documents,
                    { isDefault: true },
                  ];
                }
              }
            }
          });
        }

        break;
    }
  },
  initialState,
);

export default dealEditorReducer;
