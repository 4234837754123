import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the home state domain
 */

const selectHomeDomain = (state: ApplicationRootState) => {
  return state.home || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Home
 */

const makeSelectHome = () =>
  createSelector(selectHomeDomain, substate => {
    return substate;
  });

export default makeSelectHome;
export { selectHomeDomain };
