import { call, put, takeLatest } from 'redux-saga/effects';
import { getCommittedInvestors } from 'services/api/commit-service';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { searchCommitsSucceed } from './actions';
import { setIsLoading } from 'containers/Home/actions';

function* searchCommits(action) {
  yield put(setIsLoading(true))
  try {
    const response = yield call(getCommittedInvestors, action.payload);
    yield put(
      searchCommitsSucceed({
        data: response.data,
        total: response.total,
      }),
    );
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }finally{
    yield put(setIsLoading(false))
  }
}

export default function* commitsSaga() {
  yield takeLatest(ActionTypes.SEARCH_COMMITS, searchCommits);
}
