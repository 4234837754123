import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  getDocumentGroupStatistic,
  getInvestorDocuments,
  getAllUserDocument,
} from 'services/api/documentGroups-service';
import { refreshSignDocument } from 'services/api/sign-document';
import { showToast } from 'containers/App/actions';
import {
  getNumberOfDocumentsSucceed,
  getDocumentsSucceed,
  refreshSignatureSucceed,
  refreshAllSignaturesSucceed,
  setLoading,
} from './actions';
import ActionTypes from './constants';
import { sendPassword } from 'services/api/profile-service';
import { setIsLoading } from 'containers/Home/actions';

function* getNumberOfDocuments(action) {
  yield put(setIsLoading(true));
  try {
    const statistic = yield call(getDocumentGroupStatistic, action.payload);
    yield put(getNumberOfDocumentsSucceed(statistic));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getDocuments(action) {
  yield put(setIsLoading(true));
  try {
    const documentGroups = yield call(getInvestorDocuments, action.payload);
    const userDocuments = yield call(
      getAllUserDocument,
      ['SIGNATURE'],
      action.payload,
    );
    let documents = [];
    let reports = [];
    let signatures = [];

    documentGroups.forEach(group => {
      if (group.type === 'DOCUMENT' && group.documents) {
        documents = documents.concat(group.documents);
      }
      if (group.type === 'REPORT' && group.documents) {
        reports = reports.concat(group.documents);
      }
    });

    userDocuments.forEach(group => {
      if (group.type === 'SIGNATURE' && group.documents) {
        signatures = signatures.concat(group.documents);
      }
    });

    yield put(getDocumentsSucceed({ documents, reports, signatures }));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* refreshAllSignatures(action) {
  yield put(setIsLoading(true));
  try {
    const userDocuments = yield call(
      getAllUserDocument,
      ['SIGNATURE'],
      action.payload,
    );
    let signatures = [];
    userDocuments.forEach(group => {
      if (group.type === 'SIGNATURE' && group.documents) {
        signatures = signatures.concat(group.documents);
      }
    });
    yield put(refreshAllSignaturesSucceed(signatures));
  } catch (err) {
    yield put(setLoading(false));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* refreshSignature(action) {
  yield put(setIsLoading(true));
  try {
    const signature = yield call(refreshSignDocument, action.payload);
    yield put(refreshSignatureSucceed(signature));
  } catch (err) {
    yield put(setLoading(false));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* sendPasswordRequest(action) {
  try {
    const signature = yield call(sendPassword, action.payload);
    yield put(
      showToast({
        message: 'passwordHasBeenSentToYourEmail',
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(
      showToast({
        message: 'notAbleToSentPasswordNow',
        type: 'error',
      }),
    );
    // yield put(setLoading(false));
    // yield put(showToast({ message: err?.response?.data?.messages[0] ? err.response.data.messages[0] : 'unexpectedError', type: 'error' }));
  } finally {
    yield put(setIsLoading(false));
  }
}

// Individual exports for testing
export default function* investorDocumentsSaga() {
  yield takeLatest(ActionTypes.GET_NUMBER_OF_DOCUMENTS, getNumberOfDocuments);
  yield takeLatest(ActionTypes.GET_DOCUMENTS, getDocuments);
  yield takeLatest(ActionTypes.REFRESH_ALL_SIGNATURES, refreshAllSignatures);
  yield takeLatest(ActionTypes.REFRESH_SIGNATURE, refreshSignature);
  yield takeLatest(ActionTypes.SEND_PASSWORD_ACTION, sendPasswordRequest);
}
