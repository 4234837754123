/*
 *
 * InvestorDashboard
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, Grid, Typography, makeStyles, Link } from '@material-ui/core';
import moment from 'moment';

import Img from 'components/Img';
import NoAvatar from 'images/not-found.png';
import { ActivityItem } from 'models/request/dashboard-activity';
import { currencyFormat } from 'utils/stringFormat';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  container: {
    //padding: 20,
    height: '100%',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: '#CFE1FF',
    color: '#588FDB',
    fontWeight: 'bold',
    borderRadius: 2,
    display: 'inline-block',
    fontSize: 12,
  },
  nameText: {
    display: 'inline',
    fontSize: 16,
    color: '#212121',
    fontWeight: 'bold',
  },
  linkText: {
    display: 'inline',
    fontSize: '0.785rem',
    minWidth: 50,
    textAlign: 'right',
    color: 'var(--color-light-grey)',
    fontWeight: 600,
  },
  subTitleText: {
    color: 'var(--color-grey)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  text: {
    fontSize: '0.785rem',
    margin: 0,
    paddingTop: 4,
    color: 'var(--color-grey)',
  },
  amountText: {
    fontSize: '0.785rem',
    margin: 0,
    paddingTop: 4,
    color: 'var(--color-light-grey)',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '0.785rem',
    color: '#888888',
    margin: '0px',
    paddingTop: 4,
  },
  headerText: {
    fontWeight: 600,
    fontSize: '1.07rem',
    marginBottom: 0,
    color: 'var(--color-light-grey)',
  },
  viewAllText: {
    fontSize: '12px',
  },
  picture: {
    width: 25,
    height: 25,
    marginRight: 8,
    borderRadius: '50%',
  },
  emptyText: {
    textAlign: 'center',
    margin: '0',
  },
  linkTextGrey: {
    color: 'var(--color-grey)',
    '&:hover': {
      color: 'var(--color-purple)',
    },
  },
  emptyCard: {
    padding: '1.25rem 0.5rem 1.25rem 0.5rem',
    color: 'var(--color-light-grey)',
    borderRadius: '3px',
  },
}));

interface Props {
  title: string;
  items: ActivityItem[];
  viewAllUrl?: string;
}

function DashboardActivity(props: Props) {
  const { items, title, viewAllUrl } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleViewAll = () => {
    if (viewAllUrl) {
      dispatch(push(viewAllUrl));
    }
  };
  const getDateStr = date => {
    return moment(date).format('DD/MM/YYYY');
  };

  const handleClick = url => {
    dispatch(push(url));
  };

  return (
    <div>
      <div style={{ height: '100%' }}>
        <Grid container style={{ paddingBottom: '10px' }} alignItems="center">
          <Grid item xs={true}>
            <Typography className={classes.headerText}> {title} </Typography>
          </Grid>
          <Grid item xs={'auto'} className={classes.viewAllText}>
            {viewAllUrl && (
              <Link className={classes.linkTextGrey} onClick={handleViewAll}>
                {' '}
                <FormattedMessage id="viewAll" />{' '}
              </Link>
            )}
          </Grid>
        </Grid>
        <div>
          {items && items.length > 0 ? (
            items.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: 'var(--color-super-dark-grey)',
                    padding: '0.5rem 1rem 0.5rem 1rem',
                    borderRadius: '3px',
                    border: '1px solid var(--color-medium-dark-grey)',
                    marginBottom: '0.5rem',
                  }}
                >
                  <Grid container>
                    <Grid item xs={true}>
                      <Link
                        className={classes.linkText}
                        onClick={() => {
                          handleClick(item.url);
                        }}
                      >
                        {item.title}
                      </Link>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <Typography className={classes.subTitle}>
                        {getDateStr(item.dateStr)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={'auto'}>
                      <Img
                        src={item.picture || NoAvatar}
                        className={classes.picture}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.text}>
                        {item.subTitle}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs><Typography className={classes.subTitle}>
                              {item.group}
                            </Typography></Grid> */}

                    <Grid item xs={'auto'}>
                      <Typography className={classes.amountText}>
                        {currencyFormat(item.totalNumber)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          ) : (
            <Grid xs={12}>
              <Card className={classes.emptyCard}>
                <Typography className={classes.emptyText}>
                  <FormattedMessage id="noData" />
                </Typography>
              </Card>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(DashboardActivity);
