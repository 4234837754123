/**
 *
 * CustomFormControl
 *
 */
import React, { memo, useState } from 'react';
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  OutlinedInput,
  PropTypes,
  Select,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const initialFormControlState: FormControlState = {
  error: false,
  errorMessage: '',
};

interface Props {
  defaultValue?: string;
  inputPlaceholder?: string;
  onChange: (ev) => void;
  formState: FormControlState;
  title?: string;
  subtitle?: string;
  typePassword: boolean;
  formMargin?: PropTypes.Margin;
  type?: string;
  fullWidth: boolean;
  options: FormControlOption[];
  initOption: string;
  chips: FormControlChip[];
  futureDate?: boolean;
  inputProps?: any;
  disabled?: boolean;
}

CustomFormControl.defaultProps = {
  fullWidth: true,
  typePassword: false,
  formState: initialFormControlState,
  type: 'input',
  options: [],
  initOption: 'default',
  chips: [],
};

export interface FormControlState {
  error: boolean;
  errorMessage: string;
}

export interface FormControlOption {
  value: string;
  text: string;
}

export interface FormControlChip {
  label: string;
  checked: boolean;
}

interface FormControlInputState {
  showPassword: boolean;
  files: any[];
  selectedAvatar: string;
  selectedChips: FormControlChip[];
}

const useStyles = makeStyles(theme => ({
  chipList: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const CustomRadioGroup = withStyles(() => ({
  root: {
    flexDirection: 'unset',
  },
}))(RadioGroup);

function CustomFormControl(props: Props) {
  const styles = useStyles(props);
  const {
    defaultValue,
    inputPlaceholder,
    onChange,
    formState,
    title,
    subtitle,
    typePassword,
    formMargin,
    type,
    options,
    fullWidth,
    initOption,
    chips,
    futureDate,
    inputProps,
    disabled,
  } = props;
  let hiddenFileInput;
  const [currentDate, setCurrentDate] = useState(defaultValue);
  const [currentTime, setCurrentTime] = useState(defaultValue);

  if (defaultValue && !currentTime) {
    setCurrentTime(defaultValue);
  }

  const [values, setValues] = React.useState<FormControlInputState>({
    showPassword: false,
    files: [],
    selectedAvatar: '',
    selectedChips: chips,
  });

  const handleClickShowPassword = event => {
    event.preventDefault();
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onChangeInputType = event => {
    onChange(event.target.value);
  };

  const onChangeOptionSelectType = event => {
    onChange(event.target.value === 'default' ? '' : event.target.value);
  };

  const handleClickChip = index => () => {
    const chipsa = values.selectedChips;
    chipsa[index].checked = !chipsa[index].checked;
    setValues({ ...values, selectedChips: chipsa });
    onChange(chipsa.filter(chip => chip.checked).map(chip => chip.label));
  };

  const onChangeDatePickerType = ev => {
    setCurrentDate(ev);
    onChange(moment(ev).format('DD-MM-YYYY'));
  };

  const onChangeTimePicker = e => {
    setCurrentTime(e);
    onChange(e);
  };

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      const b = values.files;
      for (const file of e.target.files) {
        b.push(file);
        if (b.length >= 5) {
          break;
        }
      }
      setValues({ ...values, files: b });
      onChange(values.files);
    }
  };

  const handleDeleteFileSelect = index => () => {
    const b = values.files;
    b.splice(index, 1);
    setValues({ ...values, files: b });
    onChange(values.files);
  };

  const FileRow = props => {
    return (
      <Typography>
        <Link style={{ color: '#588FDB' }} underline={'always'}>
          {props.name}
        </Link>
        <Link
          style={{ color: '#D74F4F', padding: '0 0 0 10px' }}
          underline={'none'}
          onClick={handleDeleteFileSelect(props.index)}
        >
          Delete
        </Link>
      </Typography>
    );
  };

  const handleAvatarSelect = e => {
    const url = URL.createObjectURL(e.target.files[0]);
    setValues({ ...values, selectedAvatar: url });
    onChange(e.target.files[0]);
  };

  const onRemoveAvatar = () => {
    setValues({ ...values, selectedAvatar: '' });
    onChange(null);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={formMargin} variant={'outlined'}>
      {title && (
        <FormLabel style={{ marginBottom: '5px' }}>
          {title}
          {subtitle && <span style={{ color: 'var(--color-gray)' }}> {subtitle}</span>}
        </FormLabel>
      )}

      {type === 'input' && (
        <OutlinedInput
          placeholder={inputPlaceholder}
          defaultValue={defaultValue}
          onChange={onChangeInputType}
          error={formState.error}
          labelWidth={0}
          fullWidth={true}
          inputProps={inputProps}
          type={
            typePassword ? (values.showPassword ? 'text' : 'password') : 'text'
          }
          autoComplete={typePassword ? 'new-password' : 'off'}
          endAdornment={
            typePassword && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }
        />
      )}

      {type === 'option' && (
        <Select
          margin={'none'}
          defaultValue={initOption ? initOption : 'default'}
          onChange={onChangeOptionSelectType}
        >
          <MenuItem value={'default'}>{defaultValue}</MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
      )}
      {type === 'datepicker' && (
        <DatePicker
          disableFuture={!futureDate}
          inputVariant={'outlined'}
          openTo="year"
          format="DD-MM-YYYY"
          views={['year', 'month', 'date']}
          value={currentDate || null}
          onChange={onChangeDatePickerType}
        />
      )}
      {type === 'fileSelector' && (
        <div>
          {values.files.map((file, index) => (
            <FileRow key={index} index={index} name={file.name} />
          ))}
          {values.files.length < 5 && (
            <input
              ref={ref => (hiddenFileInput = ref)}
              type="file"
              name="file"
              multiple
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
          )}

          {values.files.length < 5 && (
            <Button
              size={'small'}
              style={{ marginTop: '0px' }}
              variant="contained"
              onClick={() => hiddenFileInput.click()}
            >
              UPLOAD
            </Button>
          )}
        </div>
      )}
      {type === 'avatarSelector' && (
        <div>
          {values.selectedAvatar && (
            <img
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                marginRight: '10px',
              }}
              src={values.selectedAvatar}
              alt={values.selectedAvatar}
            />
          )}
          <input
            ref={ref => (hiddenFileInput = ref)}
            type="file"
            name="file"
            onChange={handleAvatarSelect}
            style={{ display: 'none' }}
          />

          <Button
            size={'small'}
            style={{ marginTop: '0px' }}
            variant="contained"
            onClick={() => hiddenFileInput.click()}
          >
            UPLOAD
          </Button>

          {values.selectedAvatar && (
            <Button
              size={'small'}
              style={{ marginLeft: '10px', backgroundColor: '#D74F4F' }}
              variant="contained"
              onClick={onRemoveAvatar}
            >
              DELETE
            </Button>
          )}
        </div>
      )}
      {type === 'chipList' && (
        <div className={styles.chipList}>
          {chips.map((chip, index) => (
            <Chip
              variant={chip.checked ? 'default' : 'outlined'}
              key={index}
              label={chip.label}
              size={'small'}
              onClick={handleClickChip(index)}
            />
          ))}
        </div>
      )}
      {type === 'timePicker' && (
        <TimePicker
          onChange={onChangeTimePicker}
          value={currentTime || null}
          inputVariant={'outlined'}
          ampm={false}
        />
      )}
      {type === 'radio' && (
        <CustomRadioGroup
          defaultValue={defaultValue}
          onChange={e => onChange(e.target.value)}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio disabled={disabled} color="primary" />}
              label={option.text}
            />
          ))}
        </CustomRadioGroup>
      )}
      {formState.error && (
        <FormHelperText error={formState.error}>
          {formState.errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default memo(CustomFormControl);
