import React, { memo } from "react";
import { Grid, Typography, styled } from "@material-ui/core";
import Header from "./Header";
import styles from "../styles";
import Attachment from "components/Attachment";
import { useIntl } from "react-intl";
import TreeView, { TreeViewProps } from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";

const style = styles.detail;
const Title = (props: { children?: any }) => {
	const { children } = props;
	return (
		<Grid xs={12} sm={12} item>
			<Typography style={style.title}>{children}</Typography>
		</Grid>
	);
};

interface Props {
	publicDocuments: any[];
	detail?: boolean;
	dealId?: string;
	admin?: boolean;
}

const StyledTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
  paddingBottom: 10,
	[`& .MuiTreeItem-content`]: {
		"& .MuiTreeItem-label": {
			fontSize: 14,
			fontWeight: 500,
			backgroundColor: "transparent!important",
			paddingTop: 2,
			paddingBottom: 2,
		},
		[`& .MuiTreeItem-iconContainer`]: {
			"& svg": {
				fontSize: 24,
			},
		},
		[`& .MuiTreeItem-group`]: {
			marginLeft: 15,
			paddingLeft: 15,
			borderLeft: `1px dashed #ccc`,
		},
	},
}));

const StyledTreeView = styled((props: TreeViewProps) => <TreeView {...props} />)(({ theme }) => ({
	width: "100%",
  paddingLeft: 10
}));

const PublicDocuments = (props: Props) => {
	const intl = useIntl();
	const { publicDocuments, detail, dealId, admin } = props;

	const defaultExpandedId = publicDocuments?.find((group) => group.isDefault)?.index?.toString();
	const defaultExpanded = defaultExpandedId ? [defaultExpandedId] : [];

	return (
		<>
			{detail ? <Title>{`${intl.messages["dealPublicDocuments"]}`}</Title> : <Header>{`${intl.messages["dealPublicDocuments"]}`}</Header>}
			{publicDocuments && (
				<StyledTreeView defaultExpanded={defaultExpanded} aria-label="file system navigator" defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
					{publicDocuments.map((group, i) => (
						<StyledTreeItem key={i} nodeId={group.index.toString()} label={group.title}>
							{group.documents.map((doc) =>
								doc.files.map((file) => (
									<Attachment dealId={dealId} name={file.name} title={file.title} fileId={file.fileId} key={file.fileId} isDealAttachment={true} admin={admin} />
								))
							)}
						</StyledTreeItem>
					))}
				</StyledTreeView>
			)}
		</>
	);
};

export default memo(PublicDocuments);
