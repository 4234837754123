import { createMuiTheme } from '@material-ui/core';
import { primaryColor, secondaryColor } from './colors';
import typography from './typography';

export const getMuiTheme = () =>
  createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    spacing: 4,
    palette: {
      primary: {
        main: primaryColor,
        contrastText: '#1a1a1a',
      },
      secondary: {
        main: secondaryColor,
        contrastText: '#ffffff',
      },
      type: 'dark',
    },
    overrides: {
      MuiTypography: {
        root: {
          margin: undefined,
        },
        h1: {
          fontSize: '40px',
          fontWeight: 700,
        },
        h2: {
          fontSize: '30px',
          fontWeight: 700,
        },
        h3: {
          fontSize: '23px',
          fontWeight: 600,
        },
        h4: {
          fontSize: '17px',
          fontWeight: 600,
          marginBottom: '10px',
        },
      },
      MuiGrid: {
        root: {
          position: 'relative',
        },
      },
      MuiLink: {
        root: {
          fontWeight: 300,
        },
      },
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
            height: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.5)',
          },
        },
      },
      MuiBottomNavigationAction: {
        root: {
          '&$selected': {
            color: secondaryColor,
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: '5px',
          textTransform: 'uppercase',
        },
        outlined: {
          fontWeight: 500,
        },
        containedPrimary: {
          '&$disabled': {
            backgroundColor: '#B5CEFA',
            color: '#FFF',
          },
        },
        contained: {
          textTransform: 'uppercase',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: 'var(--color-super-dark-grey)',
        },
        rounded: {
          borderRadius: '3px',
        },
        outlined: {
          borderColor: 'var(--color-medium-dark-grey)',
        },
      },
      MuiOutlinedInput: {
        root: {
          height: 'inherit',
          color: 'lightGrey',
          '&$focused': {
            borderColor: '#588fdb',
            borderWidth: '1px',
          },
        },
        input: {
          height: '36px',
          borderRadius: '5px',
          padding: '5px 10px',
          color: 'lightGrey',
          boxSizing: 'border-box',
          border: '1px solid var(--color-medium-dark-grey)',
          '&:hover:not($disabled):not($error), &:focus': {
            borderColor: 'var(--color-grey)',
          },
          '&:-webkit-autofill': {
            '&:hover, &:active, &:focus': {
              WebkitBoxShadow: '0 0 0 30px black inset !important;',
            },
            WebkitBoxShadow: '0 0 0 30px black inset !important;',
            WebkitTextFillColor: 'white',
            backgroundColor: 'transparent!important',
          },
          '&:-internal-autofill-selected': {
            backgroundColor: 'transparent!important',
            color: 'black!important',
          },
        },
        notchedOutline: {
          display: 'none',
        },
      },
      MuiInputBase: {
        root: {
          '&$focused': {
            borderColor: 'var(--color-dark-purple)',
            borderWidth: '1px',
          },
          '&.small': {
            '& .MuiSelect-selectMenu, & .MuiInput-input': {
              height: '30px',
              lineHeight: '24px',
            },
          },
        },
      },
      MuiInput: {
        root: {
          height: 'inherit',
          color: 'lightGrey',
        },
        input: {
          height: '36px',
          borderRadius: '5px',
          padding: '5px 10px',
          color: 'lightGrey',
          boxSizing: 'border-box',
          border: '1px solid var(--color-medium-dark-grey)',
          '&:hover:not($disabled):not($error), &:focus': {
            borderColor: 'var(--color-grey)',
          },
          '&:-webkit-autofill': {
            '&:hover, &:active, &:focus': {
              WebkitBoxShadow: '0 0 0 30px black inset !important;',
            },
            WebkitBoxShadow: '0 0 0 30px black inset !important;',
            WebkitTextFillColor: 'white',
            backgroundColor: 'transparent!important',
          },
          '&:-internal-autofill-selected': {
            backgroundColor: 'transparent!important',
            color: 'black!important',
          },

          '&.MuiInputBase-inputAdornedStart, &.MuiInputBase-inputAdornedEnd': {
            border: 0,
          },
        },
      },
      MuiSelect: {
        root: {
          height: '36px',
          lineHeight: '30px',
          paddingTop: '3px',
          paddingBottom: '3px',
          boxSizing: 'border-box',
          '&.MuiSelect-select': {
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
          '&.MuiSelect-selectMenu': {
            height: '36px',
            lineHeight: '30px',
            paddingTop: '3px',
            paddingBottom: '3px',
            boxSizing: 'border-box',
          },
        },
      },
      MuiChip: {
        root: {
          borderRadius: '3px',
          backgroundColor: 'var(--color-dark-purple)',
          border: '1px solid var(--color-dark-purple)',
          color: 'white',
          fontSize: '12px',
          fontWeight: 500,
          padding: '2px 5px',
          '&:hover, &:focus': {
            backgroundColor: 'var(--color-purple)',
          },
          '&$clickable': {
            '&:hover, &:focus': {
              backgroundColor: 'var(--color-purple)',
            },
            '&$outlined': {
              '&:hover': {
                color: 'var(--color-light-grey)',
                borderColor: 'var(--color-light-grey)',
                backgroundColor: 'transparent',
              },
            },
          },
          '&$outlined': {
            backgroundColor: 'transparent',
            color: 'var(--color-grey)',
            borderColor: 'var(--color-grey)',
          },
          '& $label': {
            padding: 0,
          },
        },
      },
      MuiRadio: {
        root: {
          '&$checked': {
            color: 'var(--color-purple)',
          },
          '&$colorPrimary': {
            '&$checked': {
              color: 'var(--color-purple)',
            },
          },
        },
      },
      MuiCard: {
        root: {
          border: '1px solid var(--color-medium-dark-grey)',
          backgroundColor: 'var(--color-super-dark-grey)',
          padding: '1.2rem',
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: '10px',
          border: '1px solid var(--color-dark-grey)',
          backgroundColor: 'var(--color-black)',
        },
        root: {
          '& .MuiButton-root': {
            paddingLeft: '5px',
            paddingRight: '5px',
          },
          '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
            marginBottom: '0!important',
          },
        },
      },
      MuiDialogContent: {
        root: {
          paddingLeft: 17,
          paddingRight: 17,
        },
      },
      MuiDialogTitle: {
        root: {
          color: 'white',
          fontSize: '16px',
          fontWeight: 600,
          padding: '7px 17px',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 17,
            right: 17,
            bottom: 0,
            height: 1,
            backgroundColor: 'var(--color-medium-dark-grey)',
          },
        },
      },
    },
    typography: typography,
  });
