import React from 'react';
import Options from './Options';
import { makeStyles, Container, Grid, Theme } from '@material-ui/core';
import Cards from './cards/Cards';
import InvestmentOptions from './InvestmentOptions';
import { useIntl } from 'react-intl';
import businessPeople from '../../../images/businesspeople-having-discussion-office.jpg';
import { ReactComponent as SortIcon } from '../../../images/icons/Sort.svg';
import { ReactComponent as SustainIcon } from '../../../images/icons/Sustain.svg';
import { ReactComponent as StandardizeIcon } from '../../../images/icons/Stantandardize.svg';
import { ReactComponent as ShineIcon } from '../../../images/icons/Shine.svg';
import { ReactComponent as SetInOrderIcon } from '../../../images/icons/Set-in-Orrder.svg';
const useStyles = makeStyles((theme: Theme) => ({
  background: {
    minheight: '100%',
    boxSizing: 'border-box',
  },
  images: {
    width: '100%',
    height: 'auto',
  },
  spacedContainer: {
    marginTop: '5rem',
    marginBottom: '5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  },
  purpleContainer: {
    width: '100%',
    backgroundColor: 'var(--color-dark-purple)',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    marginBottom: '5rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
  cardTitleWrapper: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  cardWrapper: {
    padding: '0.5rem',
  },
  smallTitle: {
    fontSize: '20px',
    margin: 0,
    lineHeight: '22px',
  },
  explainingParagraphWrapper: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
function Main() {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div>
      <Container className="main">
        <Grid container>
          <Grid item container xs={12} spacing={4}>
            <Grid item sm={12} md={6} lg={6}>
              <img className={classes.images} src={businessPeople} />
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <div>
                <h4
                  style={{
                    fontSize: '20px',
                    marginBottom: 0,
                  }}
                >
                  JADESQUARE
                </h4>
                <h2>{`${intl.messages['Investments.philosophyTitle']}`}</h2>
                <p>{`${intl.messages['Investments.philosophyDesc1']}`}</p>
                <p>{`${intl.messages['Investments.philosophyDesc2']}`}</p>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={classes.spacedContainer}
            justify="center"
          >
            <Grid item sm={12} md={10} lg={8}>
              <div style={{ textAlign: 'center' }}>
                <h4 style={{ fontSize: '20px' }}>
                  {`${intl.messages['Investments.weStrive']}`}
                </h4>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className={`${classes.purpleContainer}`}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item sm={12} md={6} lg={6}>
              <div className={classes.explainingParagraphWrapper}>
                <h2>{`${intl.messages['Investments.5s3mTitle']}`}</h2>
                <p>{`${intl.messages['Investments.5s3mDesc']}`}</p>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <Grid container spacing={4}>
                <Grid item sm={6} md={4} lg={4}>
                  <div className={classes.cardWrapper}>
                    <SortIcon />
                    <div className={classes.cardTitleWrapper}>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle1']}`}</h4>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle1Exp']}`}</h4>
                    </div>
                    <p>{`${intl.messages['Investments.small1Desc']}`}</p>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <div className={classes.cardWrapper}>
                    <SetInOrderIcon />
                    <div className={classes.cardTitleWrapper}>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle2']}`}</h4>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle2Exp']}`}</h4>
                    </div>
                    <p>{`${intl.messages['Investments.small2Desc']}`}</p>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <div className={classes.cardWrapper}>
                    <ShineIcon />
                    <div className={classes.cardTitleWrapper}>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle3']}`}</h4>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle3Exp']}`}</h4>
                    </div>
                    <p>{`${intl.messages['Investments.small3Desc']}`}</p>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <div className={classes.cardWrapper}>
                    <StandardizeIcon />
                    <div className={classes.cardTitleWrapper}>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle4']}`}</h4>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle4Exp']}`}</h4>
                    </div>
                    <p>{`${intl.messages['Investments.small4Desc']}`}</p>
                  </div>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <div className={classes.cardWrapper}>
                    <SustainIcon />
                    <div className={classes.cardTitleWrapper}>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle5']}`}</h4>
                      <h4
                        className={classes.smallTitle}
                      >{`${intl.messages['Investments.smallTitle5Exp']}`}</h4>
                    </div>
                    <p>{`${intl.messages['Investments.small5Desc']}`}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Grid container className={classes.spacedContainer} justify="center">
        <Grid item sm={12} md={7} lg={5}>
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ fontSize: '20px' }}>
              {`${intl.messages['Investments.purposeOf5STitle']}`}
            </h4>
            <p>{`${intl.messages['Investments.purposeOf5SDesc']}`}</p>
          </div>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <Grid container className={classes.spacedContainer} spacing={4}>
          <Grid item sm={12} md={6} lg={6}>
            <div>
              <h4 style={{ fontSize: '16px' }}>
                {`${intl.messages['Investments.3MTitle']}`}
              </h4>
            </div>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <div>
              <p>{`${intl.messages['Investments.3MDesc']}`}</p>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Cards />
      <Container className="main">
        <Grid
          container
          item
          xs={12}
          className={classes.spacedContainer}
          justify="center"
        >
          <Grid item sm={12} md={10} lg={8}>
            <div style={{ textAlign: 'center' }}>
              <h1>{`${intl.messages['InvestmentOptions.2020']}`}</h1>
              <p>{`${intl.messages['InvestmentOptions.2020Desc']}`}</p>
            </div>
          </Grid>
        </Grid>
        <InvestmentOptions />
      </Container>
    </div>
  );
}

export default Main;
