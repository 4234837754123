import React from 'react';
import noInternet from './icons/noInternet.svg';
import { Container, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles({
  noInternet: {
    color: '#E5E5E5',
    maxWidth: ' 132px',
    opacity: 0.5,
  },
  header: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '38px',
    marginTop: '2.5rem',
  },
  paragraph: {
    maxWidth: '300px',
    fontSize: '15px',
    lineHeight: '23px',
    marginTop: '1.25rem',
  },
});
function NoInternet() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div
        style={{
          display:'flex',
          justifyContent:"center",
          alignItems:"center",
          minHeight: '100vh',
          textAlign: 'center',
          color: '#FFFFFF',
        }}
      >
        <div>
          <img src={noInternet} alt="" className={classes.noInternet} />
          <h2 className={classes.header}>
          <FormattedMessage id="NoInternet.header"/>
          </h2>
          <p className={classes.paragraph}>
          <FormattedMessage id="NoInternet.paragraph"/>
          </p>
        </div>
      </div>
    </Container>
  );
}

export default NoInternet;
