import React, { memo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Paper, Container } from '@material-ui/core';
import NotFound from 'images/not-found.png';
import avgBackground from 'images/avgBackground.png';
import Company from 'models/company';
import NumberLabel from 'components/NumberLabel';
import capitalizeFirstLetter from 'services/capitalize-first-letter';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#191919',
  },
  avgTicketContainer: {
    backgroundImage: `url(${avgBackground})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: 8,
  },
  boldText: {
    fontWeight: 600,
  },
  logo: {
    margin: 6,
    width: 35,
    height: 35,
    borderRadius: '50%',
  },
}));

const CustomTab = withStyles(() => ({
  root: {
    fontWeight: 600,
  
    minWidth: 100,
  },
  wrapper: {
    width: 'auto',
    flexDirection: 'unset',
  },
}))(Tab);

const CustomTabs = withStyles(() => ({
  root: {
    marginRight: 12,
  },
  flexContainer: {
    justifyContent: 'flex-end',
  },
}))(Tabs);

CustomTabs.defaultProps = {
  indicatorColor: 'primary',
  textColor: 'primary',
};

interface Props {
  company?: Company;
  languageCode: string;
  onChangeTab: (value: any) => void;
  statistic: any;
}

function TopBar(props: Props) {
  const { company, onChangeTab, statistic } = props;
  const [tabValue, onTabValueChanged] = React.useState(0);
  const classes = useStyles();
  const handleTabChanged = (e, v) => {
    onChangeTab(v);
    onTabValueChanged(v);
  };
  const intl = useIntl();
  return (
    <div className={classes.container}>
    <Container>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs>
          <img
            src={(company && company.pathPicture) || NotFound}
            className={classes.logo}
          />
          <span>{company ? company.name.get(props.languageCode) : ''}</span>
        </Grid>
        <Grid item>
          <CustomTabs value={tabValue} onChange={handleTabChanged}>
            <CustomTab
              label={
                <>
                  <FormattedMessage id="deals" />{' '}
                  <NumberLabel
                    label={
                      statistic && statistic.totalDeals
                        ? statistic.totalDeals
                        : 0 || 0
                    }
                  />
                </>
              }
            />
            <CustomTab
              label={
                <>
                  <FormattedMessage id="documents" />
                </>
              }
            />
            <CustomTab
              label={
                <>
                  {capitalizeFirstLetter(`${intl.messages['tickets']}`)}{' '}
                  <NumberLabel
                    label={
                      statistic && statistic.totalTickets
                        ? statistic.totalTickets
                        : 0 || 0
                    }
                  />
                </>
              }
            />
            <CustomTab label={`${intl.messages['information']}`} />
          </CustomTabs>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
}

export default memo(TopBar);
