import { setLoadingAction } from './../Register/actions';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import {
  FormState,
  initialFormState,
} from '../../components/FormControl/state';
import {
  initialInputState,
  InputState,
} from '../../components/FormControl/input';
import { SelectState } from '../../components/FormControl/select';
import * as actions from '../RegisterVehicles/actions';
import Vehicle from '../../models/vehicle';
import {
  getVehicles,
  createVehicle,
  updateVehicle,
} from '../../services/api/vehicle-service';
import VehicleRequest from '../../models/request/vehicle-request';
import { showToast } from '../App/actions';
import { me, updaStepProfile } from '../../services/api/profile-service';
import { initialDocumentUploadState } from '../RegisterIdentity/components/document-upload';
import { updateDocumentFiles } from 'services/api/file-service';
import makeSelectRegister from 'containers/Register/selectors';
import makeSelectRegisterVehicles from './selectors';
import { debug } from 'webpack';

function* fetchVehicles(action) {
  put(setLoadingAction(true));
  try {
    const vehicles: Vehicle[] = yield call(getVehicles, action.payload);
    vehicles.sort((v1, v2) => {
      if (v2.vehicleId === v1.vehicleId) {
        return 0;
      } else if (v1.vehicleId && v2.vehicleId && v2.vehicleId > v1.vehicleId) {
        return 1;
      } else {
        return -1;
      }
    });

    const individual: Vehicle | undefined = vehicles.find(
      v => v.vehicleType === 'INDIVIDUAL',
    );
    const corporate: Vehicle | undefined = vehicles.find(
      v => v.vehicleType === 'CORPORATE',
    );
    yield put(actions.fetchVehiclesSuccessAction(corporate));
    yield put(
      actions.updateFormStateVehiclesAction([
        { field: 'userId', state: action.payload },
        {
          field: 'individualVehicleId',
          state: individual ? individual.vehicleId : null,
        },
        {
          field: 'corporateVehicleId',
          state: corporate ? corporate.vehicleId : null,
        },
        {
          field: 'corporateVehicle',
          state: !!(corporate && corporate.status === 'ACTIVE'),
        },
        {
          field: 'individualVehicle',
          state:
            !!(individual && individual.status === 'ACTIVE') ||
            !(corporate && corporate.status === 'ACTIVE'),
        },
        {
          field: 'corporateName',
          state: {
            ...initialInputState,
            value: corporate ? corporate.corporateName : '',
          },
        },
        {
          field: 'corporateType',
          state: {
            ...initialInputState,
            value: corporate ? corporate.corporateType : '',
          },
        },
        {
          field: 'corporateRegistrationNumber',
          state: {
            ...initialInputState,
            value: corporate ? corporate.corporateRegistrationNumber : '',
          },
        },

        {
          field: 'address1',
          state: {
            ...initialInputState,
            value: corporate ? corporate.address1 : '',
          },
        },
        {
          field: 'address2',
          state: {
            ...initialInputState,
            value: corporate ? corporate.address2 : '',
          },
        },
        {
          field: 'zipCode',
          state: {
            ...initialInputState,
            value: corporate ? corporate.zipCode : '',
          },
        },
        {
          field: 'city',
          state: {
            ...initialInputState,
            value: corporate ? corporate.city : '',
          },
        },
        {
          field: 'country',
          state: {
            ...initialInputState,
            value: corporate ? corporate.country : '',
          },
        },
        {
          field: 'sameAsInvestor',
          state: corporate ? corporate.isSameInvestor : true,
        },
        {
          field: 'corporateDocFiles',
          state: {
            ...initialDocumentUploadState,
          },
        },
      ]),
    );
  } catch (err) {
    // yield put(actions.fetchMeErrorAction());
    console.error(err);
  } finally {
    put(setLoadingAction(false));
  }
}

function* updateVehicles(action) {
  put(setLoadingAction(true));
  let { profile } = yield select(makeSelectRegister());
  const { documentGroups } = yield select(makeSelectRegisterVehicles());
  if (!profile) {
    profile = yield call(me);
  }
  if (!profile) {
    return;
  }

  const states: FormState[] = { ...action.payload };
  const userId: string = action.payload.userId;
  const individualVehicle: boolean = states['individualVehicle'];
  const corporateVehicle: boolean = states['corporateVehicle'];
  const individualVehicleId: string = states['individualVehicleId'];
  const corporateVehicleId: string = states['corporateVehicleId'];
  const corporateName: InputState = {
    ...states['corporateName'],
    ...initialFormState,
  };
  const corporateType: InputState = {
    ...states['corporateType'],
    ...initialFormState,
  };
  const corporateRegistrationNumber: InputState = {
    ...states['corporateRegistrationNumber'],
    ...initialFormState,
  };
  const address1: InputState = { ...states['address1'], ...initialFormState };
  const address2: InputState = { ...states['address2'], ...initialFormState };
  const zipCode: InputState = { ...states['zipCode'], ...initialFormState };
  const city: InputState = { ...states['city'], ...initialFormState };
  const country: SelectState = { ...states['country'], ...initialFormState };
  const sameAsInvestor: boolean = states['sameAsInvestor'];

  const individualRequest: VehicleRequest = {
    userId: userId,
    status: individualVehicle ? 'ACTIVE' : 'INACTIVE',
    vehicleType: 'INDIVIDUAL',
    vehicleId: individualVehicleId,
  };

  // const requestDocuGroup = documentGroups.map(group => {
  //   if (group.documents) {
  //     group.documents = group.documents.map(doc => {
  //       doc.fileIds = doc.files.map(file => {
  //         return file.fileId;
  //       });
  //       return doc;
  //     });
  //   }
  //   return group;
  // });

  const requestDocuGroup = documentGroups.map(group => {
    let documents = [];
    if (group.documents) {
      documents = group.documents.map(doc => {
        let fileIds = [];
        if (doc.files) {
          fileIds = doc.files.map(file => {
            return file.fileId;
          });
        }
        return fileIds;
      });
    }
    return documents;
  });
  const corporateRequest: VehicleRequest = {
    userId: userId,
    status: corporateVehicle ? 'ACTIVE' : 'INACTIVE',
    vehicleType: 'CORPORATE',
    corporateName: corporateName.value ? corporateName.value.trim() : '',
    corporateType: corporateType.value ? corporateType.value.trim() : '',
    corporateRegistrationNumber: corporateRegistrationNumber.value
      ? corporateRegistrationNumber.value.trim()
      : '',
    address1: address1.value ? address1.value.trim() : '',
    address2: address2.value ? address2.value.trim() : '',
    zipCode: zipCode.value ? zipCode.value.trim() : '',
    city: city.value ? city.value.trim() : '',
    country: country.value ? country.value : '',
    isSameInvestor: sameAsInvestor,
    vehicleId: corporateVehicleId,
    documentGroups: requestDocuGroup,
  };

  if (corporateVehicle && !corporateRequest.corporateName) {
    corporateName.error = true;
    corporateName.helperMessage = 'corporateNameRequired';
  }
  if (corporateVehicle && !corporateRequest.address1) {
    address1.error = true;
    address1.helperMessage = 'address1Required';
  }
  if (corporateVehicle && !corporateRequest.corporateRegistrationNumber) {
    corporateRegistrationNumber.error = true;
    corporateRegistrationNumber.helperMessage = 'corporateRegRequired';
  }
  if (corporateVehicle && !corporateRequest.corporateType) {
    corporateType.error = true;
    corporateType.helperMessage = 'corporateTypeRequired';
  }
  if (corporateVehicle && !corporateRequest.address2) {
    address2.error = true;
    address2.helperMessage = 'address2Required';
  }
  if (corporateVehicle && !corporateRequest.zipCode) {
    zipCode.error = true;
    zipCode.helperMessage = 'zipCodeRequired';
  }
  if (corporateVehicle && !corporateRequest.city) {
    city.error = true;
    city.helperMessage = 'cityRequired';
  }
  if (corporateVehicle && !corporateRequest.country) {
    country.error = true;
    country.helperMessage = 'countryRequired';
  }

  if (
    !corporateName.error &&
    !address1.error &&
    !address2.error &&
    !zipCode.error &&
    !city.error &&
    !country.error &&
    !corporateType.error &&
    !corporateRegistrationNumber.error
  ) {
    try {
      if (!individualVehicleId) {
        yield call(createVehicle, individualRequest);
      } else {
        yield call(updateVehicle, individualVehicleId, individualRequest);
      }

      if (!corporateVehicleId) {
        yield call(createVehicle, corporateRequest);
      } else {
        yield call(updateVehicle, corporateVehicleId, corporateRequest);
      }

      const request = {
        email: profile.email,
        userStep: !profile.registerStep
          ? 'IDENTITY'
          : profile.registerStep === 'VEHICLE'
          ? 'INVESTMENT'
          : profile.registerStep,
      };
      yield call(updaStepProfile, request);
      yield put(actions.updateVehicleSuccessAction());
    } catch (err) {
      let errorMessage;
      if (err.response.errors && err.response.errors.length) {
        errorMessage = err.response.errors[0].message || 'unexpectedError';
      } else {
        errorMessage = err.response.data.messages[0] || 'unexpectedError';
      }
      yield put(
        showToast({
          message: errorMessage,
          type: 'error',
        }),
      );
    } finally {
      put(setLoadingAction(false));
    }
  }

  yield put(
    actions.updateFormStateVehiclesAction([
      { field: 'corporateName', state: corporateName },
      { field: 'address1', state: address1 },
      { field: 'address2', state: address2 },
      { field: 'zipCode', state: zipCode },
      { field: 'city', state: city },
      { field: 'country', state: country },
      {
        field: 'corporateRegistrationNumber',
        state: corporateRegistrationNumber,
      },
      { field: 'corporateType', state: corporateType },
    ]),
  );
  put(setLoadingAction(false));
}

function* updateStepAction(action) {
  put(setLoadingAction(true));
  let { profile } = yield select(makeSelectRegister());
  if (!profile) {
    profile = yield call(me);
  }
  if (!profile) {
    return;
  }

  if (profile.registerStep === 'VEHICLE') {
    const states: FormState[] = { ...action.payload };
    const individualVehicleId: string = states['individualVehicleId'];
    const individualRequest: VehicleRequest = {
      userId: profile.userId,
      status: 'ACTIVE',
      vehicleType: 'INDIVIDUAL',
    };
    if (!individualVehicleId) {
      yield call(createVehicle, individualRequest);
    } else {
      const individualRequest: VehicleRequest = {
        userId: profile.userId,
        status: 'ACTIVE',
        vehicleType: 'INDIVIDUAL',
        vehicleId: individualVehicleId,
      };
      yield call(updateVehicle, individualVehicleId, individualRequest);
    }

    const request = {
      email: profile.email,
      userStep: 'INVESTMENT',
    };
    try {
      yield call(updaStepProfile, request);
    } finally {
      put(setLoadingAction(false));
    }
  }
}

function* uploadDocumentRequest(action) {
  const { documentId, fileIds } = action.payload;
  yield call(updateDocumentFiles, documentId, fileIds);
  put(setLoadingAction(false));
}

export default function* registerVehiclesSaga() {
  yield takeLatest(ActionTypes.FETCH_VEHICLES_ACTION, fetchVehicles);
  yield takeLatest(ActionTypes.UPDATE_VEHICLE_ACTION, updateVehicles);
  yield takeLatest(ActionTypes.UPDATE_STEP_ACTION, updateStepAction);
  yield takeLatest(ActionTypes.UPLOAD_DOCUMENT_ACTION, uploadDocumentRequest);
}
