/*
 *
 * Tickets actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const cancelTicketAction = payload =>
  action(ActionTypes.CANCEL_TICKET, payload);

export const cancelTicketSuccesAction = payload =>
  action(ActionTypes.CANCEL_TICKET_SUCCESS_ACTION, payload);

export const cancelTicketFailedAction = payload =>
  action(ActionTypes.CANCEL_TICKET_FAILED_ACTION, payload);
