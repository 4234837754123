import { takeLatest, call, put, select } from 'redux-saga/effects';
import { showToast, setUserVehicle } from 'containers/App/actions';
import ActionTypes from './constants';
import { fetchUser } from 'services/api/profile-service';
import {
  fetchUserSuccessVehicleAction,
  updateUserVehicleSuccessAction,
  createUserVehicleSuccessAction,
} from './actions';
import {
  getVehicles,
  updateVehicle,
  createVehicle,
} from 'services/api/vehicle-service';

function* fetchVehicleRequest(action) {
  try {
    const response = yield call(getVehicles, action.payload);
    yield put(setUserVehicle(response));
    yield put(fetchUserSuccessVehicleAction(response));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* updateVehicleRequest(action) {
  const { vehicleId, request } = action.payload;
  try {
    const response = yield call(updateVehicle, vehicleId, request);
    const updatedVehiclesResponse = yield call(getVehicles, response?.userId);
    yield put(setUserVehicle(updatedVehiclesResponse));
    yield put(updateUserVehicleSuccessAction(response));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* createVehicleRequest(action) {
  try {
    const response = yield call(createVehicle, action.payload);
    yield put(createUserVehicleSuccessAction(response));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* profileVehiclelSaga() {
  yield takeLatest(ActionTypes.FETCH_USER_VEHICLE, fetchVehicleRequest);
  yield takeLatest(ActionTypes.UPDATE_USER_VEHICLE, updateVehicleRequest);
  yield takeLatest(ActionTypes.CREATE_USER_VEHICLE, createVehicleRequest);
}
