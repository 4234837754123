import React, { memo, useEffect, useState } from "react";
import { Dialog, Grid, DialogTitle, DialogContent, Typography, DialogActions, Button, Select, IconButton, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import ListCompanyDocumentGroups from "gql/document/ListCompanyDocumentGroups.gql";
import ListDealDocumentGroups from "gql/document/ListDealDocumentGroups.gql";
import MoveDocumentProjectByIdMutation from "gql/document/MoveDocumentProjectById.gql";
import MoveDocumentFileByIdMutation from "gql/document/MoveDocumentFileById.gql";

interface DocumentToMove {
    currentCategoryId: string;
    currentGroupId: string;
    currentProjectId: string;
    file?: any;
    doc?: any;
}

interface Props {
	open: boolean;
	onSave: () => void;
	onCancel: () => void;
    documentToMove: DocumentToMove;
    isCompany: boolean;
    companyId?: string;
    dealId?: string;
}

function DocumentMoveDialog(props: Props) {
	const intl = useIntl();
	const { open, onCancel, onSave, documentToMove, isCompany, companyId, dealId } = props;

	const [err, setErr] = useState("");
	const [categoryId, setCategoryId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [type, setType] = useState("");
    const [projectId, setProjectId] = useState("");
    const [projectList, setProjectList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [calculatedCompanyId, setCalculatedCompanyId] = useState("");

    const categoryList = [
        {
            id: 'publicDocument',
            name: intl.messages["publicDocuments"]
        },
        {
            id: 'privateDocument',
            name: intl.messages["privateDocuments"]
        },
        {
            id: 'publicReport',
            name: intl.messages["publicReports"]
        },
        {
            id: 'privateReport',
            name: intl.messages["privateReports"]
        }
    ]

    const gqlVariables = {
        dealId: dealId || null,
        companyId: companyId || null,
        isPublic: categoryId === 'publicDocument' || categoryId === 'publicReport',
        type: categoryId === 'publicDocument' || categoryId === 'privateDocument' ? 'DOCUMENT' : 'REPORT'
    }

    if(isCompany) {
        delete gqlVariables.dealId;
    }

    const { loading: loadingGroupList, error: errorGroupList, data: dataGroupList, refetch: refetchGroupList } = useQuery(isCompany? ListCompanyDocumentGroups : ListDealDocumentGroups, {
        notifyOnNetworkStatusChange: true,
        variables: {...gqlVariables, companyId: calculatedCompanyId},
    });

    const [moveDocumentProjectById] = useMutation(MoveDocumentProjectByIdMutation);
    const [MoveDocumentFileById] = useMutation(MoveDocumentFileByIdMutation);


    useEffect(() => {
        if (documentToMove) {
            setCategoryId(documentToMove?.currentCategoryId || "");
            setGroupId(documentToMove?.currentGroupId || "");
            setProjectId(documentToMove?.currentProjectId || "");
            setType(props.documentToMove?.file ? 'file' : 'project');
        }
    }, [documentToMove]);
    
    useEffect(() => {
        if (groupId && groupList?.length > 0) {
            setProjectList(groupList?.find(group => group.id === groupId)?.documents || []);
        }
    }, [groupId]);

    useEffect(() => {
        if(categoryId) {
            refetchGroupList();
        }
    }, [categoryId]);

    useEffect(() => {
        if(companyId) {
            setCalculatedCompanyId(companyId);
        }
    }, [companyId, dealId]);

    useEffect(() => {
        if(documentToMove && dataGroupList) {
            const mergedGroupList = [...dataGroupList?.company_document_group || [], ...dataGroupList?.deal_document_group || []];
            const groupList = mergedGroupList?.map(group => {
                return { id: group.documentGroup.documentGroupId, name: group.documentGroup.title, documents: group.documentGroup.documents.map(doc => {
                    return {
                        id: doc.document.documentId,
                        name: doc.document.title
                    }
                }) }
            }) || [];
            setGroupList(groupList);

            if (groupId && groupList?.length > 0) {
                setProjectList(groupList?.find(group => group.id === groupId)?.documents || []);
            }
        }
    }, [dataGroupList]);

	const handeSave = () => {
        const mutation = type === 'file' ? MoveDocumentFileById : moveDocumentProjectById;
        const variables = type === 'file' ? {
            fileId: documentToMove?.file?.fileId,
            documentId: projectId
        } : {
            id: documentToMove?.doc?.documentId,
            documentGroupId: groupId
        };
        mutation({
            variables: variables
        }).then(() => {
            setErr("");
            onCancel();
            onSave();
        }).catch(err => {
            setErr(err.message);
        });
	};

	const handleCancel = () => {
		onCancel();
		setErr("");
        setCategoryId("");
        setGroupId("");
        setProjectId("");
	};

    const handleSelectCategory = (e) => {
        const { value } = e.target;
        setCategoryId(value);
        setGroupId("");
        setProjectId("");
        setProjectList([]);
    }

    const handleSelectGroup = (e) => {
        const { value } = e.target;
        setGroupId(value);
        setProjectId("");
    }

    const handleSelectProject = (e) => {
        const { value } = e.target;
        setProjectId(value);
    }

	return (
		<Dialog open={open} fullWidth>
			<DialogTitle id="alert-dialog-title">
				<Grid container alignItems="center" spacing={2}>
					<Grid item xs>
						<Typography style={{ paddingTop: 4, fontSize: "1.25rem" }}>{type === 'file'? `${intl.messages["moveDocument"]}` : `${intl.messages["moveDocumentProject"]}`}: {documentToMove.file? documentToMove.file.title || documentToMove.file.name : documentToMove.doc.title}</Typography>
					</Grid>
					<Grid item>
						<IconButton onClick={handleCancel}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Typography style={{ marginBottom: 6 }}>
					{`${intl.messages["category"]}`}
					<span style={{ color: "red" }}>*</span>
				</Typography>
                <Select disableUnderline fullWidth value={categoryId} onChange={handleSelectCategory}>
                    {categoryList.map((category, index) => (
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
                { groupList?.length > 0 && 
                    <>
                        <Typography style={{ marginBottom: 6 }}>
                            {`${intl.messages["group"]}`}
                            <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Select disableUnderline fullWidth value={groupId} onChange={handleSelectGroup}>
                            {groupList?.map((group, index) => (
                                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                            ))}
                        </Select>
                    </>
                }
                { type === 'file' && projectList.length > 0 &&
                <>
                    <Typography style={{ marginBottom: 6 }}>
                        {`${intl.messages["project"]}`}
                    </Typography>
                    <Select disableUnderline fullWidth value={projectId} onChange={handleSelectProject}>
                        {projectList.map((project, index) => (
                            <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                        ))}
                    </Select>
                </>
                }   

				{err && <Typography style={{ color: "red", paddingTop: 6, fontSize: "0.8rem" }}>{err}</Typography>}
			</DialogContent>
			<DialogActions style={{ marginTop: 40 }}>
				<Button color="primary" variant="outlined" onClick={handleCancel}>
					{`${intl.messages["cancel"]}`.toUpperCase()}
				</Button>
				<Button color="primary" variant="contained" onClick={handeSave} disabled={!groupId || (type === 'file' && !projectId)}>
					{`${intl.messages["move"]}`.toUpperCase()}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default memo(DocumentMoveDialog);
