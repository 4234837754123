import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';

type Props = {
  article: { id: string; description: string; title: string };
};

const CustomSnackBar = (props: Props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      style={{
        backgroundColor: '#2D2D2D',
        color: '#F6F6F6',
        marginTop: '10px',
        padding: '10px',
        borderRadius: '3px',
        border: '0.5px solid #333333',
      }}
    >
      {/* <div style={{ height: 'auto' }}> */}
      {/* <h4
          style={{
            fontSize: '17px',
            padding: '0.5rem',
            margin: '0px',
            fontWeight: '700',
            display: 'inline-block',
            color: isOpen ? '#794CFF' : '#F6F6F6',
          }}
        >
          Can't find what you are looking for?
        </h4> */}
      {/* <div
          style={{ float: 'right', padding: '0.5rem', cursor: 'pointer' }}
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          {!isOpen ? (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.622 4.63273H7.48451V1.37805C7.48451 0.617012 6.8675 0 6.10646 0C5.34542 0 4.72841 0.617012 4.72841 1.37805V4.63273H1.37805C0.617012 4.63273 0 5.24974 0 6.01078C0 6.77182 0.617012 7.38883 1.37805 7.38883H4.72841V10.622C4.72841 11.383 5.34542 12 6.10646 12C6.8675 12 7.48451 11.383 7.48451 10.622V7.38883H10.622C11.383 7.38883 12 6.77182 12 6.01078C12 5.24965 11.3831 4.63273 10.622 4.63273Z"
                fill="#999999"
              />
            </svg>
          ) : (
            <svg
              width="12"
              height="3"
              viewBox="0 0 12 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.622 0H7.48451L4.72841 0H1.37805C0.617012 0 0 0.617012 0 1.37805C0 2.13909 0.617012 2.7561 1.37805 2.7561H4.72841H7.48451H10.622C11.383 2.7561 12 2.13909 12 1.37805C12 0.616921 11.3831 0 10.622 0Z"
                fill="#794CFF"
              />
            </svg>
          )}
        </div> */}
      <Grid container style={{ alignItems: 'center', padding: '0.5rem' }}>
        <Grid xs={10}>
          <h4 style={{ margin: '0px', color: isOpen ? '#794CFF' : '#F6F6F6' }}>
            {props.article.title}
          </h4>
        </Grid>
        <Grid style={{ textAlign: 'right' }} xs={2}>
          <Button
            onClick={() => {
              setOpen(!isOpen);
            }}
          >
            {!isOpen ? (
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.622 4.63273H7.48451V1.37805C7.48451 0.617012 6.8675 0 6.10646 0C5.34542 0 4.72841 0.617012 4.72841 1.37805V4.63273H1.37805C0.617012 4.63273 0 5.24974 0 6.01078C0 6.77182 0.617012 7.38883 1.37805 7.38883H4.72841V10.622C4.72841 11.383 5.34542 12 6.10646 12C6.8675 12 7.48451 11.383 7.48451 10.622V7.38883H10.622C11.383 7.38883 12 6.77182 12 6.01078C12 5.24965 11.3831 4.63273 10.622 4.63273Z"
                  fill="#999999"
                />
              </svg>
            ) : (
              <svg
                width="12"
                height="3"
                viewBox="0 0 12 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.622 0H7.48451L4.72841 0H1.37805C0.617012 0 0 0.617012 0 1.37805C0 2.13909 0.617012 2.7561 1.37805 2.7561H4.72841H7.48451H10.622C11.383 2.7561 12 2.13909 12 1.37805C12 0.616921 11.3831 0 10.622 0Z"
                  fill="#794CFF"
                />
              </svg>
            )}
          </Button>
        </Grid>
      </Grid>
      {/* </div> */}
      <div
        dangerouslySetInnerHTML={{ __html: props.article.description }}
        style={{
          padding: '0.5rem',
          display: !isOpen ? 'none' : 'block',
          fontSize: '16px',
        }}
      ></div>
    </div>
  );
};

export default CustomSnackBar;
