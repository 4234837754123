import React, { memo, useState, useEffect } from 'react';
import {
  Grid,
  Button,
  FormControl,
  FormLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEqual } from 'lodash';

import usePrevious from 'utils/hooks/usePrevious';
import { Additional as AdditionalType } from 'models/additional';

interface Props {
  onChange: (value: any) => void;
  defaultValue?: AdditionalType[];
  languageCode: string;
}

function Additional(props: Props) {
  const intl = useIntl();
  const { onChange, defaultValue, languageCode } = props;
  const prevDefaultValue: any = usePrevious({ defaultValue });

  const [additionals, setAdditionals] = useState<AdditionalType[]>(
    defaultValue || [],
  );

  useEffect(() => {
    if (!isEqual(prevDefaultValue?.defaultValue, defaultValue)) {
      setAdditionals(defaultValue || []);
    }
  }, [defaultValue]);

  const onChangeAdditional = (key, index) => e => {
    const newValue = [...additionals];
    newValue[index][key] = e.target.value;
    setAdditionals(newValue);
    onChange(newValue);
  };

  const addNewForm = () => {
    const newValue = [...additionals, { key: '', value: '' }];
    setAdditionals(newValue);
    onChange(newValue);
  };

  const onRemoveForm = index => () => {
    const newValue = [...additionals];
    newValue.splice(index, 1);
    setAdditionals(newValue);
    onChange(newValue);
  };

  return (
    <>
      <Grid item xs={12} sm={12} style={{ paddingBottom: 0 }}>
        <Typography>
          <FormattedMessage id="additionalList" />
          {languageCode !== 'en' && (
            <>
              {' '}
              <FormattedMessage id={`${languageCode}Abbr`} />
            </>
          )}
        </Typography>
      </Grid>
      {additionals.map((additional, index) => {
        return (
          <>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <FormLabel style={{ color: 'black', marginBottom: '5px' }}>
                  <FormattedMessage id="title" />
                </FormLabel>
                <OutlinedInput
                  inputProps={{ maxLength: 20 }}
                  value={additional.key}
                  onChange={onChangeAdditional('key', index)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>
                <FormLabel style={{ color: 'black', marginBottom: '5px' }}>
                  <FormattedMessage id="summary" />
                </FormLabel>
                <OutlinedInput
                  inputProps={{ maxLength: 20 }}
                  value={additional.value}
                  onChange={onChangeAdditional('value', index)}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                display: 'flex',
                paddingTop: 25,
              }}
            >
              <Button
                size={'small'}
                color="primary"
                variant="outlined"
                onClick={onRemoveForm(index)}
              >
                {`${intl.messages['delete']}`.toUpperCase()}
              </Button>
            </Grid>
          </>
        );
      })}

      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={addNewForm}
        >
          <FormattedMessage id="add" />
        </Button>
      </Grid>
    </>
  );
}

export default memo(Additional);
