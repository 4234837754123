import React, { memo, useRef, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Card, Checkbox } from '@material-ui/core';
import Select from 'components/Select';
import TagsSelector from 'components/TagsSelector';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearBtn: {
      color: '#D74F4F',
      '&:hover': {
        color: '#B74F4F',
      },
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: '1rem',
      marginBottom: 0,
    },
    root: {},
    filterContainer: {
      display: 'inline-flex',
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      marginRight: 5,
      marginBottom: 0,
    },
    checkBoxTitle: {
      padding: "9px 0",
      marginBottom: 0,
    },
    checkBoxActive: {
      color: '#6DB595',
    },
  }),
);

interface Props {
  onChange: (filter) => void;
}

function Filter(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const { onChange } = props;
  const [filter, setFilter] = React.useState<string[]>(['ACTIVE', 'INACTIVE']);

  const handleCheckboxChange = (ev, value) => {
    if (ev.target.checked) {
      setFilter(pre => {
        return [...pre, value];
      });
    } else {
      setFilter(pre => {
        return pre.filter(item => item !== value);
      });
    }
  };

  useEffect(() => {
    onChange(filter);
  }, [filter]);
  return (
    <Card variant="outlined">
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.titleText}>
            <FormattedMessage id="filter" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.filterContainer}>
          <Typography className={classes.title}>
            <FormattedMessage id="status" />
          </Typography>
          <Checkbox
            checked={filter.includes('ACTIVE')}
            onChange={ev => handleCheckboxChange(ev, 'ACTIVE')}
          />
          <Typography
            className={`${classes.checkBoxTitle} ${classes.checkBoxActive}`}
          >
            <FormattedMessage id="active" />
          </Typography>
          <Checkbox
            checked={filter.includes('INACTIVE')}
            onChange={ev => handleCheckboxChange(ev, 'INACTIVE')}
          />
          <Typography className={classes.checkBoxTitle}>
            <FormattedMessage id="inactive" />
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default memo(Filter);
