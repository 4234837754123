import { takeLatest, call, put, select } from 'redux-saga/effects';
import { createInvestor as createInvestorRequest } from 'services/api/profile-service';
import { showToast } from 'containers/App/actions';
import { setRefreshPage } from 'containers/Investors/actions';
import { validateInvestor, setLoading, setError } from './actions';
import ActionTypes from './constants';
import makeSelectInvestorCreateDialog from './selectors';

function* createInvestor(action) {
  try {
    yield put(validateInvestor(action.payload));
    const data = yield select(makeSelectInvestorCreateDialog());
    if (!data.error.firstName && !data.error.lastName && !data.error.email) {
      yield call(createInvestorRequest, action.payload);
      yield put(setLoading(false));
      yield put(setRefreshPage(true));
      yield put(
        showToast({
          message: 'investorHasBeenSuccessfullyCreated',
          type: 'success',
        }),
      );
    }
  } catch (err) {
    if (
      err.response.data.messages.findIndex(
        message => message === 'email.invalid.existed',
      ) >= 0
    ) {
      yield put(setError({ email: 'emailMustBeUnique' }));
    }
    yield put(setLoading(false));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* companyCreateDialogSaga() {
  yield takeLatest(ActionTypes.CREATE_INVESTOR, createInvestor);
}
