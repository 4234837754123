/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum ActionTypes {
  SHOW_TOAST = 'JADESQUARE/App/SHOW_TOAST',
  LOGIN_SUCCESS = 'JADESQUARE/App/LOGIN_SUCCESS',
  LOGOUT = 'JADESQUARE/App/LOGOUT',
  SET_USER_DATA = 'JADESQUARE/App/SET_USER_DATA',
  SET_USER_VEHICLE = 'JADESQUARE/App/SET_USER_VEHICLE',
  SET_PREFERENCE = 'JADESQUARE/App/SET_PREFERENCE',
  GET_COUNTRIES = 'JADESQUARE/App/GET_COUNTRIES',
  GET_COUNTRIES_SUCCEED = 'JADESQUARE/App/GET_COUNTRIES_SUCCEED',
  REQUEST_DOWNLOAD = 'JADESQUARE/App/REQUEST_DOWNLOAD',
  REQUEST_DOWNLOAD_SINGLE_FILE = 'JADESQUARE/App/REQUEST_DOWNLOAD_SINGLE_FILE',
  REQUEST_PREVIEW_SINGLE_FILE = 'JADESQUARE/App/REQUEST_PREVIEW_SINGLE_FILE',
  DOWNLOAD_FINISHED = 'JADESQUARE/App/DOWNLOAD_FINISHED',
  PREVIEW_DOWNLOAD_STARTED = 'JADESQUARE/App/PREVIEW_DOWNLOAD_STARTED',
  PREVIEW_DOWNLOAD_FINISHED = 'JADESQUARE/App/PREVIEW_DOWNLOAD_FINISHED',
  HANDLE_TOGGLE_DOC_VIEWER = 'JADESQUARE/App/HANDLE_TOGGLE_DOC_VIEWER',
}

export default ActionTypes;
