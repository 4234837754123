import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import { BACKEND_API_URL } from './../../env';
const instance = createService(BACKEND_API_URL);

export async function getCommittedInvestors(request: {
  dealId: string;
  interestsAny?: any[];
  page?: number;
  pageSize?: number;
  searchKey?: string;
  status?: string;
}): Promise<any> {
  return instance
    .post('/users/committed', request)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return {
        data: resp.data.data,
        total: resp.data.metaData.total,
      };
    });
}
