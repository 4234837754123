/*
 *
 * GetStarted
 *
 */

import React, { memo } from 'react';
import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import InvestmentOptions from 'containers/InvestmentsPage/Components/InvestmentOptions';

import stepImgDealFlow from 'images/get-started/step-dealflow.jpg';
import stepImgDealName from 'images/get-started/step-dealname.jpg';
import stepImgInvestmentParameters from 'images/get-started/step-investment-parameters.jpg';
import stepImgInvestInThisDeal from 'images/get-started/step-invest-in-this-deal.jpg';
import stepImgHelp from 'images/get-started/step-help.jpg';

import getStartedWithJadeSquareImg from 'images/our-tech-image.jpg';
import dueDiligenceSupportImg from 'images/singapore-image.jpg';
import globeIcon from 'images/icons/globe.svg';

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },
  biggerText: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
  h1: {
    marginBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '2rem',
    },
  },
  stepSection: {
    paddingBottom: '5rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '2rem',
    },
    '& a': {
      color: 'var(--color-light-grey)',
    },
  },
  spacedContainer: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },
  stepContent: {
    paddingBottom: '10px',
  },
  stepImg: {
    width: '100%',
    height: 'auto',
  },
  colorBox: {
    backgroundColor: 'var(--color-dark-purple)',
    color: 'var(--color-light-grey)',
    padding: '2.2rem',
  },
  globeIconWrapper: {
    paddingBottom: '1rem',
  },
  orderSm1: {
    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  orderSm2: {
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  orderMd1: {
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  orderMd2: {
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  hiddenMdDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function GetStarted() {
  const classes = useStyle();
  const intl = useIntl();

  const scope = 'GetStarted';

  return (
    <Container className="main">
      <div>
        <h1 className={`h2 text-center ${classes.h1}`}>
          <FormattedMessage id={`${scope}.jadeSquareQuickStartGuide`} />
        </h1>
      </div>
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <section className={classes.stepSection}>
          <h2 className="h5">
            <FormattedMessage id={`${scope}.dealFlow`} />
          </h2>
          <div className={classes.stepContent}>
            <FormattedMessage id={`${scope}.dealFlowContent`} />
          </div>
          <div>
            <img
              src={stepImgDealFlow}
              className={classes.stepImg}
              alt={`${intl.messages[`${scope}.dealFlow`]}`}
            />
          </div>
        </section>
        <section className={classes.stepSection}>
          <h2 className="h5">
            <FormattedMessage id={`${scope}.dealName`} />
          </h2>
          <div className={classes.stepContent}>
            <FormattedMessage id={`${scope}.dealNameContent`} />
          </div>
          <div>
            <img
              src={stepImgDealName}
              className={classes.stepImg}
              alt={`${intl.messages[`${scope}.dealName`]}`}
            />
          </div>
        </section>
        <section className={classes.stepSection}>
          <h2 className="h5">
            <FormattedMessage id={`${scope}.investmentParameters`} />
          </h2>
          <div className={classes.stepContent}>
            <FormattedMessage id={`${scope}.investmentParametersContent`} />
          </div>
          <div>
            <img
              src={stepImgInvestmentParameters}
              className={classes.stepImg}
              alt={`${intl.messages[`${scope}.investmentParameters`]}`}
            />
          </div>
        </section>
        <section className={classes.stepSection}>
          <h2 className="h5">
            <FormattedMessage id={`${scope}.investInThisDeal`} />
          </h2>
          <div className={classes.stepContent}>
            <FormattedMessage id={`${scope}.investInThisDealContent`} />
          </div>
          <div>
            <img
              src={stepImgInvestInThisDeal}
              className={classes.stepImg}
              alt={`${intl.messages[`${scope}.investInThisDeal`]}`}
            />
          </div>
        </section>
        <section className={classes.stepSection}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className={classes.orderSm2}>
              <h2 className="h5">
                <FormattedMessage id={`${scope}.help`} />
              </h2>
              <div
                className={classes.stepContent}
                dangerouslySetInnerHTML={{
                  __html: `${intl.messages[`${scope}.helpContent`]}`,
                }}
              ></div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.orderSm1}>
              <img
                src={stepImgHelp}
                className={classes.stepImg}
                alt={`${intl.messages[`${scope}.help`]}`}
              />
            </Grid>
          </Grid>
        </section>
      </Container>

      { false && <>
        <Container style={{ padding: 0 }}>
          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6}>
              <h2 className={`h2`}>
                <FormattedMessage id={`${scope}.getStartedWithJadeSquare`} />
              </h2>
              <p>
                <FormattedMessage id={`${scope}.getStartedWithJadeSquareText1`} />
              </p>
            </Grid>
            <Grid item xs={12} md={6} className={classes.hiddenMdDown}></Grid>
          </Grid>
          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6}>
              <img
                src={getStartedWithJadeSquareImg}
                alt={`${intl.messages[`${scope}.getStartedWithJadeSquare`]}`}
                className={classes.stepImg}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p>
                <FormattedMessage id={`${scope}.getStartedWithJadeSquareText2`} />
              </p>
              <p>
                <FormattedMessage id={`${scope}.getStartedWithJadeSquareText3`} />
              </p>
            </Grid>
          </Grid>
          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6}>
              <div style={{ fontWeight: 600 }} className={classes.biggerText}>
                JADESQUARE
              </div>
              <h2>
                <FormattedMessage id={`${scope}.investmentPhilosophy`} />
              </h2>
              <p>
                <FormattedMessage id={`${scope}.investmentPhilosophyText1`} />
              </p>
              <p>
                <FormattedMessage id={`${scope}.investmentPhilosophyText2`} />
              </p>
              <p className={`${classes.bold} ${classes.biggerText}`}>
                <FormattedMessage id={`${scope}.investmentPhilosophyText3`} />
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.colorBox}>
                <h2>
                  <FormattedMessage id={`${scope}.5sAnd3mTitle`} />
                </h2>
                <p>
                  <FormattedMessage id={`${scope}.5sAnd3mText`} />
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>
                    Seiri<br/>(<FormattedMessage id={`${scope}.sort`} />)
                  </h3>
                  <p>
                    <FormattedMessage id={`${scope}.sortText`} />
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>
                    Seiton<br/>(<FormattedMessage id={`${scope}.setInOrder`} />)
                  </h3>
                  <p>
                    <FormattedMessage id={`${scope}.setInOrderText`} />
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>
                    Seis​​o<br/>(<FormattedMessage id={`${scope}.shine`} />)
                  </h3>
                  <p>
                    <FormattedMessage id={`${scope}.shineText`} />
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>
                    Seiketsu<br/>(<FormattedMessage id={`${scope}.standardize`} />)
                  </h3>
                  <p>
                    <FormattedMessage id={`${scope}.standardizeText`} />
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>
                    Shitsuke<br/>(<FormattedMessage id={`${scope}.sustain`} />)
                  </h3>
                  <p>
                    <FormattedMessage id={`${scope}.sustainText`} />
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <p className={classes.bold}>
                <FormattedMessage id={`${scope}.5sText1`} />
              </p>
              <p>
                <FormattedMessage id={`${scope}.5sText2`} />
              </p>
              <p className={classes.bold}>
                <FormattedMessage id={`${scope}.5sText3`} />
              </p>
            </Grid>
          </Grid>

          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6} className={classes.orderMd2}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>Muda</h3>
                  <p>
                    <FormattedMessage id={`${scope}.mudaText`} />
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>Mura</h3>
                  <p>
                    <FormattedMessage id={`${scope}.muraText`} />
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.globeIconWrapper}>
                    <img src={globeIcon} alt="5S" />
                  </div>
                  <h3 className={classes.biggerText}>Muri</h3>
                  <p>
                    <FormattedMessage id={`${scope}.muriText`} />
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.orderMd1}>
              <p>
                <FormattedMessage id={`${scope}.3mText`} />
              </p>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="md" style={{ padding: 0, textAlign: 'center' }}>
          <div className={classes.spacedContainer}>
            <h2>
              <FormattedMessage id={`${scope}.theStoryOfJadeSquare`} />
            </h2>
            <p>
              <FormattedMessage id={`${scope}.theStoryOfJadeSquareText`} />
            </p>
          </div>
        </Container>

        <Container style={{ padding: 0 }}>
          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6}>
              <img
                src={dueDiligenceSupportImg}
                className={classes.stepImg}
                alt={`${intl.messages[`${scope}.dueDiligenceSupport`]}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <h2>
                <FormattedMessage id={`${scope}.dueDiligenceSupport`} />
              </h2>
              <p>
                <FormattedMessage id={`${scope}.dueDiligenceSupportText1`} />
              </p>
              <div className={classes.bold}>
                <FormattedMessage id={`${scope}.singaporeAdvantage`} />
              </div>
              <p>
                <FormattedMessage id={`${scope}.dueDiligenceSupportText2`} />
              </p>
            </Grid>
          </Grid>

          <Grid className={classes.spacedContainer} container spacing={4}>
            <Grid item xs={12} md={6}>
              <div className={classes.bold}>
                <FormattedMessage id={`${scope}.liquidityOptions`} />
              </div>
              <p>
                <FormattedMessage id={`${scope}.liquidityOptionsText`} />
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.bold}>
                <FormattedMessage id={`${scope}.2020Investments`} />
              </div>
              <p>
                <FormattedMessage id={`${scope}.2020InvestmentsText`} />
              </p>
            </Grid>
          </Grid>
        </Container>

        <InvestmentOptions />
      </>}

    </Container>
  );
}

export default memo(GetStarted);
