/*
 *
 * ProfileAbout constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ProfileAbout/DEFAULT_ACTION',

  FETCH_USER_DOCUMENT_ACTION = 'app/InvestorDetail/FETCH_USER_DOCUMENT_ACTION',
  FETCH_USER_DOCUMENT_SUCCESS_ACTION = 'app/InvestorDetail/FETCH_USER_DOCUMENT_SUCCESS_ACTION',
  UPDATE_PROFILE_SUCCESS_ACTION = 'app/InvestorDetail/UPDATE_PROFILE_SUCCESS_ACTION',
  HIDE_LOADING = 'app/InvestorDetail/HIDE_LOADING',
}

export default ActionTypes;
