/*
 *
 * AdminDocuments constants
 *
 */

enum ActionTypes {
  GET_NUMBERS_OF_DOCUMENTS = 'app/AdminDocuments/GET_NUMBERS_OF_DOCUMENTS',
  GET_DOCUMENTS = 'app/AdminDocuments/GET_DOCUMENTS',
  GET_NUMBERS_OF_DOCUMENTS_SUCCEED = 'app/AdminDocuments/GET_NUMBERS_OF_DOCUMENTS_SUCCEED',
  GET_DOCUMENTS_SUCCEED = 'app/AdminDocuments/GET_DOCUMENTS_SUCCEED',
  CREATE_GROUP = 'app/AdminDocuments/CREATE_GROUP',
  DELETE_GROUP = 'app/AdminDocuments/DELETE_GROUP',
  CREATE_DOCUMENT = 'app/AdminDocuments/CREATE_DOCUMENT',
  CREATE_DOCUMENT_SUCCEED = 'app/AdminDocuments/CREATE_DOCUMENT_SUCCEED',
  DELETE_DOCUMENT = 'app/AdminDocuments/DELETE_DOCUMENT',
  EDIT_DOCUMENT = 'app/AdminDocuments/EDIT_DOCUMENT',
  EDIT_DOCUMENT_SUCCEED = 'app/AdminDocuments/EDIT_DOCUMENT_SUCCEED',
  REQUEST_SIGN = 'app/AdminDocuments/REQUEST_SIGN',
  REQUEST_SIGN_SUCCEED = 'app/AdminDocuments/REQUEST_SIGN_SUCCEED',
  REFRESH_SIGN_GROUP = 'app/AdminDocuments/REFRESH_SIGN_GROUP',
  REFRESH_SIGN_DOCUMENT = 'app/AdminDocuments/REFRESH_SIGN_DOCUMENT',
  REFRESH_SIGN_GROUP_SUCCEED = 'app/AdminDocuments/REFRESH_SIGN_GROUP_SUCCEED',
  REFRESH_SIGN_DOCUMENT_SUCCEED = 'app/AdminDocuments/REFRESH_SIGN_DOCUMENT_SUCCEED',
  SET_LOADING = 'app/AdminDocuments/SET_LOADING',
  SET_LOADING_DOCUMENT = 'app/AdminDocuments/SET_LOADING_DOCUMENT',
  SEND_PASSWORD_ACTION = 'app/InvestorDocuments/SEND_PASSWORD_ACTION',
  MOVE_DOCUMENT_SUCCESS = 'app/AdminDocuments/MOVE_DOCUMENT_SUCCESS',
}

export default ActionTypes;
