/*
 *
 * Tickets constants
 *
 */

enum ActionTypes {
  CANCEL_TICKET = 'app/Invites/CANCEL_TICKET',
  CANCEL_TICKET_SUCCESS_ACTION = 'app/Invites/CANCEL_TICKET_SUCCESS_ACTION',
  CANCEL_TICKET_FAILED_ACTION = 'app/Invites/CANCEL_TICKET_FAILED_ACTION',
}

export default ActionTypes;
