import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the signRequest state domain
 */

const selectSignRequestDomain = (state: ApplicationRootState) => {
  return state.signRequest || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by SignRequest
 */

const makeSelectSignRequest = () =>
  createSelector(selectSignRequestDomain, substate => {
    return substate;
  });

export default makeSelectSignRequest;
export { selectSignRequestDomain };
