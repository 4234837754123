import React, { memo } from 'react';
import { Grid, Button, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  selected: number;
  matches: number;
  onSendMail: () => void;
  onSendCustomMail: () => void;
  dealStatus: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: '#888888',
      fontSize: '0.85rem',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

function Header(props: Props) {
  const intl = useIntl();
  const { selected, matches, onSendMail, onSendCustomMail, dealStatus } = props;
  const localScope = 'Invites.Header';
  const classes = useStyles();
  return (
    <Grid container style={{ paddingBottom: '11px' }}>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ fontSize: '0.8rem', alignItems: 'center', display: 'flex' }}
      >

        <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {matches}{' '}
            <FormattedMessage
              id={`${localScope}.matchedInvestors`}
              values={{ num: matches }}
            />
           {selected}{' '}
            <FormattedMessage
              id={`${localScope}.selectedInvestors`}
              values={{ num: selected }}
            /></span>
        </Typography>


      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {(dealStatus === 'OPEN' || dealStatus === 'PREVIEW') && (
          <Button
            size={'small'}
            variant="contained"
            color="primary"
            style={{ marginRight: 12 }}
            disabled={!selected}
            onClick={onSendCustomMail}
          >
            {`${intl.messages['sendEmail']}`.toUpperCase()}
          </Button>
        )}
        {(dealStatus === 'OPEN' || dealStatus === 'PREVIEW') && (
          <Button
            size={'small'}
            variant="contained"
            color="primary"
            disabled={!selected}
            onClick={onSendMail}
          >
            {`${intl.messages['resendInvitation']}`.toUpperCase()}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default memo(Header);
