import React, { memo, useEffect } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';

import { numberWithCommas } from './Detail';
import {
  ProgressWrapper,
  ProgressNumber,
  ProgressDescription,
} from '../styles';
import { useIntl } from 'react-intl';
import Deal from 'models/deal';

const CustomGridIteam = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 15px',
    borderRight: '1px solid var(--color-light-grey)',
    borderLeft: '1px solid var(--color-light-grey)',
    marginLeft: '-1px',
    '&:first-child': {
      borderLeft: 0,
    },
    '&:last-child': { 
      borderRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 40px',
    },
  },
}))(Grid);

CustomGridIteam.defaultProps = {
  item: true,
  xs: 'auto',
};

interface Props {
  deal: Deal;
  statistics?: any;
}

function Progress(props: Props) {
  const intl = useIntl();
  const { deal, statistics } = props;

  const getDayLeft = () => {
    if (deal.refClosingDate) {
      const formattedRefClosingDate = moment(deal.refClosingDate).format(
        'DD-MM-YYYY',
      );

      const day = formattedRefClosingDate.split('-');
      const newDate = `${day[2]}-${day[1]}-${day[0]}`;
      const newDay = Date.parse(newDate);
      const dayLeft = (newDay - Date.now()) / 86400000;
      return Math.max(Math.round(dayLeft), 0);
    }
    return 0;
  };
  return (
    <ProgressWrapper>
      <Grid container justify="center">
        <CustomGridIteam>
          <ProgressNumber>
            {`$${numberWithCommas(deal?.totalSizeRound || 0) || 0}`}
          </ProgressNumber>
          <ProgressDescription>
            {`${intl.messages['roundSize']}`}
          </ProgressDescription>
        </CustomGridIteam>
        <CustomGridIteam>
          <ProgressNumber>
            {`$${numberWithCommas(
              statistics?.totalRaised?.aggregate?.sum?.amount || 0,
            ) || 0}`}
          </ProgressNumber>
          <ProgressDescription>
            {`${`${intl.messages['totalRaised']}`}`}
          </ProgressDescription>
        </CustomGridIteam>
        <CustomGridIteam>
          <ProgressNumber>{getDayLeft()}</ProgressNumber>
          <ProgressDescription>{`${intl.messages['daysLeft']}`}</ProgressDescription>
        </CustomGridIteam>
        <CustomGridIteam>
          <ProgressNumber>
            {`${Math.round(
              ((statistics?.totalRaised?.aggregate?.sum?.amount || 0) /
                (deal.totalSizeRound || 1)) *
                100,
            )}%`}
          </ProgressNumber>
          <ProgressDescription>
            {`${intl.messages['funded']}`}
          </ProgressDescription>
        </CustomGridIteam>
        <CustomGridIteam>
          <ProgressNumber>
            {statistics?.approved?.aggregate?.count || 0}
          </ProgressNumber>
          <ProgressDescription>{`${intl.messages['tickets']}`}</ProgressDescription>
        </CustomGridIteam>
      </Grid>
    </ProgressWrapper>
  );
}

export default memo(Progress);
