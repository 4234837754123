/**
 *
 * AlertDialog
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import {
  Button,
  Grid,
  Typography,
  Input,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import TagsSelector from 'components/TagsSelector';
import Loading from 'components/Loading';

import PhoneInput from 'components/FormControl/phoneInput';
import { clearFormAction, createInvestor } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import makeSelectInvestorCreateDialog from './selectors';
import { makeSelectPreferences } from 'containers/App/selectors';

const stateSelector = createStructuredSelector({
  state: makeSelectInvestorCreateDialog(),
  preferences: makeSelectPreferences(),
});

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
  title: {
    paddingTop: 4,
  },
  image: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  deleteBtn: {
    margin: 8,
  },
  requireText: {
    color: '#D74F4F',
  },
  errorInput: {
    borderColor: '#D74F4F',
  },
  errorText: {
    color: '#D74F4F',
  },
  button: {
    margin: 12,
  },
}));

interface Props {
  open: boolean;
  onCancel: () => void;
}

function InvestorCreateDialog(props: Props) {
  const intl = useIntl();
  const { open, onCancel } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  useInjectReducer({ key: 'investorCreateDialog', reducer: reducer });
  useInjectSaga({ key: 'investorCreateDialog', saga: saga });
  const { state, preferences } = useSelector(stateSelector);
  const { error, loading } = state;

  const [firstName, setFirstName] = useState<any>('');
  const [lastName, setLastName] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [phone, setPhone] = useState<any>('');
  const [tags, setTags] = useState<any>([]);

  const onConfirm = () => {
    const request = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phone: phone.trim(),
      preferenceTagIds: tags,
    };
    dispatch(createInvestor(request));
  };

  useEffect(() => {
    dispatch(clearFormAction());
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setTags([]);
  }, [open]);

  const onClose = () => {
    onCancel();
  };

  const onSelect = value => {
    setTags(value);
  };

  const scope = 'InvestorCreateDialog';

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <InsertDriveFileOutlinedIcon />
            </Grid>
            <Grid item xs>
              <FormattedMessage id={`${scope}.addNewInvestor`} />
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography>
                <FormattedMessage id={`model.firstName`} />{' '}
                <span className={classes.requireText}>*</span>
              </Typography>
              <Input
                onChange={e => setFirstName(e.target.value)}
                autoComplete={'off'}
                className={`${classes.input} ${
                  error.firstName ? classes.errorInput : ''
                }`}
                inputProps={{ maxLength: 100 }}
                disableUnderline
              />
              {error.firstName && (
                <Typography className={classes.errorText}>
                  <FormattedMessage id={`error.firstNameRequired`} />
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <FormattedMessage id={`model.lastName`} />{' '}
                <span className={classes.requireText}>*</span>
              </Typography>
              <Input
                onChange={e => setLastName(e.target.value)}
                autoComplete={'off'}
                className={`${classes.input} ${
                  error.lastName ? classes.errorInput : ''
                }`}
                disableUnderline
                inputProps={{ maxLength: 100 }}
              />
              {error.lastName && (
                <Typography className={classes.errorText}>
                  <FormattedMessage id={`error.lastNameRequired`} />
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                <FormattedMessage id={`model.email`} />
                <span className={classes.requireText}>*</span>
              </Typography>
              <Input
                className={`${classes.input} ${
                  error.email ? classes.errorInput : ''
                }`}
                autoComplete={'off'}
                disableUnderline
                inputProps={{ maxLength: 100 }}
                onChange={e => setEmail(e.target.value)}
              />
              {error.email && (
                <Typography className={classes.errorText}>
                  <FormattedMessage id={`error.emailRequired`} />
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                onChange={value => setPhone(value)}
                title={`${intl.messages['model.phone']}`}
                formState={{
                  value: phone,
                  error: false,
                  helperMessage: undefined,
                }}
                type={'tel'}
                inputProps={{
                  maxLength: 15,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography>
                <FormattedMessage id={`model.preferences`} />
              </Typography>
              <TagsSelector
                onSelect={onSelect}
                option={preferences}
                defaultValue={[
                  '4a3a4f7f-2534-4d56-bf37-c106ecc1409a',
                  'fd627f60-2f91-40ea-8b56-8ce617c76223',
                  'e871011d-85cf-4da8-a6af-5cb8c6697104',
                  'e7649e16-c1b6-430f-889f-f254179406f5',
                  '679347f5-8ef5-4b1e-b106-450ad7127d31',
                  'dbe46736-97d2-4b66-aa02-b1e74d8e22cc',
                  'd62acc26-859c-43c5-b976-a5a28019b7b3',
                ]}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onConfirm}
              className={classes.button}
            >
              <FormattedMessage id={`${scope}.createAndSendInvitation`} />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {loading && <Loading fullScreen />}
    </>
  );
}

export default memo(InvestorCreateDialog);
