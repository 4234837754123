import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the investorDocuments state domain
 */

const selectInvestorDocumentsDomain = (state: ApplicationRootState) => {
  return state.investorDocuments || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InvestorDocuments
 */

const makeSelectInvestorDocuments = () =>
  createSelector(selectInvestorDocumentsDomain, substate => {
    return substate;
  });

export default makeSelectInvestorDocuments;
export { selectInvestorDocumentsDomain };
