import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: undefined,
  documents: undefined,
  needRefresh: false,
};

const profileAboutReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_USER_DOCUMENT_SUCCESS_ACTION:
        draft.documents = action.payload;
        break;
      case ActionTypes.UPDATE_PROFILE_SUCCESS_ACTION:
        draft.needRefresh = true;
        break;
      case ActionTypes.HIDE_LOADING:
        draft.needRefresh = false;
        break;
    }
  },
  initialState,
);

export default profileAboutReducer;
