import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the investorDetail state domain
 */

const selectInvestorDetailDomain = (state: ApplicationRootState) => {
  return state.investorDetail || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InvestorDetail
 */

const makeSelectInvestorDetail = () =>
  createSelector(selectInvestorDetailDomain, substate => {
    return substate;
  });

export default makeSelectInvestorDetail;
export { selectInvestorDetailDomain };
