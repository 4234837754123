import { call, put, takeLatest } from 'redux-saga/effects';
import { forgotPassword } from 'services/api/auth-service';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { callResetPasswordSucceed } from './actions';

function* resetPassword(action) {
  try {
    yield call(forgotPassword, action.payload);
    yield put(callResetPasswordSucceed());
  } catch (err) {
    yield put(showToast({ message: 'emailNotFound', type: 'error' }));
  }
}

// Individual exports for testing
export default function* forgotPasswordSaga() {
  yield takeLatest(ActionTypes.CALL_RESET_PASSWORD, resetPassword);
}
