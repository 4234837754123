/*
 *
 * DealList
 *
 */

import React, { memo } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Card, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TextSearch from 'components/TextSearch';
import { Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    //padding: 4,
  },
  card: {
    padding: '0px 5px',
    width: '100%',
    borderRadius: '5px',
  },
}));

interface Props {
  onChange: (ev) => void;
  [propName: string]: any;
}

function SearchInput(props: Props) {
  const classes = useStyles();
  const { onChange, ...rest } = props;
  return (
    <Card variant="outlined" className={classes.card}>
      <TextSearch
        placeholder={'Search'}
        className={classes.searchBox}
        onChange={onChange}
        disableUnderline
        startAdornment={
          <InputAdornment
            style={{ color: 'var(--color-grey)' }}
            position="start"
          >
            <SearchIcon />
          </InputAdornment>
        }
        {...rest}
      />
    </Card>
  );
}

export default memo(SearchInput);
