import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the templateCreate state domain
 */

const selectTemplateCreateDomain = (state: ApplicationRootState) => {
  return state.templateCreateDialog || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by TemplateCreate
 */

const makeSelectTemplateCreate = () =>
  createSelector(selectTemplateCreateDomain, substate => {
    return substate;
  });

export default makeSelectTemplateCreate;
export { selectTemplateCreateDomain };
