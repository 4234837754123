import React, { memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
import Step from './step';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    currentStep: {
      paddingTop: '10px',
    },
  }),
);

interface Props {
  steps: any;
  step: number;
}

function RegisterProcess(props: Props) {
  const classes = useStyles();
  const { steps, step } = props;

  const renderedSteps = Object.keys(steps).map(key => (
    <Grid key={key} item xs={3} lg={12}>
      <Step
        step={parseInt(key)}
        currentStep={step}
        title={steps[key].title}
        content={steps[key].content}
      />
    </Grid>
  ));

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        {renderedSteps}
      </Grid>
      <Hidden lgUp={true}>
        <div style={{ textAlign: 'center' }} className={classes.currentStep}>
          <h4>{steps[step].title}</h4>
          <div>{steps[step].content}</div>
        </div>
      </Hidden>
    </div>
  );
}

export default memo(RegisterProcess);
