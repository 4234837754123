import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the profileAbout state domain
 */

const selectProfileAboutDomain = (state: ApplicationRootState) => {
  return state.profileAbout || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProfileAbout
 */

const makeSelectProfileAbout = () =>
  createSelector(selectProfileAboutDomain, substate => {
    return substate;
  });

export default makeSelectProfileAbout;
export { selectProfileAboutDomain };
