/*
 *
 * RegisterVehicles actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const updateFormStateVehiclesAction = states =>
  action(ActionTypes.UPDATE_FORM_STATE_VEHICLES_ACTION, states);

export const fetchVehiclesAction = userId =>
  action(ActionTypes.FETCH_VEHICLES_ACTION, userId);

export const fetchVehiclesSuccessAction = payload =>
  action(ActionTypes.FETCH_VEHICLES_SUCCESS_ACTION, payload);

export const fetchProfileSuccessAction = payload =>
  action(ActionTypes.FETCH_PROFILE_SUCCESS_ACTION, payload);

export const updateVehicleAction = form =>
  action(ActionTypes.UPDATE_VEHICLE_ACTION, form);

export const updateVehicleSuccessAction = () =>
  action(ActionTypes.UPDATE_VEHICLE_SUCCESS_ACTION);

export const resetStateAction = () => action(ActionTypes.RESET_STATE_ACTION);

export const updateStepAction = form =>
  action(ActionTypes.UPDATE_STEP_ACTION, form);

export const createDocumentAction = payload =>
  action(ActionTypes.CREATE_DOCUMENT_ACTION, payload);

export const createDocumentSuccessAction = payload =>
  action(ActionTypes.CREATE_DOCUMENT_SUCCESS_ACTION, payload);

export const uploadDocument = payload =>
  action(ActionTypes.UPLOAD_DOCUMENT_ACTION, payload);

export const createDocumentGroupAction = () =>
  action(ActionTypes.CREATE_DOCUMENT_GROUP_ACTION);

export const createDocumentSucessGroupAction = payload =>
  action(ActionTypes.CREATE_DOCUMENT_GROUP_SUCCESS_ACTION, payload);
