/*
 *
 * Register actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const setStepAction = step => action(ActionTypes.SET_STEP, step);
export const setLoadingAction = isLoading =>
  action(ActionTypes.SET_LOADING, isLoading);

export const fetchProfileAction = () =>
  action(ActionTypes.FETCH_PROFILE_ACTION);

export const fetchProfileSuccessAction = payload =>
  action(ActionTypes.FETCH_PROFILE_SUCCESS_ACTION, payload);
