/*
 *
 * Home
 *
 */

import React, { memo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Card, Grid, Typography } from '@material-ui/core';
import Select from 'components/Select';
import A from 'components/A';
import TagsSelector from 'components/TagsSelector';
import ListPreferenceTagsQuery from 'gql/preferenceTag/ListPreferenceTags.gql';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalizeFirstLetter from 'services/capitalize-first-letter';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    subTitle: {
      textTransform: 'uppercase',
      fontSize: '0.8rem',
    },
    button: {
      textTransform: 'none',
    },
    title: {
      color: '#000',
      margin: theme.spacing(2, 0),
    },
    filterContainer: {
      margin: theme.spacing(0, 1),
      display: 'inline',
      width: 100,
    },
    filter: {
      border: '0.5px solid #BDBFC3',
      ':&selected': {
        border: '0.5px solid #BDBFC3',
      },
    },
    interest: {
      minWidth: 150,
    },
    select: {
      minWidth: 75,
    },
    clearBtn: {
      color: '#D74F4F',
      '&:hover': {
        color: '#B74F4F',
      },
    },
    titleText: {
      padding: 4,
      fontWeight: 'bold',
    },
    container: {
      minWidth: 150,
    },
  }),
);

interface Props {
  onChangeStatus: (value) => void;
  statusFilter: string;
  onChangePreferenceTags: (value) => void;
}

function FilterDeal(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const { onChangeStatus, statusFilter, onChangePreferenceTags } = props;
  const [clearFilter, setClearFilter] = useState(false);

  const { loading, error, data } = useQuery(ListPreferenceTagsQuery);

  const handleChangeStatus = event => {
    onChangeStatus(event.target.value);
  };

  const handleChangePreferenceTags = value => {
    onChangePreferenceTags(value);
  };

  const handleClickClearFilters = () => {
    onChangeStatus('');
    onChangePreferenceTags([]);
    setClearFilter(true);
  };

  const onClearPreferenceTags = () => {
    setClearFilter(false);
  };

  return (
    <Card>
      <Grid container className={classes.root} justify="center">
        <Grid item container>
          <Grid item xs>
            <Typography className={classes.titleText}>
              <FormattedMessage id="filter" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <A className={classes.clearBtn} onClick={handleClickClearFilters}>
                <FormattedMessage id="clearFilters" />
              </A>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs alignItems="center">
          <Grid item className={classes.container}>
            <Typography className={classes.filterContainer}>
              <FormattedMessage id="status" />
            </Typography>
            <Select
              className={classes.select}
              onChange={handleChangeStatus}
              defaultValue="All"
              value={statusFilter}
              items={[
                { value: `${intl.messages['all']}`, key: 'All' },
                { value: `${intl.messages['draft']}`, key: 'DRAFT' },
                { value: `${intl.messages['preview']}`, key: 'PREVIEW' },
                { value: `${intl.messages['open']}`, key: 'OPEN' },
                {
                  value: capitalizeFirstLetter(`${intl.messages['funded']}`),
                  key: 'FUNDED',
                },
                { value: `${intl.messages['closed']}`, key: 'CLOSED' },
                { value: `${intl.messages['archived']}`, key: 'ARCHIVED' },
                { value: `${intl.messages['removed']}`, key: 'REMOVED' },
              ]}
            />
          </Grid>
          {!error && !loading && (
            <Grid item container xs alignItems="center">
              <Grid item>
                <Typography className={classes.filterContainer}>
                  <FormattedMessage id="interest" />
                </Typography>
              </Grid>
              <Grid item xs>
                <TagsSelector
                  onSelect={handleChangePreferenceTags}
                  option={data.preference_tag}
                  style={{ minWidth: 200 }}
                  defaultValue={[]}
                  clear={clearFilter}
                  onClear={onClearPreferenceTags}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}

export default memo(FilterDeal);
