/*
 *
 * TicketCreate constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/TicketCreate/DEFAULT_ACTION',
  SUBMIT_ACTION = 'app/TicketCreate/SUBMIT_ACTION',
  SUBMIT_FAILED_ACTION = 'app/TicketCreate/SUBMIT_FAILED_ACTION',
  SUBMIT_SUCCESS_ACTION = 'app/TicketCreate/SUBMIT_SUCCESS_ACTION',
  CLEAR_ACTION = 'app/TicketCreate/CLEAR_ACTION',
  VALIDATE_TICKET = 'app/TicketCreate/VALIDATE_TICKET',
}

export default ActionTypes;
