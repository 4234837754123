/**
 *
 * AlertDialog
 *
 */

import React, { memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface Props {
  open: boolean;
  title: string;
  description: any;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  children?: any;
}

function AlertDialog(props: Props) {
  const intl = useIntl();
  const {
    open,
    title,
    description,
    onCancel,
    onConfirm,
    confirmText,
    children,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography style={{ fontSize: '1.375rem', paddingTop: '4px' }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {children !== undefined && children}
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            onClick={onCancel}
            size={'small'}
            color="primary"
            variant="outlined"
          >
            {intl.messages['no']}
          </Button>
        )}
        {onConfirm && (
          <Button
            onClick={onConfirm}
            size={'small'}
            style={{ backgroundColor: 'var(--color-dark-purple)', color: 'var(--color-light-grey)' }}
            variant="contained"
          >
            {confirmText ? confirmText : `${intl.messages['yes']}`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default memo(AlertDialog);
