/*
 *
 * CompanyDetail
 *
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TopBar from './components/TopBar';
import Detail from './components/Details';
import CompanyDeals from 'containers/Company/CompanyDeals';
import CompanyTickets from 'containers/Company/CompanyTickets';
import CompanyDocuments from 'containers/Company/CompanyDocuments';
import { useQuery } from '@apollo/react-hooks';
import GetCompanyQuery from '../hasura/gql/GetCompany.gql';
import Company from '../../../models/company';
import ErrorToast from '../../../components/ErrorToast';
import { ApolloClient } from '@apollo/client';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useIntl } from 'react-intl';
import { match } from 'react-router-dom';
import { Container } from '@material-ui/core';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  match: match<any>;
}

function CompanyDetail(props: Props): JSX.Element {
  const intl = useIntl();
  const [tab, setTab] = useState(0);
  const { currentUser } = useSelector(stateSelector);

  const LANGUAGE_CODE = currentUser?.languageCode
    ? currentUser.languageCode
    : 'en';

  const { loading, error, data, refetch } = useQuery(GetCompanyQuery, {
    variables: {
      id: props.match.params.id,
    },
  });

  const toCompany = item => {
    const nameMap = new Map<string, string>();
    const descriptionMap = new Map<string, string>();
    item.translations.forEach(translation => {
      nameMap.set(translation.languageCode, translation.name);
      descriptionMap.set(translation.languageCode, translation.description);
    });

    return {
      companyId: item.companyId,
      name: nameMap,
      description: descriptionMap,
      website: item.website,
      pathPicture: item.pathPicture,
    } as Company;
  };

  const toStatistics = item => {
    let ticketCount = 0;
    item.deals.forEach(d => {
      ticketCount += d.ticketsAggregate.count.value;
    });
    return {
      totalDeals: item.dealsAggregate.count.value,
      totalTickets: ticketCount,
    };
  };

  useEffect(() => {
    refetch();
  }, []);

  const layout = tabValue => {
    switch (tabValue) {
      case 0:
        return (
          <Container className="main"><CompanyDeals
            companyId={props.match.params.id}
            onDealCreated={() => refetch()}
          /></Container>
        );
      case 1:
        return (<Container className="main"><CompanyDocuments companyId={props.match.params.id} /></Container>);
      case 2:
        return (<Container className="main"><CompanyTickets companyId={props.match.params.id} /></Container>);
      default:
        return (
          <Container className="main">
            <ErrorToast
              isOpen={Boolean(error)}
              message={error ? error.message : ''}
            />
            {!loading && !error && (
              <Detail
                client={props.client}
                company={toCompany(data.company)}
                languageCode={LANGUAGE_CODE}
                onUpdate={() => {
                  refetch();
                }}
              />
            )}
          </Container>
        );
    }
  };
  return (
    <div>
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? `${intl.messages[`${error.message}`]}` : ''}
      />
      {!loading && !error && (
        <TopBar
          company={toCompany(data.company)}
          languageCode={LANGUAGE_CODE}
          onChangeTab={value => {
            setTab(value);
          }}
          statistic={toStatistics(data.company)}
        />
      )}
      {layout(tab)}
    </div>
  );
}

export default CompanyDetail;
