import { FormState, initialFormState } from './state';
import { FormControl, Input, PropTypes } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';
import CustomFormLabel from './components/form-label';
import CustomHelperText from './components/helper-text';

const useStyles = makeStyles(() => ({
  errorInput: {
    width: '100%',
    // border: '1px solid #f44336',
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '0!important',
  },
  inputNumber: {
    width: '100%',
    // border: '1px solid #BDBFC3',
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '0!important',
  },
}));

export interface NumberState extends FormState {
  value: number | string | undefined;
}

export const initialNumberState: NumberState = {
  value: 0,
  ...initialFormState,
};

interface Props {
  formState: NumberState;
  onChange: (ev) => void;
  title?: string;
  fullWidth: boolean;
  inputProps?: any;
  margin?: PropTypes.Margin;
}

FormNumber.defaultProps = {
  fullWidth: true,
  options: [],
};

function FormNumber(props: Props) {
  const classes = useStyles();
  const { formState, onChange, title, fullWidth, inputProps, margin } = props;

  const handleOptionChange = ev => {
    const value = ev.target.value.replace(/\$/g, '').replace(/,/g, '');
    onChange(value);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <CustomFormLabel title={title} />
      <NumberFormat
        value={!formState.value ? '' : formState.value}
        thousandSeparator={true}
        prefix={'$'}
        className={`${
          formState.error ? classes.errorInput : classes.inputNumber
        }`}
        onChange={handleOptionChange}
        customInput={Input}
        disableUnderline
        inputProps={inputProps}
      />
      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(FormNumber);
