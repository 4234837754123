import { createGlobalStyle } from 'styles/styled-components';
import CalibreThin from 'styles/fonts/CalibreThin.woff';
import CalibreLight from 'styles/fonts/CalibreLight.woff';
import CalibreRegular from 'styles/fonts/CalibreRegular.woff';
import CalibreRegularItalic from 'styles/fonts/CalibreRegularItalic.woff';
import CalibreMedium from 'styles/fonts/CalibreMedium.woff';
import CalibreSemiBold from 'styles/fonts/CalibreSemibold.woff';
import CalibreBold from 'styles/fonts/CalibreBold.woff';
import HindLight from 'styles/fonts/Hind-Light.ttf';
import HindBold from 'styles/fonts/Hind-Bold.ttf';
import HindMedium from 'styles/fonts/Hind-Medium.ttf';
import HindRegular from 'styles/fonts/Hind-Regular.ttf';
import HindSemiBold from 'styles/fonts/Hind-SemiBold.ttf';
const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Calibre';
  font-weight: 100;
  font-style: normal;
  src: url(${CalibreThin}) format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-weight: 300;
  font-style: normal;
  src: url(${CalibreLight}) format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-weight: 400;
  font-style: normal;
  src: url(${CalibreRegular}) format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-weight: 400;
  font-style: italic;
  src: url(${CalibreRegularItalic}) format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-weight: 500;
  font-style: normal;
  src: url(${CalibreMedium}) format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-weight: 600;
  font-style: normal;
  src: url(${CalibreSemiBold}) format('woff');
}

@font-face {
  font-family: 'Calibre';
  font-weight: 700;
  font-style: normal;
  src: url(${CalibreSemiBold}) format('woff');
}

@font-face {
  font-family: 'Hind';
  font-weight: 100;
  font-style: normal;
  src: url(${HindLight}) format('ttf');
}

@font-face {
  font-family: 'Hind';
  font-weight: 800;
  font-style: normal;
  src: url(${HindBold}) format('ttf');
}

@font-face {
  font-family: 'Hind';
  font-weight: 600;
  font-style: normal;
  src: url(${HindMedium}) format('ttf');
}

@font-face {
  font-family: 'Hind';
  font-weight: 700;
  font-style: normal;
  src: url(${HindSemiBold}) format('ttf');
}

@font-face {
  font-family: 'Hind';
  font-weight: 400;
  font-style: normal;
  src: url(${HindRegular}) format('ttf');
}

:root {
    --color-dark-purple: #5334B2;
    --color-purple: #794CFF;
    --color-cyan: #33CCB2;
    --color-black: #0D0D0D;
    --color-super-dark-grey: #1A1A1A;
    --color-dark-grey: #2D2D2D;
    --color-medium-dark-grey: #333333;
    --color-grey: #999999;
    --color-light-grey: #F6F6F6;

    --sm: 576px;
    --md: 768px;
    --lg: 992px;
    --xl: 1200px;

}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Hind', Georgia, Times, 'Times New Roman', serif;
  color:#F6F6F6;
  margin-top:0;
  line-height:normal;
}

  h1, .h1{
    font-size: 40px;
    font-weight: bold;
  }

  h2, .h2{
    font-size: 30px;
    font-weight: semi-bold;
  }

  h3, .h3{
    font-size: 23px;
    font-weight: bold;
  }

  h4, .h4{
    font-size:17px;
    font-weight:600;
    margin-bottom:10px;
  }

  .h5{
    font-size:1rem;
    font-weight:700;
    margin-bottom:7px;
  }

  p{
    font-size: 16px;
    font-weight: regular;
    margin-top:0;
  }

  .text-center {
      text-align:center;
  }

  button {
    font-family: 'Hind', Georgia, Times, 'Times New Roman', serif;

  }

  button.default{
    background-color: #794CFF;
    color:#cccccc;
    float:right;
    border-radius: 5px;
    margin: 10px;
    margin-right:0px;
  }
  button.default:hover{
    background-color: #794CFF;
    color:#ffffff;
  }

  button.secondary{
    background-color: none;
    border: 1px solid #999999;
    float:right;
    color:#999999;
    margin: 10px;
    border-radius: 3px;
    margin-right:0px;
  }
  button.secondary:hover{
    color:#F6F6F6;
    border-color:#F6F6F6;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
  }

  body {
    font-family: 'Hind', Georgia, Times, 'Times New Roman', serif;
    color: #F6F6F6;


  }

  #app {
    background-color: #0D0D0D;
    min-height: 100%;
    min-width: 100%;
    padding-bottom: 50px;
    position: relative;
  }

  .main {
      padding-top:30px;
      padding-bottom:30px;
  }

  p,
  label {
    font-family: 'Hind', Georgia, Times, 'Times New Roman', serif;
    line-height: normal;
  }

  a {
    color: var(--color-dark-purple);
    text-decoration: underline;
    font-weight: '500';
    cursor: pointer;
  }

  a:hover {
    color: var(--color-dark-purple);
    text-decoration: none;
  }

  .svg-stroke {
      stroke: currentColor;
  }

  .svg-fill {
    fill: currentColor;
}
`;

export default GlobalStyle;
