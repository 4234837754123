import { takeLatest, call, put, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import { RestResult } from 'models/response/rest-result';
import Company from 'models/company';
import * as actions from './actions';
import { showToast } from 'containers/App/actions';
import {
  searchTemplate,
  updateTemplateStatus,
  previewTemplate,
} from 'services/api/sign-template';
import { SigningTemplate } from 'models/signing-template';
import { TEMPLATE_CATEGORY } from 'utils/constants';
import { setIsLoading } from 'containers/Home/actions';

export function* fetchTemplateRequest(action) {
  yield put(setIsLoading(true));
  const { searchKey, page, pageSize, filter } = action.payload;
  const request = {
    statuses: filter,
    searchKey: searchKey,
    page: page,
    pageSize: pageSize,
    categories: TEMPLATE_CATEGORY.map(item => item.value),
  };
  try {
    const result: RestResult<SigningTemplate> = yield call(
      searchTemplate,
      request,
    );
    if (result) {
      yield put(actions.fetchTemplateSuccessAction(result));
    }
  } catch (err) {
    yield put(actions.fetchTemplateFailedAction(err));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

export function* updateStatusRequest(action) {
  yield put(setIsLoading(true));
  try {
    const result: RestResult<SigningTemplate> = yield call(
      updateTemplateStatus,
      action.payload,
    );
    if (result) {
      yield put(actions.updateTemplateStatusSuccessAction(result));
    }
  } catch (err) {
    yield put(actions.updateTemplateStatusFailedAction(err));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* getPreview(action) {
  try {
    const url = yield call(previewTemplate, action.payload);
    yield put(actions.getPreviewSucceed());
    const a = document.createElement('a');
    a.href = url;
    a.target = 'blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    yield put(
      showToast({
        message: 'previewSuccess',
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(actions.getPreviewSucceed());
    
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* companiesSaga() {
  yield takeLatest(ActionTypes.GET_TEMPLATE_ACTION, fetchTemplateRequest);
  yield takeLatest(
    ActionTypes.UPDATE_TEMPLATE_STATUS_ACTION,
    updateStatusRequest,
  );
  yield takeLatest(ActionTypes.GET_PREVIEW, getPreview);
}
