import React, { memo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import { Typography } from '@material-ui/core';
import { resetState } from '../actions';
import ArrowButton from 'components/ArrowButton';

function Succeed(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { email } = props;

  const backToSignIn = () => {
    dispatch(resetState());
    dispatch(replace('/login'));
  };
  const localScope = 'ForgotPassword.Succeed';
  return (
    <>
      <Typography
        variant="h3"
        style={{
          marginBottom: 0,
          color: 'white',
          fontWeight: 400,
          textAlign: 'center',
        }}
      >
        <FormattedMessage id={`${localScope}.verificationSent`} />
      </Typography>
      <Typography
        style={{
          margin: '20px 0',
          textAlign: 'center',
        }}
      >
        <FormattedMessage
          id={`${localScope}.weHaveSent`}
          values={{ email: email }}
        />
        <br />
        <FormattedMessage id={`${localScope}.pleaseCheck`} />
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <ArrowButton
          text={`${intl.messages[`backToSignIn`]}`}
          type={'button'}
          onClick={backToSignIn}
        ></ArrowButton>
      </div>
    </>
  );
}

export default memo(Succeed);
