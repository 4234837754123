/*
 *
 * CompanyTickets
 *
 */

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TicketsTable from './components/TicketsTable';
import { makeStyles } from '@material-ui/styles';
import { PAGE_SIZE } from 'utils/constants';
import { Ticket } from 'models/ticket';
import TicketEditDialog from 'containers/TicketEdit';
import { useQuery } from '@apollo/react-hooks';
import ListCompanyTicketsQuery from '../hasura/gql/ListCompanyTickets.gql';
import ErrorToast from '../../../components/ErrorToast';

interface Props {
  companyId: string;
}

const useStyles = makeStyles(() => ({
  root: { },
  logo: {
    margin: 6,
    width: 35,
    height: 35,
    borderRadius: '50%',
  },
  detail: {
    marginTop: 6,
    padding: 6,
  },
}));

function CompanyTickets(props: Props) {
  const classes = useStyles();
  const [selectedTicket, setSelectedTicket] = React.useState<Ticket>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const { loading, error, data, refetch } = useQuery(ListCompanyTicketsQuery, {
    variables: {
      id: props.companyId,
      offset: (currentPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
    },
  });

  const toTicket = items => {
    const result = new Array<Ticket>();
    items.forEach(item => {
      result.push({
        ticketId: item.id,
        createdDate: item.createdDate,
        amount: item.amount,
        status: item.status,
        modifiedDate: item.modifiedDate,
        investorApproach: item.investorApproach,
        issuer: {
          userId: item.issuer?.id,
          userType: item.issuer?.userType,
          fullName: item.issuer?.fullName,
        },
        investor: {
          userId: item.investor?.id,
          fullName: item.investor?.fullName,
        },
        vehicle: {
          vehicleId: item.vehicle?.id,
          vehicleType: item.vehicle?.vehicleType,
          corporateName: item.vehicle?.corporateName,
        },
      } as Ticket);
    });
    return result;
  };

  useEffect(() => {
    refetch();
  }, []);

  const fetchPage = page => {
    setCurrentPage(page);
    refetch();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    setOpen(false);
    refetch();
  };

  const onItemClick = item => {
    setSelectedTicket(item);
    setOpen(true);
  };

  return (
    <Grid container justify="center" className={classes.root}>
      <ErrorToast
        isOpen={Boolean(error)}
        message={error ? error.message : ''}
      />
      <Grid item xs={12}>
        {!loading && !error && (
          <TicketsTable
            loading={loading}
            error={error != undefined}
            tickets={toTicket(data.tickets)}
            total={data.ticketsAggregate.count.value}
            fetchPage={fetchPage}
            onItemClick={onItemClick}
          />
        )}
        <TicketEditDialog
          open={open}
          onClose={onClose}
          onSuccess={onSuccess}
          ticket={selectedTicket}
        />
      </Grid>
    </Grid>
  );
}

export default CompanyTickets;
