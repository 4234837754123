/*
 *
 * ProfileTicket
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectProfileTicket from './selectors';
import reducer from './reducer';
import saga from './saga';
import { fetchTicketAction } from './actions';
import { Grid, makeStyles } from '@material-ui/core';
import TicketsTable from './components/TicketsTable';
import UserData from 'models/user';
import { Ticket } from 'models/ticket';
import TicketEditDialog from 'containers/TicketEdit';
import { PAGE_SIZE } from 'utils/constants';

const stateSelector = createStructuredSelector({
  profileTicketState: makeSelectProfileTicket(),
});

const useStyles = makeStyles(() => ({
  logo: {
    margin: 6,
    width: 35,
    height: 35,
    borderRadius: '50%',
  },
  detail: {
    marginTop: 6,
    padding: 6,
  },
}));

interface Props {
  investor: UserData;
}

function ProfileTicket(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'profileTicket', reducer: reducer });
  useInjectSaga({ key: 'profileTicket', saga: saga });

  const { profileTicketState } = useSelector(stateSelector);
  const [selectedTicket, setSelectedTicket] = React.useState<
    Ticket | undefined
  >();
  const [open, setOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { tickets, total } = profileTicketState;
  const { investor } = props;
  useEffect(() => {
    if (investor) {
      dispatch(
        fetchTicketAction({
          investorId: investor.userId,
          page: 0,
          pageSize: PAGE_SIZE,
        }),
      );
    }
  }, [investor]);
  const onClose = () => {
    setOpen(false);
  };
  const onSuccess = () => {
    setOpen(false);
    if (investor) {
      dispatch(
        fetchTicketAction({
          investorId: investor.userId,
          page: 0,
          pageSize: PAGE_SIZE,
        }),
      );
    }
  };
  const onEdit = item => {
    setSelectedTicket(item);
    setOpen(true);
  };
  const fetchPage = page => {
    dispatch(
      fetchTicketAction({
        investorId: investor.userId,
        page: page - 1,
        pageSize: PAGE_SIZE,
      }),
    );
  };
  const scope = 'ProfileTicket';
  return (
    <div>
      <TicketsTable
        tickets={tickets}
        total={total}
        fetchPage={fetchPage}
        onItemClick={onEdit}
        scope={scope}
      />
      <TicketEditDialog
        open={open}
        onClose={onClose}
        onSuccess={onSuccess}
        ticket={selectedTicket}
      />
    </div>
  );
}

export default ProfileTicket;
