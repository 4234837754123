/*
 *
 * ProfileEditDialog
 *
 */

import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  Input,
  Grid,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import PhoneInput from 'components/FormControl/phoneInput';
import UserData from 'models/user';
import Select from 'components/Select';
import CustomFormLabel from 'components/FormControl/components/form-label';
import UploadDocuments from 'containers/UploadDocuments';
import Document from 'models/document';
import { makeSelectCountries, makeSelectPreferences } from 'containers/App/selectors';
import AvatarUpload from './avatarUpload';
import moment from 'moment';
import Interests from './Interests';

const useStyles = makeStyles(theme => ({
  container: {},
  input: {
    width: '100%',
  },
  requireText: {
    color: '#D74F4F',
  },
  errorInput: {
    border: '1px solid #D74F4F',
  },
  errorText: {
    color: '#D74F4F',
  },
  hiddenColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const stateSelector = createStructuredSelector({
  countries: makeSelectCountries(),
  preferences: makeSelectPreferences(),
});

interface Props {
  investor: UserData;
  onChange: (value, type) => void;
  onDocumentChange: (value, type) => void;
  scope: string;
  onFileRemove: (value, type) => void;
}

const idDocumentTypeOptions = [
  { key: 'ID_CARD', value: 'ID Card' },
  { key: 'PASSPORT', value: 'Passport' },
];

function ProfileEdit(props: Props): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  const { investor, onChange, onDocumentChange, scope, onFileRemove } = props;
  const [documentFiles, setDocumentFiles] = React.useState<string[]>([]);
  const [residentsFiles, setResidentFiles] = React.useState<string[]>([]);
  const { countries, preferences } = useSelector(stateSelector);
  const [identityDocument, setIdentityDocument] = React.useState<Document>({
    files: [],
    type: 'KYC_DOCUMENT_ID',
  });
  const [residentDocument, setResidentDocument] = React.useState<Document>({
    files: [],
    type: 'KYC_RESIDENCY_CERTIFICATE',
  });
  const onDocumentUpdate = (type, items) => {
    onDocumentChange(items, type);
    if (type === 'KYC_RESIDENCY_CERTIFICATE') {
      setResidentFiles(items);
    } else {
      setDocumentFiles(items);
    }
  };

  useEffect(() => {
    if (investor && investor['documentGroups']) {
      const defaultGroup = investor['documentGroups'].filter(
        item => item.isDefault,
      );
      if (
        defaultGroup &&
        defaultGroup.length > 0 &&
        defaultGroup[0].documents
      ) {
        defaultGroup[0].documents.forEach(item => {
          if (item.type === 'KYC_DOCUMENT_ID') {
            setIdentityDocument(item);
          } else if (item.type === 'KYC_RESIDENCY_CERTIFICATE') {
            setResidentDocument(item);
          }
        });
      }
    }
  }, [investor]);
  const onAvatarChanged = value => {
    if (value) {
      onChange(value, 'localFile');
    } else {
      onChange('', 'picturePath');
    }
  };
  const handleDateChange = ev => {
    onChange(moment(ev).format('DD-MM-YYYY'), 'birthday');
  };

  const onInterestSelect = (val) => {
    onChange(val, 'preferenceIdTags')
  }

  const getDate = str => {
    if (str) {
      const check = moment(str, ['DD-MM-YYYY', 'DD/MM/YYYY']);
      return check.isValid() ? new Date(check.valueOf()) : null;
    }
    return null;
  };

  const localScope = `${scope}.profileEdit`;
  return (
    <div className={classes.container}>
      <Typography variant={'h5'}>
        <FormattedMessage id={`${localScope}.yourIdentity`} />
      </Typography>
      <Typography variant={'subtitle1'}>
        <FormattedMessage id={`${localScope}.identityOfThePrimaryInvestor`} />
      </Typography>

      <Grid container spacing={5} style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={6}>
          <CustomFormLabel title={`${intl.messages['model.firstName']}`} />
          <Input
            id="firstName"
            defaultValue={investor ? investor.firstName : ''}
            autoComplete={'off'}
            disableUnderline
            inputProps={{ maxLength: 100 }}
            className={classes.input}
            onChange={ev => onChange(ev.currentTarget.value, 'firstName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormLabel title={`${intl.messages['model.lastName']}`} />
          <Input
            id="lastName"
            defaultValue={investor ? investor.lastName : ''}
            autoComplete={'off'}
            disableUnderline
            inputProps={{ maxLength: 100 }}
            className={classes.input}
            onChange={ev => onChange(ev.currentTarget.value, 'lastName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            onChange={value => onChange(value, 'phone')}
            title={`${intl.messages['model.phone']}`}
            formState={{
              value: investor ? investor.phone : '',
              error: false,
              helperMessage: undefined,
            }}
            type={'tel'}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AvatarUpload
            remoteUrl={investor ? investor.picturePath : ''}
            title={`${intl.messages['model.picturePath']}`}
            onChange={onAvatarChanged}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormLabel title={'Nationality'} />
          <Select
            defaultValue={investor ? investor.nationality : ''}
            items={countries.slice().sort((oneCountry, nextCountry) => oneCountry.nationality.localeCompare(nextCountry?.nationality)).map(item => {
              return { value: item.nationality, key: item.alpha2Code };
            })}
            className={classes.input}
            onChange={ev => onChange(ev.target.value, 'nationality')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormLabel title={`${intl.messages['model.birthDate']}`} />
          <DatePicker
            openTo="year"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            value={investor ? getDate(investor.birthday) : ''}
            fullWidth={true}
            onChange={handleDateChange}
            InputProps={{
              className: classes.input,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormLabel
            title={`${intl.messages['model.individualDocType']}`}
          />
          <Select
            defaultValue={investor ? investor.individualDocType : ''}
            items={idDocumentTypeOptions}
            className={classes.input}
            onChange={ev => onChange(ev.target.value, 'individualDocType')}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomFormLabel
            title={`${intl.messages['model.individualDocNumber']}`}
          />
          <Input
            defaultValue={investor ? investor.individualDocNumber : ''}
            id="individualDocNumber"
            autoComplete={'off'}
            disableUnderline
            inputProps={{ maxLength: 100 }}
            className={classes.input}
            onChange={ev =>
              onChange(ev.currentTarget.value, 'individualDocNumber')
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <UploadDocuments
            title={`${intl.messages['files']}`}
            onChange={fileIds => {
              onDocumentUpdate('KYC_DOCUMENT_ID', fileIds);
            }}
            document={identityDocument}
            downloadable={true}
            onRemove={fileId => {
              onFileRemove(fileId, 'KYC_DOCUMENT_ID');
            }}
          />
        </Grid>
      </Grid>

      <Typography
        variant={'h5'}
        style={{ marginTop: '20px', fontWeight: 'bold' }}
      >
        <FormattedMessage id="model.documentGroups.identityDocument" />
      </Typography>
      <Divider style={{ marginBottom: '20px', backgroundColor: '#212121' }} />

      <Grid container spacing={5}>
        <Grid item xs={12} sm={8}>
          <CustomFormLabel title={`${intl.messages['model.address1']}`} />
          <Input
            defaultValue={investor ? investor.address1 : ''}
            placeholder={`${intl.messages['model.address1']}`}
            disableUnderline
            className={classes.input}
            onChange={ev => onChange(ev.currentTarget.value, 'address1')}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.hiddenColumn} />
        <Grid item xs={12} sm={8}>
          <CustomFormLabel title={`${intl.messages['model.address2']}`} />
          <Input
            defaultValue={investor ? investor.address2 : ''}
            placeholder={`${intl.messages['model.address2']}`}
            disableUnderline
            className={classes.input}
            onChange={ev => onChange(ev.currentTarget.value, 'address2')}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.hiddenColumn} />
        <Grid item xs={12} sm={8}>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={3}>
              <CustomFormLabel title={`${intl.messages['model.zipCode']}`} />
              <Input
                defaultValue={investor ? investor.zipCode : ''}
                placeholder={`${intl.messages['model.zipCode']}`}
                disableUnderline
                className={classes.input}
                onChange={ev => onChange(ev.currentTarget.value, 'zipCode')}
                inputProps={{ maxLength: 25 }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <CustomFormLabel title={`${intl.messages['model.city']}`} />
              <Input
                defaultValue={investor ? investor.city : ''}
                // onChange={updateFormState('city')}
                // formState={formState.city}
                placeholder={`${intl.messages['model.city']}`}
                disableUnderline
                className={classes.input}
                onChange={ev => onChange(ev.currentTarget.value, 'city')}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomFormLabel title={`${intl.messages['model.country']}`} />
              <Select
                defaultValue={investor ? investor.country : ''}
                items={countries.map(item => {
                  return { value: item.enShortName, key: item.alpha2Code };
                })}
                className={classes.input}
                onChange={ev => onChange(ev.target.value, 'country')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={12}>
          <UploadDocuments
            title={`${intl.messages['model.proofOfResidency']}`}
            onChange={fileIds => {
              onDocumentUpdate('KYC_RESIDENCY_CERTIFICATE', fileIds);
            }}
            onRemove={fileId => {
              onFileRemove(fileId, 'KYC_RESIDENCY_CERTIFICATE');
            }}
            document={residentDocument}
            downloadable={true}
          />
        </Grid>
      </Grid>

      <Typography
        variant={'h5'}
        style={{ marginTop: '20px', fontWeight: 'bold' }}
      >
        <FormattedMessage id="tagsAndInterests" />
      </Typography>
      <Divider style={{ marginBottom: '20px', backgroundColor: '#212121' }} />
      <div style={{ paddingBottom: '20px' }}>
        <Interests
            onSelect={onInterestSelect}
            defaultValue={investor.preferenceIdTags}
            option={preferences}
            />
      </div>
    </div>
  );
}

export default ProfileEdit;
