import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import InviteInvestor from 'models/invite-investor';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  total: 0,
  loading: false,
};

const documentSettingsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.GET_TEMPLATE_SUCCESS_ACTION:
        draft.templates = action.payload.data;
        draft.total = action.payload.metaData['total'];
        draft.refreshPage = true;
        break;
      case ActionTypes.UPDATE_TEMPLATE_STATUS_SUCCESS_ACTION:
        draft.refreshPage = false;
        break;
      case ActionTypes.SUBMIT_SUCCESS_ACTION:
        draft.refreshPage = false;
        break;
      case ActionTypes.GET_PREVIEW:
        draft.loading = true;
        break;
      case ActionTypes.GET_PREVIEW_SUCCEED:
        draft.loading = false;
        break;
      default:
        break;
    }
  },
  initialState,
);

export default documentSettingsReducer;
