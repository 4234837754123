/*
 *
 * ProfileTickets constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ProfileTickets/DEFAULT_ACTION',
  FETCH_TICKET_ACTION = 'app/ProfileTickets/FETCH_TICKET_ACTION',
  FETCH_TICKET_SUCCESS_ACTION = 'app/ProfileTickets/FETCH_TICKET_SUCCESS_ACTION',
}

export default ActionTypes;
