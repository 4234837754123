/*
 *
 * RegisterSignUp actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const signUpAction = state => action(ActionTypes.SIGN_UP_ACTION, state);

export const signUpSuccessAction = () => action(ActionTypes.SIGN_UP_SUCCESS);

export const updateFormStateSignUpAction = states =>
  action(ActionTypes.UPDATE_FORM_STATE_SIGN_UP_ACTION, states);
