/*
 *
 * InvestorDashboard
 *
 */

import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { push } from 'connected-react-router';
import { Card, Grid, Typography, makeStyles, Link } from '@material-ui/core';

import { makeSelectCurrentUser } from 'containers/App/selectors';
import Img from 'components/Img';
import UserStatus from 'components/UserStatus';
import NoAvatar from 'images/not-found.png';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalizeFirstLetter from 'services/capitalize-first-letter';

const stateSelector = createStructuredSelector({
  profile: makeSelectCurrentUser(),
});

const useStyles = makeStyles(() => ({
  container: {
    padding: 12,
    height: '100%',
  },
  interestTag: {
    padding: '1px 5px',
    margin: 2,
    backgroundColor: 'var(--color-dark-purple)',
    color: '#FFFFFF',
    fontWeight: 500,
    borderRadius: 5,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    minHeight: 18,
    lineHeight: 'normal',
  },
  nameText: {
    fontSize: 15,
    lineHeight: '15px',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  linkTextWrapper: {
    textAlign: 'right',
    fontSize: '12px',
    margin: '0',
    color: 'var(--color-grey)',
  },
  subTitleText: {
    color: 'var(--color-grey)',
    fontSize: '0.85rem',
    margin: '0',
  },
  picture: {
    width: 40,
    height: 40,
    margin: 8,
    borderRadius: '50%',
  },
  welcomeText: {
    fontSize: '12px',
    color: '#999999',
  },
  linkText: {
    color: 'var(--color-grey)',
    '&:hover': {
      color: 'var(--color-dark-purple)',
      cursor: 'pointer',
    },
  },
}));

interface Props {
  scope: string;
}

function DashboardProfile(props: Props) {
  const intl = useIntl();
  const { scope } = props;
  const { profile } = useSelector(stateSelector);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleProfileClick = e => {
    e.preventDefault();
    dispatch(push('/me'));
  };
  const localScope = `${scope}.dasboardProfile`;
  return (
    <Card variant="outlined" className={classes.container}>
      <div>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          style={{ borderBottom: '1px solid var(--color-dark-grey)', marginBottom: '10px' }}
        >
          <Grid item xs={'auto'}>
            <Img
              src={profile!.picturePath || NoAvatar}
              className={classes.picture}
            />
          </Grid>
          <Grid item xs={true}>
            <div className={classes.welcomeText}>{'Welcome back,'}</div>
            <div className={classes.nameText}>{profile!.fullName}</div>
          </Grid>
          <Grid item xs={'auto'}>
            <UserStatus value={profile!.status} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={true}>
              <Typography className={classes.subTitleText}>
                <FormattedMessage id={`${localScope}.investmentPreferences`} />
              </Typography>
            </Grid>
            <Grid item xs={'auto'}>
              <Typography className={classes.linkTextWrapper}>
                <Link
                  className={classes.linkText}
                  href="/me"
                  onClick={handleProfileClick}
                >
                  <FormattedMessage id="editProfile" /> 
                </Link>
              </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
          {profile!.userPreferenceTags && profile!.userPreferenceTags.length > 0 && (
            <>
              {profile!.userPreferenceTags.map((item, index) => {
                return (
                  <span className={classes.interestTag} key={index}>
                    {item.name}
                  </span>
                );
              })}
            </>
          )}
          {!profile!.userPreferenceTags || profile!.userPreferenceTags.length === 0 && 
            <FormattedMessage id="noInerestsAdded" /> 
          }
          </Grid>
        </Grid>
        
      </div>
    </Card>
  );
}

export default memo(DashboardProfile);
