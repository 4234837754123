/*
 *
 * Companies
 *
 */

import React, { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CompanyList from './components/companyList';
import CompanyCreateDialog from 'containers/Company/CompanyCreateDialog';
import DealCreator from 'containers/DealCreator';
import { PAGE_SIZE } from 'utils/constants';
import { useQuery } from '@apollo/react-hooks';
import ListCompaniesQuery from '../hasura/gql/ListCompanies.gql';
import GetCompanyQuery from '../hasura/gql/GetCompany.gql';
import Company from '../../../models/company';
import ErrorToast from '../../../components/ErrorToast';
import SuccessToast from 'components/SuccessToast';
import { toCompany, toCompanyList } from '../hasura/mapping';
import { ApolloClient } from '@apollo/client';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useIntl } from 'react-intl';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
}

function Companies(props: Props): JSX.Element {
  const intl = useIntl();
  const [selectedItem, setSelectedItem] = React.useState<Company>();
  const [searchKey, setSearchKey] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showDealCreateDialog, setShowDealCreateDialog] = React.useState(false);
  const [dealCreationSuccess, setDealCreationSuccess] = React.useState<
    string | undefined
  >(undefined);
  const [dealCreationError, setDealCreationError] = React.useState<
    string | undefined
  >(undefined);
  const { currentUser } = useSelector(stateSelector);

  const { refetch: getCompany } = useQuery(GetCompanyQuery);

  const LANGUAGE_CODE = currentUser?.languageCode
    ? currentUser.languageCode
    : 'en';

  const { loading, error, data, refetch } = useQuery(ListCompaniesQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      searchKey: `%${searchKey}%`,
      offset: (currentPage - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      languageCode: LANGUAGE_CODE,
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);
  const handleCreateDialogClose = () => {
    setSelectedItem(undefined);
    setShowCreateDialog(false);
    refetch();
  };

  const handleCreateDialogSuccess = () => {
    setSelectedItem(undefined);
    refetch();
  };

  const openCreateDialog = async item => {
    if (item && item.companyId) {
      const company = toCompany(
        (await getCompany({ id: item.companyId })).data.company,
      );
      setSelectedItem(company);
    }
    setShowCreateDialog(true);
  };

  const handleFetchPage = (key: string, page: number) => {
    setSearchKey(key);
    setCurrentPage(page);
  };

  useEffect(() => {
    refetch();
  }, []);

  const addNewDeal = async companyId => {
    if (companyId) {
      const company = toCompany(
        (await getCompany({ id: companyId })).data.company,
      );
      setSelectedItem(company);
    }
    setShowDealCreateDialog(true);
  };

  const scope = 'Companies';
  return (
    <Container className="main">
      <ErrorToast
        isOpen={Boolean(error || dealCreationError)}
        message={
          error
            ? `${intl.messages[`error.${error.message}`]}`
            : `${intl.messages[`error.${dealCreationError}`]}` || ''
        }
      />
      <SuccessToast
        isOpen={Boolean(dealCreationSuccess)}
        message={dealCreationSuccess || ''}
      />
      <CompanyList
        loading={loading}
        error={error != undefined}
        openCreateDialog={openCreateDialog}
        items={toCompanyList(data?.companies)}
        languageCode={LANGUAGE_CODE}
        total={data?.companiesAggregate.count.value}
        fetchPage={handleFetchPage}
        currentPage={currentPage}
        addNewDeal={addNewDeal}
        scope={scope}
        onCompanyDelete={refetch}
      />
      <CompanyCreateDialog
        client={props.client}
        open={showCreateDialog}
        onCancel={handleCreateDialogClose}
        onSuccess={handleCreateDialogSuccess}
        item={selectedItem}
      />
      <DealCreator
        propOpen={showDealCreateDialog}
        companyId={String(selectedItem?.companyId)}
        setOpen={setShowDealCreateDialog}
        onSuccess={setDealCreationSuccess}
        onError={setDealCreationError}
      />
    </Container>
  );
}

export default Companies;
