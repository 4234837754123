import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the profileTicket state domain
 */

const selectProfileTicketDomain = (state: ApplicationRootState) => {
  return state.profileTicket || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProfileTicket
 */

const makeSelectProfileTicket = () =>
  createSelector(selectProfileTicketDomain, substate => {
    return substate;
  });

export default makeSelectProfileTicket;
export { selectProfileTicketDomain };
