import { takeLatest, call, put } from 'redux-saga/effects';
import {
  createDocumentGroup,
  deleteDocumentGroup,
  getCompanyDocuments,
  getDocumentGroupStatisticForCompany,
} from 'services/api/documentGroups-service';
import {
  createDocument,
  deleteDocument,
  updateDocument,
} from 'services/api/document-service';
import {
  uploadUrls,
  uploadFileToS3,
  updateDocumentFiles,
  updateDocumentFileTitles
} from 'services/api/file-service';
import {
  refreshSignDocument,
  refreshSignGroup,
} from 'services/api/sign-document';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import {
  getDocumentsSucceed,
  getNumberOfDocumentsSucceed,
  createDocumentSucceed,
  editDocumentSucceed,
  refreshSignDocumentSucceed,
  refreshSignGroupSucceed,
  setLoading,
  setLoadingDocument,
} from './actions';
import { sendCompanyPassword } from 'services/api/profile-service';
function* getDocumentGroups(action) {
  const { type, companyId, isPublic } = action.payload;
  try {
    const groups = yield call(getCompanyDocuments, companyId, isPublic, type);
    yield put(getDocumentsSucceed(groups));
  } catch (err) {
    yield put(setLoadingDocument(false));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* getNumberOfDocuments(action) {
  try {
    const statistic = yield call(
      getDocumentGroupStatisticForCompany,
      action.payload,
    );
    yield put(getNumberOfDocumentsSucceed(statistic));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* createGroup(action) {
  try {
    yield call(createDocumentGroup, action.payload);
    yield put(
      showToast({
        message: 'The group has been successfully created',
        type: 'success',
      }),
    );
    yield call(getDocumentGroups, action);
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* deleteGroup(action) {
  const { sendMail, companyId, groupId, type } = action.payload;
  try {
    yield call(deleteDocumentGroup, groupId);
    yield put(
      showToast({
        message: 'theGroupHasBeenCreated',
        type: 'success',
      }),
    );
    yield call(getDocumentGroups, action);
    yield call(getNumberOfDocuments, {
      payload: action.payload.companyId,
    });
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* createDocumentSaga(action) {
  const {
    documentGroupId,
    title,
    type,
    files,
    companyId,
    isPublic,
    newFileTitles
  } = action.payload;
  try {
    const document = yield call(createDocument, {
      documentGroupId,
      title,
      type,
    });
    const urlResp = yield call(uploadUrls, {
      companyId,
      documentGroupType: type,
      subType: 'COMPANY',
      files: files.map(file => ({ name: file.name })),
    });
    yield call(
      uploadFileToS3,
      urlResp.map(resp => resp.url),
      files,
    );
    yield call(
      updateDocumentFiles,
      document.documentId,
      urlResp.map(resp => resp.fileId),
    );
    const newFiles = urlResp.map((file, index) => ({
      ...file,
      title: newFileTitles[index],
    }));
    yield call(updateDocumentFileTitles, [...newFiles]);
    yield put(
      showToast({
        message: 'theDocumentHasBeenCreated',
        type: 'success',
      }),
    );
    yield call(getDocumentGroups, action);
    yield call(getNumberOfDocuments, {
      payload: action.payload.companyId,
    });
    yield put(createDocumentSucceed());
  } catch (err) {
    yield put(createDocumentSucceed());
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* deleteDocumentSaga(action) {
  const { documentId, companyId, type } = action.payload;
  try {
    yield call(deleteDocument, documentId);
    yield put(
      showToast({
        message: 'theDocumentHasBeenDeleted',
        type: 'success',
      }),
    );
    yield call(getDocumentGroups, action);
    yield call(getNumberOfDocuments, {
      payload: action.payload.companyId,
    });
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* editDocument(action) {
  const {
    title,
    files,
    oldFiles,
    documentId,
    companyId,
    type,
    isPublic,
    newFileTitles
  } = action.payload;
  try {
    const document = yield call(updateDocument, documentId, {
      title,
    });
    const urlResp = yield call(uploadUrls, {
      files: files.map(file => ({ name: file.name })),
      companyId,
      documentGroupType: type,
    });
    yield call(
      uploadFileToS3,
      urlResp.map(resp => resp.url),
      files,
    );
    yield call(updateDocumentFiles, document.documentId, [
      ...urlResp.map(resp => resp.fileId),
      ...oldFiles.map(file => file.fileId),
    ]);
    const newFiles = urlResp.map((file, index) => ({
      ...file,
      title: newFileTitles[index],
    }));
    yield call(updateDocumentFileTitles, [...oldFiles, ...newFiles]);
    yield put(
      showToast({
        message: 'theDocumentHasBeenEdited',
        type: 'success',
      }),
    );
    yield call(getDocumentGroups, action);
    yield put(editDocumentSucceed());
  } catch (err) {
    yield put(editDocumentSucceed());
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* refreshDocument(action) {
  try {
    const document = yield call(refreshSignDocument, action.payload);
    yield put(refreshSignDocumentSucceed(document));
  } catch (err) {
    yield put(setLoading(false));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* refreshGroup(action) {
  try {
    const document = yield call(refreshSignGroup, action.payload);
    yield put(refreshSignGroupSucceed(document));
  } catch (err) {
    yield put(setLoading(false));
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* sendPasswordRequest(action) {
  try {
    const signature = yield call(sendCompanyPassword, action.payload);
    yield put(
      showToast({
        message: 'passwordHasBeenSentToYourEmail',
        type: 'success',
      }),
    );
    // yield put(refreshSignatureSucceed(signature));
  } catch (err) {
    // yield put(setLoading(false));
    // yield put(showToast({ message: err?.response?.data?.messages[0] ? err.response.data.messages[0] : 'unexpectedError', type: 'error' }));
  }
}

function* moveDocumentSuccess(action) {
  try {
    yield put(
      showToast({
        message: 'documentHasBeenMoved',
        type: 'success',
      }),
    );
  } catch (err) {}
}

export default function* adminDocumentsSaga() {
  yield takeLatest(ActionTypes.GET_NUMBERS_OF_DOCUMENTS, getNumberOfDocuments);
  yield takeLatest(ActionTypes.GET_DOCUMENTS, getDocumentGroups);
  yield takeLatest(ActionTypes.CREATE_GROUP, createGroup);
  yield takeLatest(ActionTypes.DELETE_GROUP, deleteGroup);
  yield takeLatest(ActionTypes.CREATE_DOCUMENT, createDocumentSaga);
  yield takeLatest(ActionTypes.DELETE_DOCUMENT, deleteDocumentSaga);
  yield takeLatest(ActionTypes.EDIT_DOCUMENT, editDocument);
  yield takeLatest(ActionTypes.REFRESH_SIGN_DOCUMENT, refreshDocument);
  yield takeLatest(ActionTypes.REFRESH_SIGN_GROUP, refreshGroup);
  yield takeLatest(ActionTypes.SEND_PASSWORD_ACTION, sendPasswordRequest);
  yield takeLatest(ActionTypes.MOVE_DOCUMENT_SUCCESS, moveDocumentSuccess);
}
