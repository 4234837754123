/*
 *
 * AdminDocuments reducer
 *
 */

import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import { flatMap } from 'lodash';
export const initialState: ContainerState = {
  documentGroups: [],
  numberOfDocuments: {},
  loading: false,
  loadingDocument: false,
};

const companyDocumentsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.GET_DOCUMENTS:
        draft.documentGroups = [];
        draft.loadingDocument = true;
        break;
      case ActionTypes.GET_DOCUMENTS_SUCCEED:
        draft.documentGroups = flatMap(
          action.payload.map(item => {
            if (item.subType === 'COMPANY') {
              return item;
            }
            return [];
          }),
        );
        draft.loadingDocument = false;
        break;
      case ActionTypes.GET_NUMBERS_OF_DOCUMENTS_SUCCEED:
        draft.numberOfDocuments = action.payload;
        break;
      case ActionTypes.CREATE_DOCUMENT:
      case ActionTypes.EDIT_DOCUMENT:
        draft.loading = true;
        break;
      case ActionTypes.CREATE_DOCUMENT_SUCCEED:
      case ActionTypes.EDIT_DOCUMENT_SUCCEED:
        draft.loading = false;
        break;
      case ActionTypes.REQUEST_SIGN:
        draft.loading = true;
        break;
      case ActionTypes.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ActionTypes.SET_LOADING_DOCUMENT:
        draft.loadingDocument = action.payload;
        break;
      case ActionTypes.REQUEST_SIGN_SUCCEED:
        draft.loading = false;
        break;
      case ActionTypes.REFRESH_SIGN_GROUP:
        draft.loading = true;
        break;
      case ActionTypes.REFRESH_SIGN_DOCUMENT:
        draft.loading = true;
        break;
      case ActionTypes.REFRESH_SIGN_GROUP_SUCCEED:
        draft.loading = false;
        for (let i = 0; i < draft.documentGroups.length; i++) {
          if (
            draft.documentGroups[i].documentGroupId ===
            action.payload.documentGroupId
          ) {
            draft.documentGroups[i] = action.payload;
            break;
          }
        }
        break;
      case ActionTypes.REFRESH_SIGN_DOCUMENT_SUCCEED:
        draft.loading = false;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < draft.documentGroups.length; i++) {
          const { documents } = draft.documentGroups[i];
          if (documents) {
            for (let j = 0; j < documents.length; j++) {
              if (documents[j].documentId === action.payload.documentId) {
                draft.documentGroups[i].documents[j] = action.payload;
                break;
              }
            }
          }
        }
        break;
      default:
        break;
    }
  },
  initialState,
);

export default companyDocumentsReducer;
