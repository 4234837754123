import { takeLatest, call, put, select } from 'redux-saga/effects';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { fetchDocumentSuccessAction } from './actions';
import { fetchUser } from 'services/api/profile-service';

function* getUserDocument(action) {
  try {
    const response = yield call(fetchUser, action.payload);
    yield put(fetchDocumentSuccessAction(response));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

export default function* dealDetailSaga() {
  yield takeLatest(ActionTypes.FETCH_USER_DOCUMENT_ACTION, getUserDocument);
}
