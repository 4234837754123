/*
 *
 * InvestorDashboard
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { createStructuredSelector } from 'reselect';
import { uniq } from 'lodash';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  Container,
} from '@material-ui/core';
import ErrorToast from 'components/ErrorToast';
import DashboardProfile from './components/dashboardProfile';
import DashboardSummary from './components/dashboardSummary';
import DashboardActivity from './components/dashboardActivity';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { DashboardSummary as Summary } from 'models/request/investor-statistic';
import { ActivityItem } from 'models/request/dashboard-activity';
import InvestorDashboardQuery from 'gql/dashboard/InvestorDashboard.gql';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendQuestionAndComment } from 'services/api/mail-service';
import DashboardFeedback from './components/dashboardFeedback';
import { submitEvent } from 'services/api/notification-service';
import { useDispatch } from 'react-redux';
import { showToast } from 'containers/App/actions';
import SendEmailUniversal from 'components/SendEmailModalUniversal';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

const useStyles = makeStyles((theme: Theme) => ({
  bottomSpacing: {
    paddingBottom: 12,
  },
  topSpacing: {
    paddingTop: 4,
  },
  leftPanel: {
    paddingLeft: 4,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingBottom: 12,
    },
  },
  rightPanel: {
    paddingRight: 4,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingBottom: 12,
    },
  },
  subTitleText: {
    color: '#888888',
    fontSize: '1.05rem',
    fontWeight: 600,
    marginBottom: 12,
  },
}));

function InvestorDashboard() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const { user } = useSelector(stateSelector);
  const [message, setMessage] = useState('');

  const LANGUAGE_CODE = user?.languageCode ? user.languageCode : 'en';

  const { loading, error, data, refetch } = useQuery(InvestorDashboardQuery, {
    variables: {
      userId: user ? user.userId : '',
      language: LANGUAGE_CODE,
    },
  });

  useEffect(() => {
    dispatch(setIsLoading(loading));
  }, [loading]);

  let activeInvestments: Summary = {};
  let openInvestments: Summary = {};
  let investmentOpportunities: ActivityItem[] = [];
  let topReports: ActivityItem[] = [];
  let topDocuments: ActivityItem[] = [];

  if (data) {
    const currentCompanyIds: string[] = [];
    let raisedAmount = 0;
    let totalTickets = 0;

    data.deal_investor.forEach(el => {
      currentCompanyIds.push(el.deal.company.id);
      raisedAmount =
        raisedAmount + el.deal.tickets_aggregate.aggregate.sum.amount;
      totalTickets = totalTickets + el.deal.tickets_aggregate.aggregate.count;
    });

    const followingCompanyIds: string[] = [];
    let followingRaised = 0;
    let followingTotal = 0;

    data.following.forEach(el => {
      followingCompanyIds.push(el.deal.company.id);
      followingRaised =
        followingRaised + el.deal.tickets_aggregate.aggregate.sum.amount;
      followingTotal =
        followingTotal + el.deal.tickets_aggregate.aggregate.count;
    });

    activeInvestments = {
      companies: uniq(currentCompanyIds).length,
      deals: data.deal_investor.length,
      raisedAmount,
      tickets: totalTickets,
    };

    openInvestments = {
      companies: uniq(followingCompanyIds).length,
      deals: data.following.length,
      raisedAmount: followingRaised,
      tickets: followingTotal,
    };

    investmentOpportunities = data.top
      .filter(item => !!item.deal)
      .map(item => {
        return {
          title: item.deal?.deal_translations[0].dealName,
          picture: item.deal?.company.logo,
          totalNumber: item.deal?.totalSizeRound,
          subTitle: item.deal?.company.company_translations[0]?.companyName,
          dateStr: item.lastInvitationTime,
          url: `deals/${item.deal?.dealId}`,
        };
      });

    topReports = data.reports.map(item => {
      return {
        title: item.dealname,
        picture: item.companylogo,
        subTitle: item.companyname,
        dateStr: item.documentcreated,
        group: item.documentgrouptitle,
        url: `deals/${item.dealid}?tab=documents`,
      };
    });

    topDocuments = data.documents.map(item => {
      return {
        title: item.dealname,
        picture: item.companylogo,
        subTitle: item.companyname,
        dateStr: item.documentcreated,
        group: item.documentgrouptitle,
        url: `deals/${item.dealid}?tab=documents`,
      };
    });
  }

  useEffect(() => {
    refetch();
  }, []);

  const scope = 'InvestorDashboard';
  const emailError = () => {
    dispatch(
      showToast({
        message: 'INTERNAL_SERVER_ERROR',
        type: 'error',
      }),
    );
  };
  const emailSuccess = () => {
    submitEvent({userId: user!.userId, dealId: null, type: 'INVESTOR_NEW_MESSAGE'});
    dispatch(
      showToast({
        message: 'E-mail successfully sent',
        type: 'success',
      }),
    );
  };

  return (
    <div className="main">
      <Container>
        <ErrorToast
          isOpen={Boolean(error)}
          message={error ? error.message : ''}
        />
        <Helmet>
          <title>{`${intl.messages[`${scope}.investorDashboard`]}`}</title>
          <meta
            name="description"
            content={`${
              intl.messages[`${scope}.investorDashboardDescription`]
            }`}
          />
        </Helmet>

        {!error && !loading && (
          <div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <DashboardProfile scope={scope} />
              </Grid>
              <Grid item xs={12} md={8}>
                <DashboardSummary
                  title={`${intl.messages[`${scope}.openInvestments`]}`}
                  linkText={`${intl.messages[`${scope}.viewDealflow`]}`}
                  linkUrl="/deals"
                  summary={openInvestments}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <div className={classes.bottomSpacing}>
                  <DashboardActivity
                    items={investmentOpportunities}
                    title={`${
                      intl.messages[`${scope}.investmentOpportunities`]
                    }`}
                    viewAllUrl="/deals?status=NOT_INVESTED"
                  />
                </div>
                <div className={classes.bottomSpacing}>
                  <SendEmailUniversal
                    isBasic={false}
                    placeholder={`${intl.messages['investorAdminMessage']}`}
                    senderEmail={user!.email}
                    onSubmitSuccessHandler={emailSuccess}
                    onSubmitErrorHandler={emailError}
                    title={intl.messages['haveSomethingToTellUs'] as string}
                    investorId={user!.userId}
                    investorName={user!.fullName}
                    description={
                      intl.messages[
                        'pleaseUseTheFormBelowOrContactUsAt'
                      ] as string
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={4}>
                  <Grid item xs={12} className={classes.bottomSpacing}>
                    <DashboardSummary
                      title={`${intl.messages[`${scope}.activeInvestments`]}`}
                      linkText={`${intl.messages[`${scope}.viewPortfolio`]}`}
                      linkUrl="/portfolio"
                      summary={activeInvestments}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.rightPanel}>
                    <DashboardActivity
                      items={topReports}
                      title={`${intl.messages[`reports`]}`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.leftPanel}>
                    <DashboardActivity
                      title={`${intl.messages[`documents`]}`}
                      items={topDocuments}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </div>
  );
}

export default memo(InvestorDashboard);
