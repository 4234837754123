import { Button, Grid } from '@material-ui/core';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import React, { useState } from 'react';
import { sendQuestionAndComment } from 'services/api/mail-service';
import { ReactComponent as PaperPlaneSvg } from 'images/icons/paper-plane.svg';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'containers/Home/actions';

type Props = {
  title: string; // title to display on the modal if undefined then no title
  description?: string; //description to show on the modal if undefined then no description
  placeholder: string; //if undefined then 'Message...' will display
  isBasic?: boolean; //button style
  buttonText?: string; // optional button text if undefined will use default text
  onCancelHandler?: () => void;
  onSubmitSuccessHandler: () => void;
  onSubmitErrorHandler?: () => void;
  topics?: string[]; //email topics to chose from if only one present its not a dropdown
  senderEmail?: string; // logged in user's email or email of the sender
  dealId?: string; // id of the deal
  dealName?: string; // name of the deal
  investorId?: string; // id of the investor
  investorName?: string; // name of the investor
};

type EmailProps = {
  template: string;
  contentParams: {
    REPLY_TO_EMAIL: string;
    RECEIVER?: string;
    DEAL_NAME?: string;
    INVESTOR_ID?: string;
    INVESTOR_NAME?: string;
    TOPIC: string;
    DEAL_ID?: string;
    MESSAGE: string;
    EMAIL_HEADER: string;
  };
};
const SendEmailUniversal = (props: Props) => {
  const {
    title,
    description,
    buttonText,
    placeholder,
    onCancelHandler,
    onSubmitSuccessHandler,
    onSubmitErrorHandler,
    topics,
    senderEmail,
    dealId,
    dealName,
    investorId,
    investorName,
    isBasic = true,
  } = props;

  const initState = {
    template: 'multi-mail',
    contentParams: {
      REPLY_TO_EMAIL: senderEmail ? senderEmail : '',
      RECEIVER: senderEmail,
      DEAL_NAME: dealName ? dealName : '',
      TOPIC:
        topics && topics.length > 0 ? topics[0] : 'Investor contacts admin',
      DEAL_ID: dealId ? dealId : '',
      INVESTOR_ID: investorId ? investorId : '',
      INVESTOR_NAME: investorName ? investorName : '',
      MESSAGE: '',
      EMAIL_HEADER:
        topics && topics.length !== 0 ? topics[0] : 'Investor contacts admin',
    },
  };

  const [formData, setFormData] = useState(initState);

  const TextAreaChangeHandler = e => {
    setFormData({
      ...formData,
      contentParams: {
        ...formData.contentParams,
        MESSAGE: (e.target as HTMLInputElement).value,
      },
    });
    console.log(isBasic);
  };
  const dispatch = useDispatch();
  const sendEmail = async (formData: EmailProps) => {
    dispatch(setIsLoading(true));
    try {
      await sendQuestionAndComment(formData);
    } catch (e) {
      throw Error('sendEmail_error');
    } finally {
      dispatch(setIsLoading(false));
    }
  };
  const onSubmit = async () => {
    try {
      await sendEmail(formData);
      onSubmitSuccessHandler();
      setFormData({
        ...formData,
        contentParams: {
          ...formData.contentParams,
          MESSAGE: '',
        },
      });
    } catch (e) {
      console.log(e);
      if (onSubmitErrorHandler) {
        onSubmitErrorHandler();
      }
    }
  };
  return isBasic ? (
    <div>
      <div
        style={{
          borderBottom: '0.5px solid #333333',
          marginBottom: '0.25rem',
          paddingBottom: '0.5rem',
        }}
      >
        <h3 style={{ margin: '0rem', fontSize: '17px', fontWeight: 'bolder' }}>
          {title ? title : 'Schedule a call with the Management Team.'}
        </h3>
      </div>

      {description ? <p style={{ fontSize: '14px' }}>{description}</p> : null}
      <div>
        <textarea
          style={{
            minWidth: '100%',
            minHeight: '50px',
            padding: '0.5rem',
            borderRadius: '10px',
            backgroundColor: '#0D0D0D',
            border: '0.5px solid #333333',
            color: '#999999',
            fontSize: '14px',
            width: '100%',
            //resize: 'none',
          }}
          value={formData.contentParams.MESSAGE}
          onChange={TextAreaChangeHandler}
          placeholder={placeholder ? placeholder : 'Message...'}
        ></textarea>
        <Button className={'default'} onClick={onSubmit}>
          {buttonText ? buttonText.toUpperCase() : 'Send'.toUpperCase()}
        </Button>
        {onCancelHandler ? (
          <Button className={'secondary'} onClick={onCancelHandler}>
            {'Cancel'}
          </Button>
        ) : null}
      </div>
    </div>
  ) : (
    <div>
      <div
      // style={{
      //   borderBottom: '0.5px solid #333333',
      //   marginBottom: '0.25rem',
      //   paddingBottom: '0.5rem',
      // }}
      >
        <h3 style={{ margin: '0rem', fontSize: '17px', fontWeight: 'bolder' }}>
          {title ? title : 'Schedule a call with the Management Team.'}
        </h3>
      </div>

      {description ? (
        <p style={{ fontSize: '14px', textAlign: 'justify' }}>
          {description}
          <a
            style={{ fontSize: '14px', color: 'var(--color-cyan)' }}
            href="mailto:info@jadesquare.com"
          >
            info@jadesquare.com
          </a>
        </p>
      ) : null}

      <div style={{ display: 'flex' }}>
        <textarea
          style={{
            minWidth: '100px',
            width: '85%',
            minHeight: '75px',
            height: '100%',
            maxHeight: '200px',
            padding: '0.5rem',
            borderRadius: '10px',
            backgroundColor: '#0D0D0D',
            border: '0.5px solid #333333',
            color: '#999999',
            fontSize: '14px',
            resize: 'none',
            display: 'inline-block',
          }}
          value={formData.contentParams.MESSAGE}
          onChange={TextAreaChangeHandler}
          placeholder={placeholder ? placeholder : 'Message...'}
        ></textarea>
        <Button
          style={{
            width: '12%',
            minWidth: '50px',
            display: 'inline-block',
            minHeight: '75px',
            border: '1px solid var(--color-dark-purple)',
            marginLeft: '5px',
            height: '100%',
          }}
          onClick={onSubmit}
        >
          <PaperPlaneSvg />
        </Button>
      </div>
    </div>
  );
};

export default SendEmailUniversal;
