import { takeLatest, call, put, select } from 'redux-saga/effects';
import { showToast } from 'containers/App/actions';
import ActionTypes from './constants';
import { updateVehicle, createVehicle } from 'services/api/vehicle-service';
import { createUserVehicleSuccessAction } from 'containers/ProfileVehicles/actions';
import { updateDocumentFiles } from 'services/api/file-service';
function* updateVehicleRequest(action) {
  const { vehicleId, request } = action.payload;
  try {
    const response = yield call(updateVehicle, vehicleId, request);
    yield put(createUserVehicleSuccessAction(response));
    yield put(
      showToast({
        message: 'vehicleSuccessfullyUpdated',
        type: 'success',
      }),
    );
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* createVehicleRequest(action) {
  try {
    const response = yield call(createVehicle, {
      ...action.payload,
      status: 'ACTIVE',
    });
    yield put(createUserVehicleSuccessAction(response));
    yield put(
      showToast({
        message: 'vehicleSuccessfullyCreated',
        type: 'success',
      }),
    );
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* uploadDocumentRequest(action) {
  const { documentId, fileIds } = action.payload;
  const updateProfileResponse = yield call(
    updateDocumentFiles,
    documentId,
    fileIds,
  );
}

export default function* profileVehiclelSaga() {
  yield takeLatest(ActionTypes.SUBMIT_UPDATE_ACTION, updateVehicleRequest);
  yield takeLatest(ActionTypes.CREATE_ACTION, createVehicleRequest);
}
