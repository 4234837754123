import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import { BACKEND_API_URL } from './../../env';
const instance = createService(BACKEND_API_URL);

export async function getCountries(): Promise<any> {
  return instance
    .get('/countries')
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}
