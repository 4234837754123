import React, { memo, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import JoditEditor from 'jodit-react';
import { Dialog, Grid, Button, makeStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import { base64Encode, base64Decode } from 'utils/helpers';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import PopupTitle from 'components/PopupTitle';
import UpdateGuidanceByIdMutation from 'gql/guidance/UpdateGuidanceById.gql';
import { LANGUAGES } from 'utils/constants';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  height: '60vh',
};

interface Props {
  guidances: any;
  open: boolean;
  onCancel: () => void;
  onEdit: (isSuccess: boolean, message: string) => void;
}

const useStyle = makeStyles({
  fullWidth: {
    width: '100%',
  },
  summaryTitle: {
    margin: '10px 0',
  },
});

const GuidanceEditor = (props: Props) => {
  const intl = useIntl();
  const classes = useStyle();
  const { currentUser } = useSelector(stateSelector);
  const { open, onCancel, onEdit, guidances } = props;
  const [content, setContent] = useState({});
  const [value, setValue] = React.useState('en');

  const [updateGuidanceById] = useMutation(UpdateGuidanceByIdMutation);

  const getGuidanceByLanguage = language => {
    return guidances?.filter(item => item.languageCode === language)[0];
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeContent = (language, value, decodeNeeded) => {
    if (decodeNeeded) {
      value = base64Decode(value);
    }

    const newValue = { [language]: value };
    setContent(prevState => ({ ...prevState, ...newValue }));
  };

  const handleEdit = () => {
    LANGUAGES.forEach(language => {
      const guidance = getGuidanceByLanguage(language);

      updateGuidanceById({
        variables: {
          guidanceId: guidance.id,
          description: base64Encode(content[language]),
          modifiedBy: currentUser?.userId || '',
        },
      }).then(
        () => {
          onEdit(true, `${intl.messages['guidanceHasBeenSetUpSuccessFully']}`);
        },
        () => {
          onEdit(false, 'unexpectedError');
        },
      );
    });
  };

  const loadState = () => {
    LANGUAGES.map(language => {
      const loadedGuidance = getGuidanceByLanguage(language)?.description || '';

      onChangeContent(language, loadedGuidance, true);
    });
  };

  const handleCancel = () => {
    loadState();
    onCancel();
  };

  useEffect(() => {
    loadState();
  }, [guidances]);

  const localScope = 'GuidanceSetting.GuidanceEditor';
  return (
    <>
      <Dialog open={open} maxWidth="md">
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <PopupTitle
                title={`${intl.messages[`${localScope}.popupTitle`]}`}
                onClose={handleCancel}
              />
            </Grid>
            <TabContext value={value}>
              <TabList
                onChange={handleTabChange}
                aria-label={`${intl.messages['languageTabs']}`}
                variant="fullWidth"
                classes={{
                  root: classes.fullWidth,
                }}
              >
                <Tab label="English" value="en" />
                <Tab label="German" value="de" />
                <Tab label="Japanese" value="ja" />
              </TabList>
              {LANGUAGES.map(language => (
                <TabPanel
                  key={`tab_${language}`}
                  value={language}
                  classes={{
                    root: classes.fullWidth,
                  }}
                >
                  <Grid item xs={12} sm={12}>
                    <JoditEditor
                      value={content[language] || ''}
                      // @ts-ignore We need this because of an issue in Jodit editor
                      config={config}
                      onBlur={newContent =>
                        onChangeContent(language, newContent, false)
                      }
                    />
                  </Grid>
                </TabPanel>
              ))}
            </TabContext>
            <Grid item xs={12} sm={6} />
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                size={'small'}
                style={{ marginRight: 24 }}
                color="primary"
                variant="outlined"
                onClick={handleCancel}
              >
                {`${intl.messages['cancel']}`.toUpperCase()}
              </Button>
              <Button
                size={'small'}
                style={{ marginTop: '0px' }}
                variant="contained"
                color="primary"
                onClick={handleEdit}
              >
                {`${intl.messages['update']}`.toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

export default memo(GuidanceEditor);
