import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the dealCreator state domain
 */

const selectDealCreatorDomain = (state: ApplicationRootState) => {
  return state.dealCreator || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by DealCreator
 */

const makeSelectDealCreator = () =>
  createSelector(selectDealCreatorDomain, substate => {
    return substate;
  });

export default makeSelectDealCreator;
export { selectDealCreatorDomain };
