/*
 *
 * DealDescription
 *
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Container, Grid, Modal, styled } from '@material-ui/core';

import DealEditor from 'containers/DealEditor';
import Banner from './components/Banner';
import Detail from './components/Detail';
import FullDescription from './components/FullDescription';
import Attachments from './components/Attachments';
import Mailer from './components/Mailer';
import ErrorToast from 'components/ErrorToast';
import Deal from 'models/deal';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import makeSelectDealDescription from './selectors';
import reducer from './reducer';
import saga from './saga';
import { sendComment, setTopic, setMessage } from './actions';
import { DetailWrapper } from './styles';
import { useIntl } from 'react-intl';
import GetBannerByDealIdQuery from 'gql/banner/GetBannerByDealId.gql';
import GetBannerByDealIdInvestorQuery from 'gql/banner/GetBannerByDealIdInvestor.gql';
import SuccessToast from 'components/SuccessToast';
import { ApolloClient } from '@apollo/client';
import Ticket from 'models/tickets';
import TicketCard from './components/TicketCard';
import SmallModal from './components/SmallModal';
import SendEmailUniversal from 'components/SendEmailModalUniversal';
import { isNull } from 'lodash';
import RequestAccessModal from './components/AccessRequest';
import { submitEvent } from 'services/api/notification-service';

const stateSelector = createStructuredSelector({
  dealDescription: makeSelectDealDescription(),
  user: makeSelectCurrentUser(),
});

interface InvestorDocumentsState {
  readonly statistic: any;
  readonly documentGroups: any;
  readonly loading: boolean;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  admin?: boolean;
  deal: Deal;
  tickets?: Ticket[];
  statistics?: any;
  attachments?: any[];
  onUpdate: () => void;
  onBannerCommitClicked?: any;
  publicDocuments?: any[];
}

function DealDescription(props: Props): JSX.Element {
  const intl = useIntl();
  useInjectReducer({ key: 'dealDescription', reducer: reducer });
  useInjectSaga({ key: 'dealDescription', saga: saga });

  const { dealDescription, user } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const {
    admin,
    deal,
    statistics,
    attachments,
    onUpdate,
    onBannerCommitClicked,
    tickets = [],
    publicDocuments
  } = props;
  const { message, topic, bannerLogo } = dealDescription;
  const [dealId, setDealId] = React.useState<string | undefined>();
  const [isDealEditorOpen, setIsDealEditorOpen] = React.useState(false);
  const [dealEditSuccess, setDealEditSuccess] = React.useState<
    string | undefined
  >(undefined);
  const [dealEditError, setDealEditError] = React.useState<string | undefined>(
    undefined,
  );
  const currentUser = user || {
    email: '',
    fullName: '',
    languageCode: 'en',
    userId: ''
  };

  const LANGUAGE_CODE = currentUser?.languageCode
    ? currentUser.languageCode
    : 'en';

  const isAdmin = () => {
    return user && user.userType === 'ADMIN';
  };

  const files = attachments && attachments.length > 0 && attachments[0].files;

  const variables: any = {
    dealId: deal.dealId,
  };

  if (!isAdmin()) {
    variables.language = LANGUAGE_CODE;
  }

  const { error, data, refetch } = useQuery(
    isAdmin() ? GetBannerByDealIdQuery : GetBannerByDealIdInvestorQuery,
    {
      variables,
    },
  );

  useEffect(() => {
    if (deal.dealId) {
      setDealId(deal.dealId);
    }
  }, [deal.dealId]);

  const onBannerUpdate = message => {
    setDealEditSuccess(message);
    refetch();
  };

  const onBannerEdit = () => {
    setDealEditSuccess(undefined);
  };

  const onFullDescriptionUpdate = () => {
    onUpdate();
  };

  const editDeal = () => {
    setIsDealEditorOpen(true);
  };

  const onSendMail = () => {
    dispatch(
      sendComment({
        template: 'INVESTOR_CONTACT_ADMIN_IN_DEAL',
        contentParams: {
          REPLY_TO_EMAIL: currentUser.email,
          RECEIVER: currentUser.fullName,
          DEAL_NAME: deal.deal_translations[0].name,
          DEAL_ID: deal.dealId,
          INVESTOR_NAME: currentUser.fullName,
          INVESTOR_ID: currentUser.userId,
          TOPIC: `${intl.messages[topic]}`,
          MESSAGE: message.replace(/\n/g, '<br />'),
          EMAIL_HEADER: `${intl.messages['investorContactForTopicInDeal']}`,
        },
      }),
    );
  };

  const onFounderClick = () => {
    setEmailModalState({
      title: 'Participate in an exclusive presentation by the founder.',
      placeholder: 'Please provide time slots when it\'s best for you. Please include time zone!',
      topics: ['Investor requests presentation from founder'],
      eventType: 'INVESTOR_REQUESTED_DEAL_PARTICIPATE',
    });
    setOpen(!open);
  };
  const onManagementClick = () => {
    setEmailModalState({
      title: 'Schedule a call with the company ('+deal?.company?.company_translations[0].name+').',
      placeholder: 'Your message comes here...',
      topics: ['Investor requests call with Management team'],
      eventType: 'INVESTOR_REQUESTED_DEAL_CALL',
    });
    setOpen(!open);
  };
  let summaryData;
  if (tickets.length > 0) {
    const individualTickets: Ticket[] = tickets.filter((ticket: Ticket) => {
      return !ticket.vehicle || ticket.vehicle.vehicleType === 'INDIVIDUAL';
    });
    const corporateTickets: Ticket[] = tickets.filter((ticket: Ticket) => {
      return ticket.vehicle && ticket.vehicle.vehicleType === 'CORPORATE';
    });
    const individualTicketAmount = individualTickets.reduce(
      (partialSum, ticket: Ticket) => partialSum + ticket.amount,
      0,
    );
    const corporateTicketAmount = corporateTickets.reduce(
      (partialSum, ticket: Ticket) => partialSum + ticket.amount,
      0,
    );

    const individualTicketCount: number = individualTickets.length;
    const corporateTicketsCount: number = corporateTickets.length;
    summaryData = {
      individual: {
        amount: individualTicketAmount,
        count: individualTicketCount,
        percent: Math.floor(
          (individualTicketAmount / tickets[0]?.deal.totalSizeRound) * 100,
        ),
      },
      corporate: {
        amount: corporateTicketAmount,
        count: corporateTicketsCount,
        percent: Math.floor(
          (corporateTicketAmount / tickets[0]?.deal.totalSizeRound) * 100,
        ),
      },
    };
  }

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [bannerLogo]);

  const onDealEditSuccess = message => {
    setDealEditSuccess(message);
    setIsDealEditorOpen(false);
    refetch();
    onUpdate();
  };

  const [open, setOpen] = useState(false);
  const [emailModalState, setEmailModalState] = useState({
    title: '',
    placeholder: '',
    topics: ['Investor contacts admin'],
    eventType: ''
  });

  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const onRequestModalOpen = () => {
    setIsRequestModalOpen(true);
  };

  const emailSubmitSuccess = () => {
    submitEvent({userId: user!.userId, dealId: deal.dealId, type: emailModalState.eventType});
    setOpen(false);
  }

  return (
    <>
      <ErrorToast
        isOpen={Boolean(error || dealEditError)}
        message={
          error ? `${intl.messages[`${error.message}`]}` : dealEditError || ''
        }
      />
      <SuccessToast
        isOpen={Boolean(dealEditSuccess)}
        message={dealEditSuccess || ''}
      />
      {isRequestModalOpen && (
        <RequestAccessModal
          dealId={dealId}
          isOpen={isRequestModalOpen}
          onClose={() => {
            setIsRequestModalOpen(false);
          }}
          isAdmin={isAdmin()}
        />
      )}
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Container
          maxWidth="sm"
          style={{
            backgroundColor: 'var(--color-black)',
            border: '1px solid var(--color-dark-grey)',
            borderRadius: '3px',
            padding: '0.5rem',
          }}
        >
          <SendEmailUniversal
            title={emailModalState.title}
            placeholder={emailModalState.placeholder}
            onSubmitSuccessHandler={() => emailSubmitSuccess()}
            onCancelHandler={() => setOpen(false)}
            senderEmail={currentUser.email}
            dealId={dealId}
            dealName={deal.deal_translations[0].name}
            investorId={currentUser.userId}
            investorName={currentUser.fullName}
            topics={emailModalState.topics}
          />
        </Container>
      </Modal>
      <Banner
        deal={deal}
        statistics={statistics}
        admin={admin}
        banner={data?.banner && data.banner[0]}
        onUpdate={onBannerUpdate}
        onOpen={onBannerEdit}
        files={attachments}
        onBannerCommitClicked={onBannerCommitClicked}
        onFounderClick={onRequestModalOpen}
        onManagementClick={onRequestModalOpen}
      />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            {tickets && summaryData && <TicketCard summary={summaryData} />}
            <Detail
              deal={deal}
              admin={admin}
              editDeal={editDeal}
              attachments={files}
              publicDocuments={publicDocuments}
            />

            <Box display={{ xs: "none", md: "block" }}>
              <SmallModal
                text="Participate in an exclusive presentation by the founder."
                onButtonClick={onFounderClick}
                buttonText="Participate"
              />
              
              <SmallModal
                text={'Schedule a call with ' + deal?.company?.company_translations[0].name }
                onButtonClick={onManagementClick}
                buttonText="Schedule"
              />
            </Box>  
          </Grid>
          <Grid item xs={12} md={8}>
            <Card style={{ marginBottom: 15, padding: '1.25rem' }}>
              <FullDescription
                deal={deal}
                admin={admin}
                onUpdate={onFullDescriptionUpdate}
              />
            </Card>
            <Box style={{ width: '100%' }} display={{ md: "none" }}>
              <SmallModal
                text="Participate in an exclusive presentation by the founder."
                onButtonClick={onFounderClick}
                buttonText="Participate"
              />

              <SmallModal
                text={'Schedule a call with ' + deal?.company?.company_translations[0].name }
                onButtonClick={onManagementClick}
                buttonText="Schedule"
              />
            </Box>
            {!admin && (
              <Card style={{ padding: '1.25rem' }}>
                <Mailer
                  message={message}
                  topic={topic}
                  onChangeMessage={v => dispatch(setMessage(v))}
                  onChangeTopic={v => dispatch(setTopic(v))}
                  onSubmit={onSendMail}
                />
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
      {isDealEditorOpen && (
        <DealEditor
          client={props.client}
          deal={deal}
          attachments={attachments}
          isOpen={isDealEditorOpen}
          setOpen={setIsDealEditorOpen}
          onSuccess={message => onDealEditSuccess(message)}
          onError={setDealEditError}
        />
      )}
    </>
  );
}

export default DealDescription;
