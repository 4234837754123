import AuthRequest from 'models/request/auth-request';
import { RestResult } from 'models/response/rest-result';
import { AuthResponse } from 'models/response/auth-response';
import axios, { AxiosResponse } from 'axios';
import { BACKEND_API_URL } from './../../env';

const instance = axios.create({
  baseURL: BACKEND_API_URL,
});

export async function signIn(request: AuthRequest): Promise<AuthResponse> {
  return instance
    .post('/users/login', request)
    .then((resp: AxiosResponse<RestResult<AuthResponse>>) => {
      return resp.data.data;
    });
}

export async function forgotPassword(request: {
  email: string;
}): Promise<AuthResponse> {
  return instance
    .post('/users/forgot-password', request)
    .then((resp: AxiosResponse<RestResult<AuthResponse>>) => {
      return resp.data.data;
    });
}

export async function resetPassword(request: {
  confirmationPassword: string;
  encryptedString: string;
  newPassword: string;
}): Promise<AuthResponse> {
  const requestBody = {
    confirmPassword: request.confirmationPassword,
    password: request.newPassword,
  };
  const headers = {
    headers: { Authorization: `Bearer ${request.encryptedString}` },
  };
  return instance
    .post('/users/password-reset', requestBody, headers)
    .then((resp: AxiosResponse<RestResult<AuthResponse>>) => {
      return resp.data.data;
    });
}
