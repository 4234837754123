/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      textTransform: 'uppercase',
      fontSize: 10,
    },
    button: {
      textTransform: 'none',
    },
  }),
);

interface Props {
  selected: number;
  total: number;
  onShowOnlySelectedChange: (value) => void;
}

function InviteHeader(props: Props) {
  const { selected, total, onShowOnlySelectedChange } = props;
  const classes = useStyles();
  const localScope = 'InviteInvestor.header';
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          <FormattedMessage
            id={`${localScope}.selected`}
            values={{
              num: selected,
              total: total,
              b: (...chunks) => <b>{chunks}</b>,
            }}
          />
        </Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <Checkbox
            defaultChecked={false}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={ev => onShowOnlySelectedChange(ev.target.checked)}
          />
        </Grid>
        <Grid item>
          <Typography>
            <FormattedMessage id={`${localScope}.showingSelectedInvestor`} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(InviteHeader);
