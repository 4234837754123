enum ActionTypes {
  CREATE_DEAL_GROUP = 'app/DealCreator/CREATE_DEAL_GROUP',
  CREATE_DEAL_SUCCESS_GROUP = 'app/DealCreator/CREATE_DEAL_SUCCESS_GROUP',
  UPLOAD_DOCUMENT = 'app/DealCreator/UPLOAD_DOCUMENT',
}

export enum DealType {
  EQUITY = 'EQUITY',
  SAFE = 'SAFE',
  LOAN = 'LOAN',
}

export default ActionTypes;
