/*
 *
 * Register reducer
 *
 */

import ActionTypes from './constants';
import { ContainerActions, ContainerState } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  step: 1,
  isLoading: false,
};

const registerReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SET_STEP:
        draft.step = action.payload;
        if (draft.profile) {
          switch (action.payload) {
            case 2:
              draft.profile.registerStep = 'IDENTITY';
              break;
            case 3:
              draft.profile.registerStep = 'VEHICLE';
              break;
            case 4:
              draft.profile.registerStep = 'INVESTMENT';
              break;
            case 5:
              draft.profile.registerStep = 'COMPLETED';
              break;
            default:
              break;
          }
        }
        break;
      case ActionTypes.FETCH_PROFILE_SUCCESS_ACTION:
        draft.profile = action.payload;
        if (draft.step === 1) {
          if (action.payload.registerStep) {
            switch (action.payload.registerStep) {
              case 'IDENTITY':
                draft.step = 2;
                break;
              case 'VEHICLE':
                draft.step = 3;
                break;
              case 'INVESTMENT':
                draft.step = 4;
                break;
              case 'COMPLETED':
                draft.step = 5;
                break;
              default:
                break;
            }
          }
        }
        break;
      case ActionTypes.SET_LOADING:
        draft.isLoading = action.payload;

        break;
      default:
        break;
    }
  },
  initialState,
);

export default registerReducer;
