/*
 *
 * Home
 *
 */

import React, { memo, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      color: 'var(--color-grey)',
      fontWeight: 500,
    },
    subTitle: {
      textTransform: 'uppercase',
      color: '#000',
      fontSize: 10,
    },
    button: {
      textTransform: 'none',
    },
    title: {
      color: 'var(--color-grey)',
      fontSize: 14,
      margin: theme.spacing(2, 0),
    },
    purpleText: {
      fontWeight: 700,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  noOfDeal: number;
}

function DealHeader(props: Props) {
  const { noOfDeal } = props;
  const classes = useStyles();
  const localScope = 'Portfolio.header';
  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <Typography className={classes.title}>
              <FormattedMessage id={`thereAreCurrently`} />
              <strong className={classes.purpleText}>
                {noOfDeal}
                <FormattedMessage
                  id={`${localScope}.deal`}
                  values={{ num: noOfDeal }}
                />
              </strong>
            </Typography>
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(DealHeader);
