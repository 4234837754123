/*
 *
 * Tickets reducer
 *
 */
import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  tickets: undefined,
  total: 0,
  success: false,
  reload: false,
};

const ticketsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.CANCEL_TICKET:
        draft.success = false;
        break;
      case ActionTypes.CANCEL_TICKET_SUCCESS_ACTION:
        draft.success = true;
        break;
    }
  },
  initialState,
);

export default ticketsReducer;
