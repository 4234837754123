/*
 *
 * DocumentSettings constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/DocumentSettings/DEFAULT_ACTION',
  GET_TEMPLATE_ACTION = 'app/DocumentSettings/GET_TEMPLATE_ACTION',
  GET_TEMPLATE_SUCCESS_ACTION = 'app/DocumentSettings/GET_TEMPLATE_SUCCESS_ACTION',
  GET_TEMPLATE_FAILED_ACTION = 'app/DocumentSettings/GET_TEMPLATE_FAILED_ACTION',

  UPDATE_TEMPLATE_STATUS_ACTION = 'app/DocumentSettings/UPDATE_TEMPLATE_STATUS_ACTION',
  UPDATE_TEMPLATE_STATUS_SUCCESS_ACTION = 'app/DocumentSettings/UPDATE_TEMPLATE_STATUS_SUCCESS_ACTION',
  UPDATE_TEMPLATE_FAILED_STATUS_ACTION = 'app/DocumentSettings/UPDATE_TEMPLATE_FAILED_STATUS_ACTION',
  SUBMIT_SUCCESS_ACTION = 'app/TemplateCreate/SUBMIT_SUCCESS_ACTION',
  GET_PREVIEW = 'app/TemplateCreate/GET_PREVIEW',
  GET_PREVIEW_SUCCEED = 'app/TemplateCreate/GET_PREVIEW_SUCCEED',
}

export default ActionTypes;
