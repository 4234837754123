/*
 *
 * Commits actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const searchCommits = (request: {
  searchKey?: string;
  page?: number;
  pageSize?: number;
  dealId?: string;
  interestsAny?: any[];
  orders?: any[];
  status?: string;
}) => action(ActionTypes.SEARCH_COMMITS, request);

export const searchCommitsSucceed = (data: any) =>
  action(ActionTypes.SEARCH_COMMITS_SUCCEED, data);
