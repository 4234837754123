/*
 *
 * InvestorDocuments constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InvestorDocuments/DEFAULT_ACTION',
  GET_DOCUMENTS = 'app/InvestorDocuments/GET_DOCUMENTS',
  GET_DOCUMENTS_SUCCEED = 'app/InvestorDocuments/GET_DOCUMENTS_SUCCEED',
  GET_NUMBER_OF_DOCUMENTS = 'app/InvestorDocuments/GET_NUMBER_OF_DOCUMENTS',
  GET_NUMBER_OF_DOCUMENTS_SUCCEED = 'app/InvestorDocuments/GET_NUMBER_OF_DOCUMENTS_SUCCEED',
  REFRESH_ALL_SIGNATURES = 'app/InvestorDocuments/REFRESH_ALL_SIGNATURES',
  REFRESH_ALL_SIGNATURES_SUCCEED = 'app/InvestorDocuments/REFRESH_ALL_SIGNATURES_SUCCEED',
  REFRESH_SIGNATURE = 'app/InvestorDocuments/REFRESH_SIGNATURE',
  REFRESH_SIGNATURE_SUCCEED = 'app/InvestorDocuments/REFRESH_SIGNATURE_SUCCEED',
  SET_LOADING = 'app/InvestorDocuments/SET_LOADING',
  SEND_PASSWORD_ACTION = 'app/InvestorDocuments/SEND_PASSWORD_ACTION',
}

export default ActionTypes;
