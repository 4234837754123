/*
 *
 * InvestorDocuments
 *
 */

import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash/fp";

import Group from "./components/Group";
import Loading from "components/Loading";

import { useInjectSaga } from "utils/injectSaga";
import { useInjectReducer } from "utils/injectReducer";
import makeSelectInvestorDocuments from "./selectors";
import reducer from "./reducer";
import saga from "./saga";
import { getDocuments, getNumberOfDocuments, sendPassword } from "./actions";
import { Button, Container, makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import SearchInput from "components/SearchInput";

const useStyle = makeStyles({
	pswBtn: {
		marginBottom: 12,
	},
});

const stateSelector = createStructuredSelector({
	investorDocuments: makeSelectInvestorDocuments(),
});

interface Props {
	dealId: string;
}

function InvestorDocuments(props: Props) {
	const intl = useIntl();
	// Warning: Add your key to RootState in types/index.d.ts file
	useInjectReducer({ key: "investorDocuments", reducer: reducer });
	useInjectSaga({ key: "investorDocuments", saga: saga });

	const { dealId } = props;
	const { investorDocuments } = useSelector(stateSelector);
	const { statistic, documentGroups, loading } = investorDocuments;
	const dispatch = useDispatch();
	const classes = useStyle();
	useEffect(() => {
		dispatch(getDocuments(dealId));
		dispatch(getNumberOfDocuments(dealId));
	}, []);

	const [expandedPanels, setExpandedPanels] = useState([]);
	const [documentGroupsState, setDocumentGroups] = useState(documentGroups);

	useEffect(() => {
		setDocumentGroups(documentGroups);
	}, [documentGroups]);

	const handleSearch = (search: string) => {
		if (!documentGroups?.documents?.length && !documentGroups?.reports?.length && !documentGroups?.signatures?.length) return;
		if (search) {
			const filteredGroups = {
				...documentGroups,
				documents: documentGroups.documents
					.filter((doc) => {
						if (doc.title.toLowerCase().includes(search.toLowerCase())) return true;

						return doc.files.some((file) => {
							if (file.title) {
								return file.title.toLowerCase().includes(search.toLowerCase());
							}
							return file.name.toLowerCase().includes(search.toLowerCase());
						});
					})
					.map((doc) => {
						return {
							...doc,
							files: doc.title.toLowerCase().includes(search.toLowerCase()) ? doc.files : doc.files.filter((file) => {
								if (file.title) {
									return file.title.toLowerCase().includes(search.toLowerCase());
								}
								return file.name.toLowerCase().includes(search.toLowerCase());
							}),
						};
					}),
				reports: documentGroups.reports
					.filter((doc) => {
						if (doc.title.toLowerCase().includes(search.toLowerCase())) return true;

						return doc.files.some((file) => {
							if (file.title) {
								return file.title.toLowerCase().includes(search.toLowerCase());
							}
							return file.name.toLowerCase().includes(search.toLowerCase());
						});
					})
					.map((doc) => {
						return {
							...doc,
							files: doc.title.toLowerCase().includes(search.toLowerCase()) ? doc.files : doc.files.filter((file) => {
								if (file.title) {
									return file.title.toLowerCase().includes(search.toLowerCase());
								}
								return file.name.toLowerCase().includes(search.toLowerCase());
							}),
						};
					}),
				signatures: documentGroups.signatures?.filter((doc) => {
					if (doc.title?.toLowerCase().includes(search.toLowerCase())) return true;

					return doc.files?.some((file) => {
						if (file?.title) {
							return file?.title?.toLowerCase().includes(search.toLowerCase());
						}
						return file?.name?.toLowerCase().includes(search.toLowerCase());
					});
				}),
			};
			setDocumentGroups(filteredGroups);
			const panelsHasDocuments = [];
			for (const key in filteredGroups) {
				if (filteredGroups[key].length) panelsHasDocuments.push(key);
			}
			setExpandedPanels(panelsHasDocuments);
		} else {
			setDocumentGroups(documentGroups);
			const panelsHasDocuments = [];
			for (const key in documentGroups) {
				if (documentGroups[key].length) panelsHasDocuments.push(key);
			}
			setExpandedPanels(panelsHasDocuments);
		}
	};

	const setExpanded = (expanded: boolean, groupId: string) => {
		setExpandedPanels(expanded ? [...expandedPanels, groupId] : expandedPanels.filter((id) => id !== groupId));
	};

	const scope = "InvestorDocuments";
	return (
		<Container className="main">
			{/* <div style={{ textAlign: 'right' }}>
        <Button
          color="primary"
          variant="contained"
          className={classes.pswBtn}
          onClick={handleSendPassword}
        >
          <FormattedMessage id={`${scope}.sendPassword`} />
        </Button>
      </div> */}
			<div style={{ marginBottom: "15px" }}>
				<SearchInput onChange={(val) => handleSearch(val)} />
			</div>

			<Group signatures={get("signatures")(documentGroupsState) || []} title={`${intl.messages[`signatures`]}`} numberOfDocuments={get("signatures")(documentGroupsState) ? get("signatures")(documentGroupsState).length : 0} dealId={dealId} expanded={expandedPanels.includes("signatures")} setExpanded={(expanded) => setExpanded(expanded, "signatures")} />
			<Group title={`${intl.messages[`documents`]}`} documents={get("documents")(documentGroupsState) || []} numberOfDocuments={get("totalInDocumentPublicGroup")(statistic) || 0} dealId={dealId} expanded={expandedPanels.includes("documents")} setExpanded={(expanded) => setExpanded(expanded, "documents")} />
			<Group title={`${intl.messages[`reports`]}`} documents={get("reports")(documentGroupsState) || []} numberOfDocuments={get("totalInReportPublicGroup")(statistic) || 0} dealId={dealId} expanded={expandedPanels.includes("reports")} setExpanded={(expanded) => setExpanded(expanded, "reports")} />
			{loading && <Loading fullScreen />}
		</Container>
	);
}

export default memo(InvestorDocuments);
