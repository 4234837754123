import React, { memo } from 'react';
import styles from '../styles';

const Header = (props: { children?: any }) => {
  const { children } = props;
  return (
    <div style={{ display: 'flex' }}>
      <p style={styles.header}>{children}</p>
    </div>
  );
};

export default memo(Header);
