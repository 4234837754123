/*
 *
 * ProfileVehicleCreateDialog reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  default: null,
};

const profileVehicleCreateDialogReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.UPDATE_ACTION:
        if (!draft.vehicle) {
          draft.vehicle = {
            vehicleType: 'CORPORATE',
            status: 'ACTIVE',
          };
        }
        draft.vehicle[action.payload.type] = action.payload.value;
        break;
      case ActionTypes.INIT_VEHICLE_ACTION:
        draft.vehicle = action.payload;
        break;
      case ActionTypes.CLEAR_INFO_ACTION:
        draft.vehicle = undefined;
        draft.loading = false;
        break;
      case ActionTypes.SUBMIT_UPDATE_ACTION:
      case ActionTypes.CREATE_ACTION:
        draft.loading = true;
        break;
    }
  },
  initialState,
);

export default profileVehicleCreateDialogReducer;
