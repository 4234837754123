/*
 *
 * RegisterIdentity actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const fetchMeErrorAction = () =>
  action(ActionTypes.FETCH_ME_ERROR_ACTION);

export const updateProfileAction = form =>
  action(ActionTypes.UPDATE_PROFILE_ACTION, form);

export const initProfileAction = payload =>
  action(ActionTypes.INIT_PROFILE_ACTION, payload);

export const updateStepAction = form =>
  action(ActionTypes.UPDATE_STEP_ACTION, form);

export const updateProfileSuccessAction = () =>
  action(ActionTypes.UPDATE_PROFILE_SUCCESS_ACTION);

export const updateProfileFailedAction = () =>
  action(ActionTypes.UPDATE_PROFILE_FAILED_ACTION);

export const resetStateAction = () => action(ActionTypes.RESET_STATE_ACTION);

export const updateFormStateIdentityAction = states =>
  action(ActionTypes.UPDATE_FORM_STATE_IDENTITY_ACTION, states);

export const uploadDocumentAction = payload =>
  action(ActionTypes.UPLOAD_DOCUMENT_ACTION, payload);
