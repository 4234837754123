/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 0,
    },
    title: {
      color: '#888888',
      fontSize: '0.85rem',
    },
    amount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  noOfDeal: number;
}

function DealHeader(props: Props) {
  const { noOfDeal } = props;
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title}>
          <FormattedMessage id={`thereAreCurrently`} />
          <span className={classes.amount}>
            {' '}
            <FormattedMessage
              id={`Companies.noOfDeal`}
              values={{ num: noOfDeal }}
            />
          </span>
        </Typography>
    </div>
  );
}

export default memo(DealHeader);
