import React, { memo, useState } from 'react';
import { Typography, Grid, Link, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { requestDownload, requestDownloadSingleFile, requestPreviewSingleFile } from 'containers/App/actions';
import { useIntl } from 'react-intl';
import { ReactComponent as DownloadSvg } from 'images/icons/with-class/download.svg';
import { ReactComponent as MoveSvg } from 'images/icons/with-class/move.svg';
import { use } from 'chai';
import DocumentMoveDialog from 'components/DocumentMoveDialog/DocumentMoveDialog';

interface Props {
  documents: {
    title: string;
    files: { name: string; url: string; fileId: string, title?: string }[];
    isDefault: boolean;
  }[];
  onRemoveDocument: (doc: any) => void;
  onEditDocument: (doc: any) => void;
  setDocumentToMove: (doc: any) => void;
  setMoveDocumentDialogOpen: (open: boolean) => void;
  companyId: string;
  documentGroupId: string;
  documentCategoryIsPublic: boolean;
  documentCategoryType: string;
}

const style = {
  header: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '10px',
    color: '#888888',
  },
  docName: {
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  files: {
    display: 'grid',
  },
  file: {
    fontWeight: 300,
    fontSize: '0.8rem',
  },
  downloadIcon: {
    fontWeight: 300,
    fontSize: '0.8rem',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  divider: {
    margin: '10px 0px 0px',
    background: '#F6F6F6',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.8rem',
  },
};

function GroupTable(props: Props) {
  const intl = useIntl();
  const { documents, onRemoveDocument, onEditDocument, setDocumentToMove, setMoveDocumentDialogOpen, companyId, documentGroupId, documentCategoryType, documentCategoryIsPublic } = props;
  const dispatch = useDispatch();
  const handleExport = doc => {
    dispatch(
      requestDownload({
        companyId: companyId,
        documentId: doc.documentId,
      }),
    );
  };
  const handleExportFile = id => {
    dispatch(
      requestDownloadSingleFile(id),
    );
  };
  const handlePreviewFile = (id) => {
    dispatch(
      requestPreviewSingleFile(id),
    );
  };
  const handleMoveClick = (file, doc) => {
    let categoryId = null;
    switch(documentCategoryType) {
      case "document":
        if(documentCategoryIsPublic) {
          categoryId = "publicDocument";
        }
        else {
          categoryId = "privateDocument";
        }
        break;
      case "report":
        if(documentCategoryIsPublic) {
          categoryId = "publicReport";
        }
        else {
          categoryId = "privateReport";
        }
        break;
      default:
        break;
    }
    setDocumentToMove({
      file: file,
      doc: doc,
      currentCategoryId: categoryId,
      currentGroupId: documentGroupId,
      currentProjectId: doc.documentId
    });
    setMoveDocumentDialogOpen(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={4} sm={4} style={{ paddingLeft: 4 }}>
          <Typography style={style.header}>
            {`${intl.messages['name']}`.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} style={{ paddingLeft: 4 }}>
          <Typography style={style.header}>
            {`${intl.messages['file']}`.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography>{''}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Divider style={style.divider} />
        </Grid>
        {documents.map((doc, i) => (
          <Grid
            item
            xs={12}
            sm={12}
            container
            key={i}
            style={{ margin: '10px 0' }}
            spacing={2}
          >
            <Grid item xs={4} sm={4}>
              <Typography style={style.docName}>{doc.title}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} style={style.files}>
              {doc.files.map((file, index) => (
                <div key={index}>
                  <Link
                    style={style.file}
                    onClick={() => handlePreviewFile(file.fileId)}
                  >
                    {file.title || file.name}
                  </Link>
                  <Link style={style.downloadIcon} onClick={() => handleExportFile(file.fileId)}><DownloadSvg /></Link>
                  <Link style={style.downloadIcon} onClick={() => handleMoveClick(file, doc)}><MoveSvg /></Link>
                </div>
              ))}
            </Grid>
            <Grid
              item
              container
              xs={4}
              sm={4}
              style={{
                display: 'grid',
                justifyContent: 'flex-end',
                height: 48,
                paddingRight: 0,
              }}
            >
              <div style={style.button}>
                <Link
                  style={{ marginRight: 10 }}
                  onClick={() => onEditDocument(doc)}
                >
                  {`${intl.messages['edit']}`}
                </Link>
                <Link
                  style={{ marginRight: 10 }}
                  onClick={() => handleExport(doc)}
                >{`${intl.messages['exportAll']}`}</Link>
                <Link
                  onClick={() => handleMoveClick(null, doc)}
                >{`${intl.messages['move']}`}</Link>
              </div>
              {!doc.isDefault && (
                <div>
                  <Link
                    style={{ fontSize: '0.8rem', color: '#888888' }}
                    onClick={() => onRemoveDocument(doc)}
                  >
                    {`${intl.messages['remove']}`}
                  </Link>
                </div>
              )}
            </Grid>
            {i < documents.length - 1 && (
              <Grid item xs={12} sm={12}>
                <Divider style={style.divider} />
              </Grid>
            )}
          </Grid>
        ))}
        {!documents.length && (
          <Typography
            style={{ textAlign: 'center', padding: '20px 0', width: '100%' }}
          >
            {`${intl.messages['noDocumentsToDisplay']}`}
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default memo(GroupTable);
