import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const showToast = (payload: ToastMsg | undefined) =>
  action(ActionTypes.SHOW_TOAST, payload);
export const loginSuccess = () => action(ActionTypes.LOGIN_SUCCESS);
export const logout = () => action(ActionTypes.LOGOUT);
export const setUserData = payload =>
  action(ActionTypes.SET_USER_DATA, payload);
export const setUserVehicle = payload =>
  action(ActionTypes.SET_USER_VEHICLE, payload);
export const setPreferences = payload =>
  action(ActionTypes.SET_PREFERENCE, payload);
export const getCountries = () => action(ActionTypes.GET_COUNTRIES);
export const getCountriesSucceed = payload =>
  action(ActionTypes.GET_COUNTRIES_SUCCEED, payload);
export const requestDownload = payload =>
  action(ActionTypes.REQUEST_DOWNLOAD, payload);
export const requestDownloadSingleFile = payload =>
  action(ActionTypes.REQUEST_DOWNLOAD_SINGLE_FILE, payload);
export const downloadFinished = () => action(ActionTypes.DOWNLOAD_FINISHED);
export const requestPreviewSingleFile = payload =>
  action(ActionTypes.REQUEST_PREVIEW_SINGLE_FILE, payload);
export const previewDownloadStarted = () => action(ActionTypes.PREVIEW_DOWNLOAD_STARTED);
export const previewDownloadFinished = payload => action(ActionTypes.PREVIEW_DOWNLOAD_FINISHED, payload);
export const handleToggleDocViewer = (payload: boolean) => action(ActionTypes.HANDLE_TOGGLE_DOC_VIEWER, payload);