import { Grid } from '@material-ui/core';
import ProfileAbout from 'containers/ProfileAbout';
import React from 'react';
import UserData from 'models/user';
import ProfileVehicles from 'containers/ProfileVehicles';
import ActivityView from './activityView';

interface Props {
  investor: UserData;
  scope: string;
}

const InvestorLayout = (props: Props) => {
  const { investor, scope } = props;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <ProfileAbout investor={investor} />
      </Grid>
      <Grid item xs={12} lg={8}>
        {investor && <ProfileVehicles investor={investor} />}
        <div style={{ paddingTop: '15px' }}>
          {investor && false && (
            <ActivityView scope={scope} investor={investor} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default InvestorLayout;
