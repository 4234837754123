/*
 *
 * UploadDocuments reducer
 *
 */
import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  uploading: 0,
  localFiles: [],
};

const uploadDocumentsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SET_DATA_ACTION:
        const exist = draft.localFiles.findIndex(item => {
          return (
            item.documentId === action.payload.documentId &&
            item.type === action.payload.type
          );
        });
        if (exist < 0) {
          draft.localFiles.push(action.payload);
        } else {
          draft.localFiles[exist] = action.payload;
        }

        break;
      case ActionTypes.UPLOAD_FILE_ACTION:
        draft.uploading = draft.uploading + 1;
        if (!draft.localFiles) {
          draft.localFiles = [];
        }
        draft.localFiles = draft.localFiles.map(item => {
          if (
            item.documentId === action.payload.document.documentId &&
            item.type === action.payload.document.type
          ) {
            const index = item.files.findIndex(f => {
              return (
                (f.fileId && f.fileId === action.payload.file.fileId) ||
                f.localId === action.payload.file.localId
              );
            });
            action.payload.file.success = false;
            if (index < 0) {
              item.files.push(action.payload.file);
            } else {
              item.files[index] = action.payload.file;
            }
          }
          return item;
        });
        break;
      case ActionTypes.CLEAR_DATA:
        draft.localFiles = draft.localFiles.filter(item => {
          return item.documentId !== action.payload.documentId;
        });
        break;
      case ActionTypes.UPLOAD_FILE_SUCCESS_ACTION:
        draft.uploading = draft.uploading - 1;
        draft.localFiles = draft.localFiles.map(item => {
          if (
            item.documentId === action.payload.documentId &&
            item.type === action.payload.type
          ) {
            const index = item.files.findIndex(f => {
              return (
                (f.fileId && f.fileId === action.payload.file.fileId) ||
                f.localId === action.payload.file.localId
              );
            });
            action.payload.file.success = true;
            if (index < 0) {
              item.files.push({ ...action.payload.file, success: true });
            } else {
              item.files[index] = { ...action.payload.file, success: true };
            }
          }
          return item;
        });
        break;
      case ActionTypes.UPLOAD_FILE_FAILED_ACTION:
        draft.uploading = draft.uploading - 1;
        if (draft.localFiles) {
          draft.localFiles.forEach(doc => {
            if (
              doc.documentId === action.payload.document.documentId &&
              doc.type === action.payload.document.type
            ) {
              doc.files = doc.files.filter(item => {
                return item.localId !== action.payload.localId;
              });
            }
          });
        }
        break;
      case ActionTypes.REMOVE_FILE_ACTION:
        if (draft.localFiles) {
          draft.localFiles = draft.localFiles.map(doc => {
            if (
              doc.documentId === action.payload.document.documentId &&
              doc.type === action.payload.document.type
            ) {
              doc.files = doc.files.filter(item => {
                return item.fileId !== action.payload.fileId;
              });
            }
            return doc;
          });
        }
        console.log(draft.localFiles);

        break;
    }
  },
  initialState,
);

export default uploadDocumentsReducer;
