import { setLoadingAction } from './../Register/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import * as actions from './actions';
import { AuthResponse } from '../../models/response/auth-response';
import { signIn } from '../../services/api/auth-service';
import ProfileResponse from '../../models/response/profile-response';
import { signUp } from '../../services/api/profile-service';
import ProfileRequest from '../../models/request/profile-request';
import AuthRequest from '../../models/request/auth-request';
import { OAUTH_TOKEN, set } from '../../services/local-storage-service';
import {
  FormState,
  initialFormState,
} from '../../components/FormControl/state';
import { InputState } from '../../components/FormControl/input';
import { showToast, setUserData } from '../App/actions';

const validate = email => {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email));
};

function* validateSignUp(action) {
  const states: FormState[] = action.payload;
  const firstNameState: InputState = {
    ...states['firstName'],
    ...initialFormState,
  };
  const lastNameState: InputState = {
    ...states['lastName'],
    ...initialFormState,
  };
  const emailState: InputState = { ...states['email'], ...initialFormState };
  const passwordState: InputState = {
    ...states['password'],
    ...initialFormState,
  };
  const languageCodeState: InputState = {
    ...states['languageCode'],
    ...initialFormState,
  };
  const request: ProfileRequest = {
    firstName: firstNameState.value ? firstNameState.value.trim() : '',
    lastName: lastNameState.value ? lastNameState.value.trim() : '',
    email: emailState.value ? emailState.value.trim() : '',
    password: passwordState.value ? passwordState.value : '',
    userType: 'INVESTOR',
    registerStep: 'IDENTITY',
    languageCode: languageCodeState.value ? languageCodeState.value : 'en',
  };

  if (!request.firstName) {
    firstNameState.error = true;
    firstNameState.helperMessage = 'firstNameRequired';
  } else if (request.firstName.length > 100) {
    firstNameState.error = true;
    firstNameState.helperMessage = 'firstNameTooLong';
  }
  if (!request.lastName) {
    lastNameState.error = true;
    lastNameState.helperMessage = 'lastNameRequired';
  } else if (request.lastName.length > 100) {
    lastNameState.error = true;
    lastNameState.helperMessage = 'lastNameTooLong';
  }
  if (!request.email) {
    emailState.error = true;
    emailState.helperMessage = 'emailRequired';
  } else if (!validate(request.email)) {
    emailState.error = true;
    emailState.helperMessage = 'emailWrongFormat';
  } else if (request.email.length > 100) {
    emailState.error = true;
    emailState.helperMessage = 'emailTooLong';
  }
  if (!request.password) {
    passwordState.error = true;
    passwordState.helperMessage = 'passwordRequired';
  } else {
    if (request.password.length < 8) {
      passwordState.error = true;
      passwordState.helperMessage = 'passwordTooShort';
    } else if (request.password.length > 20) {
      passwordState.error = true;
      passwordState.helperMessage = 'passwordTooLong';
    }
  }
  if (!request.languageCode) {
    languageCodeState.error = true;
    languageCodeState.helperMessage = 'languageCodeRequired';
  }

  if (
    !firstNameState.error &&
    !lastNameState.error &&
    !emailState.error &&
    !passwordState.error &&
    !languageCodeState.error
  ) {
    try {
      const signUpResponse: ProfileResponse = yield call(signUp, request);
      const loginRequest: AuthRequest = {
        grantType: 'password',
        username: request.email ? request.email : '',
        password: request.password ? request.password : '',
      };
      const loginResponse: AuthResponse = yield call(signIn, loginRequest);
      set(OAUTH_TOKEN, loginResponse.accessToken);
      yield put(setUserData(signUpResponse));
      yield put(actions.signUpSuccessAction());
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errorMessages: string[] = err.response.data
          ? err.response.data.messages
          : [];
        if (errorMessages && errorMessages.length > 0) {
          emailState.error = true;
          emailState.helperMessage = errorMessages[0];
        } else {
          yield put(
            showToast({
              message: 'unexpectedError',
              type: 'error',
            }),
          );
        }
      } else {
        yield put(
          showToast({
            message: 'unexpectedError',
            type: 'error',
          }),
        );
      }
    } finally {
      yield put(setLoadingAction(false));
    }
  }

  yield put(
    actions.updateFormStateSignUpAction([
      { field: 'firstName', state: firstNameState },
      { field: 'lastName', state: lastNameState },
      { field: 'email', state: emailState },
      { field: 'password', state: passwordState },
      { field: 'languageCode', state: languageCodeState },
    ]),
  );
  yield put(setLoadingAction(false));
}

export default function* registerSignUpSaga() {
  yield takeLatest(ActionTypes.SIGN_UP_ACTION, validateSignUp);
}
