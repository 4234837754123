/*
 *
 * InvestorDocuments actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const getDocuments = dealId => action(ActionTypes.GET_DOCUMENTS, dealId);

export const getNumberOfDocuments = dealId =>
  action(ActionTypes.GET_NUMBER_OF_DOCUMENTS, dealId);

export const getNumberOfDocumentsSucceed = data =>
  action(ActionTypes.GET_NUMBER_OF_DOCUMENTS_SUCCEED, data);

export const getDocumentsSucceed = data =>
  action(ActionTypes.GET_DOCUMENTS_SUCCEED, data);

export const refreshAllSignatures = id =>
  action(ActionTypes.REFRESH_ALL_SIGNATURES, id);

export const refreshSignature = id => action(ActionTypes.REFRESH_SIGNATURE, id);

export const refreshSignatureSucceed = data =>
  action(ActionTypes.REFRESH_SIGNATURE_SUCCEED, data);

export const refreshAllSignaturesSucceed = data =>
  action(ActionTypes.REFRESH_ALL_SIGNATURES_SUCCEED, data);

export const setLoading = loading => action(ActionTypes.SET_LOADING, loading);
export const sendPassword = payload =>
  action(ActionTypes.SEND_PASSWORD_ACTION, payload);
