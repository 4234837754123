import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the profileDeal state domain
 */

const selectProfileDealDomain = (state: ApplicationRootState) => {
  return state.profileDeal || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProfileDeal
 */

const makeSelectProfileDeal = () =>
  createSelector(selectProfileDealDomain, substate => {
    return substate;
  });

export default makeSelectProfileDeal;
export { selectProfileDealDomain };
