import { call, put, takeLatest } from 'redux-saga/effects';
import { me } from 'services/api/profile-service';
import { getVehicles } from 'services/api/vehicle-service';
import * as actions from './actions';
import ActionTypes from './constants';
import { set } from '../../services/local-storage-service';
import {
  setUserData,
  setUserVehicle,
  setPreferences,
} from 'containers/App/actions';
import {
  getPreferenceGroup,
  getUserPreference,
} from 'services/api/preference-service';
import { flatten, values } from 'lodash';

export function* requestFetchProfile() {
  try {
    const result = yield call(me);
    const userPreferences = yield call(getUserPreference);
    result.userPreferenceTags = flatten(values(userPreferences));
    yield put(setUserData(result));
  } catch (err) {
    yield put(actions.fetchUserFailed(err));
  }
}

export function* requestFetchVehicle(action) {
  const userId = action.payload;
  try {
    const result = yield call(getVehicles, userId);
    yield put(setUserVehicle(result));
  } catch (err) {
    yield put(actions.fetchUserFailed(err));
  }
}

export function* requestGetInterests() {
  try {
    const group = yield call(getPreferenceGroup);
    yield put(setPreferences(group));
  } catch (err) {
    // yield put(showToast({ message: err?.response?.data?.messages[0] ? err.response.data.messages[0] : 'unexpectedError', type: 'error' }));
  }
}

export default function* homeSage() {
  yield takeLatest(ActionTypes.FETCH_USER_ACTION, requestFetchProfile);
  yield takeLatest(ActionTypes.FETCH_USER_VEHICLE_ACTION, requestFetchVehicle);
  yield takeLatest(ActionTypes.FETCH_PREFERENCE_ACTION, requestGetInterests);
}
