enum ActionTypes {
  GET_DEAL_DOCUMENT = 'app/DealEditor/GET_DEAL_DOCUMENT',
  GET_DEAL_DOCUMENT_SUCCESS = 'app/DealEditor/GET_DEAL_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT = 'app/DealCreator/UPLOAD_DOCUMENT',
}

export enum DealType {
  EQUITY = 'EQUITY',
  SAFE = 'SAFE',
  LOAN = 'LOAN',
}

export default ActionTypes;
