import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function getAdminDocumentGroups(
  dealId: string,
  isPublic?: boolean,
  types?: string[],
): Promise<any[]> {
  const typeUrl = types && types.length ? `?types=${types.join(',')}` : '';
  const publicUrl =
    typeof isPublic !== 'undefined' ? `&isPublic=${isPublic}` : '';
  return instance
    .get(`/documents/groups/for-deals/${dealId}${typeUrl}${publicUrl}`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getInvestorDocuments(dealId: string): Promise<any[]> {
  return instance
    .get(`/documents/groups/for-deals/${dealId}/public`)
    .then((resp: AxiosResponse<RestResult<any[]>>) => {
      return resp.data.data;
    });
}

export async function getCompanyDocuments(
  companyId: string,
  isPublic: boolean,
  type: any[],
): Promise<any[]> {
  return instance
    .get(`/documents/groups/for-company/${companyId}`, {
      params: {
        types: type,
        isPublic: isPublic,
      },
    })
    .then((resp: AxiosResponse<RestResult<any[]>>) => {
      return resp.data.data;
    });
}

export async function createDocumentGroup(data: {
  dealId?: string;
  title: string;
  type: string;
  isPublic: boolean;
}): Promise<any[]> {
  return instance
    .post('/documents/groups', data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}
export async function deleteDocumentGroup(groupId): Promise<any[]> {
  return instance
    .delete(`/documents/groups/${groupId}`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getDocumentGroupStatistic(dealId): Promise<any[]> {
  return instance
    .get(`/documents/groups/statistic/for-deals/${dealId}`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getDocumentGroupStatisticForCompany(
  companyId,
): Promise<any[]> {
  return instance
    .get(`/documents/groups/statistic/for-companies/${companyId}`)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getAllUserDocument(
  types: string[],
  dealId?: string,
): Promise<any[]> {
  const url = `${'/users/my-document-groups?' + `types=${types.join(',')}`}${
    dealId ? `&dealId=${dealId}` : ''
  }`;
  return instance.get(url).then((resp: AxiosResponse<RestResult<any[]>>) => {
    return resp.data.data;
  });
}
