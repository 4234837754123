import React, { memo } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
} from '@material-ui/core';
import TextArea from 'components/TextArea';
import { topics } from '../constants';
import styles from '../styles';
import { makeStyles } from '@material-ui/core/styles';
import A from 'components/A';
import { useIntl } from 'react-intl';
const style = styles.mailer;
const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    margin: '20px 0 0 20px',
  },
  subTitle: {
    margin: '10px 0 0 20px',
  },
  linkBtn: {
    textDecoration: 'none',
    color: '#588fdb',
  },
}));
interface Props {
  onChangeTopic: (value: string) => void;
  onChangeMessage: (value: string) => void;
  onSubmit: () => void;
  message: string;
  topic: string;
}

const Mailer = (props: Props) => {
  const intl = useIntl();
  const { onChangeTopic, onChangeMessage, onSubmit, message, topic } = props;

  const onSelect = e => {
    onChangeTopic(e.target.value);
  };
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}>
        {`${intl.messages['haveQuestionOrComment']}`}
      </Typography>
      <Typography className={classes.subTitle}>
        {`${intl.messages['pleaseUseTheFormBelowOrContactUsAt']}`}{' '}
        <A href="mailto:info@jadesquare.com">info@jadesquare.com</A>
      </Typography>
      <FormControl fullWidth variant={'outlined'} style={style.form}>
        <FormLabel style={style.label}>
          {`${intl.messages['topic']}`.toUpperCase()}{' '}
          <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <Select margin={'none'} value={topic} onChange={onSelect}>
          {topics.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {intl.messages[`${option.label}`]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant={'outlined'} style={style.form}>
        <FormLabel style={style.label}>
          {`${intl.messages['message']}`.toUpperCase()}{' '}
          <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <TextArea
          rowsMin={5}
          value={message || ''}
          autoComplete={'off'}
          id="description"
          maxLength={5000}
          onChange={e => onChangeMessage(e.target.value)}
          style={{ padding: '5px 12px' }}
        />
      </FormControl>
      <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size={'small'}
          variant="contained"
          color="primary"
          style={{ margin: '15px 20px 0 0' }}
          onClick={onSubmit}
          disabled={!message}
        >
          {`${intl.messages['submit']}`.toUpperCase()}
        </Button>
      </Grid>
    </>
  );
};

export default memo(Mailer);
