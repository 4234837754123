import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  updateDealStatus as updateDealStatusService,
  notifyToInvestors,
} from 'services/api/deal-service';
import { requestSigning } from 'services/api/sign-document';
import { showToast } from 'containers/App/actions';
import makeSelectDealDetail from './selectors';
import ActionTypes from './constants';
import { setIsLoading } from 'containers/Home/actions';
import {
  updateDealStatusSucceed,
  requestSignSucceed,
  requestSignFailed,
  getDocumentsSucceed,
} from './actions';
import { getAllUserDocument, getInvestorDocuments } from 'services/api/documentGroups-service';

function* updateDealStatus(action) {
  const { deal } = yield select(makeSelectDealDetail());
  put(setIsLoading(true));
  try {
    yield call(updateDealStatusService, deal.dealId, {
      status: action.payload.status,
    });
    if (action.payload.sendMail) {
      yield call(notifyToInvestors, {
        dealId: deal.dealId,
        dealUpdate: 'STATUS',
        oldDealStatus: action.payload.oldStatus,
      });
    }
    yield put(updateDealStatusSucceed(action.payload.status));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    put(setIsLoading(false));
  }
}

function* requestSign(action) {
  put(setIsLoading(true));
  try {
    const response = yield call(requestSigning, action.payload);
    yield put(requestSignSucceed(response));
  } catch (err) {
    yield put(requestSignFailed());
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    put(setIsLoading(false));
  }
}

function* getDocuments(action) {
  yield put(setIsLoading(true));
  try {
    const documentGroups = yield call(getInvestorDocuments, action.payload);
    let documents = [];

    documentGroups.forEach(group => {
      if (group.type === 'DOCUMENT' && group.documents) {
        // push to documents but first group by subType
        const isContain = documents.some(
          document => document.title === group.title,
        );
        if (!isContain) {
          documents.push({
            title: group.title,
            subType: group.subType,
            documents: group.documents.filter(doc => doc.files),
            isDefault: group.isDefault && group.subType === 'DEAL',
            index: documents.length,
          });
        } else {
          documents = documents.map(document => {
            if (document.title === group.title) {
              return {
                ...document,
                documents: [...document.documents, ...group.documents],
              };
            }
            return document;
          });
        }
      }
    });

    //sort documents and the first should be the one that has isDefault = true
    documents.sort((a, b) => {
      if (a.isDefault) {
        return -1;
      }
      if (b.isDefault) {
        return 1;
      }
      return a.index - b.index;
    });

    yield put(getDocumentsSucceed(documents));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

export default function* dealDetailSaga() {
  yield takeLatest(ActionTypes.UPDATE_DEAL_STATUS, updateDealStatus);
  yield takeLatest(ActionTypes.REQUEST_SIGN, requestSign);
  yield takeLatest(ActionTypes.GET_DEAL_DOCUMENTS, getDocuments);
}
