import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the inviteInvestor state domain
 */

const selectInviteInvestorDomain = (state: ApplicationRootState) => {
  return state.inviteInvestor || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InviteInvestor
 */

const makeSelectInviteInvestor = () =>
  createSelector(selectInviteInvestorDomain, substate => {
    return substate;
  });

export default makeSelectInviteInvestor;
export { selectInviteInvestorDomain };
