/*
 *
 * TicketCreate constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/TicketEdit/DEFAULT_ACTION',
  SUBMIT_ACTION = 'app/TicketEdit/SUBMIT_ACTION',
  SUBMIT_SUCCESS_ACTION = 'app/TicketEdit/SUBMIT_SUCCESS_ACTION',
  SUBMIT_FAILED_ACTION = 'app/TicketEdit/SUBMIT_FAILED_ACTION',
  CLEAR_ACTION = 'app/TicketEdit/CLEAR_ACTION',
  VALIDATE_TICKET = 'app/TicketEdit/VALIDATE_TICKET',
  FETCH_USER_VEHICLE_ACTION = 'app/TicketEdit/FETCH_USER_VEHICLE_ACTION',
  FETCH_USER_VEHICLE_SUCCESS_ACTION = 'app/TicketEdit/FETCH_USER_VEHICLE_SUCCESS_ACTION',
  FETCH_USER_VEHICLE_FAILED_ACTION = 'app/TicketEdit/FETCH_USER_VEHICLE_FAILED_ACTION',
}

export default ActionTypes;
