/*
 *
 * ProfileVehicles reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  default: null,
};

const profileVehiclesReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_USER_VEHICLE_SUCCESS:
        draft.needRefresh = false;
        draft.vehicles = action.payload.filter(item => {
          return item.vehicleType === 'CORPORATE' && item.status === 'ACTIVE';
        });
        const temp = action.payload.filter(item => {
          return item.vehicleType === 'INDIVIDUAL';
        });
        draft.individualVehicle = temp.length > 0 ? temp[0] : undefined;
        break;
      case ActionTypes.UPDATE_USER_VEHICLE_SUCCESS:
        if (action.payload.vehicleType === 'INDIVIDUAL') {
          draft.individualVehicle = action.payload;
        }
        break;
      case ActionTypes.CREATE_USER_VEHICLE_SUCCESS:
        draft.needRefresh = true;
        if (action.payload.vehicleType === 'INDIVIDUAL') {
          draft.individualVehicle = action.payload;
        }
        break;
    }
  },
  initialState,
);

export default profileVehiclesReducer;
