/*
 *
 * LanguageProvider reducer
 *
 */
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import { DEFAULT_LOCALE } from '../../i18n';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  locale: DEFAULT_LOCALE,
};

const languageProviderReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.CHANGE_LOCALE:
        draft.locale = action.payload;
        break;
      default:
        break;
    }
  },
  initialState,
);
export default languageProviderReducer;
