import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the dealEditor state domain
 */

const selectDealEditorDomain = (state: ApplicationRootState) => {
  return state.dealEditor || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by DealEditor
 */

const makeSelectDealEditor = () =>
  createSelector(selectDealEditorDomain, substate => {
    return substate;
  });

export default makeSelectDealEditor;
export { selectDealEditorDomain };
