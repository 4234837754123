/**
 *
 * AlertDialog
 *
 */

import React, { memo, useRef, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  Grid,
  Typography,
  Input,
  IconButton,
  Dialog,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import makeSelectTemplateCreateDialog from './selectors';
import Loader from 'components/Loading';
import TextArea from 'components/TextArea';
import TemplateKey from './components/templateKey';
import { SigniningDynamicField } from 'models/signing-dynamic-field';
import Select from 'components/Select';
import { submitAction, clearAction } from './actions';
import { SigningTemplate } from 'models/signing-template';
import { TEMPLATE_CATEGORY } from 'utils/constants';
const stateSelector = createStructuredSelector({
  state: makeSelectTemplateCreateDialog(),
});
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#000',
  },
  input: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  container: {
    marginTop: 12,
  },
  title: {
    paddingTop: 4,
    fontSize: '1.2rem',
  },
  titleColor: {},
  image: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  deleteBtn: {
    margin: 8,
  },
  requireText: {
    color: '#D74F4F',
  },
  errorInput: {
    borderColor: '#D74F4F',
  },
  errorText: {
    color: '#D74F4F',
  },
  addKeyBtn: {
    marginTop: 8,
  },
  fileName: {
    color: 'var(--color-purple)',
  },
}));

interface Props {
  open: boolean;
  title?: string;
  onCancel: () => void;
  item?: SigningTemplate;
}

function TemplateCreateDialog(props: Props) {
  const { open, onCancel } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  useInjectReducer({ key: 'templateCreateDialog', reducer: reducer });
  useInjectSaga({ key: 'templateCreateDialog', saga: saga });
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [fileName, setFileName] = React.useState<string | undefined>(undefined);
  const [category, setCategory] = React.useState('NDA');
  const [signKey, setSignKey] = React.useState<SigniningDynamicField[]>([]);
  const { state } = useSelector(stateSelector);
  const { error, loading } = state;
  let hiddenFileInput;

  const [item, setItem] = React.useState<SigningTemplate | undefined>(
    props.item,
  );

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const onConfirm = event => {
    const request = {
      ...item,
      createESignDynamicDataRequests: signKey,
      description: event.currentTarget.form['description'].value.trim(),
      fileNamePrefix: event.currentTarget.form['namePrefix'].value.trim(),
      name: event.currentTarget.form['name'].value.trim(),
      category: category,
    };
    dispatch(submitAction({ request: request, file: file }));
  };

  useEffect(() => {
    if (item) {
      let dynamicDatas = [...item.esignDynamicData] || [];
      dynamicDatas = dynamicDatas.map((data, index) => ({
        ...data,
        localId: index,
      }));
      setSignKey(dynamicDatas);
      handleDeleteFileSelect();
    } else {
      setSignKey([]);
      handleDeleteFileSelect();
    }

    console.log(signKey);
  }, [item]);

  const onClose = () => {
    onCancel();
    setFile(undefined);
    setFileName(undefined);
    setSignKey([]);
    setItem(undefined);
  };

  const handleFileSelect = ev => {
    const file = ev.target.files[0];
    setFile(file);
    setFileName(file.name);
    hiddenFileInput.value = '';
  };

  const formRef = useRef<any>();

  const intl = useIntl();

  const handleDelete = item => {
    setSignKey(pre => {
      return pre.filter(val => val.localId !== item.localId);
    });
  };

  const onTemplateKeyChanged = (localId, change) => {
    setSignKey(pre => {
      return pre.map(val =>
        val.localId !== localId ? val : { ...val, ...change },
      );
    });
  };

  const onKeyAdded = () => {
    setSignKey(pre => [
      ...pre,
      { localId: new Date().getTime(), type: 'DYNAMIC', fontSize: 10 },
    ]);
  };

  const templateType = TEMPLATE_CATEGORY.map(item => {
    return { value: item.label, key: item.value };
  });

  const handleOnSelect = value => {
    setCategory(value.target.value);
  };

  const handleDeleteFileSelect = () => {
    setFile(undefined);
    setFileName(undefined);
  };

  useEffect(() => {
    dispatch(clearAction({}));
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <InsertDriveFileOutlinedIcon />
            </Grid>
            <Grid item xs>
              <FormattedMessage
                id={`${item ? 'updateTemplate' : 'createTemplate'}`}
              />
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon className={classes.titleColor} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onConfirm} ref={formRef}>
            <div>
              <div className={classes.container}>
                <Typography>
                  <FormattedMessage id="templateName" />{' '}
                  <span className={classes.requireText}>*</span>
                </Typography>
              </div>
              <div>
                <Input
                  id="name"
                  defaultValue={item ? item.name : ''}
                  autoComplete={'off'}
                  className={`${classes.input} ${
                    error && error.name ? classes.errorInput : ''
                  }`}
                  disableUnderline
                  inputProps={{ maxLength: 200 }}
                />
                {error && error.name && (
                  <Typography className={classes.errorText}>
                    {intl.messages[`error.templateNameRequired`]}
                  </Typography>
                )}
              </div>
              <div className={classes.container}>
                <Typography>
                  <FormattedMessage id="templateCategory" />{' '}
                  <span className={classes.requireText}>*</span>
                </Typography>
              </div>
              <div>
                <Select
                  defaultValue={item ? item.category : 'NDA'}
                  items={templateType}
                  className={classes.input}
                  inputProps={{
                    id: 'category',
                    disableunderline: 'true',
                  }}
                  onChange={handleOnSelect}
                />
              </div>
              <div className={classes.container}>
                <Typography>
                  <FormattedMessage id="templateDescription" />{' '}
                  <span className={classes.requireText}>*</span>
                </Typography>
              </div>
              <div>
                <TextArea
                  rowsMin={4}
                  id="description"
                  defaultValue={item ? item.description : ''}
                  className={`${classes.textarea} ${
                    error && error.description ? classes.errorInput : ''
                  }`}
                  maxLength={5000}
                  autoComplete={'off'}
                />
                {error && error.description && (
                  <Typography className={classes.errorText}>
                    {intl.messages[`error.templateDescriptionRequired`]}
                  </Typography>
                )}
              </div>
              <div className={classes.container}>
                <Typography>
                  <FormattedMessage id="templateNamePrefix" />{' '}
                  <span className={classes.requireText}>*</span>
                </Typography>
              </div>
              <div>
                <Input
                  id="namePrefix"
                  autoComplete={'off'}
                  defaultValue={item ? item.fileNamePrefix : ''}
                  className={`${classes.input} ${
                    error && error.prefix ? classes.errorInput : ''
                  }`}
                  disableUnderline
                  inputProps={{ maxLength: 200 }}
                />
                {error && error.prefix && (
                  <Typography className={classes.errorText}>
                    {intl.messages[`error.exportDocumentNameRequired`]}
                  </Typography>
                )}
              </div>
              <div className={classes.container}>
                <Typography>
                  <FormattedMessage id="pdfFile" />{' '}
                  <span className={classes.requireText}>*</span>
                </Typography>
                {error && error.file && !fileName && (
                  <Typography className={classes.errorText}>
                    {intl.messages[`error.fileIsRequired`]}
                  </Typography>
                )}
                {fileName && (
                  <Typography className={classes.fileName}>
                    {fileName}
                    <Link
                      style={{ color: '#D74F4F', padding: '0 0 0 10px' }}
                      underline={'none'}
                      onClick={handleDeleteFileSelect}
                    >
                      {intl.messages['delete']}
                    </Link>
                  </Typography>
                )}
                <input
                  ref={ref => (hiddenFileInput = ref)}
                  type="file"
                  name="file"
                  multiple
                  onChange={handleFileSelect}
                  style={{ display: 'none' }}
                  accept="application/pdf"
                />

                <Button
                  size={'small'}
                  style={{ marginTop: '0px' }}
                  variant="contained"
                  onClick={() => hiddenFileInput.click()}
                  color="primary"
                >
                  {`${intl.messages['upload']}`.toUpperCase()}
                </Button>
              </div>
              <div>
                {signKey.map(item => (
                  <TemplateKey
                    key={item.localId}
                    onDelete={handleDelete}
                    onChanged={onTemplateKeyChanged}
                    item={item}
                  />
                ))}
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onKeyAdded}
                  className={classes.addKeyBtn}
                >
                  <FormattedMessage id="addMoreKeyPoint" />
                </Button>
              </div>
              <div style={{ paddingTop: 10 }}>
                {error && error.signKey && (
                  <Typography className={classes.errorText}>
                    {intl.formatMessage(
                      {
                        id: `${error.signKey}`,
                      },
                      { key: `${error.signKeyValue}` },
                    )}
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.container}>
              <Button variant="contained" color="primary" onClick={onConfirm}>
                {item ? (
                  <FormattedMessage id="update" />
                ) : (
                  <FormattedMessage id="create" />
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {loading && <Loader fullScreen />}
    </>
  );
}

export default memo(TemplateCreateDialog);
