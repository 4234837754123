/*
 *
 * InviteInvestor actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';
import DealInvestorRequest from 'models/request/deal-investor-request';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const fetchInvestorsAction = payload =>
  action(ActionTypes.FETCH_INVESTORS_ACTION, payload);
export const fetchInvestorsSuccessAction = payload =>
  action(ActionTypes.FETCH_INVESTORS_SUCCESS_ACTION, payload);
export const fetchInvestorsFailedAction = () =>
  action(ActionTypes.FETCH_INVESTORS_FAILED_ACTION);
export const submitDealInvestor = (payload: DealInvestorRequest) =>
  action(ActionTypes.SUBMIT_DEAL_INVESTOR_ACTION, payload);
export const submitDealSuccessInvestor = payload =>
  action(ActionTypes.SUBMIT_DEAL_INVESTOR_SUCCESS_ACTION, payload);
export const submitDealFailedInvestor = () =>
  action(ActionTypes.SUBMIT_DEAL_INVESTOR_FAILED_ACTION);
