import {
  Button,
  FormControl,
  Link,
  PropTypes,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';
import CustomFormLabel from '../../../components/FormControl/components/form-label';
import CustomHelperText from '../../../components/FormControl/components/helper-text';
import {
  FormState,
  initialFormState,
} from '../../../components/FormControl/state';
import { useIntl } from 'react-intl';

export interface DocumentUploadState extends FormState {
  localFiles: any[];
  remoteFiles: FileResponse[];
}

export const initialDocumentUploadState: DocumentUploadState = {
  localFiles: [],
  remoteFiles: [],
  ...initialFormState,
};

interface Props {
  formState: DocumentUploadState;
  onChange: (ev) => void;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth: boolean;
}

FormDocumentUpload.defaultProps = {
  fullWidth: true,
};

const useStyles = makeStyles(theme => ({}));

function FormDocumentUpload(props: Props) {
  const intl = useIntl();
  const styles = useStyles(props);
  const { formState, onChange, title, subtitle, margin, fullWidth } = props;
  let hiddenFileInput;

  const [selectedFiles, setSelectedFiles] = React.useState<DocumentUploadState>(
    {
      ...initialDocumentUploadState,
      remoteFiles: formState.remoteFiles ? formState.remoteFiles : [],
      localFiles: [],
    },
  );

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      const b = selectedFiles.localFiles;
      for (const file of e.target.files) {
        b.push(file);
        if (b.length + selectedFiles.remoteFiles.length >= 5) {
          break;
        }
      }
      setSelectedFiles({ ...selectedFiles, localFiles: b });
      onChange(selectedFiles);
    }
  };

  const handleDeleteFileSelect = (fileId, type) => ev => {
    if (type === 'local') {
      const b = selectedFiles.localFiles;
      b.splice(fileId, 1);
      setSelectedFiles({ ...selectedFiles, localFiles: b });
      onChange(selectedFiles);
    } else if (type === 'remote') {
      const b = selectedFiles.remoteFiles;
      const deleteIndex = selectedFiles.remoteFiles.findIndex(
        rf => rf.fileId === fileId,
      );
      if (deleteIndex >= 0) {
        b.splice(deleteIndex, 1);
      }
      setSelectedFiles({ ...selectedFiles, remoteFiles: b });
      onChange(selectedFiles);
    }
  };

  const FileRow = props => {
    return (
      <Typography>
        <Link style={{ color: '#588FDB' }} underline={'always'}>
          {props.name}
        </Link>
        <Link
          style={{ color: '#D74F4F', padding: '0 0 0 10px' }}
          underline={'none'}
          onClick={handleDeleteFileSelect(props.fileId, props.type)}
        >
          {`${intl.messages['delete']}`}
        </Link>
      </Typography>
    );
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <CustomFormLabel title={title} subtitle={subtitle} />

      <div>
        {selectedFiles.remoteFiles.map((file, index) => (
          <FileRow
            key={index}
            type={'remote'}
            fileId={file.fileId}
            name={file.name}
          />
        ))}
        {selectedFiles.localFiles.map((file, index) => (
          <FileRow key={index} type={'local'} fileId={index} name={file.name} />
        ))}
        {selectedFiles.remoteFiles.length + selectedFiles.localFiles.length <
          5 && (
          <input
            ref={ref => (hiddenFileInput = ref)}
            type="file"
            name="file"
            multiple
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
        )}

        {selectedFiles.remoteFiles.length + selectedFiles.localFiles.length <
          5 && (
          <Button
            size={'small'}
            style={{ marginTop: '0px' }}
            variant="contained"
            onClick={() => hiddenFileInput.click()}
            color="primary"
          >
            {`${intl.messages['upload']}`.toUpperCase()}
          </Button>
        )}
      </div>

      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(FormDocumentUpload);
