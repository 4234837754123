export default {
  center: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  dealName: {
    fontSize: 16,
    fontWeight: 600,
    padding: '5px 6px 5px 9px',
  },
  button: { backgroundColor: '#588FDB', color: 'white' },
  buttonDisabled: { backgroundColor: '#B5CEFA', color: 'white' },
  companyLogo: { width: 20, height: 20, borderRadius: '50%' },
  dealInfo: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  adminButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  investorButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 64px)',
  },
};
