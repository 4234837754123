import React, { memo } from 'react';

import styled from 'styles/styled-components';

const LabelWrapper = styled.div`
  line-height: 12px;
  margin-left: 5px;
  font-size: 0.725rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-dark-purple);
  color: white;
  padding: 3px 5px;
  font-weight: 600;
  border-radius: 3px;
  @media (max-width: 600px) {
    padding: 2px 3px;
    margin-left: 3px;
    font-size: 0.65rem;
  }
`;

interface Props {
  label: number;
  style?: any;
}

function NumberLabel(props: Props) {
  const { label, style } = props;
  return <LabelWrapper style={style}>{label}</LabelWrapper>;
}

export default memo(NumberLabel);
