import React, { memo } from 'react';
import { Link } from '@material-ui/core';

interface Props {
  onClick: (e: any) => void;
  children?: any;
  style?: any;
}

const EditButton = (props: Props) => {
  const { onClick, children, style } = props;
  return (
    <Link
      style={{ color: '#588FDB', ...style, fontSize: '0.9rem' }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default memo(EditButton);
