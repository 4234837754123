import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import ESGImage from '../../../../images/esg-image.png';
import TurbineImage from '../../../../images/windturbine-image.png';
import HealthImage from '../../../../images/health.png';
import GraphImage from '../../../../images/graph-image.png';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  images: {
    width: '100%',
    height: 'auto',
  },
  smallTitle: {
    marginBottom: '0px',
    fontSize: '1rem',
  },
  spacedContainer: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  },
}));

const InvestmentOptions = () => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div>
      <Grid className={classes.spacedContainer} container spacing={4}>
        <Grid item sm={12} md={6}>
          <img className={classes.images} src={ESGImage} />
        </Grid>
        <Grid item sm={12} md={6}>
          <div>
            <h5 className={classes.smallTitle}>
              {`${intl.messages['InvestmentOptions.ESG']}`}
            </h5>
            <p>{`${intl.messages['InvestmentOptions.ESGDesc1']}`}</p>
            <p>{`${intl.messages['InvestmentOptions.ESGDesc2']}`}</p>
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.spacedContainer} container spacing={4}>
        <Grid item sm={12} md={6}>
          <div>
            <h5
              className={classes.smallTitle}
            >{`${intl.messages['InvestmentOptions.Renewable']}`}</h5>
            <p>{`${intl.messages['InvestmentOptions.RenewableDesc1']}`}</p>
            <p>{`${intl.messages['InvestmentOptions.RenewableDesc2']}`}</p>
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <img className={classes.images} src={TurbineImage} />
        </Grid>
      </Grid>
      <Grid className={classes.spacedContainer} container spacing={4}>
        <Grid item sm={12} md={6}>
          <img className={classes.images} src={HealthImage} />
        </Grid>
        <Grid item sm={12} md={6}>
          <div>
            <h5 className={classes.smallTitle}>
              {`${intl.messages['InvestmentOptions.healtTech']}`}
            </h5>
            <p>{`${intl.messages['InvestmentOptions.healtTechDesc1']}`}</p>
            <p>{`${intl.messages['InvestmentOptions.healtTechDesc2']}`}</p>
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.spacedContainer} container spacing={4}>
        <Grid item sm={12} md={6}>
          <div>
            <h5
              className={classes.smallTitle}
            >{`${intl.messages['InvestmentOptions.finTech']}`}</h5>
            <p>{`${intl.messages['InvestmentOptions.finTechDesc1']}`}</p>
            <p>{`${intl.messages['InvestmentOptions.finTechDesc2']}`}</p>
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <img className={classes.images} src={GraphImage} />
        </Grid>
      </Grid>
    </div>
  );
};

export default InvestmentOptions;
