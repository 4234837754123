/*
 *
 * Invites reducer
 *
 */

import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  investors: undefined,
  total: 0,
  resend: false,
};

const invitesReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.GET_INVITES_SUCCEED:
        draft.investors = action.payload.data;
        draft.total = action.payload.total;
        break;
      case ActionTypes.GET_INVITES_FAILED:
        draft.investors = [];
        break;
      case ActionTypes.RESEND_INVITES:
        draft.resend = false;
        break;
      case ActionTypes.RESEND_INVITES_SUCCEED:
        draft.resend = true;
        break;
      case ActionTypes.REJECT_INVITE:
        draft.resend = false;
        break;
      case ActionTypes.REJECT_INVITE_SUCCEED:
        draft.resend = true;
        break;
    }
  },
  initialState,
);

export default invitesReducer;
