import Company from '../../../models/company';
import { gql } from '@apollo/client';
import { UpdateCompanyData, UpdateCompanyTranslationData } from './model';

export function toCompany(item) {
  const nameMap = new Map<string, string>();
  const descriptionMap = new Map<string, string>();
  item.translations.forEach(translation => {
    nameMap.set(translation.languageCode, translation.name);
    descriptionMap.set(translation.languageCode, translation.description);
  });

  return {
    companyId: item.companyId,
    name: nameMap,
    description: descriptionMap,
    website: item.website,
    pathPicture: item.pathPicture,
  } as Company;
}

export function toCompanyList(items) {
  const result = new Array<Company>();
  items?.forEach(item => {
    let totalRaised = 0;
    let uniqueInvestor = 0;
    item.deals?.forEach(deal => {
      totalRaised += deal.ticketsAggregate.aggregate.sum.amount;
      uniqueInvestor += deal.investorsAggregate.distinct.count;
    });

    const nameMap = new Map<string, string>();
    nameMap.set(item.languageCode, item.name);

    result.push({
      companyId: item.data.companyId,
      name: nameMap,
      pathPicture: item.data.pathPicture,
      noOfDeal: item.data.dealsAggregate.count.value,
      totalRaised: totalRaised,
      uniqueInvestor: uniqueInvestor,
    } as Company);
  });
  return result;
}

export function createUpdateCompanyMutation(
  companyData: UpdateCompanyData,
  translationsToUpdate: UpdateCompanyTranslationData[],
  translationsToInsert: UpdateCompanyTranslationData[],
  translationsToDelete: UpdateCompanyTranslationData[],
) {
  return gql`
    mutation UpdateCompany {
      update_company_by_pk(
        pk_columns: { id: "${companyData.id}" }
        _set: {
          website: "${companyData.website}",
          modifiedBy: "${companyData.modifiedBy}",
          pathPicture: "${companyData.pathPicture}"
        }
      ) {
        id
      }
      ${translationsToUpdate
        .map((item, index) => {
          return `
            updateCompanyTranslation_${index}: update_company_translation(
              where: {
                _and: {
                  companyId: {_eq: "${companyData.id}"},
                  languageCode: {_eq: "${item.languageCode}"}
                }
              },
              _set: {
                name: "${item.name}",
                description: "${item.description}"
              }
            ) {
              returning {
                id
              }
            }`;
        })
        .join('\n')}
      ${translationsToInsert
        .map((item, index) => {
          return `
            insertCompanyTranslation_${index}: insert_company_translation_one(
              object: {
                name: "${item.name}",
                description: "${item.description}",
                languageCode: "${item.languageCode}",
                companyId: "${companyData.id}"
              }
            ) {
              id
            }
          `;
        })
        .join('\n')}
      ${translationsToDelete
        .map((item, index) => {
          return `
            deleteCompanyTranslation_${index}: delete_company_translation(
              where: {
                _and: {
                  companyId: {_eq: "${companyData.id}"},
                  languageCode: {_eq: "${item.languageCode}"}
                }
              }
            ) {
              returning {
                id
              }
            }
          `;
        })
        .join('\n')}
    }
  `;
}
