/*
 *
 * InvestorDashboard
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  Grid,
  Typography,
  makeStyles,
  Link,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { push } from 'connected-react-router';

import { DashboardSummary as Summary } from 'models/request/investor-statistic';
import { currencyFormat } from 'utils/stringFormat';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
  },
  interestTag: {
    padding: 4,
    margin: 4,
    backgroundColor: '#CFE1FF',
    color: '#588FDB',
    fontWeight: 'bold',
    borderRadius: 2,
    display: 'inline-block',
    fontSize: 12,
  },
  nameText: {
    display: 'inline',
    fontSize: 16,
    color: '#212121',
    fontWeight: 'bold',
  },
  subTitleText: {
    color: '#888888',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  picture: {
    width: 35,
    height: 35,
    margin: 8,
  },
  title: {
    fontSize: '1.3rem',
    fontWeight: 600,
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.95rem',
    },
  },
  subTitle: {
    fontSize: '0.85rem',
    textAlign: 'center',
    color: 'var(--color-grey)',
    padding: 0,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  headerText: {
    fontSize: '1.07rem',
    margin: 0,
    fontWeight: 600,
    color: 'var(--color-light-grey)',
  },
  linkText: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem',
    minWidth: 50,
    textAlign: 'right',
    color: '#CCCCCC',
    backgroundColor: 'var(--color-dark-purple)',
    padding: '0 5px',
    minHeight: 25,
    borderRadius: '3px',
    textDecoration: 'none',
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'none !important',
      color: 'var(--color-light-grey)',
    },
  },
  smallCard: {
    height: '100%',
    paddingTop: '1.3rem',
    paddingBottom: '1.3rem',
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  title: string;
  linkText: string;
  linkUrl: string;
  summary?: Summary;
}

function DashboardSummary(props: Props) {
  const { title, linkText, linkUrl, summary } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    dispatch(push(linkUrl));
  };
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid
          container
          alignItems="center"
          style={{
            paddingBottom: '10px',
          }}
        >
          <Grid>
            <Typography className={classes.headerText}>{title}</Typography>
          </Grid>
          <Grid item xs style={{ textAlign: 'right' }}>
            <Link className={classes.linkText} onClick={handleClick}>
              {linkText}
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={isSmall ? 1 : 4}
          alignItems="stretch"
          style={{ height: '75%' }}
        >
          <Grid item xs={true}>
            <Card variant="outlined" className={classes.smallCard}>
              <Typography className={classes.title}>
                {summary && summary.tickets ? summary.tickets : 0}
              </Typography>
              <Typography className={classes.subTitle}>
                <FormattedMessage id={`tickets`} />
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={true}>
            <Card variant="outlined" className={classes.smallCard}>
              <Typography className={classes.title}>
                {summary && summary.companies ? summary.companies : 0}
              </Typography>
              <Typography className={classes.subTitle}>
                <FormattedMessage id={`companies`} />
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={true}>
            <Card variant="outlined" className={classes.smallCard}>
              <Typography className={classes.title}>
                {summary && summary.deals ? summary.deals : 0}
              </Typography>
              <Typography className={classes.subTitle}>
                <FormattedMessage id={`deals`} />
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={true}>
            <Card variant="outlined" className={classes.smallCard}>
              <Typography className={classes.title}>
                {summary && summary.raisedAmount
                  ? currencyFormat(summary.raisedAmount)
                  : '$0'}
              </Typography>
              <Typography className={classes.subTitle}>
                <FormattedMessage id={`totalInvested`} />
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(DashboardSummary);
