/*
 *
 * ProfileEditDialog actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const submitAction = payload =>
  action(ActionTypes.SUBMIT_ACTION, payload);
export const updateProfileSuccessAction = payload =>
  action(ActionTypes.UPDATE_SUCCESS_ACTION, payload);
export const updateProfileFailedAction = payload =>
  action(ActionTypes.UPDATE_FAILED_ACTION, payload);
