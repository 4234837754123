export default {
  interests: {
    padding: 4,
    margin: 4,
    fontWeight: 600,
    borderRadius: 2,
    display: 'inline-block',
  },
  investStatus: {
    fontWeight: 600,
  },
  image: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    marginRight: '10px',
  },
};
