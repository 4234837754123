import React, { memo, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  Input,
} from '@material-ui/core';
import { allCountries } from 'utils/constants';
import UploadDocuments from 'containers/UploadDocuments';
import makeSelectRegister from 'containers/Register/selectors';
import FormSelect, { SelectOption } from 'components/FormControl/select';
// import Select from 'components/Select';
import Vehicle from 'models/vehicle';
import CustomFormLabel from 'components/FormControl/components/form-label';
import AntSwitch from 'containers/RegisterVehicles/components/switch';
import Select from 'components/Select';
import Document from 'models/document';
import DocumentGroups from 'containers/AdminDocuments/components/DocumentGroups';
import { FormattedMessage, useIntl } from 'react-intl';
const useStyles = makeStyles(theme => ({
  container: {},
  hiddenColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  input: {
    width: '100%',
  },
}));

const countryOptions = allCountries.map(item => {
  return { key: item.value, value: item.label };
});

interface Props {
  vehicle?: Vehicle;
  onChange: (type, value) => void;
}

function CorporateVehicle(props: Props) {
  const intl = useIntl();
  const classes = useStyles(props);
  const [vehicleFiles, setVehicleFiles] = React.useState<string[]>([]);
  const [vehicleDoc, setVehicleDoc] = React.useState<Document>();

  const { vehicle, onChange } = props;
  const updateState = (type, value) => {
    onChange(type, value);
  };

  useEffect(() => {
    let tempDoc;
    if (
      vehicle &&
      vehicle.documentGroups &&
      vehicle.documentGroups.length > 0
    ) {
      const tempDocGroups = vehicle.documentGroups.find(
        item => item.subType === 'VEHICLE',
      );
      if (tempDocGroups && tempDocGroups.documents) {
        tempDoc = tempDocGroups.documents.find(
          item => item.type === 'KYC_CORPORATE',
        );
        setVehicleDoc(tempDoc);
      }
    }
    if (!tempDoc) {
      const doc = {
        title: `${intl.messages['default']}`,
        type: 'KYC_CORPORATE',
        files: [],
        fileIds: [],
      };
      setVehicleDoc(doc);
      const documentGroups = [
        { title: `${intl.messages['default']}`, documents: [doc] },
      ];
      onChange('documentGroups', documentGroups);
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicleDoc) {
      const newVehicleDoc = { ...vehicleDoc, fileIds: vehicleFiles };
      onChange('document', newVehicleDoc);
    }
  }, [vehicleFiles]);

  return (
    <div className={classes.container}>
      <div style={{ padding: ' 0 10px 0 10px' }}>
        <Typography variant={'subtitle1'} style={{ color: '#BDBFC3' }}>
          <FormattedMessage id={`companyIdentity`} />
        </Typography>
        <Divider style={{ marginBottom: '10px', backgroundColor: '#BDBFC3' }} />

        <Grid container spacing={5} style={{ marginTop: '20px' }}>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel
              title={`${intl.messages[`model.corporateName`]}`}
            />
            <Input
              defaultValue={vehicle && vehicle.corporateName}
              onChange={ev =>
                updateState('corporateName', ev.currentTarget.value)
              }
              placeholder={`${intl.messages[`model.corporateName`]}`}
              disableUnderline
              className={classes.input}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel
              title={`${intl.messages[`model.corporateType`]}`}
            />
            <Input
              defaultValue={vehicle && vehicle.corporateType}
              onChange={ev =>
                updateState('corporateType', ev.currentTarget.value)
              }
              title={''}
              placeholder={`${intl.messages[`model.corporateType`]}`}
              disableUnderline
              className={classes.input}
              inputProps={{ maxLength: 25 }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomFormLabel
              title={`${intl.messages[`model.corporateRegistrationNumber`]}`}
            />
            <Input
              defaultValue={vehicle && vehicle.corporateRegistrationNumber}
              onChange={ev =>
                updateState(
                  'corporateRegistrationNumber',
                  ev.currentTarget.value,
                )
              }
              disableUnderline
              placeholder={`${
                intl.messages[`model.corporateRegistrationNumber`]
              }`}
              className={classes.input}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <CustomFormLabel title={`${intl.messages[`model.address1`]}`} />
            <Input
              defaultValue={vehicle && vehicle.address1}
              onChange={ev => updateState('address1', ev.currentTarget.value)}
              placeholder={`${intl.messages[`model.address1`]}`}
              disableUnderline
              className={classes.input}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.hiddenColumn} />
          <Grid item xs={12} sm={8}>
            <CustomFormLabel title={`${intl.messages[`model.address2`]}`} />
            <Input
              defaultValue={vehicle && vehicle.address2}
              onChange={ev => updateState('address2', ev.currentTarget.value)}
              placeholder={`${intl.messages[`model.address2`]}`}
              disableUnderline
              className={classes.input}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.hiddenColumn} />
          <Grid item xs={12} sm={8}>
            <Grid container spacing={5}>
              <Grid item xs={6} sm={3}>
                <CustomFormLabel title={`${intl.messages[`model.zipCode`]}`} />
                <Input
                  defaultValue={vehicle && vehicle.zipCode}
                  onChange={ev =>
                    updateState('zipCode', ev.currentTarget.value)
                  }
                  disableUnderline
                  placeholder={`${intl.messages[`model.zipCode`]}`}
                  className={classes.input}
                  inputProps={{ maxLength: 25 }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CustomFormLabel title={`${intl.messages[`model.city`]}`} />
                <Input
                  defaultValue={vehicle && vehicle.city}
                  onChange={ev => updateState('city', ev.currentTarget.value)}
                  placeholder={`${intl.messages[`model.city`]}`}
                  disableUnderline
                  className={classes.input}
                  inputProps={{ maxLength: 25 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomFormLabel title={`${intl.messages[`selectCountry`]}`} />
                <Select
                  defaultValue={(vehicle && vehicle.country) || ''}
                  style={{ width: '100%' }}
                  onChange={ev => updateState('country', ev.target.value)}
                  className={classes.input}
                  items={countryOptions}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <UploadDocuments
              title={`${intl.messages[`incProof`]}`}
              onChange={fileIds => {
                setVehicleFiles(fileIds);
              }}
              document={vehicleDoc}
              downloadable={true}
            />
            <Typography>
              <FormattedMessage id={`uploadProofOfInc`} />
            </Typography>
            <Typography>
              <FormattedMessage id={`cantFindCopyOfYourIncProof`} />
            </Typography>
            <Typography>
              <FormattedMessage id={`saveProfileUpdateIncProofLater`} />
            </Typography>
          </Grid>
        </Grid>

        <Typography
          variant={'subtitle1'}
          style={{ marginTop: '20px', color: '#BDBFC3' }}
        >
          <FormattedMessage id={`legalPepresentative`} />
        </Typography>
        <Divider style={{ marginBottom: '10px', backgroundColor: '#BDBFC3' }} />
        <div>
          <AntSwitch
            defaultChecked={
              vehicle === undefined || vehicle.isSameInvestor ? true : false
            }
            onChange={ev =>
              updateState('isSameInvestor', ev.currentTarget.checked)
            }
          />
          <span style={{ paddingLeft: '10px' }}>
            <FormattedMessage id={`sameAsInvestor`} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default memo(CorporateVehicle);
