import React, { memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import MultiSelect from 'components/MultiSelect';
import Select from 'components/Select';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
  createStyles({
    clearBtn: {
      color: '#D74F4F',
      '&:hover': {
        color: '#B74F4F',
      },
    },
    titleText: {
      fontWeight: 'bold',
      fontSize: '0.875rem',
    },
    filterContainer: {
      display: 'inline-flex',
    },
    interest: {
      minWidth: 150,
    },
    select: {
      width: '100%',
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      whiteSpace: 'nowrap',
      marginRight: 5,
      marginBottom: 0,
    },
  }),
);

interface Props {
  onSelectStatus: (value: any[]) => void;
  onSelectTicketIssuer: (value: string) => void;
  status: any[];
  ticketIssuer: string;
  isAdmin?: boolean;
}

function Filter(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const {
    onSelectStatus,
    onSelectTicketIssuer,
    status,
    ticketIssuer,
    isAdmin,
  } = props;

  const clearFilter = () => {
    onSelectStatus([]);
    onSelectTicketIssuer('All');
  };

  const Statuses = [
    { title: `${intl.messages['submitted']}`, value: 'SUBMITTED' },
    { title: `${intl.messages['approved']}`, value: 'APPROVED' },
    { title: `${intl.messages['fundsRequested']}`, value: 'FUNDS_REQUESTED' },
    {
      title: `${intl.messages['fundsTransferred']}`,
      value: 'FUNDS_TRANSFERRED',
    },
    {
      title: `${intl.messages['fundsNotTransferred']}`,
      value: 'FUNDS_NOT_TRANSFERRED',
    },
    { title: `${intl.messages['rejected']}`, value: 'REJECTED' },
    { title: `${intl.messages['cancelled']}`, value: 'CANCELLED' },
  ];

  return (
    <Card variant="outlined">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={11}>
          <Typography className={classes.titleText}>
            <FormattedMessage id="filter" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Typography style={{ textAlign: 'center' }}>
            <a className={classes.clearBtn} onClick={clearFilter}>
              <FormattedMessage id="clearFilters" />
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} className={classes.filterContainer}>
          <Typography className={classes.title}>
            <FormattedMessage id="status" />
          </Typography>
          <MultiSelect
            options={Statuses}
            defaultValue={[]}
            onSelect={onSelectStatus}
            value={status}
          />
        </Grid>
        {isAdmin && (
          <Grid item xs={12} sm={6} className={classes.filterContainer}>
            <Typography className={classes.title}>
              <FormattedMessage id="ticketIssuer" />
            </Typography>
            <Select
              value={ticketIssuer}
              className={classes.select}
              onChange={e => onSelectTicketIssuer(e.target.value)}
              defaultValue="All"
              items={[
                { value: `${intl.messages['all']}`, key: 'All' },
                { value: `${intl.messages['investor']}`, key: 'INVESTOR' },
                { value: `${intl.messages['admin']}`, key: 'ADMIN' },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export default memo(Filter);
