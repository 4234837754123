/*
 *
 * DealDescription constants
 *
 */

import { GradientRounded } from '@material-ui/icons';

enum ActionTypes {
  SEND_COMMENT = 'app/DealDescription/SEND_COMMENT',
  SET_TOPIC = 'app/DealDescription/SET_TOPIC',
  SET_MESSAGE = 'app/DealDescription/SET_MESSAGE',
  EMAIL_TO_INVESTORS = 'app/DealDescription/EMAIL_TO_INVESTORS',
  UPLOAD_BANNER_LOGO = 'app/DealDescription/UPLOAD_BANNER_LOGO',
  UPLOAD_BANNER_LOGO_SUCCEED = 'app/DealDescription/UPLOAD_BANNER_LOGO_SUCCEED',
}

export const topics = [
  {
    value: 'requireAdditionalDocument',
    label: 'requireAdditionalDocument',
  },
  {
    value: 'feedbackOnTheDeal',
    label: 'feedbackOnTheDeal',
  },
  {
    value: 'directQuestion',
    label: 'directQuestion',
  },
  {
    value: 'others',
    label: 'others',
  },
];

export default ActionTypes;
