import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  PropTypes,
} from '@material-ui/core';
import React, { memo } from 'react';
import CustomHelperText from '../../../components/FormControl/components/helper-text';
import {
  FormState,
  initialFormState,
} from '../../../components/FormControl/state';
import { useIntl } from 'react-intl';
import { ReactComponent as UploadSvg } from 'images/icons/with-class/upload-rectangle.svg';

export interface AvatarUploadState extends FormState {
  localFile: any;
  remoteUrl: string | null;
}

export const initialAvatarUploadState: AvatarUploadState = {
  localFile: null,
  remoteUrl: null,
  ...initialFormState,
};

interface Props {
  formState: AvatarUploadState;
  onChange: (ev) => void;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  hideTitle?: boolean;
  fullWidth: boolean;
}

const useStyles = makeStyles(theme => ({
  uploadButton: {
    cursor: 'pointer',
    '& .svg-stroke': {
      stroke: 'var(--color-grey)',
    },
    '&:hover': {
      color: 'white',
      '& .svg-stroke': {
        stroke: 'white',
      },
    },
  },
}));

FormAvatarUpload.defaultProps = {
  fullWidth: true,
};

function FormAvatarUpload(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const {
    formState,
    onChange,
    title,
    subtitle,
    margin,
    fullWidth,
    hideTitle,
  } = props;
  let hiddenFileInput;

  const [avatarUrl, setAvatarUrl] = React.useState<string | null>(
    formState.remoteUrl,
  );

  const handleAvatarChange = ev => {
    setAvatarUrl(URL.createObjectURL(ev.target.files[0]));
    onChange(ev.target.files[0]);
  };

  const handleAvatarRemove = () => {
    setAvatarUrl(null);
    onChange(null);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <div>
        {!avatarUrl && (
          <Grid
            container
            spacing={4}
            wrap={'nowrap'}
            alignItems={'center'}
            onClick={() => hiddenFileInput.click()}
            className={classes.uploadButton}
          >
            <Grid item xs={'auto'}>
              <UploadSvg />
            </Grid>
            <Grid item xs={'auto'}>
              {title}
            </Grid>
          </Grid>
        )}
        {avatarUrl && (
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={'auto'}>
              <img
                style={{
                  width: '36px',
                  height: '36px',
                  borderRadius: '3px',
                }}
                src={avatarUrl}
                alt={avatarUrl}
              />
            </Grid>
            <Grid item xs={'auto'}>
              {title}
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <Button
                size={'small'}
                style={{ marginTop: '0px' }}
                variant="contained"
                color="primary"
                onClick={() => hiddenFileInput.click()}
              >
                {`${intl.messages[`update`]}`}
              </Button>
              <Button
                size={'small'}
                style={{
                  marginLeft: '10px',
                  backgroundColor: '#D74F4F',
                  color: '#FFF',
                }}
                variant="contained"
                onClick={handleAvatarRemove}
              >
                {`${intl.messages['delete']}`.toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        )}
        <input
          ref={ref => (hiddenFileInput = ref)}
          type="file"
          name="file"
          accept="image/*"
          onChange={handleAvatarChange}
          style={{ display: 'none' }}
        />
      </div>

      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(FormAvatarUpload);
