import React, { useRef, useEffect } from 'react';
import { Input } from '@material-ui/core';

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

interface Props {
  onChange: any;
  [propName: string]: any;
}
export default function TextSearch(props: Props): JSX.Element {
  const { onChange, ...rest } = props;

  const [timer, setTimer] = React.useState<any>();
  const [value, setValue] = React.useState('');

  useEffect(() => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        onChange(value);
      }, WAIT_INTERVAL),
    );
  }, [value]);

  const handleChange = ev => {
    setValue(ev.target.value);
  };

  const handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer);
      onChange(e.target.value);
    }
  };

  const ref = useRef<any>();
  return (
    <Input
      ref={ref}
      {...rest}
      placeholder={'Search'}
      value={value || ''}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
}
