/*
 *
 * Register constants
 *
 */

enum ActionTypes {
  SET_STEP = 'app/Register/SET_STEP',
  FETCH_PROFILE_ACTION = 'app/Register/FETCH_PROFILE_ACTION',
  FETCH_PROFILE_SUCCESS_ACTION = 'app/Register/FETCH_PROFILE_SUCCESS_ACTION',
  SET_LOADING = 'app/Register/SET_LOADING',
}

export default ActionTypes;
