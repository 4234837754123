/*
 *
 * DealFlow
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  Card,
  Typography,
  LinearProgress,
  Link,
  Theme,
} from '@material-ui/core';
import TicketCreate from 'containers/TicketCreate';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import Table, { HeaderProps } from 'components/Table';
import NumberFormat from 'react-number-format';
import Deal from 'models/deal';
import moment from 'moment';
import Status from 'components/Status';
import DealHeader from './header';
import { useIntl } from 'react-intl';
import { backgroundColor } from 'styles/colors';
import DealTranslation from 'models/dealTranslation';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    border: '1px solid #E5E5E5',
    borderRadius: 0,
  },
  centerText: {
    textAlign: 'center',
  },
  descriptionText: {
    textAlign: 'left',
  },
  amount: {
    fontWeight: 'bold',
    color: 'var(--color-light-grey)',
  },
  title: {
    fontWeight: 600,
    color: 'var(--color-light-grey)',
    '&:hover': {
      color: 'var(--color-purple)',
      textDecoration: 'none',
    },
  },
  tableCell20: {
    width: '20%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell10: {
    width: '10%',
  },
  investButton: {
    backgroundColor: 'transparent',
    border: '1px solid var(--color-purple)',
    color: 'var(--color-purple)',
    '&:hover': {
      color: 'var(--color-light-grey)',
      backgroundColor: 'var(--color-purple)',
    },
    '&:disabled': {
      border: '1px solid var(--color-grey)',
    },
  },
  noInterestButton: {
    backgroundColor: 'transparent',
    border: '1px solid var(--color-grey)',
    color: 'var(--color-grey)',
    '&:hover': {
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
      backgroundColor: 'transparent',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  gradientBar: {
    backgroundImage:
      'linear-gradient(to right, var(--color-dark-purple), var(--color-purple))',
  },
  progressBarRoot: {
    backgroundColor: 'var(--color-dark-grey)',
    borderRadius: '3px',
  },
}));

interface Props {
  items?: Deal[];
  total: number;
  fetchPage?: (page: number) => void;
  updateSuccess: () => void;
  cancelInvite: (item) => void;
  page: number;
}

function DealTable(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items, total, fetchPage, updateSuccess, cancelInvite, page } = props;
  const [ticketOpen, setTicketOpen] = useState(false);
  const [dealItem, setDealItem] = useState<any>({});

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(page);
    }
  };
  const headers: HeaderProps[] = [
    {
      name: capitalize(`${intl.messages['dealName']}`),
      headerClass: classes.tableCell15,
    },
    {
      name: capitalize(`${intl.messages['companyName']}`),
      headerClass: classes.tableCell15,
    },
    {
      name: capitalize(`${intl.messages['dealType']}`),
      headerClass: classes.tableCell10,
    },
    {
      name: capitalize(`${intl.messages['status']}`),
      headerClass: classes.tableCell10,
    },
    {
      name: capitalize(`${intl.messages['tickets']}`),
      headerClass: classes.tableCell10,
    },
    {
      name: capitalize(`${intl.messages['progress']}`),
      headerClass: classes.tableCell20,
    },
    {
      name: capitalize(`${intl.messages['actions']}`),
      headerClass: `${classes.tableCell20} ${classes.alignRight}`,
    },
  ];

  const viewDetail = id => {
    dispatch(push(`deals/${id}`));
  };

  const renderItem = (item, index: number) => {
    const acceptedTicketsAmount = item.approvedAmount || 0;
    const onLinkClick = e => {
      e.preventDefault();
      viewDetail(item.dealId);
    };

    const confidientalNotSigned =
      item.isConfidentialAgreement &&
      (!item.eSignDocuments.length ||
        item.eSignDocuments.reduce(
          (acc, curr) => acc || (curr.status !== 'SIGNED' && curr.status !== 'EXPIRED'),
          false,
        ));

    return [
      <div key={index}>
        <Typography>
          <Link
            className={classes.title}
            onClick={onLinkClick}
            href={`deals/${item.dealId}`}
          >
            {item.deal_translations[0].name}
          </Link>
        </Typography>
      </div>,
      <div key={index}>{item.company.company_translations[0]?.name}</div>,
      <div key={index}>
        <Typography>
          {intl.messages[item?.dealType ? item.dealType.toLowerCase() : '']}
        </Typography>
      </div>,
      <div key={index}>
        <Status name={item.status} />
        <Typography>
          {item.refClosingDate
            ? moment(item.refClosingDate).format('DD-MM-YYYY')
            : ''}
        </Typography>
      </div>,
      <div key={index}>
        <Typography className={`${classes.descriptionText} ${classes.amount}`}>
          <NumberFormat
            value={item.userTickets.aggregate.sum.amount || 0}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
        <Typography className={classes.descriptionText}>
          {item.userTickets.aggregate.count} {`${intl.messages['tickets']}`}
        </Typography>
      </div>,
      item.isGaugeDisplay ? (
        <div key={index} style={{ minWidth: 150 }}>
          <LinearProgress
            variant="determinate"
            classes={{
              root: classes.progressBarRoot,
              bar: classes.gradientBar,
            }}
            value={
              ((acceptedTicketsAmount || 0) / (item.totalSizeRound || 1)) * 100
            }
          />
          <Typography className={classes.descriptionText}>
            {Math.round(
              ((acceptedTicketsAmount || 0) / (item.totalSizeRound || 1)) *
                1000,
            ) / 10}
            % -{' '}
            <NumberFormat
              value={acceptedTicketsAmount || 0}
              thousandSeparator={true}
              prefix={'$'}
              displayType={'text'}
            />{' '}
            - {item.totalTickets} {`${intl.messages['tickets']}`}
          </Typography>
        </div>
      ) : (
        ''
      ),
      <div className={classes.alignRight}>
        <Button
          size={'small'}
          variant="contained"
          // color="primary"
          style={{ margin: 8 }}
          className={classes.investButton}
          disabled={item.status !== 'OPEN' || confidientalNotSigned}
          onClick={() => {
            setDealItem(item);
            setTicketOpen(true);
          }}
        >
          {`${intl.messages['invest']}`.toUpperCase()}
        </Button>
        {item.tickets && item.tickets.length === 0 && (
          <Button
            size={'small'}
            className={classes.noInterestButton}
            variant="contained"
            // color="primary"
            onClick={() => cancelInvite(item)}
            disabled={item.totalTicketInThisInvestor > 0}
          >
            {`${intl.messages['notInterest']}`.toUpperCase()}
          </Button>
        )}
      </div>,
    ];
  };
  return (
    <>
      <TicketCreate
        open={ticketOpen}
        onClose={() => setTicketOpen(false)}
        deal={dealItem}
        updateSuccess={updateSuccess}
      />
      <DealHeader noOfDeal={total} />

      <Table
        headers={headers}
        items={items}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
        currentPage={page}
      />
    </>
  );
}

export default memo(DealTable);
