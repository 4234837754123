import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Container } from '@material-ui/core';
import errorIcon from './icons/404sm.png';
import ArrowButton from 'components/ArrowButton';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles({
  errorIcon: {
    color: '#808080',
    maxWidth: '276.8px',
  },
  header: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: ' 38px',
    marginTop: '3.2rem',
  },
  paragraph: {
    maxWidth: '416px',
    fontSize: ' 15px',
    fontWeight: 400,
    lineHeight: ' 23px',
    padding: '0 4rem',
    marginTop: '1.25rem',
  },
  backhome: {
    cursor: 'pointer',
    marginTop: '4.5rem',
  },
});
function NotFound() {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div
        style={{
          minHeight: '85vh',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <img src={errorIcon} alt="errorIcon" className={classes.errorIcon} />
          <h2 className={classes.header}><FormattedMessage id="Notfound.header"/></h2>
          <p className={classes.paragraph}>
           <FormattedMessage id="Notfound.paragraph"/>
          </p>
          <div className={classes.backhome}>
            <ArrowButton
              href="/dashboard"
              text={' Home'}
              onClick={e => {
                e.preventDefault();
                history.push('/dashboard');
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default NotFound;
