import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import LocalStorage from '../local-storage-service';

export const createService = (
  baseURL?: string,
  contentType = 'application/json',
): AxiosInstance => {
  return interceptAuth(baseConfig(baseURL, contentType));
};

export const downloadFileService = (
  baseURL?: string,
  contentType = 'application/json',
): AxiosInstance => {
  const config: AxiosRequestConfig = baseConfig(baseURL, contentType);
  config.responseType = 'blob';
  return interceptAuth(config);
};

const baseConfig = (baseURL?: string, contentType = 'application/json') => {
  return {
    baseURL: baseURL,
    headers: {
      'Accept-Language': 'en-US',
      'Content-Type': contentType,
    },
  };
};

const interceptAuth = (config: AxiosRequestConfig) => {
  const instance = axios.create(config);
  instance.interceptors.request.use(
    config => {
      const token = LocalStorage.get(LocalStorage.OAUTH_TOKEN);
      if (token) {
        config.headers['Authorization'] = `bearer ${token}`;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (!error || !error.response) {
        error = {};
        error.response = 'Something went wrong';
      }
      if (error && error.response && 401 === error.response.status) {
        window.location.href = '/login';
        LocalStorage.removeItem(LocalStorage.OAUTH_TOKEN);
        // LocalStorage.removeItem(LocalStorage.PROFILE);
      }
      return Promise.reject(error);
    },
  );
  return instance;
};

export const createServiceNoToken = (baseURL?: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      'Accept-Language': 'en-US',
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.request.use(
    config => {
      return config;
    },
    error => {
      Promise.reject(error);
    },
  );
  return instance;
};

export const axiosConfig = (
  dummyResponse?: () => AxiosResponse,
  sleep?: number,
  forceMock?: boolean | string,
  config: AxiosRequestConfig = axios.defaults,
) => {
  if ((forceMock === undefined || forceMock) && dummyResponse) {
    const { adapter, ...rest } = config;
    const finalConfig: AxiosRequestConfig = {
      ...rest,
      adapter: customAdapter(dummyResponse, sleep),
    };
    return finalConfig;
  }
  return config;
};

function customAdapter(dummyResponse: () => AxiosResponse, sleep?: number) {
  return config => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (sleep) {
        delay(sleep).then(() => {
          resolve(dummyResponse());
        });
      } else {
        resolve(dummyResponse());
      }
    });
  };
}
const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));
