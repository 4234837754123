import LocalStorageService from 'services/local-storage-service';

export const isLoggedIn = () => {
  const accessToken = LocalStorageService.get<string>(
    LocalStorageService.OAUTH_TOKEN,
  );
  return !!accessToken && accessToken.length > 0;
};

export const base64Encode = (string: string): string => {
  if (!string) {
    return '';
  }

  const buffer = Buffer.from(string, 'utf8');
  return buffer.toString('base64');
};

export const base64Decode = (string: string): string => {
  if (!string) {
    return '';
  }

  const buffer = Buffer.from(string, 'base64');
  return buffer.toString('utf8');
};

const YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape

function isYoutube(url) {
  return YOUTUBEMATCH_URL.test(url);
}
function isVimeo(url) {
  return VIMEOMATCH_URL.test(url);
}
function getYoutubeSrc(url) {
  const id = url && url.match(YOUTUBEMATCH_URL)[1];
  return {
    srcID: id,
    srcType: 'youtube',
    url: url,
  };
}
function getVimeoSrc(url) {
  const id = url.match(VIMEOMATCH_URL)[3];
  return {
    srcID: id,
    srcType: 'vimeo',
    url: url,
  };
}

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

export const convertVideoSrcToEmbed = function(src) {
  if (isYoutube(src)) {
    const _getYoutubeSrc = getYoutubeSrc(src),
      srcID = _getYoutubeSrc.srcID;

    return `${YOUTUBE_PREFIX}${srcID}`;
  }

  if (isVimeo(src)) {
    const _getVimeoSrc = getVimeoSrc(src),
      _srcID = _getVimeoSrc.srcID;

    return `${VIMEO_PREFIX}${_srcID}`;
  }

  return undefined;
};

export const sanitizeFileName = (fileName) => {
	let sanitizedFileName = fileName.replace(/\s+/g, "_");
	sanitizedFileName = sanitizedFileName.replace(/[^a-zA-Z0-9_.-]/g, "");
	return sanitizedFileName;
};
