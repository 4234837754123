import React from 'react';
import { makeStyles, Container } from '@material-ui/core';
import ArrowButton from 'components/ArrowButton';
import {FormattedMessage,useIntl} from 'react-intl'
import { TOKENY_LINK } from 'env';
const useStyles = makeStyles({
 
  header: {
    marginTop: '3rem',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '48px',
    color: '#FFFFFF',
    '@media(max-width:480px)': {
      fontSize: '1.5rem',
      marginTop: '0.5rem',
    },
  },

  paragraph: {
    maxWidth: '592px',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '25px',
    marginTop: '1.25rem',
    color: '#999999',
  },
  checkOut: {
    marginTop: '2rem',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '25px',
    color: ' #F6F6F6',
  },
});
function SecondaryMarket() {
  const intl = useIntl();
  const handleClick=(e)=>{
    e.preventDefault();
    return false;
    window.open(TOKENY_LINK, '_blank')
  
  }
  const classes = useStyles();
  return (
    <Container
      maxWidth="md"
      className="main"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div>
        <h1 className={classes.header}><FormattedMessage id="SecondaryMarket.header"/></h1>
        <p className={classes.paragraph}>
         <FormattedMessage id="Secondary-market.firstParagraph"/>
          <br /> <br />
         <FormattedMessage id="Secondary-market.secondParagraph"/>
        </p>
        <div className={classes.checkOut}>
          <ArrowButton disabled text={ `${intl.messages['SecondaryMarket.comingSoon']}`} /*href={TOKENY_LINK}*/  onClick={handleClick}/>
        </div>
      </div>
    </Container>
  );
}

export default SecondaryMarket;
