// tslint:disable: jsx-wrap-multiline
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card,
  Checkbox,
  Button,
  makeStyles,
  Theme,
  Grid,
  Typography,
  Link,
  createStyles,
} from '@material-ui/core';
import Img from 'components/Img';
import Alert from 'components/AlertDialog/alertDialog';
import NoAvatar from 'images/not-found.png';
import moment from 'moment';
import Table from 'components/Table';
import Header from './Header';
import styles from '../styles';
import { rejectInvite } from '../actions';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      color:'var(--color-purple)',
      fontWeight: 600,
      minWidth: 60,
    },
    tableCell5: {
      width: '5%',
    },
    tableCell10: {
      width: '10%',
    },
    tableCell15: {
      width: '15%',
    },
    tableCell20: {
      width: '20%',
    },
    tableCell25: {
      width: '25%',
    },
    tableCell30: {
      width: '30%',
    },
    tableCell35: {
      width: '35%',
    },
  }),
);

interface Props {
  investors?: any[];
  isConfidentialAgreement: boolean;
  matches: number;
  seleted: number;
  total: number;
  fetchPage: (page: number) => void;
  onSelect: (item: any) => void;
  selectedInvestors: any[];
  onSendMail: () => void;
  onSendCustomMail: () => void;
  dealStatus: string;
}

function Investors(props: Props) {
  const intl = useIntl();
  const {
    investors,
    isConfidentialAgreement,
    matches,
    seleted,
    total,
    fetchPage,
    onSelect,
    selectedInvestors,
    onSendMail,
    onSendCustomMail,
    dealStatus,
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [rejectItem, setRejectItem] = useState<any>({});

  const headers: any[] = [
    { name: '', headerClass: classes.tableCell5 },
    {
      name: `${intl.messages['investors']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['tagsAndInterests']}`.toUpperCase(),
      headerClass: classes.tableCell35,
    },
    {
      name: `${intl.messages['lastInvitedTime']}`.toUpperCase(),
      headerClass: classes.tableCell20,
    },
    {
      name: `${intl.messages['investmentStatus']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    { name: '', headerClass: classes.tableCell10 },
  ];

  const confidentialHeaders: any[] = [
    { name: '', headerClass: classes.tableCell5 },
    {
      name: `${intl.messages['investors']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['tagsAndInterests']}`.toUpperCase(),
      headerClass: classes.tableCell30,
    },
    {
      name: `${intl.messages['lastInvitedTime']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['investmentStatus']}`.toUpperCase(),
      headerClass: classes.tableCell15,
    },
    {
      name: `${intl.messages['accessStatus']}`.toUpperCase(),
      headerClass: classes.tableCell10,
    },
    { name: '', headerClass: classes.tableCell10 },
  ];
  const [page, setPage] = React.useState(1);
  const [tableItems, setTableItem] = React.useState(investors);

  useEffect(() => {
    if (investors) {
      investors.forEach(val => {
        const selected =
          selectedInvestors.filter(item => {
            return item.userId === val.userId;
          }).length > 0;
        val = { ...val, selected: selected };
      });
    }
    setTableItem(investors);
  }, [investors]);

  const handleFetchPage = currentPage => {
    setPage(currentPage);
    fetchPage(currentPage);
  };

  const onItemChanged = (item, value) => {
    const _item = { ...item, selected: value.currentTarget.checked };
    onSelect(_item);
  };

  const onClickReject = item => {
    setRejectItem(item);
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    dispatch(rejectInvite(rejectItem.dealInvestorId));
    setOpen(false);
  };

  const onClick = item => {
    dispatch(push(`/investors/${item.userId}`));
  };

  const renderItem = (item, index) => {
    return [
      <Checkbox
        key={index}
        color="primary"
        onChange={checked => onItemChanged(item, checked)}
        checked={item.selected}
      />,
      <Grid container key={index}>
        <Grid item>
          <Img src={item.picturePath || NoAvatar} style={styles.image} />
        </Grid>
        <Grid item xs>
          <Link className={classes.name} onClick={() => onClick(item)}>
            {item.name}
          </Link>
          <Typography>{item.email}</Typography>
        </Grid>
      </Grid>,
      <div key={index}>
        {item.preferenceTag.map(val => {
          return (
            <span style={{ ...styles.interests }} key={val}>
              {val}
            </span>
          );
        })}
      </div>,
      `${moment(item.lastInvitationTime).format('DD-MM-YYYY, HH:mm')} (SGT)`,
      item.investStatus !== 'NOT_INVESTED' ? (
        <p style={styles.investStatus}>
          <FormattedMessage id="invested" />
        </p>
      ) : (
        ''
      ),
      <Button
        size={'small'}
        style={{ backgroundColor: '#BDBFC3' }}
        variant="contained"
        color="primary"
        key={index}
        onClick={() => onClickReject(item)}
      >
        {`${intl.messages['reject']}`.toUpperCase()}
      </Button>,
    ];
  };

  const renderConfidentialItem = (item, index) => {
    return [
      <Checkbox
        key={index}
        color="primary"
        onChange={checked => onItemChanged(item, checked)}
        checked={item.selected}
      />,
      <Grid container key={index}>
        <Grid item>
          <Img src={item.picturePath || NoAvatar} style={styles.image} />
        </Grid>
        <Grid item xs>
          <Link className={classes.name} onClick={() => onClick(item)}>
            {item.name}
          </Link>
          <Typography>{item.email}</Typography>
        </Grid>
      </Grid>,
      <div key={index}>
        {item.preferenceTag.map(val => {
          return (
            <span style={{ ...styles.interests }} key={val}>
              {val}
            </span>
          );
        })}
      </div>,
      `${moment(item.lastInvitationTime).format('DD-MM-YYYY, HH:mm')} (SGT)`,
      item.investStatus !== 'NOT_INVESTED' ? (
        <p style={styles.investStatus}>
          <FormattedMessage id="invested" />
        </p>
      ) : (
        ''
      ),
      item.hasDealAccess ? (
        <p style={styles.investStatus}>
          <FormattedMessage id="granted" />
        </p>
      ) : (
        ''
      ),
      <Button
        size={'small'}
        style={{ backgroundColor: '#BDBFC3' }}
        variant="contained"
        color="primary"
        key={index}
        onClick={() => onClickReject(item)}
      >
        {`${intl.messages['reject']}`.toUpperCase()}
      </Button>,
    ];
  };

  return (
    <>
      <div>
        <Header
          matches={matches}
          selected={seleted}
          onSendMail={onSendMail}
          onSendCustomMail={onSendCustomMail}
          dealStatus={dealStatus}
        />
        <Table
          headers={isConfidentialAgreement ? confidentialHeaders : headers}
          items={tableItems}
          renderItem={
            isConfidentialAgreement ? renderConfidentialItem : renderItem
          }
          fetchDataForPage={handleFetchPage}
          totalElements={total}
          currentPage={page}
        />
      </div>
      <Alert
        title={`${intl.messages['rejectInvitation']}`}
        open={open}
        onCancel={onCancel}
        onConfirm={onConfirm}
        description={
          <Typography>
            <FormattedMessage id="removeAccessOf" /> {<b>{rejectItem.name}</b>}{' '}
            <FormattedMessage id="investorToTheDeal" />{' '}
            <FormattedMessage id="doYouWantToContinue" />
          </Typography>
        }
      />
    </>
  );
}

export default memo(Investors);
