/*
 *
 * ProfileDeal reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import produce, { Draft } from 'immer';

export const initialState: ContainerState = {
  default: null,
};

const profileDealReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_DEAL_SUCCESS_ACTION:
        draft.deals = action.payload.data;
        draft.total = action.payload.metaData.total;
        break;
      default:
        break;
    }
  },
  initialState,
);
export default profileDealReducer;
