/*
 *
 * RegisterInvestments constants
 *
 */

enum ActionTypes {
  FETCH_INVESTMENTS_ACTION = 'app/RegisterInvestments/FETCH_INVESTMENTS_ACTION',
  UPDATE_FORM_STATE_INVESTMENT_ACTION = 'app/RegisterInvestments/UPDATE_FORM_STATE_INVESTMENT_ACTION',
  UPDATE_STATE_INVESTMENT_ACTION = 'app/RegisterInvestments/UPDATE_STATE_INVESTMENT_ACTION',
  UPDATE_STEP_ACTION = 'app/RegisterInvestments/UPDATE_STEP_ACTION',
  GET_USER_PREFERENCES = 'app/RegisterInvestments/GET_USER_PREFERENCES',
  GET_USER_PREFERENCES_SUCCESS = 'app/RegisterInvestments/GET_USER_PREFERENCES_SUCCESS',
  UPDATE_INVESTMENT_ACTION = 'app/RegisterInvestments/UPDATE_INVESTMENT_ACTION',
  UPDATE_INVESTMENT_SUCCESS_ACTION = 'app/RegisterInvestments/UPDATE_INVESTMENT_SUCCESS_ACTION',
}

export default ActionTypes;
