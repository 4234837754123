/*
 *
 * Home actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const changeTabAction = payload =>
  action(ActionTypes.CHANGE_TAB_ACTION, payload);
export const setIsLoading = payload =>
  action(ActionTypes.SET_ISLOADING, payload);
export const fetchUser = () => action(ActionTypes.FETCH_USER_ACTION);
export const fetchUserVehicle = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_ACTION, payload);
export const fetchUserVehicleSuccess = payload =>
  action(ActionTypes.FETCH_USER_VEHICLE_SUCCESS_ACTION, payload);
export const fetchUserSuccess = payload =>
  action(ActionTypes.FETCH_USER_SUCCESS_ACTION, payload);
export const fetchUserFailed = payload =>
  action(ActionTypes.FETCH_USER_FAILED_ACTION, payload);
export const fetchPreferences = () =>
  action(ActionTypes.FETCH_PREFERENCE_ACTION);
