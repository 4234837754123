import { action } from 'typesafe-actions';
import { ESignDocument } from 'models/eSignDocument';

import ActionTypes from './constants';

export const setDealDescription = (deal: any) =>
  action(ActionTypes.SET_DEAL_DESCRIPTION, deal);

export const updateDeal = (deal: any) => action(ActionTypes.UPDATE_DEAL, deal);

export const updateDealStatus = (
  oldStatus: string,
  status: string,
  sendMail: boolean,
) => action(ActionTypes.UPDATE_DEAL_STATUS, { oldStatus, status, sendMail });

export const updateDealStatusSucceed = (status: string) =>
  action(ActionTypes.UPDATE_DEAL_STATUS_SUCCEED, status);

export const getUserTicket = payload =>
  action(ActionTypes.GET_USER_TICKET, payload);

export const getUserTicketSuccess = payload =>
  action(ActionTypes.GET_USER_TICKET_SUCCESS, payload);

export const getUserTicketFailed = (id: string) =>
  action(ActionTypes.GET_USER_TICKET_FAILED, id);

export const requestSign = (request: {
  eSignTemplateId: string;
  dealId?: string;
  vehicleIds: string[];
  esignDocumentId?: string | undefined;
}) => action(ActionTypes.REQUEST_SIGN, request);

export const requestSignSucceed = (eSignDocument: ESignDocument) =>
  action(ActionTypes.REQUEST_SIGN_SUCCEED, eSignDocument);

export const requestSignFailed = () => action(ActionTypes.REQUEST_SIGN_FAILED);
export const getPublicDocuments = payload =>
  action(ActionTypes.GET_DEAL_DOCUMENTS, payload);
export const getDocumentsSucceed = payload =>
  action(ActionTypes.GET_DOCUMENTS_SUCCEED, payload);
