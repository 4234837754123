import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the profileEditDialog state domain
 */

const selectProfileEditDialogDomain = (state: ApplicationRootState) => {
  return state.profileEditDialog || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProfileEditDialog
 */

const makeSelectProfileEditDialog = () =>
  createSelector(selectProfileEditDialogDomain, substate => {
    return substate;
  });

export default makeSelectProfileEditDialog;
export { selectProfileEditDialogDomain };
