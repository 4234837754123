import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the investorCreateDialog state domain
 */

const selectInvestorCreateDialogDomain = (state: ApplicationRootState) => {
  return state.investorCreateDialog || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by investorCreateDialog
 */

const makeSelectInvestorCreateDialog = () =>
  createSelector(selectInvestorCreateDialogDomain, substate => {
    return substate;
  });

export default makeSelectInvestorCreateDialog;
export { makeSelectInvestorCreateDialog };
