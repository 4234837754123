import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the profileVehicles state domain
 */

const selectProfileVehiclesDomain = (state: ApplicationRootState) => {
  return state.profileVehicles || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProfileVehicles
 */

const makeSelectProfileVehicles = () =>
  createSelector(selectProfileVehiclesDomain, substate => {
    return substate;
  });

export default makeSelectProfileVehicles;
export { selectProfileVehiclesDomain };
