/*
 *
 * DealList
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Typography, LinearProgress } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import Table, { HeaderProps } from 'components/Table';
import NumberFormat from 'react-number-format';
import DealTranslation from 'models/dealTranslation';
import Status from 'components/Status';
import DealHeader from './header';
import { useIntl } from 'react-intl';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
    border: '1px solid #E5E5E5',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 8,
  },
  card: {
    border: '1px solid #E5E5E5',
    borderRadius: 0,
  },
  container: {
    padding: 12,
  },
  image: {
    width: 25,
    height: 25,
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  descriptionText: {
    textAlign: 'left',
    marginTop: 8,
  },
  title: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
    minWidth: 70,
  },
  tableCell30: {
    width: '30%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell20: {
    width: '20%',
  },
}));

interface Props {
  items: DealTranslation[];
  total: number;
  fetchPage?: (page: number) => void;
  page: number;
}

function DealTable(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items, total, fetchPage, page } = props;

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(page);
    }
  };
  const headers: HeaderProps[] = [
    { name: `${intl.messages['dealName']}`, headerClass: classes.tableCell20 },
    {
      name: `${intl.messages['companyName']}`,
      headerClass: classes.tableCell20,
    },
    { name: `${intl.messages['dealType']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['status']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['progress']}`, headerClass: classes.tableCell30 },
  ];

  const viewDetail = id => {
    dispatch(push(`deals/${id}`));
  };

  const renderItem = (item, index: number) => {
    const acceptedTicketsAmount = item.deal?.acceptedTickets.reduce(
      (acc, curr) => acc + curr.amount,
      0,
    );

    return [
      <div key={index}>
        <Typography
          className={classes.title}
          onClick={() => viewDetail(item.deal?.id)}
        >
          {item.name}
        </Typography>
        <Typography>
          <NumberFormat
            value={item.deal?.totalSizeRound ? item.deal?.totalSizeRound : 0}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
      </div>,
      <div key={index}>
        <Typography>
          {item.deal?.company
            ? item.deal?.company.company_translations[0].name
            : ''}
        </Typography>
      </div>,
      <div key={index}>
        <Typography>
          {
            intl.messages[
              item?.deal?.dealType ? item.deal.dealType.toLowerCase() : ''
            ]
          }
        </Typography>
      </div>,
      <div key={index}>
        <Status name={item.deal?.status} />
        <Typography>
          {item.deal?.refClosingDate
            ? moment(item.deal.refClosingDate).format('DD-MM-YYYY')
            : ''}
        </Typography>
      </div>,
      <div key={index} style={{ minWidth: 150 }}>
        <LinearProgress
          variant="determinate"
          value={Math.min(
            ((acceptedTicketsAmount || 0) / (item.deal?.totalSizeRound || 1)) *
              100,
            100,
          )}
        />
        <Typography className={classes.descriptionText}>
          {Math.round(
            ((acceptedTicketsAmount || 0) / (item.deal?.totalSizeRound || 1)) *
              100,
          )}
          % -{' '}
          <NumberFormat
            value={acceptedTicketsAmount ? acceptedTicketsAmount : 0}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />{' '}
          - {item.deal?.acceptedTickets.length} {`${intl.messages['tickets']}`}
        </Typography>
      </div>,
    ];
  };
  return (
    <div>
      <DealHeader noOfDeal={total} />
      <Table
        headers={headers}
        items={items}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
        currentPage={page}
      />
    </div>
  );
}

export default memo(DealTable);
