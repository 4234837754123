import React, { memo, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import {
  Divider,
  Grid,
  Dialog,
  Typography,
  Button,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import ErrorToast from 'components/ErrorToast';
import TextArea from 'components/TextArea';
import FormControl from 'components/FormControl';
import NumberForm from 'components/FormControl/number';
import FormInput from 'components/FormControl/input';
import FormDate from 'components/FormControl/datepicker';
import FormSelect from 'components/FormControl/select';
import Loading from 'components/Loading';
import PopupTitle from 'components/PopupTitle';
import ToogleForm from './components/ToogleForm';
import Interests from './components/Interests';
import Additional from './components/Additional';
import { LANGUAGES } from 'utils/constants';
import { useInjectReducer } from 'utils/injectReducer';
import style from './styles';
import makeSelectDealCreator from './selectors';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { createDealGroup } from './actions';
import reducer from './reducer';
import { DealType } from './constants';
import UploadDocuments from 'containers/UploadDocuments';
import Document from 'models/document';
import { makeSelectPreferences } from 'containers/App/selectors';
import { initialInputState } from '../../components/FormControl/input';
import { initialNumberState } from '../../components/FormControl/number';
import { initialDatePickerState } from '../../components/FormControl/datepicker';
import { initialSelectState } from '../../components/FormControl/select';
import validateForm from './validate';
import ListEsignTemplatesCreateDropdownQuery from 'gql/esignTemplate/ListEsignTemplatesCreateDropdown.gql';
import InsertDeal from 'gql/deal/InsertDeal.gql';
import InsertDocumentFile from 'gql/document/InsertDocumentFile.gql';
import UpdateDealAttachmentDocument from 'gql/deal/UpdateDealAttachmentDocument.gql';
import { setIsLoading } from 'containers/Home/actions';

const stateSelector = createStructuredSelector({
  dealCreator: makeSelectDealCreator(),
  preferences: makeSelectPreferences(),
  user: makeSelectCurrentUser(),
});

interface Props {
  propOpen?: boolean;
  companyId: string;
  setOpen: (boolean) => void;
  onSuccess: (string) => void;
  onError: (string) => void;
}

function DealCreator(props: Props) {
  useInjectReducer({ key: 'dealCreator', reducer: reducer });
  const dispatch = useDispatch();
  const { companyId, setOpen, onSuccess, onError } = props;
  const [document, setDocument] = React.useState<Document>();
  const intl = useIntl();
  const scope = 'DealCreator';
  const { dealCreator, preferences, user } = useSelector(stateSelector);
  const { documentGroup } = dealCreator;

  const [insertDeal] = useMutation(InsertDeal);
  const [updateDocument] = useMutation(UpdateDealAttachmentDocument);
  const [insertDocumentFile] = useMutation(InsertDocumentFile);

  const initialFormState = {
    confidential: { value: false },
    gauge: { value: true },
    dealType: { value: DealType.EQUITY },
    name: {
      en: initialInputState,
      de: initialInputState,
      ja: initialInputState,
    },
    tokeny_link: initialInputState,
    totalSize: initialNumberState,
    netProfit: initialNumberState,
    forecastNetProfit: initialNumberState,
    minTicket: initialNumberState,
    maxTicket: initialNumberState,
    closingDate: initialDatePickerState,
    premoney: initialNumberState,
    valuationCap: initialNumberState,
    valuationFloor: initialNumberState,
    discount: initialInputState,
    summary: {
      en: initialInputState,
      de: initialInputState,
      ja: initialInputState,
    },
    openingDate: initialDatePickerState,
    time: initialInputState,
    loanInterest: initialInputState,
    interests: { value: [] },
    attachments: { value: [] },
    additionalList: {
      en: { value: [] },
      de: { value: [] },
      ja: { value: [] },
    },
    nda: initialSelectState,
  };

  const [formState, setFormState] = useState(initialFormState);

  const {
    loading: ndaLoading,
    error: ndaError,
    data: nda,
    refetch: refetchNda,
  } = useQuery(ListEsignTemplatesCreateDropdownQuery);

  const initFormState = () => {
    setFormState(initialFormState);
  };

  const updateFormState = (field, subfield = '') => stateValue => {
    const formField = formState[field];
    let newFieldState;
    if (stateValue === undefined || stateValue === null) {
      return;
    }

    if (formField) {
      if (subfield) {
        const newSubfieldValue = { ...formField[subfield], value: stateValue };
        newFieldState = { ...formField, [subfield]: newSubfieldValue };
      } else {
        stateValue =
          Number(stateValue) &&
          field !== 'loanInterest' &&
          field !== 'discount' &&
          field !== 'valuationFloor' &&
          field !== 'valuationCap' &&
          field !== 'confidential' &&
          field !== 'gauge'
            ? Number(stateValue)
            : stateValue;
        newFieldState = { ...formField, value: stateValue };
      }

      setFormState(prevState => ({ ...prevState, [field]: newFieldState }));
    }
  };

  const updateFormStateObject = (field, subfield = '') => newObj => {
    if (subfield) {
      setFormState(prevState => {
        const newSubfieldObj = prevState[field];
        newSubfieldObj[subfield] = newObj;
        return { ...prevState, newSubfieldObj };
      });
    } else {
      setFormState(prevState => ({ ...prevState, [field]: newObj }));
    }
  };

  const toTimeFormat = callback => time => {
    if (!time) {
      callback(time);
    } else {
      callback(moment(time).format('HH:mm'));
    }
  };

  useEffect(() => {
    if (
      documentGroup &&
      documentGroup.documents &&
      documentGroup.documents.length > 0
    ) {
      setDocument(documentGroup.documents[0]);
    }
  }, [documentGroup]);

  useEffect(() => {
    dispatch(createDealGroup());
    refetchNda();
  }, []);

  const confidential = formState.confidential.value;
  const gauge = formState.gauge.value;
  const dealType = formState.dealType.value;

  const onCancel = () => {
    initFormState();
    setOpen(false);
  };

  const onCreate = () => {
    const request = validateForm(
      formState,
      updateFormStateObject,
      user?.userId,
    );
    if (request) {
      request.companyId = companyId;
      dispatch(setIsLoading(true));
      const result = insertDeal({
        variables: {
          object: request,
        },
      });
      result.then(
        deal => {
          if (deal.data) {
            dispatch(setIsLoading(false));
            onSuccess(`${intl.messages[`${scope}.createDealSucceed`]}`);

            const dealAttachmentsDocumentId = deal.data.insert_deal_one.document_groups.filter(
              group => group.documentGroup.documents.length > 0,
            )[0].documentGroup.documents[0].document.id;

            // updateDocument({
            //   variables: {
            //     id: dealAttachmentsDocumentId,
            //     dealId: deal.data.insert_deal_one.id,
            //   },
            // });

            formState.attachments.value.forEach(fileId => {
              insertDocumentFile({
                variables: {
                  documentId: dealAttachmentsDocumentId,
                  fileId,
                },
              });
            });
          }
        },
        error => {
          onError(error.message);
          dispatch(setIsLoading(false));
        },
      );
      initFormState();
      setOpen(false);
    }
  };

  const onKeyPressNumber = event => {
    // 48 is '0'
    if (event.charCode < 48) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const onKeyPressDiscount = event => {
    // 46 is .
    if (event.charCode < 48 && event.charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const onInputNumber = event => {
    if (event.target.value.length > 20) {
      event.target.value = event.target.value.slice(0, 20);
    }
  };

  const onInputDiscount = event => {
    if (event.target.value > 100) {
      event.target.value = 100;
    }
  };

  const numberInputProps = {
    onKeyPress: onKeyPressNumber,
    onInput: onInputNumber,
  };

  const discountInputProps = {
    min: 0,
    max: 100,
    onKeyPress: onKeyPressDiscount,
    onInput: onInputDiscount,
  };

  const loanInterestInputProps = {
    min: 0,
    onKeyPress: onKeyPressDiscount,
  };

  const TotalSizeForm = (
    <Grid item xs={12} sm={4}>
      <NumberForm
        onChange={updateFormState('totalSize')}
        title={`${intl.messages['dealSizeOfTheRound']} *`}
        formState={formState.totalSize}
        inputProps={numberInputProps}
      />
    </Grid>
  );

  const MinMaxTicketForm = (
    <Grid item xs={12} sm={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <NumberForm
            onChange={updateFormState('minTicket')}
            title={
              dealType === DealType.LOAN
                ? `${intl.messages['minLoan']} *`
                : `${intl.messages['minTicket']} *`
            }
            formState={formState.minTicket}
            inputProps={numberInputProps}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberForm
            onChange={updateFormState('maxTicket')}
            title={
              dealType === DealType.LOAN
                ? `${intl.messages['maxLoan']} *`
                : `${intl.messages['maxTicket']} *`
            }
            formState={formState.maxTicket}
            inputProps={numberInputProps}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormDate
            onChange={updateFormState('closingDate')}
            title={`${intl.messages['referenceClosingDate']} *`}
            futureDate
            formState={formState.closingDate}
            style={{ marginTop: 2 }}
            disablePast
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const equityLayout = (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          {TotalSizeForm}
          <Grid item xs={12} sm={4}>
            <NumberForm
              onChange={updateFormState('netProfit')}
              title={`${intl.messages['netProfit']}`}
              formState={formState.netProfit}
              inputProps={numberInputProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberForm
              onChange={updateFormState('forecastNetProfit')}
              title={`${intl.messages['forecastNetProfit']}`}
              formState={formState.forecastNetProfit}
              inputProps={numberInputProps}
            />
          </Grid>
        </Grid>
      </Grid>
      {MinMaxTicketForm}

      <Grid item xs={12} sm={4}>
        <NumberForm
          onChange={updateFormState('premoney')}
          title={`${intl.messages['premoney']} *`}
          formState={formState.premoney}
          inputProps={numberInputProps}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <div style={{ display: 'flex', marginTop: 2 }}>
          <FormInput
            onChange={updateFormState('tokeny_link')}
            title={`${intl.messages['mandatoryTextFiled']}`}
            formState={formState.tokeny_link}
            inputProps={{ maxLength: 100 }}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={4}></Grid>
    </>
  );

  const safeLayout = (
    <>
      {TotalSizeForm}
      {MinMaxTicketForm}
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <NumberForm
              onChange={updateFormState('valuationCap')}
              title={`${intl.messages['valuationCap']} *`}
              formState={formState.valuationCap}
              inputProps={numberInputProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberForm
              onChange={updateFormState('valuationFloor')}
              title={`${intl.messages['valuationFloor']} *`}
              formState={formState.valuationFloor}
              inputProps={numberInputProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ display: 'flex', marginTop: 2 }}>
              <FormInput
                onChange={updateFormState('discount')}
                type={'number'}
                title={`${intl.messages['discount']}`}
                formState={formState.discount}
                inputProps={discountInputProps}
              />
              <p style={{ margin: '22px 0 0 6px' }}>%</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ display: 'flex', marginTop: 2 }}>
              <FormInput
                onChange={updateFormState('tokeny_link')}
                title={`${intl.messages['mandatoryTextFiled']}`}
                formState={formState.tokeny_link}
                inputProps={{ maxLength: 100 }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const loanLayout = (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          {TotalSizeForm}
        </Grid>
      </Grid>
      {MinMaxTicketForm}
      <Grid item xs={12} sm={4}>
        <div style={{ display: 'flex', marginTop: 2 }}>
          <FormInput
            onChange={updateFormState('loanInterest')}
            type={'number'}
            title={`${intl.messages['loanInterest']} *`}
            formState={formState.loanInterest}
            inputProps={loanInterestInputProps}
          />
          <p style={{ margin: '22px 0 0 6px' }}>%</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div style={{ display: 'flex', marginTop: 2 }}>
          <FormInput
            onChange={updateFormState('tokeny_link')}
            title={`${intl.messages['mandatoryTextFiled']}`}
            formState={formState.tokeny_link}
            inputProps={{ maxLength: 100 }}
          />
        </div>
      </Grid>
    </>
  );

  return (
    <Dialog open={props.propOpen ?? false} maxWidth="xl">
      <ErrorToast
        isOpen={Boolean(ndaError)}
        message={ndaError ? ndaError.message : ''}
      />
      <DialogTitle>
        <PopupTitle
          title={`${intl.messages['createDeal']}`}
          onClose={onCancel}
        />
      </DialogTitle>
      <DialogContent style={style.paper}>
        <Typography
          style={style.detailTitle}
        >{`${intl.messages['detail']}`}</Typography>
        <Divider style={{ marginBottom: '28px' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <ToogleForm
                  status={confidential}
                  statusVisible
                  label={`${intl.messages['confidentalAgreement']}`}
                  description={`${
                    intl.messages[
                      confidential
                        ? `${scope}.confidentialOn`
                        : `${scope}.confidentialOff`
                    ]
                  }`}
                  onSwitch={() => {
                    updateFormState('confidential')(!confidential);
                  }}
                />
              </Grid>
              {confidential && !ndaLoading && !ndaError && (
                <Grid item xs={12} sm={4}>
                  <FormSelect
                    onChange={updateFormState('nda')}
                    title={`${intl.messages['NDA']} *`}
                    defaultLabel={`${intl.messages['selectNDA']}`}
                    disableDefault
                    formState={formState.nda || initialSelectState}
                    options={nda.e_sign_template.map(template => ({
                      label: template.name,
                      value: template.esignTemplateId,
                    }))}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ToogleForm
              status={gauge}
              statusVisible
              label={`${intl.messages['gaugeDisplay']}`}
              description={`${
                intl.messages[gauge ? `${scope}.gaugeOn` : `${scope}.gaugeOff`]
              }`}
              onSwitch={() => {
                updateFormState('gauge')(!gauge);
              }}
              checked={gauge}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl
              onChange={updateFormState('dealType')}
              type={'radio'}
              title={`${intl.messages['dealType']}`}
              options={[
                { value: DealType.EQUITY, text: `${intl.messages['equity']}` },
                {
                  value: DealType.LOAN,
                  text: `${intl.messages['loanPortfolios']}`,
                },
                {
                  value: DealType.SAFE,
                  text: `${intl.messages['SAFE']}`,
                },
              ]}
              defaultValue={DealType.EQUITY}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              {LANGUAGES.map(language => (
                <Grid key={`name_${language}`} item xs={12} sm={4}>
                  <FormInput
                    onChange={updateFormState('name', language)}
                    title={`${intl.messages['name']} ${
                      language !== 'en' ? intl.messages[`${language}Abbr`] : ''
                    }${language === 'en' ? ' *' : ''}`}
                    formState={formState.name[language]}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Interests
                  onSelect={updateFormState('interests')}
                  option={preferences}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormDate
                  onChange={updateFormState('openingDate')}
                  title={`${intl.messages['openingDate']}`}
                  futureDate
                  formState={formState.openingDate}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl
                  onChange={toTimeFormat(updateFormState('time'))}
                  title={`${intl.messages['timeUTC']}`}
                  type="timePicker"
                />
              </Grid>
            </Grid>
          </Grid>
          {dealType === DealType.EQUITY && equityLayout}
          {dealType === DealType.SAFE && safeLayout}
          {dealType === DealType.LOAN && loanLayout}
          {LANGUAGES.map(language => (
            <Grid key={`summary_${language}`} item xs={12} sm={12}>
              <Typography>
                {`${intl.messages['summary']}`}{' '}
                {language !== 'en' && (
                  <>
                    {' '}
                    <FormattedMessage id={`${language}Abbr`} />
                  </>
                )}
              </Typography>
              <TextArea
                rowsMin={3}
                style={{
                  width: '100%',
                  padding: '6px',
                }}
                maxLength={4000}
                onChange={e =>
                  updateFormState('summary', language)(e.target.value)
                }
              />
            </Grid>
          ))}
          {LANGUAGES.map(language => (
            <Grid
              key={`additional_${language}`}
              item
              xs={12}
              sm={12}
              container
              spacing={3}
            >
              <Additional
                onChange={updateFormState('additionalList', language)}
                languageCode={language}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <UploadDocuments
              title={`${intl.messages['attachments']}`}
              onChange={fileIds => updateFormState('attachments')(fileIds)}
              document={document}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: '28px' }}>
            <Grid container>
              <Grid item xs={6} sm={6} />
              <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  size={'small'}
                  style={{ marginRight: 24 }}
                  color="primary"
                  variant="outlined"
                  onClick={onCancel}
                >
                  {`${intl.messages['cancel']}`.toUpperCase()}
                </Button>
                <Button
                  style={{
                    backgroundColor: 'var(--color-dark-purple)',
                    color: 'var(--color-light-grey)',
                  }}
                  size={'small'}
                  variant="contained"
                  onClick={onCreate}
                >
                  {`${intl.messages['create']}`.toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {ndaLoading && <Loading fullScreen />}
    </Dialog>
  );
}

export default memo(DealCreator);
