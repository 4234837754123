import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import DocumentRequest from '../../models/request/document-request';
import DocumentResponse from '../../models/response/document-response';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function uploadDocuments(
  batch: DocumentRequest[],
): Promise<DocumentResponse[]> {
  return instance
    .post('/documents', batch)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getAllDocumentsByUserId(
  userId: string,
): Promise<DocumentResponse[]> {
  return instance
    .get(`/documents/users/${userId}`)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}

export async function getAllDocumentsByVehicleId(
  vehicleId: string,
): Promise<DocumentResponse[]> {
  return instance
    .get(`/documents/vehicles/${vehicleId}`)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}

export async function getAllDocumentsGroupByDealId(
  dealId: string,
): Promise<DocumentResponse[]> {
  return instance
    .get(`/documents/groups/for-deals/${dealId}/public`)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}

export async function createDocument(data: {
  description?: string;
  documentGroupId: string;
  title: string;
  type?: string;
}): Promise<any> {
  return instance
    .post('/documents', data)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}

export async function deleteDocument(id: string) {
  return instance
    .delete(`/documents/${id}`)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}

export async function updateDocument(
  documentId: string,
  data: {
    description?: string;
    documentGroupId?: string;
    title: string;
    type?: string;
  },
): Promise<any> {
  return instance
    .put(`/documents/${documentId}`, data)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}

export async function getCompanyDocuments(companyId: string): Promise<any> {
  return instance
    .get(`/documents/groups/for-company/${companyId}`)
    .then((resp: AxiosResponse<RestResult<DocumentResponse[]>>) => {
      return resp.data.data;
    });
}