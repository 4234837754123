/*
 *
 * InvestorDocuments reducer
 *
 */
import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  statistic: {},
  documentGroups: {},
  loading: false,
};

const investorDocumentsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.GET_NUMBER_OF_DOCUMENTS_SUCCEED:
        draft.statistic = action.payload || {};
        break;
      case ActionTypes.GET_DOCUMENTS_SUCCEED:
        draft.documentGroups = action.payload || {};
        break;
      case ActionTypes.REFRESH_ALL_SIGNATURES_SUCCEED:
        draft.documentGroups.signatures = action.payload || [];
        draft.loading = false;
        break;
      case ActionTypes.REFRESH_ALL_SIGNATURES:
        draft.loading = true;
        break;
      case ActionTypes.REFRESH_SIGNATURE:
        draft.loading = true;
        break;
      case ActionTypes.SET_LOADING:
        draft.loading = action.payload;
        break;
      case ActionTypes.REFRESH_SIGNATURE_SUCCEED:
        draft.loading = false;
        const { signatures } = draft.documentGroups;
        if (signatures) {
          for (let i = 0; i < signatures.length; i++) {
            if (signatures.documentId === action.payload.documentId) {
              draft.documentGroups.signatures[i] = action.payload;
              break;
            }
          }
        }
        break;
    }
  },
  initialState,
);

export default investorDocumentsReducer;
