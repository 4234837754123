import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getVehiclesToSign } from 'services/api/vehicle-service';
import { searchTemplate } from 'services/api/sign-template';
import { requestSigning, getPreviewUrl } from 'services/api/sign-document';
import { showToast } from 'containers/App/actions';
import {
  getDocuments,
  getNumberOfDocuments,
} from 'containers/AdminDocuments/actions';
import {
  searchVehiclesSucceed,
  searchTemplatesSucceed,
  requestSignSucceed,
  getPreviewSucceed,
} from './actions';
import ActionTypes from './constants';
import { TEMPLATE_CATEGORY } from 'utils/constants';

function* searchVehicles(action) {
  try {
    const resp = yield call(
      getVehiclesToSign,
      action.payload.dealId,
      action.payload.request,
    );
    yield put(searchVehiclesSucceed(resp));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* getTemplate() {
  try {
    const templates = yield call(searchTemplate, {
      categories: TEMPLATE_CATEGORY.map(item => item.value),
      statuses: ['ACTIVE'],
    });
    yield put(searchTemplatesSucceed(templates.data));
  } catch (err) {
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* requestSign(action) {
  try {
    yield call(requestSigning, action.payload);
    yield put(requestSignSucceed());
    yield put(
      getDocuments({
        type: 'SIGNATURE',
        dealId: action.payload.dealId,
      }),
    );
    yield put(getNumberOfDocuments(action.payload.dealId));
  } catch (err) {
    yield put(requestSignSucceed());
    let errorMessage;
    if (err.response.errors && err.response.errors.length) {
      errorMessage = err.response.errors[0].message || 'unexpectedError';
    } else {
      errorMessage = err.response.data.messages[0] || 'unexpectedError';
    }
    yield put(
      showToast({
        message: errorMessage,
        type: 'error',
      }),
    );
  }
}

function* getPreview(action) {
  try {
    const url = yield call(getPreviewUrl, action.payload);
    yield put(getPreviewSucceed());
    const a = document.createElement('a');
    a.href = url;
    a.target = 'blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (err) {
    yield put(getPreviewSucceed());
    yield put(
      showToast({
        message: 'SERVER_ERROR',
        type: 'error',
      }),
    );
  }
}

// Individual exports for testing
export default function* signRequestSaga() {
  yield takeLatest(ActionTypes.SEARCH_VEHICLES, searchVehicles);
  yield takeLatest(ActionTypes.SEARCH_TEMPLATES, getTemplate);
  yield takeLatest(ActionTypes.REQUEST_SIGN, requestSign);
  yield takeLatest(ActionTypes.GET_PREVIEW, getPreview);
}
