/*
 *
 * InvestorDetail actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const fetchUserAction = payload =>
  action(ActionTypes.FETCH_USER_ACTION, payload);

export const fetchUserSuccessAction = payload =>
  action(ActionTypes.FETCH_USER_SUCCESS_ACTION, payload);

export const changeStatusAction = payload =>
  action(ActionTypes.CHANGE_STATUS_ACTION, payload);
export const changeStatusSuccessAction = payload =>
  action(ActionTypes.CHANGE_STATUS_SUCCESS_ACTION, payload);
export const changeStatusFailedAction = payload =>
  action(ActionTypes.CHANGE_STATUS_FAILED_ACTION, payload);

export const fetchStatisticAction = payload =>
  action(ActionTypes.FETCH_INVESTOR_STATISTIC, payload);
export const fetchStatisticSuccessAction = payload =>
  action(ActionTypes.FETCH_INVESTOR_SUCCESS_STATISTIC, payload);
