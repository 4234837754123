import { UpdateDealRequest } from '../../models/request/deal-request';
import { gql } from '@apollo/client';

export function createUpdateDealMutation(dealRequest: UpdateDealRequest) {
  // console.log(dealRequest);

  const mutation = `
    mutation UpdateDeal {
      update_deal_by_pk(
        pk_columns: { id: "${dealRequest.dealData.id}" }
        _set: {
          isConfidentialAgreement: ${Boolean(
            dealRequest.dealData.isConfidentialAgreement,
          )}
          tokeny_link: "${dealRequest.dealData.tokeny_link}"
          nda: ${
            dealRequest.dealData.nda ? `"${dealRequest.dealData.nda}"` : null
          }
          esignTemplateId: ${
            dealRequest.dealData.esignTemplateId
              ? `"${dealRequest.dealData.esignTemplateId}"`
              : null
          }
          isGaugeDisplay: ${Boolean(dealRequest.dealData.isGaugeDisplay)}
          dealType: "${dealRequest.dealData.dealType}"
          openingDate: ${
            dealRequest.dealData.openingDate
              ? `"${dealRequest.dealData.openingDate}"`
              : null
          }
          totalSizeRound: ${dealRequest.dealData.totalSizeRound ?? null}
          netProfit: ${
            !dealRequest.dealData.netProfit
              ? null
              : dealRequest.dealData.netProfit
          }
          forecastNetProfit: ${
            !dealRequest.dealData.forecastNetProfit
              ? null
              : dealRequest.dealData.forecastNetProfit
          }
          minTicket: ${dealRequest.dealData.minTicket ?? null}
          maxTicket: ${dealRequest.dealData.maxTicket ?? null}
          refClosingDate: "${dealRequest.dealData.refClosingDate ?? null}"
          premoneyValuation: ${dealRequest.dealData.premoneyValuation ?? null}
          loanInterest: ${
            dealRequest.dealData.loanInterest
              ? `"${dealRequest.dealData.loanInterest}"`
              : null
          }
          valuationCap: ${
            dealRequest.dealData.valuationCap
              ? `"${dealRequest.dealData.valuationCap}"`
              : null
          }
          valuationFloor: ${
            dealRequest.dealData.valuationFloor
              ? `"${dealRequest.dealData.valuationFloor}"`
              : null
          }
          discount: ${
            dealRequest.dealData.discount
              ? `"${dealRequest.dealData.discount}"`
              : null
          }
          modifiedBy: "${dealRequest.userId}"
        }
      ) {
        id
      }
      delete_deal_preference_tag(where: { dealId: { _eq: "${
        dealRequest.dealData.id
      }"}}) {
        returning {
          id
        }
      }
      ${dealRequest.dealPreferenceTagIds
        .map((preferenceId, index) => {
          return `
          insertDealPreferenceTag_${index}: insert_deal_preference_tag(
            objects: {
              dealId: "${dealRequest.dealData.id}",
              preferenceTagId: "${preferenceId}"
              createdBy: "${dealRequest.userId}",
              modifiedBy: "${dealRequest.userId}"
            }
          ) {
            returning { id }
          }`;
        })
        .join('\n')}
      delete_deal_additional_detail(where: {deal_translation: {dealId: {_eq: "${
        dealRequest.dealData.id
      }"}}}) {
         returning {
           id
         }
      }
      delete_deal_translation(where: {dealId: {_eq: "${
        dealRequest.dealData.id
      }"}}) {
        returning {
          id
        }
      }
      ${dealRequest.translationsToInsert
        .map((translation, index) => {
          return `
          insertTranslation_${index}: insert_deal_translation_one(
            object: {
              dealId: "${dealRequest.dealData.id}",
              name: ${translation.name ? `"${translation.name.replace(/"/g, '\\"')}"` : '""'}
              description: ${
                translation.description
                  ? `"${translation.description.replace(/"/g, '\\"')}"`
                  : null
              }
              summaryTitle: ${
                translation.summaryTitle
                  ? `"${translation.summaryTitle.replace(/"/g, '\\"')}"`
                  : null
              }
              summary: ${
                translation.summary ? `"${translation.summary.replace(/"/g, '\\"')}"` : null
              },
              languageCode: "${translation.languageCode}",
              deal_additional_details: { data: [
                ${translation.deal_additional_details.data
                  .map(additional => {
                    return `{title: "${additional.title}", summary: "${additional.summary}"}`;
                  })
                  .join('\n')}
              ]}
            }
          ) { id }`;
        })
        .join('\n')}
    }
  `;

  // console.log(mutation);
  return gql`
    ${mutation}
  `;
}
