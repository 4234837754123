/*
 *
 * ProfileEditDialog constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ProfileEditDialog/DEFAULT_ACTION',
  SUBMIT_ACTION = 'app/ProfileEditDialog/SUBMIT_ACTION',
  UPDATE_SUCCESS_ACTION = 'app/ProfileEditDialog/UPDATE_SUCCESS_ACTION',
  UPDATE_FAILED_ACTION = 'app/ProfileEditDialog/UPDATE_FAILED_ACTION',
}

export default ActionTypes;
