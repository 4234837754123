import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import NotificationEvent from 'models/notification-event';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL);

export async function submitEvent(
  data: NotificationEvent,
): Promise<any> {
  return instance
    .post('/notifications/create', data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function getNotificationTypes(): Promise<any> {
  return instance
    .get('/notifications/types')
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}
