import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Dialog, DialogTitle, Grid, IconButton, DialogContent } from '@material-ui/core';
import { scope } from 'components/Table/messages';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';

interface Document {
    uri: string;
    name: string;
    type: string;
}

interface Props {
    isOpen: boolean;
    onClose: () => void;
    documents: Document[];
}

const DocViewerModal = (props: Props) => {

  const { isOpen, onClose, documents } = props;

  return (
    <>
      <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      PaperProps={{ square: true }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <FormattedMessage id={`documentViewerModal.viewwDocument`} />
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DocViewer documents={documents} />
      </DialogContent>
    </Dialog>
    </>
  );
};

export default DocViewerModal;