/*
 *
 * DealList
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Typography, LinearProgress } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table, { HeaderProps } from 'components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import Deal from 'models/deal';
import Status from 'components/Status';
import DealHeader from './header';
import TicketCreate from 'containers/TicketCreate';
import Vehicle from 'models/vehicle';
import { fetchStatisticAction } from 'containers/InvestorDetail/actions';

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
    border: '1px solid #E5E5E5',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 8,
  },
  container: {
    padding: 12,
  },
  image: {
    width: 25,
    height: 25,
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  descriptionText: {
    textAlign: 'left',
    marginTop: 8,
  },
  title: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
    minWidth: 70,
  },
  tableCell30: {
    width: '30%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell20: {
    width: '20%',
  },
}));

interface Props {
  investorId: string;
  vehicleList?: Vehicle[];
  items: Deal[];
  total: number;
  fetchPage?: (page: number) => void;
}

function DealTable(props: Props) {
  const intl = useIntl();
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items, total, fetchPage, vehicleList, investorId } = props;
  const [open, setOpen] = React.useState(false);
  const [dealItem, setDealItem] = React.useState<Deal | undefined>(undefined);

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(page);
    }
  };
  const headers: HeaderProps[] = [
    { name: `${intl.messages['dealName']}`, headerClass: classes.tableCell20 },
    {
      name: `${intl.messages['companyName']}`,
      headerClass: classes.tableCell20,
    },
    { name: `${intl.messages['dealType']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['status']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['progress']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['actions']}`, headerClass: classes.tableCell15 },
  ];

  const viewDetail = id => {
    dispatch(push(`/deals/${id}`));
  };

  const renderItem = (item: Deal, index: number) => {
    return [
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        <Typography
          className={classes.title}
          onClick={ev => viewDetail(item.dealId)}
        >
          {item.name}
        </Typography>
        <Typography>
          <NumberFormat
            value={item.totalSizeRound ? item.totalSizeRound : 0}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        <Typography>{item.company ? item.company.name : ''}</Typography>
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        <Typography>{item.dealType}</Typography>
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index}>
        <Status name={item.status} />
        {/* <Typography className={statusClass(item.status)}>
          {item.status}
        </Typography> */}
        <Typography>{item.refClosingDate || ''}</Typography>
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index} style={{ minWidth: 150 }}>
        <LinearProgress
          variant="determinate"
          value={Math.min(
            ((item.fundTransferredTicketsTotalAmount || 0) /
              (item.totalSizeRound || 1)) *
              100,
            100,
          )}
        />
        <Typography className={classes.descriptionText}>
          {Math.round(
            ((item.fundTransferredTicketsTotalAmount || 0) /
              (item.totalSizeRound || 1)) *
              100,
          )}
          % -{' '}
          <NumberFormat
            value={
              item.fundTransferredTicketsTotalAmount
                ? item.fundTransferredTicketsTotalAmount
                : 0
            }
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />{' '}
          - {item.fundTransferredTicketsCount}{' '}
          <FormattedMessage id={`tickets`} />
        </Typography>
      </div>,
      // tslint:disable-next-line: jsx-wrap-multiline
      <div key={index} style={{ minWidth: 150 }}>
        <Button
          size={'small'}
          variant="contained"
          color="primary"
          style={{ margin: 8 }}
          disabled={item.status !== 'OPEN'}
          onClick={() => {
            setDealItem(item);
            setOpen(true);
          }}
        >
          {`${intl.messages['invest']}`.toUpperCase()}
        </Button>
      </div>,
    ];
  };

  const updateSuccess = () => {
    setOpen(false);
    dispatch(fetchStatisticAction(investorId));
  };
  return (
    <div>
      <TicketCreate
        open={open}
        onClose={() => setOpen(false)}
        deal={dealItem}
        updateSuccess={updateSuccess}
        userVehicles={vehicleList}
      />
      <DealHeader noOfDeal={total} />
      <Table
        headers={headers}
        items={items}
        renderItem={renderItem}
        fetchDataForPage={handleFetchPage}
        totalElements={total}
      />
    </div>
  );
}

export default memo(DealTable);
