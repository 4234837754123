import { gql } from '@apollo/client';

export interface InsertCompanyData {
  company_translations: {
    data: {
      name: string;
      description: string;
      languageCode: string;
    }[];
  };
  pathPicture: string;
  website: string;
  createdBy?: string;
  modifiedBy?: string;
  document_groups?: InsertDocumentGroupData;
}

export interface InsertDocumentGroupData {
  data: {
    documentGroup: {
      data: {
        subType: string;
        title: string;
        type: string;
        isDefault: boolean;
        isPublic: boolean;
        createdBy: string;
        modifiedBy: string;
      };
    };
  }[];
}

export const DEFAULT_COMPANY_DOCUMENT_GROUPS = {
  data: [
    {
      documentGroup: {
        data: {
          subType: 'COMPANY',
          title: 'Company documents',
          type: 'DOCUMENT',
          isDefault: true,
          createdBy: '00000000-0000-0000-0000-000000000003',
          modifiedBy: '00000000-0000-0000-0000-000000000003',
        },
      },
    },
    {
      documentGroup: {
        data: {
          subType: 'COMPANY',
          title: 'Company reports',
          type: 'REPORT',
          isDefault: true,
          createdBy: '00000000-0000-0000-0000-000000000003',
          modifiedBy: '00000000-0000-0000-0000-000000000003',
        },
      },
    },
    {
      documentGroup: {
        data: {
          subType: 'COMPANY',
          title: 'Company documents',
          type: 'DOCUMENT',
          isDefault: true,
          isPublic: false,
          createdBy: '00000000-0000-0000-0000-000000000003',
          modifiedBy: '00000000-0000-0000-0000-000000000003',
        },
      },
    },
    {
      documentGroup: {
        data: {
          subType: 'COMPANY',
          title: 'Company reports',
          type: 'REPORT',
          isDefault: true,
          isPublic: false,
          createdBy: '00000000-0000-0000-0000-000000000003',
          modifiedBy: '00000000-0000-0000-0000-000000000003',
        },
      },
    },
  ],
} as InsertDocumentGroupData;

export interface UpdateCompanyData {
  id: string;
  website: string;
  modifiedBy: string;
  pathPicture: string;
}

export interface UpdateCompanyTranslationData {
  languageCode: string;
  name?: string;
  description?: string;
}
