/*
 *
 * Home reducer
 *
 */

import { ContainerState, ContainerActions } from './types';
import ActionTypes from './constants';
import { produce, Draft } from 'immer';

// The initial state of the App
export const initialState: ContainerState = {
  userVehicles: [],
  error: {
    vehicle: undefined,
    investorType: undefined,
    ticketAmount: undefined,
    isValid: true,
  },
};

// Take this container's state (as a slice of root state), this container's actions and return new state
const ticketCreateReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.SUBMIT_ACTION:
        draft.loading = true;
        break;
      case ActionTypes.SUBMIT_SUCCESS_ACTION:
        draft.success = true;
        draft.loading = false;
        break;
      case ActionTypes.FETCH_USER_VEHICLE_SUCCESS_ACTION:
        draft.userVehicles = action.payload;
        break;
      case ActionTypes.SUBMIT_FAILED_ACTION:
        draft.loading = false;
        break;
      case ActionTypes.CLEAR_ACTION:
        draft.success = undefined;
        draft.error.vehicle = undefined;
        draft.error.investorType = undefined;
        draft.error.ticketAmount = undefined;
        draft.error.isValid = true;
        break;
      case ActionTypes.VALIDATE_TICKET:
        draft.error.vehicle = undefined;
        draft.error.investorType = undefined;
        draft.error.ticketAmount = undefined;
        draft.error.isValid = true;
        if (
          !action.payload.amount ||
          `${action.payload.amount}`.trim().length === 0 ||
          action.payload.amount < action.payload.minValue ||
          action.payload.amount > action.payload.maxValue
        ) {
          draft.error.ticketAmount = [
            getErrorMessage('ticket.amount.invalid.null'),
          ];
          draft.error.isValid = false;
        }
        break;
      default:
        break;
    }
  },
  initialState,
);

const getErrorMessage = (code: string): any => {
  switch (code) {
    case 'ticket.amount.invalid.null':
      return 'invalidAmount';
  }
  return 'invalidData';
};

export default ticketCreateReducer;
