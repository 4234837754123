import React, { memo, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { createStructuredSelector } from 'reselect';

import { makeSelectCurrentUser } from 'containers/App/selectors';
import BannerEditorFields from './BannerEditorFields';
import BannerEditorHighlights from './BannerEditorHighlights';
import PopupTitle from 'components/PopupTitle';
import { useIntl } from 'react-intl';
import { emailToInvestors, uploadBannerLogo } from '../actions';
import UpdateBannerMutation from 'gql/banner/UpdateBanner.gql';
import { LANGUAGES } from 'utils/constants';

const stateSelector = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

interface Props {
  onCancel: () => void;
  onSave: (message) => void;
  onError: (message) => void;
  setOpen: (boolean) => void;
  dealId?: string;
  open: boolean;
  status?: string;
  banner: any;
}

function renderFile(logoUrl, mimeType) {
  if (logoUrl) {
    if (mimeType) {
      const mType = mimeType.toLowerCase();
      if (
        mType === 'image/jpeg' ||
        mType === 'image/jpg' ||
        mType === 'image/png' ||
        mType === 'image/svg+xml'
      ) {
        return (
          <img
            style={{
              width: '50px',
              height: '50px',
              marginRight: '10px',
            }}
            src={logoUrl}
            alt={logoUrl}
          />
        );
      } else {
        return (
          <video width="400" controls>
            <source src={logoUrl} type={mType} />
            Your browser does not support HTML5 video.
          </video>
        );
      }
    } else {
      const splittedBanner = logoUrl?.split(/[#?]/)[0];
      const type = splittedBanner
        ?.split('.')
        ?.pop()
        ?.trim().toLowerCase();
      if (
        type &&
        type !== 'jpeg' &&
        type !== 'jpg' &&
        type !== 'png' &&
        type !== 'svg+xml'
      ) {
        return (
          <video width="400" controls>
            <source src={logoUrl} type={'video/' + type} />
            Your browser does not support HTML5 video.
          </video>
        );
      } else {
        return (
          <img
            style={{
              width: '50px',
              height: '50px',
              marginRight: '10px',
            }}
            src={logoUrl}
            alt={logoUrl}
          />
        );
      }
    }
  }
  return <div />;
}

function BannerEditor(props: Props) {
  const intl = useIntl();
  const {
    onCancel,
    onSave,
    onError,
    dealId,
    open,
    status,
    setOpen,
    banner,
  } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(stateSelector);
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const [mimeType, setMimeType] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState({});
  const [summary, setSummary] = useState({});
  const [highlights, setHighlights] = useState({});
  const [sendMail, setSendMail] = useState(true);
  const [logo, setLogo] = useState<any>(null);
  const bannerTranslation = [];

  const [updateBanner] = useMutation(UpdateBannerMutation);

  const loadData = () => {
    if (logoUrl === undefined && banner.url) {
      setLogoUrl(banner.url);
    }

    LANGUAGES.forEach(language => {
      setTitle(prevState => {
        return {
          ...prevState,
          [language]: bannerTranslation[language][0]?.title,
        };
      });
      setSummary(prevState => {
        return {
          ...prevState,
          [language]: bannerTranslation[language][0]?.summary,
        };
      });
      setHighlights(prevState => {
        return {
          ...prevState,
          [language]: bannerTranslation[language][0]?.banner_highlights,
        };
      });
    });
  };

  useEffect(() => {
    if (banner) {
      LANGUAGES.forEach(language => {
        bannerTranslation[language] =
          banner?.banner_translations &&
          banner.banner_translations.filter(item => {
            return item.languageCode === language;
          });
      });

      loadData();
    }
  }, [open]);

  let hiddenFileInput;

  const handleLogoChange = ev => {
    const file = ev.target.files[0];
    setLogoUrl(URL.createObjectURL(file));
    setLogo(file);
    setMimeType(file.type.toLowerCase());
  };

  const handleLogoRemove = () => {
    setLogoUrl('');
    setLogo(null);
  };

  const onChangeHighlight = (key, index, language) => event => {
    const newValue = { ...highlights };
    newValue[language] = [...newValue[language]];
    newValue[language][index] = { ...newValue[language][index] };
    newValue[language][index][key] = event.target.value;
    setHighlights(newValue);
  };

  const onRemoveForm = (index, language) => () => {
    const newValue = { ...highlights };
    newValue[language] = [...newValue[language]];
    newValue[language].splice(index, 1);
    setHighlights(newValue);
  };

  const addNewHighlight = language => {
    const newValue = { ...highlights };
    newValue[language] = newValue[language] || [];
    newValue[language] = [...newValue[language], { title: '', summary: '' }];
    setHighlights(newValue);
  };

  const onChangeTitle = (language, value) => {
    const newValue = { ...title };
    newValue[language] = value;
    setTitle(newValue);
  };

  const onChangeSummary = (language, value) => {
    const newValue = { ...summary };
    newValue[language] = value;
    setSummary(newValue);
  };

  const handelSave = () => {
    const banner_translations: any[] = [];

    LANGUAGES.forEach(language => {
      const newTranslation: any = {
        languageCode: language,
        summary: summary[language] || '',
        title: title[language] || '',
      };
      if (highlights[language]) {
        newTranslation.banner_highlights = {
          data: highlights[language].map(highlight => {
            return {
              title: highlight.title,
              summary: highlight.summary,
            };
          }),
        };
      }
      banner_translations.push(newTranslation);
    });

    const result = updateBanner({
      variables: {
        dealId: dealId,
        url: logoUrl,
        modifiedBy: user?.userId,
        createdBy: user?.userId,
        translations: banner_translations,
      },
    });

    result
      .then(banner => {
        dispatch(
          uploadBannerLogo({
            bannerId: banner.data.insert_banner_one.id,
            logo,
          }),
        );

        if ((status === 'OPEN' || status === 'PREVIEW') && sendMail) {
          dispatch(emailToInvestors({ dealId }));
        }

        onSave(`${intl.messages[`DealEditor.editDealSucceed`]}`);
        setOpen(false);
      })
      .catch(() => {
        onError('SERVER_ERROR');
      });
  };

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <PopupTitle
                title={`${intl.messages['updateDealBanner']}`}
                onClose={onCancel}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              style={{ marginTop: 10, fontSize: '0.875rem', paddingBottom: 0 }}
            >
              {`${intl.messages['banner']}`}
            </Grid>
            <Grid item xs={12} sm={12}>
              <div>
                {renderFile(logoUrl, mimeType)}
                <input
                  ref={ref => (hiddenFileInput = ref)}
                  type="file"
                  name="file"
                  onChange={handleLogoChange}
                  style={{ display: 'none' }}
                  accept="image/jpeg, image/jpg, image/png, image/svg+xml video/mp4,video/x-m4v,video/*"
                />

                <Button
                  size={'small'}
                  style={{ marginTop: '0px', marginLeft: '2px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => hiddenFileInput.click()}
                >
                  {`${intl.messages['upload']}`.toUpperCase()}
                </Button>

                {logoUrl && (
                  <Button
                    size={'small'}
                    style={{ marginLeft: '10px', backgroundColor: '#D74F4F' }}
                    variant="contained"
                    color="primary"
                    onClick={handleLogoRemove}
                  >
                    {`${intl.messages['delete']}`.toUpperCase()}
                  </Button>
                )}
              </div>
            </Grid>
            {LANGUAGES.map(language => (
              <BannerEditorFields
                key={`field_${language}`}
                languageCode={language}
                onChangeTitle={value => onChangeTitle(language, value)}
                onChangeSummary={value => onChangeSummary(language, value)}
                title={title[language]}
                summary={summary[language]}
              />
            ))}
            <BannerEditorHighlights
              languageCode="en"
              onChangeHighlight={onChangeHighlight}
              onRemoveForm={onRemoveForm}
              addNewHighlight={addNewHighlight}
              highlights={highlights['en']}
            />
            <BannerEditorHighlights
              languageCode="de"
              onChangeHighlight={onChangeHighlight}
              onRemoveForm={onRemoveForm}
              addNewHighlight={addNewHighlight}
              highlights={highlights['de']}
            />
            <BannerEditorHighlights
              languageCode="ja"
              onChangeHighlight={onChangeHighlight}
              onRemoveForm={onRemoveForm}
              addNewHighlight={addNewHighlight}
              highlights={highlights['ja']}
            />
            {(status === 'OPEN' || status === 'PREVIEW') && (
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sendMail}
                      onChange={e => setSendMail(e.currentTarget.checked)}
                    />
                  }
                  label={
                    <Typography>
                      {status === 'OPEN'
                        ? `${intl.messages['sendInfoEmailToAllCommittedInvestors']}`
                        : `${intl.messages['sendInfoEmailToAllInvitedInvestors']}`}
                    </Typography>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} />
            <Grid
              item
              xs={12}
              sm={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                size={'small'}
                style={{ marginRight: 24 }}
                color="primary"
                variant="outlined"
                onClick={onCancel}
              >
                {`${intl.messages['cancel']}`.toUpperCase()}
              </Button>
              <Button
                size={'small'}
                variant="contained"
                color="primary"
                onClick={handelSave}
              >
                {`${intl.messages['update']}`.toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
}

export default memo(BannerEditor);
