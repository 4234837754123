import React, { memo } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { requestDownload } from 'containers/App/actions';
import { useIntl } from 'react-intl';
import SearchInput from 'components/SearchInput';
interface Props {
  title: string;
  buttonTitle: string;
  addNewGroup: () => void;
  dealId: string;
  isPublic: boolean;
  type: string;
  onSearchChange: (value: string) => void;
}

function Header(props: Props) {
  const intl = useIntl();
  const { title, buttonTitle, addNewGroup, isPublic, type, dealId, onSearchChange } = props;
  const dispatch = useDispatch();
  const handleExport = () => {
    dispatch(
      requestDownload({
        dealId: dealId,
        in: [type],
        isPublic: isPublic,
      }),
    );
  };

  return (
    <>
      <Grid container item xs={12} sm={12}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '1rem',
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <SearchInput onChange={(val) => onSearchChange(val)} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'unset', marginRight: 10 }} // marginRight: 10
            onClick={addNewGroup}
          >
            {`${intl.messages['newGroup']}`}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'unset' }}
            onClick={handleExport}
          >
            {buttonTitle}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(Header);
