import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the registerInvestments state domain
 */

const selectRegisterInvestmentsDomain = (state: ApplicationRootState) => {
  return state.registerInvestments || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by RegisterInvestments
 */

const makeSelectRegisterInvestments = () =>
  createSelector(selectRegisterInvestmentsDomain, substate => {
    return substate;
  });

export default makeSelectRegisterInvestments;
export { selectRegisterInvestmentsDomain };
