import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the uploadDocuments state domain
 */

const selectUploadDocumentsDomain = (state: ApplicationRootState) => {
  return state.uploadDocuments || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by UploadDocuments
 */

const makeSelectUploadDocuments = () =>
  createSelector(selectUploadDocumentsDomain, substate => {
    return substate;
  });

export default makeSelectUploadDocuments;
export { selectUploadDocumentsDomain };
