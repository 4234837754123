import React, { memo } from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  title: string;
  subTitle: string;
}

function Label(props: Props) {
  const { title, subTitle } = props;
  return (
    <>
      <Typography
        style={{
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '14px',
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          color: '#888888',
          fontSize: '0.875rem',
          lineHeight: '14px',
        }}
      >
        {subTitle}
      </Typography>
    </>
  );
}

export default memo(Label);
