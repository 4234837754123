import React from 'react';
import image from '../images/vertical-shot.png';
import { makeStyles,  Container, } from '@material-ui/core';
import {FormattedMessage} from "react-intl";
import CardItems from './CardItems';
const useStyles = makeStyles({
  // CONTAINER STYLE
  cardsContent: {
    color: '#fff',
    marginTop: '10%',
    textAlign: 'center',
    maxWidth: '46rem',
    fontWeight: 400,
    fontStyle: 'normal',
    marginBottom: '7%',
  },

  border: {
    borderBottom: '0.5px solid #333333',
    marginBottom: '5rem',
  },
  container: {
    maxWidth: '100%',
  },
  // IMAGE STYLE

  backgroundImageStyle: {
    // For desktop//
    '@media(min-width:1200px)': {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '40vw',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundImage: `url(${image})`,
      zIndex: 0,
      display: 'inline-block',
    },
    backgroundImage: 'none',
  },
});

function Cards() {
  const classes = useStyles();
  return (
    <div className={classes.container}>

      {/*  IMAGE*/}
      <div
        style={{
          paddingTop: '100px',
          paddingBottom: '100px',
          position: 'relative',
        }}
      >
        <div className={classes.backgroundImageStyle}></div>
        <Container maxWidth="lg">
          <CardItems />
        </Container>
      </div>
    </div>
  );
}

export default Cards;
