import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { map } from 'lodash';
import {
  Divider,
  Grid,
  Dialog,
  Typography,
  Button,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import TextArea from 'components/TextArea';

import ErrorToast from 'components/ErrorToast';
import FormControl from 'components/FormControl';
import NumberForm from 'components/FormControl/number';
import FormInput from 'components/FormControl/input';
import FormDate from 'components/FormControl/datepicker';
import FormSelect from 'components/FormControl/select';
import Loading from 'components/Loading';
import PopupTitle from 'components/PopupTitle';
import ToogleForm from './components/ToogleForm';
import Interests from './components/Interests';
import Additional from './components/Additional';
import { Additional as AdditionalType } from 'models/additional';
import { LANGUAGES } from 'utils/constants';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import style from './styles';
import makeSelectDealEditor from './selectors';
import { getDealDocument } from './actions';
import reducer from './reducer';
import saga from './saga';
import { DealType } from './constants';
import UploadDocuments from 'containers/UploadDocuments';
import { makeSelectPreferences } from 'containers/App/selectors';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import makeSelectUploadDocuments from 'containers/UploadDocuments/selectors';
import Deal from 'models/deal';
import ListDealTranslationsByDealIdQuery from 'gql/deal/ListDealTranslationsByDealId.gql';
import ListEsignTemplatesEditDropdownQuery from 'gql/esignTemplate/ListEsignTemplatesEditDropdown.gql';
import { initialInputState } from '../../components/FormControl/input';
import { initialNumberState } from '../../components/FormControl/number';
import { initialDatePickerState } from '../../components/FormControl/datepicker';
import { initialSelectState } from '../../components/FormControl/select';
import validateForm from './validate';
import { ApolloClient } from '@apollo/client';
import { createUpdateDealMutation } from '../../gql/deal/util';
import InsertDocumentFile from 'gql/document/InsertDocumentFile.gql';
import DeleteDocumentFile from 'gql/document/DeleteDocumentFile.gql';
import { notifyToInvestors } from 'services/api/deal-service';

const stateSelector = createStructuredSelector({
  dealEditor: makeSelectDealEditor(),
  preferences: makeSelectPreferences(),
  uploadDocument: makeSelectUploadDocuments(),
  user: makeSelectCurrentUser(),
});

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  deal: Deal;
  attachments?: any[];
  setOpen: (boolean) => void;
  isOpen: boolean;
  onSuccess: (string) => void;
  onError: (string) => void;
}

function DealEditor(props: Props): JSX.Element {
  useInjectReducer({ key: 'dealEditor', reducer: reducer });
  useInjectSaga({ key: 'dealEditor', saga: saga });
  const dispatch = useDispatch();
  const intl = useIntl();
  const { deal, attachments, setOpen, isOpen, onSuccess, onError } = props;
  const { preferences, uploadDocument, user } = useSelector(stateSelector);
  const { uploading } = uploadDocument;
  const [sendMail, setSendMail] = useState(true);
  const scope = 'DealEditor';
  const [insertDocumentFile] = useMutation(InsertDocumentFile);
  const [deleteDocumentFile] = useMutation(DeleteDocumentFile);

  const initialFormState = {
    confidential: { value: false },
    gauge: { value: true },
    dealType: { value: DealType.EQUITY },
    name: {
      en: initialInputState,
      de: initialInputState,
      ja: initialInputState,
    },
    totalSize: initialNumberState,
    netProfit: initialNumberState,
    forecastNetProfit: initialNumberState,
    minTicket: initialNumberState,
    maxTicket: initialNumberState,
    closingDate: initialDatePickerState,
    premoney: initialNumberState,
    valuationCap: initialNumberState,
    valuationFloor: initialNumberState,
    discount: initialInputState,
    tokeny_link: initialInputState,
    summary: {
      en: initialInputState,
      de: initialInputState,
      ja: initialInputState,
    },
    summaryTitle: {
      en: undefined,
      de: undefined,
      ja: undefined,
    },
    description: {
      en: undefined,
      de: undefined,
      ja: undefined,
    },
    openingDate: initialDatePickerState,
    time: initialInputState,
    loanInterest: initialInputState,
    interests: { value: [] },
    attachments: { value: [] },
    additionalList: {
      en: { value: undefined },
      de: { value: undefined },
      ja: { value: undefined },
    },
    nda: initialSelectState,
  };

  const [formState, setFormState] = useState<any>(initialFormState);

  const {
    loading: ndaLoading,
    error: ndaError,
    data: nda,
    refetch: refetchNda,
  } = useQuery(ListEsignTemplatesEditDropdownQuery, {
    variables: {
      dealId: deal.id,
    },
  });

  const {
    error: dealTranslationError,
    data: translationData,
    refetch: refetchDealTranslation,
  } = useQuery(ListDealTranslationsByDealIdQuery, {
    variables: {
      dealId: deal.id,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      loadDealTranslation(data);
    },
  });

  const initFormState = () => {
    setFormState(initialFormState);
  };

  const updateFormState = (field, subfield = '') => stateValue => {
    const formField = formState[field];
    let newFieldState;

    if (stateValue === undefined || stateValue === null) {
      return;
    }

    if (formField) {
      if (subfield) {
        const newSubfieldValue = { ...formField[subfield], value: stateValue };
        newFieldState = { ...formField, [subfield]: newSubfieldValue };
      } else {
        stateValue = (field !== 'time' && Number(stateValue)) || stateValue;
        newFieldState = { ...formField, value: stateValue };
      }

      setFormState(prevState => ({ ...prevState, [field]: newFieldState }));
    }
  };

  const updateFormStateObject = (field, subfield = '') => newObj => {
    if (subfield) {
      setFormState(prevState => {
        const newSubfieldObj = prevState[field];
        newSubfieldObj[subfield] = newObj;
        return { ...prevState, newSubfieldObj };
      });
    } else {
      setFormState(prevState => ({ ...prevState, [field]: newObj }));
    }
  };

  const loadDeal = () => {
    if (deal.dealId) {
      if (!document) {
        dispatch(getDealDocument(deal.dealId));
      }

      const newState = formState;

      newState.totalSize = { value: deal.totalSizeRound };
      newState.dealType = { value: deal.dealType };
      newState.gauge = { value: deal.isGaugeDisplay };
      newState.confidential = { value: deal.isConfidentialAgreement };
      newState.netProfit = { value: deal.netProfit };
      newState.forecastNetProfit = { value: deal.forecastNetProfit };
      newState.minTicket = { value: deal.minTicket };
      newState.maxTicket = { value: deal.maxTicket };
      newState.closingDate = {
        value: moment(deal.refClosingDate).format('DD-MM-YYYY'),
      };
      newState.premoney = { value: deal.premoneyValuation };
      newState.valuationCap = { value: deal.valuationCap };
      newState.valuationFloor = { value: deal.valuationFloor };
      newState.discount = { value: deal.discount };
      newState.nda = { value: deal.esignTemplate?.esignTemplateId };
      newState.tokeny_link = { value: deal.tokeny_link };
      newState.openingDate = {
        value: deal.openingDate
          ? moment(deal.openingDate).format('DD-MM-YYYY')
          : null,
      };
      newState.time = {
        value: deal.openingDate ? moment(deal.openingDate) : null,
      };
      newState.loanInterest = { value: deal.loanInterest };
      newState.interests = {
        value: deal.dealPreferenceTags
          ? map(
              deal.dealPreferenceTags,
              dealPreferenceTag =>
                dealPreferenceTag.preferenceTag.preferenceTagId,
            )
          : [],
      };
      newState.oldFiles = { value: attachments };
      setFormState(newState);
    }
  };

  const getTranslations = data => {
    const translations = [];

    if (data) {
      LANGUAGES.forEach(language => {
        translations[language] =
          data?.deal_translation &&
          data.deal_translation.filter(item => {
            return item.languageCode === language;
          });
      });
    }

    return translations;
  };

  const getAdditionalDetails = (data, language) => {
    const translations = getTranslations(data);

    if (!translations[language]) {
      return;
    }
    // @ts-ignore
    // eslint-disable-next-line prettier/prettier
    const additionalList = translations[language][0]?.deal_additional_details;
    return additionalList
      ? additionalList.map(item => {
          return {
            key: item.key,
            value: item.value,
          } as AdditionalType;
        })
      : [];
  };

  const loadDealTranslation = data => {
    if (deal.dealId) {
      const translations = getTranslations(data);

      const newState = formState;

      LANGUAGES.forEach(language => {
        newState.name[language] = { value: translations[language][0].name };
        newState.summary[language] = {
          value: translations[language][0].summary,
        };
        newState.summaryTitle[language] =
          translations[language][0].summaryTitle;
        newState.description[language] = translations[language][0].description;
        newState.additionalList[language] = {
          value: getAdditionalDetails(data, language),
        };
      });

      setFormState(newState);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadDeal();
      refetchNda();
      refetchDealTranslation();
    }
  }, [isOpen]);

  const confidential = formState.confidential.value;
  const gauge = formState.gauge.value;
  const dealType = formState.dealType.value;

  const onCancel = () => {
    initFormState();
    setOpen(false);
  };

  const onCreate = async () => {
    const request = validateForm(
      formState,
      updateFormStateObject,
      deal.dealId,
      user?.userId,
    );
    if (request) {
      const mutation = createUpdateDealMutation(request);
      const result = await props.client.mutate({
        mutation: mutation,
      });

      if (result.errors) {
        onError(result.errors.map(err => err.message).join('\n'));
      } else {
        const attachment =
          attachments && attachments.length > 0 && attachments[0];

        if (attachment.documentId) {
          // insert new file reference
          formState.attachments.value.forEach(fileId => {
            if (!attachment.files.some(a => a.fileId === fileId)) {
              insertDocumentFile({
                variables: {
                  documentId: attachment.documentId,
                  fileId,
                },
              });
            }
          });
          // delete old file reference
          attachment.files.forEach(file => {
            if (!formState.attachments.value.some(id => id === file.fileId)) {
              deleteDocumentFile({
                variables: {
                  fileId: file.fileId,
                },
              });
            }
          });
        }

        if ((status === 'OPEN' || status === 'PREVIEW') && sendMail) {
          await notifyToInvestors({
            dealId: deal.dealId as string,
            dealUpdate: 'INFO',
          });
        }

        initFormState();
        onSuccess(`${intl.messages[`${scope}.editDealSucceed`]}`);
      }
    }
  };

  const onKeyPressNumber = event => {
    // 48 is '0'
    if (event.charCode < 48) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const onKeyPressDiscount = event => {
    // 46 is .
    if (event.charCode < 48 && event.charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const onInputNumber = event => {
    if (event.target.value.length > 20) {
      event.target.value = event.target.value.slice(0, 20);
    }
  };

  const onInputDiscount = event => {
    if (event.target.value > 100) {
      event.target.value = 100;
    }
  };

  const numberInputProps = {
    onKeyPress: onKeyPressNumber,
    onInput: onInputNumber,
  };

  const discountInputProps = {
    min: 0,
    max: 100,
    onKeyPress: onKeyPressDiscount,
    onInput: onInputDiscount,
  };

  const loanInterestInputProps = {
    min: 0,
    onKeyPress: onKeyPressDiscount,
  };

  const TotalSizeForm = () => {
    return (
      <Grid item xs={12} sm={4}>
        <NumberForm
          onChange={updateFormState('totalSize')}
          title={`${intl.messages['totalSizeOfTheRound']} *`}
          formState={formState.totalSize}
          inputProps={numberInputProps}
        />
      </Grid>
    );
  };

  const MinMaxTicketForm = () => {
    return (
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <NumberForm
              onChange={updateFormState('minTicket')}
              title={
                dealType === DealType.LOAN
                  ? `${intl.messages['minLoan']} *`
                  : `${intl.messages['minTicket']} *`
              }
              formState={formState.minTicket}
              inputProps={numberInputProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberForm
              onChange={updateFormState('maxTicket')}
              title={
                dealType === DealType.LOAN
                  ? `${intl.messages['maxLoan']} *`
                  : `${intl.messages['maxTicket']} *`
              }
              formState={formState.maxTicket}
              inputProps={numberInputProps}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormDate
              onChange={updateFormState('closingDate')}
              title={`${intl.messages['referenceClosingDate']} *`}
              futureDate
              formState={formState.closingDate}
              style={{ marginTop: 2 }}
              disablePast
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const equityLayout = () => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            {TotalSizeForm()}
            <Grid item xs={12} sm={4}>
              <NumberForm
                onChange={updateFormState('netProfit')}
                title={`${intl.messages['netProfit']}`}
                formState={formState.netProfit}
                inputProps={numberInputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberForm
                onChange={updateFormState('forecastNetProfit')}
                title={`${intl.messages['forecastNetProfit']}`}
                formState={formState.forecastNetProfit}
                inputProps={numberInputProps}
              />
            </Grid>
          </Grid>
        </Grid>
        {MinMaxTicketForm()}
        <Grid item xs={12} sm={4}>
          <NumberForm
            onChange={updateFormState('premoney')}
            title={`${intl.messages['premoney']} *`}
            formState={formState.premoney}
            inputProps={numberInputProps}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', marginTop: 2 }}>
            <FormInput
              onChange={updateFormState('tokeny_link')}
              title={`${intl.messages['mandatoryTextFiled']}`}
              formState={formState.tokeny_link}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </Grid>
      </>
    );
  };

  const safeLayout = () => {
    return (
      <>
        {TotalSizeForm()}
        {MinMaxTicketForm()}
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <NumberForm
                onChange={updateFormState('valuationCap')}
                title={`${intl.messages['valuationCap']} *`}
                formState={formState.valuationCap}
                inputProps={numberInputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberForm
                onChange={updateFormState('valuationFloor')}
                title={`${intl.messages['valuationFloor']} *`}
                formState={formState.valuationFloor}
                inputProps={numberInputProps}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div style={{ display: 'flex', marginTop: 2 }}>
                <FormInput
                  onChange={updateFormState('discount')}
                  type={'number'}
                  title={`${intl.messages['discount']}`}
                  formState={formState.discount}
                  inputProps={discountInputProps}
                />
                <p style={{ margin: '22px 0 0 6px' }}>%</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const loanLayout = () => {
    return (
      <>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            {TotalSizeForm()}
          </Grid>
        </Grid>
        {MinMaxTicketForm()}
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', marginTop: 2 }}>
            <FormInput
              onChange={updateFormState('loanInterest')}
              type={'number'}
              title={`${intl.messages['loanInterest']} *`}
              formState={formState.loanInterest}
              inputProps={loanInterestInputProps}
            />
            <p style={{ margin: '22px 0 0 6px' }}>%</p>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <Dialog open={isOpen} maxWidth="xl">
      <ErrorToast
        isOpen={Boolean(dealTranslationError || ndaError)}
        message={
          dealTranslationError
            ? dealTranslationError.message
            : ndaError
            ? ndaError.message
            : ''
        }
      />
      <DialogTitle>
        <PopupTitle
          title={`${intl.messages['updateDeal']}`}
          onClose={onCancel}
        />
      </DialogTitle>
      <DialogContent style={style.paper}>
        <Typography
          style={style.detailTitle}
        >{`${intl.messages['detail']}`}</Typography>
        <Divider style={{ marginBottom: '28px' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <ToogleForm
                  status={confidential}
                  statusVisible
                  label={`${intl.messages['confidentalAgreement']}`}
                  description={`${
                    intl.messages[
                      confidential
                        ? `${scope}.confidentialOn`
                        : `${scope}.confidentialOff`
                    ]
                  }`}
                  onSwitch={() => {
                    updateFormState('confidential')(!confidential);
                  }}
                  checked={Boolean(confidential)}
                  disabled={deal.status !== 'DRAFT'}
                />
              </Grid>
              {confidential && !ndaLoading && !ndaError && (
                <Grid item xs={12} sm={4}>
                  <FormSelect
                    onChange={updateFormState('nda')}
                    title={`${intl.messages['NDA']} *`}
                    defaultLabel={`${intl.messages['selectNDA']}`}
                    disableDefault
                    formState={formState.nda || initialSelectState}
                    options={nda.e_sign_template.map(template => ({
                      label: template.name,
                      value: template.esignTemplateId,
                    }))}
                    disabled={deal.status !== 'DRAFT'}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <ToogleForm
              status={gauge}
              statusVisible
              label={`${intl.messages['gaugeDisplay']}`}
              description={`${
                intl.messages[gauge ? `${scope}.gaugeOn` : `${scope}.gaugeOff`]
              }`}
              onSwitch={() => {
                updateFormState('gauge')(!gauge);
              }}
              checked={Boolean(gauge)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl
              disabled={true}
              onChange={updateFormState('dealType')}
              type={'radio'}
              title={`${intl.messages['dealType']}`}
              options={[
                { value: DealType.EQUITY, text: `${intl.messages['equity']}` },
                {
                  value: DealType.LOAN,
                  text: `${intl.messages['loanPortfolios']}`,
                },
                {
                  value: DealType.SAFE,
                  text: `${intl.messages['SAFE']}`,
                },
              ]}
              defaultValue={deal.dealType || DealType.EQUITY}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              {LANGUAGES.map(language => (
                <Grid key={`name_${language}`} item xs={12} sm={4}>
                  <FormInput
                    onChange={updateFormState('name', language)}
                    title={`${intl.messages['name']} ${
                      language !== 'en' ? intl.messages[`${language}Abbr`] : ''
                    }${language === 'en' ? ' *' : ''}`}
                    formState={formState.name[language]}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Interests
                  onSelect={updateFormState('interests')}
                  option={preferences}
                  defaultValue={
                    deal.dealPreferenceTags
                      ? map(
                          deal.dealPreferenceTags,
                          dealPreferenceTag =>
                            dealPreferenceTag.preferenceTag.preferenceTagId,
                        )
                      : []
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormDate
                  onChange={updateFormState('openingDate')}
                  title={`${intl.messages['openingDate']}`}
                  futureDate
                  formState={formState.openingDate}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl
                  onChange={updateFormState('time')}
                  title={`${intl.messages['timeUTC']}`}
                  type="timePicker"
                  defaultValue={formState.time.value || ''}
                />
              </Grid>
            </Grid>
          </Grid>
          {dealType === DealType.EQUITY && equityLayout()}
          {dealType === DealType.SAFE && safeLayout()}
          {dealType === DealType.LOAN && loanLayout()}
          {LANGUAGES.map(language => (
            <Grid key={`summary_${language}`} item xs={12} sm={12}>
              <Typography>
                {`${intl.messages['summary']}`}{' '}
                {language !== 'en' && (
                  <>
                    {' '}
                    <FormattedMessage id={`${language}Abbr`} />
                  </>
                )}
              </Typography>
              <TextArea
                rowsMin={3}
                style={{
                  width: '100%',
                  padding: '6px',
                }}
                maxLength={4000}
                onChange={e =>
                  updateFormState('summary', language)(e.target.value)
                }
                value={formState.summary[language].value}
              />
            </Grid>
          ))}
          {LANGUAGES.map(language => (
            <Grid
              key={`additional_${language}`}
              item
              xs={12}
              sm={12}
              container
              spacing={3}
            >
              <Additional
                onChange={updateFormState('additionalList', language)}
                defaultValue={getAdditionalDetails(translationData, language)}
                languageCode={language}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <UploadDocuments
              title={`${intl.messages['attachments']}`}
              onChange={fileIds => updateFormState('attachments')(fileIds)}
              document={attachments && attachments[0]}
            />
          </Grid>
          {deal.status === 'PREVIEW' && (
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={sendMail}
                    onChange={e => setSendMail(e.currentTarget.checked)}
                  />
                }
                label={
                  <Typography>
                    {`${intl.messages['sendInfoEmailToInvestor']}`}
                  </Typography>
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} style={{ marginTop: '28px' }}>
            <Grid container>
              <Grid item xs={6} sm={6} />
              <Grid item xs={6} sm={6} style={{ textAlign: 'right' }}>
                <Button
                  size={'small'}
                  style={{ marginRight: 24 }}
                  color="primary"
                  variant="outlined"
                  onClick={onCancel}
                >
                  {`${intl.messages['cancel']}`.toUpperCase()}
                </Button>
                <Button
                  size={'small'}
                  color="primary"
                  variant="contained"
                  onClick={onCreate}
                  disabled={uploading > 0}
                >
                  {`${intl.messages['update']}`.toUpperCase()}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {ndaLoading && <Loading fullScreen />}
    </Dialog>
  );
}

export default DealEditor;
