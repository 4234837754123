/*
 *
 * CompanyTickets actions
 *
 */

import { action } from 'typesafe-actions';
import * as types from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const fetchTicketAction = payload =>
  action(ActionTypes.FETCH_TICKET_ACTION, payload);
export const fetchTicketSuccessAction = payload =>
  action(ActionTypes.FETCH_TICKET_SUCCESS_ACTION, payload);
