/*
 *
 * RegisterInvestments reducer
 *
 */

import { initialChipsState } from './components/chips';
import produce, { Draft } from 'immer';
import { ContainerActions, ContainerState } from './types';
import ActionTypes from './constants';

export const initialState: ContainerState = {
  locationChips: null,
  sectorChips: null,
  stageChips: null,
  formState: {
    userId: undefined,
    locationPreferences: initialChipsState,
    sectorPreferences: initialChipsState,
    stagePreferences: initialChipsState,
  },
};

const registerInvestmentsReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.UPDATE_STATE_INVESTMENT_ACTION:
        const states = action.payload;
        if (states && states.length > 0) {
          for (const state of states) {
            draft[state.field] = state.state;
          }
        }
        break;
      case ActionTypes.UPDATE_FORM_STATE_INVESTMENT_ACTION:
        const formStates = action.payload;
        if (formStates && formStates.length > 0) {
          for (const state of formStates) {
            draft.formState[state.field] = state.state;
          }
        }
        break;
      case ActionTypes.UPDATE_INVESTMENT_SUCCESS_ACTION:
        draft.updateSuccess = true;
        break;
      case ActionTypes.UPDATE_INVESTMENT_ACTION:
        draft.updateSuccess = false;
        break;
      case ActionTypes.GET_USER_PREFERENCES_SUCCESS:
        draft.formState['locationPreferences'].ids = action.payload['LOCATION']
          ? action.payload['LOCATION'].map(val => {
              return val.preferenceTagId;
            })
          : [];
        const sectors = action.payload['SECTOR']
          ? action.payload['SECTOR'].map(val => {
              return val.preferenceTagId;
            })
          : [];
        draft.formState['sectorPreferences'].ids = sectors;
        draft.formState['stagePreferences'].ids = action.payload['STAGE']
          ? action.payload['STAGE'].map(val => {
              return val.preferenceTagId;
            })
          : [];
        break;
      default:
        break;
    }
  },
  initialState,
);

export default registerInvestmentsReducer;
