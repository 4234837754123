import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the invites state domain
 */

const selectInvitesDomain = (state: ApplicationRootState) => {
  return state.invites || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Invites
 */

const makeSelectInvites = () =>
  createSelector(selectInvitesDomain, substate => {
    return substate;
  });

export default makeSelectInvites;
export { selectInvitesDomain };
