/*
 *
 * DealList
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Typography, LinearProgress } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table, { HeaderProps } from 'components/Table';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import Deal from 'models/deal';
import Status from 'components/Status';
import DealHeader from './header';

const useStyles = makeStyles(() => ({
  searchBox: {
    width: '100%',
    marginTop: 12,
    marginBottom: 12,
    border: '1px solid #E5E5E5',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 8,
  },
  card: {
    border: '1px solid #E5E5E5',
    borderRadius: 0,
  },
  container: {
    padding: 12,
  },
  image: {
    width: 25,
    height: 25,
  },
  centerText: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  descriptionText: {
    textAlign: 'left',
    marginTop: 8,
  },
  title: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
    minWidth: 70,
  },
  tableCell30: {
    width: '30%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell20: {
    width: '20%',
  },
}));

interface Props {
  items: Deal[];
  total: number;
  fetchPage?: (page: number) => void;
  companyId: string;
  loading: boolean;
  error: boolean;
  addNewDeal: (companyId: string) => void;
}

function DealTable(props: Props) {
  const intl = useIntl();
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items, total, fetchPage, companyId, addNewDeal } = props;

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(page);
    }
  };
  const headers: HeaderProps[] = [
    { name: `${intl.messages['dealName']}`, headerClass: classes.tableCell20 },
    {
      name: `${intl.messages['companyName']}`,
      headerClass: classes.tableCell20,
    },
    { name: `${intl.messages['dealType']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['status']}`, headerClass: classes.tableCell15 },
    { name: `${intl.messages['progress']}`, headerClass: classes.tableCell30 },
  ];

  const viewDetail = id => {
    dispatch(push(`/deals/${id}`));
  };

  const renderItem = (item: Deal, index: number) => {
    return [
      <div key={index}>
        <Typography
          className={classes.title}
          onClick={() => viewDetail(item.dealId)}
        >
          {item.name}
        </Typography>
        <Typography>
          <NumberFormat
            value={item.totalSizeRound ? item.totalSizeRound : 0}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
      </div>,
      <div key={index}>
        <Typography>{item.company ? item.company.name : ''}</Typography>
      </div>,
      <div key={index}>
        <Typography>{item.dealType}</Typography>
      </div>,
      <div key={index}>
        <Status name={item.status} />
        <Typography>
          {item?.refClosingDate
            ? moment(item.refClosingDate).format('DD-MM-YYYY')
            : ''}
        </Typography>
      </div>,
      <div key={index} style={{ minWidth: 150 }}>
        <LinearProgress
          variant="determinate"
          value={Math.min(
            ((item.fundTransferredTicketsTotalAmount || 0) /
              (item.totalSizeRound || 1)) *
              100,
            100,
          )}
        />
        <Typography className={classes.descriptionText}>
          {Math.round(
            ((item.fundTransferredTicketsTotalAmount || 0) /
              (item.totalSizeRound || 1)) *
              100,
          )}
          % -{' '}
          <NumberFormat
            value={
              item.fundTransferredTicketsTotalAmount
                ? item.fundTransferredTicketsTotalAmount
                : 0
            }
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />{' '}
          - {item.fundTransferredTicketsCount} {`${intl.messages['tickets']}`}
        </Typography>
      </div>,
    ];
  };
  return (
    <div>
      <DealHeader
        noOfDeal={total}
        companyId={companyId}
        addNewDeal={addNewDeal}
      />
      {!props.loading && !props.error && (
        <Table
          headers={headers}
          items={items}
          renderItem={renderItem}
          fetchDataForPage={handleFetchPage}
          totalElements={total}
        />
      )}
    </div>
  );
}

export default memo(DealTable);
