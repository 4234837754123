import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the dealDetail state domain
 */

const selectDealDetailDomain = (state: ApplicationRootState) => {
  return state.dealDetail || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by DealDetail
 */

const makeSelectDealDetail = () =>
  createSelector(selectDealDetailDomain, substate => {
    return substate;
  });

export default makeSelectDealDetail;
export { selectDealDetailDomain };
