import produce, { Draft } from 'immer';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: undefined,
  investor: undefined,
  changeStatusSuccess: undefined,
};

const investorDetailReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.FETCH_USER_SUCCESS_ACTION:
        draft.investor = action.payload;
        break;
      case ActionTypes.CHANGE_STATUS_ACTION:
        draft.changeStatusSuccess = false;
        break;
      case ActionTypes.CHANGE_STATUS_SUCCESS_ACTION:
        draft.changeStatusSuccess = true;
        break;
      case ActionTypes.FETCH_INVESTOR_SUCCESS_STATISTIC:
        draft.statistic = action.payload;
        break;
    }
  },
  initialState,
);

export default investorDetailReducer;
