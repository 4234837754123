// tslint:disable: object-literal-key-quotes

import React, { memo } from 'react';
import {
  Typography,
  Grid,
  Link,
  makeStyles,
  AccordionSummary,
} from '@material-ui/core';
import styled from 'styled-components';
import ArrowRight from '@material-ui/icons/ArrowRight';
import NumberLabel from 'components/NumberLabel';
import { useIntl } from 'react-intl';

const GroupName = styled(Typography)`
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 0.875rem;
  font-weight: 500 !important;
  margin: 0;
`;

interface Props {
  title: string;
  addButtonTitle?: string;
  removeVisible?: boolean;
  documents: number;
  onRemove?: () => void;
  addDocument?: () => void;
  signature?: boolean;
  expanded?: boolean;
  onRefresh?: () => void;
}

const useStyle = makeStyles({
  root: { padding: '0 18px', height: 64 },
  expandIcon: {
    position: 'absolute',
    left: 4,
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    marginLeft: '17px !important',
  },
  expanded: {},
  textButton: {
    fontSize: '0.875rem',
  },
  textButtonSecond: {
    fontSize: '0.875rem',
    color: '#888888',
  },
  textButtonDisable: {
    fontSize: '0.875rem',
    color: '#DEDEDE',
  },
});

function GroupHeader(props: Props) {
  const intl = useIntl();
  const {
    title,
    addButtonTitle,
    removeVisible,
    documents,
    onRemove,
    addDocument,
    signature,
    expanded,
    onRefresh,
  } = props;
  const classes = useStyle();
  return (
    <AccordionSummary
      classes={{
        root: classes.root,
        expandIcon: classes.expandIcon,
        content: classes.content,
        expanded: classes.expanded,
      }}
      expandIcon={<ArrowRight style={{ color: '#588FDB' }} />}
    >
      <Grid
        item
        xs={12}
        sm={8}
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <GroupName>{title}</GroupName>
        {documents > 0 && <NumberLabel label={documents} />}
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'grid', justifyContent: 'flex-end' }}
      >
        {!signature && (
          <>
            <Link
              className={classes.textButton}
              onClick={e => {
                if (addDocument) {
                  addDocument();
                }
                e.stopPropagation();
              }}
              onFocus={e => e.stopPropagation()}
            >
              {addButtonTitle}
            </Link>
            {removeVisible && (
              <Link
                className={classes.textButtonSecond}
                onClick={e => {
                  if (onRemove) {
                    onRemove();
                  }
                  e.stopPropagation();
                }}
                onFocus={e => e.stopPropagation()}
              >
                {`${intl.messages['remove']}`}
              </Link>
            )}
          </>
        )}
        {/* Temporarily hide because of unspecified functionality */}
        {/* {signature && (
          <Link
            className={
              expanded ? classes.textButton : classes.textButtonDisable
            }
            onFocus={e => e.stopPropagation()}
            onClick={e => {
              if (onRefresh) {
                onRefresh();
              }
              e.stopPropagation();
            }}
            underline={expanded ? 'hover' : 'none'}
          >
            Refresh all
          </Link>
        )} */}
      </Grid>
    </AccordionSummary>
  );
}

export default memo(GroupHeader);
