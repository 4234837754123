import React, { memo } from 'react';
import { FormHelperText } from '@material-ui/core';
import { FormState } from '../state';
import { useIntl } from 'react-intl';

interface Props {
  formState: FormState;
}

CustomHelperText.defaultProps = {};

function CustomHelperText(props: Props) {
  const intl = useIntl();

  const { formState } = props;
  return (
    <div>
      {formState && formState.helperMessage && (
        <FormHelperText error={formState.error}>
          {`${intl.messages[`error.${formState.helperMessage}`]}`}
        </FormHelperText>
      )}
    </div>
  );
}

export default memo(CustomHelperText);
