import React, { memo } from 'react';
import { get } from 'lodash/fp';
import moment from 'moment';
import { Typography, Grid, Link, Divider, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { TEMPLATE_CATEGORY } from 'utils/constants';
import { refreshSignature } from '../actions';
import { SignatureDocument } from '../types';
import { requestDownload } from 'containers/App/actions';
import { FormattedMessage, useIntl } from 'react-intl';
interface Props {
  signatures: SignatureDocument[];
  dealId?: string;
}

const getStatus = status => {
  switch (status) {
    case 'SIGNED':
      return (
        <Typography style={{ color: '#6DB595', fontWeight: 500 }}>
          <FormattedMessage id="signed" />
        </Typography>
      );
    default:
      return (
        <Typography style={{ color: '#588FDB', fontWeight: 500 }}>
          <FormattedMessage id="inProcess" />
        </Typography>
      );
  }
};

const templateType = TEMPLATE_CATEGORY.reduce((mapAccumulator, obj) => {
  mapAccumulator.set(obj.value, obj.label);
  return mapAccumulator;
}, new Map());

const useStyle = makeStyles({
  header: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '10px',
    color: '#888888',
  },
  docName: {
    fontWeight: 500,
    fontSize: '0.875rem',
    color: '#588FDB',
  },
  docNameString: {
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  files: {
    display: 'grid',
  },
  file: {
    fontWeight: 300,
    fontSize: '0.8rem',
  },
  divider: {
    margin: '10px 0px 0px',
    background: '#F6F6F6',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '0.8rem',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0px !important',
  },
  requestSign: {
    fontSize: '0.875rem',
    paddingRight: 10,
  },
  refresh: {
    fontSize: '0.875rem',
    paddingRight: 0,
  },
});

function SignatureTable(props: Props) {
  const intl = useIntl();
  const { signatures, dealId } = props;
  const classes = useStyle();
  const dispatch = useDispatch();

  const onRefresh = id => {
    dispatch(refreshSignature(id));
  };

  const onViewFile = sign => {
    if (sign.files && sign.files.length) {
      dispatch(
        requestDownload({
          fileIds: [sign.files[0].fileId],
          dealId: dealId,
        }),
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={4} sm={4} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['name']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['type']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['status']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2} style={{ paddingLeft: 4 }}>
        <Typography className={classes.header}>
          {`${intl.messages['lastUpdated']}`.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2}>
        <Typography className={classes.header}>{''}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Divider className={classes.divider} />
      </Grid>
      {signatures.map((sign, i) => (
        <Grid
          item
          xs={12}
          sm={12}
          container
          key={i}
          style={{ margin: '10px 0' }}
          spacing={2}
        >
          <Grid item xs={4} sm={4}>
            {get('esignDocument.status')(sign) === 'SIGNED' ? (
              <Link
                className={classes.docName}
                onClick={() => onViewFile(sign)}
              >
                {sign.title}
              </Link>
            ) : (
              <Typography className={classes.docNameString}>
                {sign.title}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} sm={2}>
            <Typography>
              {templateType.get(
                get('esignDocument.esignTemplate.category')(sign),
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2}>
            {getStatus(get('esignDocument.status')(sign))}
          </Grid>
          <Grid item xs={4} sm={2}>
            <Typography>
              {moment(get('esignDocument.modifiedDate')(sign)).format(
                'DD-MM-YYYY',
              )}
            </Typography>
          </Grid>
          {/* Temporarily hide because of unspecified functionality */}
          {/* {get('esignDocument.status')(sign) === 'IN_PROCESS' && (
            <Grid item xs={4} sm={2} className={classes.flexEnd}>
              <Link
                className={classes.refresh}
                onClick={() => onRefresh(sign.documentId)}
              >
                Refresh
              </Link>
            </Grid>
          )} */}
        </Grid>
      ))}
      {!signatures.length && (
        <Typography
          style={{ textAlign: 'center', padding: '20px 0', width: '100%' }}
        >
          <FormattedMessage id="noDocumentsToDisplay" />
        </Typography>
      )}
    </Grid>
  );
}

export default memo(SignatureTable);
