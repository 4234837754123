/*
 *
 * DealList
 *
 */

import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Card, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/styles';
import SearchInput from 'components/SearchInput';
import Table, { HeaderProps } from 'components/Table';
import Company from 'models/company';
import CompanyHeader from './header';
import { FormattedMessage, useIntl } from 'react-intl';
import Img from 'components/Img';
import NumberFormat from 'react-number-format';
import NoAvatar from 'images/not-found.png';
import { useMutation } from '@apollo/react-hooks';
import DeleteCompany from '../../hasura/gql/DeleteCompany.gql';

const useStyles = makeStyles(() => ({
  searchBox: {
    width: '100%',
    marginBottom: 12,
  },
  buttonContainer: {
    textAlign: 'right',
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    marginRight: '10px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  linkText: {
    fontWeight: 'bold',
    color: 'var(--color-purple)',
  },
  tableCell30: {
    width: '30%',
  },
  tableCell15: {
    width: '15%',
  },
  tableCell25: {
    width: '25%',
  },
}));

interface Props {
  loading: boolean;
  error: boolean;
  items: Company[];
  total: number;
  languageCode: string;
  fetchPage?: (searchKey: string, page: number) => void;
  openCreateDialog: (item) => void;
  addNewDeal: (companyId?: string) => void;
  scope: string;
  onCompanyDelete: () => void;
  currentPage: number;
}

function CompanyList(props: Props) {
  const intl = useIntl();
  // Warning: Add your key to RootState in types/index.d.ts file
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteCompany] = useMutation(DeleteCompany);
  const {
    items,
    total,
    fetchPage,
    openCreateDialog,
    addNewDeal,
    scope,
    onCompanyDelete,
    currentPage
  } = props;
  const [searchKey, setSearchKey] = React.useState('');

  const onChange = value => {
    setSearchKey(value);
    if (fetchPage) {
      fetchPage(value, 1);
    }
  };

  const handleFetchPage = (page: number) => {
    if (fetchPage) {
      fetchPage(searchKey, page);
    }
  };
  const headers: HeaderProps[] = [
    {
      name: `${intl.messages['companyName']}`,
      headerClass: classes.tableCell30,
    },
    { name: `${intl.messages['numberOfDeals']}` },
    {
      name: `${intl.messages['totalRaised']}`,
      headerClass: classes.tableCell15,
    },
    { name: `${intl.messages['uniqueInvestor']}` },
    { name: '', headerClass: classes.tableCell30 },
  ];

  const handleNameClick = item => {
    dispatch(push(`/companies/${item.companyId}`));
  };

  const localScope = `${scope}.companyList`;

  const renderItem = (item: Company, index: number) => {
    return [
      <div key={index}>
        <Grid item xs container spacing={2}>
          <Grid item>
            {item.pathPicture ? (
              <Img src={item.pathPicture} className={classes.image} />
            ) : (
              <Img src={NoAvatar} className={classes.image} />
            )}
          </Grid>
          <Grid item xs>
            <Typography
              className={classes.linkText}
              onClick={() => handleNameClick(item)}
            >
              {item.name.get(props.languageCode)}
            </Typography>
          </Grid>
        </Grid>
      </div>,
      <div key={index}>
        <Typography>{item.noOfDeal}</Typography>
      </div>,
      <div key={index}>
        <Typography>
          <NumberFormat
            value={item.totalRaised ? item.totalRaised : 0}
            thousandSeparator={true}
            prefix={'$'}
            displayType={'text'}
          />
        </Typography>
      </div>,
      <div key={index}>
        <Typography className={classes.boldText}>
          {item.uniqueInvestor ? item.uniqueInvestor : 0}
        </Typography>
      </div>,
      <div key={index}>
        <Grid container direction="row">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 8, marginBottom: 8, whiteSpace: 'nowrap' }}
              onClick={() => openCreateDialog(item)}
            >
              <FormattedMessage id={`${localScope}.editCompany`} />
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              variant="outlined"
              style={{ whiteSpace: 'nowrap' }}
              color="secondary"
              onClick={() => addNewDeal(item.companyId)}
            >
              <FormattedMessage id={`${localScope}.addNewDeal`} />
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              //color="secondary"
              style={{
                marginRight: 8,
                marginBottom: 8,
                whiteSpace: 'nowrap',
                backgroundColor: '#b8372e',
                color: '#fff',
              }}
              onClick={async () => {
                const isDeletable = await window.confirm(
                  'Are you sure you want to delete this company?\nThis action cannot be undone.',
                );
                if (isDeletable) {
                  await deleteCompany({
                    variables: {
                      id: item.companyId,
                    },
                  });
                  onCompanyDelete();
                }
              }}
            >
              <FormattedMessage id={`${localScope}.deleteCompany`} />
            </Button>
          </Grid>
        </Grid>
      </div>,
    ];
  };
  return (
    <div>
      <div className={classes.searchBox}>
        <SearchInput onChange={onChange} />
      </div>

      <div>
        <CompanyHeader handleCreate={openCreateDialog} noOfCompany={total} />
        {!props.loading && !props.error && (
          <Table
            headers={headers}
            items={items}
            renderItem={renderItem}
            fetchDataForPage={handleFetchPage}
            currentPage={currentPage}
            totalElements={total}
          />
        )}
      </div>
    </div>
  );
}

export default memo(CompanyList);
