/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Switch, Route } from 'react-router-dom';
import NotFoundPage from 'containers/NotFoundPage/Loadable';

import GlobalStyle from '../../global-styles';
import Authentication from 'containers/Authentication';
import Home from 'containers/Home';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import { MuiThemeProvider, Snackbar } from '@material-ui/core';
import { getMuiTheme } from 'styles/theme';
import Alert from '@material-ui/lab/Alert';
import { createStructuredSelector } from 'reselect';
import { makeSelectError, makeSelectDownloading, makeSelectPreviewDocuments, makeSelectShowDocViewer, makeSelectPreviewDocumentsLoading } from './selectors';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { showToast, getCountries, handleToggleDocViewer } from './actions';
import saga from './saga';
import Register from 'containers/Register';
import deactivated from 'containers/Authentication/components/deactivated';
import DownloadPopup from 'components/DownloadPopup';
import { useIntl } from 'react-intl';
import DocViewerModal from 'components/DocViewerModal';

const stateSelector = createStructuredSelector({
  error: makeSelectError(),
  downloading: makeSelectDownloading(),
  previewDocumentsLoading: makeSelectPreviewDocumentsLoading(),
  previewDocuments: makeSelectPreviewDocuments(),
  showDocViewer: makeSelectShowDocViewer()
});

export default function App(): JSX.Element {
  const intl = useIntl();
  useInjectSaga({ key: 'global', saga: saga });

  const theme = getMuiTheme();
  const { error, downloading, previewDocumentsLoading, previewDocuments, showDocViewer } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(showToast(undefined));
  };

  const handleDocViewerClose = () => {
    dispatch(handleToggleDocViewer(false));
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Helmet titleTemplate="JADESQUARE" defaultTitle="JADESQUARE">
        <meta
          name="description"
          content={`${intl.messages['joinJadeSquareNow']}`}
        />
      </Helmet>
      <Switch>
        <Route exact={true} path="/login" component={Authentication} />
        <Route exact={true} path="/deactivated" component={deactivated} />
        <Route exact={true} path="/register" component={Register} />
        <Route
          exact={true}
          path="/forgot-password"
          component={ForgotPassword}
        />
        <Route exact={true} path="/password-reset" component={ResetPassword} />
        <Route path="/" component={Home} />
        <Route path="" component={NotFoundPage} />
      </Switch>
      <Snackbar
        open={error ? true : false}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {error && (
          <Alert
            severity={error ? error.type : undefined}
            onClose={handleClose}
          >
            {error
              ? error.type === 'success'
                ? intl.messages[`${error.message}`]
                : intl.messages[`error.${error.message}`]? intl.messages[`error.${error.message}`]: error.message
              : ''}
          </Alert>
        )}
      </Snackbar>
      <Snackbar
        open={Boolean(downloading)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <DownloadPopup total={downloading} />
      </Snackbar>
      <Snackbar
        open={Boolean(previewDocumentsLoading)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <DownloadPopup isPreview={true}/>
      </Snackbar>
      <DocViewerModal isOpen={showDocViewer} onClose={handleDocViewerClose} documents={previewDocuments} />
      <GlobalStyle />
    </MuiThemeProvider>
  );
}
