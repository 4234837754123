import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the profileVehicleCreateDialog state domain
 */

const selectProfileVehicleCreateDialogDomain = (
  state: ApplicationRootState,
) => {
  return state.profileVehicleCreateDialog || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProfileVehicleCreateDialog
 */

const makeSelectProfileVehicleCreateDialog = () =>
  createSelector(selectProfileVehicleCreateDialogDomain, substate => {
    return substate;
  });

export default makeSelectProfileVehicleCreateDialog;
export { selectProfileVehicleCreateDialogDomain };
