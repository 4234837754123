/*
 *
 * Home
 *
 */

import React, { memo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginBottom: 12 },
    title: {
        color: '#888888',
        fontSize: '0.85rem',
        margin: theme.spacing(2, 0),
    },
    titleAmount: {
      fontWeight: 600,
      color: 'var(--color-purple)',
    },
  }),
);

interface Props {
  handleCreate: (item) => void;
  noOfCompany: number;
}

function CompanyHeader(props: Props) {
  const { handleCreate, noOfCompany } = props;
  const classes = useStyles();
  const localScope = 'Companies.header';
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={true}>
          <Typography className={classes.title}>
            <FormattedMessage id={`thereAreCurrently`} />
            <span className={classes.titleAmount}>
              {noOfCompany}{' '}
              <FormattedMessage
                id={`${localScope}.company`}
                values={{ num: noOfCompany }}
              />
            </span>
          </Typography>
        </Grid>
        <Grid item xs={'auto'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreate(undefined)}
          >
            <FormattedMessage id={`${localScope}.createNewCompany`} />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(CompanyHeader);
