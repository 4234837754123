import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const createDealGroup = () => action(ActionTypes.CREATE_DEAL_GROUP);

export const createDealSuccessGroup = payload =>
  action(ActionTypes.CREATE_DEAL_SUCCESS_GROUP, payload);

export const uploadDocumentAction = payload =>
  action(ActionTypes.UPLOAD_DOCUMENT, payload);
