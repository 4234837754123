import React, { memo } from 'react';
import { FormControl, PropTypes, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormState, initialFormState } from './state';
import CustomFormLabel from './components/form-label';
import CustomHelperText from './components/helper-text';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(() => ({
  errorInput: {
    width: '100%',
    // border: '1px solid #f44336',
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '0!important',
  },
  inputNumber: {
    width: '100%',
    marginTop: '0!important',
  },
  errorText: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.6428571428571428rem',
  },
  wrapper: {
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'top',
  },
}));

export interface InputState extends FormState {
  value: string | undefined | null;
}

export const initialInputState: InputState = {
  value: undefined,
  ...initialFormState,
};

interface Props {
  formState: InputState;
  onChange: (ev) => void;
  placeholder?: string;
  type: string;
  title?: string;
  subtitle?: string;
  margin?: PropTypes.Margin;
  fullWidth: boolean;
  hideTitle?: boolean;
  inputProps?: any;
}

PhoneInput.defaultProps = {
  type: 'text',
  fullWidth: true,
};

function PhoneInput(props: Props) {
  const {
    formState,
    onChange,
    placeholder,
    title,
    subtitle,
    margin,
    fullWidth,
    inputProps,
    hideTitle,
  } = props;

  const classes = useStyles();

  const handleInputChange = ev => {
    onChange(ev.target.value);
  };

  return (
    <FormControl fullWidth={fullWidth} margin={margin} variant={'outlined'}>
      <CustomFormLabel title={title} subtitle={subtitle} hidden={hideTitle} />
      <NumberFormat
        placeholder={placeholder}
        value={formState.value}
        className={`${
          formState.error ? classes.errorInput : classes.inputNumber
        }`}
        onChange={handleInputChange}
        customInput={Input}
        disableUnderline
        inputProps={inputProps}
        required={true}
      />

      <CustomHelperText formState={formState} />
    </FormControl>
  );
}

export default memo(PhoneInput);
