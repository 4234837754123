/*
 *
 * Home
 *
 */

import React, { memo, useRef } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Grid,
  InputBase,
  Typography,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Select from 'components/Select';
import Interests from 'components/Interests';
import A from 'components/A';
import { createStructuredSelector } from 'reselect';
import { makeSelectPreferences } from 'containers/App/selectors';
import { useSelector } from 'react-redux';
import TagsSelector from 'components/TagsSelector';
import { FormattedMessage, useIntl } from 'react-intl';
const stateSelector = createStructuredSelector({
  preferences: makeSelectPreferences(),
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    subTitle: {
      textTransform: 'uppercase',
      fontSize: 10,
    },
    button: {
      textTransform: 'none',
    },
    title: {
      fontSize: '0.875rem',
      margin: theme.spacing(2, 0),
    },
    filterContainer: {
      margin: theme.spacing(0, 1),
      display: 'inline',
      width: 100,
    },
    filter: {
      border: '0.5px solid #BDBFC3',
      ':&selected': {
        border: '0.5px solid #BDBFC3',
      },
    },
    interest: {
      minWidth: 150,
    },
    select: {
      minWidth: 75,
    },
    clearBtn: {
      color: '#D74F4F',
      '&:hover': {
        color: '#B74F4F',
      },
    },
    titleText: {
      padding: 4,
      fontWeight: 'bold',
    },
    container: {
      minWidth: 150,
    },
  }),
);

interface Props {
  onChange: (value, type) => void;
  selected?: [];
}

function FilterInvestor(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const { onChange, selected } = props;
  const [selectedInterest, setSelectedInterest] = React.useState(selected);
  const { preferences } = useSelector(stateSelector);
  const [clearFilter, setClearFilter] = React.useState(false);
  const [selectStatus, setSelectedStatus] = React.useState('All');
  const handleChange = value => {
    setSelectedStatus(value.target.value);
    if (value.target.value === 'All') {
      onChange(undefined, 'status');
    } else {
      onChange(value.target.value, 'status');
    }
  };
  const onSelect = value => {
    setSelectedInterest(value);
    if (value.length > 0) {
      onChange(value, 'interestsAny');
    } else {
      onChange(undefined, 'interestsAny');
    }
  };

  const handleClick = () => {
    setClearFilter(true);
    setSelectedStatus('All');
    onChange(undefined, 'status');
    setSelectedInterest([]);
  };

  const onClear = () => {
    setClearFilter(false);
  };

  return (
    <>
      <Grid container>
        <Grid item container>
          <Grid item xs>
            <Typography className={classes.titleText}>
              <FormattedMessage id="filter" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <A className={classes.clearBtn} onClick={handleClick}>
                <FormattedMessage id="clearFilters" />
              </A>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.root} justify="center">
        <Grid item container xs alignItems="center">
          <Grid item className={classes.container}>
            <Typography className={classes.filterContainer}>
              <FormattedMessage id="status" />
            </Typography>
            <Select
              className={classes.select}
              onChange={handleChange}
              defaultValue="All"
              value={selectStatus}
              items={[
                { value: `${intl.messages['all']}`, key: 'All' },
                { value: `${intl.messages['registered']}`, key: 'REGISTERED' },
                { value: `${intl.messages['active']}`, key: 'ACTIVE' },
                { value: `${intl.messages['inactive']}`, key: 'INACTIVE' },
              ]}
            />
          </Grid>
          <Grid item container xs alignItems="center">
            <Grid item>
              <Typography className={classes.filterContainer}>
                <FormattedMessage id="interest" />
              </Typography>
            </Grid>
            <Grid item xs>
              <TagsSelector
                onSelect={onSelect}
                option={preferences}
                style={{ minWidth: 200 }}
                defaultValue={[]}
                clear={clearFilter}
                onClear={onClear}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(FilterInvestor);
