/*
 *
 * UploadDocuments actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const uploadFileAction = payload =>
  action(ActionTypes.UPLOAD_FILE_ACTION, payload);
export const uploadFileSuccessAction = payload =>
  action(ActionTypes.UPLOAD_FILE_SUCCESS_ACTION, payload);
export const uploadFileFailedAction = payload =>
  action(ActionTypes.UPLOAD_FILE_FAILED_ACTION, payload);
export const removeFileAction = payload =>
  action(ActionTypes.REMOVE_FILE_ACTION, payload);
export const setDocumentAction = payload =>
  action(ActionTypes.SET_DATA_ACTION, payload);
export const clearData = payload => action(ActionTypes.CLEAR_DATA, payload);
