import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PAGE_SIZE } from 'utils/constants';
import Pagination from './pagination';
import { withStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    cellContent: {
      position: 'relative',
      '& p': {
        marginBottom: 0,
      },
    },
    emptyRow: {
      height: 100,
    },
    paging: {
      border: 'none',
    },
    headerCell: {
      color: 'white!important',
      paddingBottom: '24px!important',
      '&::before': {
        bottom: '10px!important',
      },
    },
    rowCell: {
      '&:hover': {},
    },
  }),
);

export interface HeaderProps {
  cell?: any;
  name: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  headerClass?: any;
}

const MuiTableCell = withStyles({
  root: {
    borderBottom: '0',
    position: 'relative',
    color: 'var(--color-grey)',
    fontSize: '0.85rem',
    padding: '14px 14px 18px 14px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: '4px',
      borderTop: '1px solid var(--color-medium-dark-grey)',
      borderBottom: '1px solid var(--color-medium-dark-grey)',
      backgroundColor: 'var(--color-super-dark-grey)',
    },
    '&:first-child': {
      '&::before': {
        borderTopLeftRadius: '3px',
        borderBottomLeftRadius: '3px',
        borderLeft: '1px solid var(--color-medium-dark-grey)',
      },
    },
    '&:last-child': {
      '&::before': {
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        borderRight: '1px solid var(--color-medium-dark-grey)',
      },
    },
  },
})(TableCell);

const MuiTableRow = withStyles({
  root: {
    '&:hover td': {
      '&::before': {
        backgroundColor: '#262626',
      },
    },
  },
})(TableRow);

interface Props {
  headers: HeaderProps[];
  items?: any[];
  totalElements?: number;
  renderItem: (item: any, idx?: number) => any[];
  fetchDataForPage?: (page: number, limit: number) => void;
  refreshData?: boolean;
  filterData?: boolean;
  currentPage?: number;
}
const NUMBER_PAGE_SIZE = PAGE_SIZE;

export default function Table(props: Props): JSX.Element {
  const classes = useStyles();
  const {
    headers,
    items,
    renderItem,
    totalElements = 0,
    fetchDataForPage,
    refreshData = false,
    filterData = false,
    currentPage = 1,
  } = props;

  const [page, setPage] = useState<number>(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(NUMBER_PAGE_SIZE);

  useEffect(() => {
    setPage(1);
  }, [totalElements]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (filterData) {
      setPage(1);
      if (fetchDataForPage) {
        fetchDataForPage(1, rowsPerPage);
      }
    }
  }, [filterData]);

  useEffect(() => {
    if (refreshData) {
      setPage(1);
      if (fetchDataForPage) {
        fetchDataForPage(1, rowsPerPage);
      }
    }
  }, [refreshData]);

  const onChangePage = page => {
    setPage(page);
    if (fetchDataForPage) {
      fetchDataForPage(page, rowsPerPage);
    }
  };
  return (
    <div>
      <TableContainer>
        <MuiTable
          aria-labelledby="tableTitle"
          // size="small"
          aria-label="enhanced table"
        >
          <TableHead>
            <MuiTableRow>
              {headers.map((header, index) => (
                <MuiTableCell
                  key={index}
                  align={'left'}
                  style={{ whiteSpace: 'nowrap' }}
                  className={`${classes.headerCell} ${header.headerClass}`}
                >
                  <span className={classes.cellContent}>
                    {header.cell ? header.cell : header.name.toUpperCase()}
                  </span>
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </TableHead>

          <TableBody>
            {isEmpty(items) ? (
              <MuiTableRow className={classes.emptyRow}>
                <MuiTableCell align="center" colSpan={headers.length}>
                  {isUndefined(items) ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <span className={classes.cellContent}>
                      No records to display
                    </span>
                  )}
                </MuiTableCell>
              </MuiTableRow>
            ) : (
              items!.map((item, index) => (
                <MuiTableRow key={index} className={classes.rowCell}>
                  {renderItem(item, index).map((col, index) => (
                    <MuiTableCell key={index} align={headers[index].align}>
                      <span className={classes.cellContent}>{col}</span>
                    </MuiTableCell>
                  ))}
                </MuiTableRow>
              ))
            )}
          </TableBody>
        </MuiTable>
        {totalElements > 0 && fetchDataForPage && (
          <Pagination
            page={page}
            total={totalElements}
            onChangePage={onChangePage}
          />
        )}
      </TableContainer>
    </div>
  );
}
