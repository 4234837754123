/*
 *
 * InvestorDetail constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InvestorDetail/DEFAULT_ACTION',
  FETCH_USER_ACTION = 'app/InvestorDetail/FETCH_USER_ACTION',
  FETCH_USER_SUCCESS_ACTION = 'app/InvestorDetail/FETCH_USER_SUCCESS_ACTION',
  CHANGE_STATUS_ACTION = 'app/Investors/CHANGE_STATUS_ACTION',
  CHANGE_STATUS_SUCCESS_ACTION = 'app/Investors/CHANGE_STATUS_SUCCESS_ACTION',
  CHANGE_STATUS_FAILED_ACTION = 'app/Investors/CHANGE_STATUS_FAILED_ACTION',
  FETCH_INVESTOR_STATISTIC = 'app/InviteInvestor/FETCH_INVESTOR_STATISTIC',
  FETCH_INVESTOR_SUCCESS_STATISTIC = 'app/InviteInvestor/FETCH_INVESTOR_SUCCESS_STATISTIC',
}

export default ActionTypes;
