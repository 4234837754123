// tslint:disable: object-literal-key-quotes

import React, { memo, useState, useEffect } from 'react';
import { get, flattenDeep } from 'lodash/fp';
import { useDispatch } from 'react-redux';
import {
  Grid,
  makeStyles,
  Select,
  MenuItem,
  Button,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';
import GroupHeader from './GroupHeader';
import SignatureTable from './SignatureTable';
import SignRequest from 'containers/SignRequest';
import { setOpen } from 'containers/SignRequest/actions';
import { Signature } from '../types';
import {
  getDocuments,
  refreshSignDocument,
  refreshSignGroup,
} from '../actions';
import { FormattedMessage } from 'react-intl';

const useStyle = makeStyles({
  root: {
    border: '1px solid #EDEDED',
    boxShadow: '1px 2px 4px rgba(57, 60, 73, 0.1)',
    borderRadius: 'unset',
    marginBottom: 16,
    '&::before': {
      backgroundColor: 'unset',
    },
    minHeight: 64,
  },
  textCenter: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 300,
    fontSize: '0.875rem',
  },
  flexEnd: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
});

interface Props {
  dealId: string;
  signatures: Signature[];
  tabValue: number;
  onRemove: (doc) => void;
}

function Signatures(props: Props) {
  const { dealId, signatures, tabValue, onRemove } = props;
  const classes = useStyle();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState<any>({});
  const [signatureNames, setSignatureNames] = useState<any[]>([]);
  const [filterSignatures, setFilterSignatures] = useState<any[]>(signatures);
  const [name, setName] = useState<any>(0);

  const getSignatureNames = () => {
    const signatureNames = flattenDeep(
      signatures.map(group => group.documents.map(doc => doc.title)),
    );
    return signatureNames.filter((v, i) => signatureNames.indexOf(v) === i);
  };

  useEffect(() => {
    setExpanded({});
  }, [tabValue]);

  useEffect(() => {
    setSignatureNames(getSignatureNames());
    setFilterSignatures(signatures);
    setName(0);
  }, [signatures]);

  useEffect(() => {
    if (name === 0) {
      setFilterSignatures(signatures);
      return;
    }
    const filter = signatures.map(group => ({
      ...group,
      documents: group.documents.filter(doc => doc.title === name),
    }));
    setFilterSignatures(filter.filter(group => group.documents.length));
  }, [name]);

  const requestNewSign = () => {
    dispatch(setOpen(true));
  };

  const onChangeName = e => {
    setName(e.target.value);
  };

  const refreshAll = () => {
    dispatch(getDocuments({ dealId, type: 'SIGNATURE' }));
  };

  const refreshGroup = groupId => {
    dispatch(refreshSignGroup(groupId));
  };

  const refreshDocument = signId => {
    dispatch(refreshSignDocument(signId));
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} container>
        <Grid item xs={12} sm={2} className={classes.textCenter}>
          <FormattedMessage id="documentName" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            defaultValue={0}
            variant="outlined"
            fullWidth
            onChange={onChangeName}
          >
            <MenuItem value={0}>
              <FormattedMessage id="all" />
            </MenuItem>
            {signatureNames.map((name, index) => (
              <MenuItem key={index} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.flexEnd}>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'unset', marginRight: 10 }}
            onClick={requestNewSign}
          >
            <FormattedMessage id="requestNewSigning" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: 'unset' }}
            onClick={refreshAll}
          >
            <FormattedMessage id="refresh" />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        {filterSignatures.map((signature, index) => {
          return (
            <Accordion
              className={classes.root}
              key={index}
              onChange={(e, expand) => {
                setExpanded({
                  ...expanded,
                  [signature.documentGroupId]: expand,
                });
              }}
            >
              <GroupHeader
                title={signature.title}
                documents={get('documents.length')(signature) || 0}
                signature
                expanded={expanded[signature.documentGroupId]}
                onRefresh={() => refreshGroup(signature.documentGroupId)}
              />
              <AccordionDetails style={{ padding: '8px 18px' }}>
                <SignatureTable
                  documents={signature.documents}
                  onRemove={onRemove}
                  onRefresh={refreshDocument}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
      <SignRequest dealId={dealId} />
    </Grid>
  );
}

export default memo(Signatures);
