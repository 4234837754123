import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  status: {
    color: 'var(--color-grey)',
    borderColor: 'var(--color-dark-grey)',
    border: '1px solid',
    padding: '1px 5px',
    borderRadius: '5px',
    fontSize: '10px',
    margin: 0,
  },
  inactive: {},
  active: { color: 'var(--color-cyan)', borderColor: 'var(--color-cyan)' },
  registered: {},
  statusButton: {
    border: '1px solid transparent',
    display: 'inline-block',
    padding: '1px 5px',
    fontSize: '10px',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    verticalAlign: 'middle',
  },
}));
interface Props {
  value?: string;
}

const UserStatus = (props: Props) => {
  const intl = useIntl();
  const { value } = props;
  const classes = useStyles();
  const status = val => {
    if (val) {
      switch (val.toLowerCase()) {
        case 'active':
          return (
            <Typography className={`${classes.status} ${classes.active}`}>
              {intl.messages[`${val.toLowerCase()}`]}
            </Typography>
          );
        case 'registered':
          return (
            <Typography className={`${classes.status} ${classes.registered}`}>
              {intl.messages[`${val.toLowerCase()}`]}
            </Typography>
          );
        default:
          return (
            <Typography className={`${classes.status} ${classes.inactive}`}>
              {intl.messages[`${val.toLowerCase()}`]}
            </Typography>
          );
      }
    }
    return (
      <Typography className={`${classes.status} ${classes.inactive}`}>
        {val}
      </Typography>
    );
  };
  return status(value);
};

export default memo(UserStatus);
