import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the adminDocuments state domain
 */

const selectAdminDocumentsDomain = (state: ApplicationRootState) => {
  return state.adminDocuments || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by AdminDocuments
 */

const makeSelectAdminDocuments = () =>
  createSelector(selectAdminDocumentsDomain, substate => {
    return substate;
  });

export default makeSelectAdminDocuments;
export { selectAdminDocumentsDomain };
