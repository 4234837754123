import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the secondaryMarket state domain
 */

const selectSecondaryMarketDomain = (state: ApplicationRootState) => {
  return state || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by SecondaryMarket
 */

const makeSelectSecondaryMarket = () =>
  createSelector(selectSecondaryMarketDomain, substate => {
    return substate;
  });

export default makeSelectSecondaryMarket;
export { selectSecondaryMarketDomain };
