/*
 *
 * DealFlow constants
 *
 */

enum ActionTypes {
  CANCEL_INVITE = 'app/DealFlow/CANCEL_INVITE',
}

export default ActionTypes;
