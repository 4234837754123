import { RestResult } from 'models/response/rest-result';
import { AxiosResponse } from 'axios';
import { createService } from './axios';
import UploadRequest from '../../models/request/upload-request';
import { File } from '@babel/types';
import { BACKEND_API_URL } from './../../env';

const instance = createService(BACKEND_API_URL, 'multipart/form-data');

export async function uploadSingleFile(
  request: UploadRequest,
): Promise<FileResponse> {
  const data: FormData = new FormData();
  data.append('path', request.path);
  data.append('file', request.files[0]);
  data.append('isPublic', request.isPublic ? 'true' : 'false');
  data.set('name', request.files[0].name);

  return instance
    .post('/file', data)
    .then((resp: AxiosResponse<RestResult<FileResponse>>) => {
      return resp.data.data;
    });
}

export async function uploadFiles(
  request: UploadRequest,
): Promise<FileResponse[]> {
  const data: FormData = new FormData();
  data.append('path', request.path);
  data.append('isPublic', request.isPublic ? 'true' : 'false');
  for (const file of request.files) {
    data.append('files', file);
  }
  return instance
    .post('/file/batch', data)
    .then((resp: AxiosResponse<RestResult<FileResponse[]>>) => {
      return resp.data.data;
    });
}
export async function uploadBannerPicture(
  bannerId: string,
  picture: any,
): Promise<any> {
  const data: FormData = new FormData();
  data.append('picture', picture);
  return instance
    .put(`files/banners/${bannerId}/picture`, data)
    .then((resp: AxiosResponse<RestResult<any>>) => {
      return resp.data.data;
    });
}

export async function uploadUserProfilePicture(
  request: any,
): Promise<FileResponse> {
  const data: FormData = new FormData();
  data.append('picture', request);

  return instance
    .put('/files/user-picture', data)
    .then((resp: AxiosResponse<RestResult<FileResponse>>) => {
      return resp.data.data;
    });
}

export async function uploadCompanyPicture(
  companyId: string,
  picture: any,
): Promise<FileResponse> {
  const data: FormData = new FormData();
  data.append('picture', picture);

  return instance
    .put(`files/companies/${companyId}/picture`, data)
    .then((resp: AxiosResponse<RestResult<FileResponse>>) => {
      return resp.data.data;
    });
}
