import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: ['Hind', 'Roboto'].join(','),
  fontSize: 12,
  fontWeightLight: 300,
  fontWeightRegular: 300,
  fontWeightMedium: 600,
  fontWeightBold: 600,
  body1: {
    fontSize: '0.875rem',
  },
  button: {
    fontWeight: 500,
    letterSpacing: 1,
  },
};

export default typography;
