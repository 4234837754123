/*
 *
 * InviteInvestor
 *
 */

import React, { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CloseIcon from '@material-ui/icons/Close';
import Alert from 'components/AlertDialog/alertDialog';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectInviteInvestor from './selectors';
import reducer from './reducer';
import saga from './saga';
import InviteList from './components/inviteList';
import SearchInput from 'components/SearchInput';
import {
  Checkbox,
  Grid,
  Typography,
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { fetchInvestorsAction, submitDealInvestor } from './actions';
import { PAGE_SIZE } from 'utils/constants';
import InviteInvestor from 'models/invite-investor';
import Deal from 'models/deal';
import { useIntl } from 'react-intl';

const stateSelector = createStructuredSelector({
  inviteInvestor: makeSelectInviteInvestor(),
});

const useStyles = makeStyles(() => ({
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: 12,
    textTransform: 'uppercase',
  },
  border: {
    borderBottom: '1px solid #e7e7e7',
  },
  title: {
    paddingTop: 4,
    fontSize: '1.375rem',
  },
  warning: {
    marginTop: 4,
    color: '#D74F4F',
  },
}));

interface Props {
  deal: Deal;
  interest?: string[];
  open: boolean;
  onClose: () => void;
}

function InviteInvestorDialog(props: Props) {
  useInjectReducer({ key: 'inviteInvestor', reducer: reducer });
  useInjectSaga({ key: 'inviteInvestor', saga: saga });
  const { deal, open, onClose, interest } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const { inviteInvestor } = useSelector(stateSelector);
  const [selectedUser, setSelecteduser] = React.useState<InviteInvestor[]>([]);
  const [searchKey, setSearchKey] = React.useState();
  const [invitedOnly, setInvitedOnly] = React.useState(true);
  const [interestedOnly, setInterestedOnly] = React.useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();

  const onChanged = key => {
    setSearchKey(key);
    dispatch(
      fetchInvestorsAction({
        interestsAny:
          interestedOnly && interest && interest.length > 0
            ? interest
            : undefined,
        dealId: deal.dealId,
        countInvitation: invitedOnly ? 0 : undefined,
        searchKey: key,
        page: 0,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  useEffect(() => {
    if (onClose) {
      onClose();
    }
  }, [inviteInvestor.inviteSuccess]);

  useEffect(() => {
    if (open) {
      dispatch(
        fetchInvestorsAction({
          interestsAny:
            interestedOnly && interest && interest.length > 0
              ? interest
              : undefined,
          dealId: deal.dealId,
          page: 0,
          pageSize: PAGE_SIZE,
        }),
      );
    }
  }, [open]);

  const handleInvite = () => {
    setConfirmOpen(false);
    const request = {
      dealId: deal.dealId,
      userIds: selectedUser.map(val => val.userId),
    };
    dispatch(submitDealInvestor(request));
  };

  const handleSelectUser = (item: InviteInvestor) => {
    const isSelected =
      selectedUser.filter(user => {
        return user.userId === item.userId;
      }).length !== 0;
    if (!isSelected) {
      setSelecteduser(pre => {
        return [...pre, item];
      });
    } else {
      setSelecteduser(pre => {
        return pre.filter(obj => obj.userId !== item.userId);
      });
    }
  };
  const fetchPage = page => {
    dispatch(
      fetchInvestorsAction({
        interestsAny:
          interestedOnly && interest && interest.length > 0
            ? interest
            : undefined,
        dealId: deal.dealId,
        searchKey: searchKey,
        page: page - 1,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  const handleInvitedChange = value => {
    setInvitedOnly(value.target.checked);
    dispatch(
      fetchInvestorsAction({
        interestsAny:
          interestedOnly && interest && interest.length > 0
            ? interest
            : undefined,
        dealId: deal.dealId,
        countInvitation: value.target.checked ? 0 : undefined,
        searchKey: searchKey,
        page: 0,
        pageSize: PAGE_SIZE,
      }),
    );
  };

  const handleInterestChange = value => {
    setInterestedOnly(value.target.checked);
    dispatch(
      fetchInvestorsAction({
        interestsAny:
          value.target.checked && interest && interest.length > 0
            ? interest
            : undefined,
        dealId: deal.dealId,
        countInvitation: invitedOnly ? 0 : undefined,
        searchKey: searchKey,
        page: 0,
        pageSize: PAGE_SIZE,
      }),
    );
  };
  const scope = 'InviteInvestor';
  return (
    <div>
      <Dialog open={open} maxWidth="lg" fullWidth={true}>
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            alignItems="center"
            className={classes.border}
            spacing={2}
          >
            <Grid item xs>
              <Typography className={classes.title}>
                <FormattedMessage id={`${scope}.inviteInvestor`} />
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Typography className={classes.warning}>
            <FormattedMessage
              id={`${scope}.${
                deal.isConfidentialAgreement
                  ? 'dealPrivateWarning'
                  : 'dealPublicWarning'
              }`}
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item style={{ padding: 12 }}>
              <SearchInput onChange={onChanged} />
            </Grid>
            <Grid item>
              <Checkbox
                defaultChecked
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={handleInterestChange}
              />
              <Typography style={{ display: 'inline' }}>
                <FormattedMessage id={`${scope}.showInterestInvestor`} />
              </Typography>
            </Grid>
            <Grid item>
              <Checkbox
                defaultChecked
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={handleInvitedChange}
              />
              <Typography style={{ display: 'inline' }}>
                <FormattedMessage id={`${scope}.showNotInvitedInvestor`} />
              </Typography>
            </Grid>
            <Grid item style={{ padding: 12 }}>
              <InviteList
                items={inviteInvestor.investors}
                total={inviteInvestor.total}
                selected={selectedUser}
                onSelect={handleSelectUser}
                fetchPage={fetchPage}
              />
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={onClose}
              >
                <FormattedMessage id="cancel" />
              </Button>
              <Button style={{backgroundColor:"var(--color-dark-purple)",color:'var(--color-light-grey)'}}
             
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setConfirmOpen(true);
                }}
              >
                <FormattedMessage id="invite" />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Alert
        open={confirmOpen}
        title={`${intl.messages[`${scope}.confirmInvestorInvitation`]}`}
        description={
          <FormattedMessage
            id={`${scope}.inviteUserMsg`}
            values={{ num: selectedUser ? selectedUser.length : 0 }}
          />
        }
        onCancel={() => {
          setConfirmOpen(false);
        }}
        onConfirm={handleInvite}
      />
    </div>
  );
}

export default memo(InviteInvestorDialog);
