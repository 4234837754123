import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import NotFound from 'images/not-found.png';
import styles from '../styles';
import UserData from 'models/user';
import Alert from 'components/AlertDialog/alertDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import UserStatus from 'components/UserStatus';

interface Props {
  investor: UserData;
  onStatusChanged: (value) => void;
  scope: string;
}

function TopBar(props: Props) {
  const { investor, onStatusChanged, scope } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState();

  const showConfirmStatus = status => {
    setSelectedType(status);
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    onStatusChanged(selectedType);
    setConfirmOpen(false);
  };

  const intl = useIntl();

  const localScope = `${scope}.TopBar`;

  return (
    <div>
      <h2 className="h4">
        <FormattedMessage id="investor" />
      </h2>
      <Grid
        container
        spacing={4}
        alignItems="center"
        style={{ marginBottom: '15px' }}
      >
        <Grid item xs style={styles.dealInfo}>
          <img
            src={(investor && investor.picturePath) || NotFound}
            style={styles.companyLogo}
          />
          <span style={{ ...styles.dealName, whiteSpace: 'nowrap' }}>
            {investor ? investor.fullName : ''}
          </span>
          <UserStatus value={investor ? investor.status : ''} />
          {/* <div className={classes.avgTicketContainer}>
              <Typography
                style={{
                  padding: 6,
                  whiteSpace: 'nowrap',
                  minWidth: 100,
                }}
              >
                avg ticket{' '}
                <span className={classes.boldText}>$710,660,0000</span>
              </Typography>
            </div> */}
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            style={{ margin: '0px 8px' }}
            variant="contained"
            color="primary"
            disabled={investor && investor.status === 'ACTIVE'}
            onClick={() => showConfirmStatus('ACTIVE')}
          >
            <FormattedMessage id="activate" />
          </Button>
          <Button
            style={{ margin: '0px 8px' }}
            color="default"
            variant="contained"
            disabled={investor && investor.status === 'INACTIVE'}
            onClick={() => showConfirmStatus('INACTIVE')}
          >
            <FormattedMessage id="deactivate" />
          </Button>
        </Grid>
      </Grid>
      <Alert
        open={confirmOpen}
        title={`${
          intl.messages[
            `${localScope}.${
              selectedType === 'ACTIVE' ? 'activeInvestor' : 'inActiveInvestor'
            }`
          ]
        }`}
        description={
          selectedType === 'ACTIVE' ? (
            <FormattedMessage
              id={`${localScope}.confirmActive`}
              values={{
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          ) : (
            <FormattedMessage
              id={`${localScope}.confirmInActive`}
              values={{
                b: (...chunks) => <b>{chunks}</b>,
              }}
            />
          )
        }
        onCancel={() => {
          setConfirmOpen(false);
        }}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default memo(TopBar);
