import produce, { Draft } from 'immer';
import ActionTypes, { topics } from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  message: '',
  topic: topics[0].value,
  bannerLogo: {},
};

const dealDescriptionReducer = produce(
  (draft: Draft<ContainerState>, action: ContainerActions) => {
    switch (action.type) {
      case ActionTypes.UPLOAD_BANNER_LOGO_SUCCEED:
        draft.bannerLogo = action.payload || {};
        break;
      case ActionTypes.SET_MESSAGE:
        draft.message = action.payload;
        break;
      case ActionTypes.SET_TOPIC:
        draft.topic = action.payload;
        break;
    }
  },
  initialState,
);

export default dealDescriptionReducer;
