import {
  Button,
  FormControl,
  Link,
  Typography,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { useSelector, useDispatch } from 'react-redux';
import {
  uploadFileAction,
  removeFileAction,
  setDocumentAction,
  clearData,
} from './actions';
import { createStructuredSelector } from 'reselect';
import makeSelectUploadDocuments from './selectors';
import Document from 'models/document';
import { MAX_FILE_SIZE } from 'utils/constants';
import { showToast } from 'containers/App/actions';
import { requestDownloadSingleFile } from 'containers/App/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as UploadSvg } from 'images/icons/with-class/upload.svg';
import { sanitizeFileName } from 'utils/helpers';

const stateSelector = createStructuredSelector({
  uploadDocuments: makeSelectUploadDocuments(),
});

interface Props {
  onChange: (fileIds: string[]) => void;
  title?: string;
  subtitle?: string;
  document?: Document;
  disable?: boolean;
  downloadable?: boolean;
  accept?: string;
  fileTypes?: string[];
  onRemove?: (fileId) => void;
}

const useStyles = makeStyles(theme => ({
  uploadBlock: {
    cursor: 'pointer',
    color: 'var(--color-purple)',
    fontSize: '0.75rem',
    fontWeight: 500,
    '&:hover': {
      color: 'var(--color-dark-purple)',
    },
  },
  fileTypes: {
    color: 'var(--color-grey)',
  },
  subtitle: {
    color: 'var(--color-grey)',
  },
}));

function UploadDocuments(props: Props): JSX.Element {
  const intl = useIntl();
  const classes = useStyles();
  useInjectReducer({ key: 'uploadDocuments', reducer: reducer });
  useInjectSaga({ key: 'uploadDocuments', saga: saga });
  const { uploadDocuments } = useSelector(stateSelector);
  const { localFiles } = uploadDocuments;
  const dispatch = useDispatch();
  const {
    onChange,
    title,
    subtitle,
    document,
    disable,
    downloadable,
    accept,
    fileTypes,
    onRemove,
  } = props;
  const [currentDoc, setCurrentDoc] = React.useState<Document | undefined>(
    document,
  );
  let hiddenFileInput;

  useEffect(() => {
    return () => {
      if (currentDoc) {
        dispatch(clearData(currentDoc));
      }
    };
  }, []);
  useEffect(() => {
    if (document) {
      dispatch(setDocumentAction(document));
      setCurrentDoc(document);
    }
  }, [document]);

  useEffect(() => {
    if (currentDoc && !currentDoc.files) {
      currentDoc.files = [];
    }
  }, [currentDoc]);

  useEffect(() => {
    localFiles.forEach(item => {
      if (
        document &&
        item.documentId === document.documentId &&
        item.type === document.type &&
        item.files
      ) {
        onChange(item.files.map(val => val.fileId));
        setCurrentDoc(item);
      }
    });
  }, [localFiles]);

  const handleFileSelect = e => {
    if (e.target.files.length > 0) {
      for (const file of e.target.files) {
        if (file.size > MAX_FILE_SIZE) {
          dispatch(
            showToast({
              message: 'error.maximumFileSizeIs200MB',
              type: 'error',
            }),
          );
          return;
        }
        const newFile = {
          file: file,
          name: sanitizeFileName(file.name),
          localId: `${new Date().getTime()}`,
          success: false,
        };
        dispatch(uploadFileAction({ document: currentDoc, file: newFile }));
      }
    }
    e.target.value = null;
  };

  const handleDeleteFileSelect = fileId => () => {
    dispatch(removeFileAction({ document: currentDoc, fileId: fileId }));
    if (onRemove) {
      onRemove(fileId);
    }
  };

  const handleOnClick = fileId => {
    if (fileId) {
      dispatch(requestDownloadSingleFile(fileId));
    }
  };

  const FileRow = props => {
    return (
      <div>
        {!props.fileId ||
          (!props.downloadable && (
            <Typography style={{ display: 'inline' }}>{props.name}</Typography>
          ))}
        {props.fileId && props.downloadable && (
          <Link
            style={{ display: 'inline', margin: 4 }}
            onClick={() => {
              handleOnClick(props.fileId);
            }}
          >
            {props.name}
          </Link>
        )}
        {!props.loading && (props.success === undefined || props.success) ? (
          <Typography style={{ display: 'inline' }}>
            <Link
              style={{ color: '#D74F4F', padding: '0 0 0 10px' }}
              underline={'none'}
              onClick={handleDeleteFileSelect(props.fileId)}
            >
              <FormattedMessage id="delete" />
            </Link>
          </Typography>
        ) : (
          <CircularProgress
            disableShrink
            style={{ margin: '0 0 0 10px' }}
            size={12}
            thickness={4}
          />
        )}
      </div>
    );
  };

  return (
    <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
      {currentDoc &&
        currentDoc.files &&
        currentDoc.files.map((file, index) => (
          <FileRow
            key={index}
            type={'remote'}
            fileId={file.fileId}
            name={file.name}
            success={file.success}
            downloadable={downloadable}
          />
        ))}

      {currentDoc && currentDoc.files && (
        <>
          <Grid
            container
            spacing={3}
            wrap={'wrap'}
            alignItems={'center'}
            onClick={() => hiddenFileInput.click()}
            className={classes.uploadBlock}
          >
            <Grid item xs={'auto'}>
              <UploadSvg />
            </Grid>
            <Grid item xs={'auto'} md={'auto'}>
              {title}
            </Grid>
            {fileTypes && (
              <Grid item xs={'auto'} md={true} className={classes.fileTypes}>
                ({fileTypes?.join(', ')})
              </Grid>
            )}
          </Grid>
          {subtitle && (
            <div style={{ paddingTop: '10px' }} className={classes.subtitle}>
              {subtitle}
            </div>
          )}
          <input
            ref={ref => (hiddenFileInput = ref)}
            type="file"
            name="file"
            multiple
            accept={accept}
            onChange={handleFileSelect}
            disabled={disable}
            style={{ display: 'none' }}
            required={true}
          />
        </>
      )}
    </div>
  );
}

export default UploadDocuments;
