/**
 *
 * AlertDialog
 *
 */

import React, { useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, Typography, Input, IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import TextArea from 'components/TextArea';
import Company from 'models/company';
import Img from 'components/Img';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from 'components/Loading';
import InsertCompanyMutation from '../hasura/gql/InsertCompany.gql';
import CheckCompanyNameQuery from '../hasura/gql/CheckCompanyName.gql';
import { LANGUAGES } from 'utils/constants';
import ErrorToast from '../../../components/ErrorToast';
import { uploadCompanyPicture } from 'services/api/upload-service';
import {
  InsertCompanyData,
  DEFAULT_COMPANY_DOCUMENT_GROUPS,
  UpdateCompanyData,
  UpdateCompanyTranslationData,
} from '../hasura/model';
import { ApolloClient } from '@apollo/client';
import { createUpdateCompanyMutation } from '../hasura/mapping';

import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useSelector } from 'react-redux';
const stateSelector = createStructuredSelector({
  user: makeSelectCurrentUser(),
});

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    width: '100%',
  },
  container: {},
  title: {
    paddingTop: 4,
    fontSize: '1.2rem',
  },
  titleColor: {},
  image: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  deleteBtn: {
    margin: 8,
  },
  requireText: {
    color: '#D74F4F',
  },
  errorInput: {
    borderColor: ' #D74F4F',
  },
  errorText: {
    color: '#D74F4F',
  },
}));

const ColorButton = withStyles(() => ({
  root: {
    color: '#FFF',
    textTransform: 'uppercase',
    backgroundColor: '#D74F4F',
    '&:hover': {
      backgroundColor: '#D74F4F',
    },
  },
}))(Button);

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>;
  open: boolean;
  title?: string;
  onCancel: () => void;
  onSuccess?: () => void;
  item?: Company;
}

interface FieldErrors {
  url: {
    error: boolean;
    message?: string;
  };
  name: {
    error: boolean;
    message?: string;
  };
  description: {
    error: boolean;
    message?: string;
  };
}

function CompanyCreateDialog(props: Props) {
  const { user } = useSelector(stateSelector);
  const classes = useStyles();
  const intl = useIntl();

  const [url, setUrl] = React.useState<string | undefined>(
    props.item ? props.item.pathPicture : undefined,
  );
  React.useEffect(() => {
    setUrl(props.item?.pathPicture);
  }, [props.item]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<Map<string, string>>(
    new Map<string, string>(),
  );
  const [fieldErrors, setFieldErrors] = React.useState<FieldErrors>({
    url: {
      error: false,
      message: undefined,
    },
    name: {
      error: false,
      message: undefined,
    },
    description: {
      error: false,
      message: undefined,
    },
  });

  React.useEffect(() => {
    setFieldErrors({
      url: {
        error: false,
        message: undefined,
      },
      name: {
        error: false,
        message: undefined,
      },
      description: {
        error: false,
        message: undefined,
      },
    });
  }, [open]);
  const onConfirm = async event => {
    setIsLoading(true);
    const target = event.currentTarget;
    let isValid = true;
    const errors: FieldErrors = {
      url: {
        error: false,
        message: undefined,
      },
      name: {
        error: false,
        message: undefined,
      },
      description: {
        error: false,
        message: undefined,
      },
    };
    setFieldErrors(errors);

    const nameErrorList = new Map<string, string>();
    for (const language of LANGUAGES) {
      const name = target.form[`name_${language}`].value.trim();
      if (language === 'en' && !name) {
        // nameErrorList.set(
        //   language,
        //   intl.messages['error.nameRequired'] as string,
        // );
        isValid = false;
        errors.name.error = true;
        errors.name.message = intl.messages['error.nameRequired'] as string;
        break;
      }

      if (name) {
        const result = await props.client.query({
          query: CheckCompanyNameQuery,
          fetchPolicy: 'no-cache',
          variables: {
            name: name,
            languageCode: language,
          },
        });

        if (result.error) {
          setErrorMessage('SERVER_ERROR');
          setShowError(true);
        }

        const nameCheckData = result.data;
        if (
          nameCheckData &&
          nameCheckData.company.length &&
          nameCheckData.company[0].id !== props.item?.companyId
        ) {
          // nameErrorList.set(
          //   language,
          //   intl.messages['error.nameExisted'] as string,
          // );
          errors.name.error = true;
          errors.name.message = intl.messages['error.nameExisted'] as string;
          isValid = false;
        }
      }
    }
    for (const language of LANGUAGES) {
      const description = target.form[`description_${language}`].value.trim();
      if (language === 'en' && !description) {
        isValid = false;
        errors.description.error = true;
        errors.description.message = intl.messages[
          'error.descRequired'
        ] as string;
        break;
      }
    }
    // const website = target.form['siteUrl'].value.trim();
    // if (!website) {
    //   errors.url.error = true;
    //   errors.url.message = intl.messages['error.urlRequired'] as string;
    //   isValid = false;
    // }
    if (isValid) {
      if (nameErrorList.size == 0 && !showError) {
        if (props.item?.companyId) {
          await modifyCompany(props.item?.companyId, target);
        } else {
          await addCompany(target);
        }
      }
    }
    setFieldErrors(errors);
    setIsLoading(false);
  };

  const addCompany = async target => {
    const translations: {
      name: string;
      description: string;
      languageCode: string;
    }[] = [];
    LANGUAGES.forEach(language => {
      const name = target.form[`name_${language}`].value.trim();
      const description = target.form[`description_${language}`].value.trim();
      if (name || description) {
        translations.push({
          name: name,
          description: description,
          languageCode: language,
        });
      }
    });

    const company = {
      company_translations: {
        data: translations,
      },
      document_groups: DEFAULT_COMPANY_DOCUMENT_GROUPS,
      // website: target.form['siteUrl'].value.trim(),
      createdBy: user?.userId,
      modifiedBy: user?.userId,
    } as InsertCompanyData;

    try {
      const result = await props.client.mutate({
        mutation: InsertCompanyMutation,
        variables: { object: company },
      });

      const picture = target.form['picture'].files[0];
      if (picture) {
        await uploadCompanyPicture(result.data.company.id, picture);
      }

      if (props.onSuccess) {
        props.onSuccess();
      }
      onClose();
    } catch (err) {
      setErrorMessage('SERVER_ERROR');
      setShowError(true);
    }
  };

  const modifyCompany = async (companyId, target) => {
    const companyData: UpdateCompanyData = {
      id: companyId,
      // website: target.form['siteUrl'].value.trim(),
      modifiedBy: user?.userId,
      pathPicture: url ? url : '',
    } as UpdateCompanyData;
    const translationsToUpdate: UpdateCompanyTranslationData[] = [];
    const translationsToInsert: UpdateCompanyTranslationData[] = [];
    const translationsToDelete: UpdateCompanyTranslationData[] = [];

    for (const language of LANGUAGES) {
      const currentName = props.item?.name.get(language);
      const newName = target.form[`name_${language}`].value.trim();
      const currentDescription = props.item?.description?.get(language);
      const newDescription = target.form[
        `description_${language}`
      ].value.trim();

      if (!newName && !newDescription) {
        translationsToDelete.push({ languageCode: language });
      } else if (!currentName && !currentDescription) {
        translationsToInsert.push({
          languageCode: language,
          name: newName,
          description: newDescription,
        });
      } else {
        translationsToUpdate.push({
          languageCode: language,
          name: newName,
          description: newDescription,
        });
      }
    }

    try {
      await props.client.mutate({
        mutation: createUpdateCompanyMutation(
          companyData,
          translationsToUpdate,
          translationsToInsert,
          translationsToDelete,
        ),
      });

      const picture = target.form['picture'].files[0];
      if (picture) {
        await uploadCompanyPicture(companyId, picture);
      }

      if (props.onSuccess) {
        props.onSuccess();
      }
      onClose();
    } catch (err) {
      setErrorMessage('SERVER_ERROR');
      setShowError(true);
    }
  };

  const onClose = () => {
    setUrl(undefined);
    setNameError(new Map<string, string>());
    setErrorMessage('');
    setShowError(false);
    props.onCancel();
  };

  const uploadRef = useRef<any>();

  const onFileChanged = ev => {
    const file = ev.target.files[0];
    const url = URL.createObjectURL(file);
    setUrl(url);
  };

  const formRef = useRef<any>();

  const handleDelete = () => {
    setUrl(undefined);
    if (formRef.current['picture']) {
      formRef.current['picture'].value = null;
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
      PaperProps={{ square: true }}
    >
      <ErrorToast isOpen={showError} message={errorMessage} />

      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <InsertDriveFileOutlinedIcon />
          </Grid>
          <Grid item xs>
            <Typography className={`${classes.title} ${classes.titleColor}`}>
              {props.item ? (
                <FormattedMessage id="updateCompany" />
              ) : (
                <FormattedMessage id="createCompany" />
              )}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon className={classes.titleColor} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onConfirm} ref={formRef}>
          <Grid container spacing={4}>
            {LANGUAGES.map(language => (
              <Grid
                key={`name_${language}`}
                item
                xs={12}
                md={4}
                className={classes.container}
              >
                <Typography>
                  <FormattedMessage id="companyName" />
                  {language !== 'en' && (
                    <>
                      {' '}
                      <FormattedMessage id={`${language}Abbr`} />
                    </>
                  )}
                  {language == 'en' && (
                    <span className={classes.requireText}>*</span>
                  )}
                </Typography>
                <Input
                  id={`name_${language}`}
                  defaultValue={props.item ? props.item.name.get(language) : ''}
                  autoComplete={'off'}
                  className={`${classes.input} ${
                    fieldErrors.name.error ? classes.errorInput : ''
                  }`}
                  disableUnderline
                  inputProps={{ maxLength: 200 }}
                />
                {fieldErrors.name.error && language === 'en' && (
                  <Typography className={classes.errorText}>
                    {fieldErrors.name.message}
                  </Typography>
                )}
              </Grid>
            ))}
            <Grid item xs={12} md={4} className={classes.container}>
              <Typography>
                <FormattedMessage id="companyPicture" />
              </Typography>
              <Grid item xs={12}>
                {url && (
                  <Img
                    src={url}
                    className={classes.image}
                    style={{
                      width: 35,
                      height: 35,
                      borderRadius: '50%',
                      marginRight: '10px',
                    }}
                  />
                )}
                <label htmlFor="picture">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      uploadRef.current.click();
                    }}
                  >
                    {url ? (
                      <FormattedMessage id="updateCompanyPicture" />
                    ) : (
                      <FormattedMessage id="upload" />
                    )}
                  </Button>
                </label>
                <input
                  ref={uploadRef}
                  id="picture"
                  type="file"
                  accept="image/*"
                  onChange={onFileChanged}
                  style={{ display: 'none' }}
                />
                {url && (
                  <ColorButton
                    className={classes.deleteBtn}
                    onClick={handleDelete}
                  >
                    <FormattedMessage id="delete" />
                  </ColorButton>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} className={classes.container}>
              <Typography>
                {/* <FormattedMessage id="website" /> */}
                {/* <span className={classes.requireText}>*</span> */}
              </Typography>
              {/* <Input
                id="siteUrl"
                defaultValue={props.item ? props.item.website : ''}
                autoComplete={'off'}
                className={classes.input}
                disableUnderline
                inputProps={{ maxLength: 200 }}
              /> */}
              {fieldErrors.url.error && (
                <Typography className={classes.errorText}>
                  {fieldErrors.url.message}
                </Typography>
              )}
            </Grid>
            {LANGUAGES.map(language => (
              <Grid
                key={`description_${language}`}
                item
                xs={12}
                className={classes.container}
              >
                <Typography>
                  <FormattedMessage id="description" />
                  {language == 'en' && (
                    <span className={classes.requireText}>*</span>
                  )}
                  {language !== 'en' && (
                    <>
                      {' '}
                      <FormattedMessage id={`${language}Abbr`} />
                    </>
                  )}
                </Typography>
                <TextArea
                  rowsMin={4}
                  defaultValue={
                    props.item?.description
                      ? props.item.description.get(language)
                      : ''
                  }
                  autoComplete={'off'}
                  id={`description_${language}`}
                  className={classes.input}
                  maxLength={5000}
                />
                {fieldErrors.description.error && language === 'en' && (
                  <Typography className={classes.errorText}>
                    {fieldErrors.description.message}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="flex-end"
            className={classes.container}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={isLoading}
            >
              {isLoading && (
                <div style={{ marginRight: 12 }}>
                  <Loader />{' '}
                </div>
              )}
              {props.item ? (
                <FormattedMessage id="update" />
              ) : (
                <FormattedMessage id="create" />
              )}
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CompanyCreateDialog;
